import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PaymentsIcon from '@mui/icons-material/Payments';
import SwapVerticalCircleIcon from '@mui/icons-material/SwapVerticalCircle';
import {
  Box,
  Grid,
  Paper,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import DatePickerHeader from '../../../../components/DatePickerHeader';
import { formatCurrency } from '../../../../utils/valueFormatters';
import { useParams } from 'react-router-dom';
import ClientProfileStatistics from './ClientProfileStatistics';
import ClientProfileTabDataList from './ClientProfileDataLists/ClientProfileTabDataList';
import { GridColDef } from '@mui/x-data-grid';
import { parseJSON } from 'date-fns';
import { InvoiceTypes } from '../../../../types/InvoiceTypes';
import { getOrderColumns } from './ClientProfileDataLists/ClientProfileTabDataListColumns';
import OrderPDFDialog from '../../../../components/OrderPDFDialog';
import { Visibility } from '@mui/icons-material';
import ClientProfileInfoTab from './ClientProfileInfoTab';
import { EntityTypes } from '../../../../types/EntityTypes';

const ClientProfilePage = (props: any) => {
  const { client, selectedInterval, setSelectedInterval, isGross } = props;
  const isSmallScreen = useMediaQuery('(max-width:1400px)');
  const [filteredOrders, setFilteredOrders] = useState<any>({
    paidOrdersPrice: 0,
    dueOrdersPrice: 0,
    outStandingOrdersPrice: 0,
  });
  const [filteredExpenses, setFilteredExpenses] = useState<any>({
    paidExpensesPrice: 0,
    dueExpensesPrice: 0,
    outStandingExpensesPrice: 0,
  });

  const [orderPDFDialog, setOrderPDFDialog] = useState<any>({
    open: false,
    orderId: 0,
  });

  useEffect(() => {
    if (isGross) {
      setFilteredOrders({
        paidOrdersPrice: client?.paidOrdersPrice,
        dueOrdersPrice: client?.dueOrdersPrice,
        outStandingOrdersPrice: client?.outStandingOrdersPrice,
      });
      setFilteredExpenses({
        paidExpensesPrice: client?.paidExpensesPrice,
        dueExpensesPrice: client?.dueExpensesPrice,
        outStandingExpensesPrice: client?.outStandingExpensesPrice,
      });
    } else {
      setFilteredOrders({
        paidOrdersPrice: client?.paidOrdersPriceNet,
        dueOrdersPrice: client?.dueOrdersPriceNet,
        outStandingOrdersPrice: client?.outStandingOrdersPriceNet,
      });
      setFilteredExpenses({
        paidExpensesPrice: client?.paidExpensesPriceNet,
        dueExpensesPrice: client?.dueExpensesPriceNet,
        outStandingExpensesPrice: client?.outStandingExpensesPriceNet,
      });
    }
  }, [isGross, client]);

  return (
    <Grid container p={0}>
      <Grid item xs={12}>
        <Box sx={{ width: '100%' }}>
          <Grid container>
            <Grid item xs={12} sm={6} p={2}>
              <ClientProfileStatistics
                filteredExpenses={filteredExpenses}
                filteredOrders={filteredOrders}
                isGross={isGross}
                orders={client?.orders}
                expenses={client?.expenses}
              />
            </Grid>
            <Grid item xs={12} sm={6} p={2}>
              <ClientProfileInfoTab client={client.client} />
            </Grid>
            <Grid item xs={12} pt={2}>
              <Typography variant="h5">Kapcsolódó számlák</Typography>
              <ClientProfileTabDataList
                entityType={EntityTypes.Order}
                paidRows={client.paidOrders}
                outstandingRows={client.outStandingOrders}
                DueRows={client.dueOrders}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <OrderPDFDialog
        open={orderPDFDialog.open}
        onClose={() => setOrderPDFDialog({ open: false, orderId: 0 })}
        orderId={orderPDFDialog.orderId}
      />
    </Grid>
  );
};

export default ClientProfilePage;
