import axios from 'axios';
import { postData } from '../axiosUtils';

const vehicleDamageReportService = {
  list: (vehicleId: any = null) => {
    if (vehicleId) {
      return axios
        .get(`/api/vehicleDamageReport/list?vehicleId=${vehicleId}`)
        .then((response) => {
          return response.data;
        });
    }
    return axios.get(`/api/vehicleDamageReport/list`).then((response) => {
      return response.data;
    });
  },
  get: (id: any) => {
    return axios
      .get(`/api/vehicleDamageReport/details/${id}`)
      .then((response) => {
        return response.data;
      });
  },
  create: (entity: any) => {
    return postData({
      url: `/api/vehicleDamageReport/create`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  update: (entity: any) => {
    return postData({
      url: `/api/vehicleDamageReport/update`,
      data: entity,
      method: 'PUT',
    }).then((response) => {
      return response;
    });
  },
  delete: (id: any) => {
    return postData({
      url: `/api/vehicleDamageReport/delete/${id}`,
      method: 'DELETE',
    }).then((response) => {
      return response;
    });
  },
};

export default vehicleDamageReportService;
