import axios from 'axios';
import { postData } from '../axiosUtils';
import { DocumentBlockTypes } from '../../types/DocumentBlockTypes';

const documentBlockService = {
  list: (
    type: DocumentBlockTypes | null = null,
    userId: number | null = null
  ) => {
    let url = `/api/documentBlock/list`;
    if (type) {
      url += `?type=${type}`;
    }
    if (userId && type) {
      url += `&userId=${userId}`;
    }
    if (userId && !type) {
      url += `?userId=${userId}`;
    }
    return axios.get(url).then((response) => {
      return response.data;
    });
  },

  get: (id: any) => {
    return axios.get(`/api/documentBlock/details/${id}`).then((response) => {
      return response.data;
    });
  },
  create: (entity: any) => {
    return postData({
      url: `/api/documentBlock/create`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  update: (entity: any) => {
    return postData({
      url: `/api/documentBlock/update`,
      data: entity,
      method: 'PUT',
    }).then((response) => {
      return response;
    });
  },
  delete: (id: any) => {
    return postData({
      url: `/api/documentBlock/delete/${id}`,
      method: 'DELETE',
    }).then((response) => {
      return response;
    });
  },
};

export default documentBlockService;
