import { useSnackbar } from "notistack";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useCheckPermission from "../../../hooks/useCheckPermission";
import vehicleserviceService from "../../../services/deviceManagement/vehicleServiceService";
import { CompanyAssetStates } from "../../../types/InstrumentStates";
import clientService from "../../../services/crm/clientService";
import userService from "../../../services/authority/userService";
import { ClientTypes } from "../../../types/ClientTypes";
import warehouseService from "../../../services/wms/warehouseService";
import VehicleServiceForm from "./VehicleServiceForm";
import vehicleService from "../../../services/deviceManagement/vehicleService";

const VehicleServiceCreate = (props: any) => {
  const { onSave = null, navigateBack = true, handleCancel = null } = props;

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<string[]>([]);
  const [now, setNow] = useState<Date>(new Date());
  const [vehicles, setVehicles] = useState<any[]>([]);
  const [clients, setClients] = useState<any[]>([]);
  const [entity, setEntity] = useState<any>({
    vehicleId: 0,
    serviceCompanyId: 0,
    startDate: now,
    endDate: "",
    description: "",
    wasOilChanged: false,
    milage: 0,
    netPrice: 0,
  });

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    vehicleService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(","), {
          variant: "error",
        });
      } else {
        setVehicles(response.records);
      }
    });
  }, []);

  useEffect(() => {
    clientService.list(true, null).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(","), {
          variant: "error",
        });
      } else {
        setClients(response.records);
        setEntity({
          ...entity,
          companyId: response.records.filter(
            (x) => x.clientType === ClientTypes.Distributor
          )[0].id,
        });
      }
    });
  }, []);

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: "SHOW_SAVE" });
    vehicleserviceService
      .create(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(","), {
            variant: "error",
          });
        } else {
          enqueueSnackbar("Sikeres mentés!", {
            variant: "success",
          });

          if (onSave) onSave(response.result);
          if (navigateBack) navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: "HIDE" });
        setSubmitting(false);
      });
  };

  return (
    <VehicleServiceForm
      entity={entity}
      readonly={!checkPermission(["VehicleServiceCreate"])}
      onSubmit={onSubmit}
      errors={errors}
      vehicles={vehicles}
      clients={clients}
      handleCancel={() =>
        handleCancel != null ? handleCancel() : navigate(-1)
      }
    />
  );
};

export default VehicleServiceCreate;
