export enum EntityTypes {
  Client = 1,
  ClientEdit = 2,
  ChimneySweepingCertificate = 3,
  Acquisiton = 4,
  Expense = 5,
  Order = 6,
  Service = 7,
  ChimneyType = 8,
  Item = 9,
  ItemGroupEdit = 10,
  ItemGroupCreate = 11,
}
