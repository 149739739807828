import { useSnackbar } from "notistack";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import CustomPriceForm from "./CustomPriceForm";
import { useNavigate } from "react-router-dom";
import customPriceService from "../../../services/pricing/customPriceService";
import brandService from "../../../services/wms/brandService";
import itemService from "../../../services/wms/itemService";
import priceCategoryService from "../../../services/pricing/priceCategoryService";
import useCheckPermission from "../../../hooks/useCheckPermission";

const CustomPriceCreate = (props: any) => {
  const { onSave = null, navigateBack = true, handleCancel = null } = props;

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<string[]>([]);
  const [items, setItems] = useState<any[]>([{ id: "", name: "" }]);
  const [brands, setBrands] = useState<any[]>([{ id: "", name: "" }]);
  const [priceCategories, setPriceCategories] = useState<any[]>([
    { id: "", name: "" },
  ]);

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    itemService.listNames(true).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(","), {
          variant: "error",
        });
      } else {
        setItems(response.records);
      }
    });

    brandService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(","), {
          variant: "error",
        });
      } else {
        setBrands(response.records);
      }
    });

    priceCategoryService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(","), {
          variant: "error",
        });
      } else {
        setPriceCategories(response.records);
      }
    });
  }, []);

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: "SHOW_SAVE" });
    customPriceService
      .create(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(","), {
            variant: "error",
          });
        } else {
          enqueueSnackbar("Sikeres mentés!", {
            variant: "success",
          });

          if (onSave) onSave(response.result);
          if (navigateBack) navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: "HIDE" });
        setSubmitting(false);
      });
  };

  return (
    <CustomPriceForm
      readonly={!checkPermission(["CustomPriceCreate"])}
      entity={{
        name: "",
        brandId: "",
        itemId: "",
        priceCategoryId: "",
        customPriceType: 1,
        margin: 0,
      }}
      brands={brands}
      onNewBrandAdded={(brand: any) => {
        setBrands([...brands, brand]);
      }}
      items={items}
      onNewItemAdded={(item: any) => {
        setItems([...items, item]);
      }}
      priceCategories={priceCategories}
      onNewPriceCategoryAdded={(priceCategory: any) => {
        setPriceCategories([...priceCategories, priceCategory]);
      }}
      onSubmit={onSubmit}
      errors={errors}
      handleCancel={() =>
        handleCancel != null ? handleCancel() : navigate(-1)
      }
    />
  );
};

export default CustomPriceCreate;
