/* eslint-disable react-hooks/exhaustive-deps */
import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import WarehouseForm from './WarehouseForm';
import { useNavigate } from 'react-router-dom';
import warehouseService from '../../../services/wms/warehouseService';
import warehouseGroupService from '../../../services/wms/warehouseGroupService';
import useCheckPermission from '../../../hooks/useCheckPermission';
import userService from '../../../services/authority/userService';

const WarehouseCreate = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<string[]>([]);
  const [groups, setGroups] = useState<any[]>([{ id: '', groupName: '' }]);
  const [users, setUsers] = useState<any[]>([
    { id: '', fullName: '', email: '' },
  ]);
  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    userService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setUsers(response.records);
      }
    });

    warehouseGroupService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setGroups(response.records);
      }
    });
  }, []);

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    warehouseService
      .create(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });

          navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        setSubmitting(false);
      });
  };

  return (
    <WarehouseForm
      entity={{ warehouseGroupId: 0, name: '', userIds: [] }}
      users={users}
      readonly={!checkPermission(['WarehouseCreate'])}
      groups={groups}
      onNewGroupAdded={(group: any) => {
        setGroups([...groups, group]);
      }}
      onSubmit={onSubmit}
      errors={errors}
    />
  );
};

export default WarehouseCreate;
