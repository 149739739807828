import { Grid, TextField, Autocomplete } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import useCheckPermission from '../../hooks/useCheckPermission';
import { DatePicker } from '@mui/x-date-pickers';
import { ClientTypes } from '../../types/ClientTypes';
import { useEffect } from 'react';
import clientService from '../../services/crm/clientService';
import userService from '../../services/authority/userService';
import warehouseService from '../../services/wms/warehouseService';
import companyAssetService from '../../services/deviceManagement/companyAssetService';
import ForwardIcon from '@mui/icons-material/Forward';

export default function MoveAssetDialog(props: any) {
  const { open, close, readonly = false, baseEntity } = props;
  const [warehouses, setWarehouses] = React.useState<any[]>([]);
  const [users, setUsers] = React.useState<any[]>([]);
  const [clients, setClients] = React.useState<any[]>([]);
  const [prevCompanyId, setPrevCompanyId] = React.useState<number>(0);
  const [prevWarehouseId, setPrevWarehouseId] = React.useState<number>(0);
  const [prevUserId, setPrevUserId] = React.useState<number>(0);
  const [entity, setEntity] = React.useState({
    companyAssetId: 0,
    reason: '',
    heldUntil: '',
    takenAt: new Date(),
    userId: 0,
    companyId: 0,
    warehouseId: 0,
  });

  useEffect(() => {
    warehouseService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setWarehouses(response.records);
      }
    });
  }, []);

  useEffect(() => {
    userService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setUsers(response.records);
      }
    });
  }, []);

  useEffect(() => {
    clientService.list(true, null).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setClients(response.records);
        setEntity({
          ...entity,
          companyId: response.records.filter(
            (x) => x.clientType === ClientTypes.Distributor
          )[0].id,
        });
      }
    });
  }, []);

  const handleClose = () => {
    close();
    setEntity({
      companyAssetId: 0,
      reason: '',
      heldUntil: '',
      takenAt: new Date(),
      userId: 0,
      companyId: 0,
      warehouseId: 0,
    });
  };

  React.useEffect(() => {
    setEntity({
      ...entity,
      companyAssetId: baseEntity?.assetId,
      companyId: baseEntity?.companyId,
      userId: baseEntity?.userId,
      warehouseId: baseEntity?.warehouseId,
    });
    setPrevCompanyId(baseEntity?.companyId);
    setPrevWarehouseId(baseEntity?.warehouseId);
    setPrevUserId(baseEntity?.userId);
  }, [baseEntity]);

  const { enqueueSnackbar } = useSnackbar();

  const moveAsset = (entity: any) => {
    companyAssetService.move(entity).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres mozgatás!', {
          variant: 'success',
        });
        handleClose();
      }
    });
  };

  const { checkPermission } = useCheckPermission();

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Vagyontárgy mozgatása</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} style={{ paddingTop: 10 }}>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12} md={5}>
              <Autocomplete
                disablePortal
                id="companyId"
                disabled
                value={prevCompanyId ?? ''}
                getOptionLabel={(option) =>
                  clients?.find((g) => g.id === option)?.companyName ?? ''
                }
                options={clients
                  ?.filter((y) => y.clientType === ClientTypes.Distributor)
                  .map((g) => g.id)}
                renderInput={(params) => (
                  <TextField {...params} required fullWidth label="Cég" />
                )}
              />
            </Grid>
            <Grid item xs={12} md={2} textAlign={'center'} alignSelf={'center'}>
              <ForwardIcon fontSize="large" color="primary" />
            </Grid>
            <Grid item xs={12} md={5}>
              <Autocomplete
                disablePortal
                id="companyId"
                disabled={readonly}
                value={entity?.companyId ?? ''}
                onChange={(event, value) => {
                  setEntity({ ...entity, companyId: value });
                }}
                getOptionLabel={(option) =>
                  clients?.find((g) => g.id === option)?.companyName ?? ''
                }
                options={clients
                  ?.filter((y) => y.clientType === ClientTypes.Distributor)
                  .map((g) => g.id)}
                renderInput={(params) => (
                  <TextField {...params} required fullWidth label="Cég" />
                )}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12} md={5}>
              <Autocomplete
                disablePortal
                id="prevUserId"
                disabled
                value={prevUserId ?? ''}
                getOptionLabel={(option) => {
                  var found = users.find((g) => g.id === option);
                  if (found) {
                    return `${found.id}.${found.fullName}`;
                  } else return '';
                }}
                options={users.map((g) => g.id)}
                renderInput={(params) => (
                  <TextField {...params} label="Felelős" required fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} md={2} textAlign={'center'} alignSelf={'center'}>
              <ForwardIcon fontSize="large" color="primary" />
            </Grid>
            <Grid item xs={12} md={5}>
              <Autocomplete
                disablePortal
                id="holderId"
                disabled={readonly}
                value={entity.userId ?? ''}
                onChange={(event, value) => {
                  setEntity({ ...entity, userId: value });
                }}
                getOptionLabel={(option) => {
                  var found = users.find((g) => g.id === option);
                  if (found) {
                    return `${found.id}.${found.fullName}`;
                  } else return '';
                }}
                options={users.map((g) => g.id)}
                renderInput={(params) => (
                  <TextField {...params} label="Felelős" required fullWidth />
                )}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12} md={5}>
              <Autocomplete
                disablePortal
                id="prevWarehouseId"
                disabled
                value={prevWarehouseId ?? ''}
                getOptionLabel={(option) => {
                  var found = warehouses.find((g) => g.id === option);
                  if (found) {
                    return `${found.name}`;
                  } else return '';
                }}
                options={warehouses.map((g) => g.id)}
                renderInput={(params) => (
                  <TextField {...params} label="Raktár" required fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} md={2} textAlign={'center'} alignSelf={'center'}>
              <ForwardIcon fontSize="large" color="primary" />
            </Grid>
            <Grid item xs={12} md={5}>
              <Autocomplete
                disablePortal
                id="warehouseId"
                disabled={readonly}
                value={entity.warehouseId ?? ''}
                onChange={(event, value) => {
                  setEntity({ ...entity, warehouseId: value });
                }}
                getOptionLabel={(option) => {
                  var found = warehouses.find((g) => g.id === option);
                  if (found) {
                    return `${found.name}`;
                  } else return '';
                }}
                options={warehouses.map((g) => g.id)}
                renderInput={(params) => (
                  <TextField {...params} label="Raktár" required fullWidth />
                )}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={'auto'} p={1}>
            <DatePicker
              label="Átadás dátuma"
              value={entity.takenAt ?? ''}
              onChange={(date) =>
                setEntity({ ...entity, takenAt: new Date(date) })
              }
              renderInput={(props) => <TextField {...props} />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={'auto'} p={1}>
            <DatePicker
              label="Visszavétel dátuma"
              value={entity.heldUntil ?? ''}
              onChange={(date) => setEntity({ ...entity, heldUntil: date })}
              renderInput={(props) => <TextField {...props} />}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={entity.reason}
              onChange={(e) => setEntity({ ...entity, reason: e.target.value })}
              label="Indok"
              multiline
              name="reason"
              required
              disabled={readonly}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={readonly}
          onClick={() => {
            moveAsset(entity);
            handleClose();
          }}
        >
          Mozgatás
        </Button>
        <Button color="primary" variant="outlined" onClick={handleClose}>
          Mégse
        </Button>
      </DialogActions>
    </Dialog>
  );
}
