import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Paper,
  TextField,
} from '@mui/material';
import { DesktopDateTimePicker } from '@mui/x-date-pickers';
import { Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import useCheckPermission from '../../../hooks/useCheckPermission';
import { useNavigate } from 'react-router';
import TaskLogDialog from './TaskLogDialog';
import { useEffect, useState } from 'react';

const TaskForm = (props: any) => {
  const user = useSelector((state: any) => state.user.userInfo);
  const navigate = useNavigate();

  const {
    entity,
    onSubmit,
    errors = [],
    handleCancel,
    readonly = false,
    employees,
  } = props;
  const { checkPermission } = useCheckPermission();
  const [taskLogDialog, setTaskLogDialog] = useState<any>({
    open: false,
    taskId: 0,
  });

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        setFieldValue,
        handleChange,
        errors: validationErrors,
      }) => (
        <Form>
          {' '}
          <Paper>
            <Grid container justifyContent="left" spacing={2} p={5}>
              <h2>
                Feladatok {values.id > 0 ? 'szerkesztése' : 'létrehozása'}
              </h2>
              <Grid xs={12} item pt={5}>
                <Box color="red">
                  {errors?.map((error) => (
                    <li>{error}</li>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                <DesktopDateTimePicker
                  label="dátum"
                  value={values?.date}
                  inputFormat="yyyy-MM-dd HH:mm"
                  disabled={readonly}
                  onChange={(value) => setFieldValue('date', value)}
                  renderInput={(params) => (
                    <TextField
                      required
                      fullWidth
                      {...params}
                      error={!!touched.date && !!validationErrors.date}
                      helperText={
                        !!touched.date &&
                        !!validationErrors.date &&
                        (validationErrors.date as string)
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="employeeId"
                  disabled={readonly}
                  value={values?.employeeId}
                  isOptionEqualToValue={(option, value) => {
                    return option === value;
                  }}
                  onChange={(event, value) => {
                    setFieldValue('employeeId', value);
                  }}
                  getOptionLabel={(option) => {
                    var found = employees?.find((g) => g.id === option);
                    if (found) {
                      return `${found.id}.${found.fullName ?? ''}`;
                    }
                    return '';
                  }}
                  options={employees?.map((g) => g.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      error={
                        !!touched.employeeId && !!validationErrors.employeeId
                      }
                      helperText={
                        !!touched.employeeId &&
                        !!validationErrors.employeeId &&
                        (validationErrors.employeeId as string)
                      }
                      label="Dolgozó"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values?.workHour}
                  onChange={(e) => setFieldValue('workHour', e.target.value)}
                  label="Munkaóra"
                  name="workHour"
                  type="number"
                  InputLabelProps={{ shrink: true }}
                  disabled={readonly}
                  fullWidth
                  error={!!touched.workHour && !!validationErrors.workHour}
                  helperText={
                    !!touched.workHour &&
                    !!validationErrors.workHour &&
                    (validationErrors.workHour as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values?.description}
                  onChange={(e) => setFieldValue('description', e.target.value)}
                  label="Leírás"
                  name="description"
                  required
                  multiline
                  InputLabelProps={{ shrink: true }}
                  disabled={readonly}
                  fullWidth
                  error={
                    !!touched.description && !!validationErrors.description
                  }
                  helperText={
                    !!touched.description &&
                    !!validationErrors.description &&
                    (validationErrors.description as string)
                  }
                />
              </Grid>
              <Grid container item pt={3} justifyContent="left">
                {values?.workItemId > 0 && (
                  <Grid item p={1}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        navigate(
                          `/erp/workitems/edit/${values.workItemId}/0/workItem`
                        );
                      }}
                    >
                      Munkalap megnyitása
                    </Button>
                  </Grid>
                )}
                {values?.itemSupplyId > 0 && (
                  <Grid item p={1}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        navigate(
                          `/wms/itemSupplies/edit/${values.itemSupplyId}`
                        );
                      }}
                    >
                      Anyagfelvétel megnyitása
                    </Button>
                  </Grid>
                )}
                {!readonly && (
                  <Grid item p={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      Mentés
                    </Button>
                  </Grid>
                )}
                {values.id > 0 && (
                  <Grid item p={1}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() =>
                        setTaskLogDialog({
                          open: true,
                          taskId: values.id,
                        })
                      }
                    >
                      Napló megtekintése
                    </Button>
                  </Grid>
                )}
                <Grid item p={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleCancel}
                  >
                    Mégse
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
          <TaskLogDialog
            open={taskLogDialog.open}
            setOpen={setTaskLogDialog}
            taskId={taskLogDialog.taskId}
          />
        </Form>
      )}
    </Formik>
  );
};

export default TaskForm;
