import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ItemGroupForm from './ItemGroupForm';
import { useNavigate, useParams } from 'react-router-dom';
import itemGroupService from '../../../services/wms/itemGroupService';
import useCheckPermission from '../../../hooks/useCheckPermission';

const ItemGroupEdit = (props: any) => {
  const { clientId = null, isDialog = false, onSave } = props;

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const params = useParams();
  const [errors, setErrors] = useState<string[]>([]);
  const [entity, setEntity] = useState<any>({ name: '', parentId: 0 });
  const id = clientId !== null ? clientId : params.id;

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    if (id) {
      itemGroupService.get(id).then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          setEntity(response.result);
        }
      });
    }
  }, [id]);

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    itemGroupService
      .update(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });
          if (onSave) onSave(response.result);
          if (!isDialog) {
            navigate(-1);
          }
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        setSubmitting(false);
      });
  };

  return (
    <ItemGroupForm
      entity={entity}
      readonly={!checkPermission(['ItemGroupEdit'])}
      onSubmit={onSubmit}
      handleCancel={() => navigate(-1)}
      errors={errors}
      isDialog={isDialog}
    />
  );
};

export default ItemGroupEdit;
