import { Grid, Paper, Tooltip } from '@mui/material';
import {
  GridActionsColDef,
  GridColDef,
  GridActionsCellItem,
} from '@mui/x-data-grid';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DataList from '../../../components/DataList';
import useCheckPermission from '../../../hooks/useCheckPermission';
import useConfirmDeleteDialog from '../../../hooks/useConfirmDeleteDialog';
import useFunctionDescriptor from '../../../hooks/useFunctionDescriptor';
import clientService from '../../../services/crm/clientService';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Visibility } from '@mui/icons-material';
import { AddressDialog } from './AddressDialog';

const AddressPage = (props: any) => {
  const { externalRows = null } = props;
  const navigate = useNavigate();
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const [rows, setRows] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const titleDescriptor = useFunctionDescriptor('AddresssPage.title');
  const [addressDialogState, setAddressDialogState] = useState<any>({
    open: false,
    addressId: 0,
  });

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    if (externalRows) {
      setRows(externalRows);
      return;
    }
    dispatch({ type: 'SHOW_QUERY' });
    clientService
      .listAddresses()
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) setRows(response.records);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];
    if (!externalRows) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Megtekintés">
              <Visibility />
            </Tooltip>
          }
          label="Munkalapok"
          onClick={() =>
            setAddressDialogState({
              open: true,
              addressId: params.row.id,
            })
          }
        />
      );
    }
    return actions;
  };

  const columns: GridColDef[] = [
    { field: 'country', headerName: 'Ország', flex: 100 },
    { field: 'postalCode', headerName: 'Irányítószám', flex: 200 },
    { field: 'city', headerName: 'Város', flex: 200 },
    { field: 'street', headerName: 'Utca', flex: 200 },
    { field: 'houseNumber', headerName: 'Házszám', flex: 200 },
    {
      field: 'actions',
      type: 'actions',
      flex: 50,
      getActions: getActions,
    } as GridActionsColDef,
  ];

  return (
    <Paper>
      <Grid container p={3}>
        {!externalRows && (
          <Grid item xs={12}>
            <h2>Címek{titleDescriptor}</h2>
          </Grid>
        )}
        <Grid item xs={12}>
          <DataList
            rows={rows}
            columns={columns}
            localStorageKey={'AddressPage'}
          />
        </Grid>
        <ConfirmDeleteDialog />
        <AddressDialog
          addressId={addressDialogState.addressId}
          open={addressDialogState.open}
          onClose={() => setAddressDialogState({ open: false, addressId: 0 })}
        />
      </Grid>
    </Paper>
  );
};

export default AddressPage;
