import { Grid } from '@mui/material';
import useCheckPermission from '../../hooks/useCheckPermission';
import useModuleSettings from '../../hooks/useModuleSettings';
import { ModuleSettingsType } from '../../types/ModuleSettingsType';

const SystemConfigurationModuleSettings = () => {
  const { checkPermission } = useCheckPermission();

  const chimneySweepingCertModuleSettings = useModuleSettings({
    type: ModuleSettingsType.ChimneySweepingCertificateInvoice,
    userId: null,
    isDefualtConfig: true,
  });
  const currencyModuleSettings = useModuleSettings({
    type: ModuleSettingsType.Currency,
    userId: null,
    isDefualtConfig: true,
  });

  return (
    <Grid container p={3} spacing={2}>
      {checkPermission(['GetChimneySweepingCertificateModuleSettings']) && (
        <Grid item xs={12}>
          <Grid item>
            {chimneySweepingCertModuleSettings?.ModuleComponent()}
          </Grid>
        </Grid>
      )}
      {checkPermission(['GetCurrencyModuleSettings']) && (
        <Grid item xs={12}>
          <Grid item>{currencyModuleSettings?.ModuleComponent()}</Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default SystemConfigurationModuleSettings;
