import {
  Grid,
  useMediaQuery,
  Autocomplete,
  TextField,
  IconButton,
  Tooltip,
} from '@mui/material';
import { useEffect } from 'react';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { Currency } from '../types/Currency';
import { AttachmentTypes } from '../types/AttachmentTypes';
import { translateAttachmentTypesName } from '../utils/nameFormatters';
import { Sync } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import billingoService from '../services/billingoService';
import taxTypeService from '../services/erp/taxTypeService';
import { useSnackbar } from 'notistack';
import orderService from '../services/sales/orderService';
import { ApiKeyType } from '../types/ApiKeyType';
import { DocumentLanguage } from '../types/DocumentLanguage';
import { ModuleSettingsType } from '../types/ModuleSettingsType';
import useModuleSettings from '../hooks/useModuleSettings';

export interface CurrencyPickerProps {
  currency: Currency;
  language?: DocumentLanguage;
  setLanguage?: any;
  setCurrency: any;
  conversionRate: number;
  setConversionRate: any;
  localStorageKey: string;
  readonly?: boolean;
  disableLanguage?: boolean;
}

const CurrencyPicker = (props: CurrencyPickerProps) => {
  const {
    currency,
    setCurrency,
    language = DocumentLanguage.Hu,
    setLanguage = () => {},
    conversionRate,
    setConversionRate,
    localStorageKey,
    readonly = false,
    disableLanguage = false,
  } = props;

  const isSmallScreen = useMediaQuery('(max-width:1400px)');
  const currencyLocal = useLocalStorage(localStorageKey + 'CurrencyPicker');
  const dispatch = useDispatch<any>();
  const { enqueueSnackbar } = useSnackbar();

  const moduleSettings = useModuleSettings({
    type: ModuleSettingsType.Currency,
    userId: null,
  });

  // useEffect(() => {
  //   if (currencyLocal.value !== null) {
  //     setCurrency(currencyLocal.value);
  //     getConversionRate(currencyLocal.value as Currency);
  //   }
  // }, [currencyLocal.value]);

  const setAndSaveCurrency = (value: any) => {
    currencyLocal.setLocalStorageValue(value);
    setCurrency(value);
    getConversionRate(value as Currency);
  };

  const getConversionRate = (currency: Currency) => {
    if (!currency) {
      return;
    }
    dispatch({ type: 'SHOW_QUERY' });
    orderService
      .getConversionRate(currency, Currency.HUF)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          return enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        }
        setConversionRate(response.result);
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            disablePortal
            id="currency"
            disabled={readonly}
            value={currency}
            onChange={(event, value) => {
              setAndSaveCurrency(value as Currency);
            }}
            getOptionLabel={(option) => {
              return Currency[option];
            }}
            options={[
              ...Object.values(Currency).filter(
                (x) =>
                  Number.isFinite(x) &&
                  moduleSettings?.settings?.allowedCurrencies?.includes(x)
              ),
            ]}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label="Pénznem"
                InputLabelProps={{
                  style: { fontWeight: 'bold' },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={10} sm={5}>
          <TextField
            value={conversionRate}
            onChange={(event) => {
              setConversionRate(parseFloat(event.target.value));
            }}
            required
            fullWidth
            disabled={readonly}
            type="number"
            label="Árfolyam"
            InputLabelProps={{
              style: { fontWeight: 'bold' },
            }}
          />
        </Grid>
        <Grid item xs={2} sm={1}>
          <IconButton
            disabled={readonly}
            onClick={() => {
              getConversionRate(currency);
            }}
          >
            <Tooltip title="Árfolyam frissítése">
              <Sync color="primary" />
            </Tooltip>
          </IconButton>
        </Grid>
      </Grid>
      {!disableLanguage && (
        <Grid item xs={12} pt={2}>
          <Autocomplete
            disablePortal
            disabled={readonly || disableLanguage}
            value={language}
            onChange={(event, value) => {
              setLanguage(value as DocumentLanguage);
            }}
            getOptionLabel={(option) => {
              return option === DocumentLanguage.Hu
                ? 'Magyar'
                : option === DocumentLanguage.De
                ? 'Német'
                : option === DocumentLanguage.En
                ? 'Angol'
                : option === DocumentLanguage.Ro
                ? 'Román'
                : option === DocumentLanguage.Sk
                ? 'Szlovák'
                : option === DocumentLanguage.Fr
                ? 'Francia'
                : option === DocumentLanguage.It
                ? 'Olasz'
                : option === DocumentLanguage.Hr
                ? 'Horvát'
                : option === DocumentLanguage.Us
                ? 'Amerikai'
                : option === DocumentLanguage.Zh
                ? 'Kínai'
                : '';
            }}
            options={
              Object.values(DocumentLanguage).filter((x) =>
                Number.isFinite(x)
              ) ?? []
            }
            renderInput={(params) => (
              <TextField {...params} required fullWidth label="Számla nyelve" />
            )}
          />
        </Grid>
      )}
    </>
  );
};

export default CurrencyPicker;
