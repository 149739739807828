import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Tab,
  Tabs,
  TextField,
  useMediaQuery,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Modules } from '../../../types/Modules';
import { translateModules } from '../../../utils/nameFormatters';
import React from 'react';
import {
  AccountCircle,
  AccountTree,
  Assessment,
  Assignment,
  AttachFile,
  AttachMoney,
  CorporateFare,
  Devices,
  Email,
  Flag,
  Liquor,
  LocalShipping,
  Paid,
  PointOfSale,
  Psychology,
  RequestQuote,
  Roofing,
  Settings,
  Task,
  Warehouse,
} from '@mui/icons-material';

export default function PermissionDialog(props: any) {
  const { open, onClose, permissions, selectedPermissions } = props;

  const [checkedPermissions, setCheckedPermissions] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const tabs = Object.values(Modules).filter((x) => Number.isFinite(x)) ?? [];
  const [tabValue, setTabValue] = useState<number>(1);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    if (selectedPermissions && selectedPermissions.length)
      setCheckedPermissions(selectedPermissions);
  }, [selectedPermissions]);

  const handlePermissionChange = (event) => {
    const { value, checked } = event.target;
    const permissionId = parseInt(value);

    if (value === 'all') {
      setSelectAll(checked);
      setCheckedPermissions((prev) =>
        checked
          ? [
              ...prev.filter(
                (x) =>
                  !filteredPermissions
                    ?.map((permission) => permission.id)
                    .includes(x)
              ),
              ...filteredPermissions?.map((permission) => permission.id),
            ]
          : prev.filter(
              (x) =>
                !filteredPermissions
                  ?.map((permission) => permission.id)
                  .includes(x)
            )
      );
    } else {
      if (checked) {
        setCheckedPermissions([...checkedPermissions, permissionId]);
      } else {
        setCheckedPermissions([
          ...checkedPermissions.filter(
            (permission) => permission !== permissionId
          ),
        ]);
      }
    }
  };

  const handleSave = () => {
    onClose(checkedPermissions);
  };

  const filteredPermissions = permissions.filter(
    (permission) =>
      permission.modul === (tabValue as Modules) &&
      permission.description.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    setSelectAll(
      filteredPermissions.every((permission) =>
        checkedPermissions.includes(permission.id)
      )
    );
  }, [filteredPermissions, checkedPermissions]);

  const getTabIcon = (tab: Modules) => {
    switch (tab) {
      case Modules.ChimneySweeping:
        return <Roofing />;
      case Modules.Client:
        return <CorporateFare />;
      case Modules.Pricing:
        return <AttachMoney />;
      case Modules.Report:
        return <Flag />;
      case Modules.Sales:
        return <PointOfSale />;
      case Modules.Statistics:
        return <Assessment />;
      case Modules.SystemSettings:
        return <Settings />;
      case Modules.UserSettings:
        return <AccountCircle />;
      case Modules.Warehouse:
        return <Warehouse />;
      case Modules.Registration:
        return <Devices />;
      case Modules.Acquisition:
        return <LocalShipping />;
      case Modules.Billing:
        return <RequestQuote />;
      case Modules.Email:
        return <Email />;
      case Modules.FilesAndAttachments:
        return <AttachFile />;
      case Modules.Finance:
        return <AttachMoney />;
      case Modules.HR:
        return <Psychology />;
      case Modules.ItemsAndServices:
        return <Liquor />;
      case Modules.Offer:
        return <Paid />;
      case Modules.Project:
        return <AccountTree />;
      case Modules.Task:
        return <Assignment />;
      case Modules.WorkItem:
        return <Task />;
      default:
        return <AccountCircle />;
    }
  };

  const handleCancel = () => {
    onClose(selectedPermissions);
    setCheckedPermissions(selectedPermissions);
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleCancel()}
      fullWidth
      maxWidth={'xl'}
    >
      <DialogTitle textAlign={'center'}>Jogosultságok</DialogTitle>
      <DialogContent style={{ height: '1000px' }}>
        <Grid item xs={12}>
          <Box sx={{ width: '100%' }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                maxWidth: isSmallScreen ? '280px' : '100%',
              }}
            >
              <Tabs
                value={tabValue}
                onChange={handleChange}
                TabIndicatorProps={{ style: { background: '#1976d2' } }}
                sx={{
                  maxWidth: isSmallScreen ? '280px' : '100%',
                  overflowX: 'scroll',
                }}
                allowScrollButtonsMobile
                scrollButtons="auto"
                variant={true ? 'scrollable' : 'fullWidth'}
              >
                {tabs.map((tab, i) => (
                  <Tab
                    value={tab}
                    icon={getTabIcon(tab as Modules)}
                    label={translateModules(tab as Modules)}
                    sx={{ fontWeight: 'bold' }}
                  />
                ))}
              </Tabs>
            </Box>
          </Box>
        </Grid>
        <TextField
          label="Search"
          value={searchQuery}
          autoFocus
          onChange={(e) => setSearchQuery(e.target.value)}
          fullWidth
          margin="normal"
          variant="outlined"
        />

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectAll}
                  onChange={handlePermissionChange}
                  value="all"
                  color="primary"
                />
              }
              label="Mindegyik Kijelölése"
            />
          </Grid>
          {filteredPermissions.map((permission, i) => (
            <Grid item xs={12} sm={6} md={4} key={i} textAlign={'left'}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkedPermissions.includes(permission.id)}
                    onChange={handlePermissionChange}
                    value={permission.id}
                    color="primary"
                  />
                }
                label={permission.description || permission.name}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth={isSmallScreen}
          variant="contained"
          color="primary"
          onClick={handleSave}
        >
          Mentés
        </Button>
        <Button
          fullWidth={isSmallScreen}
          variant="contained"
          color="secondary"
          onClick={handleCancel}
        >
          Mégse
        </Button>
      </DialogActions>
    </Dialog>
  );
}
