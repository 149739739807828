import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ClientForm from './ClientForm';
import { useNavigate, useParams } from 'react-router-dom';
import clientService from '../../../services/crm/clientService';
import { ClientTypes } from '../../../types/ClientTypes';
import useCheckPermission from '../../../hooks/useCheckPermission';
import { ClientTaxType } from '../../../types/ClientTaxType';
import { ApiKeyType } from '../../../types/ApiKeyType';

const ClientEdit = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const { clientId = null, isDialog = false, onSave } = props;
  const params = useParams();
  const id = clientId !== null ? clientId : params.id;
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<string[]>([]);
  const { checkPermission } = useCheckPermission();

  const [entity, setEntity] = useState<any>({
    id: 0,
    name: {
      firstName: '',
      lastName: '',
      middleName: '',
      title: '',
    },
    clientType: ClientTypes.Customer,
    companyName: '',
    bankName: '',
    bankAccountNumber: '',
    szamlazzhuApi: '',
    customerLedgerNumber: '',
    iban: '',
    clientTaxType: ClientTaxType.HasTaxNumber,
    swift: '',
    representativeName: { firstName: '', lastName: '' },
    representativePhone: '',
    contact: {
      phone: '',
      email: '',
      contactName: '',
      address: {
        postalCode: 0,
        city: '',
        street: '',
        houseNumber: '',
        country: '',
        district: '',
        comment: '',
        hasPerson: false,
        personName: { firstName: '', lastName: '', middleName: '', title: '' },
        personPhone: '',
        personEmail: '',
      },
    },
    addresses: [],
    isPrivatePerson: true,
    taxNumber: '',
    comment: '',
    apiKeyDescription: '',
    apiKey: '',
    apiKeyName: '',
    hasApiKey: false,
    apiKeyType: ApiKeyType.Szamlazzhu,
    hasSpecialNeeds: false,
    specialNeeds: '',
    companyRegistrationNumber: '',
    needElectronicInvoice: false,
    isOfferMandatory: false,
  });

  useEffect(() => {
    if (id) {
      dispatch({ type: 'SHOW_QUERY' });
      clientService
        .get(id)
        .then((response) => {
          if (response.canceled) return;
          if (response.hasError) {
            setErrors(response.errorMessages);
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
          } else {
            setEntity(response.result);
          }
        })
        .finally(() => {
          dispatch({ type: 'HIDE' });
        });
    }
  }, [id]);

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    clientService
      .update(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });

          if (!isDialog) {
            navigate(-1);
          }
          if (isDialog) {
            onSave(response.result);
          }
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        setSubmitting(false);
      });
  };

  return (
    <ClientForm
      readonly={!checkPermission(['ClientEdit'])}
      entity={entity}
      onSubmit={onSubmit}
      errors={errors}
      handleCancel={() => navigate(-1)}
      isDialog={isDialog}
    />
  );
};

export default ClientEdit;
