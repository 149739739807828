import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ServiceForm from './ServiceForm';
import { useNavigate, useParams } from 'react-router-dom';
import useCheckPermission from '../../../hooks/useCheckPermission';
import serviceService from '../../../services/erp/serviceService';
import serviceCategoryService from '../../../services/erp/serviceCategoryService';
import taxTypeService from '../../../services/erp/taxTypeService';

const ServiceEdit = (props: any) => {
  const { clientId = null, isDialog = false, onSave } = props;
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const params = useParams();
  const id = clientId !== null ? clientId : params.id;

  const [errors, setErrors] = useState<string[]>([]);
  const [entity, setEntity] = useState<any>({
    name: '',
    description: '',
    servicePriceNet: 0,
    serviceCategoryId: 0,
    acquisitionPriceNet: 0,
    unitOfQuantity: '',
    taxTypeId: 0,
  });
  const [taxes, setTaxes] = useState<any[]>([]);

  const [serviceCategories, setServiceCategories] = useState<string[]>([]);

  useEffect(() => {
    taxTypeService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setTaxes(response.records);
      }
    });
  }, []);

  const { checkPermission } = useCheckPermission();
  useEffect(() => {
    serviceCategoryService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setServiceCategories(response.records);
      }
    });
  }, []);

  useEffect(() => {
    if (id) {
      serviceService.get(id).then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          setEntity(response.result);
        }
      });
    }
  }, [id]);

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    serviceService
      .update(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });

          if (!isDialog) {
            navigate(-1);
          }
          if (isDialog) {
            onSave(response.result);
          }
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        setSubmitting(false);
      });
  };

  return (
    <ServiceForm
      entity={entity}
      serviceCategories={serviceCategories}
      onNewServiceCategoryAdded={(serviceCategory) => {
        setServiceCategories([...serviceCategories, serviceCategory]);
      }}
      readonly={!checkPermission(['ServiceEdit'])}
      onSubmit={onSubmit}
      handleCancel={() => navigate(-1)}
      errors={errors}
      isDialog={isDialog}
      taxes={taxes}
    />
  );
};

export default ServiceEdit;
