import { Box, Button, Chip, useMediaQuery } from "@mui/material";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { reset } from "../stateManagement/actions/workItemActions";

const WorkItemInProgress = () => {
  const { workItemInProgress, form, step } = useSelector<any>(
    (state) => state.workItem
  ) as any;

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [dots, setDots] = useState("...");
  const isSmallScreen = useMediaQuery("(max-width:500px)");

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDots((prevDots) => {
        if (prevDots === "...") {
          return ".";
        } else if (prevDots === ".") {
          return "..";
        } else {
          return "...";
        }
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  if (!workItemInProgress) {
    return null;
  }
  if (isSmallScreen) {
    return (
      <Button
        title="Munkalap folyamatban"
        onClick={() => {
          if (
            workItemInProgress &&
            !window.location.hash.endsWith(
              `/erp/workitems/edit/${form.id}/${step}/workItem`
            )
          ) {
            if (form.id > 0) {
              navigate(`/erp/workItem/edit/${form.id}/${step}/workItem`);
            }
          }
        }}
      >
        <BookmarkIcon color="secondary" />
      </Button>
    );
  }
  return (
    <Chip
      color="secondary"
      label={<Box style={{ width: 150 }}>Munkalap folyamatban{dots}</Box>}
      onClick={() => {
        if (
          workItemInProgress &&
          !window.location.hash.endsWith(
            `/erp/workitems/edit/${form.id}/${step}/workItem`
          )
        ) {
          if (form.id > 0) {
            navigate(`/erp/workitems/edit/${form.id}/${step}/workItem`);
          }
        }
      }}
      onDelete={() => {
        if (
          window.location.hash.endsWith(
            `/erp/workitems/edit/${form.id}/${step}/workItem`
          )
        ) {
          navigate("/erp/workItems");
        }
        dispatch(reset());
      }}
    />
  );
};

export default WorkItemInProgress;
