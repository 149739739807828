import { createReducer } from "@reduxjs/toolkit";
import { reset, setForm } from "../actions/acquisitionActions";

const initialState = {
  form: {},
  acquisitionInProgress: false,
};

export const acquisitionReducer = createReducer(initialState, (builder) => {
  builder.addCase(setForm, (state, action) => {
    state.form = action.payload;
    state.acquisitionInProgress = true;
  });
  builder.addCase(reset, (state, action) => {
    state.form = {};
    state.acquisitionInProgress = false;
  });
});
