import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Autocomplete,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { Form, Formik } from "formik";
import useCheckPermission from "../../../hooks/useCheckPermission";

const HolidayForm = (props: any) => {
  const {
    entity,
    onSubmit,
    errors,
    handleCancel,
    readonly = false,
    users,
  } = props;

  const { checkPermission } = useCheckPermission();

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        setFieldValue,
        handleChange,
        errors: validationErrors,
      }) => (
        <Form>
          {" "}
          <Paper>
            <Grid container justifyContent="left" spacing={2} p={5}>
              <h2>
                Szabadság kérelem{" "}
                {values.id > 0 ? "szerkesztése" : "létrehozása"}
              </h2>
              <Grid xs={12} item pt={5}>
                <Box color="red">
                  {errors.map((error) => (
                    <li>{error}</li>
                  ))}
                </Box>
              </Grid>
              <Grid container item xs={12} spacing={2} alignItems="center">
                <Grid item>
                  <DesktopDatePicker
                    label="Kezdő dátum"
                    value={values.startDate}
                    inputFormat="yyyy-MM-dd"
                    disabled={readonly}
                    onChange={(value) => setFieldValue("startDate", value)}
                    renderInput={(params) => (
                      <TextField
                        required
                        fullWidth
                        {...params}
                        error={!!touched.date && !!validationErrors.date}
                        helperText={
                          !!touched.date &&
                          !!validationErrors.date &&
                          (validationErrors.date as string)
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <DesktopDatePicker
                    label="Vég dátum"
                    value={values.endDate}
                    inputFormat="yyyy-MM-dd"
                    disabled={readonly}
                    onChange={(value) => setFieldValue("endDate", value)}
                    renderInput={(params) => (
                      <TextField
                        required
                        fullWidth
                        {...params}
                        error={!!touched.date && !!validationErrors.date}
                        helperText={
                          !!touched.date &&
                          !!validationErrors.date &&
                          (validationErrors.date as string)
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
              {checkPermission(["HolidayCreateForOthers"]) && (
                <Grid item xs={12} md={12}>
                  <Autocomplete
                    disablePortal
                    id="userId"
                    disabled={readonly}
                    value={values.userId}
                    onChange={(event, value) => {
                      setFieldValue("userId", value);
                    }}
                    getOptionLabel={(option) => {
                      if (option === null) {
                        return "Mindegy";
                      }
                      let user = users.find((g) => g.id === option);
                      return `${user?.fullName} (${user?.email ?? ""})`;
                    }}
                    options={users.map((g) => g.id)}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth label="Dolgozó" />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Megjegyzés"
                  name="comment"
                  multiline
                  value={values.description}
                  onChange={(e) => {
                    setFieldValue("description", e.target.value);
                  }}
                  disabled={readonly}
                />
              </Grid>
              <Grid container item pt={3} justifyContent="left">
                {!readonly && (
                  <Grid item p={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      Mentés
                    </Button>
                  </Grid>
                )}
                <Grid item p={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleCancel}
                  >
                    Mégse
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default HolidayForm;
