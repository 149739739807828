import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CustomPriceForm from './CustomPriceForm';
import { useNavigate, useParams } from 'react-router-dom';
import brandService from '../../../services/wms/brandService';
import itemService from '../../../services/wms/itemService';
import priceCategoryService from '../../../services/pricing/priceCategoryService';
import customPriceService from '../../../services/pricing/customPriceService';
import useCheckPermission from '../../../hooks/useCheckPermission';

const CustomPriceEdit = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const params = useParams();
  const [errors, setErrors] = useState<string[]>([]);
  const [entity, setEntity] = useState<any>({
    name: '',
    brandId: '',
    itemId: '',
    priceCategoryId: '',
    customPriceType: 1,
    margin: 0,
  });

  const [items, setItems] = useState<any[]>([{ id: '', name: '' }]);
  const [brands, setBrands] = useState<any[]>([{ id: '', name: '' }]);
  const [priceCategories, setPriceCategories] = useState<any[]>([
    { id: '', name: '' },
  ]);

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    itemService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setItems(response.records);
      }
    });

    brandService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setBrands(response.records);
      }
    });

    priceCategoryService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setPriceCategories(response.records);
      }
    });
  }, []);

  useEffect(() => {
    customPriceService.get(params.id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        setErrors(response.errorMessages);
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setEntity(response.result);
      }
    });
  }, [params.id]);

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    customPriceService
      .update(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });

          navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        setSubmitting(false);
      });
  };

  return (
    <CustomPriceForm
      entity={entity}
      readonly={!checkPermission(['CustomPriceEdit'])}
      brands={brands}
      onNewBrandAdded={(brand: any) => {
        setBrands([...brands, brand]);
      }}
      items={items}
      onNewItemAdded={(item: any) => {
        setItems([...items, item]);
      }}
      priceCategories={priceCategories}
      onNewPriceCategoryAdded={(priceCategory: any) => {
        setPriceCategories([...priceCategories, priceCategory]);
      }}
      onSubmit={onSubmit}
      handleCancel={() => navigate(-1)}
      errors={errors}
    />
  );
};

export default CustomPriceEdit;
