import axios from 'axios';

const appService = {
  getAppStatus: () => {
    return axios.get('/api/app/appStatus').then((response) => {
      return response.data;
    });
  },
};

export default appService;
