import SelectClient from '../../../../crm/clients/SelectClient';
import { useEffect } from 'react';
import { setForm } from '../../../../../stateManagement/actions/workItemActions';
import { useDispatch } from 'react-redux';

const SelectClientStep = (props: any) => {
  const {
    values,
    setFieldValue,
    readonly,
    touched,
    validationErrors,
    onClientChange,
    clients,
    onClientAdded,
    addresses,
    onAddressAdded,
    setRepresentative,
    representative,
  } = props;

  const dispatch = useDispatch<any>();
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(setForm(values));
    }, 200);
    return () => clearTimeout(timeoutId);
  }, [values]);

  return (
    <SelectClient
      values={values}
      setFieldValue={setFieldValue}
      readonly={readonly}
      touched={touched}
      setRepresentative={setRepresentative}
      representative={representative}
      validationErrors={validationErrors}
      onClientChange={onClientChange}
      clients={clients}
      onClientAdded={onClientAdded}
      addresses={addresses}
      onAddressAdded={onAddressAdded}
      showContactInName={false}
    />
  );
};

export default SelectClientStep;
