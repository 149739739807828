import {
  Button,
  TextField,
  Box,
  Grid,
  Paper,
  Autocomplete,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { Form, Formik } from 'formik';

const ReportForm = (props: any) => {
  const {
    entity,
    onSubmit,
    errors,
    handleCancel,
    readonly = false,
    reportCategories,
  } = props;

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};
        if (!values.name) {
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        handleChange,
        setFieldValue,
        errors: validationErrors,
      }) => (
        <Form>
          {' '}
          <Paper>
            <Grid container justifyContent="left" spacing={2} p={5}>
              <h2>
                Bejelentés {values.id > 0 ? 'szerkesztése' : 'létrehozása'}
              </h2>
              <Grid xs={12} item pt={5}>
                <Box color="red">
                  {errors.map((error) => (
                    <li>{error}</li>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="reportCategoryId"
                  disabled={readonly}
                  value={values.reportCategoryId}
                  onChange={(event, value) => {
                    setFieldValue('reportCategoryId', value);
                  }}
                  getOptionLabel={(option) => {
                    var found = reportCategories?.find((g) => g.id === option);
                    if (found) {
                      return `${found.name}`;
                    } else return '';
                  }}
                  options={reportCategories?.map((g) => g.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Kategória"
                      required
                      fullWidth
                      error={
                        !!touched.reportCategoryId &&
                        !!validationErrors.reportCategoryId
                      }
                      helperText={
                        !!touched.reportCategoryId &&
                        !!validationErrors.reportCategoryId &&
                        (validationErrors.reportCategoryId as string)
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.title}
                  onChange={handleChange}
                  label="Cím"
                  name="title"
                  required
                  disabled={readonly}
                  fullWidth
                  error={!!touched.title && !!validationErrors.title}
                  helperText={
                    !!touched.title &&
                    !!validationErrors.title &&
                    (validationErrors.title as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.description}
                  onChange={handleChange}
                  label="Leírás"
                  name="description"
                  required
                  InputLabelProps={{ shrink: true }}
                  disabled={readonly}
                  fullWidth
                  error={
                    !!touched.description && !!validationErrors.description
                  }
                  helperText={
                    !!touched.description &&
                    !!validationErrors.description &&
                    (validationErrors.description as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  label="Névtelen bejelentés"
                  control={
                    <Checkbox
                      checked={values.hasDrivingLicense}
                      onChange={(e, checked) =>
                        setFieldValue('isAnonym', checked)
                      }
                    />
                  }
                />
              </Grid>
              <Grid container item pt={3} justifyContent="left">
                {!readonly && (
                  <Grid item p={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      Mentés
                    </Button>
                  </Grid>
                )}
                <Grid item p={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleCancel}
                  >
                    Mégse
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default ReportForm;
