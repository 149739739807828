import { Start } from '@mui/icons-material';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  TextField,
} from '@mui/material';

import { TimePicker } from '@mui/x-date-pickers';
import { format, isSameDay, parseJSON } from 'date-fns';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';

export const ResourcePlannerConfirmDialog = (props: any) => {
  const {
    open,
    onClose = () => {},
    onResourceSelected,
    groupId,
    time,
    workItemId,
    users,
    selectedDate,
    setHelperIds,
    helperIds,
    helperSelector = false,
    helperEmployees,
  } = props;

  const [startTime, setStartTime] = useState(time);
  const [endTime, setEndTime] = useState(moment(time).add(1, 'hour').toDate());
  const isEndTimeGreater = moment(endTime).isAfter(moment(startTime));
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setStartTime(time);
    setEndTime(moment(time).add(1, 'hour').toDate());
  }, [time]);

  const handleSave = () => {
    var start = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      selectedDate.getDate(),
      new Date(startTime).getHours(),
      new Date(startTime).getMinutes(),
      0,
      0
    );
    var end = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      selectedDate.getDate(),
      new Date(endTime).getHours(),
      new Date(endTime).getMinutes(),
      0,
      0
    );
    onResourceSelected(groupId, start, end, workItemId);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogContent>
        <DialogContentText>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} textAlign="center">
              <h2>Erőforrás megerősítése</h2>
            </Grid>
            <Grid item xs={6} md={3}>
              <TimePicker
                value={startTime}
                onChange={(value) => {
                  if (
                    isSameDay(time, new Date()) &&
                    moment(value).isBefore(moment(new Date()))
                  ) {
                    enqueueSnackbar(
                      'A kezdési időpont nem lehet kisebb a jelenlegi időpontnál!',
                      {
                        variant: 'error',
                      }
                    );
                  } else {
                    setStartTime(value);
                    setEndTime(moment(value).add(1, 'hour').toDate());
                  }
                }}
                label="Kezdési időpont"
                renderInput={(params) => <TextField {...params} />}
                ampm={false}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TimePicker
                value={endTime}
                onChange={(value) => {
                  if (moment(value).isSameOrBefore(moment(startTime))) {
                    enqueueSnackbar(
                      'A befejezési időpont nem lehet kisebb a kezdési időpontnál!',
                      {
                        variant: 'error',
                      }
                    );
                  } else {
                    setEndTime(value);
                  }
                }}
                label="Befejezési időpont"
                renderInput={(params) => <TextField {...params} />}
                ampm={false}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                disablePortal
                disabled
                value={groupId}
                getOptionLabel={(option) => {
                  return users.find((x) => x.id === option)?.fullName ?? '';
                }}
                options={users.map((x) => x.id)}
                renderInput={(params) => (
                  <TextField {...params} label="Dolgozó" required />
                )}
              />
            </Grid>
            {helperSelector && (
              <Grid item xs={12} pb={30}>
                <Autocomplete
                  disablePortal
                  value={helperIds}
                  multiple
                  getOptionLabel={(option) => {
                    return (
                      helperEmployees.find((x) => x.id === option)?.fullName ??
                      ''
                    );
                  }}
                  onChange={(e, value) => {
                    setHelperIds(value);
                  }}
                  options={helperEmployees.map((x) => x.id)}
                  renderInput={(params) => (
                    <TextField {...params} label="Segítők" required />
                  )}
                />
              </Grid>
            )}
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleSave}
          disabled={!isEndTimeGreater}
          color="primary"
        >
          Mentés
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            onClose();
            setHelperIds([]);
          }}
          color="primary"
        >
          Mégse
        </Button>
      </DialogActions>
    </Dialog>
  );
};
