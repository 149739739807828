import axios from 'axios';
import { postData } from '../axiosUtils';
import { AcquisitionStatuses } from '../../types/AcquisitionStatuses';

const acquisitionService = {
  list: (
    status?: AcquisitionStatuses,
    signal: AbortSignal = new AbortController().signal
  ) => {
    let url = `/api/acquisition/list`;

    if (status) {
      url += `?status=${status}`;
    }
    return axios.get(url, { signal }).then((response) => {
      return response.data;
    });
  },
  listBadge: () => {
    let url = `/api/acquisition/listBadge`;

    return axios.get(url).then((response) => {
      return response.data;
    });
  },
  syncItems: (
    clientId: number,
    warehouseId: number,
    signal: AbortSignal = new AbortController().signal
  ) => {
    let url = `/api/acquisition/syncItemCart?clientId=${clientId}&warehouseId=${warehouseId}`;

    return axios.get(url, { signal }).then((response) => {
      return response.data;
    });
  },
  get: (id: any) => {
    return axios.get(`/api/acquisition/details/${id}`).then((response) => {
      return response.data;
    });
  },
  create: (entity: any) => {
    return postData({
      url: `/api/acquisition/create`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  createItemAcquisition: (entity: any) => {
    return postData({
      url: `/api/acquisition/createItemAcquisition`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  ignoreItemAcquisition: (entity: any) => {
    return postData({
      url: `/api/acquisition/ignoreItemAcquisition`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  markItemUnderAcquisition: (entity: any) => {
    return postData({
      url: `/api/acquisition/markItemUnderAcquisition`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  update: (entity: any) => {
    return postData({
      url: `/api/acquisition/update`,
      data: entity,
      method: 'PUT',
    }).then((response) => {
      return response;
    });
  },
  delete: (id: any) => {
    return postData({
      url: `/api/acquisition/delete/${id}`,
      method: 'DELETE',
    }).then((response) => {
      return response;
    });
  },
};

export default acquisitionService;
