import {
  Button,
  TextField,
  Box,
  Grid,
  Paper,
  Autocomplete,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { AttachmentTypes } from '../../../types/AttachmentTypes';
import {
  translateAttachmentTypesName,
  translateDamageScaleName,
} from '../../../utils/nameFormatters';
import { DamageScale } from '../../../types/DamageScale';

const VehicleDamageReportForm = (props: any) => {
  const {
    entity,
    onSubmit,
    errors,
    handleCancel,
    readonly = false,
    users,
    vehicles,
  } = props;

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        handleChange,
        setFieldValue,
        errors: validationErrors,
      }) => (
        <Form>
          {' '}
          <Paper>
            <Grid container justifyContent="left" spacing={2} p={5}>
              <h2>
                Jármű kárbejelentés{' '}
                {values.id > 0 ? 'szerkesztése' : 'létrehozása'}
              </h2>
              <Grid xs={12} item pt={5}>
                <Box color="red">
                  {errors.map((error) => (
                    <li>{error}</li>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="vehicleId"
                  disabled={readonly}
                  value={values.vehicleId ?? ''}
                  onChange={(event, value) => {
                    setFieldValue('vehicleId', value);
                  }}
                  getOptionLabel={(option) => {
                    var found = vehicles?.find((g) => g.id === option);
                    if (found) {
                      return `${found.name}`;
                    } else return '';
                  }}
                  options={vehicles?.map((g) => g.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Jármű"
                      required
                      fullWidth
                      error={
                        !!touched.vehicleId && !!validationErrors.vehicleId
                      }
                      helperText={
                        !!touched.vehicleId &&
                        !!validationErrors.vehicleId &&
                        (validationErrors.vehicleId as string)
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="userId"
                  disabled={readonly}
                  value={values?.userId}
                  onChange={(event, value) => {
                    setFieldValue('userId', value);
                  }}
                  getOptionLabel={(option) => {
                    var found = users?.find((g) => g.id === option);
                    if (found) {
                      return `${found.id}.${found.fullName ?? ''}`;
                    }
                    return '';
                  }}
                  options={users?.map((g) => g.id) ?? []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!touched.userId && !!validationErrors.userId}
                      helperText={
                        !!touched.userId &&
                        !!validationErrors.userId &&
                        (validationErrors.userId as string)
                      }
                      label="Károkozó"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="damageScale"
                  value={values.damageScale}
                  disabled={readonly}
                  onChange={(event, value) => {
                    setFieldValue('damageScale', value);
                  }}
                  getOptionLabel={(option) => {
                    return translateDamageScaleName(option as DamageScale);
                  }}
                  options={
                    Object.values(DamageScale).filter((x) =>
                      Number.isFinite(x)
                    ) ?? []
                  }
                  renderInput={(params) => (
                    <TextField {...params} required label="Kár mértéke" />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.title}
                  onChange={handleChange}
                  label="Cím"
                  name="title"
                  required
                  disabled={readonly}
                  fullWidth
                  error={!!touched.title && !!validationErrors.title}
                  helperText={
                    !!touched.title &&
                    !!validationErrors.title &&
                    (validationErrors.title as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.description}
                  onChange={handleChange}
                  label="Leírás"
                  name="description"
                  required
                  InputLabelProps={{ shrink: true }}
                  disabled={readonly}
                  multiline
                  minRows={3}
                  fullWidth
                  error={
                    !!touched.description && !!validationErrors.description
                  }
                  helperText={
                    !!touched.description &&
                    !!validationErrors.description &&
                    (validationErrors.description as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.price}
                  onChange={handleChange}
                  label="Ár"
                  name="price"
                  InputLabelProps={{ shrink: true }}
                  disabled={readonly}
                  fullWidth
                  type="number"
                  error={!!touched.price && !!validationErrors.price}
                  helperText={
                    !!touched.price &&
                    !!validationErrors.price &&
                    (validationErrors.price as string)
                  }
                />
              </Grid>
              <Grid container item pt={3} justifyContent="left">
                {!readonly && (
                  <Grid item p={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      Mentés
                    </Button>
                  </Grid>
                )}
                <Grid item p={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleCancel}
                  >
                    Mégse
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default VehicleDamageReportForm;
