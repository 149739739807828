import { Visibility } from '@mui/icons-material';
import PrintIcon from '@mui/icons-material/Print';
import StarIcon from '@mui/icons-material/Star';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Rating,
  Tooltip,
} from '@mui/material';
import {
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
} from '@mui/x-data-grid';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { parseJSON } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DataList from '../../../components/DataList';
import workItemService from '../../../services/erp/workItemService';
import { formatRole } from '../../../utils/valueFormatters';
import { WorkItemStatus } from '../../erp/workItems/WorkItemForm';

export const ClientWorkItemsDialog = (props: any) => {
  const { open, clientId, onClose = () => {} } = props;

  const { enqueueSnackbar } = useSnackbar();
  const [workItems, setWorkItems] = useState<any[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    workItemService.listClientWorkItem(clientId).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessage, { variant: 'error' });
      } else {
        setWorkItems(response.records);
      }
    });
  }, [clientId]);
  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];
    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          <Tooltip title="Megtekintés">
            <Visibility />
          </Tooltip>
        }
        label={'Megtekintés'}
        onClick={() =>
          navigate(`/erp/workitems/edit/${params.row.id}/0/workItem`)
        }
      />
    );
    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          <Tooltip title="PDF nézet">
            <PrintIcon />
          </Tooltip>
        }
        label={'PDF nézet'}
        onClick={() => navigate(`/erp/workItems/PDF/${params.row.id}`)}
      />
    );

    return actions;
  };

  const columns: GridColDef[] = [
    {
      field: 'workFlowDate',
      headerName: 'Dátum',
      flex: 150,
      valueFormatter: (params) => {
        if (!params.value) return '';
        const previousValue = parseJSON(params.value).toLocaleDateString();
        const date = new Date(parseJSON(params.value));
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const formattedTime = `${previousValue} ${hours}:${minutes
          .toString()
          .padStart(2, '0')}`;
        return formattedTime;
      },
    },
    { field: 'workItemNumber', headerName: 'Sorszám', flex: 100 },
    { field: 'clientName', headerName: 'Ügyfél', flex: 100 },
    { field: 'workAddress', headerName: 'Cím', flex: 100 },
    { field: 'taskTypeName', headerName: 'Leírás', flex: 100 },
    { field: 'employeeName', headerName: 'Dolgozó', flex: 100 },
    {
      field: 'workItemStatus',
      headerName: 'Munkalap státusza',
      flex: 100,
      renderCell: (params) => {
        return (
          <div>
            {params.value === WorkItemStatus.Draft ? (
              <span style={{ color: 'orange' }}>Tervezés alatt</span>
            ) : params.value === WorkItemStatus.New ? (
              <span style={{ color: '#A0A0A0' }}>Nincs hozzárendelve</span>
            ) : params.value === WorkItemStatus.Assigned ? (
              <span style={{ color: '#82C0FF' }}>Tervezett</span>
            ) : params.value === WorkItemStatus.Completed ? (
              <span style={{ color: 'lightgreen' }}>Befejezett</span>
            ) : params.value === WorkItemStatus.Suspended ? (
              <span style={{ color: '#FFCC00' }}>Felfüggesztett</span>
            ) : params.value === WorkItemStatus.Closed ? (
              <span style={{ color: 'green' }}>Lezárt</span>
            ) : params.value === WorkItemStatus.Evaluated ? (
              <span style={{ color: '#90EE90' }}>Kiértékelt</span>
            ) : null}
          </div>
        );
      },
    },
    {
      field: 'roleName',
      headerName: 'Munkafolyamat státusza',
      flex: 130,
      valueFormatter: (params) => {
        return formatRole(params.value);
      },
    },
    {
      field: 'statisticRating',
      headerName: 'Értékelés',
      flex: 150,
      renderCell: (params) => (
        <div>
          <Rating
            name="hover-feedback"
            value={params.value}
            readOnly={true}
            precision={0.5}
            emptyIcon={
              <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
            }
          />
        </div>
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      align: 'right',
      flex: 200,
      getActions: getActions,
    } as GridActionsColDef,
  ];

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
      <DialogTitle>Munkalapok</DialogTitle>
      <DialogContent>
        <Grid item xs={12}>
          <DataList
            rows={workItems}
            columns={columns}
            localStorageKey={'ClientWorkItemsDialog'}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose} color="primary">
          Bezárás
        </Button>
      </DialogActions>
    </Dialog>
  );
};
