import { postData } from "./axiosUtils";
import axios from "axios";

const systemConfigurationService = {
  list: () => {
    return axios.get(`/api/systemconfiguration/list`).then((response) => {
      return response.data;
    });
  },
  get: (id: any) => {
    return axios
      .get(`/api/systemconfiguration/details?id=${id}`)
      .then((response) => {
        return response.data;
      });
  },
  getByKey: (key: any) => {
    return axios
      .get(`/api/systemconfiguration/getByKey?key=${key}`)
      .then((response) => {
        return response.data;
      });
  },
  update: (entity: any) => {
    return postData({
      url: `/api/systemconfiguration/update`,
      data: entity,
      method: "PUT",
    }).then((response) => {
      return response;
    });
  },
};

export default systemConfigurationService;
