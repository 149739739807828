import { Button, Dialog, Grid, IconButton } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import RefreshIcon from "@mui/icons-material/Refresh";

const SignatureDialog = (props: any) => {
  const {
    signatureDialogOpen,
    handleClose,
    handleSave,
    sigSignatureRef,
    resetSignature,
  } = props;

  const signatureDivRef = useRef<HTMLDivElement | null>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const handleResize = useCallback(() => {
    if (signatureDialogOpen) {
      const signatureDiv = signatureDivRef.current;
      if (signatureDiv) {
        const { width, height } = signatureDiv.getBoundingClientRect();
        setDimensions({ width, height });
      } else {
        setDimensions({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
    }
  }, [signatureDialogOpen]);

  useEffect(() => {
    if (signatureDialogOpen) {
      handleResize();
    }
  }, [signatureDialogOpen, handleResize]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    handleResize();
  }, [signatureDivRef]);

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      sx={{ textAlign: "center" }}
      style={{ display: signatureDialogOpen ? "block" : "none" }}
      fullWidth
    >
      <Grid container spacing={2} p={2}>
        <Grid item xs={12}>
          <strong>Aláírás</strong>
        </Grid>

        <Grid
          item
          xs={12}
          alignItems="center"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div
            ref={(node) => (signatureDivRef.current = node)}
            id="signatureSignatureDiv"
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.05)",
              width: "80vw",
              height: "40vh",
            }}
          >
            <SignatureCanvas
              ref={sigSignatureRef}
              canvasProps={{
                width: dimensions.width,
                height: dimensions.height,
              }}
            />
          </div>
        </Grid>
        <Grid item>
          <IconButton
            onClick={resetSignature}
            style={{ position: "absolute", top: 0, right: 0 }}
          >
            <RefreshIcon />
          </IconButton>
        </Grid>
        <Grid container pt={3} justifyContent={"center"}>
          <Grid item pr={1}>
            <Button variant="contained" onClick={handleSave}>
              Mentés
            </Button>
          </Grid>
          <Grid item pl={1}>
            <Button variant="outlined" onClick={handleClose}>
              Mégse
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default SignatureDialog;
