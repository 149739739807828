import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useCheckPermission from '../../../hooks/useCheckPermission';
import VehicleDamageReportForm from './VehicleDamageReportForm';
import vehicleDamageReportService from '../../../services/Reports/vehicleDamageReportService';
import { DamageScale } from '../../../types/DamageScale';
import documentBlockService from '../../../services/billing/documentBlockService';
import vehicleService from '../../../services/deviceManagement/vehicleService';
import userService from '../../../services/authority/userService';

const VehicleDamageReportCreate = (props: any) => {
  const { onSave = null, navigateBack = true, handleCancel = null } = props;

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<string[]>([]);
  const [users, setUsers] = useState<any[]>([]);
  const [vehicles, setVehicles] = useState<any[]>([]);

  const { checkPermission } = useCheckPermission();

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    vehicleDamageReportService
      .create(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });

          if (onSave) onSave(response.result);
          if (navigateBack) navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        setSubmitting(false);
      });
  };

  useEffect(() => {
    vehicleService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setVehicles(response.records);
      }
    });
  }, []);

  useEffect(() => {
    userService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setUsers(response.records);
      }
    });
  }, []);

  return (
    <VehicleDamageReportForm
      entity={{
        vehicleId: 0,
        userId: 0,
        title: '',
        description: '',
        price: 0,
        damageScale: DamageScale.Small,
      }}
      readonly={!checkPermission(['VehicleDamageReportCreate'])}
      onSubmit={onSubmit}
      errors={errors}
      users={users}
      vehicles={vehicles}
      handleCancel={() =>
        handleCancel != null ? handleCancel() : navigate(-1)
      }
    />
  );
};

export default VehicleDamageReportCreate;
