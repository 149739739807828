import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  Box,
  Grid,
  IconButton,
  Switch,
  Tab,
  Tabs,
  TextField,
  useMediaQuery,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  TablePagination,
  Paper,
  Typography,
  Card,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import { formatCurrency } from "../utils/valueFormatters";

import React from "react";

export interface selectedIntervalProps {
  startDate: any;
  endDate: any;
}
export interface DatePickerHeaderProps {
  selectedInterval: selectedIntervalProps;
  data: any;
  frontActions: any;
  behindActions: any;
}

const DateTableComponent = (props: DatePickerHeaderProps) => {
  const { selectedInterval, data, frontActions, behindActions } = props;

  const isSmallScreen = useMediaQuery("(max-width:1400px)");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(31);

  const [days, setDays] = React.useState([]);

  React.useEffect(() => {
    if (selectedInterval) {
      setPage(0);
      const startDate = moment(selectedInterval.startDate);
      const endDate = moment(selectedInterval.endDate);
      const daysArray = [];

      let currentDate = startDate;

      while (currentDate.isSameOrBefore(endDate)) {
        daysArray.push(currentDate.clone());
        currentDate.add(1, "day");
      }

      setDays(daysArray);
    }
  }, [selectedInterval]);

  const formatDate = (date: any) => {
    if (!date) return "";
    let newDate = new Date(date);
    return moment(newDate).format("HH:mm");
  };

  const translateToHungarian = (day: any) => {
    switch (day) {
      case "Monday":
        return "Hétfő";
      case "Tuesday":
        return "Kedd";
      case "Wednesday":
        return "Szerda";
      case "Thursday":
        return "Csütörtök";
      case "Friday":
        return "Péntek";
      case "Saturday":
        return "Szombat";
      case "Sunday":
        return "Vasárnap";
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box display={isSmallScreen ? "block" : "flex"} marginBottom={2}>
          <Box marginRight={3}>
            <Box display="flex" alignItems="center">
              <div
                style={{
                  color: "lightgreen",
                  fontSize: "24px",
                  marginRight: "8px",
                }}
              >
                ▬
              </div>
              <div>Ledolgozott nap</div>
            </Box>
          </Box>
          <Box marginRight={3}>
            <Box display="flex" alignItems="center">
              <div
                style={{
                  color: "#ff9800",
                  fontSize: "24px",
                  marginRight: "8px",
                }}
              >
                ▬
              </div>
              <div>Szabadság</div>
            </Box>
          </Box>
          <Box marginRight={3}>
            <Box display="flex" alignItems="center">
              <div
                style={{ color: "red", fontSize: "24px", marginRight: "8px" }}
              >
                ▬
              </div>
              <div>Beteg szabadság</div>
            </Box>
          </Box>
          <Box>
            <Box display="flex" alignItems="center">
              <div
                style={{
                  color: "#67CBF9",
                  fontSize: "24px",
                  marginRight: "8px",
                }}
              >
                ▬
              </div>
              <div>Egyéb</div>
            </Box>
          </Box>
        </Box>
      </Grid>
      {!isSmallScreen && (
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: "lightgrey" }}>
                  <TableCell sx={{ pl: 1, borderRight: "1px solid grey" }}>
                    <Typography fontWeight={"bold"} fontSize={"17px"}>
                      Nap
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{ p: 0, borderRight: "1px solid grey" }}
                    align="center"
                  >
                    <Typography fontWeight={"bold"} fontSize={"17px"}>
                      Munkaidő kezdete{" "}
                    </Typography>
                  </TableCell>
                  <TableCell
                    colSpan={2}
                    align="center"
                    sx={{ p: 0, borderRight: "1px solid grey" }}
                  >
                    <Typography fontWeight={"bold"} fontSize={"17px"}>
                      Ebédidő{" "}
                    </Typography>
                    <Typography fontWeight={"bold"} fontSize={"17px"}>
                      -tól -ig
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{ p: 0, borderRight: "1px solid grey" }}
                    align="center"
                  >
                    <Typography fontWeight={"bold"} fontSize={"17px"}>
                      Munkaidő Vége
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ p: 0 }} align="center">
                    <Typography fontWeight={"bold"} fontSize={"17px"}>
                      Megjegyzés
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ p: 0 }} align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {days
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((day, index) => {
                    let dayData = data.find((d) =>
                      moment(d.startOfWork).isSame(day, "day")
                    );
                    if (!dayData) {
                      dayData = {
                        startOfWork: null,
                        startOfLunch: null,
                        endOfLunch: null,
                        endOfWork: null,
                        description: null,
                        isHolyday: false,
                        isSickHoliday: false,
                      };
                    }
                    const color =
                      dayData.other !== null && dayData.other !== undefined
                        ? "#67CBF9"
                        : dayData.isHoliday
                        ? "#ff9800"
                        : dayData.isSickHoliday
                        ? "red"
                        : dayData.id > 0
                        ? "lightgreen"
                        : day.day() === 0 || day.day() === 6 // Check if day is weekend
                        ? "grey"
                        : "white";

                    return (
                      <TableRow
                        key={day.toString()}
                        sx={{ backgroundColor: color }}
                      >
                        <TableCell>
                          <Grid container>
                            <Grid item xs={8}>
                              {day.format("YYYY-MM-DD")}{" "}
                              {translateToHungarian(day.format("dddd"))}
                            </Grid>
                            {!(dayData.id > 0) && (
                              <Grid item xs={4} key={day}>
                                {frontActions({
                                  id: 0,
                                  description: "",
                                  startOfWork: new Date(
                                    new Date(day).setHours(8, 0, 0, 0)
                                  ),
                                  endOfWork: new Date(
                                    new Date(day).setHours(16, 30, 0, 0)
                                  ),
                                  startOfLunch: new Date(
                                    new Date(day).setHours(12, 0, 0, 0)
                                  ),
                                  endOfLunch: new Date(
                                    new Date(day).setHours(12, 30, 0, 0)
                                  ),
                                  isSickHoliday: false,
                                  isHoliday: false,
                                })}
                              </Grid>
                            )}
                          </Grid>
                        </TableCell>
                        <TableCell align="center">
                          {!(
                            dayData.isSickHoliday ||
                            dayData.isHoliday ||
                            dayData.other !== undefined
                          ) && formatDate(dayData.startOfWork)}
                        </TableCell>
                        <TableCell align="center">
                          {!(
                            dayData.isSickHoliday ||
                            dayData.isHoliday ||
                            dayData.other !== undefined
                          ) && formatDate(dayData.startOfLunch)}
                        </TableCell>
                        <TableCell align="center">
                          {!(
                            dayData.isSickHoliday ||
                            dayData.isHoliday ||
                            dayData.other !== undefined
                          ) && formatDate(dayData.endOfLunch)}
                        </TableCell>
                        <TableCell align="center">
                          {!(
                            dayData.isSickHoliday ||
                            dayData.isHoliday ||
                            dayData.other !== undefined
                          ) && formatDate(dayData.endOfWork)}
                        </TableCell>
                        <TableCell align="center">
                          {dayData.description}
                        </TableCell>
                        <TableCell align="center">
                          {dayData.id > 0 &&
                            behindActions(dayData)?.map((action) => action)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[rowsPerPage]}
            component="div"
            count={days.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, page) => {
              setPage(page);
            }}
          />
        </Grid>
      )}
      {isSmallScreen && (
        <Grid item xs={12}>
          <TableContainer>
            <Table>
              <TableBody>
                {days
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((day, index) => {
                    let dayData = data.find((d) =>
                      moment(d.startOfWork).isSame(day, "day")
                    );
                    if (!dayData) {
                      dayData = {
                        startOfWork: null,
                        startOfLunch: null,
                        endOfLunch: null,
                        endOfWork: null,
                        description: null,
                        isHolyday: false,
                        isSickHoliday: false,
                      };
                    }
                    const color = dayData.isHoliday
                      ? "#ff9800"
                      : dayData.isSickHoliday
                      ? "red"
                      : dayData.id > 0
                      ? "lightgreen"
                      : day.day() === 0 || day.day() === 6 // Check if day is weekend
                      ? "grey"
                      : "lightgrey";

                    return (
                      <Grid container pb={2}>
                        <Card
                          variant="outlined"
                          sx={{
                            margin: "auto",
                            transition: "0.3s",
                            width: "100%",
                            height: "100%",
                            borderRadius: "10px",
                          }}
                        >
                          <TableRow key={index} sx={{ backgroundColor: color }}>
                            <TableCell>
                              <Typography fontWeight={"bold"} fontSize={"17px"}>
                                Nap
                              </Typography>
                            </TableCell>
                            <TableCell align="center" width={"100%"}>
                              <Grid container>
                                <Grid item xs={12}>
                                  {day.format("YYYY-MM-DD")}{" "}
                                  {translateToHungarian(day.format("dddd"))}
                                </Grid>
                              </Grid>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography fontWeight={"bold"} fontSize={"17px"}>
                                Munkaidő kezdete{" "}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              {!(
                                dayData.isSickHoliday ||
                                dayData.isHoliday ||
                                dayData.other !== undefined
                              ) && formatDate(dayData.startOfWork)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography fontWeight={"bold"} fontSize={"17px"}>
                                Ebédidő{" "}
                              </Typography>
                              <Typography fontWeight={"bold"} fontSize={"17px"}>
                                -tól
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              {!(
                                dayData.isSickHoliday ||
                                dayData.isHoliday ||
                                dayData.other !== undefined
                              ) && formatDate(dayData.startOfLunch)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography fontWeight={"bold"} fontSize={"17px"}>
                                Ebédidő{" "}
                              </Typography>
                              <Typography fontWeight={"bold"} fontSize={"17px"}>
                                -ig
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              {!(
                                dayData.isSickHoliday ||
                                dayData.isHoliday ||
                                dayData.other !== undefined
                              ) && formatDate(dayData.endOfLunch)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography fontWeight={"bold"} fontSize={"17px"}>
                                Munkaidő Vége
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              {!(
                                dayData.isSickHoliday ||
                                dayData.isHoliday ||
                                dayData.other !== undefined
                              ) && formatDate(dayData.endOfWork)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography fontWeight={"bold"} fontSize={"17px"}>
                                Megjegyzés
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <TextField
                                value={
                                  dayData.id > 0 ? dayData.description : ""
                                }
                                multiline
                                disabled
                                sx={{ border: 0 }}
                                fullWidth
                                variant="standard"
                                InputProps={{
                                  disableUnderline: true,
                                }}
                                inputProps={{
                                  min: 0,
                                  style: { textAlign: "center" },
                                }}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="center" colSpan={2}>
                              {!(dayData.id > 0) && (
                                <Grid item xs={12}>
                                  {frontActions({
                                    id: 0,
                                    description: "",
                                    startOfWork: new Date(
                                      new Date(day).setHours(8, 0, 0, 0)
                                    ),
                                    endOfWork: new Date(
                                      new Date(day).setHours(17, 0, 0, 0)
                                    ),
                                    startOfLunch: new Date(
                                      new Date(day).setHours(12, 0, 0, 0)
                                    ),
                                    endOfLunch: new Date(
                                      new Date(day).setHours(12, 30, 0, 0)
                                    ),
                                    isSickHoliday: false,
                                    isHoliday: false,
                                  })?.map((action) => action)}
                                </Grid>
                              )}
                              {dayData.id > 0 &&
                                behindActions(dayData)?.map((action) => action)}
                            </TableCell>
                          </TableRow>
                        </Card>
                      </Grid>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[rowsPerPage]}
            component="div"
            count={days.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, page) => {
              setPage(page);
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default DateTableComponent;
