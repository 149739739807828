import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

export interface Customer {
  id: number;
  isActive: boolean;
  contactMode: string;
  source: string;
  name: string;
  fullAddress: string;
  email: string;
  isEmailValid: boolean;
  phoneNumber: string;
  isPhoneNumberValid: boolean;
  comment: string;
  activities: string;
  contactQuality: string;
  firstContact: string;
  lastContact: string;
  nextContact: string;
  conversionDate: string;
  clientId: string;
  salesPhase: string;
}

interface Props {
  entity: Customer;
  onSubmit: (
    values: Customer,
    setSubmitting: (isSubmitting: boolean) => void
  ) => void;
  errors: string[];
  handleCancel: () => void;
  readonly?: boolean;
}

const CustomerForm = (props: Props) => {
  const { entity, onSubmit, errors, handleCancel, readonly = false } = props;
  
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Kötelező mező!'),
  });

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        handleChange,
        setFieldValue,
        errors: validationErrors,
        isValid,
      }) => (
        <Form noValidate>
          <Paper>
            <Grid container justifyContent="left" spacing={2} p={5}>
              <h2>Ügyfél {values.id > 0 ? 'szerkesztése' : 'létrehozása'}</h2>
              <Grid xs={12} item pt={5}>
                <Box color="red">
                  {errors.map((error) => (
                    <li>{error}</li>
                  ))}
                </Box>
              </Grid>

              <Grid
                container
                item
                pt={3}
                justifyContent="left"
                alignItems="center"
              >
                <Grid item xs={12} sm={6} md={4} lg={3} p={1}>
                  <TextField
                    fullWidth
                    label="Név"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    error={touched.name && Boolean(validationErrors.name)}
                    helperText={touched.name && validationErrors.name}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} p={1}>
                  <TextField
                    fullWidth
                    label="Kapcsolat minősége"
                    name="contactQuality"
                    value={values.contactQuality}
                    onChange={handleChange}
                    error={
                      touched.contactQuality &&
                      Boolean(validationErrors.contactQuality)
                    }
                    helperText={
                      touched.contactQuality && validationErrors.contactQuality
                    }
                  />{' '}
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.isActive}
                        onChange={(e) =>
                          setFieldValue('isActive', e.target.checked)
                        }
                        name="isActive"
                        color="primary"
                      />
                    }
                    label="Aktív ügyfél"
                  />
                </Grid>
                <Grid item xs={12} p={1}>
                  <TextField
                    fullWidth
                    label="Cím"
                    name="fullAddress"
                    value={values.fullAddress}
                    onChange={handleChange}
                    error={
                      touched.fullAddress &&
                      Boolean(validationErrors.fullAddress)
                    }
                    helperText={
                      touched.fullAddress && validationErrors.fullAddress
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} p={1}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    error={touched.email && Boolean(validationErrors.email)}
                    helperText={touched.email && validationErrors.email}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.isEmailValid}
                        onChange={(e) =>
                          setFieldValue('isEmailValid', e.target.checked)
                        }
                        name="isEmailValid"
                        color="primary"
                      />
                    }
                    label="Telefonszám érvényes"
                  />
                </Grid>
                <Grid item xs={12} sm={6} p={1}>
                  <TextField
                    fullWidth
                    label="Telefonszám"
                    name="phoneNumber"
                    value={values.phoneNumber}
                    onChange={handleChange}
                    error={
                      touched.phoneNumber &&
                      Boolean(validationErrors.phoneNumber)
                    }
                    helperText={
                      touched.phoneNumber && validationErrors.phoneNumber
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.isPhoneNumberValid}
                        onChange={(e) =>
                          setFieldValue('isPhoneNumberValid', e.target.checked)
                        }
                        name="isPhoneNumberValid"
                        color="primary"
                      />
                    }
                    label="Telefonszám érvényes"
                  />
                </Grid>

                <Grid item xs={12} p={1}>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    label="Megjegyzés"
                    name="comment"
                    value={values.comment}
                    onChange={handleChange}
                    error={touched.comment && Boolean(validationErrors.comment)}
                    helperText={touched.comment && validationErrors.comment}
                  />
                </Grid>
                <Grid item xs={12} p={1}>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    label="Tevékenységek"
                    name="activities"
                    value={values.activities}
                    onChange={handleChange}
                    error={
                      touched.activities && Boolean(validationErrors.activities)
                    }
                    helperText={
                      touched.activities && validationErrors.activities
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3} p={1}>
                  <DatePicker
                    label="Első kapcsolatfelvétel"
                    value={values.firstContact}
                    onChange={(date) => setFieldValue('firstContact', date)}
                    renderInput={(props) => <TextField {...props} />}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} p={1}>
                  <DatePicker
                    label="Utolsó kapcsolatfelvétel"
                    value={values.lastContact}
                    onChange={(date) => setFieldValue('lastContact', date)}
                    renderInput={(props) => <TextField {...props} />}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} p={1}>
                  <DatePicker
                    label="Következő kapcsolatfelvétel"
                    value={values.nextContact}
                    onChange={(date) => setFieldValue('nextContact', date)}
                    renderInput={(props) => <TextField {...props} />}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} p={1}>
                  <DatePicker
                    label="Konverzió dátuma"
                    value={values.conversionDate}
                    onChange={(date) => setFieldValue('conversionDate', date)}
                    renderInput={(props) => <TextField {...props} />}
                  />
                </Grid>
                <Grid item xs={12} p={1}>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    label="Értékesítési fázis"
                    name="salesPhase"
                    value={values.salesPhase}
                    onChange={handleChange}
                    error={
                      touched.salesPhase && Boolean(validationErrors.salesPhase)
                    }
                    helperText={
                      touched.salesPhase && validationErrors.salesPhase
                    }
                  />
                </Grid>
              </Grid>

              <Grid container item pt={3} justifyContent="left">
                {!readonly && (
                  <Grid item p={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      Mentés
                    </Button>
                  </Grid>
                )}
                <Grid item p={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleCancel}
                  >
                    Mégse
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default CustomerForm;
