import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Visibility,
} from '@mui/icons-material';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
} from '@mui/x-data-grid';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataList from '../../../components/DataList';
import useCheckPermission from '../../../hooks/useCheckPermission';
import useConfirmDeleteDialog from '../../../hooks/useConfirmDeleteDialog';
import taskLogService from '../../../services/erp/taskLogService';
import TaskLogFormDialog from './TaskLogFormDialog';
import taskService from '../../../services/erp/taskService';
import { parseJSON } from 'date-fns';

export default function TaskLogDialog(props: any) {
  const { open, setOpen, taskId } = props;

  const [rows, setRows] = React.useState<any[]>([]);
  const [readonly, setReadonly] = React.useState<boolean>(false);
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const dispatch = useDispatch<any>();
  const user = useSelector((state: any) => state.user.userInfo);
  const [workHourSum, setWorkHourSum] = React.useState<number>(0);

  const [taskLogFormDialogState, setTaskLogFormDialogState] =
    React.useState<any>({
      open: false,
      readOnly: false,
      entity: {
        id: 0,
        taskId: 0,
        description: '',
        workHours: 0,
        isTaskCompleted: false,
      },
    });

  const { enqueueSnackbar } = useSnackbar();
  const { checkPermission } = useCheckPermission();

  const handleClose = () => {
    setOpen({ open: false, taskId: null });
    setRows([]);
  };

  useEffect(() => {
    if (taskId > 0) {
      dispatch({ type: 'SHOW_QUERY' });
      taskLogService
        .list(taskId)
        .then((response) => {
          if (response.canceled) return;
          if (!response.hasError) {
            setRows(response.records);
            setWorkHourSum(
              response.records.reduce(
                (sum, current) => sum + current.workHours,
                0
              )
            );
          } else {
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
          }
        })
        .finally(() => dispatch({ type: 'HIDE' }));
    }
  }, [taskId]);

  useEffect(() => {
    if (taskId > 0 && user.userId > 0) {
      dispatch({ type: 'SHOW_QUERY' });
      taskService
        .get(taskId)
        .then((response) => {
          if (response.canceled) return;
          if (!response.hasError) {
            setReadonly(response.result.createdById !== user.userId);
          } else {
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
          }
        })
        .finally(() => dispatch({ type: 'HIDE' }));
    }
  }, [taskId, user]);

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];

    let readonly = !checkPermission(['TaskLogEdit']);
    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          readonly ? (
            <Tooltip title="Megtekintés">
              <Visibility />
            </Tooltip>
          ) : (
            <Tooltip title="Szerkesztés">
              <EditIcon />
            </Tooltip>
          )
        }
        label={readonly ? 'Megtekintés' : 'Szerkesztés'}
        onClick={() => {
          setTaskLogFormDialogState({
            open: true,
            entity: params.row,
            readOnly: readonly,
          });
        }}
      />
    );

    if (checkPermission(['TaskLogDelete'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Törlés">
              <DeleteIcon />
            </Tooltip>
          }
          label="Törlés"
          onClick={() =>
            setParams({
              open: true,
              name: '',
              onConfirm: async () => handleDelete(params.row.id),
            })
          }
        />
      );
    }

    return actions;
  };

  const handleDelete = (id: number) => {
    taskLogService.delete(id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres törlés', {
          variant: 'success',
        });
        setRows(rows.filter((row) => row.id !== id));
      }
    });
  };

  const columns: GridColDef[] = [
    {
      field: 'date',
      headerName: 'Dátum',
      valueFormatter(params) {
        return parseJSON(params.value).toLocaleDateString();
      },
      flex: 200,
    },
    {
      field: 'employeeName',
      headerName: 'Dolgozó',
      flex: 100,
    },
    {
      field: 'description',
      headerName: 'Leírás',
      flex: 100,
    },
    {
      field: 'workHours',
      headerName: 'Óraszám',
      flex: 50,
    },
    {
      field: 'isTaskCompleted',
      headerName: 'Kész',
      valueFormatter: (params) => {
        return params.value ? 'Igen' : 'Nem';
      },
      flex: 100,
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 100,
      disableExport: true,
      getActions: getActions,
    } as GridActionsColDef,
  ];

  return (
    <Grid>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitle>Feladat napló:</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} style={{ paddingTop: 10 }}>
            <Grid
              item
              xs={checkPermission(['TaskLogCreate']) && !readonly ? 10 : 12}
            >
              <Typography variant="subtitle1" gutterBottom component="div">
                Összesen: {workHourSum} óra
              </Typography>
            </Grid>
            {checkPermission(['TaskLogCreate']) && !readonly && (
              <Grid item xs={2} textAlign={'right'}>
                <Tooltip title="Feladat napló létrehozása">
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setTaskLogFormDialogState({
                        open: true,
                        readOnly: false,
                        entity: {
                          id: 0,
                          taskId: taskId,
                          description: '',
                          workHours: 0,
                          isTaskCompleted: false,
                        },
                      });
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
            <Grid item xs={12}>
              <DataList
                rows={rows}
                columns={columns}
                getRowId={(row) => row.id}
                localStorageKey={'TaskLogDialog'}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined" onClick={handleClose}>
            Mégse
          </Button>
        </DialogActions>
        <TaskLogFormDialog
          open={taskLogFormDialogState.open}
          setOpen={setTaskLogFormDialogState}
          taskId={taskId}
          entity={taskLogFormDialogState.entity}
          readOnly={taskLogFormDialogState.readOnly}
          updateRows={(result) => {
            if (taskId > 0) {
              dispatch({ type: 'SHOW_QUERY' });
              taskLogService
                .list(taskId)
                .then((response) => {
                  if (response.canceled) return;
                  if (!response.hasError) {
                    setRows(response.records);
                    setWorkHourSum(
                      response.records.reduce(
                        (sum, current) => sum + current.workHours,
                        0
                      )
                    );
                  } else {
                    enqueueSnackbar(response.errorMessages.join(','), {
                      variant: 'error',
                    });
                  }
                })
                .finally(() => dispatch({ type: 'HIDE' }));
            }
          }}
        />
      </Dialog>
    </Grid>
  );
}
