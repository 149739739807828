import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CollapsePaper } from '../../../components/CollapsePaper';
import EntityNavigator from '../../../components/EntityNavigator';
import '../../../css/dataGrid.css';
import documentBlockService from '../../../services/billing/documentBlockService';
import clientService from '../../../services/crm/clientService';
import serviceService from '../../../services/erp/serviceService';
import { ClientTypes } from '../../../types/ClientTypes';
import { EntityTypes } from '../../../types/EntityTypes';
import SelectBillingServices from './SelectBillingServices';

import { Currency } from '../../../types/Currency';
import {
  setConversionRate,
  setCurrency,
} from '../../../stateManagement/actions/itemActions';
import CurrencyPicker from '../../../components/CurrencyPicker';
import { formatCurrency } from '../../../utils/valueFormatters';
import { DocumentBlockTypes } from '../../../types/DocumentBlockTypes';
import contractService from '../../../services/HR/contractService';
import { DocumentLanguage } from '../../../types/DocumentLanguage';
import { send } from 'process';

export interface BillingoInvoiceProps {
  vendor_id: string;
  clientId: number;
  block_id: number;
  fulfillment_date: Date;
  due_date: Date;
  payment_method: string;
  electronic: boolean;
  paid: boolean;
  items: [
    {
      product_id: number;
      quantity: number;
      comment: string;
    },
  ];
  comment: string;
  discount: number;
  onCreateInvoice: (data: any) => void;
  onClose: () => void;
  moduleSettings: any;
}

export default function BillingoInvoiceForm(props: BillingoInvoiceProps) {
  const {
    vendor_id,
    clientId,
    block_id,
    fulfillment_date,
    due_date,
    payment_method,
    electronic,
    paid,
    items,
    comment,
    discount,
    onCreateInvoice,
    onClose,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const [client, setClient] = useState<any>({});
  const [services, setServices] = useState<any[]>([]);
  const [clients, setClients] = useState<any[]>([]);
  const [documentBlocks, setDocumentBlocks] = useState<any[]>([]);
  const [contract, setContract] = useState<any>({});
  const user = useSelector((state: any) => state.user.userInfo);

  const [values, setValues] = useState<any>({
    vendor_id,
    clientId,
    block_id,
    fulfillment_date,
    due_date,
    payment_method,
    electronic,
    paid,
    items,
    comment,
    sendEmail: true,
    discount,
    conversion_rate: 1,
    currency: Currency.HUF,
    language: DocumentLanguage.Hu,
  });
  const address = client?.addresses?.find((x: any) => x.isBillingAddress) ?? {};
  const paymentTypes = ['Készpénz', 'Bankkártya', 'Átutalás'];
  const [finalPrice, setFinalPrice] = useState<number>(0);
  const [now, setNow] = useState(new Date());

  useEffect(() => {
    let price = values?.items?.reduce((a, x) => {
      let foundService = services?.find((y) => y.id === x.product_id);
      return a + foundService?.servicePriceGross * x?.quantity;
    }, 0);

    setFinalPrice(price * (1 - values?.discount / 100));
  }, [values, services]);

  useEffect(() => {
    setValues((prev) => {
      return { ...prev, electronic: client.needElectronicInvoice };
    });
  }, [electronic, client]);

  const getClient = () => {
    if (clientId) {
      clientService.get(clientId).then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          setClient(response.result);
        }
      });
    }
  };
  useEffect(() => {
    getClient();
  }, [clientId]);

  useEffect(() => {
    if (clientId) {
      contractService.list(clientId).then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          setContract(response.records[0]);
          setValues((prev) => ({
            ...prev,
            discount: response.records[0]?.discount ?? 0,
          }));
        }
      });
    }
  }, [clientId]);

  useEffect(() => {
    serviceService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setServices(response.records);
      }
    });
  }, []);

  useEffect(() => {
    if (user) {
      documentBlockService.list(DocumentBlockTypes.Invoice).then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          setDocumentBlocks(response.records);
        }
      });
    }
  }, [user]);

  useEffect(() => {
    let abort = new AbortController();
    dispatch({ type: 'SHOW_QUERY' });
    clientService
      .list(true, null, abort.signal, ClientTypes.Distributor)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          setClients(response.records);
        }
      })
      .finally(() => dispatch({ type: 'HIDE' }));
    return () => {
      abort.abort();
    };
  }, []);

  return (
    <Grid container sx={{ backgroundColor: '#EFEFEF' }} spacing={2}>
      <Grid item xs={6}>
        <CollapsePaper
          title={
            <Grid container>
              <Grid item>
                <Typography
                  textAlign="left"
                  fontWeight={'bold'}
                  fontSize={20}
                  sx={{ mt: 2 }}
                >
                  Ügyfél adatai:
                </Typography>{' '}
              </Grid>
              <Grid item>
                <EntityNavigator
                  entityType={EntityTypes.ClientEdit}
                  entityId={client.id}
                  value={'Szerkesztés'}
                  showIcon
                  onSave={() => {
                    getClient();
                  }}
                />
              </Grid>
            </Grid>
          }
          collapsed={false}
          children={
            <Grid container p={2} justifyContent={'center'} spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Név"
                  value={
                    client.isPrivatePerson
                      ? client.name.fullName
                      : client.companyName
                  }
                  InputLabelProps={{ shrink: true }}
                  disabled
                />
              </Grid>
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={6}>
                  <Autocomplete
                    disablePortal
                    id="phone"
                    value={
                      client.contact?.phone
                        ? client.contact?.phone.split(';')
                        : []
                    }
                    onChange={(event, value) => {
                      setClient((prev) => ({
                        ...prev,
                        contact: {
                          ...prev.contact,
                          phone: value.join(';'),
                        },
                      }));
                    }}
                    multiple
                    freeSolo
                    autoSelect
                    renderInput={(params) => (
                      <TextField {...params} fullWidth label="Telefonszám" />
                    )}
                    options={[]}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    disablePortal
                    id="email"
                    value={
                      client.contact?.email
                        ? client.contact?.email.split(';')
                        : []
                    }
                    onChange={(event, value) => {
                      setClient((prev) => ({
                        ...prev,
                        contact: {
                          ...prev.contact,
                          email: value.join(';'),
                        },
                      }));
                    }}
                    multiple
                    freeSolo
                    autoSelect
                    renderInput={(params) => (
                      <TextField {...params} fullWidth label="E-mail cím" />
                    )}
                    options={[]}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Adószám"
                  value={client.taxNumber}
                  InputLabelProps={{ shrink: true }}
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Számlázási cím"
                  value={address.fullAddress}
                  InputLabelProps={{ shrink: true }}
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Megjegyzés"
                  value={client.comment}
                  disabled
                  multiline
                  minRows={2}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              {client.hasSpecialNeeds && (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Speciális igények"
                    value={client.specialNeeds}
                    disabled
                    multiline
                    minRows={2}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              )}
            </Grid>
          }
        />
      </Grid>
      <Grid item xs={6}>
        <CollapsePaper
          title={
            <Typography
              textAlign="left"
              fontWeight={'bold'}
              fontSize={20}
              sx={{ mt: 2 }}
            >
              Számla adatai:
            </Typography>
          }
          collapsed={false}
          children={
            <Grid container p={2} spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="serviceProviderId"
                  value={values.vendor_id ?? ''}
                  onChange={(event, value) => {
                    setValues((prev) => ({
                      ...prev,
                      vendor_id: value,
                    }));
                  }}
                  getOptionLabel={(option) =>
                    clients?.find((g) => g.id === option)?.companyName ?? ''
                  }
                  options={clients
                    ?.filter((x) => x.clientType === ClientTypes.Distributor)
                    ?.map((g) => g.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      required
                      label="Számlakibocsátó"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="documentBlockId"
                  value={values?.block_id ?? ''}
                  onChange={(event, value) => {
                    setValues((prev) => ({
                      ...prev,
                      block_id: value,
                    }));
                  }}
                  getOptionLabel={(option) =>
                    documentBlocks?.find((g) => g.id === option)?.name ?? ''
                  }
                  options={documentBlocks?.map((g) => g.id)}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth label="Bizonylattömb" />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Autocomplete
                  disablePortal
                  id="paymentMethod"
                  value={values?.payment_method}
                  onChange={(event, value) => {
                    setValues((prev) => ({
                      ...prev,
                      payment_method: value,
                    }));
                    if (value === 'Átutalás') {
                      setValues((prev) => ({
                        ...prev,
                        paid: false,
                        due_date: moment(now).add(15, 'days').toDate(),
                      }));
                    } else {
                      setValues((prev) => ({
                        ...prev,
                        paid: true,
                      }));
                    }
                  }}
                  options={paymentTypes?.map((g) => g)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      fullWidth
                      label="Fizetési mód"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={'auto'}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.paid}
                      onChange={(e, checked) =>
                        setValues((prev) => ({ ...prev, paid: checked }))
                      }
                      color="primary"
                    />
                  }
                  label="Kifizetve"
                />
              </Grid>
              <Grid item xs={12} md={'auto'}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.electronic}
                      onChange={(e, checked) =>
                        setValues((prev) => ({ ...prev, electronic: checked }))
                      }
                      color="primary"
                    />
                  }
                  label="E-számla?"
                />
              </Grid>
              <Grid item xs={12} md={'auto'}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.sendEmail}
                      onChange={(e, checked) =>
                        setValues((prev) => ({ ...prev, sendEmail: checked }))
                      }
                      color="primary"
                    />
                  }
                  label="Email küldése"
                />
              </Grid>
              <Grid item xs={12} md={'auto'}>
                <DatePicker
                  label="Teljesítés dátuma"
                  value={values.fulfillment_date ?? ''}
                  onChange={(date) => {
                    setValues((prev) => ({
                      ...prev,
                      fulfillment_date,
                    }));
                  }}
                  renderInput={(props) => <TextField {...props} />}
                />
              </Grid>
              <Grid item xs={12} md={'auto'}>
                <DatePicker
                  label="Lejárati dátum"
                  value={values.due_date ?? ''}
                  disabled={values.payment_method !== 'Átutalás'}
                  onChange={(date) => {
                    setValues((prev) => ({
                      ...prev,
                      due_date: date,
                    }));
                  }}
                  renderInput={(props) => <TextField {...props} />}
                />
                <Button
                  disabled={values.payment_method !== 'Átutalás'}
                  onClick={() => {
                    setValues((prev) => ({
                      ...prev,
                      due_date: moment(new Date()).add(8, 'days').toDate(),
                    }));
                  }}
                >
                  +8
                </Button>
                <Button
                  disabled={values.payment_method !== 'Átutalás'}
                  onClick={() => {
                    setValues((prev) => ({
                      ...prev,
                      due_date: moment(new Date()).add(15, 'days').toDate(),
                    }));
                  }}
                >
                  +15
                </Button>
                <Button
                  disabled={values.payment_method !== 'Átutalás'}
                  onClick={() => {
                    setValues((prev) => ({
                      ...prev,
                      due_date: moment(new Date()).add(30, 'days').toDate(),
                    }));
                  }}
                >
                  +30
                </Button>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Kedvezmény"
                  value={values.discount}
                  type="number"
                  onChange={(e) => {
                    if (
                      parseFloat(e.target.value) >= 0 &&
                      parseFloat(e.target.value) <= 100
                    ) {
                      setValues((prev) => ({
                        ...prev,
                        discount: e.target.value,
                      }));
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <CurrencyPicker
                  currency={values.currency}
                  setCurrency={(value: Currency) => {
                    setValues((prev) => ({ ...prev, currency: value }));
                  }}
                  conversionRate={values.conversion_rate}
                  setConversionRate={(value: number) => {
                    setValues((prev) => ({ ...prev, conversion_rate: value }));
                  }}
                  localStorageKey="BillingoInvoiceForm"
                  language={values.language}
                  setLanguage={(value: DocumentLanguage) => {
                    setValues((prev) => ({ ...prev, language: value }));
                  }}
                  disableLanguage={false}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Megjegyzés"
                  value={values.comment}
                  onChange={(e) => {
                    setValues((prev) => ({ ...prev, comment: e.target.value }));
                  }}
                  multiline
                  minRows={2}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <CollapsePaper
          title={
            <Typography
              textAlign="left"
              fontWeight={'bold'}
              fontSize={20}
              sx={{ mt: 2 }}
            >
              Termékek:
            </Typography>
          }
          collapsed={false}
          children={
            <Grid container p={2} spacing={2}>
              <Grid item xs={12}>
                <SelectBillingServices
                  services={services}
                  values={values.items}
                  currency={values.currency}
                  conversionRate={values.conversion_rate}
                  setValues={(items: any) => {
                    setValues((prev) => ({ ...prev, items }));
                  }}
                  disableAddProduct
                  disableRemoveProduct
                />
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={'auto'}>
                  <Typography variant="h5">Összesen(Bruttó):</Typography>
                </Grid>
                <Grid item xs={'auto'}>
                  <Typography>
                    {formatCurrency(
                      finalPrice / values.conversion_rate,
                      values.currency
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          }
        />
      </Grid>
      <Grid item xs={12} container spacing={2} justifyContent="flex-end">
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            disabled={!(values.block_id > 0) || !values.payment_method}
            onClick={() => {
              onCreateInvoice(values);
            }}
          >
            Számla kiállítása
          </Button>
        </Grid>
        <Grid item>
          <Button color="primary" variant="outlined" onClick={() => onClose()}>
            Mégse
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
