import axios from "axios";
import { postData } from "./axiosUtils";

const functionDescriptorService = {
  list: () => {
    return axios.get("/api/functionDescriptor/list").then((response) => {
      return response.data;
    });
  },
  save: (command) => {
    return postData({ url: "/api/functionDescriptor/save", data: command });
  },
};

export default functionDescriptorService;
