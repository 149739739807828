import SunEditor from 'suneditor-react';
import juice from 'juice';
import 'suneditor/dist/css/suneditor.min.css';
import sunEditorCss from '../../../css/suneditor';
import { useState, useRef, useEffect } from 'react';
import SunEditorCore from 'suneditor/src/lib/core';

const SunEditorWithCustomButtons = (props: any) => {
  const { values, setFieldValue, readonly, properties } = props;
  const [suneditorCss, setSuneditorCss] = useState<string>(sunEditorCss);
  const editor = useRef<SunEditorCore>();

  const getSunEditorInstance = (sunEditor: SunEditorCore) => {
    editor.current = sunEditor;
  };

  if (!properties || properties.length === 0) return;

  var plugin_submenu2 = () => {
    return {
      name: 'custom_plugin_submenu2',
      display: 'submenu',
      title: 'Dinamikus paraméterek',
      buttonClass: '',
      innerHTML: '@',

      add: function (core, targetElement) {
        const context = core.context;
        context.customSubmenu = {
          targetButton: targetElement,
          textElement: null,
          currentSpan: null,
        };

        let listDiv = this.setSubmenu(core);
        context.customSubmenu.textElement = listDiv.querySelector('input');

        // Append event listeners to each button dynamically
        listDiv.querySelectorAll('.se-dictionary-btn').forEach((button) => {
          button.addEventListener('click', this.onClick.bind(core));
        });

        core.initMenuTarget(this.name, targetElement, listDiv);
      },

      setSubmenu: (core: any) => {
        const listDiv = core.util.createElement('DIV');
        listDiv.className = 'se-menu-container se-submenu se-list-layer';
        listDiv.style.padding = '0';

        let listHTML =
          '<div class="se-list-inner" style="padding: 0; margin: 0; box-sizing: border-box;">';
        listHTML +=
          '<ul class="se-list-basic" style="padding: 10px; margin: 0; list-style: none; background-color: #C5C4C4FF; border-radius: 5px; box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);">';

        // Iterate through the dictionary and create buttons for each key
        Object.keys(properties).forEach((key) => {
          listHTML += `
      <li style="list-style: none; margin-bottom: 10px; padding: 0;">
        <button style="background-color: #FFFFFFFF; color: black; padding: 10px 15px; border-radius: 5px; border: none; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); cursor: pointer; transition: background-color 0.3s ease, transform 0.2s; width: 100%;" type="button" class="se-dictionary-btn" data-key="${key}" title="${key}">
          <strong>${properties[key]}</strong>
        </button>
      </li>
    `;
        });

        listHTML += '</ul></div>';
        listDiv.innerHTML = listHTML;

        return listDiv;
      },

      onClickRemove: function () {
        const span = this.context.customSubmenu.currentSpan;
        if (span) {
          this.util.removeItem(span);
          this.context.customSubmenu.currentSpan = null;

          this.submenuOff();
          this.focus();
        }
      },

      onClick: function (event) {
        const key = event.target.getAttribute('data-key'); // Get the key from the button
        const valueToInsert = `{{${key}}}`; // Assuming you want to insert it wrapped in curly braces
        // Insert the value into the editor at the current cursor position
        if (editor.current) {
          editor.current.insertHTML(valueToInsert); // Use insertHTML to paste the content
        }
        this.submenuOff(); // Close the submenu after insertion
        this.focus(); // Bring focus back to the editor
      },

      active: function (element) {
        // If the current element has the 'se-dictionary-key' class, we activate the button
        if (element && this.util.hasClass(element, 'se-dictionary-key')) {
          this.util.addClass(this.context.customSubmenu.targetButton, 'active');
          return true;
        }

        // Otherwise, deactivate the button
        this.util.removeClass(
          this.context.customSubmenu.targetButton,
          'active'
        );
        return false;
      },

      on: function () {
        if (this.context.customSubmenu.textElement) {
          this.context.customSubmenu.textElement.focus();
        }
      },
    };
  };

  return (
    <SunEditor
      getSunEditorInstance={getSunEditorInstance}
      width="100%"
      height="500px"
      setContents={values.body}
      placeholder="Please type here..."
      disable={readonly}
      defaultValue={values.body}
      onChange={(content: string) => {
        var result = juice(
          `<div class="sun-editor-editable">${content}</div>`,
          {
            extraCss: suneditorCss,
          }
        );
        setFieldValue('body', result);
      }}
      onImageUploadBefore={(files, info, uploadHandler) => {
        return null;
      }}
      setOptions={{
        plugins: [plugin_submenu2()],
        buttonList: [
          ['undo', 'redo'],
          ['font', 'fontSize', 'formatBlock'],
          ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
          ['fontColor', 'hiliteColor'],
          ['removeFormat'],
          '/', // line break
          ['outdent', 'indent'],
          ['align', 'horizontalRule', 'list', 'table'],
          ['link', 'image', 'video'],
          ['fullScreen', 'showBlocks', 'codeView'],
          ['preview', 'print'],
          ['math'],
          ['custom_plugin_submenu2'],
        ],
        katex: 'window.katex',
        templates: [],
      }}
    />
  );
};

export default SunEditorWithCustomButtons;
