import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import closingSheetService from '../services/erp/closingSheetService';

const useClosingSheetCreate = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();

  const createClosingSheet = (callback: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    closingSheetService
      .create()
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Elkezdte a munkanapot!', {
            variant: 'success',
          });
        }
        if (callback) callback();
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
      });
  };

  return { createClosingSheet };
};

export default useClosingSheetCreate;
