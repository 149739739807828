import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AcquisitionForm from './AcquisitionForm';
import { useNavigate } from 'react-router-dom';
import warehouseService from '../../services/wms/warehouseService';
import acquisitionService from '../../services/acquisitions/acquisitionService';
import itemService from '../../services/wms/itemService';
import clientService from '../../services/crm/clientService';
import useCheckPermission from '../../hooks/useCheckPermission';
import {
  reset,
  setForm,
} from '../../stateManagement/actions/acquisitionActions';
import expenseTypeService from '../../services/erp/expenseTypeService';
import taxTypeService from '../../services/erp/taxTypeService';
import { EntityStatuses } from '../../types/EntityStatuses';
import { set } from 'date-fns';
import { ClientTypes } from '../../types/ClientTypes';
import { Currency } from '../../types/Currency';
import { AcquisitionStatuses } from '../../types/AcquisitionStatuses';

const AcquisitionCreate = (props: any) => {
  const { form, acquisitionInProgress } = useSelector<any>(
    (state) => state.acquisition
  ) as any;

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<string[]>([]);
  const [expenseTypes, setExpenseTypes] = useState<any[]>([]);
  const [taxes, setTaxes] = useState<any[]>([]);
  const [warehouses, setWarehouses] = useState<any[]>([{ id: '', name: '' }]);
  const [items, setItems] = useState<any[]>([
    {
      id: '',
      name: '',
      itemNumber: '',
      externalItemNumber: '',
      description: '',
      otherCosts: 0,
      unitPrice: 0,
      status: AcquisitionStatuses.InProgress,
    },
  ]);
  const [clients, setClients] = useState<any[]>([{ id: '', companyName: '' }]);
  const [entity, setEntity] = useState<any>(
    acquisitionInProgress
      ? form
      : {
          id: 0,
          clientId: 0,
          companyId: 0,
          invoiceNumber: '',
          acquisitions: [],
          date: new Date(),
          paymentDeadLine: new Date(),
          paymentType: '',
          description: '',
          status: AcquisitionStatuses.InProgress,

          warehouseId: '',
          expenses: [],
          orderNumber: '',
          currency: Currency.HUF,
          conversionRate: 1,
        }
  );

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    expenseTypeService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setExpenseTypes(response.records);
      }
    });
  }, []);

  useEffect(() => {
    taxTypeService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setTaxes(response.records);
      }
    });
  }, []);

  useEffect(() => {
    let abort = new AbortController();
    dispatch({ type: 'SHOW_QUERY' });
    clientService
      .list(false, EntityStatuses.Approved, abort.signal, null)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          setClients(response.records);
          setEntity({
            ...entity,
            companyId: response.records.filter(
              (x) => x.clientType === ClientTypes.Distributor
            )[0].id,
          });
        }
      })
      .finally(() => dispatch({ type: 'HIDE' }));

    return () => {
      abort.abort();
    };
  }, []);

  useEffect(() => {
    warehouseService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setWarehouses(response.records);
      }
    });
  }, []);

  const loadItemNames = (
    clientId: number | null = null,
    itemGroupId: number | null = null
  ) => {
    itemService.listNames(false, clientId, itemGroupId).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setItems(response.records);
      }
    });
  };

  useEffect(() => {
    if (entity.clientId > 0) {
      loadItemNames(entity.clientId);
    } else {
      loadItemNames();
    }
  }, [entity]);

  useEffect(() => {
    let warehouseId = parseInt(
      window.localStorage.getItem('acquisition.warehouseId')
    );
    if (!isNaN(warehouseId)) {
      setEntity({ ...entity, warehouseId });
      dispatch(setForm(entity));
    }
  }, []);

  const onSubmit = (entity: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    acquisitionService
      .create(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });

          dispatch(reset());

          navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
      });
  };

  return (
    <AcquisitionForm
      entity={entity}
      readonly={!checkPermission(['AcquisitionCreate'])}
      items={items}
      clients={clients}
      warehouses={warehouses}
      taxes={taxes}
      expenseTypes={expenseTypes}
      setExpenseTypes={setExpenseTypes}
      onClientChange={(clientId) => loadItemNames(clientId)}
      onNewItemAdded={(item) => setItems((items) => [...items, item])}
      onClientAdded={(client) => {
        if (clients.find((x) => x.id === client.id)) {
          setClients((clients) =>
            clients.map((x) => (x.id === client.id ? client : x))
          );
        } else {
          setClients((clients) => [...clients, client]);
        }
      }}
      onSubmit={onSubmit}
      errors={errors}
    />
  );
};

export default AcquisitionCreate;
