export enum AttendenceType {
  HomeOffice = 1,
  RestDay = 2,
  NonWorkinkDay = 3,
  PaidHoliday = 4,
  DadHoliday = 5,
  BirthHoliday = 6,
  ChildrenHoliday = 7,
  StudyHoliday = 8,
  ExtraOrdinaryHoliday = 9,
  PaidVerifiedLeave = 10,
  SickHoliday = 11,
  InsurancePause = 12,
  WorkDay = 13,
}
