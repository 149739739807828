import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function RejectDialog(props: any) {
  const { open, setOpen, entity, onReject } = props;
  const [comment, setComment] = React.useState("");

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Termék rögzítés elutasítása</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Kérem adja meg az elutasítás okát!
        </DialogContentText>
        <TextField
          autoFocus
          value={comment}
          margin="dense"
          id="comment"
          label="Elutasítás oka"
          type="text"
          fullWidth
          required
          variant="standard"
          onChange={(e) => setComment(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            onReject(entity, comment);
            setComment("");
          }}
        >
          Elutasítás
        </Button>
        <Button color="primary" variant="contained" onClick={handleClose}>
          Mégse
        </Button>
      </DialogActions>
    </Dialog>
  );
}
