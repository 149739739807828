import { Menu, Search } from '@mui/icons-material';
import {
  Autocomplete,
  Grid,
  IconButton,
  InputBase,
  Paper,
  SwipeableDrawer,
  TextField,
  useMediaQuery,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useCheckPermission from '../../hooks/useCheckPermission';
import userService from '../../services/authority/userService';
import clientService from '../../services/crm/clientService';
import orderService from '../../services/sales/orderService';
import InvoiceTabPage from '../sales/orders/InvoiceTabPage';
import ChimneySweepingCertificatePage from '../chimneySweeping/chimneySweepingCertificate/ChimneySweepingCertificatePage';
import chimneyControlAppointmentService from '../../services/chimneySweeping/chimneyControlAppointmentService';
import chimneySweepingCertificateService from '../../services/chimneySweeping/chimneySweepingCertificateService';
import SearchBar from '../../components/SearchBar';

const ChimneySweepingCertificateQueryPage = () => {
  const { checkPermission } = useCheckPermission();
  const [users, setUsers] = React.useState<any[]>([]);
  const [clients, setClients] = React.useState<any[]>([]);
  const [rows, setRows] = React.useState<any[] | null>(null);
  const [drawer, setDrawer] = React.useState(false);
  const [query, setQuery] = React.useState<any>({
    queryContent: '',
    userId: null,
    clientId: null,
  });
  const { enqueueSnackbar } = useSnackbar();
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const dispatch = useDispatch();

  useEffect(() => {
    userService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setUsers(response.records);
      }
    });
  }, []);

  useEffect(() => {
    let abort = new AbortController();
    clientService.list(false, null, abort.signal).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setClients(response.records);
      }
    });

    return () => {
      abort.abort();
    };
  }, []);

  const handleSearch = (queryContent: string) => {
    if (queryContent === '') {
      enqueueSnackbar('Kérem szűkítse a keresést!', {
        variant: 'error',
      });
      return;
    }
    let abort = new AbortController();
    dispatch({ type: 'SHOW_QUERY', abort: abort });
    chimneySweepingCertificateService
      .query({ ...query, queryContent }, abort.signal)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          setRows(response.records);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        abort.abort();
      });
  };

  return (
    <Grid container xs={12} spacing={2} pt={2}>
      <Grid item xs={12}>
        <SearchBar setDrawer={setDrawer} handleSearch={handleSearch} />
      </Grid>
      <Grid item xs={12}>
        <ChimneySweepingCertificatePage externalRows={rows ?? []} />
      </Grid>
      <SwipeableDrawer
        anchor={isSmallScreen ? 'bottom' : 'right'}
        open={drawer}
        PaperProps={{
          sx: { backgroundColor: 'white', width: '30%' },
        }}
        onClose={() => {
          setDrawer(false);
        }}
        onOpen={() => {
          setDrawer(true);
        }}
      >
        <Grid container spacing={2} p={1} pt={2}>
          <Grid item xs={12}>
            <Autocomplete
              id="employeeId"
              disabled={!checkPermission(['IncomeQueryAll'])}
              value={query.userId}
              onChange={(event, value) => {
                setQuery({ ...query, userId: value });
              }}
              getOptionLabel={(option) => {
                if (option === null) {
                  return 'Mindegy';
                }
                let user = users.find((g) => g.id === option);
                return `${user?.fullName} (${user?.email ?? ''})`;
              }}
              options={[null, ...users.map((g) => g.id)]}
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Dolgozó" />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              id="clientId"
              disabled={!checkPermission(['ClientView'])}
              value={query.clientId}
              onChange={(event, value) => {
                setQuery({ ...query, clientId: value });
              }}
              getOptionLabel={(option) => {
                if (option === null) {
                  return 'Mindegy';
                }
                var found = clients.find((g) => g.id === option);
                if (found) {
                  return `${
                    found?.companyName === ''
                      ? found.name.fullName
                      : found.companyName
                  } (${found.taxNumber})}`;
                } else return 'Nem található';
              }}
              options={[null, ...clients.map((g) => g.id)]}
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Ügyfél" />
              )}
            />
          </Grid>
        </Grid>
      </SwipeableDrawer>
    </Grid>
  );
};

export default ChimneySweepingCertificateQueryPage;
