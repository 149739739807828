import React, { useState, useEffect } from "react";
import Quagga from "quagga";

const BarcodeScanner = ({ onBarcodeDetected, onClose }) => {
  useEffect(() => {
    let isScanning = true; // Flag to control scanning

    Quagga.init(
      {
        inputStream: {
          name: "Live",
          type: "LiveStream",
          target: document.querySelector("#barcode-scanner"),
          constraints: {
            facingMode: "environment", // Use the rear camera
          },
        },
        decoder: {
          readers: ["code_128_reader"],
        },
      },
      (err) => {
        if (err) {
          console.error("Quagga initialization failed:", err);
          return;
        }
        Quagga.start();

        Quagga.onDetected((result) => {
          if (isScanning) {
            isScanning = false; // Prevent multiple detections
            onBarcodeDetected(result.codeResult.code);
            Quagga.stop();
          }
        });
      }
    );

    return () => {
      isScanning = false; // Ensure scanning is stopped on unmount
      Quagga.stop();
    };
  }, [onBarcodeDetected]);
  return (
    <div id="barcode-scanner" style={{ width: "100%", height: "100%" }}></div>
  );
};

export default BarcodeScanner;
