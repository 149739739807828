import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Autocomplete,
} from '@mui/material';
import Button from '@mui/material/Button';
import OrderView from '../../pages/sales/orders/OrderView';
import { useEffect, useState } from 'react';
import serviceService from '../../services/erp/serviceService';
import documentBlockService from '../../services/billing/documentBlockService';
import { DocumentBlockTypes } from '../../types/DocumentBlockTypes';
import itemService from '../../services/wms/itemService';
import warehouseService from '../../services/wms/warehouseService';

export default function ChimneySweepingModuleSettingsForm(props: any) {
  const { settings, setSettings, readonly = false, userId } = props;
  const [services, setServices] = useState<any[]>([]);
  const [value, setValue] = useState<any>(settings);
  const [documentBlocks, setDocumentBlocks] = useState<any[]>([]);
  const [items, setItems] = useState<any[]>([]);
  const [warehouses, setWarehouses] = useState<any[]>([]);

  useEffect(() => {
    serviceService.list().then((response) => {
      if (response.canceled) return;
      if (!response.hasError) setServices(response.records);
      else {
      }
    });
  }, []);

  useEffect(() => {
    warehouseService.list(userId).then((response) => {
      if (response.canceled) return;
      if (!response.hasError) setWarehouses(response.records);
      else {
      }
    });
  }, []);

  useEffect(() => {
    itemService.list().then((response) => {
      if (response.canceled) return;
      if (!response.hasError) setItems(response.records);
      else {
      }
    });
  }, []);

  useEffect(() => {
    documentBlockService.list(DocumentBlockTypes.Invoice).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
      } else {
        setDocumentBlocks(response.records);
      }
    });
  }, []);

  useEffect(() => {
    setValue(settings);
  }, [settings]);

  const setValues = (values: any) => {
    setValue(values);
    setSettings(values);
  };

  return (
    <Grid container spacing={2} p={2}>
      <Grid item xs={12}>
        <Autocomplete
          disablePortal
          id="coItemWarehouseId"
          value={value.coItemWarehouseId}
          onChange={(event, value: any) => {
            setValues({ ...settings, coItemWarehouseId: value });
          }}
          getOptionLabel={(option) => {
            var service = warehouses?.find((g) => g.id === option);
            if (service == null || service === undefined) {
              return '';
            }

            return `${service.name}`;
          }}
          options={warehouses.map((option) => option.id)}
          disabled={readonly}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              label="Szén-monoxid érzékelő raktár"
              helperText="A szén-monoxid érzékelő ebből a raktárból kerül eladásra"
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          disablePortal
          id="coCheckingServiceId"
          value={value.coCheckingServiceId}
          onChange={(event, value: any) => {
            setValues({ ...settings, coCheckingServiceId: value });
          }}
          getOptionLabel={(option) => {
            var service = services?.find((g) => g.id === option);
            if (service == null || service === undefined) {
              return '';
            }

            return `${service.name}`;
          }}
          options={services.map((option) => option.id)}
          disabled={readonly}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              label="Szén-monoxid érzékelő karbantartásának szolgáltatása"
              helperText="A szén-monoxid érzékelő karbantartásakor ez az ár kerül felszámolásra"
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          disablePortal
          id="coServiceId"
          value={value.coServiceId}
          onChange={(event, value: any) => {
            setValues({ ...settings, coServiceId: value });
          }}
          getOptionLabel={(option) => {
            var service = services?.find((g) => g.id === option);
            if (service == null || service === undefined) {
              return '';
            }

            return `${service.name}`;
          }}
          options={services.map((option) => option.id)}
          disabled={readonly}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              label="Szén-monoxid érzékelő szolgáltatása"
              helperText="A szén-monoxid érzékelő a szolgáltatásból veszi az árat"
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          disablePortal
          id="coItemId"
          value={value.coItemId}
          onChange={(event, value: any) => {
            setValues({ ...settings, coItemId: value });
          }}
          getOptionLabel={(option) => {
            var service = items?.find((g) => g.id === option);
            if (service == null || service === undefined) {
              return '';
            }

            return `${service.name}`;
          }}
          options={items.map((option) => option.id)}
          disabled={readonly}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              label="Szén-monoxid érzékelő terméke"
              helperText="A szén-monoxid érzékelő eladásakor ez a termék kerül felhasználásra"
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          disablePortal
          id="serviceId"
          value={value.deliveryFee}
          onChange={(event, value: any) => {
            setValues({ ...settings, deliveryFee: value });
          }}
          getOptionLabel={(option) => {
            var service = services?.find((g) => g.id === option);
            if (service == null || service === undefined) {
              return '';
            }

            return `${service.name}`;
          }}
          options={services.map((option) => option.id)}
          disabled={readonly}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              label="Kiszállási díj szolgáltatás"
              helperText="A kiszállási díjakat ebből a szolgáltatásból veszi"
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          disablePortal
          id="documentBlockId"
          disabled={readonly}
          value={value.documentBlockId}
          onChange={(event, value) => {
            setValues({ ...settings, documentBlockId: value });
          }}
          getOptionLabel={(option) =>
            documentBlocks?.find((g) => g.id === option)?.name ?? ''
          }
          options={documentBlocks?.map((g) => g.id)}
          renderInput={(params) => (
            <TextField {...params} fullWidth label="Bizonylattömb" />
          )}
        />
      </Grid>
    </Grid>
  );
}
