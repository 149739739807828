import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useCheckPermission from '../../../hooks/useCheckPermission';
import holidayService from '../../../services/erp/holidayService';
import HolidayForm from './HolidayForm';
import { EntityStatuses } from '../../../types/EntityStatuses';
import userService from '../../../services/authority/userService';

const HolidayEdit = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const params = useParams();
  const [now] = useState(new Date());
  const [errors, setErrors] = useState<string[]>([]);
  const [users, setUsers] = useState<any[]>([]);

  const [entity, setEntity] = useState<any>({
    startDate: now,
    endDate: now,
    userId: 0,
    description: '',
  });
  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    holidayService.get(params.id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        setErrors(response.errorMessages);
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setEntity(response.result);
      }
    });
  }, [params.id]);

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    holidayService
      .update(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });
          navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        setSubmitting(false);
      });
  };

  useEffect(() => {
    userService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setUsers(response.records);
      }
    });
  }, []);

  return (
    <HolidayForm
      entity={entity}
      readonly={
        !checkPermission(['HolidayEdit']) ||
        entity.status !== EntityStatuses.SentToApproval
      }
      onSubmit={onSubmit}
      handleCancel={() => navigate(-1)}
      errors={errors}
      users={users}
    />
  );
};

export default HolidayEdit;
