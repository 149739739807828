import axios from 'axios';
import { postData } from './axiosUtils';
import { ModuleSettingsType } from '../types/ModuleSettingsType';

const moduleSettingsService = {
  get: (type: ModuleSettingsType, userId: number | null = null) => {
    let url = `/api/moduleSettings/get?type=${type}`;
    if (userId !== null) url += `&userId=${userId}`;
    return axios.get(url).then((response) => {
      return response.data;
    });
  },
  save: (command) => {
    return postData({ url: '/api/moduleSettings/save', data: command });
  },
};

export default moduleSettingsService;
