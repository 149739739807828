import {
  Button,
  TextField,
  Box,
  Grid,
  Paper,
  Autocomplete,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { DatePicker } from '@mui/x-date-pickers';
import { isValid } from 'date-fns';
import moment from 'moment';

const CarbonMonoxideSensorForm = (props: any) => {
  const {
    entity,
    onSubmit,
    errors,
    handleCancel,
    readonly = false,
    clients,
    services,
    items,
  } = props;

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        handleChange,
        setFieldValue,
        errors: validationErrors,
      }) => (
        <Form>
          {' '}
          <Paper>
            <Grid container justifyContent="left" spacing={2} p={5}>
              <h2>
                Szén-monoxid érzékelő{' '}
                {values.id > 0 ? 'szerkesztése' : 'létrehozása'}
              </h2>
              <Grid xs={12} item pt={5}>
                <Box color="red">
                  {errors.map((error) => (
                    <li>{error}</li>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.identifier}
                  onChange={(e) => {
                    setFieldValue('identifier', e.target.value);
                  }}
                  label="Azonosító"
                  required
                  disabled={readonly}
                  fullWidth
                  autoFocus
                  error={!!touched.identifier && !!validationErrors.identifier}
                  helperText={
                    !!touched.identifier &&
                    !!validationErrors.identifier &&
                    (validationErrors.identifier as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="clientId"
                  disabled={readonly}
                  value={values.clientId ?? ''}
                  onChange={(event, value) => {
                    setFieldValue('clientId', value);
                  }}
                  getOptionLabel={(option) => {
                    var found = clients.find((g) => g.id === option);
                    if (found) {
                      return `${found.id}.${
                        found?.companyName === ''
                          ? found.name.fullName
                          : found.companyName
                      } (${found.taxNumber})}`;
                    } else return 'Nem található';
                  }}
                  options={clients.map((g) => g.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Ügyfél"
                      required
                      fullWidth
                      error={!!touched.clientId && !!validationErrors.clientId}
                      helperText={
                        !!touched.clientId &&
                        !!validationErrors.clientId &&
                        (validationErrors.clientId as string)
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="addressId"
                  disabled={readonly}
                  value={values.addressId ?? ''}
                  onChange={(event, value) => {
                    setFieldValue('addressId', value);
                  }}
                  getOptionLabel={(option) => {
                    var found = clients
                      .find((g) => g.id === values.clientId)
                      ?.addresses.find((g) => g.id === option);
                    if (found) {
                      return found.fullAddress;
                    } else return 'Nem található';
                  }}
                  options={
                    clients
                      .find((g) => g.id === values.clientId)
                      ?.addresses.map((x) => x.id) ?? []
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Cím"
                      required
                      fullWidth
                      error={
                        !!touched.addressId && !!validationErrors.addressId
                      }
                      helperText={
                        !!touched.addressId &&
                        !!validationErrors.addressId &&
                        (validationErrors.addressId as string)
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="itemId"
                  disabled={readonly}
                  value={values.itemId}
                  onChange={(event, value) => {
                    setFieldValue('itemId', value);
                  }}
                  getOptionLabel={(option) => {
                    var found = items.find((g) => g.id === option);
                    if (found) {
                      return found.name;
                    } else return 'Nem található';
                  }}
                  options={items.map((g) => g.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Termék"
                      required
                      fullWidth
                      error={!!touched.itemId && !!validationErrors.itemId}
                      helperText={
                        !!touched.itemId &&
                        !!validationErrors.itemId &&
                        (validationErrors.itemId as string)
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="serviceId"
                  disabled={readonly}
                  value={values.serviceId ?? ''}
                  onChange={(event, value) => {
                    setFieldValue('serviceId', value);
                  }}
                  getOptionLabel={(option) => {
                    var found = services.find((g) => g.id === option);
                    if (found) {
                      return found.name;
                    } else return 'Nem található';
                  }}
                  options={services.map((g) => g.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Szolgáltatás"
                      required
                      fullWidth
                      error={
                        !!touched.serviceId && !!validationErrors.serviceId
                      }
                      helperText={
                        !!touched.serviceId &&
                        !!validationErrors.serviceId &&
                        (validationErrors.serviceId as string)
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={12} xl={'auto'} textAlign={'center'}>
                  <DatePicker
                    value={values.dateOfInstallation}
                    onChange={(value) => {
                      setFieldValue('dateOfInstallation', value);
                    }}
                    label="Telepítés dátuma"
                    inputFormat="yyyy-MM-dd"
                    componentsProps={{
                      actionBar: {
                        actions: ['clear'],
                      },
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Grid>
                <Grid item xs={12} xl={'auto'} textAlign={'center'}>
                  <DatePicker
                    value={values.lastControlYear}
                    onChange={(value) => {
                      setFieldValue('lastControlYear', value);
                    }}
                    label="Utolsó ellenőrzés dátuma"
                    inputFormat="yyyy-MM-dd"
                    componentsProps={{
                      actionBar: {
                        actions: ['clear'],
                      },
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Grid>
              </Grid>
              <Grid container item pt={3} justifyContent="left">
                {!readonly && (
                  <Grid item p={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      Mentés
                    </Button>
                  </Grid>
                )}
                <Grid item p={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleCancel}
                  >
                    Mégse
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default CarbonMonoxideSensorForm;
