import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useCheckPermission from '../../../hooks/useCheckPermission';
import irregularWorkdayService from '../../../services/erp/irregularWorkdayService';
import IrregularWorkdayForm from './IrregularWorkdayForm';

const IrregularWorkdayCreate = (props: any) => {
  const [now] = useState(new Date());
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<string[]>([]);

  const { checkPermission } = useCheckPermission();

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    irregularWorkdayService
      .create(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });
          navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        setSubmitting(false);
      });
  };

  return (
    <IrregularWorkdayForm
      entity={{ date: now, isWorkday: false }}
      readonly={!checkPermission(['IrregularWorkdayCreate'])}
      onSubmit={onSubmit}
      errors={errors}
      handleCancel={() => navigate(-1)}
    />
  );
};

export default IrregularWorkdayCreate;
