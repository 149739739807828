import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CustomerForm from './CustomerForm';
import { useNavigate, useParams } from 'react-router-dom';
import customerService from '../../../../services/crm/customerService';
import useCheckPermission from '../../../../hooks/useCheckPermission';

const CustomerEdit = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const params = useParams();
  const [errors, setErrors] = useState<string[]>([]);
  const { checkPermission } = useCheckPermission();

  const [entity, setEntity] = useState<any>({
    id: 0,
    isActive: true,
    contactMode: '',
    source: '',
    name: '',
    fullAddress: '',
    email: '',
    isEmailValid: false,
    phoneNumber: '',
    isPhoneNumberValid: false,
    comment: '',
    activities: '',
    contactQuality: '',
    firstContact: '',
    lastContact: '',
    nextContact: '',
    conversionDate: '',
    clientId: '',
    salesPhase: '',
  });

  useEffect(() => {
    customerService.get(params.id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        setErrors(response.errorMessages);
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setEntity(response.result);
      }
    });
  }, [params.id]);

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    customerService
      .update(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });

          navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        setSubmitting(false);
      });
  };

  return (
    <CustomerForm
      readonly={!checkPermission(['CustomerEdit'])}
      entity={entity}
      onSubmit={onSubmit}
      errors={errors}
      handleCancel={() => navigate(-1)}
    />
  );
};

export default CustomerEdit;
