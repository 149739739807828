import { Typography } from "@mui/material";

const LoggedOut = () => {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      Sikeres kijelentkezés!
    </Typography>
  );
};

export default LoggedOut;
