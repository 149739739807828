import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function InvoiceStornoDialog(props: any) {
  const { open, setOpen, entity, onStorno } = props;
  const [reason, setReason] = React.useState('');

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Sztornózás</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Miért szeretnéd a számlát sztornózni (maximum 255 karakter)?
        </DialogContentText>
        <TextField
          autoFocus
          value={reason}
          margin="dense"
          id="comment"
          label="Sztornózás oka"
          type="text"
          fullWidth
          variant="standard"
          onChange={(e) => setReason(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            onStorno(entity, reason);
            setReason('');
          }}
        >
          Sztornózás
        </Button>
        <Button color="primary" variant="contained" onClick={handleClose}>
          Mégse
        </Button>
      </DialogActions>
    </Dialog>
  );
}
