import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import { GridActionsColDef, GridColDef } from '@mui/x-data-grid';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import DataList from '../../../components/DataList';
import '../../../css/dataGrid.css';
import chimneySweepingCertificateService from '../../../services/chimneySweeping/chimneySweepingCertificateService';
import EntityNavigator from '../../../components/EntityNavigator';
import { EntityTypes } from '../../../types/EntityTypes';
import '../../../css/dataGrid.css';
import moment from 'moment';
import BillingoInvoiceForm, {
  BillingoInvoiceProps,
} from './BillingoInvoiceForm';
import billingoService from '../../../services/billingoService';
import { parseJSON } from 'date-fns';

export default function ChimneySweepingCertificatePickerDialog(props: any) {
  const {
    chimneySweepingCertificateId,
    open,
    setOpen,
    onCreated,
    clientId,
    moduleSettings,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const [certificates, setCertificates] = useState<any[]>([]);
  const [selectedCerts, setSelectedCerts] = useState<any[]>([]);
  const [now, setNow] = useState(new Date());
  const [invoiceDialog, setInvoiceDialog] = useState({
    open: false,
    data: {},
  });

  const handleCloseInvoiceDialog = () => {
    setInvoiceDialog((prev) => ({ ...prev, open: false }));
  };

  function areDatesOnSameDay(date1, date2) {
    const d1 = new Date(date1);
    const d2 = new Date(date2);

    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  }

  useEffect(() => {
    var items = [];
    selectedCerts.forEach((x) => {
      let found = certificates.find((y) => y.id === x);
      if (!found) {
        return;
      }
      let serviceIds = [];
      found.chimneyControls?.forEach((element) => {
        if (parseInt(element.connectionElementLength) > 0) {
          for (
            let index = 0;
            index < parseInt(element.connectionElementLength);
            index++
          ) {
            serviceIds = [...serviceIds, element.serviceId];
          }
        } else {
          serviceIds = [...serviceIds, element.serviceId];
        }
      });
      // found.chimneyControls?.map((y: any) => {
      //   return y.serviceId;
      // });

      var groups = serviceIds.reduce((acc, curr) => {
        acc[curr] = (acc[curr] || 0) + 1;
        return acc;
      }, {});
      var certItems = Object.entries(groups).map(([serviceId, count]) => ({
        product_id: parseInt(serviceId),
        quantity: count,
        comment: '',
        certificateId: found.id,
      }));

      items = items.concat(certItems);
      if (found.hasDeliveryFee) {
        items = [
          ...items,
          {
            product_id: parseInt(moduleSettings.deliveryFee),
            quantity: 1,
            comment: '',
            certificateId: found.id,
          },
        ];
      }
      if (
        found.carbonMonoxideSensors?.filter((s) => s.wasChecked)?.length > 0
      ) {
        items = [
          ...items,
          {
            product_id: parseInt(moduleSettings.coCheckingServiceId),
            quantity: found.carbonMonoxideSensors?.filter((s) => s.wasChecked)
              ?.length,
            comment: '',
            certificateId: found.id,
          },
        ];
      }
      if (
        found.carbonMonoxideSensors?.filter((s) => s.wasInstalled)?.length > 0
      ) {
        items = [
          ...items,
          {
            product_id: parseInt(moduleSettings.coServiceId),
            quantity: found.carbonMonoxideSensors?.filter((s) => s.wasInstalled)
              ?.length,
            comment: '',
            certificateId: found.id,
          },
        ];
      }
    });
    if (selectedCerts.length > 0) {
      let cert = certificates.find((y) => y.id === selectedCerts[0]);
      if (!cert) {
        return;
      }
      var newData = {
        vendor_id: cert.serviceProviderId,
        clientId: cert.clientId,
        block_id: moduleSettings?.documentBlockId ?? 0,
        fulfillment_date: cert.date,
        due_date:
          cert.paymentMethod === 'Átutalás'
            ? moment(now).add(15, 'days').toDate()
            : now,
        payment_method: cert.paymentMethod,
        electronic: false,
        paid: cert.isPaid,
        items: items,
        comment: '',
        discount: 0,
      };
      setInvoiceDialog({ ...invoiceDialog, data: newData });
    }
  }, [selectedCerts, certificates, moduleSettings]);

  useEffect(() => {
    if (clientId > 0 && chimneySweepingCertificateId > 0) {
      dispatch({ type: 'SHOW_QUERY' });
      chimneySweepingCertificateService
        .list(null, null, clientId)
        .then((response) => {
          if (response.canceled) return;
          if (!response.hasError) {
            setCertificates(response.records);
          } else
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
        })
        .finally(() => dispatch({ type: 'HIDE' }));
    }
  }, [clientId, chimneySweepingCertificateId]);

  useEffect(() => {
    if (chimneySweepingCertificateId > 0) {
      setSelectedCerts([chimneySweepingCertificateId]);
    }
  }, [chimneySweepingCertificateId]);

  const handleClose = () => {
    setSelectedCerts([]);
    setOpen({ open: false, id: 0, certificates: [] });
  };

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];
    let foundCert = certificates.find(
      (x) => x.id === chimneySweepingCertificateId
    );
    if (
      foundCert &&
      !(params.row.orderId > 0) &&
      foundCert.paymentMethod === params.row.paymentMethod &&
      foundCert.isPaid === params.row.isPaid
    )
      actions.push(
        <Button
          variant="contained"
          color={color ? color : 'primary'}
          onClick={() => {
            if (selectedCerts.some((x) => x === params.row.id)) {
              setSelectedCerts((prev) =>
                prev.filter((x) => x !== params.row.id)
              );
            } else {
              setSelectedCerts((prev) => [...prev, params.row.id]);
            }
          }}
          sx={{ padding: '0px 10px', borderRadius: '8px' }}
        >
          Kiválasztás
        </Button>
      );

    return actions;
  };

  const clientCertColumns: GridColDef[] = [
    {
      field: 'date',
      headerName: 'Dátum',
      flex: 100,
      valueFormatter(params) {
        return params.value
          ? parseJSON(params.value).toLocaleDateString()
          : 'Nincs';
      },
    },

    {
      field: 'clientName',

      headerName: 'Ügyfél',
      flex: 200,
      renderCell(params) {
        return (
          <EntityNavigator
            entityType={EntityTypes.Client}
            entityId={params.row.clientId}
            value={params.row.clientName}
          />
        );
      },
      valueGetter: (params: any) => {
        return params.row.clientName;
      },
    },
    {
      field: 'documentNumber',
      headerName: 'Dokumentum száma',
      flex: 50,
      valueGetter(params) {
        return params.value ? parseInt(params.value) : params.value;
      },
    },
    {
      field: 'employee',
      headerName: 'Dolgozó',
      flex: 100,
      valueFormatter(params) {
        return params.value ? params.value.fullName : 'Nincs';
      },
    },
    { field: 'workAddress', headerName: 'Cím', flex: 200 },
    { field: 'workAddressEmail', headerName: 'Email cím', flex: 150 },
    { field: 'paymentMethod', headerName: 'Fizetés típus', flex: 150 },
    {
      field: 'isPaid',
      headerName: 'Kifizetett',
      flex: 150,
      valueFormatter(params) {
        return params.value ? 'Igen' : 'Nem';
      },
    },
    {
      field: 'actions',
      type: 'actions',
      align: 'right',
      flex: 200,
      getActions: getActions,
    } as GridActionsColDef,
  ];

  const CreateInvoice = (invoice: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    chimneySweepingCertificateService
      .createInvoice({
        invoice: invoice,
        chimneySweepingCertificateIds: selectedCerts,
      })
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) {
          enqueueSnackbar('Számla kiállítva', {
            variant: 'success',
          });
          onCreated();
          handleCloseInvoiceDialog();
          handleClose();
        } else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  };

  return (
    <>
      <Dialog open={open} onClose={() => handleClose()} fullWidth maxWidth="xl">
        <DialogTitle>Tanúsítványok kiválasztása</DialogTitle>
        <DialogContent>
          <Grid item xs={12}>
            <Grid item xs={12} pt={2}>
              <DataList
                rows={certificates ?? []}
                columns={clientCertColumns}
                getRowId={(row) => `${row.id}-${row.uniqueId}`}
                minimal
                localStorageKey={'chimneysweepingclientcertificatesEmails'}
                getRowClassName={(params) => {
                  return selectedCerts.some((x) => x === params.row.id)
                    ? 'verified'
                    : '';
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disabled={selectedCerts.length === 0}
            onClick={() => {
              setInvoiceDialog({ ...invoiceDialog, open: true });
            }}
          >
            Számla kiállítása
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => handleClose()}
          >
            Mégse
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={invoiceDialog.open}
        onClose={() => handleCloseInvoiceDialog()}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle sx={{ backgroundColor: '#EFEFEF' }}>
          <Typography variant="h4">Új számla kiállítása</Typography>
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: '#EFEFEF' }}>
          <Grid item xs={12}>
            <BillingoInvoiceForm
              onClose={() => {
                handleCloseInvoiceDialog();
              }}
              onCreateInvoice={(invoice) => {
                CreateInvoice(invoice);
              }}
              {...(invoiceDialog.data as BillingoInvoiceProps)}
            />
          </Grid>
        </DialogContent>
        <DialogActions sx={{ backgroundColor: '#EFEFEF' }}></DialogActions>
      </Dialog>
    </>
  );
}
