import { Info } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useCheckPermission from './useCheckPermission';
import functionDescriptorService from '../services/functionDescriptorService';
import { useSnackbar } from 'notistack';

const useFunctionDescriptor = (functionName: string) => {
  const [open, setOpen] = useState(false);
  const [descriptorText, setDescriptorText] = useState('');

  const dispatch = useDispatch<any>();
  const functionDescriptors = useSelector(
    (state: any) => state.app.functionDescriptors
  );

  useEffect(() => {
    setDescriptorText(
      functionDescriptors.find((x) => x.key === functionName)?.description ?? ''
    );
  }, [functionDescriptors, functionName]);

  const { checkPermission } = useCheckPermission();

  const isAdmin = checkPermission(['Administrator']);
  const { enqueueSnackbar } = useSnackbar();

  return (
    (descriptorText || isAdmin) && (
      <>
        <Tooltip title="Sugó">
          <IconButton onClick={() => setOpen(true)}>
            <Info color="info" />
          </IconButton>
        </Tooltip>
        <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
          <DialogTitle>Információk</DialogTitle>
          <DialogContent>
            <Box pt={3}>
              <TextField
                value={descriptorText}
                onChange={(event) => {
                  setDescriptorText(event.target.value);
                }}
                label="Leírás"
                multiline
                rows={20}
                disabled={!isAdmin}
                name="description"
                size="small"
                fullWidth
                type="text"
              />
            </Box>
          </DialogContent>
          <DialogActions>
            {isAdmin && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  dispatch({ type: 'SHOW_SAVE' });
                  functionDescriptorService
                    .save({
                      key: functionName,
                      description: descriptorText,
                    })
                    .then((response) => {
                      if (response.canceled) return;
                      if (response.hasError) {
                        enqueueSnackbar(response.errorMessages.join(','), {
                          variant: 'error',
                        });
                      } else {
                        enqueueSnackbar('Sikeres mentés!', {
                          variant: 'success',
                        });

                        setOpen(false);

                        functionDescriptorService
                          .list()
                          .then((response: any) => {
                            if (response.canceled) return;
                            if (!response.hasError)
                              dispatch({
                                type: 'LOAD_FUNCTION_DESCRIPTORS',
                                payload: response.records,
                              });
                            else
                              enqueueSnackbar(
                                response.errorMessages.join(','),
                                {
                                  variant: 'error',
                                }
                              );
                          });
                      }
                    })
                    .finally(() => {
                      dispatch({ type: 'HIDE' });
                    });
                }}
              >
                Mentés
              </Button>
            )}
            <Button
              color="primary"
              variant="outlined"
              onClick={() => setOpen(false)}
            >
              Mégse
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  );
};

export default useFunctionDescriptor;
