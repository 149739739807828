import { styled } from '@mui/system';

import { Button, Grid, LinearProgress, Typography } from '@mui/material';

import { connect, useDispatch } from 'react-redux';

const Root = styled('div')({
  width: '100%',
  height: '100%',
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 99999,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  '& #message': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    color: 'white',
    transform: 'translate(-50%)',
  },
});

const WaitLayer = ({ state }: any) => {
  const dispatch = useDispatch();
  return state.waitLayerVisible ? (
    <Root
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        gap: '1rem', // Add some spacing between elements
      }}
    >
      <LinearProgress />
      <Typography id="message" component="div" variant="h5">
        {state.message}
      </Typography>
      {state.abort && (
        <Grid item xs={12} pt={20}>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              dispatch({ type: 'HIDE' });
              (state.abort as AbortController).abort();
            }}
          >
            Megszakítás
          </Button>
        </Grid>
      )}
    </Root>
  ) : null;
};

const mapStateToProps = (state: any) => {
  return {
    state: state.waitLayer,
  };
};

export default connect(mapStateToProps)(WaitLayer);
