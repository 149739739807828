import React from 'react';
import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import moment from 'moment';
import { PDFViewer } from '@react-pdf/renderer';
import { AttendenceType } from '../../../types/AttendenceType';
import { ca } from 'date-fns/locale';

Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf',
});
Font.register({
  family: 'Roboto-bold',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf',
});

export const UserAttendancePDFDialog = (props) => {
  const { data = [], date, open, onClose } = props;

  const daysOfMonthWithData = Array.from(
    { length: 31 },
    (_, index) => index + 1
  ).filter((day) => {
    // Check if any employee has data for this day
    return data?.some((employee) =>
      employee?.some((entry) => new Date(entry.startOfWork)?.getDate() === day)
    );
  });

  const daysOfMonth = daysOfMonthWithData.length;

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      padding: 20,
      fontFamily: 'Roboto',
      fontSize: 12,
    },
    title: {
      fontSize: 24,
      fontWeight: 'bold',
      textAlign: 'center',
      marginBottom: 20,
    },
    table: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      border: '1px solid black',
      marginBottom: 20,
    },
    tableRow: {
      flexDirection: 'row',
      borderBottomWidth: 1,
      borderBottomColor: '#000',
      borderBottomStyle: 'solid',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      minHeight: 24,
    },
    tableCell: {
      flex: 1,
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      borderRightWidth: 1,
      borderRightHeight: '100%',
      borderRightColor: '#000',
      borderRightStyle: 'solid',
      paddingVertical: 4,
      fontSize: 8,
      height: '100%',
      width: '100%',
    },
    tableCellItems: {
      flex: 1,
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      borderRightWidth: 1,
      borderRightHeight: '100%',
      borderRightColor: '#000',
      borderRightStyle: 'solid',
      paddingVertical: 4,
      fontSize: 6,
      height: '100%',
      width: '100%',
    },
    tableCellItems2: {
      flex: 1,
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      borderRightWidth: 1,
      borderRightHeight: '100%',
      borderRightColor: '#000',
      borderRightStyle: 'solid',
      paddingVertical: 4,
      fontSize: 10,
      height: '100%',
      width: '100%',
    },
    tableCell2: {
      flex: 3,
      textAlign: 'center',
      borderRightWidth: 1,
      borderRightColor: '#000',
      borderRightStyle: 'solid',
      paddingVertical: 4,
      height: '100%',
      width: '100%',
      fontSize: 8,
    },
    tableCell3: {
      flex: 3,
      textAlign: 'center',
      paddingVertical: 4,
      height: '100%',
      width: '100%',
    },
    tableCell4: {
      flex: 2,
      textAlign: 'center',
      borderRightWidth: 1,
      borderRightColor: '#000',
      borderRightStyle: 'solid',
      paddingVertical: 4,
      height: '100%',
      width: '100%',
      fontSize: 8,
    },
    headerCell: {
      backgroundColor: 'black',
      color: '#fff',
      maxHeight: 24,
    },
    footer: {
      position: 'absolute',
      bottom: 20, // Adjust this value as needed
      left: 0,
      right: 0,
      textAlign: 'center',
    },
    footerText: {
      fontSize: 10,
    },
  });

  // Chunk the data array into arrays of 10 entries each
  const chunkedData = [];
  for (let i = 0; i < data.length; i += 11) {
    chunkedData.push(data.slice(i, i + 11));
  }

  const totalPages = chunkedData.length;

  const calculateWorkHour = (row: any) => {
    let startOfWork = moment(row.startOfWork);
    let endOfWork = moment(row.endOfWork);
    let startOfLunch = moment(row.startOfLunch);
    let endOfLunch = moment(row.endOfLunch);

    let workDuration = endOfWork.diff(startOfWork, 'hours'); // Total work hours
    let lunchDuration = endOfLunch.diff(startOfLunch, 'hours'); // Lunch break hours

    return workDuration - lunchDuration;
  };

  const calculateTotalWorkHour = (rows: any) => {
    let totalHours = 0;
    rows
      .filter(
        (x) =>
          (x.other === 4 ||
            x.other === 10 ||
            x.other === 1 ||
            !(x.other > 0)) &&
          !x.isHoliday &&
          !x.isSickHoliday
      )
      .forEach((row) => {
        totalHours += calculateWorkHour(row); // Subtract lunch break from total work hours
      });
    return totalHours;
  };

  return (
    <Dialog open={open} onClose={() => onClose()} fullWidth maxWidth={'lg'}>
      <DialogContent>
        <Grid item xs={12} pt={2}>
          <div>
            <PDFViewer
              width={'100%'}
              height={window.outerHeight - 50}
              children={
                <Document>
                  {/* Iterate over chunks and render a new page for each */}
                  {chunkedData.map((chunk, pageIndex) => (
                    <Page
                      key={pageIndex}
                      style={styles.page}
                      orientation="landscape"
                      size={'A4'}
                    >
                      <Text style={styles.title}>
                        Jelenléti MJ-{moment(date).format('YYYY-MM')}
                      </Text>
                      <View style={styles.table}>
                        {/* Table Header */}
                        <View
                          fixed
                          style={[styles.tableRow, styles.headerCell]}
                        >
                          <Text style={[styles.tableCell2, styles.headerCell]}>
                            Név
                          </Text>
                          {[...Array(daysOfMonth)].map((_, index) => (
                            <Text
                              key={index}
                              style={[styles.tableCell, styles.headerCell]}
                            >
                              {daysOfMonthWithData[index]}
                            </Text>
                          ))}
                          <Text style={[styles.tableCell4, styles.headerCell]}>
                            Munkaóra
                          </Text>
                          <Text style={[styles.tableCell2, styles.headerCell]}>
                            Aláírás
                          </Text>
                        </View>
                        {/* Employee Rows */}
                        {chunk.map((employee, employeeIndex) => (
                          <View key={employeeIndex} style={styles.tableRow}>
                            <Text style={styles.tableCell2}>
                              {employee[0].createdByName}
                            </Text>
                            {[...Array(daysOfMonth)].map((_, dayIndex) => (
                              <Text
                                key={dayIndex}
                                style={
                                  employee?.find(
                                    (g) =>
                                      new Date(g.startOfWork)?.getDate() ===
                                      daysOfMonthWithData[dayIndex]
                                  )?.other > 0 ||
                                  employee?.find(
                                    (g) =>
                                      new Date(g.startOfWork)?.getDate() ===
                                      daysOfMonthWithData[dayIndex]
                                  )?.isHoliday ||
                                  employee?.find(
                                    (g) =>
                                      new Date(g.startOfWork)?.getDate() ===
                                      daysOfMonthWithData[dayIndex]
                                  )?.isSickHoliday
                                    ? styles.tableCellItems2
                                    : styles.tableCellItems
                                }
                              >
                                {(() => {
                                  var found = employee?.find(
                                    (g) =>
                                      new Date(g.startOfWork)?.getDate() ===
                                      daysOfMonthWithData[dayIndex]
                                  );
                                  if (found?.isHoliday) return 'S';
                                  if (found?.isSickHoliday) return 'B';
                                  if (
                                    found?.other === AttendenceType.BirthHoliday
                                  )
                                    return 'Z';
                                  if (
                                    found?.other ===
                                    AttendenceType.ChildrenHoliday
                                  )
                                    return 'G';
                                  if (
                                    found?.other === AttendenceType.DadHoliday
                                  )
                                    return 'A';
                                  if (
                                    found?.other ===
                                    AttendenceType.ExtraOrdinaryHoliday
                                  )
                                    return 'E';
                                  if (
                                    found?.other === AttendenceType.HomeOffice
                                  )
                                    return 'H';
                                  if (
                                    found?.other ===
                                    AttendenceType.InsurancePause
                                  )
                                    return 'K';
                                  if (
                                    found?.other ===
                                    AttendenceType.NonWorkinkDay
                                  )
                                    return 'F';
                                  if (
                                    found?.other ===
                                    AttendenceType.PaidVerifiedLeave
                                  )
                                    return 'I';
                                  if (
                                    found?.other === AttendenceType.StudyHoliday
                                  )
                                    return 'T';
                                  if (found?.other === AttendenceType.RestDay)
                                    return 'M';

                                  const workStart = found?.startOfWork;

                                  const workEnd = found?.endOfWork;

                                  return workStart
                                    ? ` ${moment(workStart).format(
                                        'HH:mm'
                                      )} \n  ${moment(workEnd).format(
                                        'HH:mm'
                                      )}   \n  (${calculateWorkHour(found)}h)`
                                    : ' ';
                                })()}
                              </Text>
                            ))}
                            <Text style={styles.tableCell4}>
                              {calculateTotalWorkHour(employee)}
                            </Text>
                            <Text style={styles.tableCell3}>
                              {/* Employee Signature */}
                            </Text>
                          </View>
                        ))}
                      </View>
                      <View
                        fixed
                        style={{
                          position: 'absolute',
                          left: 0,
                          right: 10,
                          bottom: 100,
                          textAlign: 'center',
                          fontSize: 5,
                          alignItems: 'flex-end',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                          }}
                        >
                          <div>
                            <Text style={styles.footerText}>
                              Munkáltató aláírása
                            </Text>
                          </div>
                          <div style={{ marginLeft: 10 }}>
                            {' '}
                            <Text style={styles.footerText}>
                              ___________________________
                            </Text>
                          </div>
                        </div>
                      </View>
                      <View
                        fixed
                        style={{
                          position: 'absolute',
                          left: 20,
                          right: 10,
                          bottom: 40,
                          width: '100%',
                          fontSize: 5,
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                          }}
                        >
                          <div style={{ flex: 1 }}>
                            <Text style={styles.footerText}>
                              {'H     Home Office'}
                            </Text>
                            <Text style={styles.footerText}>
                              {'F     Munkaszüneti nap'}
                            </Text>
                            <Text style={styles.footerText}>
                              {'A     Apai szabadság'}
                            </Text>
                          </div>
                          <div style={{ flex: 1 }}>
                            <Text style={styles.footerText}>
                              {'M    Pihenő nap'}
                            </Text>
                            <Text style={styles.footerText}>
                              {'S     Fizetett szabadság'}
                            </Text>
                            <Text style={styles.footerText}>
                              {'Z     Szülői szabadság'}
                            </Text>
                          </div>
                          <div style={{ flex: 1 }}>
                            <Text style={styles.footerText}>
                              {'G     Gyermekek után járó szabad.'}
                            </Text>
                            <Text style={styles.footerText}>
                              {'E     Rendkívüli szabadság'}
                            </Text>
                            <Text style={styles.footerText}>
                              {'B     Betegszabadság'}
                            </Text>
                          </div>
                          <div style={{ flex: 1 }}>
                            {' '}
                            <Text style={styles.footerText}>
                              {'T     Tanulmányi szabadság'}
                            </Text>
                            <Text style={styles.footerText}>
                              {'I       Fizetett igazolt távollét'}
                            </Text>
                            <Text style={styles.footerText}>
                              {'K     Biztosítás szünetelése'}
                            </Text>
                          </div>
                        </div>
                      </View>

                      <View
                        style={{
                          position: 'absolute',
                          bottom: '20px',
                          left: 0,
                          right: 0,
                          textAlign: 'center',
                          fontSize: 5,
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                        render={({ pageNumber }) => (
                          <Text
                            style={{ fontSize: 10 }}
                          >{`${pageNumber} / ${totalPages}`}</Text>
                        )}
                      />
                    </Page>
                  ))}
                </Document>
              }
            ></PDFViewer>
          </div>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={() => onClose()}>
          Kilépés
        </Button>
      </DialogActions>
    </Dialog>
  );
};
