import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  ButtonBase,
  Box,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import FileCopyIcon from '@mui/icons-material/FileCopy';

const COVerticalTable = ({
  data,
  onCellClicked = (params: any) => {},
  onDelete = (params: any) => {},
  onDuplicationClicked = (params: any) => {},
  readonly = false,
}) => {
  if (!data || data.length === 0) {
    return null;
  }

  const headers = Object.keys(data[0]);

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableBody>
          {headers.map((header, index) => (
            <TableRow
              key={index}
              style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }}
            >
              <TableCell style={{ border: '1px solid black' }} width="auto">
                {header === headers[headers.length - 1] ||
                header === headers[0] ? (
                  ''
                ) : (
                  <b>{header}</b>
                )}
              </TableCell>
              {data.map((row, rowIndex) => (
                <TableCell
                  onClick={() =>
                    header === headers[0] ? null : onCellClicked(row)
                  }
                  style={{ border: '1px solid black' }}
                  key={rowIndex}
                  width="auto"
                  align="center"
                >
                  {<strong>{row[header]}</strong>}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default COVerticalTable;
