import axios from 'axios';
import { postData } from '../axiosUtils';

const companyAssetService = {
  // list: () => {
  //   return axios.get(`/api/tool/list`).then((response) => {
  //     return response.data;
  //   });
  // },
  move: (entity: any) => {
    return postData({
      url: `/api/companyAsset/move`,
      data: entity,
      method: 'PUT',
    }).then((response) => {
      return response;
    });
  },
  updateStatus: (entity: any) => {
    return postData({
      url: `/api/companyAsset/updateStatus`,
      data: entity,
      method: 'PUT',
    }).then((response) => {
      return response;
    });
  },
  listLog: (assetId: any) => {
    return axios
      .get(`/api/companyAsset/listLog/${assetId}`)
      .then((response) => {
        return response.data;
      });
  },
};

export default companyAssetService;
