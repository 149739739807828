import {
  Visibility,
  CheckCircle as ApproveIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  OpenInNew,
} from '@mui/icons-material';
import PrintIcon from '@mui/icons-material/Print';
import StarIcon from '@mui/icons-material/Star';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Rating,
  Tooltip,
} from '@mui/material';
import {
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
} from '@mui/x-data-grid';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { parseJSON } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DataList from '../../../components/DataList';
import workItemService from '../../../services/erp/workItemService';
import { formatRole } from '../../../utils/valueFormatters';
import { WorkItemStatus } from '../workItems/WorkItemForm';
import clientService from '../../../services/crm/clientService';
import { useDispatch } from 'react-redux';
import Divider from '@mui/material/Divider';
import { EntityStatuses } from '../../../types/EntityStatuses';
import AssignmentIcon from '@mui/icons-material/Assignment';
import useCheckPermission from '../../../hooks/useCheckPermission';

export const AddressDialog = (props: any) => {
  const { open, addressId, onClose = () => {} } = props;

  const { enqueueSnackbar } = useSnackbar();
  const [workItems, setWorkItems] = useState<any[]>([]);
  const [clients, setClients] = useState<any[]>([]);
  const [address, setAddress] = useState<any>();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    if (addressId > 0) {
      dispatch({ type: 'SHOW_QUERY' });
      clientService
        .addressWorkItem(addressId)
        .then((response) => {
          if (response.canceled) return;
          if (!response.hasError) {
            setWorkItems(response.workItems);
            setClients(response.clients);
            setAddress(response.adress);
          } else
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
        })
        .finally(() => dispatch({ type: 'HIDE' }));
    }
  }, [addressId]);

  const getWorkItemActions = (params: GridRowParams, color: any) => {
    var actions = [];
    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          <Tooltip title="Megtekintés">
            <Visibility />
          </Tooltip>
        }
        label={'Megtekintés'}
        onClick={() =>
          navigate(`/erp/workitems/edit/${params.row.id}/0/workItem`)
        }
      />
    );
    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          <Tooltip title="PDF nézet">
            <PrintIcon />
          </Tooltip>
        }
        label={'PDF nézet'}
        onClick={() => navigate(`/erp/workItems/PDF/${params.row.id}`)}
      />
    );

    return actions;
  };

  const getClientActions = (params: GridRowParams, color: any) => {
    var actions = [];

    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          <Tooltip title="Weboldal felkeresése új lapon">
            <OpenInNew />
          </Tooltip>
        }
        label="Weboldal felkeresése új lapon"
        disabled={!params.row.contact.webAddress}
        onClick={() => {
          try {
            let urlString = params.row.contact.webAddress;
            if (!urlString.startsWith('http')) {
              urlString = 'https://' + urlString;
            }

            window.open(urlString, '_blank').focus();
          } catch (error) {
            console.error('Invalid URL: ', params.row.contact.webAddress);
          }
        }}
      />
    );

    let readonly = !checkPermission(['ClientEdit']);
    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          readonly ? (
            <Tooltip title="Megtekintés">
              <Visibility />
            </Tooltip>
          ) : (
            <Tooltip title="Szerkesztés">
              <EditIcon />
            </Tooltip>
          )
        }
        label={readonly ? 'Megtekintés' : 'Szerkesztés'}
        onClick={() => navigate(`/crm/clients/edit/${params.row.id}`)}
      />
    );

    return actions;
  };

  const workItemColumns: GridColDef[] = [
    {
      field: 'workFlowDate',
      headerName: 'Dátum',
      flex: 150,
      valueFormatter: (params) => {
        if (!params.value) return '';
        const previousValue = parseJSON(params.value).toLocaleDateString();
        const date = new Date(parseJSON(params.value));
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const formattedTime = `${previousValue} ${hours}:${minutes
          .toString()
          .padStart(2, '0')}`;
        return formattedTime;
      },
    },
    { field: 'workItemNumber', headerName: 'Sorszám', flex: 100 },
    { field: 'clientName', headerName: 'Ügyfél', flex: 100 },
    { field: 'workAddress', headerName: 'Cím', flex: 100 },
    { field: 'taskTypeName', headerName: 'Leírás', flex: 100 },
    { field: 'employeeName', headerName: 'Dolgozó', flex: 100 },
    {
      field: 'workItemStatus',
      headerName: 'Munkalap státusza',
      flex: 100,
      renderCell: (params) => {
        return (
          <div>
            {params.value === WorkItemStatus.Draft ? (
              <span style={{ color: 'orange' }}>Tervezés alatt</span>
            ) : params.value === WorkItemStatus.New ? (
              <span style={{ color: '#A0A0A0' }}>Nincs hozzárendelve</span>
            ) : params.value === WorkItemStatus.Assigned ? (
              <span style={{ color: '#82C0FF' }}>Tervezett</span>
            ) : params.value === WorkItemStatus.Completed ? (
              <span style={{ color: 'lightgreen' }}>Befejezett</span>
            ) : params.value === WorkItemStatus.Suspended ? (
              <span style={{ color: '#FFCC00' }}>Felfüggesztett</span>
            ) : params.value === WorkItemStatus.Closed ? (
              <span style={{ color: 'green' }}>Lezárt</span>
            ) : params.value === WorkItemStatus.Evaluated ? (
              <span style={{ color: '#90EE90' }}>Kiértékelt</span>
            ) : null}
          </div>
        );
      },
    },
    {
      field: 'roleName',
      headerName: 'Munkafolyamat státusza',
      flex: 130,
      valueFormatter: (params) => {
        return formatRole(params.value);
      },
    },
    {
      field: 'statisticRating',
      headerName: 'Értékelés',
      flex: 150,
      renderCell: (params) => (
        <div>
          <Rating
            name="hover-feedback"
            value={params.value}
            readOnly={true}
            precision={0.5}
            emptyIcon={
              <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
            }
          />
        </div>
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      align: 'right',
      flex: 200,
      getActions: getWorkItemActions,
    } as GridActionsColDef,
  ];

  const clientColumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', flex: 100 },
    {
      field: 'fullName',
      headerName: 'Ügyfél',
      flex: 100,
      editable: false,
      valueGetter: (params: any) => {
        return params.row.isPirvatePerson
          ? `${params.row.name.firstName} ${params.row.name.lastName}`
          : params.row.companyName;
      },
    },
    {
      field: 'companyName',
      headerName: 'Cég',
      flex: 100,
      editable: false,
      valueGetter: (params: any) => {
        return `${params.row.companyName ?? ''}`;
      },
    },
    {
      field: 'taxNumber',
      headerName: 'Adószám',
      flex: 100,
      editable: false,
      valueGetter: (params: any) => {
        return `${params.row.taxNumber ?? ''}`;
      },
    },
    {
      field: 'contact.phone',
      headerName: 'Telefonszám',
      flex: 100,
      editable: false,
      valueGetter: (params: any) => {
        return `${params.row.contact.phone ?? ''}`;
      },
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 100,
      getActions: getClientActions,
    } as GridActionsColDef,
  ];

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
      <DialogTitle>{address?.fullAddress ?? ''}</DialogTitle>
      <DialogContent>
        <Grid item xs={12}>
          <Divider textAlign="left" sx={{ mt: 2, paddingBottom: 2 }}>
            Munkalapok
          </Divider>
        </Grid>
        <Grid item xs={12}>
          <DataList
            rows={workItems}
            columns={workItemColumns}
            localStorageKey={'AddressDialogWorkItems'}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider textAlign="left" sx={{ mt: 2, paddingBottom: 2 }}>
            Ügyfelek
          </Divider>
        </Grid>
        <Grid item xs={12}>
          <DataList
            rows={clients}
            columns={clientColumns}
            localStorageKey={'AddressDialogClients'}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose} color="primary">
          Bezárás
        </Button>
      </DialogActions>
    </Dialog>
  );
};
