import { Box, Grid, Paper, Tab, Tabs, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { EmailTemplateType } from '../../../types/EmailTemplateType';
import EmailTemplatePage from './EmailTemplatePage';
import useFunctionDescriptor from '../../../hooks/useFunctionDescriptor';

const EmailTemplateHomePage = () => {
  const navigate = useNavigate();
  const titleDescriptor = useFunctionDescriptor('FinancePage.title');
  const [tabValue, setTabValue] = useState<number>(0);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const storedTabValue = localStorage.getItem('EmailTemplateTab');
    if (storedTabValue) {
      setTabValue(parseInt(storedTabValue));
    }
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    localStorage.setItem('EmailTemplateTab', newValue.toString());
  };

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <Box sx={{ width: '100%' }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                pb: 2,
                maxWidth: isSmallScreen ? '280px' : '100%',
              }}
            >
              <Tabs
                value={tabValue}
                onChange={handleChange}
                TabIndicatorProps={{ style: { background: '#1976d2' } }}
                sx={{
                  maxWidth: isSmallScreen ? '280px' : '100%',
                  overflowX: 'scroll',
                }}
                allowScrollButtonsMobile
                scrollButtons="auto"
                variant="scrollable"
              >
                <Tab label="Árajánlat" sx={{ fontWeight: 'bold' }} />
                <Tab label="Megrendelés" sx={{ fontWeight: 'bold' }} />
                <Tab label="Munkalap" sx={{ fontWeight: 'bold' }} />
                <Tab
                  label="Kéményseprő-ipari tanúsítvány"
                  sx={{ fontWeight: 'bold' }}
                />
              </Tabs>
            </Box>
            {tabValue === 0 && (
              <EmailTemplatePage emailTemplateType={EmailTemplateType.Offer} />
            )}
            {tabValue === 1 && (
              <EmailTemplatePage emailTemplateType={EmailTemplateType.Order} />
            )}
            {tabValue === 2 && (
              <EmailTemplatePage
                emailTemplateType={EmailTemplateType.WorkItem}
              />
            )}
            {tabValue === 3 && (
              <EmailTemplatePage
                emailTemplateType={EmailTemplateType.ChimneySweepingCertificate}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default EmailTemplateHomePage;
