import axios from "axios";
import { postData } from "../axiosUtils";

const priceCategoryService = {
  list: (employeeId: number = null) => {
    let queryparams = employeeId ? "?employeeId=" + employeeId : "";
    return axios
      .get(`/api/priceCategory/list${queryparams}`)
      .then((response) => {
        return response.data;
      });
  },
  listByRole: (roleId: any) => {
    return axios
      .get(`/api/priceCategory/listByRole/${roleId}`)
      .then((response) => {
        return response.data;
      });
  },
  get: (id: any) => {
    return axios.get(`/api/priceCategory/details/${id}`).then((response) => {
      return response.data;
    });
  },
  create: (entity: any) => {
    return postData({
      url: `/api/priceCategory/create`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  update: (entity: any) => {
    return postData({
      url: `/api/priceCategory/update`,
      data: entity,
      method: "PUT",
    }).then((response) => {
      return response;
    });
  },
  delete: (id: any) => {
    return postData({
      url: `/api/priceCategory/delete/${id}`,
      method: "DELETE",
    }).then((response) => {
      return response;
    });
  },
};

export default priceCategoryService;
