import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import WarehouseForm from './WarehouseForm';
import { useNavigate, useParams } from 'react-router-dom';
import warehouseService from '../../../services/wms/warehouseService';
import warehouseGroupService from '../../../services/wms/warehouseGroupService';
import useCheckPermission from '../../../hooks/useCheckPermission';
import userService from '../../../services/authority/userService';

const WarehouseEdit = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const params = useParams();
  const [errors, setErrors] = useState<string[]>([]);
  const [entity, setEntity] = useState<any>({
    warehouseGroupId: 0,
    name: '',
    userIds: [],
  });
  const [groups, setGroups] = useState<any[]>([{ id: '', groupName: '' }]);
  const [users, setUsers] = useState<any[]>([
    { id: '', fullName: '', email: '' },
  ]);
  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    warehouseService.get(params.id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        setErrors(response.errorMessages);
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setEntity(response.result);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  useEffect(() => {
    userService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setUsers(response.records);
      }
    });

    warehouseGroupService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setGroups(response.records);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    warehouseService
      .update(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });

          navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        setSubmitting(false);
      });
  };

  return (
    <WarehouseForm
      entity={entity}
      users={users}
      readonly={!checkPermission(['WarehouseEdit'])}
      groups={groups}
      onNewGroupAdded={(group: any) => {
        setGroups([...groups, group]);
      }}
      onSubmit={onSubmit}
      errors={errors}
    />
  );
};

export default WarehouseEdit;
