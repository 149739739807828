import { Box, Grid, Paper, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import '../../../css/dataGrid.css';
import ChimneySweepingCertificateBillingPage from './ChimneySweepingCertificateBillingPage';

const BillingPage = (props: any) => {
  const [tabValue, setTabValue] = useState(0);

  return (
    <Paper>
      <Grid container p={2}>
        <Grid item xs={12}>
          <h2>Számlázás</h2>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ width: '100%' }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                pb: 2,
              }}
            >
              <Tabs
                value={tabValue}
                onChange={(event, newValue) => {
                  setTabValue(newValue);
                }}
                TabIndicatorProps={{ style: { background: '#1976d2' } }}
                allowScrollButtonsMobile
                scrollButtons="auto"
                variant="scrollable"
              >
                <Tab
                  value={0}
                  label="Kéményseprő-tanúsítvány"
                  sx={{ fontWeight: 'bold' }}
                />
              </Tabs>
            </Box>
            {tabValue === 0 && <ChimneySweepingCertificateBillingPage />}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default BillingPage;
