import {
  Autocomplete,
  Checkbox,
  Grid,
  InputAdornment,
  TextField,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useCheckPermission from '../../../hooks/useCheckPermission';
import itemSupplyService from '../../../services/wms/itemSupplyService';
import warehouseService from '../../../services/wms/warehouseService';
import { ItemSupplyStatus } from './ItemSupplyPage';
import RejectSupplyDialog from './RejectSupplyDialog';

export default function ItemSupplyViewDialog(props: any) {
  const { open, handleClose, entityId, handleAccept, handleReject, items } =
    props;
  const [openRejectSupplyDialog, setOpenRejectSupplyDialog] = useState({
    open: false,
    entity: {},
  });
  const [entity, setEntity] = useState<any>({
    items: [],
    sourceWarehouseId: null,
    targetWarehouseId: null,
    userId: null,
    comment: '',
  });
  const [checkedItems, setCheckedItems] = useState(
    new Array(entity.items?.length).fill(false)
  );
  const [allChecked, setAllChecked] = useState(false);
  const { checkPermission } = useCheckPermission();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [employeeWarehouses, setEmployeeWarehouses] = useState<any[]>([]);
  const user = useSelector((state: any) => state.user.userInfo);
  const [selectedWarehouseId, setSelectedWarehouseId] = useState<number | null>(
    null
  );

  useEffect(() => {
    if (user?.userId > 0 && entity.userId > 0) {
      warehouseService.listUser(user.userId).then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          setEmployeeWarehouses(response.records);
          if (response.records.length > 0 && entity.userId !== user.userId) {
            let warehouse = response.records.find(
              (x) => x.id !== entity.sourceWarehouseId
            );

            setSelectedWarehouseId(warehouse?.id ?? null);
          }
        }
      });
    }
  }, [user, entity]);

  useEffect(() => {
    if (entityId) {
      dispatch({ type: 'QUERY' });
      itemSupplyService
        .get(entityId)
        .then((response) => {
          if (response.canceled) return;
          if (response.hasError) {
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
          } else {
            setEntity(response.result);
            if (response.result.userId === user.userId) {
              setSelectedWarehouseId(response.result.targetWarehouseId);
            }
            setCheckedItems(
              new Array(response.result.items?.length).fill(false)
            );
          }
        })
        .finally(() => {
          dispatch({ type: 'HIDE' });
        });
    }
  }, [entityId]);

  const handleCheckboxToggle = (index: number) => {
    const updatedCheckedItems = [...checkedItems];
    updatedCheckedItems[index] = !updatedCheckedItems[index];
    setCheckedItems(updatedCheckedItems);
  };
  useEffect(() => {
    setAllChecked(checkedItems.every((isChecked) => isChecked));
  }, [checkedItems]);

  return (
    <Dialog
      open={open}
      style={{ minHeight: 500, width: '100%' }}
      fullWidth
      onClose={handleClose}
    >
      <DialogTitle>Anyagfelvétel</DialogTitle>
      <DialogContent>
        <Grid item container xs={12}>
          {allChecked
            ? 'Minden termék kiválasztva'
            : 'Nincs minden termék kiválasztva'}
          <Grid item xs={12} pt={2}>
            <Autocomplete
              disablePortal
              id="targetWarehouseId"
              value={selectedWarehouseId}
              disabled={entity.status !== ItemSupplyStatus.ReadyForPickUp}
              onChange={(event, value) => {
                setSelectedWarehouseId(value);
              }}
              getOptionLabel={(option) => {
                return employeeWarehouses.find((g) => g.id === option)?.name;
              }}
              options={employeeWarehouses
                .filter((x) => x.id !== entity.sourceWarehouseId)
                .map((g) => g.id)}
              renderInput={(params) => (
                <TextField {...params} required fullWidth label="Raktár" />
              )}
            />
          </Grid>
          <Grid item xs={9} pt={2}>
            <TextField
              value={entity.userFullName}
              label="Tervezett Átvevő"
              type="text"
              InputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
              name="itemName"
              fullWidth
              disabled
              sx={{
                input: {
                  xs: { textAlign: 'center' },
                  md: { textAlign: 'left' },
                },
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Checkbox color="success" />
          </Grid>
          <Grid item xs={12}>
            {entity.items?.map((x, i) => {
              const item = items.find((y) => y.id === x.itemId);

              return (
                <Grid
                  item
                  container
                  spacing={2}
                  pt={1}
                  key={i}
                  alignItems="center"
                  textAlign={'center'}
                  style={{ borderBottom: '1px solid' }}
                >
                  <Grid item xs={12} md={7}>
                    <TextField
                      value={item?.name}
                      variant="standard"
                      label="Termék megnevezése"
                      type="text"
                      InputProps={{ readOnly: true }}
                      InputLabelProps={{ shrink: true }}
                      name="itemName"
                      fullWidth
                      disabled={x.itemId > 0}
                      sx={{
                        input: {
                          xs: { textAlign: 'center' },
                          md: { textAlign: 'left' },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      value={x.amount}
                      variant="standard"
                      label="Mennyiség"
                      type="number"
                      name="amount"
                      disabled={true}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">db</InputAdornment>
                        ),
                      }}
                      sx={{
                        input: {
                          xs: { textAlign: 'center' },
                          md: { textAlign: 'right' },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Checkbox
                      color="success"
                      onChange={() => handleCheckboxToggle(i)}
                    />
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
          <Grid item xs={12} pt={2}>
            <TextField
              value={entity?.comment}
              disabled
              multiline
              minRows={3}
              label={'Megjegyzés'}
              fullWidth
            ></TextField>{' '}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2} textAlign={'center'}>
          <Grid item container spacing={2} justifyContent={'center'}>
            <Grid item>
              <Button
                color="success"
                variant="contained"
                disabled={entity.status !== ItemSupplyStatus.ReadyForPickUp}
                onClick={() => {
                  handleAccept(entity.id, allChecked, selectedWarehouseId);
                  if (allChecked) handleClose();
                }}
              >
                Elfogadás
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="error"
                variant="contained"
                disabled={entity.status !== ItemSupplyStatus.ReadyForPickUp}
                onClick={() => {
                  setOpenRejectSupplyDialog({
                    open: true,
                    entity: entity,
                  });
                }}
              >
                Elutasítás
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button color="info" variant="contained" onClick={handleClose}>
              Mégse
            </Button>
          </Grid>
        </Grid>
        <RejectSupplyDialog
          open={openRejectSupplyDialog.open}
          entity={openRejectSupplyDialog.entity}
          setOpen={() =>
            setOpenRejectSupplyDialog((state) => ({ ...state, open: false }))
          }
          onReject={(comment) => handleReject(entity.id, comment)}
        />
      </DialogActions>
    </Dialog>
  );
}
