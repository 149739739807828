import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import BarcodeScanner from "./BarcodeScanner";

export default function BarcodeQrCodeReaderDialog(props) {
  const { open, setOpen, onQrCodeScanned } = props;

  const handleClose = () => {
    setOpen(false);
  };

  const handleScan = (data) => {
    if (data) {
      setOpen(false);
      onQrCodeScanned(data);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Vonalkód olvasás</DialogTitle>
      <DialogContent sx={{ p: 0 }}>
        <Grid container>
          <Grid item xs={12}>
            {open && (
              <BarcodeScanner
                onBarcodeDetected={handleScan}
                onClose={handleClose}
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={handleClose}>
          Mégse
        </Button>
      </DialogActions>
    </Dialog>
  );
}
