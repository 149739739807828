import {
  Button,
  TextField,
  Box,
  Grid,
  Paper,
  Autocomplete,
} from '@mui/material';
import { Form, Formik } from 'formik';

const ChimneyTypeForm = (props: any) => {
  const {
    entity,
    onSubmit,
    errors,
    handleCancel,
    readonly = false,
    services,
    isDialog = false,
  } = props;

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};
        if (!values.name) {
          errors.name = 'Required';
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        setFieldValue,
        handleChange,
        errors: validationErrors,
      }) => (
        <Form>
          {' '}
          <Paper>
            <Grid container justifyContent="left" spacing={2} p={5}>
              <h2>
                Égéstermék elvezető{' '}
                {values.id > 0 ? 'szerkesztése' : 'létrehozása'}
              </h2>
              <Grid xs={12} item pt={5}>
                <Box color="red">
                  {errors.map((error) => (
                    <li>{error}</li>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.name}
                  onChange={handleChange}
                  label="Megnevezés"
                  name="name"
                  required
                  disabled={readonly}
                  fullWidth
                  autoFocus
                  error={!!touched.name && !!validationErrors.name}
                  helperText={
                    !!touched.name &&
                    !!validationErrors.name &&
                    (validationErrors.name as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.description}
                  onChange={handleChange}
                  label="Leírás"
                  name="description"
                  required
                  InputLabelProps={{ shrink: true }}
                  disabled={readonly}
                  fullWidth
                  error={
                    !!touched.description && !!validationErrors.description
                  }
                  helperText={
                    !!touched.description &&
                    !!validationErrors.description &&
                    (validationErrors.description as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="serviceId"
                  value={values.serviceId}
                  onChange={(event, value: any) => {
                    setFieldValue('serviceId', value);
                  }}
                  getOptionLabel={(option) => {
                    var service = services?.find((g) => g.id === option);
                    if (service == null || service === undefined) {
                      return '';
                    }

                    return `${service.name}`;
                  }}
                  options={services.map((option) => option.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label="Szolgáltatás"
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Autocomplete
                  disablePortal
                  id="unit"
                  value={values.unit}
                  onChange={(event, value) => {
                    setFieldValue('unit', value);
                  }}
                  options={['db', 'fm']}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth label="Mértékegység" />
                  )}
                />
              </Grid>
              <Grid container item pt={3} justifyContent="left">
                {!readonly && (
                  <Grid item p={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      Mentés
                    </Button>
                  </Grid>
                )}
                {!isDialog && (
                  <Grid item p={1}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleCancel}
                    >
                      Mégse
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default ChimneyTypeForm;
