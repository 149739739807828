import { Box, Button, Chip, useMediaQuery } from "@mui/material";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { reset } from "../stateManagement/actions/offerActions";
import { useNavigate } from "react-router-dom";
import BookmarkIcon from "@mui/icons-material/Bookmark";

const OfferInProgress = () => {
  const { offerInProgress, form } = useSelector<any>(
    (state) => state.offer
  ) as any;

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [dots, setDots] = useState("...");
  const isSmallScreen = useMediaQuery("(max-width:500px)");

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDots((prevDots) => {
        if (prevDots === "...") {
          return ".";
        } else if (prevDots === ".") {
          return "..";
        } else {
          return "...";
        }
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  if (!offerInProgress) {
    return null;
  }
  if (isSmallScreen) {
    return (
      <Button
        title="Árajánlat folyamatban"
        onClick={() => {
          if (
            (offerInProgress &&
              !window.location.hash.endsWith("#/erp/offers/create/0")) ||
            !window.location.hash.endsWith(`#/erp/offers/edit/${form.id}`)
          ) {
            if (form.id > 0) {
              navigate(`/erp/offers/edit/${form.id}`);
            } else {
              navigate("/erp/offers/create/0");
            }
          }
        }}
      >
        <BookmarkIcon color="secondary" />
      </Button>
    );
  }
  return (
    <Chip
      color="secondary"
      label={<Box style={{ width: 150 }}>Árajánlat folyamatban{dots}</Box>}
      onClick={() => {
        if (
          (offerInProgress &&
            !window.location.hash.endsWith("#/erp/offers/create/0")) ||
          !window.location.hash.endsWith(`#/erp/offers/edit/${form.id}`)
        ) {
          if (form.id > 0) {
            navigate(`/erp/offers/edit/${form.id}`);
          } else {
            navigate("/erp/offers/create/0");
          }
        }
      }}
      onDelete={() => {
        if (
          window.location.hash.endsWith("#/erp/offers/create/0") ||
          window.location.hash.endsWith(`#/erp/offers/edit/${form.id}`) ||
          window.location.hash.endsWith(
            `#/erp/offers/create/${form.workItemId}`
          )
        ) {
          navigate(-1);
        }
        dispatch(reset());
      }}
    />
  );
};

export default OfferInProgress;
