import axios from 'axios';
import { postData } from '../axiosUtils';
const userService = {
  list: () => {
    return axios.get(`/api/user/list`).then((response) => {
      return response.data;
    });
  },
  listHidden: () => {
    return axios.get(`/api/user/listHidden`).then((response) => {
      return response.data;
    });
  },
  get: (id: any) => {
    return axios.get(`/api/user/details/${id}`).then((response) => {
      return response.data;
    });
  },
  update: (entity: any) => {
    return postData({
      url: `/api/user/update`,
      data: entity,
      method: 'PUT',
    }).then((response) => {
      return response;
    });
  },
  delete: (id: any) => {
    return postData({
      url: `/api/user/delete/${id}`,
      method: 'DELETE',
    }).then((response) => {
      return response;
    });
  },
};

export default userService;
