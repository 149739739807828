import {
  Button,
  TextField,
  Box,
  Grid,
  Paper,
  Autocomplete,
  Chip,
  Tooltip,
} from '@mui/material';
import { Form, Formik } from 'formik';
import ClientCreate from '../../crm/clients/ClientCreate';
import CreateEntityDecorator from '../../../components/CreateEntityDecorator';
import useCheckPermission from '../../../hooks/useCheckPermission';
import AbilityCreate from '../../authority/abilities/AbilityCreate';

const TaskTypeForm = (props: any) => {
  const {
    entity,
    onSubmit,
    errors,
    handleCancel,
    readonly = false,
    abilities,
    onAbilityAdded,
  } = props;

  const { checkPermission } = useCheckPermission();

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};
        if (!values.name) {
          errors.name = 'Required';
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        handleChange,
        setFieldValue,
        errors: validationErrors,
      }) => (
        <Form>
          {' '}
          <Paper>
            <Grid container justifyContent="left" spacing={2} p={5}>
              <h2>
                Feladattípus {values.id > 0 ? 'szerkesztése' : 'létrehozása'}
              </h2>
              <Grid xs={12} item pt={5}>
                <Box color="red">
                  {errors.map((error) => (
                    <li>{error}</li>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.name}
                  onChange={handleChange}
                  label="Megnevezés"
                  name="name"
                  required
                  disabled={readonly}
                  fullWidth
                  autoFocus
                  error={!!touched.name && !!validationErrors.name}
                  helperText={
                    !!touched.name &&
                    !!validationErrors.name &&
                    (validationErrors.name as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.description}
                  onChange={handleChange}
                  label="Leírás"
                  name="description"
                  required
                  InputLabelProps={{ shrink: true }}
                  disabled={readonly}
                  fullWidth
                  error={
                    !!touched.description && !!validationErrors.description
                  }
                  helperText={
                    !!touched.description &&
                    !!validationErrors.description &&
                    (validationErrors.description as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <CreateEntityDecorator
                  hideAdd={!checkPermission(['AbilityCreate'])}
                  AutocompleteComponent={
                    <Autocomplete
                      disablePortal
                      multiple
                      value={values.abilities}
                      getOptionLabel={(option) =>
                        abilities?.find((g: any) => g.id === option.id)?.name ??
                        ''
                      }
                      onChange={(event, newValue) => {
                        setFieldValue('abilities', newValue);
                      }}
                      options={abilities
                        ?.map((g: any) => g)
                        ?.filter((g: any) => {
                          return !values.abilities?.find(
                            (a: any) => a.id === g.id
                          );
                        })}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Szükséges képességek a feladat elvégzéséhez"
                          fullWidth
                        />
                      )}
                    />
                  }
                  onSave={(ability) => {
                    onAbilityAdded(ability);
                    setFieldValue('abilities', [...values.abilities, ability]);
                  }}
                  CreateEntityComponent={AbilityCreate}
                />
              </Grid>
              <Grid container item pt={3} justifyContent="left">
                {!readonly && (
                  <Grid item p={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      Mentés
                    </Button>
                  </Grid>
                )}
                <Grid item p={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleCancel}
                  >
                    Mégse
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default TaskTypeForm;
