import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, InputAdornment } from '@mui/material';
import { useSnackbar } from 'notistack';

export default function EditItemDialog(props: any) {
  const { open, setOpen, onSubmit, getAvailableAmount, row, items } = props;

  const [amount, setAmount] = React.useState(0);

  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    setAmount(row?.amount);
  }, [row]);

  const itemTextField = (param) => {
    var item = items?.find((g) => g?.itemId === param?.itemId);
    if (item == null || item === undefined) {
      return '';
    }

    return `${item.name} (${item.barcode}) - Elérhető: ${getAvailableAmount(
      item.itemId
    )} db`;
  };

  if (!row) return null;

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Termék szerkeztése</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} style={{ paddingTop: 5 }}>
          <Grid item xs={12}>
            <TextField
              disabled
              value={itemTextField(row)}
              size="small"
              label="Termék"
              fullWidth
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={amount}
              onChange={(event) => {
                let value = parseFloat(event.target.value);
                if (
                  value === 0 ||
                  value > getAvailableAmount(row?.itemId) + amount
                ) {
                  enqueueSnackbar(
                    'Hozzáadni kívánt mennyiség nem elérhető a kiválasztott raktárban vagy nulla!',
                    {
                      variant: 'error',
                    }
                  );
                  return;
                }

                setAmount(value);
              }}
              label="Darabszám"
              disabled={row?.itemId === 0}
              name="amount"
              size="small"
              fullWidth
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">db</InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (row?.itemId !== 0) {
              onSubmit({
                ...row,
                amount,
              });
              setAmount(0);

              handleClose();
            }
          }}
        >
          Szerkesztés
        </Button>
        <Button color="primary" variant="outlined" onClick={handleClose}>
          Mégse
        </Button>
      </DialogActions>
    </Dialog>
  );
}
