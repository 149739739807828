import html2canvas from "html2canvas";
import React from "react";
import { useReactToPrint } from "react-to-print";

const usePrintComponent = () => {
  const printRef = React.useRef(null);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const handleCopyToClipboard = async () => {
    const element = printRef.current;
    if (element) {
      const canvas = await html2canvas(element);
      canvas.toBlob((blob) => {
        const item = new ClipboardItem({ "image/png": blob });
        navigator.clipboard.write([item]);
      });
    }
  };

  const handleDownloadImage = async (fileName: string) => {
    const element = printRef.current;
    if (element) {
      const canvas = await html2canvas(element);

      const data = canvas.toDataURL("image/jpg");
      const link = document.createElement("a");

      if (typeof link.download === "string") {
        link.href = data;
        link.download = `${fileName}.jpg`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(data);
      }
    }
  };

  return {
    printRef,
    handleDownloadImage,
    handlePrint,
    handleCopyToClipboard,
  };
};

export default usePrintComponent;
