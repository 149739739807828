import {
  AssignmentTurnedIn,
  Business,
  Comment,
  Engineering,
  FactCheck,
  FileUpload,
  MiscellaneousServices,
  NavigateNext,
  Policy,
  ShoppingCart,
  Task,
} from '@mui/icons-material';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { Box, Breadcrumbs, Button, Grid, Link } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useCheckPermission from '../../../../hooks/useCheckPermission';
import { setWorkItemStep } from '../../../../stateManagement/actions/workItemActions';
import { AttachmentTypes } from '../../../../types/AttachmentTypes';
import FilesPage from '../../../chimneySweeping/Files/FilesPage';
import CommentStep from './steps/CommentStep';
import CompleteWorkItem from './steps/CompleteWorkItem';
import EvaluationStep from './steps/EvaluationStep';
import InternalCommentStep from './steps/InternalCommentStep';
import LegalStep from './steps/LegalStep';
import SelectClientStep from './steps/SelectClientStep';
import SelectItemPackagesStep from './steps/SelectItemPackagesStep';
import SelectItemsStep from './steps/SelectItemsStep';
import SelectServicesStep from './steps/SelectServicesStep';
import TaskDescriptorStep from './steps/TaskDescriptorStep';
import WorkItemAssignmentStep from './steps/WorkItemAssignmentStep';
import WorkItemHistory from './steps/WorkItemHistory';

const WorkItemWizard: React.FC = (props: any) => {
  const fullscreenMode = useSelector((state: any) => state.app.fullscreenMode);
  const navigate = useNavigate();
  const { checkPermission } = useCheckPermission();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();

  const readyForEvaluation = () => {
    return checkPermission(['EvaluationView']);
  };

  const [step, setStep] = useState(0);

  useEffect(() => {
    if (props.step) {
      setStep(parseInt(props.step));
      dispatch(setWorkItemStep(parseInt(props.step)));
    }
  }, [props.step]);

  const handleNext = () => {
    const visibleSteps = steps.filter((s) => s.visibility).map((x) => x.step);
    const maxStep = visibleSteps.reduce((a, b) => Math.max(a, b));
    let nextStep = step + 1;
    while (
      !visibleSteps.some((x) => x === nextStep) &&
      nextStep < maxStep + 1
    ) {
      nextStep++;
    }

    if (nextStep < maxStep + 1) {
      setStep(nextStep);
      dispatch(setWorkItemStep(nextStep));
      navigate(
        `/erp/workitems/edit/${props.entity.id}/${nextStep}/${props.source}`
      );
    }
  };

  const handleBack = () => {
    if (step === 0) {
      return;
    } else {
      const visibleSteps = steps.filter((s) => s.visibility).map((x) => x.step);
      const minStep = visibleSteps.reduce((a, b) => Math.min(a, b));
      let nextStep = step - 1;
      while (
        !visibleSteps.some((x) => x === nextStep) &&
        nextStep > minStep - 1
      ) {
        nextStep--;
      }

      if (nextStep > minStep - 1) {
        setStep(nextStep);
        dispatch(setWorkItemStep(nextStep));
        navigate(
          `/erp/workitems/edit/${props.entity.id}/${nextStep}/${props.source}`
        );
      }
    }
  };

  const steps = [
    {
      label: 'Ügyfél kiválasztása',
      step: 0,
      visibility: props.currentRolePermissionNames?.some(
        (x) => x === 'ClientStepView'
      ),
      component: (
        <SelectClientStep
          {...props}
          readonly={
            props.readonly ||
            !props.currentRolePermissionNames?.some(
              (x) => x === 'ClientStepEdit'
            )
          }
        />
      ),
      icon: <Business />,
    },
    {
      label: 'Feladat leírása',
      visibility: props.currentRolePermissionNames?.some(
        (x) => x === 'TaskDescriptorStepView'
      ),
      step: 1,
      component: (
        <TaskDescriptorStep
          {...props}
          readonly={
            props.readonly ||
            !props.currentRolePermissionNames?.some(
              (x) => x === 'TaskDescriptorStepEdit'
            )
          }
        />
      ),
      icon: <Task />,
    },
    {
      label: 'Szolgáltatások kiválasztása',
      visibility: props.currentRolePermissionNames?.some(
        (x) => x === 'SelectServicesStepView'
      ),
      step: 2,
      component: (
        <SelectServicesStep
          {...props}
          readonly={
            props.readonly ||
            !props.currentRolePermissionNames?.some(
              (x) => x === 'SelectServicesStepEdit'
            )
          }
        />
      ),
      icon: <MiscellaneousServices />,
    },
    {
      label: 'Tételek kiválasztása',
      visibility: props.currentRolePermissionNames?.some(
        (x) => x === 'SelectItemsStepView'
      ),
      step: 3,
      component: (
        <SelectItemsStep
          {...props}
          readonly={
            props.readonly ||
            !props.currentRolePermissionNames?.some(
              (x) => x === 'SelectItemsStepEdit'
            )
          }
        />
      ),
      icon: <ShoppingCart />,
    },
    {
      label: 'Termékcsomagok kiválasztása',
      visibility: props.currentRolePermissionNames?.some(
        (x) => x === 'SelectItemPackagesStepView'
      ),
      step: 4,
      component: (
        <SelectItemPackagesStep
          {...props}
          readonly={
            props.readonly ||
            !props.currentRolePermissionNames?.some(
              (x) => x === 'SelectItemPackagesStepEdit'
            )
          }
        />
      ),
      icon: <Inventory2Icon />,
    },
    {
      label: 'Megjegyzés',
      visibility: props.currentRolePermissionNames?.some(
        (x) => x === 'CommentStepView'
      ),
      step: 5,
      component: (
        <CommentStep
          {...props}
          readonly={
            props.readonly ||
            !props.currentRolePermissionNames?.some(
              (x) => x === 'CommentStepEdit'
            )
          }
        />
      ),
      icon: <Comment />,
    },
    {
      label: 'Belső megjegyzés',
      visibility: props.currentRolePermissionNames?.some(
        (x) => x === 'InternalCommentStepView'
      ),
      step: 6,
      component: (
        <InternalCommentStep
          {...props}
          readonly={
            props.readonly ||
            !props.currentRolePermissionNames?.some(
              (x) => x === 'InternalCommentStepEdit'
            )
          }
        />
      ),
      icon: <Comment />,
    },
    {
      label: 'Szerepkör kiosztása',
      visibility:
        props.currentUser.authorityInfo.roles?.some(
          (r: any) => r.name === 'Dispatcher'
        ) || checkPermission(['WorkItemAssignmentStepView']),
      step: 7,
      component: <WorkItemAssignmentStep {...props} />,
      icon: <Engineering />,
    },
    {
      label: 'Fájlok feltöltése',
      visibility: props.currentRolePermissionNames?.some(
        (x) => x === 'CreateFileAttachment'
      ),
      step: 8,
      component: (
        <FilesPage
          attachmentType={AttachmentTypes.WorkItem}
          entityUniqueId={props.values.uniqueId}
          isDialog={false}
        />
      ),
      icon: <FileUpload />,
    },
    {
      label: 'Feltételek elfogadása',
      visibility: props.currentRolePermissionNames?.some(
        (x) => x === 'LegalStepView'
      ),
      step: 9,
      component: (
        <LegalStep
          {...props}
          readonly={
            props.readonly ||
            !props.currentRolePermissionNames?.some(
              (x) => x === 'LegalStepEdit'
            )
          }
        />
      ),
      icon: <Policy />,
    },
    {
      label: 'Munkalap zárása',
      visibility: props.currentRolePermissionNames?.some(
        (x) => x === 'CompleteStepView'
      ),
      step: 10,
      component: (
        <CompleteWorkItem
          {...props}
          readonly={
            props.readonly ||
            !props.currentRolePermissionNames?.some(
              (x) => x === 'CompleteStepEdit'
            )
          }
        />
      ),
      icon: <FactCheck />,
    },
    {
      label: 'Munkalap kiértékelése',
      visibility: readyForEvaluation(),
      step: 11,
      component: <EvaluationStep {...props} />,
      icon: <AssignmentTurnedIn />,
    },
    {
      label: 'Munkalap napló',
      visibility: checkPermission(['WorkItemHistoryView']),
      step: 12,
      component: <WorkItemHistory {...props} />,
      icon: <AssignmentTurnedIn />,
    },
  ];

  const breadcrumbs = steps
    .filter((x) => x.visibility)
    .map((s, i) => {
      const textFull = `${i + 1}. ${s.label}`;
      return (
        <Box key={s.label}>
          <Link
            underline={step === s.step ? 'always' : 'none'}
            color={step === s.step ? 'primary' : 'inherit'}
            onClick={() => {
              dispatch(setWorkItemStep(s.step));
              navigate(
                `/erp/workitems/edit/${props.entity.id}/${s.step}/${props.source}`
              );
            }}
            sx={{ display: { xs: 'flex', md: 'none' } }}
          >
            {s.icon}
          </Link>
          <Link
            underline={step === s.step ? 'always' : 'none'}
            color={step === s.step ? 'primary' : 'inherit'}
            onClick={() => {
              dispatch(setWorkItemStep(s.step));
              navigate(
                `/erp/workitems/edit/${props.entity.id}/${s.step}/${props.source}`
              );
            }}
            sx={{ display: { xs: 'none', md: 'flex' } }}
          >
            {s.icon}
            {textFull}
          </Link>
        </Box>
      );
    });

  const currentStep = steps.find((s) => s.step === step);

  return (
    <Grid container p={2}>
      <Grid item>
        <Breadcrumbs
          maxItems={10}
          separator={<NavigateNext />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Grid>
      <Grid item container pt={3}>
        {currentStep.visibility ? currentStep.component : null}
      </Grid>
      <Grid container spacing={5} justifyContent="center" sx={{ pt: 5 }}>
        <Grid item>
          <Button variant="outlined" onClick={handleBack}>
            Vissza
          </Button>
        </Grid>
        {step < steps.length - 1 && (
          <Grid item>
            <Button variant="contained" onClick={handleNext}>
              Következő
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default WorkItemWizard;
