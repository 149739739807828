import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextFieldProps,
  TextField,
} from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import React from 'react';

const UserAttendanceCreateDialog = (props: any) => {
  const { open, onClose, onSave, entity } = props;
  const [localEntity, setLocalEntity] = React.useState<any>({
    id: 0,
    description: '',
    startOfWork: '',
    endOfWork: '',
    startOfLunch: '',
    endOfLunch: '',
    isSickHoliday: false,
    isHoliday: false,
  });

  React.useEffect(() => {
    if (entity) {
      setLocalEntity(entity);
    }
  }, [entity]);

  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogTitle>Jelenléti ív</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} pt={2}>
          <Grid item xs={6}>
            <TimePicker
              label="Munka kezdete"
              value={localEntity.startOfWork}
              onChange={(value) =>
                setLocalEntity({ ...localEntity, startOfWork: value })
              }
              renderInput={(params) => <TextField {...params} />}
              ampm={false}
            />
          </Grid>
          <Grid item xs={6}>
            <TimePicker
              label="Munka vége"
              value={localEntity.endOfWork}
              onChange={(value) =>
                setLocalEntity({ ...localEntity, endOfWork: value })
              }
              renderInput={(params) => <TextField {...params} />}
              ampm={false}
            />
          </Grid>
          <Grid item xs={6}>
            <TimePicker
              label="Ebéd kezdete"
              value={localEntity.startOfLunch}
              onChange={(value) =>
                setLocalEntity({ ...localEntity, startOfLunch: value })
              }
              renderInput={(params) => <TextField {...params} />}
              ampm={false}
            />
          </Grid>
          <Grid item xs={6}>
            <TimePicker
              label="Ebéd vége"
              value={localEntity.endOfLunch}
              onChange={(value) =>
                setLocalEntity({ ...localEntity, endOfLunch: value })
              }
              renderInput={(params) => <TextField {...params} />}
              ampm={false}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Megjegyzés"
              value={localEntity.description}
              multiline
              fullWidth
              minRows={3}
              onChange={(event) =>
                setLocalEntity({
                  ...localEntity,
                  description: event.target.value,
                })
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => onSave(localEntity)}>
          Mentés
        </Button>
        <Button variant="outlined" onClick={() => onClose()}>
          Mégse
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserAttendanceCreateDialog;
