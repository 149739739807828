import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { GridColDef } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import DataList from '../../../../components/DataList';

export default function UserWorkHourDialog(props: any) {
  const { open, setOpen, workHourHistory } = props;
  const [rows, setRows] = React.useState<any[]>([]);

  const columns: GridColDef[] = [
    {
      field: 'userName',
      headerName: 'Dolgozó',
      flex: 200,
    },
    {
      field: 'workHour',
      headerName: 'Munkaóra száma',
      flex: 100,
    },
    {
      field: 'startDate',
      headerName: 'Kezdés',
      valueFormatter(params) {
        return new Date(params.value).toLocaleDateString();
      },
      flex: 100,
    },
    {
      field: 'endDate',
      headerName: 'Befejezés',
      valueFormatter(params) {
        if (params.value === null) return 'Nincs';
        return new Date(params.value).toLocaleDateString();
      },
      flex: 100,
    },
  ];

  React.useEffect(() => {
    if (workHourHistory) {
      setRows(workHourHistory);
    }
  }, [workHourHistory]);

  return (
    <Dialog
      open={open}
      style={{ minHeight: 500 }}
      fullWidth
      maxWidth="lg"
      onClose={() => setOpen({ open: false, workHourHistory: [] })}
    >
      <DialogTitle>Munkaóra napló:</DialogTitle>
      <DialogContent>
        <Grid container style={{ minHeight: '30vh' }} pt={5}>
          <Grid item xs={12}>
            <DataList
              rows={rows}
              columns={columns}
              getRowId={(row) => row.id}
              localStorageKey={'UserWorkHourDialog'}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="error"
          variant="outlined"
          onClick={() => setOpen({ open: false, itemId: 0 })}
        >
          Mégse
        </Button>
      </DialogActions>
    </Dialog>
  );
}
