import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { GridColDef } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import DataList from '../../../components/DataList';
import useCheckPermission from '../../../hooks/useCheckPermission';
import itemService from '../../../services/wms/itemService';
import { EntityTypes } from '../../../types/EntityTypes';
import EntityNavigator from '../../../components/EntityNavigator';

export default function SupplierDialog(props: any) {
  const { itemId, open, setOpen } = props;
  const [rows, setRows] = React.useState<any[]>([]);

  const { checkPermission } = useCheckPermission();
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setOpen({ open: false, itemId: 0 });
    setRows([]);
  };

  const columns: GridColDef[] = [
    {
      field: 'clientName',

      headerName: 'Cég neve',
      flex: 200,
      renderCell(params) {
        return (
          <EntityNavigator
            entityType={EntityTypes.Client}
            entityId={params.row.clientId}
            value={params.row.clientName}
          />
        );
      },
      valueGetter: (params: any) => {
        return params.row.clientName;
      },
    },
    {
      field: 'externalItemNumber',
      headerName: 'Beszállítói cikkszám',
      flex: 100,
    },
  ];

  React.useEffect(() => {
    if (itemId > 0) {
      itemService.getSuppliers(itemId).then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          setRows(response.records);
        }
      });
    }
  }, [itemId]);

  return (
    <Dialog
      open={open}
      style={{ minHeight: 500 }}
      fullWidth
      onClose={() => handleClose()}
    >
      <DialogTitle>Beszállítók:</DialogTitle>
      <DialogContent>
        <Grid container style={{ minHeight: '30vh' }} pt={5}>
          <Grid item xs={12}>
            <DataList
              rows={rows}
              columns={columns}
              getRowId={(row) => row.clientId}
              localStorageKey={'SupplierDialog'}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="error" variant="outlined" onClick={() => handleClose()}>
          Mégse
        </Button>
      </DialogActions>
    </Dialog>
  );
}
