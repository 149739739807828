import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import OrderView from '../../pages/sales/orders/OrderView';
import SmartSearchTabPage from './SmartSearchTabPage';

const SmartSearchDialog = (props: any) => {
  const { open, onClose } = props;

  return (
    <Dialog open={open} onClose={() => onClose()} fullWidth maxWidth={'xl'}>
      <DialogContent style={{ padding: '0px' }}>
        <Grid item xs={12}>
          <SmartSearchTabPage />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={() => onClose()}>
          Kilépés
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SmartSearchDialog;
