import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ExpenseForm from './ExpenseForm';
import { useNavigate } from 'react-router-dom';
import useCheckPermission from '../../../hooks/useCheckPermission';
import expenseService from '../../../services/erp/expenseService';
import expenseTypeService from '../../../services/erp/expenseTypeService';
import clientService from '../../../services/crm/clientService';
import { ClientTypes } from '../../../types/ClientTypes';
import taxTypeService from '../../../services/erp/taxTypeService';
import { EntityStatuses } from '../../../types/EntityStatuses';
import { Currency } from '../../../types/Currency';
import { setConversionRate } from '../../../stateManagement/actions/itemActions';

const ExpenseCreate = (props: any) => {
  const { onSave = null, navigateBack = true, handleCancel = null } = props;
  const [now] = useState(new Date());
  const [expenseTypes, setExpenseTypes] = useState<any[]>([
    { id: '', name: '', description: '' },
  ]);

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<string[]>([]);
  const [clients, setClients] = useState<any[]>([]);
  const [taxes, setTaxes] = useState<any[]>([]);
  const [entity, setEntity] = useState<any>({
    clientId: '',
    companyId: 0,
    taxTypeId: 0,
    paymentType: 'Készpénz',
    receiptNumber: '',
    paymentDeadline: now,
    dateOfPayment: '',
    currency: Currency.HUF,
    conversionRate: 1,
    isPaid: false,
    date: now,
    priceGross: 0,
    description: '',
    expenseTypeId: 0,
  });

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    expenseTypeService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setExpenseTypes(response.records);
      }
    });
  }, []);

  useEffect(() => {
    taxTypeService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setTaxes(response.records);
      }
    });
  }, []);

  useEffect(() => {
    clientService.list(false, EntityStatuses.Approved).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setClients(response.records);
        setEntity({
          ...entity,
          companyId: response.records.filter(
            (x) => x.clientType === ClientTypes.Distributor
          )[0].id,
        });
      }
    });
  }, []);

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    expenseService
      .create(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });

          if (onSave) onSave(response.result);
          if (navigateBack) navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        setSubmitting(false);
      });
  };

  return (
    <ExpenseForm
      entity={entity}
      readonly={!checkPermission(['ExpenseCreate'])}
      onSubmit={onSubmit}
      taxes={taxes}
      onNewExpenseTypeAdded={(expenseType) => {
        setExpenseTypes([...expenseTypes, expenseType]);
      }}
      errors={errors}
      clients={clients}
      handleCancel={() =>
        handleCancel != null ? handleCancel() : navigate(-1)
      }
      expenseTypes={expenseTypes}
    />
  );
};

export default ExpenseCreate;
