import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CashLogForm from './CashLogForm';
import { useNavigate } from 'react-router-dom';
import useCheckPermission from '../../../hooks/useCheckPermission';
import cashLogService from '../../../services/billing/cashLogService';
import clientService from '../../../services/crm/clientService';
import { EntityStatuses } from '../../../types/EntityStatuses';
import userService from '../../../services/authority/userService';
import moment from 'moment';
import cashRegisterService from '../../../services/billing/cashRegisterService';
import { useLocalStorage } from '../../../hooks/useLocalStorage';

const CashLogCreate = (props: any) => {
  const {
    onSave = null,
    navigateBack = true,
    handleCancel = null,
    externalEntity,
  } = props;

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<[]>([]);
  const [clients, setClients] = useState<any[]>([]);
  const [users, setUsers] = useState<any[]>([]);
  const [registers, setRegisters] = useState<any[]>([]);
  const user = useSelector((state: any) => state.user.userInfo);

  const registerLocal = useLocalStorage('CashLogCreateRegisterId');
  const [entity, setEntity] = useState<any>({
    receiptNumber: '',
    userId: '',
    clientId: '',
    description: '',
    incomeGross: 0,
    expenseGross: 0,
    cashRegisterId: 0,
  });

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    if (externalEntity) {
      setEntity(externalEntity);
    }
  }, [externalEntity]);

  useEffect(() => {
    if (registerLocal.value) {
      setEntity({ ...entity, cashRegisterId: parseInt(registerLocal.value) });
    }
  }, [registerLocal.value, entity]);

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    clientService
      .list(false, EntityStatuses.Approved)
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) setClients(response.records);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    userService
      .list()
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) setUsers(response.records);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    registerLocal.setLocalStorageValue(entity.cashRegisterId);
    cashLogService
      .create(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });

          if (onSave) onSave(response.result);
          if (navigateBack) navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        setSubmitting(false);
      });
  };

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    cashRegisterService
      .list()
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) {
          setRegisters(
            response.records.filter((x) => x.userId === user?.userId)
          );
        } else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  return (
    <CashLogForm
      entity={entity}
      readonly={!checkPermission(['CreateCashLog'])}
      onSubmit={onSubmit}
      errors={errors}
      clients={clients}
      users={users}
      registers={registers}
      handleCancel={() =>
        handleCancel != null ? handleCancel() : navigate(-1)
      }
    />
  );
};

export default CashLogCreate;
