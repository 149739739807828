import { DialogContent, useMediaQuery, DialogActions } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';

export default function CreateEntityDialog(props: any) {
  const {
    open,
    handleCancel,
    CreateEntityComponent,
    isClientCreate = false,
    ...rest
  } = props;
  const isSmallScreen = useMediaQuery('(min-width:600px)');

  return (
    <Dialog
      open={open}
      style={{ minHeight: 500 }}
      fullWidth
      maxWidth="lg"
      onClose={handleCancel}
      fullScreen={!isSmallScreen}
    >
      <DialogContent
        sx={{ backgroundColor: isClientCreate ? '#EFEFEF' : 'white' }}
      >
        <CreateEntityComponent handleCancel={handleCancel} {...rest} />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => handleCancel()}
        >
          Kilépés
        </Button>
      </DialogActions>
    </Dialog>
  );
}
