import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Paper,
  TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Form, Formik } from 'formik';
import { ClientTypes } from '../../../types/ClientTypes';
import { CompanyAssetStates } from '../../../types/InstrumentStates';

const AccessManagementForm = (props: any) => {
  const {
    entity,
    onSubmit,
    errors,
    handleCancel,
    readonly = false,
    users,
    accessSubjectTypes,
  } = props;

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};
        if (!values.userId) {
          errors.userId = 'Required';
        }
        if (!values.accessSubjectTypeId) {
          errors.accessSubjectTypeId = 'Required';
        }
        if (!values.subject) {
          errors.subject = 'Required';
        }
        if (!values.accessLevelDescription) {
          errors.accessLevelDescription = 'Required';
        }
        if (!values.expirationDate) {
          errors.expirationDate = 'Required';
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        setFieldValue,
        handleChange,
        errors: validationErrors,
      }) => (
        <Form>
          {' '}
          <Paper>
            <Grid container justifyContent="left" spacing={2} p={5}>
              <h2>
                Felhasználói hozzáférés{' '}
                {values.id > 0 ? 'szerkesztése' : 'létrehozása'}
              </h2>
              <Grid xs={12} item pt={5}>
                <Box color="red">
                  {errors.map((error) => (
                    <li>{error}</li>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Autocomplete
                  disablePortal
                  id="userId"
                  disabled={values.id > 0 || readonly}
                  value={values.userId ?? ''}
                  onChange={(event, value) => {
                    setFieldValue('userId', value);
                  }}
                  getOptionLabel={(option) => {
                    var found = users.find((g) => g.id === option);
                    if (found) {
                      return `${found.id}.${found.fullName}`;
                    } else return '';
                  }}
                  options={users.map((g) => g.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Felhasználó"
                      required
                      fullWidth
                      error={
                        !!touched.userId && !!validationErrors.holderuserIdId
                      }
                      helperText={
                        !!touched.userId &&
                        !!validationErrors.userId &&
                        (validationErrors.userId as string)
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Autocomplete
                  disablePortal
                  id="accessSubjectTypeId"
                  disabled={values.id > 0 || readonly}
                  value={values.accessSubjectTypeId ?? ''}
                  onChange={(event, value) => {
                    setFieldValue('accessSubjectTypeId', value);
                  }}
                  getOptionLabel={(option) => {
                    var found = accessSubjectTypes.find((g) => g.id === option);
                    if (found) {
                      return `${found.name}`;
                    } else return '';
                  }}
                  options={accessSubjectTypes.map((g) => g.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Hozzáférés típusa"
                      required
                      fullWidth
                      error={
                        !!touched.accessSubjectTypeId &&
                        !!validationErrors.accessSubjectTypeId
                      }
                      helperText={
                        !!touched.accessSubjectTypeId &&
                        !!validationErrors.accessSubjectTypeId &&
                        (validationErrors.accessSubjectTypeId as string)
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.subject}
                  onChange={handleChange}
                  label="Tárgy"
                  name="subject"
                  required
                  disabled={readonly}
                  fullWidth
                  autoFocus
                  error={!!touched.subject && !!validationErrors.subject}
                  helperText={
                    !!touched.subject &&
                    !!validationErrors.subject &&
                    (validationErrors.subject as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.accessLevelDescription}
                  onChange={handleChange}
                  label="Hozzáférés szintje"
                  name="accessLevelDescription"
                  required
                  InputLabelProps={{ shrink: true }}
                  disabled={readonly}
                  fullWidth
                  error={
                    !!touched.accessLevelDescription &&
                    !!validationErrors.accessLevelDescription
                  }
                  helperText={
                    !!touched.accessLevelDescription &&
                    !!validationErrors.accessLevelDescription &&
                    (validationErrors.accessLevelDescription as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.url}
                  onChange={handleChange}
                  label="URL"
                  name="url"
                  required
                  InputLabelProps={{ shrink: true }}
                  disabled={readonly}
                  fullWidth
                  error={!!touched.url && !!validationErrors.url}
                  helperText={
                    !!touched.url &&
                    !!validationErrors.url &&
                    (validationErrors.url as string)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={'auto'} p={1}>
                <DatePicker
                  label="Hozzáférés kezdete"
                  disabled={readonly}
                  value={values.accessGainDate ?? ''}
                  onChange={(date) => setFieldValue('accessGainDate', date)}
                  renderInput={(props) => <TextField {...props} />}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={'auto'} p={1}>
                <DatePicker
                  label="Hozzáférés vége"
                  disabled={readonly}
                  value={values.expirationDate ?? ''}
                  onChange={(date) => setFieldValue('expirationDate', date)}
                  renderInput={(props) => <TextField {...props} />}
                />
              </Grid>
              <Grid container item pt={3} justifyContent="left">
                {!readonly && (
                  <Grid item p={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      Mentés
                    </Button>
                  </Grid>
                )}
                <Grid item p={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleCancel}
                  >
                    Mégse
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default AccessManagementForm;
