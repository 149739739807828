import { Box, Grid, Paper, Tab, Tabs, useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CashRegisterPage from '../cashRegisters/CashRegisterPage';
import CashLogsPage from './CashLogsPage';
import useCheckPermission from '../../../hooks/useCheckPermission';
import useFunctionDescriptor from '../../../hooks/useFunctionDescriptor';
import FinancePage from '../FinancePage';
import { useLocalStorage } from '../../../hooks/useLocalStorage';

const CashPage = () => {
  const navigate = useNavigate();
  const titleDescriptor = useFunctionDescriptor('CashPage.title');
  const { checkPermission } = useCheckPermission();
  const isSmallScreen = useMediaQuery('(max-width:900px)');
  const cashPageTabLocal = useLocalStorage('CashPageTabLocal');
  const [tabValue, setTabValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    cashPageTabLocal.setLocalStorageValue(newValue);
  };

  useEffect(() => {
    if (cashPageTabLocal.value) {
      setTabValue(parseInt(cashPageTabLocal.value));
    }
  }, [cashPageTabLocal]);

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <Box sx={{ width: '100%' }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                pb: 2,
                maxWidth: isSmallScreen ? '280px' : '100%',
              }}
            >
              <Tabs
                value={tabValue}
                onChange={handleChange}
                TabIndicatorProps={{ style: { background: '#1976d2' } }}
                sx={{
                  maxWidth: isSmallScreen ? '280px' : '100%',
                  overflowX: 'scroll',
                }}
                allowScrollButtonsMobile
                scrollButtons="auto"
                variant="scrollable"
              >
                {checkPermission(['ViewCashLog']) && (
                  <Tab label="Házipénztár" sx={{ fontWeight: 'bold' }} />
                )}
                {checkPermission(['ViewCashRegister']) && (
                  <Tab label="Pénztárak" sx={{ fontWeight: 'bold' }} />
                )}
              </Tabs>
            </Box>
            {tabValue === 0 && <CashLogsPage />}
            {tabValue === 1 && <CashRegisterPage />}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CashPage;
