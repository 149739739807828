import axios from 'axios';
import { postData } from '../axiosUtils';
import { EntityStatuses } from '../../types/EntityStatuses';

const customerService = {
  list: (entity: any) => {
    return postData({
      url: `/api/customer/list`,
      data: entity,
      method: 'POST',
    }).then((response) => {
      return response;
    });
  },
  listRecommended: (entity: any) => {
    return postData({
      url: `/api/customer/listRecommended`,
      data: entity,
      method: 'POST',
    }).then((response) => {
      return response;
    });
  },

  get: (id: any) => {
    return axios.get(`/api/customer/details/${id}`).then((response) => {
      return response.data;
    });
  },
  create: (entity: any) => {
    return postData({
      url: `/api/customer/create`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  update: (entity: any) => {
    return postData({
      url: `/api/customer/update`,
      data: entity,
      method: 'PUT',
    }).then((response) => {
      return response;
    });
  },
  import: (entity: any) => {
    return postData({
      url: `/api/customer/import`,
      data: entity,
      method: 'POST',
    }).then((response) => {
      return response;
    });
  },
  delete: (id: any) => {
    return postData({
      url: `/api/customer/delete/${id}`,
      method: 'DELETE',
    }).then((response) => {
      return response;
    });
  },
};

export default customerService;
