import { combineReducers } from 'redux';
import { acquisitionReducer } from './acquisitionReducer';
import { appReducer } from './appReducer';
import { itemsReducer } from './itemsReducer';
import { salesReducer } from './salesReducer';
import { stockTakingReducer } from './stockTakingReducer';
import { userReducer } from './userReducer';
import { waitLayerReducer } from './waitLayerReducer';
import { resourcePlannerReducer } from './resourcePlannerReducer';
import { offerReducer } from './offerReducer';
import { userWarehouseReducer } from './userWarehouseReducer';
import { workItemReducer } from './workItemReducer';
import { userDistributorReducer } from './userDistributorReducer';
import { documentTemplateReducer } from './documentTemplateReducer';
import { userPriceCategoryReducer } from './userPriceCategoryReducer';

const rootReducer = combineReducers({
  waitLayer: waitLayerReducer,
  app: appReducer,
  sales: salesReducer,
  items: itemsReducer,
  stockTaking: stockTakingReducer,
  user: userReducer,
  acquisition: acquisitionReducer,
  offer: offerReducer,
  documentTemplate: documentTemplateReducer,
  workItem: workItemReducer,
  resourcePlanner: resourcePlannerReducer,
  userWarehouse: userWarehouseReducer,
  userDistributor: userDistributorReducer,
  userPriceCategory: userPriceCategoryReducer,
});

export default rootReducer;
