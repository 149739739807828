import { Download, ZoomIn, ZoomOut } from '@mui/icons-material';
import { Grid, SpeedDial, Tooltip, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfViewer = (props: any) => {
  const { pdf, downloadName } = props;
  const [numPages, setNumPages] = useState<any>(null);
  const [scale, setScale] = useState(1.0); // Default scale is 1 (100%)
  const isSmallScreen = useMediaQuery('(min-width:600px)');

  const handleZoomIn = () => setScale(scale + 0.5); // Increase scale by 0.5
  const handleZoomOut = () => setScale(scale - 0.5); // Decrease scale by 0.5

  return (
    <Grid container justifyContent={scale > 1 ? 'left' : 'center'}>
      <Document
        file={pdf}
        onLoadSuccess={({ numPages }) => setNumPages(numPages)}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            width={!isSmallScreen ? window.innerWidth : 950}
            height={1000}
            renderAnnotationLayer={false}
            renderTextLayer={false}
            scale={scale}
          />
        ))}
      </Document>
      <Tooltip title="Nagyítás">
        <SpeedDial
          ariaLabel="Nagyítás"
          onClick={() => handleZoomIn()}
          sx={{
            display: 'fixed',
            position: 'fixed',
            bottom: 170,
            right: 16,
          }}
          icon={<ZoomIn />}
        />
      </Tooltip>
      <Tooltip title="Kicsinyítés">
        <SpeedDial
          ariaLabel="Kicsinyítés"
          onClick={() => handleZoomOut()}
          sx={{
            display: 'fixed',
            position: 'fixed',
            bottom: 100,
            right: 16,
          }}
          icon={<ZoomOut />}
        />
      </Tooltip>
      <Tooltip title="Letöltés">
        <SpeedDial
          ariaLabel="Letöltés"
          onClick={() => {
            fetch(pdf)
              .then((response) => response.blob())
              .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const a = document.createElement('a');
                a.href = url;
                a.download = `${downloadName}.pdf`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
              })
              .catch((error) =>
                console.error('Error downloading file:', error)
              );
          }}
          sx={{
            display: 'fixed',
            position: 'fixed',
            bottom: 240,
            right: 16,
          }}
          icon={<Download />}
        />
      </Tooltip>
    </Grid>
  );
};

export default PdfViewer;
