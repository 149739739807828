import { Autocomplete, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import CreateEntityDecorator from '../../../../components/CreateEntityDecorator';
import useCheckPermission from '../../../../hooks/useCheckPermission';
import CertificationCreate from '../../../hr/certifications/CertificationCreate';
import { useSelector } from 'react-redux';
export default function AddUserCertificationDialog(props: any) {
  const { open, setOpen, certifications, setCertifications, onSave } = props;

  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector((state: any) => state.user.userInfo);

  const [certification, setCertification] = React.useState({
    userId: 0,
    certificationId: 0,
    documentNumber: '',
    obtainingDate: '',
    validityDate: '',
  });

  React.useEffect(() => {
    setCertification({
      ...certification,
      userId: user.userId,
    });
  }, [user]);

  const handleClose = () => {
    setOpen(false);
  };

  const { checkPermission } = useCheckPermission();

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Képesítés felvétele</DialogTitle>
      <DialogContent style={{ height: '300px' }}>
        <Grid container spacing={2} style={{ paddingTop: 10 }}>
          <Grid item xs={12}>
            <CreateEntityDecorator
              hideAdd={!checkPermission(['CertificationCreate'])}
              AutocompleteComponent={
                <Autocomplete
                  disablePortal
                  id="certificationId"
                  value={certification.certificationId ?? ''}
                  onChange={(event, value: any) => {
                    setCertification({
                      ...certification,
                      certificationId: value,
                    });
                  }}
                  getOptionLabel={(option) => {
                    var cert = certifications.find((g) => g.id === option);

                    if (cert == null || cert === undefined) {
                      return '';
                    }

                    return `${cert.name}`;
                  }}
                  options={certifications?.map((cert) => cert.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label="Képesítés"
                      fullWidth
                    />
                  )}
                />
              }
              onSave={(cert) => {
                setCertifications([...certifications, cert]);
                setCertification({
                  ...certification,
                  certificationId: cert.id,
                });
              }}
              CreateEntityComponent={CertificationCreate}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={certification.documentNumber}
              onChange={(e) => {
                setCertification({
                  ...certification,
                  documentNumber: e.target.value,
                });
              }}
              label="Okmány szám"
              name="documentNumber"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={'auto'} p={1}>
            <DatePicker
              label="Megszerzés dátuma"
              value={certification.obtainingDate ?? ''}
              onChange={(date) =>
                setCertification({ ...certification, obtainingDate: date })
              }
              renderInput={(props) => <TextField {...props} />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={'auto'} p={1}>
            <DatePicker
              label="Érvényesség"
              value={certification.validityDate ?? ''}
              onChange={(date) =>
                setCertification({ ...certification, validityDate: date })
              }
              renderInput={(props) => <TextField {...props} />}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={
            certification.certificationId === 0 ||
            certification.documentNumber === '' ||
            certification.obtainingDate === '' ||
            certification.validityDate === ''
          }
          onClick={() => {
            onSave(certification);
            setCertification({
              userId: 0,
              certificationId: 0,
              documentNumber: '',
              obtainingDate: '',
              validityDate: '',
            });
            handleClose();
          }}
        >
          Hozzáadás
        </Button>
        <Button color="primary" variant="outlined" onClick={handleClose}>
          Mégse
        </Button>
      </DialogActions>
    </Dialog>
  );
}
