import { createAsyncThunk } from '@reduxjs/toolkit';
import clientService from '../../services/crm/clientService';
import itemPriceService from '../../services/pricing/itemPriceService';
import priceCategoryService from '../../services/pricing/priceCategoryService';
import warehouseService from '../../services/wms/warehouseService';

export const loadItems = createAsyncThunk(
  'items/loadItems',
  async (payload, thunkAPI) => {
    thunkAPI.dispatch({ type: 'SHOW_QUERY' });
    let state = thunkAPI.getState() as any;
    if (state.items.warehouseId === 0 || state.items.priceCategoryId === 0) {
      thunkAPI.rejectWithValue([]);
      return;
    }
    const response = await itemPriceService
      .listListPrices(
        state.items.warehouseId,
        state.items.priceCategoryId,
        state.items.includeUnpriced
      )
      .finally(() => thunkAPI.dispatch({ type: 'HIDE' }));
    return response;
  }
);
export const loadWarehouses = createAsyncThunk(
  'items/loadWarehouses',
  async (payload, thunkAPI) => {
    thunkAPI.dispatch({ type: 'SHOW_QUERY' });
    const response = await warehouseService
      .list()
      .finally(() => thunkAPI.dispatch({ type: 'HIDE' }));

    return response;
  }
);

export const loadPriceCategories = createAsyncThunk(
  'items/loadPriceCategories',
  async (payload, thunkAPI) => {
    thunkAPI.dispatch({ type: 'SHOW_QUERY' });
    const response = await priceCategoryService
      .list()
      .finally(() => thunkAPI.dispatch({ type: 'HIDE' }));

    return response;
  }
);
