import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import Button from '@mui/material/Button';
import AddressesForm from './AddressesForm';
import { useState } from 'react';

export default function SyncAddressesDialog(props: any) {
  const { open, onClose, onAgreed, addresses } = props;
  const [selectedAddresses, setSelectedAddresses] = useState<any[]>([]);
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={() => handleClose()} fullWidth maxWidth={'lg'}>
      <DialogTitle>Talált címek</DialogTitle>
      <DialogContent>
        <Grid item xs={12} pt={2}>
          {' '}
          <Grid item xs={12}>
            <AddressesForm
              values={{ addresses: addresses }}
              setFieldValue={() => {}}
              errors={{}}
              touched={{}}
              readonly={true}
              isSelectable
              setSelectedAddresses={setSelectedAddresses}
              selectedAddresses={selectedAddresses}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            onAgreed(selectedAddresses);
            handleClose();
          }}
        >
          Elfogad
        </Button>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => handleClose()}
        >
          Mégse
        </Button>
      </DialogActions>
    </Dialog>
  );
}
