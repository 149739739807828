import { createAsyncThunk } from "@reduxjs/toolkit";
import clientService from "../../services/crm/clientService";

export const loadClients = createAsyncThunk(
  "clients/loadClients",
  async (payload, thunkAPI) => {
    thunkAPI.dispatch({ type: "SHOW_QUERY" });
    const response = await clientService
      .list(false, null)
      .finally(() => thunkAPI.dispatch({ type: "HIDE" }));

    return response;
  }
);
