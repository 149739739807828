import CloseIcon from '@mui/icons-material/Close';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import {
  Alert,
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { AttachmentTypes } from '../types/AttachmentTypes';

type FileUploadingPreviewProps = {
  onUpload: (
    fileName: string,
    contentType: string,
    data: any,
    type: AttachmentTypes,
    entityUniqueId: string | null
  ) => Promise<void>;
  filesToUpload: any[];
  filesUploaded: boolean;
  setFilesUploaded: (value: boolean) => void;
  setFilesToUpload: (value: any) => void;
  onAllFileUploaded: () => void;
};

const FileUploadingPreview = ({
  onUpload,
  filesToUpload,
  setFilesToUpload,
  filesUploaded,
  setFilesUploaded,
  onAllFileUploaded,
}: FileUploadingPreviewProps) => {
  const [uploading, setUploading] = useState(false);
  const [uploadedFileCount, setUploadedFileCount] = useState(0);

  const uploadFiles = async (filesToUpload: any[]) => {
    if (filesToUpload.length === 0 || !filesToUpload) return;
    setUploading(true);

    // Create an array to track if each file is uploaded
    const uploadStatusArray = filesToUpload.map(() => false);

    // Use Promise.all to wait for all uploads to complete
    await Promise.all(
      filesToUpload.map(async (data, index) => {
        await onUpload(
          data.fileName,
          data.contentType,
          data.data,
          data.type,
          data.entityUniqueId
        ).then((res) => {
          // Update the upload status array for the current file
          uploadStatusArray[index] = true;
          setFilesToUpload((prev) =>
            prev.map((prevData, i) => {
              if (i === index) {
                return { ...prevData, isUploaded: true };
              } else {
                return prevData;
              }
            })
          );
        });
      })
    );

    // Check if all files are uploaded
    const allFilesUploaded = uploadStatusArray.every((status) => status);

    // If all files are uploaded, set filesUploaded to true
    if (allFilesUploaded) {
      setFilesUploaded(true);
      setUploading(false);
      onAllFileUploaded();
    }
  };

  useEffect(() => {
    if (filesToUpload.length !== 0) {
      setUploadedFileCount(0);
      filesToUpload.forEach((file) => {
        if (file.isUploaded) {
          setUploadedFileCount((prev) => prev + 1);
        }
      });
    }
  }, [filesToUpload]);

  return (
    <Grid pt={5} container spacing={2} justifyContent="left">
      <Grid
        item
        xs={12}
        sx={{ overflowY: 'auto', maxHeight: '190px', overflow: 'auto' }}
      >
        {filesToUpload.length > 0 && (
          <Grid item>
            <Typography variant="subtitle1">
              Feltöltésre kerülő fájlok: {uploadedFileCount}/
              {filesToUpload.length}
            </Typography>
          </Grid>
        )}
        {filesToUpload.map((file) => (
          <Box
            sx={{
              backgroundColor: file?.isUploaded ? 'lightgreen' : 'white',
              borderRadius: '5px',
              padding: '5px',
            }}
          >
            <InsertDriveFileIcon />
            {file?.fileName}({(file?.size / 1024 / 1024).toFixed(1)} MB)
            {!uploading && !file?.isUploaded && (
              <IconButton
                onClick={() => {
                  setFilesToUpload((prev) =>
                    prev.filter((data) => data.fileName !== file.fileName)
                  );
                }}
              >
                <CloseIcon />
              </IconButton>
            )}
          </Box>
        ))}
      </Grid>
      {!filesUploaded && !uploading && (
        <Grid item container xs={12} justifyContent="center" spacing={2}>
          <Grid item>
            <Alert
              severity="warning"
              children="Figyelem! A fájlok még nem kerültek feltöltésre!"
            />
          </Grid>
          <Grid item>
            <Button
              onClick={() => {
                uploadFiles(filesToUpload);
              }}
              disabled={filesToUpload.length === 0}
              variant="contained"
            >
              Kiválasztott fájlok feltöltése
            </Button>
          </Grid>
        </Grid>
      )}
      {uploading && (
        <Grid item container xs={12} justifyContent="center">
          <Grid item>
            <Alert severity="info" children="Feltöltés folyamatban..." />
          </Grid>
        </Grid>
      )}
      {!uploading && filesUploaded && (
        <Grid item container xs={12} justifyContent="center">
          <Grid item>
            <Alert
              severity="success"
              children="A fájlok feltöltésre kerültek!"
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default FileUploadingPreview;
