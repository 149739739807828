import {
  Button,
  TextField,
  Box,
  Grid,
  Paper,
  Autocomplete,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { DocumentBlockFormats } from '../../../types/DocumentBlockFormats';
import { translateDocumentBlockTypesName } from '../../../utils/nameFormatters';
import { DocumentBlockTypes } from '../../../types/DocumentBlockTypes';
import DocumentTemplateTestView from '../documentTemplates/DocumentTemplateTestView';
import { DocumentTemplateType } from '../../../types/DocumentTemplateType';

const DocumentBlockForm = (props: any) => {
  const {
    entity,
    onSubmit,
    errors,
    handleCancel,
    readonly = false,
    users,
    documentTemplates,
  } = props;

  const getType = (type: DocumentBlockTypes) => {
    if (type) {
      switch (type) {
        case DocumentBlockTypes.DepositInvoice:
          return DocumentTemplateType.DepositInvoice;
        case DocumentBlockTypes.Invoice:
          return DocumentTemplateType.Invoice;
        case DocumentBlockTypes.Offer:
          return DocumentTemplateType.Offer;
        case DocumentBlockTypes.Waybill:
          return DocumentTemplateType.DeliveryNote;
        case DocumentBlockTypes.Receipt:
          return DocumentTemplateType.Receipt;
        case DocumentBlockTypes.WorkItem:
          return DocumentTemplateType.WorkItem;
        case DocumentBlockTypes.FeeCollection:
          return DocumentTemplateType.FeeCollection;

        default:
          return '';
      }
    }
  };

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};
        if (!values.name) {
          errors.name = 'Required';
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        handleChange,
        setFieldValue,
        errors: validationErrors,
      }) => (
        <Form>
          {' '}
          <Paper>
            <Grid container justifyContent="left" spacing={2} p={5}>
              <h2>
                Bizonylattömb {values.id > 0 ? 'szerkesztése' : 'létrehozása'}
              </h2>
              <Grid xs={12} item pt={5}>
                <Box color="red">
                  {errors.map((error) => (
                    <li>{error}</li>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  value={values.type}
                  onChange={(event, newValue) => {
                    setFieldValue('type', newValue);
                    setFieldValue('documentTemplateId', null);
                  }}
                  options={Object.values(DocumentBlockTypes).filter((x) =>
                    Number.isFinite(x)
                  )}
                  getOptionLabel={(option) => {
                    return translateDocumentBlockTypesName(option);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Típus"
                      name="type"
                      disabled={readonly}
                      fullWidth
                      error={!!touched.type && !!validationErrors.type}
                      helperText={
                        !!touched.type &&
                        !!validationErrors.type &&
                        (validationErrors.type as string)
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="userIds"
                  multiple
                  value={values.userIds}
                  onChange={(event, value) => {
                    setFieldValue('userIds', value);
                  }}
                  getOptionLabel={(option) => {
                    let user = users.find((g) => g.id === option);
                    return `${user?.fullName} (${user?.email ?? ''})`;
                  }}
                  options={users
                    .map((g) => g.id)
                    .filter((x) => !values.userIds?.some((y) => y === x))}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth label="Felhasználók" />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.name}
                  onChange={handleChange}
                  label="Megnevezés"
                  name="name"
                  required
                  disabled={readonly}
                  fullWidth
                  autoFocus
                  error={!!touched.name && !!validationErrors.name}
                  helperText={
                    !!touched.name &&
                    !!validationErrors.name &&
                    (validationErrors.name as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.prefix}
                  onChange={(e) => {
                    const asciiRegex = /^[\x00-\x7F]*$/;

                    if (
                      asciiRegex.test(e.target.value) &&
                      e.target.value.length <= 10
                    ) {
                      setFieldValue('prefix', e.target.value.toUpperCase());
                    }
                  }}
                  label="Prefix"
                  name="prefix"
                  required
                  disabled={readonly}
                  fullWidth
                  error={!!touched.prefix && !!validationErrors.prefix}
                  helperText={
                    !!touched.prefix &&
                    !!validationErrors.prefix &&
                    (validationErrors.prefix as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.senderName}
                  onChange={handleChange}
                  label="Küldő neve"
                  name="senderName"
                  disabled={readonly}
                  fullWidth
                  error={!!touched.senderName && !!validationErrors.senderName}
                  helperText={
                    !!touched.senderName &&
                    !!validationErrors.senderName &&
                    (validationErrors.senderName as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.senderEmail}
                  onChange={handleChange}
                  label="Küldő email címe"
                  name="senderEmail"
                  disabled={readonly}
                  fullWidth
                  error={
                    !!touched.senderEmail && !!validationErrors.senderEmail
                  }
                  helperText={
                    !!touched.senderEmail &&
                    !!validationErrors.senderEmail &&
                    (validationErrors.senderEmail as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  value={values.format}
                  onChange={(event, newValue) => {
                    setFieldValue('format', newValue);
                  }}
                  options={Object.values(DocumentBlockFormats).filter((x) =>
                    Number.isFinite(x)
                  )}
                  getOptionLabel={(option) => {
                    return option === DocumentBlockFormats.Default
                      ? 'Alapértelmezett(XJZ-2020-109)'
                      : option === DocumentBlockFormats.LongWithSlash
                      ? 'Hosszú perjellel (XJZ / 2020-000109)'
                      : '';
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Formátum"
                      name="format"
                      disabled={readonly}
                      fullWidth
                      error={!!touched.format && !!validationErrors.format}
                      helperText={
                        !!touched.format &&
                        !!validationErrors.format &&
                        (validationErrors.format as string)
                      }
                    />
                  )}
                />
              </Grid>
              {(values.type === DocumentBlockTypes.DepositInvoice ||
                values.type === DocumentBlockTypes.Invoice ||
                values.type === DocumentBlockTypes.Offer ||
                values.type === DocumentBlockTypes.Waybill ||
                values.type === DocumentBlockTypes.Receipt ||
                values.type === DocumentBlockTypes.WorkItem ||
                values.type === DocumentBlockTypes.FeeCollection) && (
                <Grid item xs={12}>
                  <Autocomplete
                    value={values.documentTemplateId}
                    onChange={(event, newValue) => {
                      setFieldValue('documentTemplateId', newValue);
                    }}
                    options={documentTemplates
                      .filter((x) => x.type === getType(values.type))
                      ?.map((x: any) => x.id)}
                    getOptionLabel={(option) => {
                      var found = documentTemplates.find(
                        (x) => x.id === option
                      );
                      return found?.name;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Sablon"
                        disabled={readonly}
                        fullWidth
                        error={
                          !!touched.documentTemplateId &&
                          !!validationErrors.documentTemplateId
                        }
                        helperText={
                          !!touched.documentTemplateId &&
                          !!validationErrors.documentTemplateId &&
                          (validationErrors.documentTemplateId as string)
                        }
                      />
                    )}
                  />
                </Grid>
              )}
              {values.documentTemplateId > 0 &&
                (values.type === DocumentBlockTypes.DepositInvoice ||
                  values.type === DocumentBlockTypes.Invoice ||
                  values.type === DocumentBlockTypes.Offer ||
                  values.type === DocumentBlockTypes.Waybill ||
                  values.type === DocumentBlockTypes.Receipt ||
                  values.type === DocumentBlockTypes.WorkItem ||
                  values.type === DocumentBlockTypes.FeeCollection) && (
                  <Grid item xs={12}>
                    <DocumentTemplateTestView
                      type={getType(values.type)}
                      setting={
                        documentTemplates.find(
                          (x) => x.id === values.documentTemplateId
                        )?.setting
                      }
                    />
                  </Grid>
                )}
              <Grid container item pt={3} justifyContent="left">
                {!readonly && (
                  <Grid item p={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      Mentés
                    </Button>
                  </Grid>
                )}
                <Grid item p={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleCancel}
                  >
                    Mégse
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default DocumentBlockForm;
