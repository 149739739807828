import { Grid, Autocomplete, TextField } from "@mui/material";
import React from "react";
import CreateEntityDecorator from "../../../../../components/CreateEntityDecorator";
import useCheckPermission from "../../../../../hooks/useCheckPermission";
import TaskTypeCreate from "../../../taskTypes/TaskTypesCreate";
import { useEffect } from "react";
import { setForm } from "../../../../../stateManagement/actions/workItemActions";
import { useDispatch } from "react-redux";

const TaskDescriptorStep: React.FC = (props: any) => {
  const {
    values,
    setFieldValue,
    readonly,
    handleChange,
    touched,
    validationErrors,
    taskTypes,
    onTaskTypeAdded,
  } = props;

  const dispatch = useDispatch<any>();
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(setForm(values));
    }, 200);
    return () => clearTimeout(timeoutId);
  }, [values]);

  const { checkPermission } = useCheckPermission();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CreateEntityDecorator
          hideAdd={readonly || checkPermission(["TaskTypeAdd"])}
          AutocompleteComponent={
            <Autocomplete
              disablePortal
              id="taskTypeId"
              disabled={readonly}
              value={values.taskTypeId ?? ""}
              onChange={(event, value) => {
                setFieldValue("taskTypeId", value);
                setFieldValue(
                  "description",
                  taskTypes.find((g) => g.id === value)?.description ?? ""
                );
              }}
              getOptionLabel={(option) =>
                taskTypes.find((g) => g.id === option)?.name ?? ""
              }
              options={taskTypes.map((g) => g.id)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Feladat"
                  required
                  fullWidth
                  error={!!touched.taskTypeId && !!validationErrors.taskTypeId}
                  helperText={
                    !!touched.taskTypeId &&
                    !!validationErrors.taskTypeId &&
                    (validationErrors.taskTypeId as string)
                  }
                />
              )}
            />
          }
          onSave={(taskType) => {
            onTaskTypeAdded(taskType);
            setFieldValue("taskTypeId", taskType.id);
          }}
          CreateEntityComponent={TaskTypeCreate}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={values.description ?? ""}
          multiline
          rows={2}
          onChange={handleChange}
          label="Feladat leírás"
          name="description"
          fullWidth
          disabled={readonly}
          error={!!touched.description && !!validationErrors.description}
          helperText={
            !!touched.description &&
            !!(validationErrors.description as string) &&
            (validationErrors.description as string)
          }
        />
      </Grid>
    </Grid>
  );
};

export default TaskDescriptorStep;
