import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Form, Formik } from 'formik';
import { ClientTypes } from '../../../types/ClientTypes';

const MissionForm = (props: any) => {
  const {
    entity,
    onSubmit,
    errors,
    handleCancel,
    readonly = false,
    clients,
    users,
    vehicles,
    addresses,
  } = props;

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        setFieldValue,
        handleChange,
        errors: validationErrors,
      }) => (
        <Form>
          {' '}
          <Paper>
            <Grid container justifyContent="left" spacing={2} p={5}>
              <h2>
                Kiküldetés {values.id > 0 ? 'szerkesztése' : 'létrehozása'}
              </h2>
              <Grid xs={12} item pt={5}>
                <Box color="red">
                  {errors.map((error) => (
                    <li>{error}</li>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Autocomplete
                  disablePortal
                  id="userId"
                  disabled={values.id > 0 || readonly}
                  value={values.userId ?? ''}
                  onChange={(event, value) => {
                    setFieldValue('userId', value);
                  }}
                  getOptionLabel={(option) => {
                    var found = users.find((g) => g.id === option);
                    if (found) {
                      return `${found.id}.${found.fullName}`;
                    } else return '';
                  }}
                  options={users.map((g) => g.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Dolgozó"
                      required
                      fullWidth
                      error={!!touched.userId && !!validationErrors.userId}
                      helperText={
                        !!touched.userId &&
                        !!validationErrors.userId &&
                        (validationErrors.userId as string)
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Autocomplete
                  disablePortal
                  id="clientId"
                  disabled={values.id > 0 || readonly}
                  value={values?.clientId ?? ''}
                  onChange={(event, value) => {
                    setFieldValue('clientId', value);
                  }}
                  getOptionLabel={(option) =>
                    clients?.find((g) => g.id === option)?.companyName ?? ''
                  }
                  options={clients?.map((g) => g.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      error={!!touched.clientId && !!validationErrors.clientId}
                      helperText={
                        !!touched.clientId &&
                        !!validationErrors.clientId &&
                        (validationErrors.clientId as string)
                      }
                      fullWidth
                      label="Ügyfél"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  disablePortal
                  id="fromId"
                  disabled={readonly}
                  value={values?.fromId ?? ''}
                  onChange={(event, value) => {
                    setFieldValue('fromId', value);
                  }}
                  getOptionLabel={(option) =>
                    addresses?.find((g) => g.id === option)?.fullAddress ?? ''
                  }
                  options={addresses.map((g) => g.id) ?? []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      error={!!touched.fromId && !!validationErrors.fromId}
                      helperText={
                        !!touched.fromId &&
                        !!validationErrors.fromId &&
                        (validationErrors.fromId as string)
                      }
                      fullWidth
                      label="Honnan"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  disablePortal
                  id="toId"
                  disabled={readonly}
                  value={values?.toId ?? ''}
                  onChange={(event, value) => {
                    setFieldValue('toId', value);
                  }}
                  getOptionLabel={(option) =>
                    clients
                      ?.find((x) => x.id === values.clientId)
                      ?.addresses.find((g) => g.id === option)?.fullAddress ??
                    ''
                  }
                  options={
                    clients
                      ?.find((x) => x.id === values.clientId)
                      ?.addresses.map((g) => g.id) ?? []
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      error={!!touched.toId && !!validationErrors.toId}
                      helperText={
                        !!touched.toId &&
                        !!validationErrors.toId &&
                        (validationErrors.toId as string)
                      }
                      fullWidth
                      label="Hova"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Autocomplete
                  disablePortal
                  id="vehicleId"
                  disabled={readonly}
                  value={values.vehicleId ?? ''}
                  onChange={(event, value) => {
                    setFieldValue('vehicleId', value);
                  }}
                  getOptionLabel={(option) => {
                    var found = vehicles?.find((g) => g.id === option);
                    if (found) {
                      return `${found.name}`;
                    } else return '';
                  }}
                  options={vehicles?.map((g) => g.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Jármű"
                      required
                      fullWidth
                      error={
                        !!touched.vehicleId && !!validationErrors.vehicleId
                      }
                      helperText={
                        !!touched.vehicleId &&
                        !!validationErrors.vehicleId &&
                        (validationErrors.vehicleId as string)
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.name}
                  onChange={handleChange}
                  label="Név"
                  name="name"
                  required
                  InputLabelProps={{ shrink: true }}
                  disabled={readonly}
                  fullWidth
                  error={!!touched.name && !!validationErrors.name}
                  helperText={
                    !!touched.name &&
                    !!validationErrors.name &&
                    (validationErrors.name as string)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={'auto'} p={1}>
                <DatePicker
                  label="Dátum"
                  disabled={readonly}
                  value={values.date ?? ''}
                  onChange={(date) => setFieldValue('date', date)}
                  renderInput={(props) => <TextField {...props} />}
                />
              </Grid>
              <Grid container item pt={3} justifyContent="left">
                {!readonly && (
                  <Grid item p={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      Mentés
                    </Button>
                  </Grid>
                )}
                <Grid item p={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleCancel}
                  >
                    Mégse
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default MissionForm;
