import {
  GridColDef,
  GridActionsCellItem,
  GridActionsColDef,
} from '@mui/x-data-grid';
import {
  Paper,
  IconButton,
  Grid,
  Tooltip,
  Stack,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Visibility,
  CheckCircle as ApproveIcon,
  Cancel as RejectIcon,
} from '@mui/icons-material';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import DataList from '../../../components/DataList';
import { useEffect, useState } from 'react';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useCheckPermission from '../../../hooks/useCheckPermission';
import useFunctionDescriptor from '../../../hooks/useFunctionDescriptor';
import useConfirmDeleteDialog from '../../../hooks/useConfirmDeleteDialog';
import { parseJSON } from 'date-fns';
import { formatCurrency } from '../../../utils/valueFormatters';
import { EntityStatuses } from '../../../types/EntityStatuses';
import holidayService from '../../../services/erp/holidayService';
import RejectDialog from '../../wms/items/components/RejectDialog';
import emailtemplateService from '../../../services/emails/emailtemplateService';
import { EmailTemplateType } from '../../../types/EmailTemplateType';

export interface EmailTemplate {
  id: string;
  name: string;
  description: string;
}

const EmailTemplatePage = (props: any) => {
  const navigate = useNavigate();
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const [rows, setRows] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const titleDescriptor = useFunctionDescriptor('EmailTemplatePage.title');
  const { emailTemplateType } = props;

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    emailtemplateService
      .list()
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError)
          setRows(response.records.filter((r) => r.type === emailTemplateType));
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  const setDefault = (id: number) => {
    dispatch({ type: 'SHOW_SAVE' });
    emailtemplateService
      .setDefault(id)
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) {
          setRows(
            rows.map((r) => {
              r.isDefault = r.id === id;
              return r;
            })
          );
        } else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  };

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];
    if (
      checkPermission(['DefaultEmailTemplateChange']) &&
      !params.row.isDefault
    ) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Beállítás alapértelmezettként">
              <SettingsApplicationsIcon />
            </Tooltip>
          }
          label="Beállítás alapértelmezettként"
          onClick={() => setDefault(params.row.id)}
        />
      );
    }

    let readonly = !checkPermission(['EmailTemplateEdit']);
    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          readonly ? (
            <Tooltip title="Megtekintés">
              <Visibility />
            </Tooltip>
          ) : (
            <Tooltip title="Szerkesztés">
              <EditIcon />
            </Tooltip>
          )
        }
        label={readonly ? 'Megtekintés' : 'Szerkesztés'}
        onClick={() => navigate(`/erp/emailTemplates/edit/${params.row.id}`)}
      />
    );

    if (checkPermission(['EmailTemplateDelete'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Törlés">
              <DeleteIcon />
            </Tooltip>
          }
          label="Törlés"
          onClick={() =>
            setParams({
              open: true,
              name: '',
              onConfirm: async () => handleDelete(params.row.id),
            })
          }
        />
      );
    }

    return actions;
  };

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Név', flex: 100 },
    { field: 'subject', headerName: 'Tárgy', flex: 100 },
    {
      field: 'isDefault',
      headerName: 'Alapértelmezett',
      valueFormatter(params) {
        return params.value ? 'Igen' : 'Nem';
      },
      flex: 100,
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 150,
      align: 'right',
      getActions: getActions,
    } as GridActionsColDef,
  ];

  const handleDelete = (id: number) => {
    emailtemplateService.delete(id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres törlés', {
          variant: 'success',
        });
        setRows(rows?.filter((row) => row.id !== id));
      }
    });
  };

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>
            {emailTemplateType === EmailTemplateType.Offer
              ? 'Árajánlat'
              : emailTemplateType === EmailTemplateType.Order
              ? 'Megrendelés'
              : emailTemplateType ===
                EmailTemplateType.ChimneySweepingCertificate
              ? 'Kéményseprő-ipari tanúsítvány'
              : 'Munkalap'}{' '}
            sablonok
            {titleDescriptor}
          </h2>
        </Grid>
        {checkPermission(['EmailTemplateCreate']) && (
          <Grid container item xs={12} p={2} justifyContent="end">
            <Grid item>
              <Tooltip title="Email sablon létrehozása">
                <IconButton
                  component={RouterLink}
                  to={`/erp/emailTemplates/create/${emailTemplateType}`}
                  color="primary"
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <DataList
            rows={rows}
            columns={columns}
            localStorageKey={'EmailTemplatePage'}
          />
        </Grid>
        <ConfirmDeleteDialog />
      </Grid>
    </Paper>
  );
};

export default EmailTemplatePage;
