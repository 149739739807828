const initialState = {
  accessToken: '',
  pendingItemsApprovalCount: 0,
  functionDescriptors: [],
  fullscreenMode: false,
  hasClosingSheet: false,
  sasToken: '',
};

export const appReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'SET_ACCESS_TOKEN':
      return {
        ...state,
        accessToken: action.payload,
      };
    case 'SET_SAS_TOKEN':
      return {
        ...state,
        sasToken: action.payload,
      };
    case 'SET_HAS_CLOSING_SHEET':
      return {
        ...state,
        hasClosingSheet: action.payload,
      };
    case 'LOAD_APP_STATUS':
      return {
        ...state,
        pendingItemsApprovalCount: action.payload.pendingItemsApprovalCount,
      };
    case 'TOGGLE_FULLSCREEN_MODE':
      return {
        ...state,
        fullscreenMode: !state.fullscreenMode,
      };
    case 'LOAD_FUNCTION_DESCRIPTORS':
      return {
        ...state,
        functionDescriptors: action.payload,
      };
    case 'DECREASE_APPROVAL_COUNT':
      return {
        ...state,
        pendingItemsApprovalCount: state.pendingItemsApprovalCount - 1,
      };
    default:
      return state;
  }
};
