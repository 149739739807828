import { Collapse, Grid, Paper } from '@mui/material';
import { useState } from 'react';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

export const CollapsePaper = (props) => {
  const [open, setOpen] = useState(!props.collapsed);
  const { color = 'white' } = props;

  return (
    <Paper sx={{ bgcolor: color }}>
      <ListItem>
        <ListItemText
          onClick={() => setOpen((x) => !x)}
          sx={{ color: 'black', userSelect: 'none' }}
        >
          <Grid container justifyContent="space-between">
            <Grid item>{props.title}</Grid>
            <Grid item> {open ? <ExpandLess /> : <ExpandMore />}</Grid>
          </Grid>
        </ListItemText>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {props.children}
      </Collapse>
      <Divider sx={{ mt: 2 }} />
    </Paper>
  );
};
