import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ExpenseForm from './ExpenseForm';
import { useNavigate, useParams } from 'react-router-dom';
import useCheckPermission from '../../../hooks/useCheckPermission';
import expenseService from '../../../services/erp/expenseService';
import expenseTypeService from '../../../services/erp/expenseTypeService';
import clientService from '../../../services/crm/clientService';
import { ClientTypes } from '../../../types/ClientTypes';
import { EntityStatuses } from '../../../types/EntityStatuses';
import taxTypeService from '../../../services/erp/taxTypeService';
import { Currency } from '../../../types/Currency';
import { setConversionRate } from '../../../stateManagement/actions/itemActions';

const ExpenseEdit = (props: any) => {
  const { clientId = null, isDialog = false, onSave } = props;
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const params = useParams();
  const id = clientId !== null ? clientId : params.id;
  const [errors, setErrors] = useState<string[]>([]);
  const [clients, setClients] = useState<any[]>([]);
  const [taxes, setTaxes] = useState<any[]>([]);
  const [entity, setEntity] = useState<any>({
    clientId: '',
    companyId: '',
    taxTypeId: '',
    paymentType: '',
    receiptNumber: '',
    paymentDeadline: new Date(),
    dateOfPayment: '',
    date: new Date(),
    isPaid: false,
    priceGross: 0,
    currency: Currency.HUF,
    conversionRate: 1,
    description: '',
    expenseTypeId: 0,
  });
  const [expenseTypes, setExpenseTypes] = useState<any[]>([
    { id: '', name: '', description: '' },
  ]);

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    expenseTypeService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setExpenseTypes(response.records);
      }
    });
  }, []);

  useEffect(() => {
    if (id) {
      expenseService.get(id).then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          setEntity(response.result);
        }
      });
    }
  }, [id]);

  useEffect(() => {
    let abort = new AbortController();
    dispatch({ type: 'SHOW_QUERY' });
    clientService
      .list(false, EntityStatuses.Approved, abort.signal)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          setClients(response.records);
        }
        dispatch({ type: 'HIDE' });
      });

    return () => {
      abort.abort();
    };
  }, []);

  useEffect(() => {
    taxTypeService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setTaxes(response.records);
      }
    });
  }, []);

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    expenseService
      .update(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });

          if (!isDialog) {
            navigate(-1);
          }
          if (isDialog) {
            onSave(response.result);
          }
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        setSubmitting(false);
      });
  };

  return (
    <ExpenseForm
      entity={entity}
      readonly={
        !checkPermission(['ExpenseEdit']) ||
        entity.status !== EntityStatuses.SentToApproval
      }
      onSubmit={onSubmit}
      handleCancel={() => navigate(-1)}
      expenseTypes={expenseTypes}
      taxes={taxes}
      errors={errors}
      clients={clients}
      isDialog={isDialog}
    />
  );
};

export default ExpenseEdit;
