import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, Autocomplete, InputAdornment } from '@mui/material';
import { useSnackbar } from 'notistack';

export default function AddNewItemDialog(props: any) {
  const { open, setOpen, addToCart, items, getAvailableAmount } = props;
  const [item, setItem] = React.useState({
    itemId: 0,
    name: '',
    itemNumber: '',
    amount: 0,
  });

  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>Termék felvétele</DialogTitle>
      <DialogContent>
        <Grid style={{ minHeight: '50vh' }}>
          <Grid container spacing={2} style={{ paddingTop: 5 }}>
            <Grid item xs={12}>
              <Autocomplete
                disablePortal
                id="itemId"
                value={item}
                onChange={(event, value: any) => {
                  setItem({
                    ...value,
                    amount: 1,
                  });
                }}
                getOptionLabel={(option) => {
                  var item = items.find((g) => g.itemId === option.itemId);
                  if (item == null || item === undefined) {
                    return '';
                  }

                  return `${item.name} (${
                    item.itemNumber
                  }) - Elérhető: ${getAvailableAmount(item.itemId)} db`;
                }}
                options={items?.filter((x) => getAvailableAmount(x.itemId) > 0)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label="Termék"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={item.amount}
                onChange={(event) => {
                  let amount = parseFloat(event.target.value);
                  if (amount > getAvailableAmount(item.itemId)) {
                    enqueueSnackbar(
                      'Hozzáadni kívánt mennyiség nem elérhető a kiválasztott raktárban!',
                      {
                        variant: 'error',
                      }
                    );
                    return;
                  }
                  setItem({ ...item, amount: amount });
                }}
                label="Darabszám"
                disabled={item.itemId === 0}
                name="amount"
                size="small"
                fullWidth
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">db</InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            addToCart(item);
            setItem({
              itemId: 0,
              name: '',
              itemNumber: '',
              amount: 1,
            });
          }}
        >
          Hozzáadás
        </Button>
        <Button color="primary" variant="outlined" onClick={handleClose}>
          Mégse
        </Button>
      </DialogActions>
    </Dialog>
  );
}
