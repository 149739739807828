import { Link, Typography } from '@mui/material';
import versionFile from '../version.json';

const Copyright = () => {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.cloudscape.hu">
        CloudScape Innováció Kft.
      </Link>{' '}
      {versionFile.version}
    </Typography>
  );
};

export default Copyright;
