import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React from 'react';
import FilesPage from '../pages/chimneySweeping/Files/FilesPage';

const FilePageDialog = (props: any) => {
  const {
    open,
    onClose,
    attachmentType,
    entityUniqueId = null,
    localStorageKey,
    onList,
  } = props;

  return (
    <Dialog open={open} onClose={() => onClose()} fullWidth maxWidth={'lg'}>
      <DialogTitle>Fájlok feltöltése</DialogTitle>
      <DialogContent>
        <FilesPage
          attachmentType={attachmentType}
          entityUniqueId={entityUniqueId}
          isDialog={true}
          localStorageKey={localStorageKey}
          onList={(length) => {
            onList(length);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>Mégse</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FilePageDialog;
