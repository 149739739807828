import { createReducer } from '@reduxjs/toolkit';
import {
  setDistributors,
  setSelectedDistributor,
} from '../actions/userDistributorActions';

const initialState = {
  distributors: [],
  selectedDistributor: null,
};

export const userDistributorReducer = createReducer(initialState, (builder) => {
  builder.addCase(setSelectedDistributor, (state, action) => {
    state.selectedDistributor = action.payload;
  });
  builder.addCase(setDistributors, (state, action) => {
    state.distributors = action.payload;
  });
});
