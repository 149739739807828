import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Visibility,
} from '@mui/icons-material';
import ArticleIcon from '@mui/icons-material/Article';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DoneIcon from '@mui/icons-material/Done';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import StarIcon from '@mui/icons-material/Star';
import {
  Badge,
  Button,
  Checkbox,
  Dialog,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Rating,
  Select,
  Tooltip,
} from '@mui/material';
import {
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
} from '@mui/x-data-grid';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import {
  endOfMonth,
  endOfWeek,
  parseJSON,
  startOfMonth,
  startOfWeek,
  eachMonthOfInterval,
} from 'date-fns';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DataList from '../../../components/DataList';
import EntityNavigator from '../../../components/EntityNavigator';
import FilePageDialog from '../../../components/FilePageDialog';
import useCheckPermission from '../../../hooks/useCheckPermission';
import useClosingSheetCreate from '../../../hooks/useClosingSheetCreate';
import useConfirmDeleteDialog from '../../../hooks/useConfirmDeleteDialog';
import useFunctionDescriptor from '../../../hooks/useFunctionDescriptor';
import useSignalREffect from '../../../hooks/useSignalREffect';
import closingSheetService from '../../../services/erp/closingSheetService';
import workItemService from '../../../services/erp/workItemService';
import itemPriceService from '../../../services/pricing/itemPriceService';
import { AttachmentTypes } from '../../../types/AttachmentTypes';
import { EntityTypes } from '../../../types/EntityTypes';
import { formatCurrency, formatRole } from '../../../utils/valueFormatters';
import { WorkItemStatus } from '../workItems/WorkItemForm';
import WorkItemCreate from './WorkItemCreate';
import WorkItemPapersDialog from './WorkItemPapersDialog';
import { Offer } from '../offers/OfferPage';
import DatePickerHeader from '../../../components/DatePickerHeader';
import chimneySweepingCertificateService from '../../../services/chimneySweeping/chimneySweepingCertificateService';

const WorkItemsPage = (props: any) => {
  const { externalRows = null } = props;
  const navigate = useNavigate();
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const [rows, setRows] = useState<any[]>([]);
  const [filePageDialogOpen, setFilePageDialogOpen] = useState<any>({
    open: false,
    entityUniqueId: null,
  });
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const { createClosingSheet } = useClosingSheetCreate();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const titleDescriptor = useFunctionDescriptor('WorkItemsPage.title');
  const [workItemPapersDialog, setWorkItemPapersDialog] = useState<any>({
    open: false,
    workItem: null,
  });
  const [now, setNow] = useState(new Date());

  const [selectedInterval, setSelectedInterval] = useState<any>({
    startDate: startOfMonth(moment(now).startOf('month').toDate()),
    endDate: endOfMonth(moment(now).endOf('month').toDate()),
  });
  const [tabValue, setTabValue] = useState<number>(2);
  const { CreateWorkItem } = WorkItemCreate();
  const setDefaultCheckbox = () => {
    const checkbox = localStorage.getItem('WorkItemListAll');
    if (checkbox) {
      return checkbox === 'true';
    }
    return true;
  };
  const [checkboxValue, setCheckboxValue] =
    useState<boolean>(setDefaultCheckbox());
  const [coloumnsToShow, setColoumnsToShow] = useState<string[]>([
    'createdAt',
    'workFlowDate',
    'workItemNumber',
    'clientName',
    'clientNameForQuickSearch',
    'workAddress',
    'employeeName',
    'workItemStatus',
    'roleName',
    'statisticRating',
    'finalPriceGross',
    'invoiceNumber',
    'actions',
  ]);
  const hasClosingSheet = useSelector(
    (state: any) => state.app.hasClosingSheet
  );
  const connection = useSignalREffect('workItemHub');
  const handleWorkItemChanged = useCallback(
    (
      workitemNumber: string,
      oldUserName: string,
      newUserName: string,
      oldStatus: WorkItemStatus,
      newStatus: WorkItemStatus
    ) => {
      if (externalRows) {
        return;
      }
      if (checkPermission(['WorkItemViewAll']) && !checkboxValue) {
        workItemService
          .list(
            null,
            new Date(selectedInterval.startDate),
            new Date(selectedInterval.endDate)
          )
          .then((response) => {
            if (response.canceled) return;
            if (!response.hasError) {
              setOriginalRows(response.records);
            } else
              enqueueSnackbar(response.errorMessages.join(','), {
                variant: 'error',
              });
          });
      } else {
        workItemService
          .listmy(
            new Date(selectedInterval.startDate),
            new Date(selectedInterval.endDate)
          )
          .then((response) => {
            if (response.canceled) return;
            if (!response.hasError) {
              setOriginalRows(response.records);
            } else
              enqueueSnackbar(response.errorMessages.join(','), {
                variant: 'error',
              });
          });
      }
    },
    []
  );
  useEffect(() => {
    if (!connection) return;

    var callback = handleWorkItemChanged;
    connection.on('WorkItemStatusChanged', callback);

    return () => {
      connection.off('WorkItemStatusChanged', callback);
    };
  }, [connection, handleWorkItemChanged]);

  const user = useSelector((state: any) => state.user.userInfo);
  const { workItemInProgress } = useSelector<any>(
    (state) => state.workItem
  ) as any;
  const getDefaultStatusFilter = () => {
    return [
      WorkItemStatus.Draft,
      WorkItemStatus.New,
      WorkItemStatus.Assigned,
      WorkItemStatus.Completed,
    ];
  };

  const saveStatusFilterToLocalStorage = (statusFilter) => {
    localStorage.setItem('WorkItemStatusFilter', JSON.stringify(statusFilter));
  };
  const getStatusFilterFromLocalStorage = () => {
    const statusFilterJSON = localStorage.getItem('WorkItemStatusFilter');
    const parsedStatusFilter = statusFilterJSON
      ? JSON.parse(statusFilterJSON)
      : [];
    return parsedStatusFilter.length > 0
      ? parsedStatusFilter
      : getDefaultStatusFilter();
  };

  const [statusFilter, setStatusFilter] = useState<number[]>(
    getStatusFilterFromLocalStorage()
  );

  const [originalRows, setOriginalRows] = useState<any[]>([]);
  const isMechanic = user?.authorityInfo.roles.some(
    (x) => x.name === 'Mechanic'
  );

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    if (externalRows) {
      return setRows(originalRows);
    }

    const newFilteredRows = originalRows.filter((row) => {
      const status = row.workItemStatus;
      return statusFilter.includes(status);
    });
    setRows(newFilteredRows);
  }, [originalRows, statusFilter]);

  useEffect(() => {
    saveStatusFilterToLocalStorage(statusFilter);
  }, [statusFilter]);

  useEffect(() => {
    if (user.userId > 0) {
      var userRoles = user.authorityInfo.roles;
      var dispatcher = [
        'createdAt',
        'workFlowDate',
        'workItemNumber',
        'clientName',
        'workAddress',
        'employeeName',
        'workItemStatus',
        'roleName',
        'statisticRating',
        'finalPriceGross',
        'invoiceNumber',
        'actions',
      ];
      if (userRoles.some((x) => x.name === 'Dispatcher')) {
        setColoumnsToShow(dispatcher);
      }
    }
  }, [user]);

  useEffect(() => {
    const checkbox = localStorage.getItem('WorkItemListAll');
    if (checkbox) {
      setCheckboxValue(checkbox === 'true');
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('WorkItemListAll', checkboxValue.toString());
  }, [checkboxValue]);

  useEffect(() => {
    if (isMechanic && checkPermission(['ClosingSheetView'])) {
      closingSheetService.getToday().then((response) => {
        if (response.canceled) return;
        if (!response.hasError) {
          dispatch({ type: 'SET_HAS_CLOSING_SHEET', payload: true });
        } else {
          dispatch({ type: 'SET_HAS_CLOSING_SHEET', payload: false });
        }
      });
    }
  }, [hasClosingSheet]);

  const fetchData = async (abort?: AbortController) => {
    if (externalRows) {
      setOriginalRows(externalRows);
      setRows(externalRows);
      return;
    }

    const { startDate, endDate } = selectedInterval;

    // Parse the startDate and endDate to Date objects
    let start = new Date(startDate);
    let end = new Date(endDate);
    // Helper function to perform the query based on the list type
    const performQuery = async (start, end) => {
      if (checkPermission(['WorkItemViewAll']) && !checkboxValue) {
        return await workItemService.list(null, start, end, abort.signal);
      } else {
        return await workItemService.listmy(start, end, abort.signal);
      }
    };

    try {
      if (!endDate) {
        end = new Date();
      }
      const months = eachMonthOfInterval({ start, end });
      let allRecords = [];

      for (let i = months.length - 1; i >= 0; i--) {
        const monthStart = 1 === months.length ? start : months[i];
        const monthEnd = i === months.length - 1 ? end : endOfMonth(monthStart);
        const formattedStart = monthStart;
        const formattedEnd = monthEnd;
        if (i === months.length - 1) {
          dispatch({
            type: 'SHOW_QUERY',
            payload: { abort: abort },
          });
        }
        const response = await performQuery(formattedStart, formattedEnd);

        if (response.canceled) {
          if (i === months.length - 1) {
            dispatch({ type: 'HIDE' });
          }
          return;
        }

        if (response.hasError) {
          console.error('Query had errors:', response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
          if (i === months.length - 1) {
            dispatch({ type: 'HIDE' });
          }
          return;
        }

        allRecords = allRecords.concat(response.records);
        setOriginalRows(allRecords);
        if (i === months.length - 1) {
          dispatch({ type: 'HIDE' });
        }
      }
    } catch (error) {
      console.error('An error occurred while fetching data:', error);
      enqueueSnackbar('An error occurred while fetching data.', {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    let abort = new AbortController();
    fetchData(abort);

    return () => {
      abort.abort();
    };
  }, [checkboxValue, selectedInterval]);

  const handleWorkItemCompletedAcquisition = (row: any) => {
    var listPrices = [];
    let isItemsAvailable = true;

    itemPriceService
      .listListPrices(row.warehouseId, row.priceCategoryId, true)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          listPrices = response.records;
          row.selectedItems.forEach((item) => {
            let warehouseAmount = listPrices?.find(
              (x) => x.itemId === item.itemId
            )?.availableAmount;
            if (warehouseAmount < item.amount) {
              isItemsAvailable = false;
            }
          });
          if (isItemsAvailable) {
            workItemService.completeProcurement(row.id).then((response) => {
              if (response.canceled) return;
              if (response.hasError) {
                enqueueSnackbar(response.errorMessages.join(','), {
                  variant: 'error',
                });
              } else {
                enqueueSnackbar('Sikeres művelet', {
                  variant: 'success',
                });
                setRows(rows.filter((r) => r.id !== row.id));
              }
            });
          } else {
            enqueueSnackbar(
              'Nincs elegendő mennyiség minden termékből a raktárban',
              {
                variant: 'error',
              }
            );
          }
        }
      });
  };

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];

    if (checkPermission(['CreateFileAttachment'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Fájl feltöltése">
              <Badge
                badgeContent={params.row.attachmentCount}
                sx={{
                  padding: '0 4px',
                  '& .MuiBadge-badge': {
                    fontSize: 13,
                    height: 15,
                    minWidth: 15,
                    top: 5,
                  },
                }}
                color="error"
              >
                <AttachFileIcon />
              </Badge>
            </Tooltip>
          }
          label="Fájl feltöltése"
          onClick={() => {
            setFilePageDialogOpen({
              open: true,
              entityUniqueId: params.row.uniqueId,
            });
          }}
        />
      );
    }

    if (
      checkPermission(['ItemSupplyCreate']) &&
      params.row.workItemStatus === WorkItemStatus.Assigned &&
      params.row.roleName === 'Warehouseman' &&
      user.userId === params.row.assignedToId
    ) {
      actions.push(
        <GridActionsCellItem
          color={'success'}
          icon={
            <Tooltip title="Anyagfelvétel készítése">
              <AddIcon />
            </Tooltip>
          }
          label="Anyagfelvétel készítése."
          onClick={() => navigate(`/wms/itemSupplies/create/${params.row.id}`)}
        />
      );
    }

    if (
      checkPermission(['WorkItemProcurementComplete']) &&
      params.row.workItemStatus === WorkItemStatus.Assigned &&
      params.row.roleName === 'ProcurementSpecialist' &&
      params.row.assignedToId === user.userId
    ) {
      actions.push(
        <GridActionsCellItem
          color={'success'}
          icon={
            <Tooltip title="Beszerzés megtörtént">
              <DoneIcon />
            </Tooltip>
          }
          label="Beszerzés megtörtént."
          onClick={() => handleWorkItemCompletedAcquisition(params.row)}
        />
      );
    }

    if (
      checkPermission(['OfferCreate']) &&
      params.row.workItemStatus === WorkItemStatus.Assigned &&
      params.row.roleName === 'OfferGiver' &&
      params.row.assignedToId === user.userId &&
      params.row.offerId === null
    ) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Árajánlat Készítés">
              <AddIcon />
            </Tooltip>
          }
          label="Árajánlat készítés"
          onClick={() => {
            params.row.offerId > 0
              ? navigate(`/erp/offers/edit/${params.row.offerId}`)
              : navigate(`/erp/offers/create/${params.row.id}`);
          }}
        />
      );
    }

    if (
      checkPermission(['EvaluationEdit']) &&
      params.row.workItemStatus === WorkItemStatus.Closed
    ) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Kiértékelés">
              <FactCheckIcon />
            </Tooltip>
          }
          label="Kiértékelés"
          onClick={() => navigate(`/erp/workitems/edit/${params.row.id}/8`)}
        />
      );
    }

    let readonly =
      !checkPermission(['WorkItemEdit']) ||
      params.row.workItemStatus === WorkItemStatus.Closed ||
      params.row.workItemStatus === WorkItemStatus.Evaluated ||
      (!checkPermission(['EditNotAssignedWorkItem']) &&
        user.userId !== params.row.assignedToId);
    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          readonly ? (
            <Tooltip title="Megtekintés">
              <Visibility />
            </Tooltip>
          ) : (
            <Tooltip title="Szerkesztés">
              <EditIcon />
            </Tooltip>
          )
        }
        label={readonly ? 'Megtekintés' : 'Szerkesztés'}
        onClick={() => {
          if (!hasClosingSheet && isMechanic) {
            setDialogOpen(true);
          } else {
            navigate(`/erp/workitems/edit/${params.row.id}/0/workItem`);
          }
        }}
      />
    );
    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          <Tooltip title="Papirok">
            <ArticleIcon />
          </Tooltip>
        }
        label={'Papirok'}
        showInMenu
        onClick={() =>
          setWorkItemPapersDialog({ open: true, workItem: params.row })
        }
      />
    );

    if (checkPermission(['WorkItemDelete'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Törlés">
              <DeleteIcon />
            </Tooltip>
          }
          label="Törlés"
          showInMenu
          onClick={() =>
            setParams({
              open: true,
              name: '',
              onConfirm: async () => handleDelete(params.row.id),
            })
          }
        />
      );
    }

    return actions;
  };

  const getPriorityText = (priority: number) => {
    switch (priority) {
      case 1:
        return 'Alacsony';
      case 2:
        return 'Közepes';
      case 3:
        return 'Magas';
      default:
        return '';
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'createdAt',
      headerName: 'Létrehozás dátuma',
      flex: 150,
      valueFormatter: (params) => {
        if (!params.value) return '';
        if (new Date(params.value).getFullYear() < 2000) {
          return 'Nincs';
        }
        const previousValue = parseJSON(params.value).toLocaleDateString();
        const date = new Date(parseJSON(params.value));
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const formattedTime = `${previousValue} ${hours}:${minutes
          .toString()
          .padStart(2, '0')}`;
        return formattedTime;
      },
    },
    {
      field: 'workFlowDate',
      headerName: 'Tervezett Dátum',
      flex: 150,
      valueFormatter: (params) => {
        if (!params.value) return '';
        if (new Date(params.value).getFullYear() < 2000) {
          return 'Nincs';
        }
        const previousValue = parseJSON(params.value).toLocaleDateString();
        const date = new Date(parseJSON(params.value));
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const formattedTime = `${previousValue} ${hours}:${minutes
          .toString()
          .padStart(2, '0')}`;
        return formattedTime;
      },
    },
    { field: 'workItemNumber', headerName: 'Sorszám', flex: 100 },

    {
      field: 'clientName',

      headerName: 'Ügyfél',
      flex: 100,
      renderCell(params) {
        return (
          <EntityNavigator
            entityType={EntityTypes.Client}
            entityId={params.row.clientId}
            value={params.row.clientName}
            disableLink={externalRows}
          />
        );
      },
      valueGetter: (params: any) => {
        return params.row.clientName;
      },
    },
    { field: 'employeeName', headerName: 'Dolgozó', flex: 100 },
    { field: 'workAddress', headerName: 'Cím', flex: 100 },
    {
      field: 'workItemStatus',
      headerName: 'Munkalap státusza',
      flex: 100,
      renderCell: (params) => {
        return (
          <div>
            {params.value === WorkItemStatus.Draft ? (
              <span style={{ color: 'orange' }}>Tervezés alatt</span>
            ) : params.value === WorkItemStatus.New ? (
              <span style={{ color: '#A0A0A0' }}>Nincs hozzárendelve</span>
            ) : params.value === WorkItemStatus.Assigned ? (
              <span style={{ color: '#82C0FF' }}>Tervezett</span>
            ) : params.value === WorkItemStatus.Completed ? (
              <span style={{ color: 'lightgreen' }}>Teljesített</span>
            ) : params.value === WorkItemStatus.Suspended ? (
              <span style={{ color: '#FFCC00' }}>Felfüggesztett</span>
            ) : params.value === WorkItemStatus.Closed ? (
              <span style={{ color: 'green' }}>Lezárt</span>
            ) : params.value === WorkItemStatus.Evaluated ? (
              <span style={{ color: '#90EE90' }}>Kiértékelt</span>
            ) : null}
          </div>
        );
      },
    },
    {
      field: 'roleName',
      headerName: 'Munkafolyamat státusza',
      flex: 130,
      valueFormatter: (params) => {
        return formatRole(params.value);
      },
    },
    // {
    //   field: "priority",
    //   headerName: "Prioritás",
    //   flex: 100,
    //   valueFormatter: (params) => getPriorityText(params.value),
    // },
    {
      field: 'statisticRating',
      headerName: 'Értékelés',
      flex: 150,
      renderCell: (params) => (
        <div>
          <Rating
            name="hover-feedback"
            value={params.value}
            readOnly={true}
            precision={0.5}
            emptyIcon={
              <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
            }
          />
        </div>
      ),
    },
    {
      field: 'finalPriceGross',
      headerName: 'Ár(bruttó)',
      flex: 130,
      valueGetter(params) {
        return formatCurrency(params.row.finalPriceGross, params.row.currency);
      },
    },
    {
      field: 'invoiceNumber',
      headerName: 'Számlaszám',
      flex: 130,
    },
    {
      field: 'actions',
      type: 'actions',
      align: 'right',
      flex: 200,
      getActions: getActions,
    } as GridActionsColDef,
  ].filter((x) => coloumnsToShow.some((y) => y === x.field));

  const handleDelete = (id: number) => {
    workItemService.delete(id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres törlés', {
          variant: 'success',
        });
        setRows(rows.filter((row) => row.id !== id));
      }
    });
  };

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>Munkalapok {titleDescriptor}</h2>
        </Grid>
        {!externalRows && (
          <>
            <Grid item xs={12} pt={2}>
              <DatePickerHeader
                selectedInterval={selectedInterval}
                setSelectedInterval={setSelectedInterval}
                tabValue={tabValue}
                setTabValue={setTabValue}
                netGrossPicker={false}
                localStorageKey={'WorkItemPage'}
              />
            </Grid>
            <Grid container item xs={3} pt={2} spacing={2}>
              <Grid item pt={2}>
                <FormControl>
                  <Select
                    multiple
                    fullWidth
                    value={statusFilter}
                    onChange={(e) =>
                      setStatusFilter(e.target.value as number[])
                    }
                    renderValue={() => 'Státusz szűrő'}
                  >
                    <MenuItem value={WorkItemStatus.Draft}>
                      Tervezés alatt
                    </MenuItem>
                    <MenuItem value={WorkItemStatus.New}>
                      Nincs hozzárendelve
                    </MenuItem>
                    <MenuItem value={WorkItemStatus.Assigned}>
                      Tervezett
                    </MenuItem>
                    <MenuItem value={WorkItemStatus.Completed}>
                      Teljesített
                    </MenuItem>
                    <MenuItem value={WorkItemStatus.Closed}>Lezárt</MenuItem>
                    <MenuItem value={WorkItemStatus.Suspended}>
                      Felfüggesztett
                    </MenuItem>
                    <MenuItem value={WorkItemStatus.Evaluated}>
                      Kiértékelt
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={!checkPermission(['WorkItemViewAll'])}
                      checked={checkboxValue}
                      onChange={(e, checked) => {
                        setCheckboxValue(checked);
                      }}
                    />
                  }
                  label={'Saját adatok'}
                />
              </Grid>
            </Grid>
            {checkPermission(['WorkItemCreate']) && (
              <Grid container item xs={9} p={3} justifyContent="end">
                <Grid item>
                  <Tooltip
                    title={
                      workItemInProgress
                        ? 'Munkalap folyamatban van'
                        : 'Munkalap létrehozása'
                    }
                  >
                    <span>
                      <IconButton
                        disabled={workItemInProgress}
                        onClick={() => CreateWorkItem()}
                        color="primary"
                      >
                        <AddIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Grid>
              </Grid>
            )}
          </>
        )}
        {checkPermission(['WorkItemView']) && (
          <Grid item xs={12}>
            <DataList
              rows={rows}
              columns={columns}
              localStorageKey={'WorkItemsPage'}
            />
          </Grid>
        )}
        <ConfirmDeleteDialog />
        <FilePageDialog
          onList={(length) => {
            setRows((prev) => {
              return prev.map((row) => {
                if (row.uniqueId === filePageDialogOpen.entityUniqueId) {
                  row.attachmentCount = length;
                }
                return row;
              });
            });
          }}
          open={filePageDialogOpen.open}
          onClose={() =>
            setFilePageDialogOpen({ open: false, entityUniqueId: null })
          }
          attachmentType={AttachmentTypes.WorkItem}
          entityUniqueId={filePageDialogOpen.entityUniqueId}
          localStorageKey={'WorkItemsPage'}
        />
        <Dialog
          open={dialogOpen}
          onClose={() => {
            setDialogOpen(false);
          }}
          maxWidth="lg"
        >
          <Grid container textAlign="center" spacing={2} p={2}>
            <Grid item xs={12}>
              Még nem nyitotta meg a napját!
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  createClosingSheet(() =>
                    dispatch({ type: 'SET_HAS_CLOSING_SHEET', payload: true })
                  );
                  setDialogOpen(false);
                }}
              >
                Munkanap kezdés
              </Button>
            </Grid>
          </Grid>
        </Dialog>
      </Grid>
      <WorkItemPapersDialog
        open={workItemPapersDialog.open}
        setOpen={setWorkItemPapersDialog}
        workItem={workItemPapersDialog.workItem}
      />
    </Paper>
  );
};

export default WorkItemsPage;
