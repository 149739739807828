import { Menu, Search } from '@mui/icons-material';
import { IconButton, InputBase, Paper } from '@mui/material';
import React from 'react';

const SearchBar = (props: any) => {
  const { setDrawer, handleSearch } = props;
  const [value, setValue] = React.useState('');
  return (
    <Paper
      component="form"
      sx={{
        p: '2px 4px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <IconButton
        sx={{ p: '10px' }}
        aria-label="menu"
        onClick={() => {
          setDrawer((prev) => !prev);
        }}
      >
        <Menu />
      </IconButton>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Keresés"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        fullWidth
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            handleSearch(value);
          }
        }}
      />
      <IconButton
        type="button"
        sx={{ p: '10px' }}
        aria-label="search"
        onClick={() => {
          handleSearch(value);
        }}
      >
        <Search />
      </IconButton>
    </Paper>
  );
};

export default SearchBar;
