import { Autocomplete, Grid, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { Currency } from '../../types/Currency';

export default function CurrencyModuleSettingsForm(props: any) {
  const { settings, setSettings, readonly = false, userId } = props;
  const [value, setValue] = useState<any>(settings);

  useEffect(() => {
    setValue(settings);
  }, [settings]);

  const setValues = (values: any) => {
    setValue(values);
    setSettings(values);
  };

  return (
    <Grid container spacing={2} p={2} minHeight={'500px'}>
      <Grid item xs={12}>
        <Autocomplete
          disablePortal
          value={value.allowedCurrencies}
          disabled={readonly}
          multiple
          onChange={(event, value) => {
            setValues({ ...settings, allowedCurrencies: value });
          }}
          getOptionLabel={(option) => {
            return Currency[option];
          }}
          options={
            Object.values(Currency).filter((x) => Number.isFinite(x)) ?? []
          }
          renderInput={(params) => (
            <TextField {...params} required label="Engedélyezett pénznemek" />
          )}
        />
      </Grid>
    </Grid>
  );
}
