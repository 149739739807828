import { Box, Grid, Paper, Tab, Tabs, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useFunctionDescriptor from '../../../hooks/useFunctionDescriptor';
import { DocumentTemplateType } from '../../../types/DocumentTemplateType';
import { translateDocumentTemplateType } from '../../../utils/nameFormatters';
import DocumentTemplatePage from './DocumentTemplatePage';

const DocumentTemplateHomePage = () => {
  const navigate = useNavigate();
  const titleDescriptor = useFunctionDescriptor('FinancePage.title');
  const [tabValue, setTabValue] = useState<number>(0);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const storedTabValue = localStorage.getItem('DocumentTemplateTab');
    if (storedTabValue) {
      setTabValue(parseInt(storedTabValue));
    }
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    localStorage.setItem('DocumentTemplateTab', newValue.toString());
  };

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <Box sx={{ width: '100%' }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                pb: 2,
                maxWidth: isSmallScreen ? '280px' : '100%',
              }}
            >
              <Tabs
                value={tabValue}
                onChange={handleChange}
                TabIndicatorProps={{ style: { background: '#1976d2' } }}
                sx={{
                  maxWidth: isSmallScreen ? '280px' : '100%',
                  overflowX: 'scroll',
                }}
                allowScrollButtonsMobile
                scrollButtons="auto"
                variant="scrollable"
              >
                <Tab
                  value={0}
                  label={translateDocumentTemplateType(
                    DocumentTemplateType.Invoice
                  )}
                  sx={{ fontWeight: 'bold' }}
                />
                <Tab
                  value={1}
                  label={translateDocumentTemplateType(
                    DocumentTemplateType.DeliveryNote
                  )}
                  sx={{ fontWeight: 'bold' }}
                />
                <Tab
                  value={2}
                  label={translateDocumentTemplateType(
                    DocumentTemplateType.DepositInvoice
                  )}
                  sx={{ fontWeight: 'bold' }}
                />
                <Tab
                  value={3}
                  label={translateDocumentTemplateType(
                    DocumentTemplateType.FeeCollection
                  )}
                  sx={{ fontWeight: 'bold' }}
                />
                <Tab
                  value={4}
                  label={translateDocumentTemplateType(
                    DocumentTemplateType.WorkItem
                  )}
                  sx={{ fontWeight: 'bold' }}
                />
                <Tab
                  value={5}
                  label={translateDocumentTemplateType(
                    DocumentTemplateType.SmallInvoice
                  )}
                  sx={{ fontWeight: 'bold' }}
                />
                <Tab
                  value={6}
                  label={translateDocumentTemplateType(
                    DocumentTemplateType.SmallReceipt
                  )}
                  sx={{ fontWeight: 'bold' }}
                />
                <Tab
                  value={7}
                  label={translateDocumentTemplateType(
                    DocumentTemplateType.Storno
                  )}
                  sx={{ fontWeight: 'bold' }}
                />
                <Tab
                  value={8}
                  label={translateDocumentTemplateType(
                    DocumentTemplateType.Receipt
                  )}
                  sx={{ fontWeight: 'bold' }}
                />
                <Tab
                  value={9}
                  label={translateDocumentTemplateType(
                    DocumentTemplateType.Offer
                  )}
                  sx={{ fontWeight: 'bold' }}
                />
              </Tabs>
            </Box>
            {tabValue === 0 && (
              <DocumentTemplatePage
                documentTemplateType={DocumentTemplateType.Invoice}
              />
            )}
            {tabValue === 1 && (
              <DocumentTemplatePage
                documentTemplateType={DocumentTemplateType.DeliveryNote}
              />
            )}
            {tabValue === 2 && (
              <DocumentTemplatePage
                documentTemplateType={DocumentTemplateType.DepositInvoice}
              />
            )}
            {tabValue === 3 && (
              <DocumentTemplatePage
                documentTemplateType={DocumentTemplateType.FeeCollection}
              />
            )}
            {tabValue === 4 && (
              <DocumentTemplatePage
                documentTemplateType={DocumentTemplateType.WorkItem}
              />
            )}
            {tabValue === 5 && (
              <DocumentTemplatePage
                documentTemplateType={DocumentTemplateType.SmallInvoice}
              />
            )}
            {tabValue === 6 && (
              <DocumentTemplatePage
                documentTemplateType={DocumentTemplateType.SmallReceipt}
              />
            )}
            {tabValue === 7 && (
              <DocumentTemplatePage
                documentTemplateType={DocumentTemplateType.Storno}
              />
            )}
            {tabValue === 8 && (
              <DocumentTemplatePage
                documentTemplateType={DocumentTemplateType.Receipt}
              />
            )}
            {tabValue === 9 && (
              <DocumentTemplatePage
                documentTemplateType={DocumentTemplateType.Offer}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default DocumentTemplateHomePage;
