import {
  Button,
  TextField,
  Box,
  Grid,
  Paper,
  Autocomplete,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { ProjectStatuses } from '../../../types/ProjectStatuses';
import CreateEntityDecorator from '../../../components/CreateEntityDecorator';
import ClientCreate from '../../crm/clients/ClientCreate';
import useCheckPermission from '../../../hooks/useCheckPermission';
import { DatePicker } from '@mui/x-date-pickers';
import TaskPage from '../tasks/TaskPage';
import ProjectTasks from './ProjectTasks';

const ProjectForm = (props: any) => {
  const {
    entity,
    onSubmit,
    errors,
    handleCancel,
    readonly = false,
    clients,
    onClientAdded,
    users,
    documentBlocks,
  } = props;

  const { checkPermission } = useCheckPermission();

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};
        if (!values.name) {
          errors.name = 'Required';
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        handleChange,
        setFieldValue,
        errors: validationErrors,
      }) => (
        <Form>
          {' '}
          <Paper>
            <Grid container justifyContent="left" spacing={2} p={5}>
              <h2>Projekt {values.id > 0 ? 'szerkesztése' : 'létrehozása'}</h2>
              <Grid xs={12} item pt={5}>
                <Box color="red">
                  {errors.map((error) => (
                    <li>{error}</li>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="documentBlockId"
                  disabled={readonly}
                  value={values?.documentBlockId ?? ''}
                  onChange={(event, value) => {
                    setFieldValue('documentBlockId', value);
                  }}
                  getOptionLabel={(option) =>
                    documentBlocks?.find((g) => g.id === option)?.name ?? ''
                  }
                  options={documentBlocks?.map((g) => g.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={
                        !!touched.documentBlockId &&
                        !!validationErrors.documentBlockId
                      }
                      helperText={
                        !!touched.documentBlockId &&
                        !!validationErrors.documentBlockId &&
                        (validationErrors.documentBlockId as string)
                      }
                      fullWidth
                      label="Bizonylattömb"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.name}
                  onChange={handleChange}
                  label="Megnevezés"
                  name="name"
                  required
                  disabled={readonly}
                  fullWidth
                  autoFocus
                  error={!!touched.name && !!validationErrors.name}
                  helperText={
                    !!touched.name &&
                    !!validationErrors.name &&
                    (validationErrors.name as string)
                  }
                />
              </Grid>
              <Grid item xs={12} container spacing={2}>
                <Grid item>
                  <TextField
                    value={values.projectNumber}
                    onChange={handleChange}
                    label="Projektszám"
                    name="projectNumber"
                    required
                    disabled={readonly || values?.documentBlockId > 0}
                    error={
                      !!touched.projectNumber &&
                      !!validationErrors.projectNumber
                    }
                    helperText={
                      !!touched.projectNumber &&
                      !!validationErrors.projectNumber &&
                      (validationErrors.projectNumber as string)
                    }
                  />
                </Grid>
                <Grid item>
                  <TextField
                    value={values.plannedWorkHour}
                    onChange={handleChange}
                    label="Tervezett munkaóra"
                    name="plannedWorkHour"
                    type="number"
                    disabled={readonly}
                    error={
                      !!touched.plannedWorkHour &&
                      !!validationErrors.plannedWorkHour
                    }
                    helperText={
                      !!touched.plannedWorkHour &&
                      !!validationErrors.plannedWorkHour &&
                      (validationErrors.plannedWorkHour as string)
                    }
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="status"
                  disabled={readonly}
                  value={values?.status}
                  onChange={(event, value) => {
                    setFieldValue('status', value);
                  }}
                  getOptionLabel={(option) => {
                    return option === ProjectStatuses.New
                      ? 'Új'
                      : option === ProjectStatuses.Active
                      ? 'Aktív'
                      : option === ProjectStatuses.Closed
                      ? 'Lezárt'
                      : option === ProjectStatuses.Suspended
                      ? 'Felfüggesztett'
                      : option === ProjectStatuses.Resolved
                      ? 'Kész'
                      : option === ProjectStatuses.Cancelled
                      ? 'Törölt'
                      : '';
                  }}
                  options={
                    Object.values(ProjectStatuses).filter((x) =>
                      Number.isFinite(x)
                    ) ?? []
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      error={!!touched.status && !!validationErrors.status}
                      helperText={
                        !!touched.status &&
                        !!validationErrors.status &&
                        (validationErrors.status as string)
                      }
                      label="Státusz"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <CreateEntityDecorator
                  hideAdd={readonly || !checkPermission(['ClientCreate'])}
                  AutocompleteComponent={
                    <Autocomplete
                      autoHighlight
                      disablePortal
                      autoSelect
                      autoComplete
                      id="clientId"
                      disabled={readonly}
                      value={values?.clientId}
                      onChange={(event, value) => {
                        setFieldValue('clientId', value);
                      }}
                      getOptionLabel={(option) => {
                        if (option === null) return 'Nincs';
                        var client = clients?.find((g) => g.id === option);
                        if (!client) return '';
                        if (client?.companyName && client?.companyName !== '') {
                          return `${client?.companyName}(${client?.taxNumber}, ${client?.contactEmail}, ${client?.contactPhone}, ${client.id})`;
                        } else if (
                          client?.name &&
                          client.name.fullName !== ''
                        ) {
                          return client?.name.fullName + ' (' + client.id + ')';
                        }
                      }}
                      options={[null, ...clients?.map((g) => g.id)] ?? []}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Ügyfél"
                          fullWidth
                          error={
                            !!touched?.clientId && !!validationErrors?.clientId
                          }
                          helperText={
                            !!touched?.clientId &&
                            !!validationErrors?.clientId &&
                            (validationErrors?.clientId as string)
                          }
                        />
                      )}
                    />
                  }
                  onSave={(client) => {
                    onClientAdded(client);
                    setFieldValue('clientId', client.id);
                  }}
                  CreateEntityComponent={ClientCreate}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="managerId"
                  disabled={readonly}
                  value={values?.managerId}
                  onChange={(event, value) => {
                    setFieldValue('managerId', value);
                  }}
                  getOptionLabel={(option) => {
                    var found = users?.find((g) => g.id === option);
                    if (found) {
                      return `${found.id}.${found.fullName ?? ''}`;
                    }
                    return '';
                  }}
                  options={users?.map((g) => g.id) ?? []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={
                        !!touched.managerId && !!validationErrors.managerId
                      }
                      helperText={
                        !!touched.managerId &&
                        !!validationErrors.managerId &&
                        (validationErrors.managerId as string)
                      }
                      label="Projekt menedzser"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="userIds"
                  disabled={readonly}
                  value={values?.userIds ?? []}
                  multiple
                  onChange={(event, value) => {
                    setFieldValue('userIds', value);
                  }}
                  getOptionLabel={(option) => {
                    var found = users?.find((g) => g.id === option);
                    if (found) {
                      return `${found.id}.${found.fullName ?? ''}`;
                    }
                    return '';
                  }}
                  options={users?.map((g) => g.id) ?? []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!touched.userIds && !!validationErrors.userIds}
                      helperText={
                        !!touched.userIds &&
                        !!validationErrors.userIds &&
                        (validationErrors.userIds as string)
                      }
                      label="Projekt tagok"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.description}
                  onChange={handleChange}
                  label="Leírás"
                  name="description"
                  multiline
                  InputLabelProps={{ shrink: true }}
                  disabled={readonly}
                  fullWidth
                  error={
                    !!touched.description && !!validationErrors.description
                  }
                  helperText={
                    !!touched.description &&
                    !!validationErrors.description &&
                    (validationErrors.description as string)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={'auto'} p={1}>
                <DatePicker
                  label="Kezdés dátuma"
                  value={values.startDate ?? ''}
                  onChange={(date) => setFieldValue('startDate', date)}
                  renderInput={(props) => <TextField {...props} />}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={'auto'} p={1}>
                <DatePicker
                  label="Végdátum"
                  value={values.endDate ?? ''}
                  onChange={(date) => setFieldValue('endDate', date)}
                  renderInput={(props) => <TextField {...props} />}
                />
              </Grid>
              {values.id > 0 && (
                <Grid item xs={12}>
                  <ProjectTasks
                    tasks={values.tasks ?? []}
                    onTaskChange={(tasks) => {
                      setFieldValue('tasks', tasks);
                    }}
                    employees={values.userIds ?? []}
                    users={users}
                    projectId={values.id}
                    readonly={readonly}
                    projectWorkHourSum={values.workHourSum ?? 0}
                  />
                </Grid>
              )}
              <Grid container item pt={3} justifyContent="left">
                {!readonly && (
                  <Grid item p={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      Mentés
                    </Button>
                  </Grid>
                )}
                <Grid item p={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleCancel}
                  >
                    Mégse
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default ProjectForm;
