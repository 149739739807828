import { Grid, Tooltip, Badge } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  GridColDef,
  GridActionsColDef,
  GridActionsCellItem,
} from '@mui/x-data-grid';
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DataList from '../../../components/DataList';
import useCheckPermission from '../../../hooks/useCheckPermission';
import { useSnackbar } from 'notistack';
import { formatCurrency } from '../../../utils/valueFormatters';
import vehicleDamageReportService from '../../../services/Reports/vehicleDamageReportService';
import { translateDamageScaleName } from '../../../utils/nameFormatters';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility,
  FileUpload,
} from '@mui/icons-material';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { AttachmentTypes } from '../../../types/AttachmentTypes';
import FilePageDialog from '../../../components/FilePageDialog';
import { VehicleDamageReport } from '../../reports/vehicleDamageReports/VehicleDamageReportPage';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { parseJSON } from 'date-fns';

export default function VehicleDamageReportDialog(props: any) {
  const { open, setOpen, vehicleId } = props;
  const [rows, setRows] = React.useState<any[]>([]);
  const [filePageDialogOpen, setFilePageDialogOpen] = React.useState<any>({
    open: false,
    entityUniqueId: null,
  });

  const { enqueueSnackbar } = useSnackbar();
  const { checkPermission } = useCheckPermission();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();

  const handleClose = () => {
    setOpen({ open: false, services: [] });
    setRows([]);
  };

  useEffect(() => {
    if (vehicleId) {
      vehicleDamageReportService.list(vehicleId).then((response) => {
        if (response.canceled) return;
        if (!response.hasError) setRows(response.records);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      });
    }
  }, [vehicleId]);

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];

    if (checkPermission(['CreateFileAttachment'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Fájl feltöltése">
              <Badge
                badgeContent={params.row.attachmentCount}
                sx={{
                  padding: '0 4px',
                  '& .MuiBadge-badge': {
                    fontSize: 13,
                    height: 15,
                    minWidth: 15,
                    top: 5,
                  },
                }}
                color="error"
              >
                <AttachFileIcon />
              </Badge>
            </Tooltip>
          }
          label="Fájl feltöltése"
          onClick={() => {
            setFilePageDialogOpen({
              open: true,
              entityUniqueId: params.row.uniqueId,
            });
          }}
        />
      );
    }

    let readonly = !checkPermission(['VehicleDamageReportEdit']);
    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          readonly ? (
            <Tooltip title="Megtekintés">
              <Visibility />
            </Tooltip>
          ) : (
            <Tooltip title="Szerkesztés">
              <EditIcon />
            </Tooltip>
          )
        }
        label={readonly ? 'Megtekintés' : 'Szerkesztés'}
        onClick={() =>
          navigate(`/reports/vehicleDamageReports/edit/${params.row.id}`)
        }
      />
    );

    return actions;
  };

  const columns: GridColDef[] = [
    {
      field: 'createdAt',
      headerName: 'Dátum',
      flex: 150,
      valueFormatter(params) {
        return parseJSON(params.value).toLocaleDateString();
      },
    },
    { field: 'vehicleName', headerName: 'Jármű', flex: 100 },
    { field: 'userName', headerName: 'Károkozó', flex: 100 },
    { field: 'title', headerName: 'Cím', flex: 100 },
    { field: 'description', headerName: 'Leírás', flex: 100 },
    {
      field: 'damageScale',
      headerName: 'Kár mértéke',
      flex: 100,
      valueFormatter(params) {
        return translateDamageScaleName(params.value);
      },
    },
    {
      field: 'price',
      headerName: 'Ár',
      flex: 100,
      valueFormatter(params) {
        return formatCurrency(params.value);
      },
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 100,
      getActions: getActions,
    } as GridActionsColDef,
  ];

  return (
    <Grid>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'lg'}>
        <DialogTitle>Kárbejelentések:</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} style={{ paddingTop: 10 }}>
            <Grid item xs={12}>
              <DataList
                rows={rows}
                columns={columns}
                getRowId={(row) => row.id}
                minimal
                hideFooterPagination
                localStorageKey={'VehicleDamageReportDialog'}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined" onClick={handleClose}>
            Mégse
          </Button>
        </DialogActions>
      </Dialog>
      <FilePageDialog
        onList={(length) => {
          setRows((prev) => {
            return prev.map((row) => {
              if (row.uniqueId === filePageDialogOpen.entityUniqueId) {
                row.attachmentCount = length;
              }
              return row;
            });
          });
        }}
        open={filePageDialogOpen.open}
        onClose={() =>
          setFilePageDialogOpen({ open: false, entityUniqueId: null })
        }
        attachmentType={AttachmentTypes.VehicleDamageReport}
        entityUniqueId={filePageDialogOpen.entityUniqueId}
        localStorageKey={'VehicleDamageReportDialog'}
      />
    </Grid>
  );
}
