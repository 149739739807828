import { useEffect } from 'react';

const Timer = (props: any) => {
  const {
    countFromSeconds,
    elapsedSeconds,
    onElapsedSeconds,
    onCountDownOver,
  } = props;

  const padLeadingZeros = (num: string, size: number) => {
    var s = num + '';
    while (s.length < size) s = '0' + s;
    return s;
  };

  const getTimeLeft = () => {
    var left = countFromSeconds - elapsedSeconds;
    var seconds = left % 60;
    var minutes = Math.floor(left / 60);

    return `${padLeadingZeros(minutes.toFixed(0), 2)}:${padLeadingZeros(
      seconds.toFixed(0),
      2
    )}`;
  };

  useEffect(() => {
    var tickInterval = setInterval(() => {
      if (elapsedSeconds === countFromSeconds && onCountDownOver) {
        onCountDownOver();

        return;
      }

      onElapsedSeconds();
    }, 1000);

    return () => {
      clearInterval(tickInterval);
    };
  }, []);

  return <div style={{ color: 'white' }}>{getTimeLeft()}</div>;
};

export default Timer;
