import { usePDF } from '@react-pdf/renderer';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Autocomplete,
} from '@mui/material';
import Button from '@mui/material/Button';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import clientService from '../../../services/crm/clientService';
import orderService from '../../../services/sales/orderService';
import { OrderPDF } from './OrderPDF';
import DataList from '../../../components/DataList';
import { GridColDef } from '@mui/x-data-grid';
import { parseJSON } from 'date-fns';
import { translateEmailStatusName } from '../../../utils/nameFormatters';

export default function OrderEmailDialog(props: any) {
  const { orderId, open, setOpen, addressId, onSent } = props;
  const [address, setAddress] = React.useState<string>('');
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const [order, setOrder] = useState<any>({
    items: [],
    invoiceUrl: '',
    invoiceNumber: '',
    discount: '',
  });

  useEffect(() => {
    if (order.clientId) {
      dispatch({ type: 'SHOW_QUERY' });
      clientService
        .get(order?.clientId)
        .then((response) => {
          if (response.canceled) return;
          if (!response.hasError) {
            setAddress(response.result.contactEmail);
          } else
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
        })
        .finally(() => dispatch({ type: 'HIDE' }));
    }
  }, [order?.clientId]);

  useEffect(() => {
    if (orderId > 0) {
      orderService.get(orderId).then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          setOrder(response.result);
        }
      });
    }
  }, [orderId]);

  const sendEmail = async (row: any) => {
    const entity = {
      to: address,
      orderId: row.id,
    };
    dispatch({ type: 'SHOW_QUERY' });
    orderService
      .sendEmail(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres küldés', {
            variant: 'success',
          });
          onSent(response.result);
          setOpen({ open: false, id: 0 });
        }
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  };

  const columns: GridColDef[] = [
    {
      field: 'to',
      headerName: 'Email cím',
      flex: 200,
    },
    {
      field: 'sentAt',
      headerName: 'Küldés dátuma',
      valueFormatter(params) {
        return new Date(params.value).toLocaleDateString('hu-HU');
      },
      flex: 200,
    },
    {
      field: 'openedAt',
      headerName: 'Megnyitás dátuma',
      valueFormatter(params) {
        var date = new Date(params.value);
        return date > new Date(null)
          ? parseJSON(params.value).toLocaleString()
          : 'Nincs megnyitva';
      },
      flex: 200,
    },
    {
      field: 'status',
      headerName: 'Státusz',
      valueFormatter(params) {
        return translateEmailStatusName(params.value);
      },
      flex: 150,
    },
  ];

  return (
    <Dialog
      open={open}
      onClose={() => setOpen({ open: false, id: 0 })}
      fullWidth
    >
      <DialogTitle>Email küldése:</DialogTitle>
      <DialogContent>
        <Grid item xs={12} pt={2}>
          <Autocomplete
            disablePortal
            id="address"
            value={address ? address.split(';') : []}
            onChange={(event, value) => {
              setAddress(value.join(';'));
            }}
            multiple
            freeSolo
            autoSelect
            renderInput={(params) => (
              <TextField {...params} required label="Email cím" />
            )}
            options={[]}
          />
        </Grid>
        <Grid item xs={12} pt={2}>
          Korábbi küldések:
          <DataList
            rows={order?.orderEmails || []}
            columns={columns}
            getRowId={(row) => row.id}
            minimal
            localStorageKey={'OrderEmailDialog'}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={address === ''}
          onClick={() => {
            sendEmail(order);
          }}
        >
          Küldés
        </Button>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => setOpen({ open: false, id: 0 })}
        >
          Mégse
        </Button>
      </DialogActions>
    </Dialog>
  );
}
