import {
  Box,
  Grid,
  TextField,
  Typography,
  Autocomplete,
  Alert,
} from '@mui/material';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import FileUploadingPreview from '../components/FileUploadingPreview';
import { AttachmentTypes } from '../types/AttachmentTypes';
import { ProjectStatuses } from '../types/ProjectStatuses';
import { translateAttachmentTypesName } from '../utils/nameFormatters';

function FileSelect({
  onUpload,
  onSelect,
  fileAttachmentType,
  onAllFileUploaded,
  entityUniqueId = null,
  showAttachmentType = true,
}: any) {
  const [filesUploaded, setFilesUploaded] = useState(false);
  const [attachmentType, setAttachmentType] = useState<AttachmentTypes>(
    AttachmentTypes.ChimneySweepingCertificate
  );
  const [filesToUpload, setFilesToUpload] = useState<any[]>([]);

  useEffect(() => {
    if (fileAttachmentType) {
      setAttachmentType(fileAttachmentType as AttachmentTypes);
    }
  }, [fileAttachmentType]);

  async function createFileAttachment(file, attachmentType) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      // Event handler for when the file is loaded
      reader.onloadend = () => {
        // Create the object similar to your .NET record
        const fileAttachment = {
          fileName: file.name,
          contentType: file.type,
          data: Array.from(new Uint8Array(reader.result as any)),
          type: attachmentType,
          isUploaded: false,
          hasFailed: false,
          size: file.size,
          entityUniqueId: entityUniqueId,
        };

        resolve(fileAttachment);
      };

      // Event handler for errors during file reading
      reader.onerror = () => {
        reject(new Error('Error reading file.'));
      };

      // Read the file as an ArrayBuffer
      reader.readAsArrayBuffer(file);
    });
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: useCallback(
      (acceptedFiles: any) => {
        if (filesUploaded) {
          setFilesToUpload([]);
        }
        setFilesUploaded(false);

        acceptedFiles.map((acceptedFile) => {
          createFileAttachment(acceptedFile, attachmentType)
            .then((fileAttachment) => {
              setFilesToUpload((prev) => [...prev, fileAttachment]);
            })
            .catch((error) => {
              console.error('Error creating file: ', error);
            });
        });
      },
      [filesUploaded, attachmentType, entityUniqueId]
    ),
  });

  return (
    <Grid container pt={2} spacing={2}>
      {showAttachmentType && (
        <Grid item xs={12}>
          <Autocomplete
            disablePortal
            id="attachmentType"
            value={attachmentType}
            disabled={
              fileAttachmentType !== null && fileAttachmentType !== undefined
            }
            onChange={(event, value) => {
              setAttachmentType(value as AttachmentTypes);
            }}
            getOptionLabel={(option) => {
              return translateAttachmentTypesName(option as AttachmentTypes);
            }}
            options={
              Object.values(AttachmentTypes).filter((x) =>
                Number.isFinite(x)
              ) ?? []
            }
            renderInput={(params) => (
              <TextField {...params} required label="Típus" />
            )}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Box>
          <div {...getRootProps()} style={{ cursor: 'pointer' }}>
            <input {...getInputProps()} />
            <Grid item xs={12} pb={2}>
              <Alert
                severity="warning"
                children="Figyelem! Maximum fájl méret 10MB!"
              />
            </Grid>
            <Box
              border={2}
              borderColor="primary.main"
              borderRadius="5px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="150px"
            >
              <Typography variant="body1" color="primary">
                Húzd ide a fájlokat, vagy kattints a kiválasztáshoz.
              </Typography>
            </Box>
          </div>
          {filesToUpload ? (
            <FileUploadingPreview
              onUpload={onUpload}
              onAllFileUploaded={onAllFileUploaded}
              filesUploaded={filesUploaded}
              setFilesUploaded={setFilesUploaded}
              filesToUpload={filesToUpload}
              setFilesToUpload={setFilesToUpload}
            />
          ) : (
            <Typography>Kérjük töltsön fel egy fájlt!</Typography>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

function useFileSelect() {
  const [uploadedFile, setUploadedFile] = useState<any>({});
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState<string>('');

  const onUpload = useCallback(
    async (
      fileName: string,
      contentType: string,
      data: any,
      type: AttachmentTypes,
      entityUniqueId: string | null,
      onUploaded: (file: any) => void,
      onError: (errorMessage: any) => void
    ) => {
      try {
        const createFileCommand = {
          fileName: fileName, // Include the file extension in the FileName
          contentType: contentType,
          type: type,
          data: data,
          entityUniqueId: entityUniqueId,
        };

        setUploading(true);
        setError('');

        // Make the API call to upload the image
        const response = await axios.post(
          '/api/fileAttachment/create',
          createFileCommand,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        setUploadedFile(response.data.result);
        if (onUploaded) onUploaded(response.data.result);
        setUploading(false);
      } catch (err) {
        setError('Error uploading file');
        onError(err);
        setUploading(false);
      }
    },
    []
  );

  return { FileSelect, uploadedFile, uploading, error, onUpload };
}

export default useFileSelect;
