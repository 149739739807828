import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TaskForm from './TaskForm';
import { useNavigate } from 'react-router-dom';
import useCheckPermission from '../../../hooks/useCheckPermission';
import taskService from '../../../services/erp/taskService';
import userService from '../../../services/authority/userService';
import appService from '../../../services/appService';

const TaskCreate = (props: any) => {
  const { onSave = null, navigateBack = true, handleCancel = null } = props;
  const [now] = useState(new Date());
  const [employees, setEmployees] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<string[]>([]);
  const user = useSelector((state: any) => state.user.userInfo);

  const { checkPermission } = useCheckPermission();

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    taskService
      .create(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });

          if (onSave) onSave(response.result);
          if (navigateBack) navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        setSubmitting(false);
      });
  };
  useEffect(() => {
    if (checkPermission(['TaskCreateForOthers'])) {
      userService.list().then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          setEmployees(response.records);
        }
      });
    } else {
      setEmployees([{ id: user.userId, fullName: user.fullName }]);
    }
  }, []);

  return (
    <TaskForm
      entity={{
        date: now,
        description: '',
        employeeId: user.userId,
        employeeName: '',
        isCompleted: false,
        workHour: '',
      }}
      readonly={!checkPermission(['TaskCreate'])}
      onSubmit={onSubmit}
      errors={errors}
      employees={employees}
      handleCancel={() =>
        handleCancel != null ? handleCancel() : navigate(-1)
      }
    />
  );
};

export default TaskCreate;
