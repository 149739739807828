import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useCheckPermission from '../../../hooks/useCheckPermission';
import decisionService from '../../../services/chimneySweeping/decisionService';
import DecisionForm from './DecisionForm';
import clientService from '../../../services/crm/clientService';
import { EntityStatuses } from '../../../types/EntityStatuses';
import documentBlockService from '../../../services/billing/documentBlockService';
import { DocumentBlockTypes } from '../../../types/DocumentBlockTypes';

const DecisionCreate = (props: any) => {
  const { onSave = null, navigateBack = true, handleCancel = null } = props;

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<string[]>([]);
  const [clients, setClients] = useState<any[]>([]);
  const [now, setNow] = useState<Date>(new Date());
  const [documentBlocks, setDocumentBlocks] = useState<any[]>([]);

  const { checkPermission } = useCheckPermission();

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    decisionService
      .create(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });

          if (onSave) onSave(response.result);
          if (navigateBack) navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        setSubmitting(false);
      });
  };

  useEffect(() => {
    clientService.list(true, EntityStatuses.Approved).then((response) => {
      if (response.canceled) return;
      if (!response.hasError) {
        setClients(response.records);
      } else {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      }
    });
  }, []);

  useEffect(() => {
    documentBlockService.list(DocumentBlockTypes.Decision).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setDocumentBlocks(response.records);
      }
    });
  }, []);

  return (
    <DecisionForm
      entity={{
        reason: '',
        validity: now,
        addressId: 0,
        clientId: 0,
        name: '',
        documentNumber: '',
        documentBlockId: 0,
        id: 0,
      }}
      readonly={!checkPermission(['DecisionCreate'])}
      onSubmit={onSubmit}
      errors={errors}
      clients={clients}
      documentBlocks={documentBlocks}
      handleCancel={() =>
        handleCancel != null ? handleCancel() : navigate(-1)
      }
    />
  );
};

export default DecisionCreate;
