import axios from "axios";
import { postData } from "../axiosUtils";
import { EntityStatuses } from "../../types/EntityStatuses";

const holidayService = {
  list: (status: EntityStatuses = EntityStatuses.Approved) => {
    return axios.get(`/api/holiday/list?status=${status}`).then((response) => {
      return response.data;
    });
  },
  listUser: (userId: any, status: EntityStatuses = EntityStatuses.Approved) => {
    return axios
      .get(`/api/holiday/listUser/${userId}?status=${status}`)
      .then((response) => {
        return response.data;
      });
  },
  get: (id: any) => {
    return axios.get(`/api/holiday/details/${id}`).then((response) => {
      return response.data;
    });
  },
  create: (entity: any) => {
    return postData({
      url: `/api/holiday/create`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  update: (entity: any) => {
    return postData({
      url: `/api/holiday/update`,
      data: entity,
      method: "PUT",
    }).then((response) => {
      return response;
    });
  },
  accept: (id: any) => {
    return postData({
      url: `/api/holiday/accept/${id}`,
      method: "PATCH",
    }).then((response) => {
      return response;
    });
  },
  reject: (id: any, comment: string) => {
    return postData({
      url: `/api/holiday/reject/${id}?comment=${comment}`,
      method: "PATCH",
    }).then((response) => {
      return response;
    });
  },
  delete: (id: any) => {
    return postData({
      url: `/api/holiday/delete/${id}`,
      method: "DELETE",
    }).then((response) => {
      return response;
    });
  },
};

export default holidayService;
