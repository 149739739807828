import { useEffect, useState } from "react";

export const useLocalStorage = (itemId: string) => {
  const [value, setValue] = useState<any>(null);

  useEffect(() => {
    const item = window.localStorage.getItem(itemId);
    if (item) {
      let value = JSON.parse(item);
      setValue(value);
    }
  }, [itemId]);

  const setLocalStorageValue = (newValue: any) => {
    window.localStorage.setItem(itemId, JSON.stringify(newValue));
    setValue(newValue);
  };

  return { value, setLocalStorageValue };
};
