import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import userService from '../../../../services/authority/userService';
import UserForm from './UserForm';
import roleService from '../../../../services/authority/roleService';
import certificationService from '../../../../services/HR/certificationService';
import abilityService from '../../../../services/authority/abilityService';
import { id } from 'date-fns/locale';
import { name } from '@azure/msal-browser/dist/packageMetadata';

const UserEdit = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const params = useParams();
  const [errors, setErrors] = useState<string[]>([]);
  const [users, setUsers] = useState<any[]>([]);
  const [certifications, setCertifications] = useState<any[]>([]);
  const [abilities, setAbilities] = useState<any[]>([]);
  const [entity, setEntity] = useState<any>({
    phone: '',
    fullName: '',
    email: '',
    isHidden: false,
    personalEmail: '',
    invoicePrefixes: '',
    id: 0,
    superiorId: 0,
    commissionRate: '',
    incomeTarget: '',
    imageId: 0,
    image: { fileName: '', id: 0, url: '' },
    workHour: 0,
    hasDrivingLicense: false,
    medicalExaminationDate: null,
    birthDate: null,
    userCertifications: [
      {
        certificationId: 0,
        documentNumber: '',
        obtainingDate: '',
        validityDate: '',
      },
    ],
    abilities: [
      {
        id: 0,
        name: '',
        description: '',
      },
    ],
  });

  useEffect(() => {
    userService.get(params.id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        setErrors(response.errorMessages);
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setEntity(response.result);
      }
    });
  }, [params.id]);

  useEffect(() => {
    userService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        setErrors(response.errorMessages);
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setUsers(response.records);
      }
    });
  }, []);

  useEffect(() => {
    certificationService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        setErrors(response.errorMessages);
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setCertifications(response.records);
      }
    });
  }, []);

  useEffect(() => {
    abilityService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        setErrors(response.errorMessages);
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setAbilities(response.records);
      }
    });
  }, []);

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    userService
      .update(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });

          navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        setSubmitting(false);
      });
  };

  return (
    <UserForm
      entity={entity}
      onSubmit={onSubmit}
      users={users}
      certifications={certifications}
      setCertifications={setCertifications}
      handleCancel={() => navigate(-1)}
      errors={errors}
      abilities={abilities}
      setAbilities={abilities}
    />
  );
};

export default UserEdit;
