import axios from 'axios';
import { postData } from '../axiosUtils';

const warehouseTransactionService = {
  list: (
    startDate: any | null = null,
    endDate: any | null = null,
    signal: AbortSignal = new AbortController().signal
  ) => {
    let url = `/api/warehouseTransaction/list`;
    if (startDate) {
      url += `?startDate=${startDate?.toISOString()}`;
    }
    if (endDate && !startDate) {
      url += `?endDate=${endDate?.toISOString()}`;
    } else if (endDate) {
      url += `&endDate=${endDate?.toISOString()}`;
    }
    return axios.get(url, { signal }).then((response) => {
      return response.data;
    });
  },

  add: (command: any) => {
    return postData({
      url: `/api/warehouseTransaction/add`,
      data: command,
    }).then((response) => {
      return response;
    });
  },
  remove: (command: any) => {
    return postData({
      url: `/api/warehouseTransaction/remove`,
      data: command,
    }).then((response) => {
      return response;
    });
  },
  move: (command: any) => {
    return postData({
      url: `/api/warehouseTransaction/move`,
      data: command,
    }).then((response) => {
      return response;
    });
  },
  stockReplenishment: (command: any) => {
    return postData({
      url: `/api/warehouseTransaction/stockReplenishment`,
      data: command,
    }).then((response) => {
      return response;
    });
  },
  discard: (command: any) => {
    return postData({
      url: `/api/warehouseTransaction/discard`,
      data: command,
    }).then((response) => {
      return response;
    });
  },
};

export default warehouseTransactionService;
