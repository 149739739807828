import axios from 'axios';
import { postData } from '../axiosUtils';

const accessManagementsService = {
  list: () => {
    return axios.get(`/api/accessManagement/list`).then((response) => {
      return response.data;
    });
  },
  get: (id: any) => {
    return axios.get(`/api/accessManagement/details/${id}`).then((response) => {
      return response.data;
    });
  },
  create: (entity: any) => {
    return postData({
      url: `/api/accessManagement/create`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  update: (entity: any) => {
    return postData({
      url: `/api/accessManagement/update`,
      data: entity,
      method: 'PUT',
    }).then((response) => {
      return response;
    });
  },
  delete: (id: any) => {
    return postData({
      url: `/api/accessManagement/delete/${id}`,
      method: 'DELETE',
    }).then((response) => {
      return response;
    });
  },
};

export default accessManagementsService;
