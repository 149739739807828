import axios from "axios";
import { postData } from "../axiosUtils";
import { EntityStatuses } from "../../types/EntityStatuses";

const expenseService = {
  list: (status: EntityStatuses = EntityStatuses.Approved) => {
    return axios.get(`/api/expense/list?status=${status}`).then((response) => {
      return response.data;
    });
  },
  get: (id: any) => {
    return axios.get(`/api/expense/details/${id}`).then((response) => {
      return response.data;
    });
  },
  approve: (id: any) => {
    return postData({
      url: `/api/expense/approve/${id}`,
      method: "PATCH",
    }).then((response) => {
      return response;
    });
  },
  reject: (id: any, comment: string) => {
    return postData({
      url: `/api/expense/reject/${id}?comment=${comment}`,
      method: "PATCH",
    }).then((response) => {
      return response;
    });
  },
  create: (entity: any) => {
    return postData({
      url: `/api/expense/create`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  update: (entity: any) => {
    return postData({
      url: `/api/expense/update`,
      data: entity,
      method: "PUT",
    }).then((response) => {
      return response;
    });
  },
  delete: (id: any) => {
    return postData({
      url: `/api/expense/delete/${id}`,
      method: "DELETE",
    }).then((response) => {
      return response;
    });
  },
};

export default expenseService;
