import axios from 'axios';
import { postData } from '../axiosUtils';

const cashlogService = {
  list: (startDate: any, endDate: any, registerId: any) => {
    let url = `/api/cashlog/list?startDate=${startDate}&endDate=${endDate}&registerId=${registerId}`;
    return axios.get(url).then((response) => {
      return response.data;
    });
  },
  get: (id: any) => {
    return axios.get(`/api/cashlog/details/${id}`).then((response) => {
      return response.data;
    });
  },
  create: (entity: any) => {
    return postData({
      url: `/api/cashlog/create`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  update: (entity: any) => {
    return postData({
      url: `/api/cashlog/update`,
      data: entity,
      method: 'PUT',
    }).then((response) => {
      return response;
    });
  },
  delete: (id: any) => {
    return postData({
      url: `/api/cashlog/delete/${id}`,
      method: 'DELETE',
    }).then((response) => {
      return response;
    });
  },
};

export default cashlogService;
