import React, { useMemo } from 'react';
import { Delete } from '@mui/icons-material';
import {
  Grid,
  TextField,
  InputAdornment,
  Checkbox,
  IconButton,
} from '@mui/material';
import { FixedSizeList as List } from 'react-window';

const ItemSupplyItem = ({
  items,
  setFieldValue,
  getAvailableAmount,
  readonly,
  enqueueSnackbar,
  index,
  x,
}) => {
  const memoizedItem = useMemo(() => {
    const item = items.find((y) => y.id === x.itemId);

    return (
      <>
        <Grid item xs={12} md={8}>
          <TextField
            value={item?.name}
            variant="standard"
            label="Termék megnevezése"
            type="text"
            InputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
            name="itemName"
            fullWidth
            disabled={x.itemId > 0}
            sx={{
              input: {
                xs: { textAlign: 'center' },
                md: { textAlign: 'left' },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            value={x.amount}
            variant="standard"
            onChange={(e) => {
              const value = parseFloat(e.target.value);
              const available = getAvailableAmount(x.itemId);
              if (value === 0 || value > available + x.amount) {
                enqueueSnackbar(
                  'Hozzáadni kívánt mennyiség nem elérhető a kiválasztott raktárban vagy nulla!',
                  {
                    variant: 'error',
                  }
                );
                return;
              }
              setFieldValue(`items[${index}].amount`, value);
            }}
            label="Mennyiség"
            type="number"
            name="amount"
            disabled={readonly}
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment position="end">db</InputAdornment>,
            }}
            sx={{
              input: {
                xs: { textAlign: 'center' },
                md: { textAlign: 'right' },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={1}>
          <Checkbox
            checked={x.checked}
            required
            onChange={(e, checked) => {
              setFieldValue(`items[${index}].checked`, checked);
            }}
          />
        </Grid>
      </>
    );
  }, [x, items, readonly, index]);

  return memoizedItem;
};

export default ItemSupplyItem;
