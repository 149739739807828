import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useCheckPermission from '../../../hooks/useCheckPermission';
import useConfirmDeleteDialog from '../../../hooks/useConfirmDeleteDialog';
import taskLogService from '../../../services/erp/taskLogService';
import { DatePicker } from '@mui/x-date-pickers';

export default function TaskLogFormDialog(props: any) {
  const { open, setOpen, taskId, entity, readOnly, updateRows } = props;

  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const dispatch = useDispatch<any>();
  const [now, setNow] = useState<Date>(new Date());
  const [newEntity, setNewEntity] = useState<any>({
    id: 0,
    taskId: taskId,
    description: '',
    workHours: 0,
    isTaskCompleted: false,
    date: now,
  });

  const { enqueueSnackbar } = useSnackbar();
  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    if (entity?.id > 0) {
      setNewEntity(entity);
    }
  }, [entity]);

  useEffect(() => {
    if (taskId > 0) {
      setNewEntity({
        ...newEntity,
        taskId: taskId,
      });
    }
  }, [taskId]);

  const handleClose = () => {
    setOpen({ open: false, taskId: null });
    setNewEntity({
      id: 0,
      taskId: taskId,
      description: '',
      workHours: 0,
      isTaskCompleted: false,
      date: now,
    });
  };

  const createLog = (entity: any) => {
    dispatch({ type: 'SHOW_QUERY' });
    taskLogService
      .create(entity)
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) {
          updateRows(response.result);
          enqueueSnackbar('Sikeres mentés', {
            variant: 'success',
          });
        } else {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        }
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  };

  const updateLog = (entity: any) => {
    dispatch({ type: 'SHOW_QUERY' });
    taskLogService
      .update(entity)
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) {
          updateRows(response.result);
          enqueueSnackbar('Sikeres mentés', {
            variant: 'success',
          });
        } else {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        }
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  };

  return (
    <Grid>
      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogTitle>
          <Typography variant="h6">Feladat napló hozzáadása:</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} p={2}>
            <Grid item xs={12} lg={'auto'} p={1}>
              <DatePicker
                label="Dátum"
                value={newEntity?.date}
                onChange={(date) =>
                  setNewEntity({
                    ...newEntity,
                    date: date,
                  })
                }
                renderInput={(props) => <TextField {...props} />}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Munkaóra"
                variant="outlined"
                type="number"
                value={newEntity?.workHours}
                disabled={readOnly}
                onChange={(event) => {
                  setNewEntity({
                    ...newEntity,
                    workHours: event.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Leírás"
                variant="outlined"
                value={newEntity?.description}
                disabled={readOnly}
                multiline
                minRows={3}
                onChange={(event) => {
                  setNewEntity({
                    ...newEntity,
                    description: event.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                label="Feladat teljesítve"
                control={
                  <Checkbox
                    checked={newEntity?.isTaskCompleted}
                    disabled={readOnly}
                    onChange={(e, checked) => {
                      setNewEntity({
                        ...newEntity,
                        isTaskCompleted: checked,
                      });
                    }}
                  />
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            disabled={readOnly}
            onClick={() => {
              if (newEntity.id > 0) {
                updateLog(newEntity);
              } else {
                createLog(newEntity);
              }
              handleClose();
            }}
          >
            Mentés
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              handleClose();
            }}
          >
            Mégse
          </Button>
        </DialogActions>
        <ConfirmDeleteDialog />
      </Dialog>
    </Grid>
  );
}
