import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import useCheckPermission from "../../../hooks/useCheckPermission";
import abilityService from "../../../services/authority/abilityService";
import AbilityForm from "./AbilityForm";

const AbilityEdit = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const params = useParams();
  const [errors, setErrors] = useState<string[]>([]);
  const [entity, setEntity] = useState<any>({ name: "", description: "" });

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    abilityService.get(params.id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        setErrors(response.errorMessages);
        enqueueSnackbar(response.errorMessages.join(","), {
          variant: "error",
        });
      } else {
        setEntity(response.result);
      }
    });
  }, [params.id]);

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: "SHOW_SAVE" });
    abilityService
      .update(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(","), {
            variant: "error",
          });
        } else {
          enqueueSnackbar("Sikeres mentés!", {
            variant: "success",
          });

          navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: "HIDE" });
        setSubmitting(false);
      });
  };

  return (
    <AbilityForm
      entity={entity}
      readonly={!checkPermission(["AbilityEdit"])}
      onSubmit={onSubmit}
      handleCancel={() => navigate(-1)}
      errors={errors}
    />
  );
};

export default AbilityEdit;
