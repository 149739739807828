import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useCheckPermission from '../../../hooks/useCheckPermission';
import holidayService from '../../../services/erp/holidayService';
import HolidayForm from './HolidayForm';
import { tomorrow } from '../../../utils/dateUtils';
import userService from '../../../services/authority/userService';

const HolidayCreate = (props: any) => {
  const [now] = useState(new Date().setDate(new Date().getDate() + 1));
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<string[]>([]);
  const [users, setUsers] = useState<any[]>([]);
  const user = useSelector((state: any) => state.user.userInfo);
  const { checkPermission } = useCheckPermission();

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    holidayService
      .create(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });
          navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        setSubmitting(false);
      });
  };

  useEffect(() => {
    userService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setUsers(response.records);
      }
    });
  }, []);

  return (
    <HolidayForm
      entity={{
        startDate: tomorrow(0, 0),
        endDate: tomorrow(23, 59),
        userId: user.userId,
        description: '',
      }}
      readonly={!checkPermission(['HolidayCreate'])}
      onSubmit={onSubmit}
      users={users}
      errors={errors}
      handleCancel={() => navigate(-1)}
    />
  );
};

export default HolidayCreate;
