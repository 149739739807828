import Hashids from 'hashids';
import * as React from 'react';
import systemConfigurationService from '../services/systemConfigurationService';
import { useState } from 'react';

const useHashId = () => {
  const [config, setConfig] = useState({ key: '', value: '' });
  const hashid = new Hashids(config.value, 8);

  React.useEffect(() => {
    systemConfigurationService
      .getByKey('account.sessionTimeout')
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) setConfig(response.result);
      });
  }, []);

  const encode = (id: number) => hashid.encode(id);
  const decode = (id: string) => hashid.decode(id);
  return { encode, decode };
};
export default useHashId;
