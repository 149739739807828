import {
  Button,
  Box,
  Grid,
  Paper,
  Tooltip,
  DialogContent,
  DialogActions,
  Dialog,
} from '@mui/material';
import { Form, Formik } from 'formik';
import useCheckPermission from '../../../hooks/useCheckPermission';
import WorkItemWizard from './wizard/WorkItemWizard';
import { useSelector } from 'react-redux';
import ResourcePlanner from './resourcePlanner/ResourcePlanner';
import workItemService from '../../../services/erp/workItemService';
import { useSnackbar } from 'notistack';
import ReasonDialog from './wizard/steps/ReasonDialog';
import React, { useEffect } from 'react';

export enum WorkItemStatus {
  Draft = 1,
  New = 2,
  Assigned = 3,
  Completed = 4,
  Cancelled = 5,
  Closed = 6,
  Suspended = 7,
  Evaluated = 8,
}

const WorkItemForm = (props: any) => {
  const {
    entity,
    onSubmit,
    errors,
    handleCancel,
    readonly = false,
    completeSurvey,
    workItemClose,
    handleIncomplete,
  } = props;

  const { checkPermission } = useCheckPermission();
  const fullscreenMode = useSelector((state: any) => state.app.fullscreenMode);
  const { enqueueSnackbar } = useSnackbar();
  const [reasonDialogOpen, setReasonDialogOpen] = React.useState(false);
  const [exitDialogOpen, setExitDialogOpen] = React.useState(false);

  const handleWorkItemReject = (reason: string) => {
    workItemService
      .rejectWorkFlow({ workItemId: entity.id, reason: reason })
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });
        }
      });
  };

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};
        if (!values.name) {
          errors.name = 'Required';
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({
        isSubmitting,
        setSubmitting,
        values,
        setFieldValue,
        touched,
        handleChange,
        errors: validationErrors,
      }) => (
        <Form>
          <Paper>
            <Grid container justifyContent="left" pb={5} pt={2}>
              <Grid item xs={1} pl={2}>
                {values.workItemNumber}
              </Grid>
              <Grid
                container
                item
                xs={11}
                pt={3}
                pb={3}
                justifyContent="center"
                sx={{ display: { md: 'flex' } }}
              >
                {!readonly && (
                  <>
                    {values.roleName === 'Surveyor' && (
                      <Grid item p={1} textAlign={'center'}>
                        <Button
                          onClick={() => completeSurvey(values)}
                          disabled={readonly || isSubmitting}
                          variant="contained"
                        >
                          Felmérés lezárása
                        </Button>
                      </Grid>
                    )}
                    {values.roleName !== 'Dispatcher' && (
                      <Grid item p={1}>
                        <Button
                          onClick={() => setReasonDialogOpen(true)}
                          variant="contained"
                          color="error"
                        >
                          Munkalap elutasítása
                        </Button>
                      </Grid>
                    )}
                    {values.roleName === 'Dispatcher' &&
                      values.workItemStatus !== WorkItemStatus.Closed &&
                      values.workItemStatus !== WorkItemStatus.Evaluated && (
                        <Grid item p={1} textAlign={'center'}>
                          <Tooltip title="Lezárja a munkalapot véglegesen.">
                            <Button
                              onClick={() => workItemClose(values.id)}
                              disabled={readonly || isSubmitting}
                              variant="contained"
                            >
                              Munkalap lezárása
                            </Button>
                          </Tooltip>
                        </Grid>
                      )}
                  </>
                )}
                <Grid item p={1}>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => setExitDialogOpen(true)}
                  >
                    Kilépés
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <WorkItemWizard
                  values={values}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  setIsSubmitting={setSubmitting}
                  isSubmitting={isSubmitting}
                  touched={touched}
                  validationErrors={validationErrors}
                  {...props}
                />
              </Grid>
            </Grid>
          </Paper>
          <ReasonDialog
            open={reasonDialogOpen}
            setReasonDialogOpen={setReasonDialogOpen}
            onClose={function (reason: string): void {
              handleWorkItemReject(reason);
              setReasonDialogOpen(false);
              handleCancel();
            }}
          />
          <Dialog
            open={exitDialogOpen}
            onClose={() => setExitDialogOpen(false)}
          >
            <DialogContent>
              <Grid item xs={12}>
                Szeretné menteni a változtatásokat?
              </Grid>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
              <Grid item p={1}>
                <Button
                  onClick={() => {
                    handleIncomplete(values, '', setSubmitting);
                    handleCancel();
                  }}
                  disabled={readonly}
                  variant="contained"
                >
                  Mentés
                </Button>
              </Grid>
              <Grid item p={1}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleCancel}
                >
                  Kilépés
                </Button>
              </Grid>
            </DialogActions>
          </Dialog>
        </Form>
      )}
    </Formik>
  );
};

export default WorkItemForm;
