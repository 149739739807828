import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Grid,
  Autocomplete,
  InputAdornment,
  Tooltip,
  Paper,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import useCheckPermission from '../../../hooks/useCheckPermission';
import CreateEntityDecorator from '../../../components/CreateEntityDecorator';
import ItemCreate from '../items/ItemCreate';

export default function AddBasicItemDialog(props: any) {
  const {
    open,
    setOpen,
    addToCart,
    cart,
    items,
    scannedItem = null,
    disableAvailableAmount = false,
  } = props;
  const [item, setItem] = React.useState({
    id: 0,
    amount: 0,
  });
  const getAvailableAmount = (itemId: number) => {
    let warehouseAmount = 0;
    let found = items.find((x) => x.itemId === itemId);
    if (found) {
      if (found.isComplexItem) {
        let partCount = found.parts?.map((x) => {
          let available = getAvailableAmount(x.partItemId);

          return { count: Math.floor(available / x.partAmount) };
        }) ?? [{ count: 0 }];
        if (partCount.length > 0) {
          let minCount = Math.min(...partCount.map((x) => x.count));
          warehouseAmount = minCount;
        }
      } else {
        warehouseAmount = found.amount;
      }
    }
    if (cart?.length > 0) {
      let cartAmount = 0;

      if (!found.isComplexItem) {
        let complexItemsInCart = cart.filter(
          (x) => items.find((y) => x.itemId === y.itemId)?.isComplexItem
        );
        if (complexItemsInCart.length > 0) {
          complexItemsInCart.forEach((complexItem) => {
            let complexItemParts = items.find(
              (x) => x.itemId === complexItem.itemId
            )?.parts;
            complexItemParts?.forEach((part) => {
              if (part.partItemId === itemId) {
                cartAmount += part.partAmount * complexItem.amount;
              }
            });
          });
        }
        cartAmount += cart
          .filter((x) => x.itemId === itemId)
          .reduce((acc, cur) => (acc += cur.amount), 0);
      }

      return warehouseAmount - cartAmount;
    }
    return warehouseAmount;
  };

  useEffect(() => {
    if (scannedItem) {
      setItem({ ...scannedItem, amount: 1 });
    }
  }, [scannedItem]);

  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setOpen(false);
  };

  const { checkPermission } = useCheckPermission();

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Termék felvétele</DialogTitle>
      <DialogContent>
        <Grid style={{ minHeight: '50vh' }}>
          <Grid container spacing={2} style={{ paddingTop: 10 }}>
            <Grid item xs={12}>
              <CreateEntityDecorator
                hideAdd={!checkPermission(['ItemCreate'])}
                AutocompleteComponent={
                  <Autocomplete
                    disablePortal
                    id="itemId"
                    value={item}
                    onChange={(event, value: any) => {
                      setItem({
                        ...value,
                        amount: 1,
                      });
                    }}
                    isOptionEqualToValue={(option, value) => {
                      return option.id === value.id;
                    }}
                    getOptionLabel={(option) => {
                      if (option.id === 0 || option.name === undefined) {
                        return '';
                      }
                      if (disableAvailableAmount) {
                        return `(${option.itemNumber})${option.name}(${option.barcode})`;
                      }
                      return `(${option.itemNumber})${option.name}(${
                        option.barcode
                      }) - Elérhető: ${getAvailableAmount(option.id)} db`;
                    }}
                    options={items?.filter((x) =>
                      disableAvailableAmount
                        ? true
                        : getAvailableAmount(x.id) > 0
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label="Termék"
                        fullWidth
                      />
                    )}
                  />
                }
                onSave={(item) => {
                  addToCart({
                    itemId: item.itemId,
                    amount: 1,
                  });
                }}
                CreateEntityComponent={ItemCreate}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={item.amount}
                onChange={(event) => {
                  let amount = parseFloat(event.target.value);
                  if (
                    amount === 0 ||
                    (amount > getAvailableAmount(item.id) &&
                      !disableAvailableAmount)
                  ) {
                    enqueueSnackbar(
                      'Hozzáadni kívánt mennyiség nem elérhető a kiválasztott raktárban vagy nulla!',
                      {
                        variant: 'error',
                      }
                    );
                    return;
                  }
                  setItem({ ...item, amount: amount });
                }}
                label="Darabszám"
                disabled={item.id === 0}
                name="amount"
                size="small"
                fullWidth
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">db</InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={item.id === 0}
          onClick={() => {
            if (item.id !== 0) {
              addToCart(item);
              setItem({
                id: 0,
                amount: 1,
              });
            }
          }}
        >
          Hozzáadás
        </Button>
        <Button color="primary" variant="outlined" onClick={handleClose}>
          Mégse
        </Button>
      </DialogActions>
    </Dialog>
  );
}
