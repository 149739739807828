import {
  Grid,
  Button,
  IconButton,
  SpeedDial,
  TextField,
  Typography,
  InputAdornment,
  Autocomplete,
  Tooltip,
  useMediaQuery,
  Badge,
} from '@mui/material';
import { memo, useEffect, useState, useCallback } from 'react';
import { Add, Delete, Info } from '@mui/icons-material';
import useCheckPermission from '../../../../hooks/useCheckPermission';
import {
  formatCurrency,
  currencyAdornment,
} from '../../../../utils/valueFormatters';
import AddNewItemDialog from '../../../sales/AddNewItemDialog';
import { useSelector, useDispatch } from 'react-redux';
import BarcodeQrCodeReaderDialog from '../../../../components/mobile-barcode-qrCode-scanner/BarcodeQrCodeReaderDialog';
import { useBarcodeScanner } from '../../../../components/barcode-scanner/useBarcodeScanner';
import { useSnackbar } from 'notistack';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { read } from 'fs';
import itemPriceService from '../../../../services/pricing/itemPriceService';
import AcquisitionHistoryDialog from '../../../pricing/acquisitionPrices/AcquisitionHistoryDialog';

const SelectItems = (props: any) => {
  const {
    values,
    setFieldValue,
    listPrices,
    readOnly,
    loadItemPrices,
    isOffer,
    priceCategories,
    taxes,
    canOverrideGrossPrice = false,
  } = props;
  const [addItemDialog, setAddItemDialog] = useState({
    open: false,
    item: {},
  });
  const userWarehouse = useSelector((state: any) => state.userWarehouse);
  const [qrCodeReaderDialogState, setQrCodeReaderDialogState] = useState<any>({
    open: false,
  });
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const { checkPermission } = useCheckPermission();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const [itemStocks, setItemStocks] = useState<any[]>([]);
  const [openDialog, setOpenDialog] = useState({
    open: false,
    row: { acquisitionHistory: {}, itemId: 0 },
  });

  const loadData = useCallback(() => {
    let ac = new AbortController();
    dispatch({ type: 'SHOW_QUERY' });
    itemPriceService
      .listAcquisitionPrices(ac.signal)
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) setItemStocks(response.records);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));

    return () => {
      ac.abort();
    };
  }, [dispatch, enqueueSnackbar]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    if (values.priceCategoryId !== null) {
      if (values.warehouseId) {
        loadItemPrices(values.warehouseId, values.priceCategoryId);
      } else {
        loadItemPrices(null, values.priceCategoryId);
      }
    }
  }, [values.warehouseId, values.priceCategoryId]);

  const procurementQuantityCalc = (item: any) => {
    if (item.itemId === 0) return 0;
    if (item.itemId < 0) {
      return item.amount;
    } else if (getAvailableAmount(item.itemId) < 0) {
      return getAvailableAmount(item.itemId) * -1;
    } else {
      return 0;
    }
  };

  const handleBarcodeRead = (barcode) => {
    if (readOnly) return;
    if (barcode.item) {
      setAddItemDialog({ open: true, item: barcode.item });
    } else {
      enqueueSnackbar(
        'Ismeretlen árucikk, ellenőrizze hogy van-e kiválasztott árkategória / raktár',
        {
          variant: 'error',
        }
      );
    }
  };

  useBarcodeScanner(handleBarcodeRead, listPrices);

  const getAvailableAmount = (itemId: number) => {
    let warehouseAmount = 0;
    let found = listPrices?.find((x) => x.itemId === itemId);
    if (found) {
      if (found.isComplexItem) {
        let partCount = found.parts?.map((x) => {
          let available = getAvailableAmount(x.partItemId);

          return { count: Math.floor(available / x.partAmount) };
        }) ?? [{ count: 0 }];
        if (partCount.length > 0) {
          let minCount = Math.min(...partCount.map((x) => x.count));
          warehouseAmount = minCount;
        }
      } else {
        warehouseAmount = found.availableAmount;
      }
    }
    let cartAmount = 0;

    if (!found?.isComplexItem) {
      let complexItemsInCart = values.selectedItems.filter(
        (x) => listPrices?.find((y) => x.itemId === y.itemId)?.isComplexItem
      );
      if (complexItemsInCart.length > 0) {
        complexItemsInCart.forEach((complexItem) => {
          let complexItemParts = listPrices.find(
            (x) => x.itemId === complexItem.itemId
          )?.parts;
          complexItemParts?.forEach((part) => {
            if (part.partItemId === itemId) {
              cartAmount += part.partAmount * complexItem.amount;
            }
          });
        });
      }
      cartAmount += values.selectedItems
        ?.filter((x) => x.itemId === itemId)
        .reduce((acc, cur) => (acc += cur.amount), 0);
      cartAmount += values.itemPackages
        ?.map((c) =>
          c.items
            ?.filter((i) => i.itemId === itemId)
            .reduce((acc, cur) => (acc += cur.amount), 0)
        )
        .reduce((acc, cur) => (acc += cur), 0);
    }

    return warehouseAmount - cartAmount;
  };

  const getAcquisitionHistory = () => {
    return (
      itemStocks.find((x) => x.itemId === openDialog.row.itemId)
        ?.acquisitionHistory ?? []
    );
  };

  return (
    <Grid container justifyContent="right">
      <Grid container item xs={12} spacing={2}>
        {!isOffer && isSmallScreen && (
          <Grid item>
            <Tooltip title="QRCode olvasás">
              <Button
                variant="contained"
                onClick={() => {
                  setQrCodeReaderDialogState({ open: true });
                }}
              >
                Vonalkód olvasás
              </Button>
            </Tooltip>
          </Grid>
        )}
        {!isOffer &&
          (userWarehouse?.warehouses.length > 1 ||
            userWarehouse?.selectedWarehouseId !== values?.warehouseId) &&
          values?.roleName === 'Dispatcher' && (
            <Grid item xs={12} md={2} pb={2}>
              <Autocomplete
                disablePortal
                fullWidth
                disabled={readOnly}
                id="warehouseId"
                value={values?.warehouseId ?? ''}
                onChange={(event, value) => {
                  setFieldValue('warehouseId', value);
                }}
                getOptionLabel={(option) => {
                  return userWarehouse?.warehouses?.find((x) => x.id === option)
                    ?.name;
                }}
                options={userWarehouse?.warehouses?.map((g) => g.id)}
                renderInput={(params) => (
                  <TextField {...params} label="Raktár" />
                )}
              />
            </Grid>
          )}
        {!isOffer && priceCategories?.length > 1 && (
          <Grid item xs={12} md={2} pb={2}>
            <Autocomplete
              disablePortal
              id="priceCategoryId"
              disabled={readOnly}
              value={values?.priceCategoryId ?? ''}
              onChange={(event, value) => {
                setFieldValue('priceCategoryId', value);
              }}
              getOptionLabel={(option) => {
                let priceCategory = priceCategories.find(
                  (g) => g.id === option
                );
                return priceCategory?.name ?? 'Nincs hozzáférésed';
              }}
              options={priceCategories?.map((g) => g.id)}
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Árkategória" />
              )}
            />
          </Grid>
        )}
      </Grid>
      <Grid item sx={{ display: { xs: isOffer ? '' : 'none', md: 'flex' } }}>
        <Button
          disabled={readOnly}
          variant="outlined"
          onClick={() => setAddItemDialog({ open: true, item: {} })}
        >
          Hozzáadás
        </Button>
      </Grid>
      {values.selectedItems?.length === 0 && (
        <Grid item container xs={12}>
          Nem igényel anyagokat
        </Grid>
      )}
      <Grid item container xs={12}>
        {values.selectedItems?.map((x, i) => {
          return (
            <Grid
              item
              container
              spacing={2}
              pt={1}
              key={i}
              alignItems="center"
              textAlign={'center'}
              style={{ borderBottom: '1px solid' }}
            >
              <Grid item xs={12} md={2}>
                <TextField
                  value={x.itemName}
                  variant="standard"
                  onChange={(e) => {
                    var value = e.target.value;
                    setFieldValue(`selectedItems[${i}].itemName`, value);
                  }}
                  label="Termék megnevezése"
                  type="text"
                  name="itemName"
                  fullWidth
                  disabled={x.itemId > 0}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField
                  value={taxes.find((y) => y.id === x.taxTypeId)?.name ?? ''}
                  variant="standard"
                  label="Adótípus"
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={3} container>
                <Grid item xs={12} md={6}>
                  <TextField
                    value={x.sellPriceNet?.toFixed(2) ?? ''}
                    variant="standard"
                    label="Nettó egységár"
                    onChange={(e) => {
                      let value = parseFloat(e.target.value);
                      setFieldValue(`selectedItems[${i}].sellPriceNet`, value);
                      let gross =
                        value *
                        (1 +
                          (taxes?.find((y) => y.id === x.taxTypeId)?.value ??
                            27) /
                            100);
                      setFieldValue(
                        `selectedItems[${i}].sellPriceGross`,
                        gross
                      );
                    }}
                    type="number"
                    fullWidth
                    disabled={
                      readOnly ||
                      (isOffer
                        ? false
                        : !checkPermission(['WorkItemCustomPrice']))
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {' '}
                          {currencyAdornment(values.currency)}
                        </InputAdornment>
                      ),
                    }}
                    sx={{ input: { textAlign: 'right' } }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    value={x.sellPriceGross?.toFixed(2) ?? ''}
                    variant="standard"
                    onChange={(e) => {
                      var value = parseFloat(e.target.value);
                      setFieldValue(
                        `selectedItems[${i}].sellPriceGross`,
                        value
                      );
                      var net =
                        value /
                        (1 +
                          taxes.find((y) => y.id === x.taxTypeId)?.value / 100);
                      setFieldValue(`selectedItems[${i}].sellPriceNet`, net);
                    }}
                    label="Bruttó egységár"
                    type="number"
                    name="gross"
                    fullWidth
                    disabled={
                      readOnly ||
                      (isOffer
                        ? false
                        : !checkPermission(['WorkItemCustomPrice']))
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {' '}
                          {currencyAdornment(values.currency)}
                        </InputAdornment>
                      ),
                    }}
                    sx={{ input: { textAlign: 'right' } }}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                container
                justifyContent="center"
                spacing={2}
              >
                <Grid item xs={12} md={3}>
                  <TextField
                    value={x.amount}
                    variant="standard"
                    onChange={(e) => {
                      var value = parseFloat(e.target.value);
                      setFieldValue(`selectedItems[${i}].amount`, value);
                    }}
                    label="Mennyiség"
                    type="number"
                    name="amount"
                    disabled={readOnly}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">db</InputAdornment>
                      ),
                    }}
                    sx={{ input: { textAlign: 'right' } }}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextField
                    value={x.discount}
                    variant="standard"
                    onChange={(e) => {
                      var value = parseFloat(e.target.value);
                      if (value >= 0 && value <= 100) {
                        setFieldValue(`selectedItems[${i}].discount`, value);
                      }
                    }}
                    label="Engedmény"
                    type="number"
                    disabled={readOnly}
                    name="discount"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                    sx={{ input: { textAlign: 'right' } }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    value={procurementQuantityCalc(x)}
                    variant="standard"
                    label="Beszerezendő mennyiség"
                    type="number"
                    disabled={true}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">db</InputAdornment>
                      ),
                    }}
                    sx={{ input: { textAlign: 'right' } }}
                  />
                </Grid>

                <Grid
                  container
                  justifyContent={isSmallScreen ? 'space-between' : 'center'}
                  alignItems="center"
                  item
                  xs={12}
                  md={3}
                >
                  <Grid item>
                    {' '}
                    <Typography fontWeight={'Bold'}>Összesen:</Typography>
                    {formatCurrency(
                      x.sellPriceGross * x.amount * (1 - x.discount / 100),
                      values.currency
                    )}
                  </Grid>
                  <Grid item>
                    {checkPermission(['ItemStockView']) && isOffer && (
                      <Grid>
                        <IconButton
                          onClick={() =>
                            setOpenDialog({
                              row: itemStocks.find(
                                (c) => x.itemId === c.itemId
                              ),
                              open: true,
                            })
                          }
                        >
                          <Info color="info" />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={1}
                sx={{ position: 'relative', right: { xs: '0%' } }}
              >
                <Badge
                  badgeContent={x.parts?.length ?? 0}
                  sx={{
                    padding: '0 4px',
                    '& .MuiBadge-badge': {
                      fontSize: 13,
                      height: 15,
                      minWidth: 15,
                      top: 5,
                    },
                  }}
                  color="error"
                >
                  <IconButton
                    disabled={readOnly}
                    onClick={() => {
                      setFieldValue('selectedItems', [
                        ...values.selectedItems?.filter(
                          (y) => y.itemId !== x.itemId
                        ),
                      ]);
                    }}
                    color={'primary'}
                  >
                    <Delete />
                  </IconButton>
                </Badge>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={x.comment}
                  variant="standard"
                  fullWidth
                  onChange={(e) => {
                    var value = e.target.value;
                    setFieldValue(`selectedItems[${i}].comment`, value);
                  }}
                  disabled={readOnly}
                  label="Megjegyzés"
                />
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      <AcquisitionHistoryDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        getAcquisitionHistory={getAcquisitionHistory}
        loadData={loadData}
        hideIgnore={true}
      />
      <AddNewItemDialog
        items={listPrices}
        open={addItemDialog.open}
        itemScanned={addItemDialog.item}
        workItem={values}
        currency={values.currency}
        conversionRate={values.conversionRate}
        setOpen={setAddItemDialog}
        getAvailableAmount={getAvailableAmount}
        selectUnpriced={values.roleName !== 'Mechanic'}
        taxes={taxes}
        cart={values.selectedItems}
        canOverrideGrossPrice={canOverrideGrossPrice}
        addToCart={(item) => {
          const i = values.selectedItems?.find((x) => x.itemId === item.itemId);
          if (!i) {
            setFieldValue('selectedItems', [
              ...values.selectedItems,
              {
                itemName: item.itemName,
                itemAlternateName: item.itemAlternateName,
                itemNumber: item.itemNumber,
                itemId: item.itemId,
                amount: item.amount,
                parts: item.parts,
                isComplexItem: item.isComplexItem,
                discount: item.discount,
                sellPriceNet: item.sellPriceNet,
                sellPriceGross: item.sellPriceGross,
                taxTypeId: item.taxTypeId,
                comment: item.comment,
              },
            ]);
          } else {
            setFieldValue('selectedItems', [
              ...values.selectedItems?.filter((x) => x.itemId !== i.itemId),
              {
                ...i,
                discount: item.discount,
                amount: item?.amount + i?.amount,
              },
            ]);
          }
          setAddItemDialog({ open: false, item: {} });
        }}
      />
      <SpeedDial
        ariaLabel="Termék hozzáadása"
        onClick={() => setAddItemDialog({ open: true, item: {} })}
        sx={{
          display: { xs: isOffer ? 'none' : '', md: 'none' },
          position: 'fixed',
          bottom: 16,
          right: 16,
        }}
        icon={<Add />}
      ></SpeedDial>
      <SpeedDial
        ariaLabel="QR kód olvasása"
        onClick={() => setQrCodeReaderDialogState({ open: true })}
        sx={{
          display: { xs: isOffer ? 'none' : '', lg: 'none' },
          position: 'fixed',
          bottom: 80,
          right: 16,
        }}
        icon={<QrCodeScannerIcon />}
      ></SpeedDial>
      <BarcodeQrCodeReaderDialog
        open={qrCodeReaderDialogState.open}
        setOpen={setQrCodeReaderDialogState}
        onQrCodeScanned={(data: any) => {
          var item = listPrices.find((x) => x.barcode === data);
          setAddItemDialog({ open: true, item: item });
        }}
      />
    </Grid>
  );
};

export default memo(
  SelectItems,
  (prevProps, nextProps) =>
    prevProps.values.selectedItems === nextProps.values.selectedItems &&
    prevProps.listPrices === nextProps.listPrices &&
    prevProps.isOffer === nextProps.isOffer &&
    prevProps.readOnly === nextProps.readOnly &&
    prevProps.values.priceCategoryId === nextProps.values.priceCategoryId &&
    prevProps.values.warehouseId === nextProps.values.warehouseId &&
    prevProps.values.conversionRate === nextProps.values.conversionRate &&
    prevProps.values.currency === nextProps.values.currency
);
