import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import DownloadIcon from '@mui/icons-material/Download';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
} from '@mui/material';
import { BarChart, axisClasses } from '@mui/x-charts';
import { LineChart } from '@mui/x-charts/LineChart';
import { endOfMonth, startOfMonth } from 'date-fns';
import { groupBy } from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePickerHeader from '../../components/DatePickerHeader';
import useCheckPermission from '../../hooks/useCheckPermission';
import clientService from '../../services/crm/clientService';
import financeService from '../../services/statistics/financeService';
import { ClientTypes } from '../../types/ClientTypes';
import { formatCurrency } from '../../utils/valueFormatters';

const StatisticsHomePage = () => {
  const [chartType, setChartType] = useState('Oszlop');
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const { checkPermission } = useCheckPermission();
  const [tabValue, setTabValue] = useState<number>(2);
  const [clients, setClients] = useState<any[]>([]);
  const [income, setIncome] = useState<any>({
    net: 0,
    gross: 0,
    count: 0,
    taxAmount: 0,
  });
  const [expense, setExpense] = useState<any>({
    net: 0,
    gross: 0,
    count: 0,
    taxAmount: 0,
  });
  const [profit, setProfit] = useState<any>({ net: 0, gross: 0 });
  const user = useSelector((state: any) => state.user.userInfo);
  const [checkboxValue, setCheckboxValue] = useState<boolean>(false);
  const [now] = useState(new Date());
  const [selectedFilterType, setSelectedFilterType] = useState<string>(
    'filterDateByCreation' as
      | 'filterDateByPaid'
      | 'filterDateByCreation'
      | 'filterDateByFulfillment'
  );
  const [selectedInterval, setSelectedInterval] = useState<any>({
    startDate: startOfMonth(moment(now).startOf('day').toDate()),
    endDate: endOfMonth(moment(now).endOf('day').toDate()),
  });
  const [incomesByDay, setIncomesByDay] = useState<any>([
    { date: '', sumNetPrice: 0 },
  ]);
  const [expensesByDay, setExpensesByDay] = useState<any>([
    { date: '', sumNetPrice: 0 },
  ]);
  const [expensesIncomesByDate, setExpensesIncomesByDate] = useState<any>([
    { date: '', expense: 0, income: 0 },
  ]);
  const [chartXAxis, setChartXAxis] = useState<any>([
    new Date().toLocaleDateString(),
  ]);

  const handleChartType = (event: any, newChartType: string) => {
    if (newChartType !== null) {
      setChartType(newChartType);
    }
  };

  const [entity, setEntity] = useState<any>({
    emplyoeeId: !checkPermission(['IncomeQueryAll']) ? user.userId : null,
    startDate: moment(new Date()).startOf('day').toDate(),
    endDate: moment(new Date()).endOf('day').toDate(),
    companyId: 0,
  });

  useEffect(() => {
    let abort = new AbortController();
    clientService.list(false, null, abort.signal).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setClients(response.records);
        setEntity({
          ...entity,
          companyId:
            response.records.filter(
              (x) => x.clientType === ClientTypes.Distributor
            )[0]?.id ?? 0,
        });
      }
    });

    return () => {
      abort.abort();
    };
  }, []);

  useEffect(() => {
    let startDate = selectedInterval.startDate;

    switch (tabValue) {
      case 0:
        setChartXAxis(
          Array.from({ length: 24 }, (_, i) =>
            new Date(
              startDate.getFullYear(),
              startDate.getMonth(),
              startDate.getDate(),
              i
            ).toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
            })
          )
        );
        break;
      case 1:
        setChartXAxis(
          Array.from({ length: 7 }, (_, i) =>
            new Date(
              startDate.getFullYear(),
              startDate.getMonth(),
              startDate.getDate() + i
            ).toLocaleDateString()
          )
        );
        break;
      case 2:
        const daysInMonth = new Date(
          startDate.getFullYear(),
          startDate.getMonth() + 1,
          0
        ).getDate();
        setChartXAxis(
          Array.from({ length: daysInMonth }, (_, i) =>
            new Date(
              startDate.getFullYear(),
              startDate.getMonth(),
              startDate.getDate() + i
            ).toLocaleDateString()
          )
        );
        break;
      case 3:
        setChartXAxis(
          Array.from({ length: 3 }, (_, i) =>
            new Date(startDate.getFullYear(), startDate.getMonth() + i)
              .toLocaleDateString()
              .slice(0, -4)
          )
        );
        break;
      case 4:
        setChartXAxis(
          Array.from({ length: 12 }, (_, i) =>
            new Date(startDate.getFullYear(), startDate.getMonth() + i)
              .toLocaleDateString()
              .slice(0, -4)
          )
        );
        break;
    }
  }, [selectedInterval, tabValue]);

  useEffect(() => {
    let abort = new AbortController();

    dispatch({ type: 'SHOW_QUERY' });
    financeService
      .incomeQuery(
        {
          ...entity,
          startDate: selectedInterval.startDate,
          endDate: selectedInterval.endDate,
          filterDateByFullfilment:
            selectedFilterType === 'filterDateByFullfilment',
          filterDateByPaid: selectedFilterType === 'filterDateByPaid',
          filterDateByCreation: selectedFilterType === 'filterDateByCreation',
        },
        abort.signal
      )
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          const sumNetPriceByDate = Object.entries(
            groupBy(response.records, (record) =>
              tabValue === 4 || tabValue === 3
                ? new Date(record.date).toLocaleDateString().slice(0, -4)
                : new Date(record.date).toLocaleDateString()
            )
          ).reduce((acc, [date, records]) => {
            const sumNetPrice = records.reduce(
              (total, row) => total + row.priceNet,
              0
            );
            return [...acc, { date, sumNetPrice }];
          }, []);
          setIncome({
            ...income,
            gross: response.records.reduce(
              (total, row) => total + row.priceGross,
              0
            ),
            net: response.records.reduce(
              (total, row) => total + row.priceNet,
              0
            ),
            count: response.records.length,
            taxAmount: response.records.reduce(
              (total, row) => total + row.taxAmount,
              0
            ),
          });
          setIncomesByDay(sumNetPriceByDate);
        }
      })
      .then(() => dispatch({ type: 'HIDE' }));

    return () => {
      abort.abort();
    };
  }, [entity, selectedInterval, selectedFilterType]);

  useEffect(() => {
    let abort = new AbortController();

    financeService
      .expenseQuery(
        {
          ...entity,
          startDate: selectedInterval.startDate,
          endDate: selectedInterval.endDate,
        },
        abort.signal
      )
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          const sumNetPriceByDate = Object.entries(
            groupBy(response.records, (record) =>
              tabValue === 4 || tabValue === 3
                ? new Date(record.date).toLocaleDateString().slice(0, -4)
                : new Date(record.date).toLocaleDateString()
            )
          ).reduce((acc, [date, records]) => {
            const sumNetPrice = records.reduce(
              (total, row) => total + row.priceNet,
              0
            );
            return [...acc, { date, sumNetPrice }];
          }, []);
          setExpense({
            ...expense,
            gross: response.records.reduce(
              (total, row) => total + row.priceGross,
              0
            ),
            net: response.records.reduce(
              (total, row) => total + row.priceNet,
              0
            ),
            count: response.records.length,
            taxAmount: response.records.reduce(
              (total, row) => total + row.taxAmount,
              0
            ),
          });
          setExpensesByDay(sumNetPriceByDate);
        }
      });
    return () => {
      abort.abort();
    };
  }, [entity, selectedInterval]);

  useEffect(() => {
    if (expense && income) {
      setProfit({
        net: income.net - expense.net,
        gross: income.gross - expense.gross,
      });
    }
  }, [expense, income]);

  useEffect(() => {
    setExpensesIncomesByDate([]);
    if (chartXAxis.length > 0) {
      chartXAxis.forEach((item: any) => {
        const expenseItem = expensesByDay?.find((x) => x.date === item);
        const incomeItem = incomesByDay?.find((x) => x.date === item);

        const dateData = {
          date: item,
          expense: expenseItem ? expenseItem.sumNetPrice : 0,
          income: incomeItem ? incomeItem.sumNetPrice : 0,
        };

        setExpensesIncomesByDate((prevData: any) => [...prevData, dateData]);
      });
    }
  }, [chartXAxis, incomesByDay, expensesByDay]);

  return (
    <Paper>
      <Grid container spacing={2} p={3}>
        <Grid item xs={12}>
          <Autocomplete
            disablePortal
            id="companyId"
            disabled={!checkPermission(['ClientView'])}
            value={entity.companyId}
            onChange={(event, value) => {
              setEntity({ ...entity, companyId: value });
            }}
            getOptionLabel={(option) => {
              if (option === null) {
                return 'Mindegy';
              }
              var found = clients.find((g) => g.id === option);
              if (found) {
                return `${
                  found?.isPrivatePerson
                    ? found?.name?.fullName
                    : found?.companyName
                } (${found.taxNumber})}`;
              } else return 'Nem található';
            }}
            options={[
              null,
              ...clients
                .filter((c) => c.clientType === ClientTypes.Distributor)
                .map((g) => g.id),
            ]}
            renderInput={(params) => (
              <TextField
                {...params}
                style={{ maxWidth: '400px' }}
                label="Számlakibocsátó"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <DatePickerHeader
            selectedInterval={selectedInterval}
            setSelectedInterval={setSelectedInterval}
            tabValue={tabValue}
            setTabValue={setTabValue}
            netGrossPicker={false}
            localStorageKey={'StatisticsHomePage'}
            setNullSelectedInterval
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                disabled={!checkPermission(['IncomeQueryAll'])}
                checked={checkboxValue}
                onChange={(e, checked) => {
                  setEntity({
                    ...entity,
                    employeeId: checked ? user.userId : null,
                  });
                  setCheckboxValue(checked);
                }}
              />
            }
            label={'Saját adatok'}
          />
        </Grid>
        <Grid item>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="appearedOnLocation"
              row
              name="appearedOnLocation"
              value={selectedFilterType}
              onChange={(event) =>
                setSelectedFilterType(event.target.value as string)
              }
            >
              <FormControlLabel
                value="filterDateByCreation"
                control={<Radio />}
                label="Kiállítás dátuma"
              />
              <FormControlLabel
                value="filterDateByFullfilment"
                control={<Radio />}
                label="Teljesítés dátuma"
              />
              <FormControlLabel
                value="filterDateByPaid"
                control={<Radio />}
                label="Fizetés dátuma"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container p={2} spacing={4}>
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              margin: 'auto',
              transition: '0.3s',
              borderRadius: '10px',
              minHeight: '270px',
            }}
          >
            <CardHeader
              sx={{
                background: '#E4E4E4',
                display: 'block',
                color: 'black',
              }}
              action={
                <Typography
                  fontWeight={'bold'}
                  display="flex"
                  alignItems="center"
                >
                  <DownloadIcon color="primary" />
                  Bevétel statisztika
                </Typography>
              }
            />
            <CardContent>
              <Typography variant="h6">Nettó:</Typography>
              <Typography variant="h4">{formatCurrency(income.net)}</Typography>
              <Typography variant="h6">Bruttó:</Typography>
              <Typography variant="h4">
                {formatCurrency(income.gross)}
              </Typography>
              <Typography variant="caption">{`${income.count} db megrendelés`}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              margin: 'auto',
              transition: '0.3s',
              borderRadius: '10px',
              minHeight: '270px',
            }}
          >
            <CardHeader
              sx={{
                background: '#E4E4E4',
                display: 'block',
                color: 'black',
              }}
              action={
                <Typography
                  fontWeight={'bold'}
                  display="flex"
                  alignItems="center"
                >
                  <MonetizationOnIcon color="success" />
                  Nyereség statisztika
                </Typography>
              }
            />
            <CardContent>
              <Typography variant="h6">Nettó:</Typography>
              <Typography variant="h4">{formatCurrency(profit.net)}</Typography>
              <Typography variant="h6">Bruttó:</Typography>
              <Typography variant="h4">
                {formatCurrency(profit.gross)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card
            sx={{
              margin: 'auto',
              transition: '0.3s',
              borderRadius: '10px',
              minHeight: '270px',
            }}
          >
            <CardHeader
              sx={{
                background: '#E4E4E4',
                display: 'block',
                color: 'black',
              }}
              action={
                <Typography
                  fontWeight={'bold'}
                  display="flex"
                  alignItems="center"
                >
                  <FileUploadIcon color="error" />
                  Kiadás statisztika
                </Typography>
              }
            />
            <CardContent>
              <Typography variant="h6">Nettó:</Typography>
              <Typography variant="h4">
                {formatCurrency(expense.net)}
              </Typography>
              <Typography variant="h6">Bruttó:</Typography>
              <Typography variant="h4">
                {formatCurrency(expense.gross)}
              </Typography>
              <Typography variant="caption">{`${expense.count} db kiadás`}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              margin: 'auto',
              transition: '0.3s',
              borderRadius: '10px',
              minHeight: '270px',
            }}
          >
            <CardHeader
              sx={{
                background: '#E4E4E4',
                display: 'block',
                color: 'black',
              }}
              action={
                <Typography
                  fontWeight={'bold'}
                  display="flex"
                  alignItems="center"
                >
                  <MoneyOffIcon color="error" />
                  Becsült fizetendő ÁFA
                </Typography>
              }
            />
            <CardContent style={{ marginTop: 'auto' }}>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '160px',
                }}
              >
                <Typography variant="h4">
                  {formatCurrency(income.taxAmount - expense.taxAmount)}
                </Typography>
              </Grid>
              <Grid
                container
                sx={{ borderTop: '1px solid grey', height: '20px' }}
              >
                <Grid item xs={6} sx={{ borderRight: '1px solid grey' }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                    }}
                  >
                    <ArrowCircleUpIcon
                      color="primary"
                      sx={{ marginRight: '5px' }}
                    />
                    {formatCurrency(income.taxAmount)}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                    }}
                  >
                    <ArrowCircleDownIcon
                      color="error"
                      sx={{ marginRight: '5px' }}
                    />
                    {formatCurrency(expense.taxAmount)}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Grid container style={{ position: 'relative' }}>
            {tabValue === 0 ||
              (tabValue === 5 && (
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 1,
                  }}
                >
                  <Typography variant="h4" style={{ color: 'white' }}>
                    Nem elérhető
                  </Typography>
                </div>
              ))}
            <Grid item xs={12}>
              <Stack
                direction={{ xs: 'column', xl: 'row' }}
                spacing={1}
                sx={{ width: '100%' }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <ToggleButtonGroup
                    value={chartType}
                    exclusive
                    onChange={handleChartType}
                    aria-label="chart type"
                    fullWidth
                  >
                    {['Oszlop', 'Vonal'].map((type) => (
                      <ToggleButton
                        key={type}
                        value={type}
                        aria-label="left aligned"
                      >
                        {type}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                  {chartType === 'Oszlop' && (
                    <BarChart
                      xAxis={[
                        {
                          scaleType: 'band',
                          dataKey: 'date',
                          valueFormatter: (value: any) => {
                            if (selectedInterval === 'month') {
                              return new Date(value).getDate();
                            } else {
                              return value;
                            }
                          },
                        },
                      ]}
                      yAxis={[
                        {
                          id: 'money',
                          valueFormatter(value) {
                            if (value > 999999) {
                              return `${value / 1000000}M`;
                            }
                          },
                        },
                      ]}
                      dataset={expensesIncomesByDate ?? []}
                      series={[
                        {
                          type: 'bar',
                          yAxisKey: 'money',
                          dataKey: 'expense',
                          label: 'Kiadás',
                          color: '#D23616',
                          valueFormatter: (value: any) => {
                            if (value) {
                              return formatCurrency(value);
                            }
                            return '';
                          },
                        },
                        {
                          type: 'bar',
                          label: 'Bevétel',
                          yAxisKey: 'money',
                          dataKey: 'income',
                          color: '#61AEE9',
                          valueFormatter: (value: any) => {
                            if (value) {
                              return formatCurrency(value);
                            }
                            return '';
                          },
                        },
                      ]}
                      tooltip={{ trigger: 'axis' }}
                      height={300}
                      sx={{
                        pl: 2,
                        [`.${axisClasses.left} .${axisClasses.tickLabel}`]: {
                          fontSize: '11px',
                          fontWeight: 'bold',
                          width: '100px',
                        },
                      }}
                    />
                  )}
                  {chartType === 'Vonal' && (
                    <LineChart
                      sx={{
                        pl: 2,
                        [`.${axisClasses.left} .${axisClasses.tickLabel}`]: {
                          fontSize: '11px',
                          fontWeight: 'bold',
                        },
                      }}
                      xAxis={[
                        {
                          scaleType: 'band',
                          dataKey: 'date',
                          valueFormatter: (value: any) => {
                            if (tabValue === 2) {
                              return new Date(value).getDate();
                            } else {
                              return value;
                            }
                          },
                        },
                      ]}
                      yAxis={[
                        {
                          id: 'money',
                          valueFormatter(value) {
                            if (value > 999999) {
                              return `${value / 1000000}M`;
                            }
                          },
                        },
                      ]}
                      dataset={expensesIncomesByDate ?? []}
                      series={[
                        {
                          yAxisKey: 'money',
                          dataKey: 'expense',
                          label: 'Kiadás',
                          area: true,
                          color: '#D23616',
                          valueFormatter: (value: any) => {
                            if (value) {
                              return formatCurrency(value);
                            }
                            return '';
                          },
                        },
                        {
                          label: 'Bevétel',
                          yAxisKey: 'money',
                          area: true,
                          dataKey: 'income',
                          color: '#61AEE9',
                          valueFormatter: (value: any) => {
                            if (value) {
                              return formatCurrency(value);
                            }
                            return '';
                          },
                        },
                      ]}
                      tooltip={{ trigger: 'axis' }}
                      height={300}
                    />
                  )}
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default StatisticsHomePage;
