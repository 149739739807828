import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Paper,
  TextField,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import useCheckPermission from '../../../hooks/useCheckPermission';
import { EmailTemplateType } from '../../../types/EmailTemplateType';

import { useSnackbar } from 'notistack';
import emailtemplateService from '../../../services/emails/emailtemplateService';
import SunEditorWithCustomButtons from './SunEditorWithCustomButtons';

const EmailTemplateForm = (props: any) => {
  const { entity, onSubmit, errors, handleCancel, readonly = false } = props;
  const [properties, setProperties] = useState<string[]>([]);
  const [type, setType] = useState<EmailTemplateType | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [sunEditor, setSunEditor] = useState<any>(null);

  const templateTypes = [
    EmailTemplateType.Offer,
    EmailTemplateType.Order,
    EmailTemplateType.WorkItem,
    EmailTemplateType.ChimneySweepingCertificate,
  ];

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    if (type !== null) {
      emailtemplateService
        .getEmailTemplateProps(type as number)
        .then((response) => {
          if (response.canceled) return;
          if (response.hasError) {
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
          } else {
            setProperties(response.result.properties);
          }
        });
    }
  }, [type]);

  useEffect(() => {
    if (entity.type) {
      setType(entity.type);
    }
  }, [entity]);

  const sunEditor2 = useCallback(
    (values, setFieldValue) => {
      return (
        <SunEditorWithCustomButtons
          values={values}
          readonly={readonly}
          properties={properties}
          setFieldValue={setFieldValue}
        />
      );
    },
    [properties]
  );

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        setFieldValue,
        handleChange,
        errors: validationErrors,
      }) => (
        <Form>
          {' '}
          <Paper>
            <Grid container justifyContent="left" spacing={2} p={5}>
              <h2>
                {values.type === EmailTemplateType.Offer
                  ? 'Árajánlat'
                  : values.type === EmailTemplateType.Order
                  ? 'Megrendelés'
                  : values.type === EmailTemplateType.ChimneySweepingCertificate
                  ? 'Kéményseprő-ipari tanúsítvány'
                  : 'Munkalap'}{' '}
                Email sablon{' '}
                {readonly
                  ? 'megtekintése'
                  : values.id !== 0
                  ? 'szerkesztése'
                  : 'létrehozása'}
              </h2>
              <Grid xs={12} item pt={5}>
                <Box color="red">
                  {errors?.map((error) => <li>{error}</li>) ?? []}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.name}
                  onChange={handleChange}
                  label="Név"
                  name="name"
                  InputLabelProps={{ shrink: true }}
                  disabled={readonly}
                  fullWidth
                  error={!!touched.name && !!validationErrors.name}
                  helperText={
                    !!touched.name &&
                    !!validationErrors.name &&
                    (validationErrors.name as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.cc}
                  onChange={handleChange}
                  label="Másolat (CC)"
                  name="cc"
                  InputLabelProps={{ shrink: true }}
                  disabled={readonly}
                  fullWidth
                  error={!!touched.cc && !!validationErrors.cc}
                  helperText={
                    !!touched.cc &&
                    !!validationErrors.cc &&
                    (validationErrors.cc as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.replyTo}
                  onChange={handleChange}
                  label="Válasz cím"
                  name="replyTo"
                  InputLabelProps={{ shrink: true }}
                  disabled={readonly}
                  fullWidth
                  error={!!touched.replyTo && !!validationErrors.replyTo}
                  helperText={
                    !!touched.replyTo &&
                    !!validationErrors.replyTo &&
                    (validationErrors.replyTo as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.subject}
                  onChange={handleChange}
                  label="Tárgy"
                  name="subject"
                  InputLabelProps={{ shrink: true }}
                  disabled={readonly}
                  fullWidth
                  error={!!touched.subject && !!validationErrors.subject}
                  helperText={
                    !!touched.subject &&
                    !!validationErrors.subject &&
                    (validationErrors.subject as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.description}
                  onChange={handleChange}
                  label="Leírás"
                  name="description"
                  InputLabelProps={{ shrink: true }}
                  disabled={readonly}
                  fullWidth
                  error={
                    !!touched.description && !!validationErrors.description
                  }
                  helperText={
                    !!touched.description &&
                    !!validationErrors.description &&
                    (validationErrors.description as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                {sunEditor2(values, setFieldValue)}
              </Grid>
              <Grid container item pt={3} justifyContent="left">
                {!readonly && (
                  <Grid item p={1}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        onSubmit(values);
                      }}
                    >
                      Mentés
                    </Button>
                  </Grid>
                )}
                <Grid item p={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleCancel}
                  >
                    Mégse
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default EmailTemplateForm;
