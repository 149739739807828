import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Visibility,
} from "@mui/icons-material";
import { Grid, IconButton, Paper, Tooltip, Badge } from "@mui/material";
import {
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
} from "@mui/x-data-grid";
import { GridRowParams } from "@mui/x-data-grid/models/params/gridRowParams";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import DataList from "../../../components/DataList";
import useCheckPermission from "../../../hooks/useCheckPermission";
import useConfirmDeleteDialog from "../../../hooks/useConfirmDeleteDialog";
import useFunctionDescriptor from "../../../hooks/useFunctionDescriptor";
import vehicleserviceService from "../../../services/deviceManagement/vehicleServiceService";
import { formatCurrency } from "../../../utils/valueFormatters";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { AttachmentTypes } from "../../../types/AttachmentTypes";
import FilePageDialog from "../../../components/FilePageDialog";
import { Instrument } from "../instruments/InstrumentPage";

const VehicleServicePage = () => {
  const navigate = useNavigate();
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const [rows, setRows] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const titleDescriptor = useFunctionDescriptor("VehicleServicesPage.title");
  const [filePageDialogOpen, setFilePageDialogOpen] = useState<any>({
    open: false,
    entityUniqueId: null,
  });

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    dispatch({ type: "SHOW_QUERY" });
    vehicleserviceService
      .list()
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) setRows(response.records);
        else
          enqueueSnackbar(response.errorMessages.join(","), {
            variant: "error",
          });
      })
      .finally(() => dispatch({ type: "HIDE" }));
  }, []);

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];

    if (checkPermission(["CreateFileAttachment"])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : "primary"}
          icon={
            <Tooltip title="Fájl feltöltése">
              <Badge
                badgeContent={params.row.attachmentCount}
                sx={{
                  padding: "0 4px",
                  "& .MuiBadge-badge": {
                    fontSize: 13,
                    height: 15,
                    minWidth: 15,
                    top: 5,
                  },
                }}
                color="error"
              >
                <AttachFileIcon />
              </Badge>
            </Tooltip>
          }
          label="Fájl feltöltése"
          onClick={() => {
            setFilePageDialogOpen({
              open: true,
              entityUniqueId: params.row.uniqueId,
            });
          }}
        />
      );
    }

    let readonly = !checkPermission(["VehicleServiceEdit"]);
    actions.push(
      <GridActionsCellItem
        color={color ? color : "primary"}
        icon={
          readonly ? (
            <Tooltip title="Megtekintés">
              <Visibility />
            </Tooltip>
          ) : (
            <Tooltip title="Szerkesztés">
              <EditIcon />
            </Tooltip>
          )
        }
        label={readonly ? "Megtekintés" : "Szerkesztés"}
        onClick={() => navigate(`/erp/vehicleServices/edit/${params.row.id}`)}
      />
    );
    if (checkPermission(["VehicleServiceDelete"])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : "primary"}
          icon={
            <Tooltip title="Törlés">
              <DeleteIcon />
            </Tooltip>
          }
          label="Törlés"
          onClick={() =>
            setParams({
              open: true,
              name: "",
              onConfirm: async () => handleDelete(params.row.id),
            })
          }
          showInMenu
        />
      );
    }

    return actions;
  };

  const columns: GridColDef[] = [
    { field: "serviceCompanyName", headerName: "Szerviz", flex: 100 },
    { field: "vehicleName", headerName: "Jármű neve", flex: 100 },
    { field: "vehicleLicensePlate", headerName: "Rendszám", flex: 100 },
    {
      field: "wasOilChanged",
      headerName: "Olajcsere",
      valueFormatter(params) {
        return params.value ? "Igen" : "Nem";
      },
      flex: 200,
    },
    { field: "milage", headerName: "Km állás", flex: 200 },
    {
      field: "netPrice",
      headerName: "Nettó Ár",
      valueFormatter(params) {
        return formatCurrency(params.value);
      },
      flex: 100,
    },
    {
      field: "startDate",
      headerName: "Kezdő dátum",
      valueFormatter(params) {
        return new Date(params.value as Date).toLocaleDateString();
      },
      flex: 200,
    },
    {
      field: "endDate",
      headerName: "Végdátum",
      valueFormatter(params) {
        if (params.value === null) return "Nincs";
        return new Date(params.value as Date).toLocaleDateString();
      },
      flex: 200,
    },
    {
      field: "actions",
      type: "actions",
      flex: 200,
      getActions: getActions,
    } as GridActionsColDef,
  ];

  const handleDelete = (id: number) => {
    vehicleserviceService.delete(id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(","), {
          variant: "error",
        });
      } else {
        enqueueSnackbar("Sikeres törlés", {
          variant: "success",
        });
        setRows(rows.filter((row) => row.id !== id));
      }
    });
  };

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>Jármű szervizek{titleDescriptor}</h2>
        </Grid>
        {checkPermission(["VehicleServiceCreate"]) && (
          <Grid container item xs={12} p={2} justifyContent="end">
            <Grid item>
              <Tooltip title="Jármű szerviz létrehozása">
                <IconButton
                  component={RouterLink}
                  to={`/erp/vehicleServices/create`}
                  color="primary"
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <DataList
            rows={rows}
            columns={columns}
            localStorageKey={"VehicleServicePage"}
          />
        </Grid>
        <ConfirmDeleteDialog />
        <FilePageDialog
          onList={(length) => {
            setRows((prev) => {
              return prev.map((row) => {
                if (row.uniqueId === filePageDialogOpen.entityUniqueId) {
                  row.attachmentCount = length;
                }
                return row;
              });
            });
          }}
          open={filePageDialogOpen.open}
          onClose={() =>
            setFilePageDialogOpen({ open: false, entityUniqueId: null })
          }
          attachmentType={AttachmentTypes.VehicleSerivce}
          entityUniqueId={filePageDialogOpen.entityUniqueId}
          localStorageKey={"VehicleServicePageFilePageDialog"}
        />
      </Grid>
    </Paper>
  );
};

export default VehicleServicePage;
