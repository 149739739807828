import {
  GridColDef,
  GridActionsCellItem,
  GridActionsColDef,
} from '@mui/x-data-grid';
import { Paper, IconButton, Grid, Tooltip, Badge } from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Visibility,
  FileUpload,
} from '@mui/icons-material';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import DataList from '../../../components/DataList';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useCheckPermission from '../../../hooks/useCheckPermission';
import useFunctionDescriptor from '../../../hooks/useFunctionDescriptor';
import useConfirmDeleteDialog from '../../../hooks/useConfirmDeleteDialog';
import vehicleDamageReportService from '../../../services/Reports/vehicleDamageReportService';
import { formatCurrency } from '../../../utils/valueFormatters';
import { translateDamageScaleName } from '../../../utils/nameFormatters';
import { AttachmentTypes } from '../../../types/AttachmentTypes';
import FilePageDialog from '../../../components/FilePageDialog';
import { Contract } from '../../hr/contracts/ContractPage';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { parseJSON } from 'date-fns';

export interface VehicleDamageReport {
  id: string;
  name: string;
  description: string;
}

const VehicleDamageReportPage = () => {
  const navigate = useNavigate();
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const [rows, setRows] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const [filePageDialogOpen, setFilePageDialogOpen] = useState<any>({
    open: false,
    entityUniqueId: null,
  });
  const titleDescriptor = useFunctionDescriptor(
    'VehicleDamageReportPage.title'
  );

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    vehicleDamageReportService
      .list()
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) setRows(response.records);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];

    if (checkPermission(['CreateFileAttachment'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Fájl feltöltése">
              <Badge
                badgeContent={params.row.attachmentCount}
                sx={{
                  padding: '0 4px',
                  '& .MuiBadge-badge': {
                    fontSize: 13,
                    height: 15,
                    minWidth: 15,
                    top: 5,
                  },
                }}
                color="error"
              >
                <AttachFileIcon />
              </Badge>
            </Tooltip>
          }
          label="Fájl feltöltése"
          onClick={() => {
            setFilePageDialogOpen({
              open: true,
              entityUniqueId: params.row.uniqueId,
            });
          }}
        />
      );
    }

    let readonly = !checkPermission(['VehicleDamageReportEdit']);
    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          readonly ? (
            <Tooltip title="Megtekintés">
              <Visibility />
            </Tooltip>
          ) : (
            <Tooltip title="Szerkesztés">
              <EditIcon />
            </Tooltip>
          )
        }
        label={readonly ? 'Megtekintés' : 'Szerkesztés'}
        onClick={() =>
          navigate(`/reports/vehicleDamageReports/edit/${params.row.id}`)
        }
      />
    );

    if (checkPermission(['VehicleDamageReportDelete'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Törlés">
              <DeleteIcon />
            </Tooltip>
          }
          label="Törlés"
          onClick={() =>
            setParams({
              open: true,
              name: '',
              onConfirm: async () => handleDelete(params.row.id),
            })
          }
          showInMenu
        />
      );
    }

    return actions;
  };

  const columns: GridColDef[] = [
    {
      field: 'createdAt',
      headerName: 'Dátum',
      flex: 150,
      valueFormatter(params) {
        return parseJSON(params.value).toLocaleDateString();
      },
    },
    { field: 'vehicleName', headerName: 'Jármű', flex: 100 },
    { field: 'userName', headerName: 'Károkozó', flex: 100 },
    { field: 'title', headerName: 'Cím', flex: 100 },
    { field: 'description', headerName: 'Leírás', flex: 100 },
    {
      field: 'damageScale',
      headerName: 'Kár mértéke',
      flex: 100,
      valueFormatter(params) {
        return translateDamageScaleName(params.value);
      },
    },
    {
      field: 'price',
      headerName: 'Ár',
      flex: 100,
      valueFormatter(params) {
        return formatCurrency(params.value);
      },
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 100,
      getActions: getActions,
    } as GridActionsColDef,
  ];

  const handleDelete = (id: number) => {
    vehicleDamageReportService.delete(id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres törlés', {
          variant: 'success',
        });
        setRows(rows.filter((row) => row.id !== id));
      }
    });
  };

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>Jármű kárbejelentések{titleDescriptor}</h2>
        </Grid>
        {checkPermission(['VehicleDamageReportCreate']) && (
          <Grid container item xs={12} p={2} justifyContent="end">
            <Grid item>
              <Tooltip title="Jármű kárbejelentés létrehozása">
                <IconButton
                  component={RouterLink}
                  to={`/reports/vehicleDamageReports/create`}
                  color="primary"
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <DataList
            rows={rows}
            columns={columns}
            localStorageKey={'VehicleDamageReportPage'}
          />
        </Grid>
        <FilePageDialog
          onList={(length) => {
            setRows((prev) => {
              return prev.map((row) => {
                if (row.uniqueId === filePageDialogOpen.entityUniqueId) {
                  row.attachmentCount = length;
                }
                return row;
              });
            });
          }}
          open={filePageDialogOpen.open}
          onClose={() =>
            setFilePageDialogOpen({ open: false, entityUniqueId: null })
          }
          attachmentType={AttachmentTypes.VehicleDamageReport}
          entityUniqueId={filePageDialogOpen.entityUniqueId}
          localStorageKey={'VehicleDamageReportPage'}
        />
        <ConfirmDeleteDialog />
      </Grid>
    </Paper>
  );
};

export default VehicleDamageReportPage;
