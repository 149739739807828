import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import DocumentBlockForm from './DocumentBlockForm';
import { useNavigate } from 'react-router-dom';
import useCheckPermission from '../../../hooks/useCheckPermission';
import documentBlockService from '../../../services/billing/documentBlockService';
import clientService from '../../../services/crm/clientService';
import { EntityStatuses } from '../../../types/EntityStatuses';
import userService from '../../../services/authority/userService';
import { ClientTypes } from '../../../types/ClientTypes';
import { DocumentBlockFormats } from '../../../types/DocumentBlockFormats';
import { DocumentBlockTypes } from '../../../types/DocumentBlockTypes';
import documentTemplateService from '../../../services/billing/documentTemplateService';

const DocumentBlockCreate = (props: any) => {
  const { onSave = null, navigateBack = true, handleCancel = null } = props;

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<[]>([]);
  const [users, setUsers] = useState<any>([]);
  const [documentTemplates, setDocumentTemplates] = useState<any>([]);

  const { checkPermission } = useCheckPermission();

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    documentBlockService
      .create(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });

          if (onSave) onSave(response.result);
          if (navigateBack) navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        setSubmitting(false);
      });
  };

  useEffect(() => {
    userService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        setErrors(response.errorMessages);
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setUsers(response.records);
      }
    });
  }, []);

  useEffect(() => {
    documentTemplateService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        setErrors(response.errorMessages);
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setDocumentTemplates(response.records);
      }
    });
  }, []);

  return (
    <DocumentBlockForm
      entity={{
        name: '',
        prefix: '',
        format: DocumentBlockFormats.Default,
        senderEmail: '',
        senderName: '',
        type: DocumentBlockTypes.Invoice,
        userIds: [],
        documentTemplateId: null,
      }}
      readonly={!checkPermission(['CreateDocumentBlock'])}
      onSubmit={onSubmit}
      errors={errors}
      users={users}
      documentTemplates={documentTemplates}
      handleCancel={() =>
        handleCancel != null ? handleCancel() : navigate(-1)
      }
    />
  );
};

export default DocumentBlockCreate;
