import {
  GridColDef,
  GridActionsCellItem,
  GridActionsColDef,
  GridRowParams,
} from '@mui/x-data-grid';
import {
  Paper,
  Grid,
  Tooltip,
  Box,
  Tab,
  Tabs,
  useMediaQuery,
} from '@mui/material';
import { Edit as EditIcon, Visibility } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import DataList from '../../components/DataList';
import useCheckPermission from '../../hooks/useCheckPermission';
import systemConfigurationService from '../../services/systemConfigurationService';
import { AttachmentTypes } from '../../types/AttachmentTypes';
import FilesPage from '../chimneySweeping/Files/FilesPage';
import ChimneySweepingCertificatePage from '../chimneySweeping/chimneySweepingCertificate/ChimneySweepingCertificatePage';
import ChimneyControlAppointmentPage from '../chimneySweeping/chimneyControlAppointments/ChimneyControlAppointmentPage';
import React from 'react';
import SystemConfigurationModuleSettings from './SystemConfigurationModuleSettings';

export interface SystemConfiguration {
  id: number;
  key: string;
  value: string;
  description: string;
}

const SystemConfigurationsPage = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const { checkPermission } = useCheckPermission();
  const isSmallScreen = useMediaQuery('(max-width:800px)');
  const [tabValue, setTabValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    saveTab(newValue);
  };

  useEffect(() => {
    const savedtab = localStorage.getItem(
      `SystemConfigurationPage_tab${window.location.hash}`
    );
    if (savedtab) {
      setTabValue(JSON.parse(savedtab));
    }
  }, []);

  const saveTab = (tab) => {
    localStorage.setItem(
      `SystemConfigurationPage_tab${window.location.hash}`,
      JSON.stringify(tab)
    );
  };

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    systemConfigurationService
      .list()
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) setRows(response.records);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];

    let readonly = !checkPermission(['SystemConfigurationEdit']);
    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          readonly ? (
            <Tooltip title="Megtekintés">
              <Visibility />
            </Tooltip>
          ) : (
            <Tooltip title="Szerkesztés">
              <EditIcon />
            </Tooltip>
          )
        }
        label={readonly ? 'Megtekintés' : 'Szerkesztés'}
        onClick={() => navigate(`/systemConfigurations/edit/${params.row.id}`)}
      />
    );

    return actions;
  };

  const columns: GridColDef[] = [
    { field: 'key', headerName: 'Kulcs', flex: 100 },
    {
      field: 'description',
      headerName: 'Leírás',
      flex: 100,
    },
    {
      field: 'value',
      headerName: 'Érték',
      flex: 100,
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 20,
      getActions: getActions,
    } as GridActionsColDef,
  ];

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>Rendszerkonfigurációk</h2>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ width: '100%' }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                pb: 2,
                maxWidth: isSmallScreen ? '280px' : '100%',
              }}
            >
              <Tabs
                value={tabValue}
                onChange={handleChange}
                TabIndicatorProps={{ style: { background: '#1976d2' } }}
                sx={{
                  maxWidth: isSmallScreen ? '280px' : '100%',
                  overflowX: 'scroll',
                }}
                allowScrollButtonsMobile
                scrollButtons="auto"
                variant="scrollable"
              >
                {checkPermission(['SystemConfigurationView']) && (
                  <Tab
                    value={0}
                    label="Rendszerkonfigurációk"
                    sx={{ fontWeight: 'bold' }}
                  />
                )}
                {checkPermission(['ViewModuleSettings']) && (
                  <Tab
                    value={1}
                    label="Modulbeállítások"
                    sx={{ fontWeight: 'bold' }}
                  />
                )}
              </Tabs>
            </Box>
            {checkPermission(['SystemConfigurationView']) && tabValue === 0 && (
              <Grid item xs={12}>
                <DataList
                  rows={rows}
                  columns={columns}
                  localStorageKey={'SystemConfigurations'}
                />
              </Grid>
            )}
            {checkPermission(['ViewModuleSettings']) && tabValue === 1 && (
              <SystemConfigurationModuleSettings />
            )}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SystemConfigurationsPage;
