import { Box, Grid, Tab, Tabs, useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';
import useCheckPermission from '../../hooks/useCheckPermission';
import useFunctionDescriptor from '../../hooks/useFunctionDescriptor';
import ChimneySweepingCertificateQueryPage from './ChimneySweepingCertificateQueryPage';
import OrderQueryPage from './OrderQueryPage';

const QueryTabPage = () => {
  const titleDescriptor = useFunctionDescriptor('QueryTabPage.title');
  const { checkPermission } = useCheckPermission();

  const [tabValue, setTabValue] = React.useState(
    checkPermission(['ChimneySweepingCertificateView']) ? 0 : 1
  );
  const isSmallScreen = useMediaQuery('(max-width:800px)');

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    saveTab(newValue);
  };

  useEffect(() => {
    const savedtab = localStorage.getItem(
      `queryTabPage_tab${window.location.hash}`
    );
    if (savedtab) {
      setTabValue(JSON.parse(savedtab));
    }
  }, []);

  const saveTab = (tab) => {
    localStorage.setItem(
      `queryTabPage_tab${window.location.hash}`,
      JSON.stringify(tab)
    );
  };

  return (
    <Grid container p={3}>
      <Grid item xs={12}>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              pb: 2,
              maxWidth: isSmallScreen ? '280px' : '100%',
            }}
          >
            <Tabs
              value={tabValue}
              onChange={handleChange}
              TabIndicatorProps={{ style: { background: '#1976d2' } }}
              sx={{
                maxWidth: isSmallScreen ? '280px' : '100%',
                overflowX: 'scroll',
              }}
              allowScrollButtonsMobile
              scrollButtons="auto"
              variant="scrollable"
            >
              {checkPermission(['ChimneySweepingCertificateView']) && (
                <Tab
                  value={0}
                  label="Tanúsítvány lekérdezés"
                  sx={{ fontWeight: 'bold' }}
                />
              )}
              {checkPermission(['OrderView']) && (
                <Tab
                  value={1}
                  label="Bizonylat lekérdezés"
                  sx={{ fontWeight: 'bold' }}
                />
              )}
            </Tabs>
          </Box>{' '}
          {checkPermission(['ChimneySweepingCertificateView']) &&
            tabValue === 0 && <ChimneySweepingCertificateQueryPage />}
        </Box>
        {checkPermission(['OrderView']) && tabValue === 1 && <OrderQueryPage />}
      </Grid>
    </Grid>
  );
};

export default QueryTabPage;
