import {
  Button,
  TextField,
  Box,
  Grid,
  Autocomplete,
  Paper,
} from "@mui/material";
import { Form, Formik } from "formik";
import { localizePropertyType } from "../../../localization/wms/propertyTypeLocalizer";
import { IProperty } from "../../../types/IProperty";
import { PropertyTypes } from "../../../types/PropertyTypes";
import ItemGroupCreate from "../itemGroups/ItemGroupCreate";
import CreateEntityDecorator from "../../../components/CreateEntityDecorator";
import useCheckPermission from "../../../hooks/useCheckPermission";
import useFunctionDescriptor from "../../../hooks/useFunctionDescriptor";

const PropertyForm = (props: {
  entity: IProperty;
  groups: any;
  onSubmit: (values: IProperty, setSubbmitting: any) => void;
  errors: any;
  onNewItemGroupAdded: any;
  readonly?: boolean;
  handleCancel: () => void;
}) => {
  const {
    entity,
    groups,
    onSubmit,
    errors,
    handleCancel,
    onNewItemGroupAdded,
    readonly = false,
  } = props;

  const { checkPermission } = useCheckPermission();

  const titleDescriptor = useFunctionDescriptor("PropertyForm.title");

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};
        if (!values.name) {
          errors.name = "Kötelező mező";
        }
        if (!values.propertyType) {
          errors.propertyType = "Kötelező mező";
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({
        isSubmitting,
        values,
        handleChange,
        setFieldValue,
        touched,
        errors: validationErrors,
      }) => (
        <Form>
          <Paper>
            <Grid container justifyContent="left" spacing={2} p={5}>
              <h2>
                Tulajdonság {values.id > 0 ? "szerkesztése" : "létrehozása"}
                {titleDescriptor}
              </h2>
              <Grid xs={12} item pt={5}>
                <Box color="red">
                  {errors.map((error) => (
                    <li>{error}</li>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.name}
                  onChange={handleChange}
                  label="Tulajdonság megnevezése"
                  name="name"
                  disabled={readonly}
                  required
                  fullWidth
                  error={!!touched.name && !!validationErrors.name}
                  helperText={
                    !!touched.name &&
                    !!validationErrors.name &&
                    validationErrors.name
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  value={values.propertyType}
                  onChange={(event, newValue) => {
                    setFieldValue("propertyType", newValue);
                  }}
                  id="type"
                  disabled={readonly}
                  fullWidth
                  options={Object.values(PropertyTypes).filter((x) =>
                    Number.isFinite(x)
                  )}
                  getOptionLabel={(option) => {
                    return localizePropertyType(option as PropertyTypes);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      label="Adattípus"
                      error={
                        !!touched.propertyType &&
                        !!validationErrors.propertyType
                      }
                      helperText={
                        !!touched.propertyType &&
                        !!validationErrors.propertyType &&
                        validationErrors.propertyType
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.unit}
                  onChange={handleChange}
                  label="Mértékegység"
                  disabled={readonly}
                  name="unit"
                  fullWidth
                  error={!!touched.unit && !!validationErrors.unit}
                  helperText={
                    !!touched.unit &&
                    !!validationErrors.unit &&
                    validationErrors.unit
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <CreateEntityDecorator
                  hideAdd={!checkPermission(["ItemGroupCreate"])}
                  AutocompleteComponent={
                    <Autocomplete
                      disablePortal
                      id="itemGroup"
                      disabled={readonly}
                      value={values.itemGroupId}
                      onChange={(event, value) => {
                        setFieldValue("itemGroupId", value);
                      }}
                      getOptionLabel={(option) =>
                        groups.find((g) => g.id === option)?.name ?? ""
                      }
                      options={groups.map((g) => g.id)}
                      renderInput={(params) => (
                        <TextField {...params} label="Termékcsoport neve" />
                      )}
                    />
                  }
                  onSave={(group) => {
                    setFieldValue("itemGroupId", group.id);
                    onNewItemGroupAdded(group);
                  }}
                  CreateEntityComponent={ItemGroupCreate}
                />
              </Grid>
              <Grid container item pt={3} justifyContent="left">
                {!readonly && (
                  <Grid item p={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      Mentés
                    </Button>
                  </Grid>
                )}
                <Grid item p={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleCancel}
                  >
                    Mégse
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default PropertyForm;
