import axios from "axios";

const permissionService = {
  list: () => {
    return axios.get(`/api/permission/list`).then((response) => {
      return response.data;
    });
  },
};

export default permissionService;
