import { Visibility } from '@mui/icons-material';
import {
  Grid,
  Paper,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Divider,
} from '@mui/material';
import { translateClientTypeName } from '../../../../utils/nameFormatters';
import CopyButton from '../../../../components/CopyButton';

const ClientProfileInfoTab = (props: any) => {
  const { client } = props;

  return (
    <Card
      sx={{
        margin: 'auto',
        transition: '0.3s',
        borderRadius: '10px',
      }}
    >
      <CardHeader
        sx={{
          background: '#E4E4E4',
          display: 'block',
          color: 'black',
        }}
        action={
          <Grid container item xs={12}>
            <Typography fontWeight={'bold'} display="flex" alignItems="center">
              <Visibility color="primary" /> Ügyfél adatok
            </Typography>
          </Grid>
        }
      />
      <CardContent style={{ marginTop: 'auto', padding: '0px' }}>
        <Grid container>
          <Grid container p={2} pb={0}>
            <Grid item xs={11}>
              <Grid item xs={12}>
                <Typography
                  fontWeight={'bold'}
                  display="flex"
                  alignItems="center"
                >
                  Ügyfél típus
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography display="flex" alignItems="center">
                  {translateClientTypeName(client.clientType)}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={1}>
              <CopyButton text={translateClientTypeName(client.clientType)} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid container p={2} pb={0}>
            <Grid item xs={11}>
              <Grid item xs={12}>
                <Typography
                  fontWeight={'bold'}
                  display="flex"
                  alignItems="center"
                >
                  Adószám
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography display="flex" alignItems="center">
                  {client.taxNumber}
                  {client.groupMemberTaxNumber
                    ? ` (${client.groupMemberTaxNumber})`
                    : ''}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={1}>
              <CopyButton
                text={`${client.taxNumber}${
                  client.groupMemberTaxNumber
                    ? ` (${client.groupMemberTaxNumber})`
                    : ''
                }`}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid container p={2} pb={0}>
            <Grid item xs={11}>
              <Grid item xs={12}>
                <Typography
                  fontWeight={'bold'}
                  display="flex"
                  alignItems="center"
                >
                  Számlázási cím
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography display="flex" alignItems="center">
                  {
                    client.addresses?.find(
                      (a: any) => a.isBillingAddress === true
                    )?.fullAddress
                  }
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={1}>
              <CopyButton
                text={
                  client.addresses?.find(
                    (a: any) => a.isBillingAddress === true
                  )?.fullAddress ?? ''
                }
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid container p={2} pb={0}>
            <Grid item xs={11}>
              <Grid item xs={12}>
                <Typography
                  fontWeight={'bold'}
                  display="flex"
                  alignItems="center"
                >
                  Bankszámlaszám
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography display="flex" alignItems="center">
                  {client.bankName} {client.bankAccountNumber}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={1}>
              <CopyButton
                text={`${client.bankName} ${client.bankAccountNumber}` ?? ''}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid container p={2} pb={0}>
            <Grid item xs={12}>
              <Typography
                fontWeight={'bold'}
                display="flex"
                alignItems="center"
              >
                Kapcsolat
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Typography display="flex" alignItems="center">
                {client.contactEmail}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <CopyButton text={client.contactEmail} />
            </Grid>
            <Grid item xs={11}>
              <Typography display="flex" alignItems="center">
                {client.contactPhone}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <CopyButton text={client.contactPhone} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {client.comment && (
            <>
              <Grid container p={2} pb={0}>
                <Grid item xs={12}>
                  <Typography
                    fontWeight={'bold'}
                    display="flex"
                    alignItems="center"
                  >
                    Megjegyzés
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography display="flex" alignItems="center">
                    {client.comment}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ClientProfileInfoTab;
