import { Grid, TextField } from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { setForm } from '../../../../../stateManagement/actions/workItemActions';
import { useDispatch } from 'react-redux';

const InternalCommentStep: React.FC = (props: any) => {
  const { values, setFieldValue, readonly, touched, validationErrors } = props;

  const dispatch = useDispatch<any>();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(setForm(values));
    }, 200);
    return () => clearTimeout(timeoutId);
  }, [values]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          value={values.internalComment ?? ''}
          multiline
          rows={8}
          onChange={(e) => setFieldValue('internalComment', e.target.value)}
          label="Belső megjegyzés"
          name="internalComment"
          fullWidth
          disabled={readonly}
        />
      </Grid>
    </Grid>
  );
};

export default InternalCommentStep;
