export enum AttachmentTypes {
  ChimneySweepingCertificate = 1,
  Decision = 2,
  Contract = 3,
  WorkItem = 4,
  Order = 5,
  Acquisition = 6,
  Instrument = 7,
  Project = 8,
  Task = 9,
  Expense = 10,
  Client = 11,
  Vehicle = 12,
  User = 13,
  VehicleDamageReport = 14,
  Report = 15,
  VehicleSerivce = 16,
  Offer = 17,
}
