import InventoryIcon from '@mui/icons-material/Inventory';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Tooltip,
  InputAdornment,
  TextField,
} from '@mui/material';
import Button from '@mui/material/Button';
import { GridActionsColDef, GridColDef, GridRowParams } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import '../css/dataGrid.css';
import itemService from '../services/wms/itemService';
import { EntityTypes } from '../types/EntityTypes';
import DataList from './DataList';
import EntityNavigator from './EntityNavigator';
import { Search } from '@mui/icons-material';

export default function CombineItemIdDialog(props: any) {
  const { open, onClose, onSelected, itemId } = props;
  const [rows, setRows] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const [selectedItemId, setSelectedItemId] = useState<number>(0);
  const [item, setItem] = useState<any>(null);
  const handleClose = () => {
    setSelectedItemId(0);
    onClose();
  };

  const handleCombine = (selectedItemId: any) => {
    itemService
      .combine({ itemIdToDelete: itemId, itemIdToKeep: selectedItemId })
      .then((response) => {
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('A termékek sikeresen össze lettek vonva!', {
            variant: 'success',
          });
          props.onClose();
        }
      });
    onSelected();
    handleClose();
  };
  const [searchTerm, setSearchTerm] = useState('');
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const searchItems = () => {
    // Convert the search term to lowercase for case-insensitive search
    const searchTermLower = searchTerm.toLowerCase();

    // Perform a fuzzy search by splitting the search term into tokens
    const searchTokens = searchTermLower.split(/\s+/);

    // Filter items based on the search tokens
    const filteredItems = rows.filter((item) => {
      // Convert the item name to lowercase
      const itemNameLower = item.name.toLowerCase();

      // Check if each token is present in the item name
      return searchTokens.every((token) => itemNameLower.includes(token));
    });

    return filteredItems;
  };

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    let controller = new AbortController();
    itemService
      .list(null, controller.signal)
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) {
          setItem(response.records.find((x) => x.id === itemId) || null);
          setRows(response.records.filter((x) => x.id !== itemId) || []);
        } else if (response.errorMessages.length > 0) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        }
      })
      .finally(() => dispatch({ type: 'HIDE' }));

    return () => {
      controller.abort();
    };
  }, [itemId]);

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];

    actions.push(
      <Button
        variant="contained"
        color={color ? color : 'primary'}
        disabled={
          item === null || item?.isComplexItem !== params.row.isComplexItem
        }
        onClick={() => {
          setSelectedItemId(params.row.id);
        }}
        sx={{ padding: '0px 10px' }}
      >
        Kiválasztás
      </Button>
    );

    actions.push(
      <EntityNavigator
        entityType={EntityTypes.Item}
        entityId={params.row.id}
        value={'Megtekintés'}
        showIcon
        readonly={true}
        onSave={(result: any) => {}}
      />
    );

    return actions;
  };

  const columns: GridColDef[] = [
    {
      field: 'isComplexItem',
      headerName: 'Összetett',
      description: 'Összetett termék',
      flex: 20,
      renderCell(params) {
        return params.value ? (
          <Tooltip title={'Összetett termék!'}>
            <InventoryIcon color="primary" />
          </Tooltip>
        ) : (
          ''
        );
      },
    },
    {
      field: 'itemNumber',
      headerName: 'Cikkszám',
      description: 'Cikkszám',
      flex: 50,
    },
    {
      field: 'name',
      headerName: 'Megnevezés',
      description: 'Megnevezés',
      flex: 100,
    },
    {
      field: 'brandName',
      headerName: 'Márka',
      description: 'Márka',
      flex: 100,
    },
    {
      field: 'itemGroupName',
      headerName: 'Termékcsoport',
      description: 'Termékcsoport',
      flex: 100,
    },
    {
      minWidth: 250,
      field: 'actions',
      type: 'actions',
      align: 'right',
      getActions: getActions,
    } as GridActionsColDef,
  ];

  return (
    <Dialog open={open} onClose={() => handleClose()} fullWidth maxWidth={'lg'}>
      <DialogTitle>
        Válassza ki melyik termékkel vonjuk össze a kiválasztott terméket!
      </DialogTitle>
      <DialogContent>
        <Grid item xs={12} pt={2}>
          <Grid item xs={12} pb={2}>
            <TextField
              value={searchTerm ?? ''}
              onChange={handleSearchChange}
              label="Termék keresése"
              name="name"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} pt={2}>
          <DataList
            rows={searchItems()}
            columns={columns}
            localStorageKey={'SelectItemIdDialog'}
            getRowClassName={(params) => {
              return params.row.id === selectedItemId ? 'verified' : '';
            }}
            sx={{
              '& .MuiDataGrid-row:hover': {
                backgroundColor: 'inherit',
              },
            }}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={!(selectedItemId > 0)}
          onClick={() => {
            handleCombine(selectedItemId);
          }}
        >
          Véglegesítés
        </Button>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => handleClose()}
        >
          Mégse
        </Button>
      </DialogActions>
    </Dialog>
  );
}
