import FileOpenIcon from '@mui/icons-material/FileOpen';
import { Grid, Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
} from '@mui/x-data-grid';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import DataList from '../../components/DataList';
import useCheckPermission from '../../hooks/useCheckPermission';
import companyAssetService from '../../services/deviceManagement/companyAssetService';
import { useEffect } from 'react';
import vehicleService from '../../services/deviceManagement/vehicleService';
import { useDispatch } from 'react-redux';
import { CompanyAssetStates } from '../../types/InstrumentStates';

export default function CompanyAssetLogDialog(props: any) {
  const { open, setOpen, assetId } = props;
  const [rows, setRows] = React.useState<any[]>([]);

  const { enqueueSnackbar } = useSnackbar();
  const { checkPermission } = useCheckPermission();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();

  const handleClose = () => {
    setOpen({ open: false, assetId: null });
    setRows([]);
  };

  useEffect(() => {
    if (assetId !== 0 && assetId !== null && assetId !== undefined) {
      dispatch({ type: 'SHOW_QUERY' });
      companyAssetService
        .listLog(assetId)
        .then((response) => {
          if (response.canceled) return;
          if (!response.hasError) setRows(response.records);
          else
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
        })
        .finally(() => dispatch({ type: 'HIDE' }));
    }
  }, [assetId]);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Azonosító',
      flex: 30,
    },
    {
      field: 'warehouseName',
      headerName: 'RaktárNév',
      flex: 100,
    },
    {
      field: 'companyName',
      headerName: 'Cég',
      flex: 100,
    },
    {
      field: 'holderName',
      headerName: 'Felelős',
      flex: 100,
    },
    {
      field: 'takenAt',
      headerName: 'Átvétel dátuma',
      valueFormatter(params) {
        return new Date(params.value as Date).toLocaleDateString();
      },
      flex: 200,
    },
    {
      field: 'heldUntil',
      headerName: 'Visszaadás dátuma',
      valueFormatter(params) {
        return new Date(params.value as Date).toLocaleDateString();
      },
      flex: 200,
    },
    {
      field: 'state',
      headerName: 'Állapot',
      valueFormatter(params) {
        return params.value === CompanyAssetStates.Scrapped
          ? 'Selejtezett'
          : params.value === CompanyAssetStates.Working
          ? 'Működő'
          : params.value === CompanyAssetStates.WaitingForFix
          ? 'Javításra vár'
          : 'Javítás alatt';
      },
      flex: 200,
    },
    {
      field: 'reason',
      headerName: 'Indok',
      flex: 100,
    },
  ];

  return (
    <Grid>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'lg'}>
        <DialogTitle>Napló:</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} style={{ paddingTop: 10 }}>
            <Grid item xs={12}>
              <DataList
                rows={rows}
                columns={columns}
                getRowId={(row) => row.id}
                minimal
                hideFooterPagination
                localStorageKey={'CompanyAssetLogDialog'}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined" onClick={handleClose}>
            Mégse
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
