import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useCheckPermission from '../../../hooks/useCheckPermission';
import emailtemplateService from '../../../services/emails/emailtemplateService';
import EmailTemplateForm from './EmailTemplateForm';
import { Email } from '@mui/icons-material';
import { EmailTemplateType } from '../../../types/EmailTemplateType';
import userService from '../../../services/authority/userService';
import { DocumentTemplateType } from '../../../types/DocumentTemplateType';

const EmailTemplateCreate = (props: any) => {
  const { onSave = null, navigateBack = true, handleCancel = null } = props;
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const params = useParams();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<string[]>([]);
  const [entity, setEntity] = useState<any>({
    name: '',
    description: '',
    subject: '',
    body: '',
    type: parseInt(params.type) as EmailTemplateType,
    cc: '',
    replyTo: '',
  });

  const { checkPermission } = useCheckPermission();

  const onSubmit = (entity: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    emailtemplateService
      .create(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });

          if (onSave) onSave(response.result);
          if (navigateBack) navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
      });
  };

  return (
    <EmailTemplateForm
      entity={entity}
      readonly={!checkPermission(['EmailTemplateCreate'])}
      onSubmit={onSubmit}
      errors={errors}
      handleCancel={() =>
        handleCancel != null ? handleCancel() : navigate(-1)
      }
    />
  );
};

export default EmailTemplateCreate;
