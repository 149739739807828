import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, InputAdornment, Autocomplete } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { Currency } from '../../types/Currency';
import { currencyAdornment } from '../../utils/valueFormatters';

export default function EditItemDialog(props: any) {
  const {
    open,
    setOpen,
    onSubmit,
    getAvailableAmount,
    disableAmountCheck = false,
    externalTaxTypeId = null,
    row,
    items,
    taxes,
    canOverrideGrossPrice = false,
    currency = Currency.HUF,
  } = props;

  const [item, setItem] = React.useState({
    itemId: 0,
    itemNumber: '',
    itemName: '',
    amount: 0,
    discount: 0,
    sellPriceGross: 0,
    sellPriceNet: 0,
    taxTypeId: 0,
    comment: '',
  });

  React.useEffect(() => {
    setItem(row);
  }, [row]);

  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setItem({
      itemId: 0,
      itemNumber: '',
      itemName: '',
      amount: 0,
      discount: 0,
      sellPriceGross: 0,
      sellPriceNet: 0,
      taxTypeId: 0,
      comment: '',
    });

    setOpen(false);
  };

  const itemTextField = (param) => {
    var item = items?.find((g) => g?.itemId === param?.itemId);
    if (item == null || item === undefined) {
      return '';
    }

    return `${item.itemName} (${item.barcode}) - Elérhető: ${getAvailableAmount(
      item?.itemId
    )} db`;
  };

  const isCustomItem = item?.itemId < 0;

  if (!row) return null;
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Termék szerkeztése</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} style={{ paddingTop: 10 }}>
          {isCustomItem && (
            <Grid item xs={12}>
              <TextField
                value={item?.itemNumber}
                size="small"
                label="Cikkszám"
                fullWidth
                onChange={(event) => {
                  setItem({ ...item, itemNumber: event.target.value });
                }}
              ></TextField>
            </Grid>
          )}
          {!isCustomItem && (
            <Grid item xs={12}>
              <TextField
                disabled
                value={itemTextField(item)}
                size="small"
                label="Termék"
                fullWidth
              ></TextField>
            </Grid>
          )}
          {isCustomItem && (
            <Grid item xs={12}>
              <TextField
                value={item?.itemName}
                size="small"
                label="Termék"
                fullWidth
                onChange={(event) => {
                  setItem({ ...item, itemName: event.target.value });
                }}
              ></TextField>
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              value={item?.amount}
              onChange={(event) => {
                let amount = parseFloat(event.target.value);
                let available = getAvailableAmount(item?.itemId);
                let isNotAvailable = amount > available + row?.amount;
                if (
                  !disableAmountCheck &&
                  !isCustomItem &&
                  (amount === 0 || isNotAvailable)
                ) {
                  enqueueSnackbar(
                    'Hozzáadni kívánt mennyiség nem elérhető a kiválasztott raktárban vagy nulla!',
                    {
                      variant: 'error',
                    }
                  );
                  return;
                }
                setItem({ ...item, amount: amount });
              }}
              label="Darabszám"
              disabled={item?.itemId === 0}
              name="amount"
              size="small"
              fullWidth
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">db</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              disablePortal
              id="taxTypeId"
              disabled={item?.itemId === 0 || externalTaxTypeId !== null}
              value={item?.taxTypeId ?? ''}
              onChange={(event, value: number) => {
                setItem({
                  ...item,
                  taxTypeId: value,
                  sellPriceGross:
                    item.sellPriceNet *
                    (1 +
                      (taxes?.find((x) => x.id === value)?.value ?? 27) / 100),
                });
              }}
              getOptionLabel={(option) =>
                taxes?.find((g) => g.id === option)?.name ?? ''
              }
              options={taxes?.map((g) => g.id)}
              renderInput={(params) => (
                <TextField {...params} required fullWidth label="ÁFA típusa" />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={item?.sellPriceNet}
              onChange={(event) => {
                let sellPriceNet = parseFloat(event.target.value);
                setItem({
                  ...item,
                  sellPriceNet: sellPriceNet,
                  sellPriceGross:
                    sellPriceNet *
                    (1 +
                      (taxes?.find((x) => x.id === item.taxTypeId)?.value ??
                        27) /
                        100),
                });
              }}
              label="Nettó ár"
              disabled={!canOverrideGrossPrice && item?.itemId >= 0}
              name="sellPriceNet"
              size="small"
              InputLabelProps={{ shrink: true }}
              fullWidth
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {' '}
                    {currencyAdornment(currency)}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={item?.sellPriceGross}
              onChange={(event) => {
                let sellPriceGross = parseFloat(event.target.value);
                setItem({
                  ...item,
                  sellPriceGross: sellPriceGross,
                  sellPriceNet:
                    sellPriceGross /
                    (1 +
                      (taxes?.find((x) => x.id === item?.taxTypeId)?.value ??
                        27) /
                        100),
                });
              }}
              label="Bruttó egységár"
              disabled={
                item?.itemId === 0 ||
                (!canOverrideGrossPrice && item?.itemId > 0)
              }
              name="sellPriceGross"
              size="small"
              fullWidth
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {' '}
                    {currencyAdornment(currency)}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={item?.discount}
              onChange={(event) => {
                let discount = parseFloat(event.target.value);
                if (discount >= 0 && discount <= 100) {
                  setItem({ ...item, discount: discount });
                }
              }}
              label="Kedvezmény"
              disabled={item?.itemId === 0}
              name="discount"
              size="small"
              fullWidth
              type="number"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={item?.comment}
              onChange={(event) => {
                setItem({ ...item, comment: event.target.value });
              }}
              label="Megjegyzés"
              disabled={item?.itemId === 0}
              name="comment"
              multiline
              minRows={2}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={item?.itemId === 0 || item?.taxTypeId === null}
          onClick={() => {
            if (item?.itemId !== 0 && item.taxTypeId !== null) {
              if (!isCustomItem) {
                onSubmit({
                  ...row,
                  amount: item.amount,
                  discount: item.discount,
                  taxTypeId: item.taxTypeId,
                  sellPriceGross: item.sellPriceGross,
                  sellPriceNet: item.sellPriceNet,
                  comment: item.comment,
                });
                handleClose();
              } else {
                onSubmit({
                  ...row,
                  amount: item.amount,
                  discount: item.discount,
                  itemName: item.itemName,
                  sellPriceGross: item.sellPriceGross,
                  sellPriceNet: item.sellPriceNet,
                  itemNumber: item.itemNumber,
                  taxTypeId: item.taxTypeId,
                  comment: item.comment,
                });

                setItem({
                  itemId: 0,
                  itemName: '',
                  itemNumber: '',
                  amount: 1,
                  discount: 0,
                  sellPriceGross: 0,
                  sellPriceNet: 0,
                  taxTypeId: 0,
                  comment: '',
                });
                handleClose();
              }
            }
          }}
        >
          Szerkesztés
        </Button>
        <Button color="primary" variant="outlined" onClick={handleClose}>
          Mégse
        </Button>
      </DialogActions>
    </Dialog>
  );
}
