import { createReducer } from '@reduxjs/toolkit';
import {
  addToCart,
  clearCart,
  removeFromCart,
  replaceCartItem,
  reset,
  setCart,
  setDescription,
  setDiscount,
  setOrderId,
  setIsBooking,
  setBookings,
  setCreateInvoice,
  setPaymentType,
  setCompanyId,
  addToServices,
  removeFromServices,
  setServices,
  setClientId,
  addToItemPackages,
  removeFromItemPackages,
  setItemPackages,
  replaceItemPackage,
  setInvoiceType,
  setIsReadonly,
  setIsCopy,
  setPreviousInvoiceType,
  setOriginalCart,
  setIsElectronic,
  setDocumentBlockId,
  setInvoiceInfo,
  setAddressId,
  setPaid,
  setDueDate,
  setFulfillmentDate,
} from '../actions/salesActions';
import { loadClients } from '../thunks/clientsThunk';
import { loadInvoiceNumberPrefixes } from '../thunks/systemConfigurationsThunk';
import { InvoiceTypes } from '../../types/InvoiceTypes';
import moment from 'moment';
import { is } from 'date-fns/locale';

const initialState = {
  cart: [],
  originalCart: [],
  services: [],
  itemPackages: [],
  bookings: [],
  discount: 0,
  description: '',
  orderId: 0,
  clientId: null,
  isBooking: false,
  createInvoice: false,
  clients: [],
  invoiceNumberPrefixes: [],
  error: '',
  paymentType: 'Készpénz',
  companyId: 0,
  isReadonly: false,
  isElectronic: false,
  invoiceType: InvoiceTypes.Invoice,
  previousInvoiceType: null,
  isCopy: false,
  documentBlockId: 0,
  addressId: 0,
  invoiceInfo: {},
  paid: true,
  dueDate: new Date(),
  fulfillmentDate: new Date(),
};

export const salesReducer = createReducer(initialState, (builder) => {
  builder.addCase(setPaid, (state, action) => {
    state.paid = action.payload;
  });
  builder.addCase(setDueDate, (state, action) => {
    state.dueDate = action.payload;
  });
  builder.addCase(setFulfillmentDate, (state, action) => {
    state.fulfillmentDate = action.payload;
  });
  builder.addCase(setInvoiceInfo, (state, action) => {
    state.invoiceInfo = action.payload;
  });
  builder.addCase(setAddressId, (state, action) => {
    state.addressId = action.payload;
  });
  builder.addCase(setDocumentBlockId, (state, action) => {
    state.documentBlockId = action.payload;
  });
  builder.addCase(setClientId, (state, action) => {
    state.clientId = action.payload;
  });
  builder.addCase(setIsCopy, (state, action) => {
    state.isCopy = action.payload;
  });
  builder.addCase(setOrderId, (state, action) => {
    state.orderId = action.payload;
  });
  builder.addCase(setPaymentType, (state, action) => {
    state.paymentType = action.payload;
  });
  builder.addCase(setCompanyId, (state, action) => {
    state.companyId = action.payload;
  });
  builder.addCase(setInvoiceType, (state, action) => {
    state.invoiceType = action.payload;
  });
  builder.addCase(setIsElectronic, (state, action) => {
    state.isElectronic = action.payload;
  });
  builder.addCase(setPreviousInvoiceType, (state, action) => {
    state.previousInvoiceType = action.payload;
  });
  builder.addCase(setIsBooking, (state, action) => {
    state.isBooking = action.payload;
  });
  builder.addCase(setCreateInvoice, (state, action) => {
    state.createInvoice = action.payload;
  });
  builder.addCase(addToCart, (state, action) => {
    state.cart = [...state.cart, action.payload];
  });
  builder.addCase(addToServices, (state, action) => {
    state.services = [...state.services, action.payload];
  });
  builder.addCase(setServices, (state, action) => {
    state.services = action.payload;
  });
  builder.addCase(setIsReadonly, (state, action) => {
    state.isReadonly = action.payload;
  });
  builder.addCase(removeFromServices, (state, action) => {
    state.services = state.services.filter((x) => x.id !== action.payload);
  });
  builder.addCase(addToItemPackages, (state, action) => {
    state.itemPackages = [...state.itemPackages, action.payload];
  });
  builder.addCase(setItemPackages, (state, action) => {
    state.itemPackages = action.payload;
  });
  builder.addCase(removeFromItemPackages, (state, action) => {
    state.itemPackages = state.itemPackages.filter(
      (x) => x.id !== action.payload
    );
  });
  builder.addCase(replaceItemPackage, (state, action) => {
    let clone = [...state.itemPackages];
    let index = clone.findIndex((x) => x.id === action.payload.oldId);
    clone[index] = action.payload.itemPackage;
    state.itemPackages = clone;
  });
  builder.addCase(setCart, (state, action) => {
    state.cart = action.payload;
  });
  builder.addCase(setOriginalCart, (state, action) => {
    state.originalCart = action.payload;
  });
  builder.addCase(setBookings, (state, action) => {
    state.bookings = action.payload;
  });
  builder.addCase(removeFromCart, (state, action) => {
    state.cart = state.cart.filter((x) => x.itemId !== action.payload);
  });
  builder.addCase(replaceCartItem, (state, action) => {
    let clone = [...state.cart];
    let index = clone.findIndex((x) => x.itemId === action.payload.oldId);
    clone[index] = action.payload.cartItem;
    state.cart = clone;
  });
  builder.addCase(clearCart, (state, action) => {
    state.cart = [];
  });
  builder.addCase(setDescription, (state, action) => {
    state.description = action.payload;
  });
  builder.addCase(setDiscount, (state, action) => {
    state.discount = action.payload;
  });
  builder.addCase(reset, (state, action) => {
    state.discount = 0;
    state.description = '';
    state.orderId = 0;
    state.isBooking = false;
    state.createInvoice = false;
    state.cart = [];
    state.originalCart = [];
    state.services = [];
    state.bookings = [];
    state.paymentType = 'Készpénz';
    // state.companyId = 0;
    state.clientId = 0;
    state.itemPackages = [];
    state.invoiceType = InvoiceTypes.Invoice;
    state.isElectronic = false;
    state.isReadonly = false;
    state.isCopy = false;
    state.previousInvoiceType = null;
    state.addressId = 0;
    state.invoiceInfo = {};
    state.paid = true;
    state.dueDate = new Date();
    state.fulfillmentDate = new Date();
  });
  builder.addCase(loadClients.fulfilled, (state, action) => {
    if (action.payload?.hasError)
      state.error = action.payload.errorMessages.join('|');
    else state.clients = action.payload?.records ?? [];
  });
  builder.addCase(loadInvoiceNumberPrefixes.fulfilled, (state, action) => {
    if (action.payload?.hasError) {
      state.error = action.payload.errorMessages.join('|');
    } else {
      state.invoiceNumberPrefixes =
        (action.payload?.result.invoicePrefixes as string)?.split(';') ?? [];
    }
  });
});
