import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Grid, IconButton, Paper, Tooltip } from '@mui/material';
import {
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
} from '@mui/x-data-grid';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DataList from '../../../../../components/DataList';
import useCheckPermission from '../../../../../hooks/useCheckPermission';
import useConfirmDeleteDialog from '../../../../../hooks/useConfirmDeleteDialog';
import useFunctionDescriptor from '../../../../../hooks/useFunctionDescriptor';
import roleService from '../../../../../services/authority/roleService';
import workItemAssignmentService from '../../../../../services/erp/workItemAssignmentService';
import workItemService from '../../../../../services/erp/workItemService';
import { translateRoleName } from '../../../../../utils/nameFormatters';
import { WorkItemStatus } from '../../WorkItemForm';
import WorkItemAssignmentDialog from './WorkItemAssignmentDialog';

export interface WorkItemAssignment {
  id: string;
  userId: string;
  roleId: string;
  order: string;
  workItemAssignmentStatus: WorkItemAssignmentStatus;
}

export enum WorkItemAssignmentStatus {
  Draft = -1,
  Assigned = 0,
  InProgress = 1,
  Completed = 2,
  Rejected = 3,
}

const WorkItemAssignmentStep = (props: any) => {
  const navigate = useNavigate();
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const titleDescriptor = useFunctionDescriptor('WorkItemAssignmentStep.title');
  const [assignmentDialogOpen, setAssignmentDialogOpen] = React.useState(false);
  const [roles, setRoles] = React.useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [helperIds, setHelperIds] = useState<any[]>([]);
  const [completed, setCompleted] = useState<boolean>(false);
  const [resourcePlannerDialogState, setResourcePlannerDialogState] =
    useState<any>({ open: false, workItemId: 0 });
  const { setFieldValue, employees, values, taskTypes, loadWorkItem } = props;

  const handleDialogOpen = () => {
    workItemService.update({ ...values }).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setAssignmentDialogOpen(true);
      }
    });
  };

  const { checkPermission } = useCheckPermission();

  const readyForAssignment = () => {
    return (
      values?.clientId > 0 &&
      values?.billingAddressId > 0 &&
      values?.workAddressId > 0 &&
      values?.taskTypeId > 0 &&
      values?.priceCategoryId > 0
    );
  };

  useEffect(() => {
    workItemAssignmentService.list(values.id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setRows(response.records);
        if (
          response.records.some(
            (x) =>
              x.roleName === 'Mechanic' &&
              x.workItemAssignmentStatus === WorkItemAssignmentStatus.Completed
          )
        ) {
          setCompleted(true);
        }
      }
    });
  }, [values.id]);

  useEffect(() => {
    roleService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setRoles(response.records);
      }
    });
  }, []);

  const handleDeleteWorkItemAssignment = (row: any) => {
    workItemAssignmentService.delete(row.id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        workItemAssignmentService.list(values.id).then((response) => {
          if (response.canceled) return;
          if (response.hasError) {
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
          } else {
            setRows(response.records);
            loadWorkItem();
          }
        });
      }
    });
  };

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];

    if (checkPermission(['WorkItemAssignmentDelete'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Törlés">
              <DeleteIcon />
            </Tooltip>
          }
          label="Törlés"
          disabled={
            params.row.workItemAssignmentStatus ===
            WorkItemAssignmentStatus.Completed
          }
          onClick={() => {
            setParams({
              open: true,
              name: 'Biztosan törli a kiosztást?',
              onConfirm: async () => handleDeleteWorkItemAssignment(params.row),
            });
          }}
        />
      );
    }

    return actions;
  };

  const columns: GridColDef[] = [
    {
      field: 'userId',
      headerName: 'Dolgozó',
      valueFormatter(params) {
        var employees = props.employees.find((g) => g.id === params.value);
        if (employees == null || employees === undefined) {
          return '';
        }

        return `${employees?.fullName} (${employees?.email ?? ''})`;
      },
      flex: 200,
    },
    {
      field: 'roleId',
      headerName: 'Munkakör',
      flex: 200,
      valueFormatter: (params) => {
        return translateRoleName(
          roles.find((g) => g.id === params.value)?.name ?? ''
        );
      },
    },
    {
      field: 'order',
      headerName: 'Sorszám',
      flex: 100,
    },
    {
      field: 'workItemAssignmentStatus',
      headerName: 'Státusz',
      flex: 100,
      renderCell: (params) => {
        return (
          <div
            style={{
              color:
                params.value === WorkItemAssignmentStatus.InProgress
                  ? 'orange'
                  : params.value === WorkItemAssignmentStatus.Completed
                  ? 'green'
                  : params.value === WorkItemAssignmentStatus.Rejected
                  ? 'red'
                  : params.value === WorkItemAssignmentStatus.Assigned
                  ? 'blue'
                  : params.value === WorkItemAssignmentStatus.Draft
                  ? 'yellowgreen'
                  : '',
            }}
          >
            {params.value === WorkItemAssignmentStatus.InProgress
              ? 'Folyamatban'
              : params.value === WorkItemAssignmentStatus.Completed
              ? 'Kész'
              : params.value === WorkItemAssignmentStatus.Rejected
              ? 'Elutasítva'
              : params.value === WorkItemAssignmentStatus.Assigned
              ? 'Hozzárendelve'
              : params.value === WorkItemAssignmentStatus.Draft
              ? 'Piszkozat'
              : ''}
          </div>
        );
      },
    },
    {
      field: 'completedAt',
      headerName: 'Teljesítés dátuma',
      valueFormatter: (params) => {
        if (new Date(params.value).getFullYear() < 2000) {
          return 'Nincs';
        }
        return new Date(params.value).toLocaleString();
      },
      flex: 100,
    },
    {
      field: 'actions',
      type: 'actions',
      align: 'right',
      flex: 100,
      getActions: getActions,
    } as GridActionsColDef,
  ];

  return (
    <Paper style={{ width: '100%' }}>
      <Grid container>
        <Grid item xs={12}>
          <h2>Munkakörök kiosztása{titleDescriptor}</h2>
        </Grid>
        {!readyForAssignment() && (
          <Grid item xs={12}>
            <div style={{ color: 'red' }}>
              A munkalap még nem áll készen a hozzárendeléshez!
            </div>
          </Grid>
        )}

        {readyForAssignment() &&
          checkPermission(['WorkItemAssignmentCreate']) &&
          values.workItemStatus !== WorkItemStatus.Closed &&
          values.workItemStatus !== WorkItemStatus.Evaluated &&
          (rows.length === 0 ||
            rows[rows.length - 1]?.workItemAssignmentStatus ===
              WorkItemAssignmentStatus.Completed ||
            roles?.find((x) => x.id === rows[rows.length - 1]?.roleId)?.name ===
              'Warehouseman' ||
            rows[rows.length - 1]?.workItemAssignmentStatus ===
              WorkItemAssignmentStatus.Rejected) && (
            <Grid container xs={12} justifyContent="end">
              <Grid item>
                <Tooltip title="Új kiosztás">
                  <IconButton onClick={handleDialogOpen} color="primary">
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          )}
        <Grid item xs={12}>
          <DataList
            rows={rows}
            columns={columns}
            getRowId={(row) => `${row.id}`}
            localStorageKey={'WorkItemAssignmentStep'}
          />
        </Grid>
        <ConfirmDeleteDialog />
      </Grid>
      <WorkItemAssignmentDialog
        employees={employees}
        roles={roles}
        worItemId={values.id}
        taskTypeAbilities={
          taskTypes.find((x) => x.id === values.taskTypeId)?.abilities ?? []
        }
        isCompleted={completed}
        workItemAssignments={rows}
        setHelperIds={setHelperIds}
        helperIds={helperIds}
        open={assignmentDialogOpen}
        setOpen={setAssignmentDialogOpen}
        addToTable={(assignment, resourcePlanner) => {
          workItemAssignmentService
            .create({ ...assignment, workItemId: values.id, helperIds })
            .then((response) => {
              if (response.canceled) return;
              if (response.hasError) {
                enqueueSnackbar(response.errorMessages.join(','), {
                  variant: 'error',
                });
              } else {
                setFieldValue('assignedToId', resourcePlanner?.employeeId);
                setRows([...rows, response.result]);
                if (resourcePlanner?.employeeId > 0) {
                  workItemService
                    .delegate({
                      assignedToId: resourcePlanner?.employeeId,
                      roleId: assignment.roleId,
                      timeWindowStart: resourcePlanner.startDate,
                      id: values.id,
                      timeWindowEnd: resourcePlanner.endDate,
                      helperIds: helperIds,
                    })
                    .then((response2) => {
                      if (response2.hasError) {
                        enqueueSnackbar(response2.errorMessages.join(','), {
                          variant: 'error',
                        });
                      } else {
                        enqueueSnackbar('Sikeres delegálás', {
                          variant: 'success',
                        });
                        workItemAssignmentService
                          .list(values.id)
                          .then((response) => {
                            if (response.canceled) return;
                            if (response.hasError) {
                              enqueueSnackbar(
                                response.errorMessages.join(','),
                                {
                                  variant: 'error',
                                }
                              );
                            } else {
                              setRows(response.records);
                              setHelperIds([]);
                            }
                          });
                      }
                    });
                }
              }
            });
          setAssignmentDialogOpen(false);
        }}
      />
    </Paper>
  );
};

export default WorkItemAssignmentStep;
