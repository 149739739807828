import SelectServices from '../../../services/SelectServices';
import { useEffect } from 'react';
import { setForm } from '../../../../../stateManagement/actions/workItemActions';
import { useDispatch } from 'react-redux';

const SelectServicesStep = (props: any) => {
  const { values, setFieldValue, services, readonly, taxes } = props;

  const dispatch = useDispatch<any>();
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(setForm(values));
    }, 200);
    return () => clearTimeout(timeoutId);
  }, [values]);

  return (
    <SelectServices
      values={values}
      taxes={taxes}
      setFieldValue={setFieldValue}
      services={services}
      readOnly={readonly || values.orderId > 0}
    />
  );
};

export default SelectServicesStep;
