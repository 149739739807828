import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Visibility,
} from '@mui/icons-material';
import HistoryIcon from '@mui/icons-material/History';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
} from '@mui/x-data-grid';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { parseJSON, set } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import DataList from '../../../components/DataList';
import useCheckPermission from '../../../hooks/useCheckPermission';
import useConfirmDeleteDialog from '../../../hooks/useConfirmDeleteDialog';
import useFunctionDescriptor from '../../../hooks/useFunctionDescriptor';
import taskService from '../../../services/erp/taskService';
import TaskLogDialog from '../tasks/TaskLogDialog';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import TaskForm from '../tasks/TaskForm';

const ProjectTasks = (props: any) => {
  const {
    tasks,
    onTaskChange,
    employees,
    users,
    projectId,
    readonly,
    projectWorkHourSum,
  } = props;

  const navigate = useNavigate();
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const [rows, setRows] = useState<any[]>([]);
  const [filteredRows, setFilteredRows] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const titleDescriptor = useFunctionDescriptor('TaskPage.title');
  const [checkboxValue, setCheckboxValue] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);

  const [taskDialog, setTaskDialog] = useState<any>({
    open: false,
    entity: {
      id: 0,
      date: new Date(),
      description: '',
      employeeId: '',
      employeeName: '',
      isCompleted: false,
      projectId: projectId,
    },
  });

  const [taskLogDialog, setTaskLogDialog] = useState<any>({
    open: false,
    taskId: 0,
  });

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    if (tasks && tasks?.length > 0) {
      setRows(tasks);
    }
  }, [tasks]);

  useEffect(() => {
    if (projectId && projectId > 0) {
      setTaskDialog({
        ...taskDialog,
        entity: {
          ...taskDialog.entity,
          projectId: projectId,
        },
      });
    }
  }, [projectId]);

  useEffect(() => {
    if (rows) {
      setFilteredRows(
        rows?.filter((row) => row.isCompleted === checkboxValue) ?? []
      );
    }
  }, [checkboxValue, rows]);

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];
    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          <Tooltip title="Napló megtekintése">
            <HistoryIcon />
          </Tooltip>
        }
        label="Napló megtekintése"
        onClick={() =>
          setTaskLogDialog({
            open: true,
            taskId: params.row.id,
          })
        }
      />
    );

    let readonly = !checkPermission(['TaskEdit']) || props.readonly;
    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          readonly ? (
            <Tooltip title="Megtekintés">
              <Visibility />
            </Tooltip>
          ) : (
            <Tooltip title="Szerkesztés">
              <EditIcon />
            </Tooltip>
          )
        }
        label={readonly ? 'Megtekintés' : 'Szerkesztés'}
        onClick={() => navigate(`/erp/tasks/edit/${params.row.id}`)}
      />
    );

    if (checkPermission(['TaskDelete']) && !readonly) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Törlés">
              <DeleteIcon />
            </Tooltip>
          }
          label="Törlés"
          onClick={() =>
            setParams({
              open: true,
              name: '',
              onConfirm: async () => handleDelete(params.row.id),
            })
          }
        />
      );
    }

    return actions;
  };

  const columns: GridColDef[] = [
    {
      field: 'employeeName',
      headerName: 'Dolgozó',
      flex: 100,
    },
    {
      field: 'date',
      headerName: 'Dátum',
      flex: 100,
      valueFormatter: (params) => {
        return parseJSON(params.value).toLocaleDateString();
      },
    },

    { field: 'description', headerName: 'Leírás', flex: 200 },
    { field: 'workHourSum', headerName: 'Összes munkaóra', flex: 100 },
    {
      field: 'isCompleted',
      headerName: 'Státusz',
      flex: 200,
      renderCell: (params) => {
        return (
          <span
            style={{
              color: params.value ? 'green' : 'orange',
              fontWeight: 'bold',
            }}
          >
            {params.value ? 'Kész' : 'Folyamatban'}
          </span>
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 150,
      align: 'right',
      getActions: getActions,
    } as GridActionsColDef,
  ];

  const handleDelete = (id: number) => {
    taskService.delete(id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres törlés', {
          variant: 'success',
        });
        setRows(rows?.filter((row) => row.id !== id));
      }
    });
  };
  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>Feladatok{titleDescriptor}</h2>
        </Grid>
        <Grid container item p={2}>
          <Grid item xs={12} md={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkboxValue}
                  onChange={(e, checked) => setCheckboxValue(checked)}
                />
              }
              label="Teljesített feladatok"
            />
          </Grid>
          {checkPermission(['TaskCreate']) && !readonly && (
            <Grid item xs={12} textAlign={'right'}>
              <Tooltip title="Feladat létrehozása">
                <IconButton
                  color="primary"
                  onClick={() =>
                    setTaskDialog({
                      open: true,
                      entity: {
                        date: new Date(),
                        description: '',
                        employeeId: '',
                        employeeName: '',
                        isCompleted: false,
                      },
                    })
                  }
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom component="div">
            Összesen: {projectWorkHourSum} óra
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <DataList
            rows={filteredRows}
            columns={columns}
            getRowId={(row) => row.id}
            localStorageKey={'ProjectTasks'}
          />
        </Grid>
        <ConfirmDeleteDialog />
        <TaskLogDialog
          open={taskLogDialog.open}
          setOpen={setTaskLogDialog}
          taskId={taskLogDialog.taskId}
        />
        <Dialog
          open={taskDialog.open}
          onClose={() => {
            setTaskDialog({
              open: false,
              entity: {
                date: new Date(),
                description: '',
                employeeId: '',
                employeeName: '',
                isCompleted: false,
              },
            });
          }}
          maxWidth="lg"
          fullWidth
        >
          <DialogContent sx={{ p: 0 }}>
            <TaskForm
              entity={taskDialog.entity}
              readonly={
                !checkPermission(['TaskEdit']) ||
                !checkPermission(['TaskCreate']) ||
                taskDialog.entity?.isCompleted ||
                readonly
              }
              onSubmit={(result: any) => {
                if (result.id && result.id > 0) {
                  taskService.update(result).then((response) => {
                    if (response.canceled) return;
                    if (!response.hasError) {
                      onTaskChange(
                        tasks?.map((task) => {
                          if (task.id === response.result.id) {
                            return response.result;
                          }
                          return task;
                        })
                      );

                      enqueueSnackbar('Sikeres mentés', {
                        variant: 'success',
                      });
                    } else {
                      setErrors(response.errorMessages);

                      enqueueSnackbar(response.errorMessages.join(','), {
                        variant: 'error',
                      });
                    }
                  });
                } else {
                  taskService
                    .create({ ...result, projectId: projectId })
                    .then((response) => {
                      if (response.canceled) return;
                      if (!response.hasError) {
                        onTaskChange([...tasks, response.result]);

                        enqueueSnackbar('Sikeres mentés', {
                          variant: 'success',
                        });
                      } else {
                        setErrors(response.errorMessages);
                        enqueueSnackbar(response.errorMessages.join(','), {
                          variant: 'error',
                        });
                      }
                    });
                }
                setTaskDialog({ open: false, entity: result });
              }}
              handleCancel={() =>
                setTaskDialog({
                  open: false,
                  entity: {
                    date: new Date(),
                    description: '',
                    employeeId: '',
                    employeeName: '',
                    isCompleted: false,
                  },
                })
              }
              errors={errors}
              employees={users?.filter((user) => employees?.includes(user.id))}
            />
          </DialogContent>
        </Dialog>
      </Grid>
    </Paper>
  );
};

export default ProjectTasks;
