import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { GridColDef } from '@mui/x-data-grid';
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DataList from '../../../components/DataList';
import useCheckPermission from '../../../hooks/useCheckPermission';
import { useSnackbar } from 'notistack';
import { formatCurrency } from '../../../utils/valueFormatters';

export default function VehicleServiceDialog(props: any) {
  const { open, setOpen, services } = props;
  const [rows, setRows] = React.useState<any[]>([]);

  const { enqueueSnackbar } = useSnackbar();
  const { checkPermission } = useCheckPermission();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();

  const handleClose = () => {
    setOpen({ open: false, services: [] });
    setRows([]);
  };

  useEffect(() => {
    if (services) {
      setRows(services);
    }
  }, [services]);

  const columns: GridColDef[] = [
    {
      field: 'serviceCompanyName',
      headerName: 'Szerviz',
      flex: 200,
    },
    {
      field: 'startDate',
      headerName: 'Bevitel dátuma',
      valueFormatter(params) {
        return new Date(params.value as Date).toLocaleDateString();
      },
      flex: 100,
    },
    {
      field: 'endDate',
      headerName: 'Kihozatal dátuma',
      valueFormatter(params) {
        if (!params.value) {
          return 'Nincs';
        }
        return new Date(params.value as Date).toLocaleDateString();
      },
      flex: 100,
    },
    {
      field: 'wasOilChanged',
      headerName: 'Olajcsere',
      valueFormatter(params) {
        return params.value ? 'Igen' : 'Nem';
      },
      flex: 100,
    },
    {
      field: 'milage',
      headerName: 'Km óra állás',
      flex: 100,
    },
    {
      field: 'netPrice',
      headerName: 'Nettó ár',
      valueFormatter(params) {
        return formatCurrency(params.value as number);
      },
      flex: 100,
    },
    {
      field: 'description',
      headerName: 'Megjegyzés',
      flex: 100,
    },
  ];

  return (
    <Grid>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'lg'}>
        <DialogTitle>Szerviz napló:</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} style={{ paddingTop: 10 }}>
            <Grid item xs={12}>
              <DataList
                rows={rows}
                columns={columns}
                getRowId={(row) => row.id}
                minimal
                hideFooterPagination
                localStorageKey={'VehicleServiceDialog'}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined" onClick={handleClose}>
            Mégse
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
