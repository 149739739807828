import axios from "axios";
import { postData } from "../axiosUtils";

const closingSheetService = {
  list: (userId: any) => {
    if (!userId) {
      userId = 0;
    }
    return axios.get(`/api/closingSheet/list/${userId}`).then((response) => {
      return response.data;
    });
  },
  listmy: () => {
    return axios.get(`/api/closingSheet/listmy`).then((response) => {
      return response.data;
    });
  },
  get: (id: any) => {
    return axios.get(`/api/closingSheet/details/${id}`).then((response) => {
      return response.data;
    });
  },
  getToday: () => {
    return axios.get(`/api/closingSheet/getToday`).then((response) => {
      return response.data;
    });
  },
  create: () => {
    return postData({
      url: `/api/closingSheet/create`,
    }).then((response) => {
      return response;
    });
  },
  update: (entity: any) => {
    return postData({
      url: `/api/closingSheet/update`,
      data: entity,
      method: "PUT",
    }).then((response) => {
      return response;
    });
  },
  close: (entity: any) => {
    return postData({
      url: `/api/closingSheet/close`,
      data: entity,
      method: "PUT",
    }).then((response) => {
      return response;
    });
  },
  delete: (id: any) => {
    return postData({
      url: `/api/closingSheet/delete/${id}`,
      method: "DELETE",
    }).then((response) => {
      return response;
    });
  },
};

export default closingSheetService;
