import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { menuCategories } from '../../layout/Navigator';
import useCheckPermission from '../../hooks/useCheckPermission';
import { NavLink } from 'react-router-dom';
import { Settings, Visibility, VisibilityOff } from '@mui/icons-material';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import {
  Box,
  Button,
  IconButton,
  IconButtonProps,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/system';
import EmployeeRankingPage from '../statistics/EmployeeRankingPage';
import { config } from 'process';
import StarIcon from '@mui/icons-material/Star';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import HomePage from './HomePage';

interface StyledIconButtonProps extends IconButtonProps {
  component?: any;
  href?: any;
  to?: any;
  replace?: any;
}

const Tile = styled(IconButton)<StyledIconButtonProps>(({ theme }) => ({
  '&:hover': {
    transitionDuration: '5s',
    transform: 'scale(1.1)',
    color: theme.palette.primary.light,
  },
}));

const VisibilityIcon = styled(Visibility)(({ theme }) => ({
  fontSize: '5vw !important',
  color: 'green',
}));

const VisibilityOffIcon = styled(VisibilityOff)(({ theme }) => ({
  fontSize: '5vw !important',
  color: 'red',
}));

const Dashboard = () => {
  const { checkPermission } = useCheckPermission();
  const [configure, setConfigure] = useState(false);
  const [disabledMenuItems, setDisabledMenuItems] = useState<string[]>([]);
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const isMediumScreen = useMediaQuery('(max-width:1200px)');
  const isSmallMediumScreen = useMediaQuery('(max-width:900px)');
  const user = useSelector((state: any) => state.user.userInfo);
  const defaultId = useLocalStorage('defaultId');
  const defaultPath = useLocalStorage('defaultPath');

  useEffect(() => {
    if (disabledMenuItems.length === 0) {
      return;
    }

    // save settings to localstorage
    window.localStorage.setItem(
      'dashboard.disabledMenuItems.' + user.email,
      JSON.stringify(disabledMenuItems)
    );
  }, [disabledMenuItems, user]);

  useEffect(() => {
    // save settings to localstorage
    const setting = window.localStorage.getItem(
      'dashboard.disabledMenuItems.' + user.email
    );
    if (setting) {
      setDisabledMenuItems(JSON.parse(setting));
    }
  }, [user]);

  return (
    <Grid container spacing={2} alignItems="center" alignContent="center">
      <Grid item xs={12}>
        {!configure && <HomePage path={defaultPath.value} />}
      </Grid>
      <Grid item container xs={12} mt={1} justifyContent="end">
        <Grid item>
          {configure && (
            <>
              <Button variant="contained" onClick={() => setConfigure(false)}>
                Mentés
              </Button>{' '}
              <Button
                variant="outlined"
                onClick={() => {
                  setDisabledMenuItems([]);
                  window.localStorage.removeItem(
                    'dashboard.disabledMenuItems.' + user.email
                  );
                  setConfigure(false);
                }}
              >
                Alaphelyzet
              </Button>
            </>
          )}

          {!configure && (
            <Tooltip title={'Menüpontok konfigurálása'}>
              <IconButton onClick={() => setConfigure(true)}>
                <Settings />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
      </Grid>
      {menuCategories.map((menuCategory) =>
        menuCategory.children.map((child) => {
          let Icon = child.icon.type;
          if (!checkPermission(child.requiredPermissions)) {
            return null;
          }

          if (!configure && disabledMenuItems.includes(child.id)) {
            return null;
          }

          return (
            <Grid item key={child.id} xs={12} sm={6} lg={3} md={4}>
              <Paper
                sx={{
                  margin: 'auto',
                  overflow: 'hidden',
                  textAlign: 'center',
                  padding: '16px',
                }}
              >
                {child.useHref ? (
                  <Tile
                    component="a"
                    href={child.to}
                    disabled={configure}
                    sx={{
                      fontSize: '2rem',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    <Icon
                      sx={{
                        fontSize: '18vw !important',
                        color: '#59C3E6!important',
                      }}
                    />
                  </Tile>
                ) : (
                  <Tile
                    component={NavLink}
                    to={child.to}
                    disabled={configure}
                    replace
                    sx={{
                      fontSize: '2rem',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    <Icon
                      sx={{
                        fontSize: '18vw !important',
                        color: '#59C3E6!important',
                      }}
                    />
                  </Tile>
                )}
                {configure && (
                  <IconButton
                    style={{
                      position: 'absolute',
                      marginLeft: isSmallScreen
                        ? '-80%'
                        : isSmallMediumScreen
                        ? '-45%'
                        : isMediumScreen
                        ? '-60%'
                        : '-19%',
                      marginTop: '0%',
                      fontSize: '18vw !important',
                      zIndex: '10',
                    }}
                    onClick={() => {
                      const disabled = disabledMenuItems.includes(child.id);
                      if (disabled)
                        setDisabledMenuItems(
                          disabledMenuItems.filter((x) => child.id !== x)
                        );
                      else
                        setDisabledMenuItems([...disabledMenuItems, child.id]);
                    }}
                  >
                    {!disabledMenuItems.includes(child.id) ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                )}
                <Tooltip title={'Beállítás kezdőlapként'}>
                  <IconButton
                    style={{
                      position: 'absolute',
                      marginLeft: isSmallScreen
                        ? '-19%'
                        : isMediumScreen
                        ? '-5%'
                        : '-2%',
                      marginTop: '0%',
                      fontSize: '18vw !important',
                      zIndex: '10',
                    }}
                    onClick={() => {
                      if (defaultId.value === child.id) {
                        defaultId.setLocalStorageValue('defaultId');
                        defaultPath.setLocalStorageValue('defaultPath');
                      } else {
                        defaultId.setLocalStorageValue(child.id);
                        defaultPath.setLocalStorageValue(child.to);
                      }
                    }}
                  >
                    {defaultId.value === child.id ? (
                      <StarIcon color="warning" />
                    ) : (
                      <StarBorderIcon />
                    )}
                  </IconButton>
                </Tooltip>
                <Box style={{ height: '50px' }}>
                  <Typography
                    textAlign="center"
                    style={{
                      userSelect: 'none',
                    }}
                  >
                    {child.id}
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          );
        })
      )}
    </Grid>
  );
};

export default Dashboard;
