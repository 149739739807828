import { PropertyTypes } from "../../types/PropertyTypes";

export const localizePropertyType = (type: PropertyTypes) => {
  switch (type) {
    case PropertyTypes.Text:
      return "Szöveges";
    case PropertyTypes.Number:
      return "Szám";
    case PropertyTypes.Date:
      return "Dátum";
    case PropertyTypes.Boolean:
      return "Logikai";
    case PropertyTypes.Enum:
      return "Enum";
  }
};
