import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
} from '@mui/material';
import Button from '@mui/material/Button';
import { GridActionsColDef, GridColDef } from '@mui/x-data-grid';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import DataList from '../../../components/DataList';
import EntityNavigator from '../../../components/EntityNavigator';
import '../../../css/dataGrid.css';
import { EntityTypes } from '../../../types/EntityTypes';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DangerousIcon from '@mui/icons-material/Dangerous';
import clientService from '../../../services/crm/clientService';

export default function CombineClientDialog(props: any) {
  const {
    open,
    setOpen,
    clients,
    onCombined = () => {},
    clientToCombine,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const [clientToDeleteId, setClientToDeleteId] = useState<any>(null);

  const handleClose = () => {
    setOpen(false);
    setClientToDeleteId(null);
  };

  const combineClients = () => {
    dispatch({ type: 'SHOW_SAVE' });
    clientService
      .combine({
        clientToCombineId: clientToCombine.id,
        clientToDeleteId: clientToDeleteId,
      })
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) {
          enqueueSnackbar('Számla kiállítva', {
            variant: 'success',
          });
          onCombined();
          handleClose();
        } else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  };

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];

    actions.push(
      <Button
        variant="contained"
        color={color ? color : 'primary'}
        onClick={() => {
          if (clientToDeleteId === params.row.id) {
            setClientToDeleteId(null);
          } else {
            setClientToDeleteId(params.row.id);
          }
        }}
        sx={{ padding: '0px 10px', borderRadius: '8px' }}
      >
        Kiválasztás
      </Button>
    );

    return actions;
  };

  const columns: GridColDef[] = [
    {
      field: 'isTaxNumberInvalid',
      headerName: 'Adószám érvényesség',
      flex: 20,
      renderCell(params) {
        return params.row.isTaxNumberInvalid ? (
          <Tooltip title="Érvénytelen adószám">
            <IconButton color="error">
              <DangerousIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Érvényes adószám">
            <IconButton color="success">
              <CheckCircleIcon />
            </IconButton>
          </Tooltip>
        );
      },
    },
    { field: 'id', headerName: 'ID', flex: 100 },
    {
      field: 'fullName',
      headerName: 'Ügyfél',
      flex: 100,
      editable: false,
      valueGetter: (params: any) => {
        return `${params.row.clientName ?? ''}`;
      },
      renderCell(params) {
        return (
          <EntityNavigator
            entityType={EntityTypes.Client}
            entityId={params.row.id}
            value={
              params.row.isPrivatePerson
                ? `${params.row.name.fullName}`
                : params.row.companyName
            }
          />
        );
      },
    },
    {
      field: 'taxNumber',
      headerName: 'Adószám',
      flex: 100,
      editable: false,
      valueGetter: (params: any) => {
        return `${params.row.taxNumber ?? ''}`;
      },
    },
    {
      field: 'contact.phone',
      headerName: 'Telefonszám',
      flex: 100,
      editable: false,
      valueGetter: (params: any) => {
        return `${params.row.contact.phone ?? ''}`;
      },
    },
    {
      field: 'contact.email',
      headerName: 'Email ',
      flex: 100,
      editable: false,
      valueGetter: (params: any) => {
        return `${params.row.contact.email ?? ''}`;
      },
    },
    {
      field: 'comment',
      headerName: 'Megjegyzés ',
      flex: 100,
      editable: false,
    },
    {
      field: 'addressesForQuickSearch',
      headerName: 'Címek',
      flex: 100,
      valueGetter: (params: any) => {
        let addresses = '';
        params.row.addresses?.forEach((address: any) => {
          addresses += address.fullAddress + ';';
        });
        return addresses;
      },
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 100,
      getActions: getActions,
    } as GridActionsColDef,
  ];

  return (
    <>
      <Dialog open={open} onClose={() => handleClose()} fullWidth maxWidth="xl">
        <DialogTitle>Tanúsítványok kiválasztása</DialogTitle>
        <DialogContent>
          <Grid item xs={12}>
            <Grid item xs={12} pt={2}>
              <DataList
                rows={
                  clients.filter(
                    (x) =>
                      x.isPrivatePerson === clientToCombine?.isPrivatePerson
                  ) ?? []
                }
                columns={columns}
                getRowId={(row) => `${row.id}-${row.uniqueId}`}
                minimal
                localStorageKey={'CombineClientDialog'}
                getRowClassName={(params) => {
                  return clientToDeleteId === params.row.id ? 'verified' : '';
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disabled={!(clientToDeleteId > 0)}
            onClick={() => {
              combineClients();
            }}
          >
            Összevonás
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => handleClose()}
          >
            Mégse
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
