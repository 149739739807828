import {
  Grid,
  Button,
  IconButton,
  SpeedDial,
  Typography,
  TextField,
  InputAdornment,
} from '@mui/material';
import { memo, useState } from 'react';
import { Add, Delete } from '@mui/icons-material';
import useCheckPermission from '../../../hooks/useCheckPermission';
import {
  formatCurrency,
  currencyAdornment,
} from '../../../utils/valueFormatters';
import SelectServiceDialog from '../../sales/SelectServiceDialog';
import { FailedToNegotiateWithServerError } from '@microsoft/signalr/dist/esm/Errors';

const SelectServices = (props: any) => {
  const { values, setFieldValue, services, readOnly, isOffer, taxes } = props;

  const [addServiceDialog, setAddServiceDialog] = useState(false);
  const { checkPermission } = useCheckPermission();
  return (
    <Grid container justifyContent="right">
      <Grid
        item
        sx={{ display: { xs: isOffer ? 'flex' : 'none', md: 'flex' } }}
      >
        <Button
          disabled={readOnly}
          variant="outlined"
          onClick={() => setAddServiceDialog(true)}
        >
          Hozzáadás
        </Button>
      </Grid>
      {values?.selectedServices?.length === 0 && (
        <Grid item container xs={12}>
          Nem igényel szolgáltatásokat
        </Grid>
      )}
      <Grid item container xs={12}>
        {values.selectedServices?.map((x, i) => {
          return (
            <Grid
              item
              container
              spacing={2}
              pt={1}
              key={x.id}
              alignItems="center"
              textAlign={'center'}
              style={{ borderBottom: '1px solid' }}
            >
              <Grid item xs={12} md={2}>
                <TextField
                  value={x.name}
                  variant="standard"
                  label="Szolgáltatás megnevezése"
                  type="text"
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={5} container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    value={x.servicePriceNet}
                    variant="standard"
                    label="Nettó egységár"
                    onChange={(e) => {
                      var value = parseFloat(e.target.value);
                      setFieldValue(
                        `selectedServices[${i}].servicePriceNet`,
                        value
                      );
                      var net =
                        value *
                        (1 +
                          taxes.find((y) => y.id === x.taxTypeId)?.value / 100);
                      setFieldValue(
                        `selectedServices[${i}].servicePriceGross`,
                        net
                      );
                    }}
                    type="number"
                    fullWidth
                    disabled={
                      readOnly ||
                      (isOffer
                        ? false
                        : !checkPermission(['WorkItemCustomPrice']))
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {currencyAdornment(values.currency)}
                        </InputAdornment>
                      ),
                    }}
                    sx={{ input: { textAlign: 'right' } }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={x.servicePriceGross}
                    variant="standard"
                    onChange={(e) => {
                      var value = parseFloat(e.target.value);
                      setFieldValue(
                        `selectedServices[${i}].servicePriceGross`,
                        value
                      );
                      var net =
                        value /
                        (1 +
                          taxes.find((y) => y.id === x.taxTypeId)?.value / 100);
                      setFieldValue(
                        `selectedServices[${i}].servicePriceNet`,
                        net
                      );
                    }}
                    label="Bruttó egységár"
                    type="number"
                    name="grossPrice"
                    disabled={
                      readOnly ||
                      (isOffer
                        ? false
                        : !checkPermission(['WorkItemCustomPrice']))
                    }
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {' '}
                          {currencyAdornment(values.currency)}
                        </InputAdornment>
                      ),
                    }}
                    sx={{ input: { textAlign: 'right' } }}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                container
                justifyContent="center"
                spacing={2}
              >
                <Grid item xs={3}>
                  <TextField
                    value={x.amount}
                    variant="standard"
                    onChange={(e) => {
                      var value = parseFloat(e.target.value);
                      setFieldValue(`selectedServices[${i}].amount`, value);
                    }}
                    label="Egység"
                    type="number"
                    name="amount"
                    disabled={readOnly}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {x.unitOfQuantity}
                        </InputAdornment>
                      ),
                    }}
                    sx={{ input: { textAlign: 'right' } }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    value={taxes.find((y) => y.id === x.taxTypeId)?.name ?? ''}
                    variant="standard"
                    label="Áfa típus"
                    name="amount"
                    disabled
                    fullWidth
                    sx={{ input: { textAlign: 'right' } }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    value={x.discount}
                    variant="standard"
                    onChange={(e) => {
                      var value = parseFloat(e.target.value);
                      if (
                        parseFloat(e.target.value) >= 0 &&
                        parseFloat(e.target.value) <= 100
                      ) {
                        setFieldValue(`selectedServices[${i}].discount`, value);
                      }
                    }}
                    label="Kedvezmény"
                    type="number"
                    disabled={readOnly}
                    name="discount"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                    sx={{ input: { textAlign: 'right' } }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Grid item xs={12} md={4}>
                    <Typography fontWeight={'Bold'}>Összesen:</Typography>

                    {formatCurrency(
                      x.servicePriceGross * x.amount * (1 - x.discount / 100),
                      values.currency
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={1}
                sx={{ position: 'relative', right: { xs: '0%' } }}
              >
                <IconButton
                  disabled={readOnly}
                  onClick={() => {
                    setFieldValue('selectedServices', [
                      ...values.selectedServices.filter((y) => y.id !== x.id),
                    ]);
                  }}
                  color={'primary'}
                >
                  <Delete />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={x.comment}
                  variant="standard"
                  fullWidth
                  onChange={(e) => {
                    var value = e.target.value;
                    setFieldValue(`selectedServices[${i}].comment`, value);
                  }}
                  disabled={readOnly}
                  label="Megjegyzés"
                />
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      <SelectServiceDialog
        services={services}
        open={addServiceDialog}
        currency={values.currency}
        conversionRate={values.conversionRate}
        taxes={taxes}
        setOpen={setAddServiceDialog}
        addToCart={(service) => {
          const i = values.selectedServices?.find((x) => x.id === service.id);

          if (!i) {
            setFieldValue('selectedServices', [
              ...values.selectedServices,
              {
                ...service,
              },
            ]);
          } else {
            setFieldValue('selectedServices', [
              ...values.selectedServices?.filter((x) => x.id !== service.id),
              {
                ...service,
                amount: i?.amount + service.amount,
                discount: service.discount,
              },
            ]);
          }
          setAddServiceDialog(false);
        }}
      />
      <SpeedDial
        ariaLabel="Szolgáltatás hozzáadása"
        onClick={() => setAddServiceDialog(true)}
        sx={{
          display: { xs: isOffer ? 'none' : 'fixed', md: 'none' },
          position: 'fixed',
          bottom: 16,
          right: 16,
        }}
        icon={<Add />}
      ></SpeedDial>
    </Grid>
  );
};

export default memo(
  SelectServices,
  (prevValues, nextValues) =>
    prevValues.values.selectedServices === nextValues.values.selectedServices &&
    prevValues.services === nextValues.services &&
    prevValues.isOffer === nextValues.isOffer &&
    prevValues.readOnly === nextValues.readOnly &&
    prevValues.values.conversionRate === nextValues.values.conversionRate &&
    prevValues.values.currency === nextValues.values.currency
);
