import { createAsyncThunk } from "@reduxjs/toolkit";
import systemConfigurationService from "../../services/systemConfigurationService";
import userService from "../../services/authority/userService";
import { useSelector } from "react-redux";

export const loadInvoiceNumberPrefixes = createAsyncThunk(
  "systemConfigurations/loadInvoiceNumberPrefixes",
  async (payload, thunkAPI) => {
    thunkAPI.dispatch({ type: "SHOW_QUERY" });
    var user = useSelector((state: any) => state.user.userInfo);
    var response = await userService
      .get(user.userId)
      .finally(() => thunkAPI.dispatch({ type: "HIDE" }));
    return response;
  }
);
