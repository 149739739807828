import { createReducer } from "@reduxjs/toolkit";
import { reset, setForm, setWorkItemStep } from "../actions/workItemActions";

const initialState = {
  form: {},
  workItemInProgress: false,
  step: 0,
};

export const workItemReducer = createReducer(initialState, (builder) => {
  builder.addCase(setForm, (state, action) => {
    state.form = action.payload;
    state.workItemInProgress = true;
  });
  builder.addCase(reset, (state, action) => {
    state.form = {};
    state.workItemInProgress = false;
  });
  builder.addCase(setWorkItemStep, (state, action) => {
    state.step = action.payload;
  });
});
