import { useSelector } from 'react-redux';
const useCheckPermission = () => {
  const user = useSelector((state: any) => state.user.userInfo);

  const checkPermission = (permissions: string[]) => {
    if (!user) {
      return false;
    }

    if (permissions == null || permissions.length === 0) {
      return true;
    }

    let userPermissions = user.authorityInfo.permissions.map(
      (x) => x.name
    ) as string[];

    return permissions.every((x) => userPermissions.some((y) => y === x));
  };

  const hasAnyPermission = (permissions: string[]) => {
    if (!user) {
      return false;
    }

    if (permissions == null || permissions.length === 0) {
      return true;
    }

    let userPermissions = user.authorityInfo.permissions.map(
      (x) => x.name
    ) as string[];

    return permissions.some((x) => userPermissions.includes(x));
  };

  return {
    checkPermission,
    hasAnyPermission,
  };
};

export default useCheckPermission;
