import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useCheckPermission from '../../../hooks/useCheckPermission';
import projectService from '../../../services/erp/projectService';
import ProjectForm from './ProjectForm';
import { ProjectStatuses } from '../../../types/ProjectStatuses';
import clientService from '../../../services/crm/clientService';
import { EntityStatuses } from '../../../types/EntityStatuses';
import userService from '../../../services/authority/userService';
import documentBlockService from '../../../services/billing/documentBlockService';
import { DocumentBlockTypes } from '../../../types/DocumentBlockTypes';

const ProjectCreate = (props: any) => {
  const { onSave = null, navigateBack = true, handleCancel = null } = props;

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<string[]>([]);
  const [clients, setClients] = useState<any[]>([]);
  const [documentBlocks, setDocumentBlocks] = useState<any[]>([]);
  const [users, setUsers] = useState<any[]>([]);

  const [entity, setEntity] = useState<any>({
    startDate: '',
    endDate: '',
    clientId: '',
    managerId: '',
    plannedWorkHour: 0,
    projectNumber: '',
    name: '',
    documentBlockId: 0,
    description: '',
    status: ProjectStatuses.New,
    userIds: [],
    tasks: [],
  });

  const { checkPermission } = useCheckPermission();

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    projectService
      .create(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });

          if (onSave) onSave(response.result);
          if (navigateBack) navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        setSubmitting(false);
      });
  };

  useEffect(() => {
    documentBlockService.list(DocumentBlockTypes.Project).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setDocumentBlocks(response.records);
      }
    });
  }, []);

  useEffect(() => {
    clientService.list(false, EntityStatuses.Approved).then((response) => {
      if (response.canceled) return;
      if (!response.hasError) setClients(response.records);
      else
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
    });
  }, []);

  useEffect(() => {
    userService.list().then((response) => {
      if (response.canceled) return;
      if (!response.hasError) setUsers(response.records);
      else
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
    });
  }, []);

  return (
    <ProjectForm
      entity={entity}
      readonly={!checkPermission(['ProjectCreate'])}
      onSubmit={onSubmit}
      errors={errors}
      clients={clients}
      users={users}
      documentBlocks={documentBlocks}
      onClientAdded={(client) => {
        if (clients.find((x) => x.id === client.id)) {
          setClients((clients) =>
            clients.map((x) => (x.id === client.id ? client : x))
          );
        } else {
          setClients((clients) => [...clients, client]);
        }
      }}
      handleCancel={() =>
        handleCancel != null ? handleCancel() : navigate(-1)
      }
    />
  );
};

export default ProjectCreate;
