import axios from "axios";
import { postData } from "../axiosUtils";

const propertyService = {
  list: (itemGroupId = null) => {
    return axios
      .get(
        itemGroupId != null
          ? `/api/property/list?itemGroupId=${itemGroupId}`
          : "/api/property/list"
      )
      .then((response) => {
        return response.data;
      });
  },
  get: (id: any) => {
    return axios.get(`/api/property/details/${id}`).then((response) => {
      return response.data;
    });
  },
  create: (entity: any) => {
    return postData({
      url: `/api/property/create`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  update: (entity: any) => {
    return postData({
      url: `/api/property/update`,
      data: entity,
      method: "PUT",
    }).then((response) => {
      return response;
    });
  },
  delete: (id: any) => {
    return postData({
      url: `/api/property/delete/${id}`,
      method: "DELETE",
    }).then((response) => {
      return response;
    });
  },
};

export default propertyService;
