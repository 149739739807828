import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useCheckPermission from '../../../hooks/useCheckPermission';
import WorkClothesForm from './WorkClothesForm';
import workclothesService from '../../../services/deviceManagement/workClothesService';
import clientService from '../../../services/crm/clientService';
import userService from '../../../services/authority/userService';
import { ClientTypes } from '../../../types/ClientTypes';
import warehouseService from '../../../services/wms/warehouseService';
import { CompanyAssetStates } from '../../../types/InstrumentStates';

const WorkClothesCreate = (props: any) => {
  const { onSave = null, navigateBack = true, handleCancel = null } = props;

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<string[]>([]);
  const [users, setUsers] = useState<any[]>([]);
  const [clients, setClients] = useState<any[]>([]);
  const [warehouses, setWarehouses] = useState<any[]>([]);
  const [now, setNow] = useState<Date>(new Date());
  const [entity, setEntity] = useState<any>({
    name: '',
    description: '',
    companyId: 0,
    holderId: 0,
    warehouseId: 0,
    netPrice: 0,
    size: 'M',
    amount: 1,
    purchaseDate: now,
    guarantee: '',
    state: CompanyAssetStates.Working,
  });

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    warehouseService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setWarehouses(response.records);
      }
    });
  }, []);

  useEffect(() => {
    userService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setUsers(response.records);
      }
    });
  }, []);

  useEffect(() => {
    clientService.list(true, null).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setClients(response.records);
        setEntity({
          ...entity,
          companyId: response.records.filter(
            (x) => x.clientType === ClientTypes.Distributor
          )[0].id,
        });
      }
    });
  }, []);

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    workclothesService
      .create(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });

          if (onSave) onSave(response.result);
          if (navigateBack) navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        setSubmitting(false);
      });
  };

  return (
    <WorkClothesForm
      entity={entity}
      readonly={!checkPermission(['WorkClothesCreate'])}
      onSubmit={onSubmit}
      users={users}
      clients={clients}
      warehouses={warehouses}
      errors={errors}
      handleCancel={() =>
        handleCancel != null ? handleCancel() : navigate(-1)
      }
    />
  );
};

export default WorkClothesCreate;
