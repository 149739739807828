import axios from 'axios';
import { postData } from '../axiosUtils';

const itemSupplyService = {
  list: (signal?: AbortSignal) => {
    return axios.get(`/api/itemSupply/list`, { signal }).then((response) => {
      return response.data;
    });
  },

  listMy: (signal?: AbortSignal) => {
    return axios.get(`/api/itemSupply/listMy`, { signal }).then((response) => {
      return response.data;
    });
  },

  get: (id: any) => {
    return axios.get(`/api/itemSupply/details/${id}`).then((response) => {
      return response.data;
    });
  },

  create: (entity: any) => {
    return postData({
      url: `/api/itemSupply/create`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },

  update: (entity: any) => {
    return postData({
      url: `/api/itemSupply/update`,
      data: entity,
      method: 'PUT',
    }).then((response) => {
      return response;
    });
  },

  delete: (id: any) => {
    return postData({
      url: `/api/itemSupply/delete/${id}`,
      method: 'DELETE',
    }).then((response) => {
      return response;
    });
  },

  accept: (id: any, selectedWarehouseId: number) => {
    return postData({
      url: `/api/itemSupply/accept/${id}?selectedWarehouseId=${selectedWarehouseId}`,
      method: 'PUT',
    }).then((response) => {
      return response;
    });
  },

  reject: (id: any, rejectionComment: string) => {
    return postData({
      url: `/api/itemSupply/reject/${id}?rejectionComment=${rejectionComment}`,
      method: 'PATCH',
    }).then((response) => {
      return response;
    });
  },
};

export default itemSupplyService;
