import {
  Grid,
  Checkbox,
  FormControlLabel,
  Box,
  Rating,
  TextField,
  Radio,
  FormControl,
  FormLabel,
  RadioGroup,
  Button,
} from '@mui/material';
import React, { useEffect } from 'react';
import StarIcon from '@mui/icons-material/Star';
import useCheckPermission from '../../../../../hooks/useCheckPermission';
import { setForm } from '../../../../../stateManagement/actions/workItemActions';
import { useDispatch } from 'react-redux';
import { WorkItemStatus } from '../../WorkItemForm';

const EvaluationStep = (props: any) => {
  const { values, setFieldValue, handleEvaluation } = props;

  const [hover, setHover] = React.useState(-1);

  const { checkPermission } = useCheckPermission();
  const dispatch = useDispatch<any>();

  useEffect(() => {
    setFieldValue('workItemStatistic.workItemId', values.id);
  }, [values.id]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(setForm(values));
    }, 200);
    return () => clearTimeout(timeoutId);
  }, [values]);

  const labels: { [index: string]: string } = {
    0.5: 'Használhatatlan',
    1: 'Használhatatlan+',
    1.5: 'Gyenge',
    2: 'Gyenge+',
    2.5: 'Rendben',
    3: 'Rendben+',
    3.5: 'Jó',
    4: 'Jó+',
    4.5: 'Kiváló',
    5: 'Kiváló+',
  };

  function getLabelText(value: number) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
  }

  function isItOther(whereDidCLientFindUs: string) {
    return (
      whereDidCLientFindUs === 'Állandó partner' ||
      whereDidCLientFindUs === 'Visszatérő ügyfél' ||
      whereDidCLientFindUs === 'Ajánlás' ||
      whereDidCLientFindUs === 'Internet' ||
      whereDidCLientFindUs === 'Transzparens' ||
      whereDidCLientFindUs === 'Újsághirdetés'
    );
  }

  return (
    <Grid
      item
      xs={12}
      container
      textAlign={'center'}
      justifyContent="center"
      spacing={2}
      pt={5}
    >
      <Grid item xs={12} textAlign="center">
        <h2>
          <strong>Munkalap Kiértékelés</strong>
        </h2>
      </Grid>{' '}
      {!(
        values?.roleName === 'Dispatcher' &&
        values.workItemStatus !== WorkItemStatus.Closed &&
        values.workItemStatus !== WorkItemStatus.Evaluated
      ) && (
        <Grid item xs={12} md={2} justifyContent={'center'} pb={2}>
          <Grid item xs={12} justifyContent={'center'}>
            <strong>Munkalapon szereplő munka valósult meg?</strong>
          </Grid>
          <Grid item xs={12} justifyContent={'center'}>
            <Checkbox
              checked={values.workItemStatistic?.wasAppropiateJobDone}
              disabled={!checkPermission(['EvaluationEdit'])}
              onChange={(e, checked) => {
                setFieldValue(
                  'workItemStatistic.wasAppropiateJobDone',
                  checked
                );
              }}
            />
          </Grid>
        </Grid>
      )}
      <Grid item xs={12} md={4} p={1}>
        <FormControl>
          <FormLabel component="legend">
            <strong>Hol talált meg minket:</strong>
          </FormLabel>

          <RadioGroup
            value={values.workItemStatistic?.whereDidCLientFindUs}
            onChange={(e) =>
              setFieldValue(
                'workItemStatistic.whereDidClientFindUs',
                e.target.value
              )
            }
          >
            <FormControlLabel
              value="Állandó partner"
              checked={
                values.workItemStatistic?.whereDidClientFindUs ===
                'Állandó partner'
              }
              disabled={!checkPermission(['EvaluationEdit'])}
              labelPlacement="start"
              control={<Radio />}
              label="Állandó partner"
            />
            <FormControlLabel
              value="Visszatérő ügyfél"
              checked={
                values.workItemStatistic?.whereDidClientFindUs ===
                'Visszatérő ügyfél'
              }
              disabled={!checkPermission(['EvaluationEdit'])}
              labelPlacement="start"
              control={<Radio />}
              label="Visszatérő ügyfél"
            />
            <FormControlLabel
              value="Ajánlás"
              checked={
                values.workItemStatistic?.whereDidClientFindUs === 'Ajánlás'
              }
              disabled={!checkPermission(['EvaluationEdit'])}
              labelPlacement="start"
              control={<Radio />}
              label="Ajánlás"
            />
            <FormControlLabel
              value="Internet"
              checked={
                values.workItemStatistic?.whereDidClientFindUs === 'Internet'
              }
              disabled={!checkPermission(['EvaluationEdit'])}
              labelPlacement="start"
              control={<Radio />}
              label="Internet"
            />
            <FormControlLabel
              value="Transzparens"
              checked={
                values.workItemStatistic?.whereDidClientFindUs ===
                'Transzparens'
              }
              disabled={!checkPermission(['EvaluationEdit'])}
              labelPlacement="start"
              control={<Radio />}
              label="Transzparens"
            />
            <FormControlLabel
              value="Újsághirdetés"
              checked={
                values.workItemStatistic?.whereDidClientFindUs ===
                'Újsághirdetés'
              }
              disabled={!checkPermission(['EvaluationEdit'])}
              labelPlacement="start"
              control={<Radio />}
              label="Újsághirdetés"
            />
          </RadioGroup>
        </FormControl>
        <Grid item xs={12} md={12}>
          <TextField
            label="Egyéb:"
            value={
              isItOther(values.workItemStatistic?.whereDidClientFindUs)
                ? ''
                : values.workItemStatistic?.whereDidClientFindUs
            }
            onChange={(e) =>
              setFieldValue(
                'workItemStatistic.whereDidClientFindUs',
                e.target.value
              )
            }
            disabled={!checkPermission(['EvaluationEdit'])}
          />
        </Grid>
      </Grid>
      {!(
        values?.roleName === 'Dispatcher' &&
        values.workItemStatus !== WorkItemStatus.Closed &&
        values.workItemStatus !== WorkItemStatus.Evaluated
      ) && (
        <Grid item xs={12} md={2}>
          <Grid item xs={12}>
            <strong>Szolgáltatás értékelése</strong>
          </Grid>
          <Rating
            name="hover-feedback"
            value={values.workItemStatistic?.rating}
            precision={0.5}
            getLabelText={getLabelText}
            readOnly={!checkPermission(['EvaluationEdit'])}
            onChange={(event, newValue) => {
              setFieldValue('workItemStatistic.rating', newValue);
            }}
            onChangeActive={(event, newHover) => {
              setHover(newHover);
            }}
            emptyIcon={
              <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
            }
          />
          {values.WorkItemStatistic?.rating !== null && (
            <Box>
              {labels[hover !== -1 ? hover : values.WorkItemStatistic?.rating]}
            </Box>
          )}
        </Grid>
      )}
      <Grid
        item
        xs={12}
        sx={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}
      >
        <Button
          variant="contained"
          disabled={!checkPermission(['EvaluationEdit'])}
          onClick={() => handleEvaluation(values)}
        >
          Mentés
        </Button>
      </Grid>
    </Grid>
  );
};
export default EvaluationStep;
