import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Autocomplete,
} from '@mui/material';
import Button from '@mui/material/Button';
import { usePDF } from '@react-pdf/renderer';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import clientService from '../../../services/crm/clientService';
import taskTypeService from '../../../services/erp/taskTypeService';
import workItemService from '../../../services/erp/workItemService';
import { WorkItemPDF } from './WorkItemPDF';
import DataList from '../../../components/DataList';
import { GridColDef } from '@mui/x-data-grid';
import { parseJSON } from 'date-fns';
import { translateEmailStatusName } from '../../../utils/nameFormatters';
import { EmailTemplateType } from '../../../types/EmailTemplateType';

export default function WorkItemEmailDialog(props: any) {
  const { workItemId, open, setOpen, sendAll } = props;
  const [address, setAddress] = React.useState<string>('');
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const [workItem, SetWorkItem] = React.useState<any>();

  useEffect(() => {
    if (workItemId) {
      dispatch({ type: 'SHOW_QUERY' });
      workItemService
        .get(workItemId)
        .then((response) => {
          if (response.canceled) return;
          if (!response.hasError) SetWorkItem(response.result);
          else
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
        })
        .finally(() => dispatch({ type: 'HIDE' }));
    }
  }, [workItemId]);

  useEffect(() => {
    if (workItem) {
      dispatch({ type: 'SHOW_QUERY' });
      clientService
        .get(workItem?.clientId)
        .then((response) => {
          if (response.canceled) return;
          if (!response.hasError) {
            setAddress(response.result.contactEmail);
          } else
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
        })
        .finally(() => dispatch({ type: 'HIDE' }));
    }
  }, [workItem?.clientId]);

  const sendEmail = async (row: any) => {
    const entity = {
      to: address,
      workItemId: row.id,
    };
    dispatch({ type: 'SHOW_QUERY' });
    workItemService
      .sendEmail(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres küldés', {
            variant: 'success',
          });
          setOpen({ open: false, id: 0 });
        }
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  };

  const sendAllEmail = async () => {
    dispatch({ type: 'SHOW_QUERY' });
    workItemService
      .sendEmailAll({
        workItemId: workItem.id,
        type: EmailTemplateType.WorkItem,
        to: address,
      })
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres küldés', {
            variant: 'success',
          });
          setOpen({ open: false, id: 0 });
        }
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  };

  const columns: GridColDef[] = [
    {
      field: 'to',
      headerName: 'Email cím',
      flex: 200,
    },
    {
      field: 'sentAt',
      headerName: 'Küldés dátuma',
      valueFormatter(params) {
        return new Date(params.value).toLocaleDateString('hu-HU');
      },
      flex: 200,
    },
    {
      field: 'openedAt',
      headerName: 'Megnyitás dátuma',
      valueFormatter(params) {
        var date = new Date(params.value);
        return date > new Date(null)
          ? parseJSON(params.value).toLocaleString()
          : 'Nincs megnyitva';
      },
      flex: 200,
    },
    {
      field: 'status',
      headerName: 'Státusz',
      valueFormatter(params) {
        return translateEmailStatusName(params.value);
      },
      flex: 150,
    },
  ];

  return (
    <Dialog
      open={open}
      onClose={() => setOpen({ open: false, id: 0 })}
      fullWidth
    >
      <DialogTitle>Email küldése:</DialogTitle>
      <DialogContent>
        <Grid item xs={12} pt={2}>
          <Autocomplete
            disablePortal
            id="address"
            value={address ? address?.split(';') : []}
            onChange={(event, value) => {
              setAddress(value.join(';'));
            }}
            multiple
            freeSolo
            autoSelect
            renderInput={(params) => (
              <TextField {...params} required label="Email cím" />
            )}
            options={[]}
          />
        </Grid>
        <Grid item xs={12} pt={2}>
          Korábbi küldések:
          <DataList
            rows={workItem?.workItemEmails || []}
            columns={columns}
            getRowId={(row) => row.id}
            minimal
            localStorageKey={'WorkItemEmailDialog'}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={address === ''}
          onClick={() => {
            if (sendAll) {
              sendAllEmail();
            } else {
              sendEmail(workItem);
            }
          }}
        >
          Küldés
        </Button>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => setOpen({ open: false, id: 0 })}
        >
          Mégse
        </Button>
      </DialogActions>
    </Dialog>
  );
}
