export enum Modules {
  Pricing = 1,
  Sales = 2,
  ChimneySweeping = 3,
  Warehouse = 4,
  SystemSettings = 5,
  Report = 6,
  Client = 7,
  Statistics = 8,
  UserSettings = 9,
  Registration = 10,
  ItemsAndServices = 11,
  Billing = 12,
  Offer = 13,
  Email = 14,
  WorkItem = 15,
  Project = 16,
  Finance = 17,
  FilesAndAttachments = 18,
  HR = 19,
  Acquisition = 20,
  Task = 21,
}
