import axios from 'axios';
import { postData } from './axiosUtils';

const sasService = {
  getSASToken: () => {
    return axios.get('/api/sas/getSASToken').then((response) => {
      return response.data;
    });
  },
};

export default sasService;
