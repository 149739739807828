import axios from "axios";
import { postData } from "../axiosUtils";

const serialnumberedBlocktypeService = {
  list: () => {
    return axios.get(`/api/serialnumberedblocktype/list`).then((response) => {
      return response.data;
    });
  },
  get: (id: any) => {
    return axios
      .get(`/api/serialnumberedblocktype/details/${id}`)
      .then((response) => {
        return response.data;
      });
  },
  create: (entity: any) => {
    return postData({
      url: `/api/serialnumberedblocktype/create`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  update: (entity: any) => {
    return postData({
      url: `/api/serialnumberedblocktype/update`,
      data: entity,
      method: "PUT",
    }).then((response) => {
      return response;
    });
  },
  delete: (id: any) => {
    return postData({
      url: `/api/serialnumberedblocktype/delete/${id}`,
      method: "DELETE",
    }).then((response) => {
      return response;
    });
  },
};

export default serialnumberedBlocktypeService;
