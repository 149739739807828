import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, InputAdornment } from '@mui/material';

export default function SetMinAmountDialog(props: any) {
  const { open, handleClose, onSubmit, data } = props;
  const [minAmount, setMinAmount] = React.useState<number>(0);

  React.useEffect(() => {
    setMinAmount(data.minAmount);
  }, [data]);

  return (
    <Dialog
      open={open}
      style={{ minHeight: 500 }}
      fullWidth
      onClose={handleClose}
    >
      <DialogTitle>Beszerzési ár hozzáadása</DialogTitle>
      <DialogContent>
        <Grid container style={{ minHeight: '30vh' }} pt={5}>
          <Grid item xs={12}>
            <TextField
              size="small"
              label="Termék"
              disabled
              fullWidth
              value={data?.name}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              label="Minimális raktármennyiség"
              required
              autoFocus
              type="number"
              onChange={(event) => {
                if (parseInt(event.target.value) >= 0) {
                  setMinAmount(parseInt(event.target.value));
                } else {
                  setMinAmount(0);
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">db</InputAdornment>
                ),
              }}
              InputLabelProps={{ shrink: true }}
              fullWidth
              value={minAmount}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            onSubmit(data.itemId, minAmount);
            setMinAmount(0);
          }}
        >
          Mentés
        </Button>
        <Button color="error" variant="outlined" onClick={handleClose}>
          Mégse
        </Button>
      </DialogActions>
    </Dialog>
  );
}
