import axios from 'axios';
import { postData } from '../axiosUtils';

const offerService = {
  list: () => {
    return axios.get(`/api/offer/list`).then((response) => {
      return response.data;
    });
  },
  get: (id: any) => {
    return axios.get(`/api/offer/details/${id}`).then((response) => {
      return response.data;
    });
  },
  create: (entity: any) => {
    return postData({
      url: `/api/offer/create`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  getPdf: (entity: any) => {
    let url = `/api/offer/getPdf`;

    return postData({
      url: url,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  sendEmail: (entity: any) => {
    return postData({
      url: `/api/offer/sendEmail`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  update: (entity: any) => {
    return postData({
      url: `/api/offer/update`,
      data: entity,
      method: 'PUT',
    }).then((response) => {
      return response;
    });
  },
  accept: (id: any) => {
    return postData({
      url: `/api/offer/accept/${id}`,
      method: 'PATCH',
    }).then((response) => {
      return response;
    });
  },
  reject: (id: any) => {
    return postData({
      url: `/api/offer/reject/${id}`,
      method: 'PATCH',
    }).then((response) => {
      return response;
    });
  },
  updateStatus: (command: any) => {
    return postData({
      url: `/api/offer/updateStatus`,
      data: command,
      method: 'PUT',
    }).then((response) => {
      return response;
    });
  },
  delete: (id: any) => {
    return postData({
      url: `/api/offer/delete/${id}`,
      method: 'DELETE',
    }).then((response) => {
      return response;
    });
  },
};

export default offerService;
