export enum DocumentTemplateType {
  Invoice = 1,
  DeliveryNote = 2,
  WorkItem = 3,
  DepositInvoice = 4,
  FeeCollection = 5,
  Offer = 6,
  Receipt = 7,
  Storno = 8,
  SmallInvoice = 9,
  SmallReceipt = 10,
}
