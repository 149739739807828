import {
  Grid,
  Modal,
  Box,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';

type ImagePreviewProps = {
  image: {
    url1_1: string;
  };
};

const ImagePreview = ({ image }: ImagePreviewProps) => {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState({ url: '', aspect: 1 });

  const handleOpen = (imageUrl: string, aspect: number) => {
    setSelectedImage({ url: imageUrl, aspect });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid pt={5} container spacing={3} justifyContent="center">
        <Grid item>
          {image?.url1_1 && (
            <img
              width={150}
              height={150}
              src={image.url1_1}
              alt=""
              onClick={() => {
                handleOpen(image.url1_1, 1);
              }}
            />
          )}
        </Grid>
      </Grid>
      <Dialog open={open} onClose={handleClose} maxWidth="lg">
        <DialogContent>
          <Grid container justifyContent={'center'}>
            <Box
              sx={{
                position: 'absolute',
                top: '0%',
                right: '10px',
              }}
            >
              <IconButton
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  background: 'rgba(255,255,255,0.7)',
                }}
                onClick={handleClose}
              >
                <CloseIcon color="error" />
              </IconButton>{' '}
            </Box>
            <Grid item>
              <img
                height={500}
                width={500 * selectedImage.aspect}
                style={{ objectFit: 'cover' }}
                src={selectedImage.url}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ImagePreview;
