import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import WarehouseGroupForm from './WarehouseGroupForm';
import { useNavigate } from 'react-router-dom';
import warehouseGroupService from '../../../services/wms/warehouseGroupService';
import useCheckPermission from '../../../hooks/useCheckPermission';

const WarehouseGroupCreate = (props: any) => {
  const { onSave = null, navigateBack = true, handleCancel = null } = props;
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<string[]>([]);
  const { checkPermission } = useCheckPermission();

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    warehouseGroupService
      .create(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés', {
            variant: 'success',
          });

          if (onSave) onSave(response.result);
          if (navigateBack) navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        setSubmitting(false);
      });
  };

  return (
    <WarehouseGroupForm
      entity={{ id: 0, groupName: '' }}
      readonly={!checkPermission(['WarehouseGroupCreate'])}
      handleCancel={() =>
        handleCancel != null ? handleCancel() : navigate(-1)
      }
      onSubmit={onSubmit}
      errors={errors}
    />
  );
};

export default WarehouseGroupCreate;
