import { createReducer } from "@reduxjs/toolkit";
import {
  setSelectedWarehouseId,
  setWarehouses,
} from "../actions/userWarehouseActions";

const initialState = {
  warehouses: [],
  selectedWarehouseId: null,
};

export const userWarehouseReducer = createReducer(initialState, (builder) => {
  builder.addCase(setSelectedWarehouseId, (state, action) => {
    state.selectedWarehouseId = action.payload;
  });
  builder.addCase(setWarehouses, (state, action) => {
    state.warehouses = action.payload;
  });
});
