import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useCheckPermission from '../../../hooks/useCheckPermission';
import PeripheryForm from './PeripheryForm';
import peripheryService from '../../../services/deviceManagement/peripheryService';
import clientService from '../../../services/crm/clientService';
import userService from '../../../services/authority/userService';
import { ClientTypes } from '../../../types/ClientTypes';
import warehouseService from '../../../services/wms/warehouseService';
import { CompanyAssetStates } from '../../../types/InstrumentStates';

const PeripheryEdit = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const params = useParams();
  const [errors, setErrors] = useState<string[]>([]);
  const [now, setNow] = useState<Date>(new Date());
  const [users, setUsers] = useState<any[]>([]);
  const [clients, setClients] = useState<any[]>([]);
  const [warehouses, setWarehouses] = useState<any[]>([]);
  const [entity, setEntity] = useState<any>({
    serialNumber: '',
    type: '',
    name: '',
    other: '',
    purchaseDate: now,
    guarantee: '',
    state: CompanyAssetStates.Working,
    companyId: 0,
    holderId: 0,
    warehouseId: 0,
    netPrice: 0,
  });

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    warehouseService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setWarehouses(response.records);
      }
    });
  }, []);

  useEffect(() => {
    userService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setUsers(response.records);
      }
    });
  }, []);

  useEffect(() => {
    clientService.list(true, null).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setClients(response.records);
      }
    });
  }, []);

  useEffect(() => {
    peripheryService.get(params.id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        setErrors(response.errorMessages);
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setEntity(response.result);
      }
    });
  }, [params.id]);

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    peripheryService
      .update(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });

          navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        setSubmitting(false);
      });
  };

  return (
    <PeripheryForm
      entity={entity}
      readonly={!checkPermission(['PeripheryEdit'])}
      onSubmit={onSubmit}
      users={users}
      clients={clients}
      warehouses={warehouses}
      handleCancel={() => navigate(-1)}
      errors={errors}
    />
  );
};

export default PeripheryEdit;
