import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import TaskTypeForm from './TaskTypesForm';
import { useNavigate, useParams } from 'react-router-dom';
import useCheckPermission from '../../../hooks/useCheckPermission';
import taskTypeService from '../../../services/erp/taskTypeService';
import abilityService from '../../../services/authority/abilityService';

const TaskTypeEdit = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const params = useParams();
  const [errors, setErrors] = useState<string[]>([]);
  const [abilities, setAbilities] = useState<any[]>([]);

  const [entity, setEntity] = useState<any>({
    name: '',
    description: '',
    abilities: [],
  });

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    taskTypeService.get(params.id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        setErrors(response.errorMessages);
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setEntity(response.result);
      }
    });
  }, [params.id]);

  useEffect(() => {
    abilityService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setAbilities(response.records);
      }
    });
  }, []);

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    taskTypeService
      .update(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });

          navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        setSubmitting(false);
      });
  };

  return (
    <TaskTypeForm
      entity={entity}
      readonly={!checkPermission(['TaskTypeEdit'])}
      onSubmit={onSubmit}
      handleCancel={() => navigate(-1)}
      errors={errors}
      abilities={abilities}
      onAbilityAdded={(ability: any) => {
        setAbilities([...abilities, ability]);
      }}
    />
  );
};

export default TaskTypeEdit;
