import { Button, TextField, Box, Grid, Paper } from '@mui/material';
import { Form, Formik } from 'formik';

const SystemConfigurationForm = (props: any) => {
  const { entity, onSubmit, errors, handleCancel, readonly = false } = props;

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};
        if (values.value === null || values.value === undefined) {
          errors.value = 'Kötelező mező';
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        setFieldValue,
        errors: validationErrors,
      }) => (
        <Form>
          {' '}
          <Paper>
            <Grid container justifyContent="left" spacing={2} p={5}>
              <h2>
                Rendszerkonfiguráció{' '}
                {values.id > 0 ? 'szerkesztése' : 'létrehozása'}
              </h2>
              <Grid xs={12} item pt={5}>
                <Box color="red">
                  {errors.map((error) => (
                    <li>{error}</li>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  value={values.key}
                  label="Kulcs"
                  name="key"
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  value={values.value}
                  onChange={(event) => {
                    setFieldValue('value', event.target.value);
                  }}
                  label="Érték"
                  name="value"
                  fullWidth
                  autoFocus
                  disabled={readonly}
                  error={!!touched.value && !!validationErrors.value}
                  helperText={
                    !!touched.value &&
                    !!validationErrors.value &&
                    (validationErrors.value as string)
                  }
                />
              </Grid>{' '}
              <Grid item xs={12}>
                <TextField
                  value={values.description}
                  label="Leírás"
                  name="Description"
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid container item pt={3} justifyContent="left">
                {!readonly && (
                  <Grid item p={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      Mentés
                    </Button>
                  </Grid>
                )}
                <Grid item p={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleCancel}
                  >
                    Mégse
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default SystemConfigurationForm;
