import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
} from '@mui/material';

import ResourcePlanner from './ResourcePlanner';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  loadEmployees,
  loadEvents,
} from '../../../../stateManagement/thunks/resourcePlannerThunk';
import moment from 'moment';
import userService from '../../../../services/authority/userService';
import { useSnackbar } from 'notistack';

export const ResourcePlannerDialog = (props: any) => {
  const {
    open,
    workItemId,
    onClose = () => {},
    handleResourceSelected,
    employees,
    setHelperIds,
    helperIds,
    helperSelector = false,
    helperEmployees = [],
  } = props;

  const {
    // employees,
    events = [],
    error,
  } = useSelector((state: any) => state.resourcePlanner);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const dispatch = useDispatch<any>();
  const [users, setUsers] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [employeeId, setEmployeeId] = useState<number>(0);

  useEffect(() => {
    userService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setUsers(response.records);
      }
    });
  }, []);

  useEffect(() => {
    dispatch(loadEvents(selectedDate));
  }, [selectedDate]);

  const calendarEvents = events.map((x: any) => ({
    id: x.eventId,
    group: x.employeeId,
    title: x.task,
    start_time: moment.utc(x.startDate).local(),
    end_time: moment.utc(x.endDate).local(),
    itemProps: {
      style: {
        textAlign: 'center',
      },
    },
  }));

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
      <DialogContent>
        <DialogContentText>
          {workItemId && (
            <ResourcePlanner
              employees={employees}
              workItemId={workItemId}
              events={calendarEvents}
              onResourceSelected={handleResourceSelected}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              users={users}
              setHelperIds={setHelperIds}
              helperIds={helperIds}
              helperSelector={helperSelector}
              helperEmployees={helperEmployees}
            />
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose} color="primary">
          Mégse
        </Button>
      </DialogActions>
    </Dialog>
  );
};
