import { Button, Checkbox, FormControlLabel, Grid } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import SignatureDialog from './SignatureDialog';
import { setForm } from '../../../../../stateManagement/actions/workItemActions';
import { useDispatch } from 'react-redux';

const LegalStep: React.FC = (props: any) => {
  const { values, setFieldValue, readonly } = props;
  const [signature, setSignature] = React.useState<string | undefined>(
    undefined
  );
  const [signatureDialogOpen, setSignatureDialogOpen] = React.useState(false);

  const sigSignatureRef = useRef(null);
  const dispatch = useDispatch<any>();

  const [hasOfferPriceDifference, setHasOfferPriceDifference] =
    React.useState(false);

  const resetSignature = () => {
    sigSignatureRef.current?.clear();
    setSignature(undefined);
  };

  const handleClose = () => {
    setSignatureDialogOpen(false);
    resetSignature();
  };
  const handleSave = () => {
    let signature = sigSignatureRef.current?.toDataURL();
    setSignature(signature);
    signature = signature.replace(/^data:image\/[a-zA-Z]+;base64,/, '');
    setFieldValue('signature', signature);

    handleClose();
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(setForm(values));
    }, 200);
    return () => clearTimeout(timeoutId);
  }, [values]);

  useEffect(() => {
    var servicePrice = (values.selectedServices || []).reduce((sum, x) => {
      const price = x.servicePriceGross * x.amount * (1 - x.discount / 100);
      return sum + price;
    }, 0);

    var itemPrice = values.selectedItems?.reduce((sum, x) => {
      const price = x.sellPriceGross * x.amount * (1 - x.discount / 100);
      return sum + price;
    }, 0);
    if (values.offerPrice === null) {
      setFieldValue('isPriceDifferenceAcceptedByClient', true);

      return setHasOfferPriceDifference(false);
    } else {
      if (values.offerPrice !== servicePrice + itemPrice) {
        setHasOfferPriceDifference(true);
        setFieldValue('isPriceDifferenceAcceptedByClient', false);
      } else {
        setHasOfferPriceDifference(false);
        setFieldValue('isPriceDifferenceAcceptedByClient', true);
      }
    }
  }, [values.selectedServices, values.selectedItems]);

  return (
    <Grid item xs={12} container justifyContent="left" spacing={2} pt={5}>
      <Grid item xs={12} textAlign="center">
        <strong>Szerződési feltételek, adatvédelmi tájékoztató</strong>
      </Grid>{' '}
      <Grid item xs={12}></Grid>
      <Grid item>
        <FormControlLabel
          control={<Checkbox />}
          disabled={readonly}
          checked={values.isContractTermsAgreedByClient}
          onChange={(e, checked) =>
            setFieldValue('isContractTermsAgreedByClient', checked)
          }
          label="Ügyfél elfogadta a szerződési feltételeket"
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={<Checkbox />}
          disabled={readonly}
          checked={values.isPrivacyPolicyAgreedByClient}
          onChange={(e, checked) =>
            setFieldValue('isPrivacyPolicyAgreedByClient', checked)
          }
          label="Ügyfél nyilatkozik, hogy megismerte az adatvédelmi tájékoztató tartalmát"
        />
      </Grid>
      {hasOfferPriceDifference && (
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox />}
            disabled={readonly}
            checked={values.isPriceDifferenceAcceptedByClient}
            onChange={(e, checked) =>
              setFieldValue('isPriceDifferenceAcceptedByClient', checked)
            }
            label="Ügyfél elfogadta hogy az árajánlatban szereplő árak megváltoztak és a különbözetet kifizeti"
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Button
          disabled={readonly}
          variant="contained"
          onClick={() => setSignatureDialogOpen(true)}
        >
          Aláírás
        </Button>
      </Grid>
      <SignatureDialog
        signatureDialogOpen={signatureDialogOpen}
        handleClose={handleClose}
        setSignature={setSignature}
        handleSave={handleSave}
        sigSignatureRef={sigSignatureRef}
        resetSignature={resetSignature}
      />
    </Grid>
  );
};

export default LegalStep;
