import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  MoreVert,
  Pending,
} from '@mui/icons-material';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import SickIcon from '@mui/icons-material/Sick';
import {
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Button,
  Menu,
  MenuItem,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DatePickerHeader from '../../../components/DatePickerHeader';
import DateTableComponent from '../../../components/DateTableComponent';
import useCheckPermission from '../../../hooks/useCheckPermission';
import useConfirmDeleteDialog from '../../../hooks/useConfirmDeleteDialog';
import useFunctionDescriptor from '../../../hooks/useFunctionDescriptor';
import userAttendanceService from '../../../services/HR/userAttendanceService';
import UserAttendanceCreateDialog from './UserAttendanceCreateDialog';
import * as XLSX from 'xlsx';
import React from 'react';
import { AttendenceType } from '../../../types/AttendenceType';
import { UserAttendancePDFDialog } from './UserAttendancePDFDialog';
import moment from 'moment';

const UserAttendancePage = () => {
  const navigate = useNavigate();
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const [rows, setRows] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const titleDescriptor = useFunctionDescriptor('UserAttendancePage.title');
  const [now, setNow] = useState<Date>(new Date());
  const [tabValue, setTabValue] = useState<number>(2);
  const [selectedInterval, setSelectedInterval] = useState<any>({
    startDate: now,
    endDate: now,
  });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [menuEntity, setMenuEntity] = useState<any>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>, entity: any) => {
    setAnchorEl(event.currentTarget);
    setMenuEntity(entity);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [createDialog, setCreateDialog] = useState<any>({
    open: false,
    entity: null,
    handleSave: () => {},
  });

  const [pdfDialog, setPdfDialog] = useState<any>({
    open: false,
    data: [],
    date: null,
  });

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    userAttendanceService
      .list()
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) setRows(response.records);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  const handleDelete = (id: number) => {
    userAttendanceService.delete(id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres törlés', {
          variant: 'success',
        });
        setRows(rows.filter((row) => row.id !== id));
      }
    });
  };

  const handleCreate = (entity: any) => {
    userAttendanceService.create(entity).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres rögzítés', {
          variant: 'success',
        });
        setRows([...rows, response.result]);
      }
    });
  };

  const handleEdit = (entity: any) => {
    userAttendanceService.update(entity).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres szerkesztés', {
          variant: 'success',
        });
        setRows(
          rows.map((row) => {
            if (row.id === entity.id) return response.result;
            return row;
          })
        );
      }
    });
  };

  const frontActions = (entity: any) => {
    const actions: any[] = [];

    actions.push(
      <Grid container>
        <Grid item xs={3}>
          <Tooltip title="Ledolgozott nap">
            <IconButton
              color="success"
              onClick={() => {
                handleCreate(entity);
              }}
            >
              <EventAvailableIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={3}>
          <Tooltip title="Szabadság">
            <IconButton
              color="warning"
              onClick={() => {
                handleCreate({ ...entity, isHoliday: true });
              }}
            >
              <EventBusyIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={3}>
          <Tooltip title="Beteg szabadság">
            <IconButton
              color="error"
              onClick={() => {
                handleCreate({ ...entity, isSickHoliday: true });
              }}
            >
              <SickIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={3}>
          <div>
            <Tooltip title="Egyéb">
              <IconButton
                id="demo-positioned-button"
                aria-controls={open ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={(e) => {
                  handleClick(e, entity);
                  console.log('entity', entity);
                }}
              >
                <Pending color="primary" />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              open={open}
              elevation={0}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <MenuItem
                onClick={() => {
                  handleCreate({
                    ...menuEntity,
                    other: AttendenceType.HomeOffice,
                  });
                  handleClose();
                }}
              >
                Home Office
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleCreate({
                    ...menuEntity,
                    other: AttendenceType.RestDay,
                  });
                  handleClose();
                }}
              >
                Pihenőnap
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleCreate({
                    ...menuEntity,
                    other: AttendenceType.NonWorkinkDay,
                  });
                  handleClose();
                }}
              >
                Munkaszüneti nap
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleCreate({
                    ...menuEntity,
                    other: AttendenceType.DadHoliday,
                  });
                  handleClose();
                }}
              >
                Apai szabadság
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleCreate({
                    ...menuEntity,
                    other: AttendenceType.BirthHoliday,
                  });
                  handleClose();
                }}
              >
                Szülői szabadság
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleCreate({
                    ...menuEntity,
                    other: AttendenceType.ChildrenHoliday,
                  });
                  handleClose();
                }}
              >
                Gyermekek után járó szabadság
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleCreate({
                    ...menuEntity,
                    other: AttendenceType.StudyHoliday,
                  });
                  handleClose();
                }}
              >
                Tanulmányi szabadság
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleCreate({
                    ...menuEntity,
                    other: AttendenceType.ExtraOrdinaryHoliday,
                  });
                  handleClose();
                }}
              >
                Rendkívüli szabadság
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleCreate({
                    ...menuEntity,
                    other: AttendenceType.PaidVerifiedLeave,
                  });
                  handleClose();
                }}
              >
                Fizetett igazolt távollét
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleCreate({
                    ...menuEntity,
                    other: AttendenceType.InsurancePause,
                  });
                  handleClose();
                }}
              >
                Biztosítás szünetelése
              </MenuItem>
            </Menu>
          </div>
        </Grid>
      </Grid>
    );
    return actions;
  };
  const behindActions = (entity: any) => {
    const actions: any[] = [];
    actions.push(
      <Grid container>
        <Grid item xs={6}>
          <Tooltip title="Szerkesztés">
            <IconButton
              color="primary"
              onClick={() => {
                setCreateDialog({
                  open: true,
                  entity: entity,
                  handleSave: (newEntity) => {
                    handleEdit(newEntity);
                  },
                });
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={6}>
          <Tooltip title="Törlés">
            <IconButton
              color="error"
              onClick={() => {
                handleDelete(entity.id);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    );
    return actions;
  };

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>Jelenléti{titleDescriptor}</h2>
        </Grid>
        <Grid item xs={12}>
          <DatePickerHeader
            selectedInterval={selectedInterval}
            setSelectedInterval={setSelectedInterval}
            tabValue={tabValue}
            setTabValue={setTabValue}
            netGrossPicker={false}
            localStorageKey="userAttendance"
          />
        </Grid>
        {tabValue === 2 && (
          <Grid container justifyContent={'end'}>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  dispatch({ type: 'SHOW_QUERY' });
                  userAttendanceService
                    .listAll()
                    .then((response) => {
                      if (response.canceled) return;
                      if (!response.hasError) {
                        let filteredRows = response.records.filter((row) => {
                          let date = moment(row.startOfWork);
                          let intervalStart = moment(
                            selectedInterval.startDate
                          );
                          let intervalEnd = moment(selectedInterval.endDate);
                          let result =
                            date.isSameOrAfter(intervalStart) &&
                            date.isSameOrBefore(intervalEnd);

                          return result;
                        });
                        const groupedRows = filteredRows.reduce((acc, row) => {
                          let key = row.createdById;
                          if (!acc[key]) {
                            acc[key] = [];
                          }
                          acc[key].push(row);
                          return acc;
                        }, {});

                        let groupedRowsArray = Object.values(groupedRows);

                        setPdfDialog({
                          open: true,
                          data: groupedRowsArray,
                          date: selectedInterval.startDate,
                        });
                      } else {
                        enqueueSnackbar(response.errorMessages.join(','), {
                          variant: 'error',
                        });
                      }
                    })
                    .finally(() => dispatch({ type: 'HIDE' }));
                }}
              >
                Exportálás excelbe
              </Button>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <DateTableComponent
            selectedInterval={selectedInterval}
            data={rows}
            behindActions={behindActions}
            frontActions={frontActions}
          />
        </Grid>
        <ConfirmDeleteDialog />
        <UserAttendanceCreateDialog
          open={createDialog.open}
          onClose={() => {
            setCreateDialog({ open: false, entity: null });
          }}
          onSave={(entity) => {
            createDialog.handleSave(entity);
            setCreateDialog({ open: false, entity: null });
          }}
          entity={createDialog.entity}
        />
        <UserAttendancePDFDialog
          open={pdfDialog.open}
          data={pdfDialog.data}
          date={pdfDialog.date}
          onClose={() => {
            setPdfDialog({ open: false, data: [], date: null });
          }}
        />
      </Grid>
    </Paper>
  );
};

export default UserAttendancePage;
