import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Form, Formik } from 'formik';
import { ClientTypes } from '../../../types/ClientTypes';
import { CompanyAssetStates } from '../../../types/InstrumentStates';

const VehicleServiceForm = (props: any) => {
  const {
    entity,
    onSubmit,
    errors,
    handleCancel,
    readonly = false,
    clients,
    vehicles,
  } = props;

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        setFieldValue,
        handleChange,
        errors: validationErrors,
      }) => (
        <Form>
          {' '}
          <Paper>
            <Grid container justifyContent="left" spacing={2} p={5}>
              <h2>
                Jármű szerviz {values.id > 0 ? 'szerkesztése' : 'létrehozása'}
              </h2>
              <Grid xs={12} item pt={5}>
                <Box color="red">
                  {errors.map((error) => (
                    <li>{error}</li>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  disablePortal
                  id="serviceCompanyId"
                  disabled={readonly}
                  value={values?.serviceCompanyId ?? ''}
                  onChange={(event, value) => {
                    setFieldValue('serviceCompanyId', value);
                  }}
                  getOptionLabel={(option) =>
                    clients?.find((g) => g.id === option)?.companyName ?? ''
                  }
                  options={clients.map((g) => g.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      error={
                        !!touched.serviceCompanyId &&
                        !!validationErrors.serviceCompanyId
                      }
                      helperText={
                        !!touched.serviceCompanyId &&
                        !!validationErrors.serviceCompanyId &&
                        (validationErrors.serviceCompanyId as string)
                      }
                      fullWidth
                      label="Szerviz"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  disablePortal
                  id="vehicleId"
                  disabled={readonly}
                  value={values?.vehicleId ?? ''}
                  onChange={(event, value) => {
                    setFieldValue('vehicleId', value);
                  }}
                  getOptionLabel={(option) =>
                    vehicles?.find((g) => g.id === option)?.name ?? ''
                  }
                  options={vehicles.map((g) => g.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      error={
                        !!touched.vehicleId && !!validationErrors.vehicleId
                      }
                      helperText={
                        !!touched.vehicleId &&
                        !!validationErrors.vehicleId &&
                        (validationErrors.vehicleId as string)
                      }
                      fullWidth
                      label="Jármű"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={'auto'} p={1}>
                <DatePicker
                  label="Kezdés dátuma"
                  value={values.startDate ?? ''}
                  onChange={(date) => setFieldValue('startDate', date)}
                  renderInput={(props) => <TextField {...props} />}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={'auto'} p={1}>
                <DatePicker
                  label="Végdátum"
                  value={values.endDate ?? ''}
                  onChange={(date) => setFieldValue('endDate', date)}
                  renderInput={(props) => <TextField {...props} />}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.description}
                  onChange={handleChange}
                  label="Leírás"
                  name="description"
                  disabled={readonly}
                  fullWidth
                  multiline
                  error={
                    !!touched.description && !!validationErrors.description
                  }
                  helperText={
                    !!touched.description &&
                    !!validationErrors.description &&
                    (validationErrors.description as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.netPrice}
                  onChange={handleChange}
                  label="Nettó ár"
                  name="netPrice"
                  required
                  type="number"
                  InputLabelProps={{ shrink: true }}
                  disabled={readonly}
                  fullWidth
                  error={!!touched.netPrice && !!validationErrors.netPrice}
                  helperText={
                    !!touched.netPrice &&
                    !!validationErrors.netPrice &&
                    (validationErrors.netPrice as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.milage}
                  onChange={handleChange}
                  label="Km óra állás"
                  name="milage"
                  required
                  type="number"
                  InputLabelProps={{ shrink: true }}
                  disabled={readonly}
                  fullWidth
                  error={!!touched.milage && !!validationErrors.milage}
                  helperText={
                    !!touched.milage &&
                    !!validationErrors.milage &&
                    (validationErrors.milage as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  label="Volt olajcsere"
                  control={
                    <Checkbox
                      checked={values.wasOilChanged}
                      disabled={readonly}
                      onChange={(e, checked) =>
                        setFieldValue('wasOilChanged', checked)
                      }
                    />
                  }
                />
              </Grid>
              <Grid container item pt={3} justifyContent="left">
                {!readonly && (
                  <Grid item p={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      Mentés
                    </Button>
                  </Grid>
                )}
                <Grid item p={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleCancel}
                  >
                    Mégse
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default VehicleServiceForm;
