import useConfirmDialog from './useConfirmDialog';

const useConfirmOfferDialog = () => {
  const { ConfirmDialog, setConfirmParams, params } = useConfirmDialog();

  const ConfirmOfferDialog = (props: any) => {
    return (
      <ConfirmDialog actionName="Az ügyfélnek kötelező árajánlatot készíteni!" />
    );
  };
  return {
    ConfirmOfferDialog,
    setOfferParams: setConfirmParams,
    params,
  };
};
export default useConfirmOfferDialog;
