import {
  Button,
  Grid,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import AddressForm from './AddressForm';
import Divider from '@mui/material/Divider';
import { Add, Key } from '@mui/icons-material';
import { CollapsePaper } from '../../../components/CollapsePaper';
import React from 'react';
import { translateAddressTypeName } from '../../../utils/nameFormatters';
import { AddressTypes } from '../../../types/AddressTypes';

const AddressesForm = (props: any) => {
  const {
    values,
    setFieldValue,
    errors,
    touched,
    readonly = false,
    setSelectedAddresses = () => {},
    selectedAddresses = [],
    isSelectable = false,
  } = props;
  const [searchTerm, setSearchTerm] = React.useState('');
  const [checkAll, setCheckAll] = React.useState(false);

  const addNewAddress = () => {
    setFieldValue('addresses', [
      ...values.addresses,
      {
        postalCode: '',
        city: '',
        street: '',
        houseNumber: '',
        apartmentNumber: '',
        country: 'Magyarország',
        region: '',
        district: '',
        comment: '',
        id: 0,
        isBillingAddress: false,
        isCollapsed: false,
        type: AddressTypes.Site,
      },
    ]);
  };

  const handleDelete = (index: number, address: any) => {
    setFieldValue(
      'addresses',
      values.addresses.filter((x) => x !== address)
    );
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setCheckAll(false);
  };

  const filteredAddresses = values.addresses.filter((address: any) =>
    `${address.country} ${address.postalCode} ${address.city} ${
      address.street
    } ${address.houseNumber ?? ''}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const handleSelectAll = (checked: boolean) => {
    setCheckAll(checked);
    setSelectedAddresses(
      checked
        ? [
            ...selectedAddresses,
            ...filteredAddresses.filter(
              (x) => !selectedAddresses.find((g) => g === x)
            ),
          ]
        : selectedAddresses.filter(
            (x) => !filteredAddresses.find((g) => g === x)
          )
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          label="Keresés"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearch}
          fullWidth
        />
      </Grid>
      {isSelectable && (
        <Grid item xs={12}>
          <FormControlLabel
            label="Összes kijelölése"
            control={
              <Checkbox
                checked={checkAll}
                onChange={(e, checked) => handleSelectAll(checked)}
              />
            }
          />
        </Grid>
      )}
      {filteredAddresses.map((address: any, index: number) => (
        <Grid item xs={12}>
          <CollapsePaper
            title={
              <Grid container alignItems={'center'}>
                <Grid item>
                  <Typography
                    textAlign="left"
                    fontWeight={'bold'}
                    fontSize={20}
                    sx={{ mt: 2 }}
                  >
                    {`${address.country} ${address.postalCode} ${
                      address.city
                    } ${address.street} ${
                      address.houseNumber ?? ''
                    }(${translateAddressTypeName(address.type)}) `}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    textAlign="left"
                    fontWeight={'bold'}
                    fontSize={20}
                    color={'#73FF3E'}
                    sx={{ mt: 2 }}
                  >
                    {address.id > 0 ? '' : '(Új)'}
                  </Typography>
                </Grid>
                {isSelectable && (
                  <Grid item>
                    <Checkbox
                      checked={selectedAddresses.some((g) => g === address)}
                      onChange={(e, checked) => {
                        setSelectedAddresses(
                          checked
                            ? [...selectedAddresses, address]
                            : selectedAddresses.filter((x) => x !== address)
                        );
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            }
            color="#F5F5F5"
            collapsed={address.isCollapsed ?? true}
            children={
              <Grid container p={2}>
                <>
                  <Grid item key={index}>
                    <AddressForm
                      onAddressChange={(address) => {
                        if (address.isBillingAddress) {
                          var clone = values.addresses.map((x) => {
                            return { ...x, isBillingAddress: false };
                          });
                          setFieldValue(`addresses`, clone);
                        }

                        setFieldValue(`addresses[${index}]`, address);
                      }}
                      readonly={readonly}
                      errors={errors?.addresses?.[index]}
                      touched={touched?.addresses?.[index]}
                      address={values.addresses[index]}
                    />
                    {address != null && (
                      <Grid item pt={2}>
                        <Button
                          color="error"
                          variant="contained"
                          disabled={readonly}
                          onClick={() => handleDelete(index, address)}
                        >
                          Törlés
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </>
              </Grid>
            }
          />
        </Grid>
      ))}
      {!readonly && (
        <Grid item xs={12}>
          <Button variant="contained" onClick={addNewAddress}>
            Cím hozzáadása
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default AddressesForm;
