import {
  Add as AddIcon,
  CopyAll,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Visibility,
} from '@mui/icons-material';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import { Grid, IconButton, Paper, Tooltip } from '@mui/material';
import {
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
} from '@mui/x-data-grid';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import DataList from '../../../components/DataList';
import useCheckPermission from '../../../hooks/useCheckPermission';
import useConfirmDeleteDialog from '../../../hooks/useConfirmDeleteDialog';
import useFunctionDescriptor from '../../../hooks/useFunctionDescriptor';
import documentTemplateService from '../../../services/billing/documentTemplateService';
import { translateDocumentTemplateType } from '../../../utils/nameFormatters';
import {
  reset,
  setSettings,
} from '../../../stateManagement/actions/documentTemplateActions';

export interface DocumentTemplate {
  id: string;
  name: string;
  description: string;
}

const DocumentTemplatePage = (props: any) => {
  const navigate = useNavigate();
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const [rows, setRows] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const titleDescriptor = useFunctionDescriptor('DocumentTemplatePage.title');
  const { documentTemplateType } = props;

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    dispatch(reset());
  }, []);

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    documentTemplateService
      .list()
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError)
          setRows(
            response.records.filter((r) => r.type === documentTemplateType)
          );
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  const setDefault = (id: number) => {
    dispatch({ type: 'SHOW_SAVE' });
    documentTemplateService
      .setDefault(id)
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) {
          setRows(
            rows.map((r) => {
              r.isDefault = r.id === id;
              return r;
            })
          );
        } else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  };

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];
    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          <Tooltip title="Másolás">
            <CopyAll />
          </Tooltip>
        }
        label={'Másolás'}
        onClick={() => {
          dispatch(setSettings(params.row.setting));
          navigate(`/erp/documentTemplates/create/${documentTemplateType}`);
        }}
      />
    );
    if (
      checkPermission(['DefaultDocumentTemplateChange']) &&
      !params.row.isDefault
    ) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Beállítás alapértelmezettként">
              <SettingsApplicationsIcon />
            </Tooltip>
          }
          label="Beállítás alapértelmezettként"
          onClick={() => setDefault(params.row.id)}
        />
      );
    }

    let readonly = !checkPermission(['DocumentTemplateEdit']);
    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          readonly ? (
            <Tooltip title="Megtekintés">
              <Visibility />
            </Tooltip>
          ) : (
            <Tooltip title="Szerkesztés">
              <EditIcon />
            </Tooltip>
          )
        }
        label={readonly ? 'Megtekintés' : 'Szerkesztés'}
        onClick={() => navigate(`/erp/documentTemplates/edit/${params.row.id}`)}
      />
    );

    if (checkPermission(['DocumentTemplateDelete'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Törlés">
              <DeleteIcon />
            </Tooltip>
          }
          label="Törlés"
          onClick={() =>
            setParams({
              open: true,
              name: '',
              onConfirm: async () => handleDelete(params.row.id),
            })
          }
        />
      );
    }

    return actions;
  };

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Név', flex: 100 },
    {
      field: 'isDefault',
      headerName: 'Alapértelmezett',
      valueFormatter(params) {
        return params.value ? 'Igen' : 'Nem';
      },
      flex: 100,
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 150,
      align: 'right',
      getActions: getActions,
    } as GridActionsColDef,
  ];

  const handleDelete = (id: number) => {
    documentTemplateService.delete(id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres törlés', {
          variant: 'success',
        });
        setRows(rows?.filter((row) => row.id !== id));
      }
    });
  };

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>
            {translateDocumentTemplateType(documentTemplateType)} sablonok
            {titleDescriptor}
          </h2>
        </Grid>
        {checkPermission(['DocumentTemplateCreate']) && (
          <Grid container item xs={12} p={2} justifyContent="end">
            <Grid item>
              <Tooltip title="Dokumentum sablon létrehozása">
                <IconButton
                  component={RouterLink}
                  to={`/erp/documentTemplates/create/${documentTemplateType}`}
                  color="primary"
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <DataList
            rows={rows}
            columns={columns}
            localStorageKey={'DocumentTemplatePage'}
          />
        </Grid>
        <ConfirmDeleteDialog />
      </Grid>
    </Paper>
  );
};

export default DocumentTemplatePage;
