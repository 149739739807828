import { Grid, TextField } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { setForm } from "../../../../../stateManagement/actions/workItemActions";
import { useDispatch } from "react-redux";

const CommentStep: React.FC = (props: any) => {
  const { values, setFieldValue, readonly, touched, validationErrors } = props;

  const dispatch = useDispatch<any>();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(setForm(values));
    }, 200);
    return () => clearTimeout(timeoutId);
  }, [values]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          value={values.comment ?? ""}
          multiline
          rows={8}
          onChange={(e) => setFieldValue("comment", e.target.value)}
          label="Megjegyzés"
          name="description"
          fullWidth
          disabled={readonly}
          error={!!touched.comment && !!validationErrors.comment}
          helperText={
            !!touched.comment &&
            !!(validationErrors.comment as string) &&
            (validationErrors.comment as string)
          }
        />
      </Grid>
    </Grid>
  );
};

export default CommentStep;
