import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useCheckPermission from '../../../hooks/useCheckPermission';
import taskService from '../../../services/erp/taskService';
import TaskForm from './TaskForm';
import userService from '../../../services/authority/userService';

const TaskEdit = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const params = useParams();
  const [errors, setErrors] = useState<string[]>([]);
  const user = useSelector((state: any) => state.user.userInfo);
  const [employees, setEmployees] = useState<any[]>([]);
  const [entity, setEntity] = useState<any>({
    date: new Date(),
    description: '',
    employeeId: '',
    employeeName: '',
    isCompleted: false,
    workHour: '',
  });

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    taskService.get(params.id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        setErrors(response.errorMessages);
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setEntity(response.result);
      }
    });
  }, [params.id]);

  useEffect(() => {
    userService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        setErrors(response.errorMessages);
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setEmployees(response.records);
      }
    });
  }, []);

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    taskService
      .update(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });

          navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        setSubmitting(false);
      });
  };

  return (
    <TaskForm
      entity={entity}
      readonly={
        !checkPermission(['TaskEdit']) ||
        entity.isCompleted ||
        entity.createdById !== user?.userId
      }
      onSubmit={onSubmit}
      handleCancel={() => navigate(-1)}
      errors={errors}
      employees={employees}
    />
  );
};

export default TaskEdit;
