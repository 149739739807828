import { abort } from 'process';

const initialState = {
  waitLayerVisible: false,
  waitLayerCount: 0,
  message: 'The request is being processed please wait',
  abort: null,
};

export const waitLayerReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'TOGGLE':
      return {
        ...state,
        waitLayerVisible: !state.waitLayerVisible,
        message: action.payload || initialState.message,
      };
    case 'SHOW':
      return {
        ...state,
        waitLayerVisible: true,
        message: action.payload || initialState.message,
      };
    case 'SHOW_SAVE':
      return {
        ...state,
        waitLayerVisible: true,
        message: 'Mentés folyamatban...',
      };
    case 'SHOW_QUERY':
      return {
        ...state,
        waitLayerVisible: true,
        message: 'Lekérdezés folyamatban...',
        abort: action.payload?.abort,
        waitLayerCount: state.waitLayerCount + 1,
      };
    case 'SHOW_SERVICE_UNAVAILABLE':
      return {
        ...state,
        waitLayerVisible: true,
        message: 'Szerver nem elérhető, kérem várjon!',
        abort: null,
      };
    case 'HIDE':
      return {
        ...state,
        waitLayerVisible: state.waitLayerCount - 1 > 0 ? true : false,
        message:
          state.waitLayerCount - 1 > 0 ? state.message : initialState.message,
        abort: state.waitLayerCount - 1 > 0 ? state.abort : null,
        waitLayerCount: state.waitLayerCount > 0 ? state.waitLayerCount - 1 : 0,
      };
    case 'SET_MESSAGE':
      return {
        ...state,
        message: action.payload,
      };
    default:
      return state;
  }
};
