import { Box, Grid, Paper, Tab, Tabs, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import ExpenseStatistics from './ExpenseStatistics';
import IncomeStatistics from './IncomeStatistics';
import ItemsIncomeStatistics from './ItemsIncomeStatistics';
import ItemsProfitStatistics from './ItemsProfitStatistics';
import OutstandingOrderStatistics from './OutstandingOrderStatistics';
import StatisticsHomePage from './StatisticsHomePage';
import useFunctionDescriptor from '../../hooks/useFunctionDescriptor';
import useCheckPermission from '../../hooks/useCheckPermission';
import UnPaidExpenseStatistics from './UnPaidExpenseStatistics';
import EmployeeProfitStatistics from './EmployeeProfitStatistics';
const FinancePage = () => {
  const navigate = useNavigate();
  const titleDescriptor = useFunctionDescriptor('FinancePage.title');
  const [tabValue, setTabValue] = React.useState(0);
  const { checkPermission } = useCheckPermission();
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>Pénzügyi statisztika{titleDescriptor}</h2>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ width: '100%' }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                pb: 2,
                maxWidth: isSmallScreen ? '280px' : '100%',
              }}
            >
              <Tabs
                value={tabValue}
                onChange={handleChange}
                TabIndicatorProps={{ style: { background: '#1976d2' } }}
                sx={{
                  maxWidth: isSmallScreen ? '280px' : '100%',
                  overflowX: 'scroll',
                }}
                allowScrollButtonsMobile
                scrollButtons="auto"
                variant="scrollable"
              >
                <Tab label="Statisztikák" sx={{ fontWeight: 'bold' }} />
                <Tab label="Kiadások" sx={{ fontWeight: 'bold' }} />
                <Tab label="Befizetésre vár" sx={{ fontWeight: 'bold' }} />
                <Tab label="Bevételek" sx={{ fontWeight: 'bold' }} />
                <Tab label="Termék" sx={{ fontWeight: 'bold' }} />
                <Tab label="Kintlévőségek" sx={{ fontWeight: 'bold' }} />
                <Tab label="Termék Profit" sx={{ fontWeight: 'bold' }} />
                <Tab label="Dolgozói Profit" sx={{ fontWeight: 'bold' }} />
              </Tabs>
            </Box>
            {tabValue === 0 && <StatisticsHomePage />}
            {checkPermission(['ExpenseQuery']) && tabValue === 1 && (
              <ExpenseStatistics />
            )}
            {checkPermission(['ExpenseQuery']) && tabValue === 2 && (
              <UnPaidExpenseStatistics />
            )}
            {checkPermission(['IncomeQuery']) && tabValue === 3 && (
              <IncomeStatistics />
            )}
            {checkPermission(['ItemsIncomeQuery']) && tabValue === 4 && (
              <ItemsIncomeStatistics />
            )}
            {checkPermission(['OutstandingOrderQuery']) && tabValue === 5 && (
              <OutstandingOrderStatistics />
            )}
            {checkPermission(['ItemsProfitQuery']) && tabValue === 6 && (
              <ItemsProfitStatistics />
            )}
            {checkPermission(['EmployeeProfitQuery']) && tabValue === 7 && (
              <EmployeeProfitStatistics />
            )}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default FinancePage;
