import axios from "axios";
import { EntityStatuses } from "../../types/EntityStatuses";
import { postData } from "../axiosUtils";
import { FailedToNegotiateWithServerError } from "@microsoft/signalr/dist/esm/Errors";
const errorHandler = (error: any) => {
  return { hasError: true, errorMessages: [] };
};

const itemService = {
  listItemsWithTotalCount: (wareHouseId: number | null = null) => {
    var queryParams = "";
    if (wareHouseId != null) {
      queryParams = `wareHouseId=${wareHouseId}`;
    }
    return axios
      .get(
        `/api/item/listItemsWithTotalCount${
          queryParams === "" ? "" : "?" + queryParams
        }`
      )
      .then((response) => {
        return response.data;
      })
      .catch(errorHandler);
  },
  list: (
    status: EntityStatuses = null,
    signal: AbortSignal = new AbortController().signal,
    isMarked: boolean = false
  ) => {
    let url = `/api/item/list`;
    if (status !== null) {
      url += `?status=${status}`;
    }
    if (status === null) {
      url += `?isMarked=${isMarked}`;
    } else {
      url += `&isMarked=${isMarked}`;
    }
    return axios
      .get(url, { signal })
      .then((response) => {
        return response.data;
      })
      .catch(errorHandler);
  },
  listDeleted: (
    status: EntityStatuses = null,
    signal: AbortSignal = new AbortController().signal,
    isMarked: boolean = false
  ) => {
    let url = `/api/item/listDeleted`;
    if (status !== null) {
      url += `?status=${status}`;
    }
    if (isMarked && status === null) {
      url += `?isMarked=${isMarked}`;
    } else {
      url += `&isMarked=${isMarked}`;
    }
    return axios
      .get(url, { signal })
      .then((response) => {
        return response.data;
      })
      .catch(errorHandler);
  },

  listMy: (
    status: EntityStatuses = EntityStatuses.Approved,
    signal: AbortSignal = new AbortController().signal,
    isMarked: boolean = false
  ) => {
    return axios
      .get(`/api/item/listMy?status=${status}&isMarked=${isMarked}`, { signal })
      .then((response) => {
        return response.data;
      })
      .catch(errorHandler);
  },

  listNames: (
    listComplexItems: boolean = true,
    clientId: number | null = null,
    itemGroupId: number | null = null,
    signal: AbortSignal = new AbortController().signal
  ) => {
    var queryParams = "";
    if (clientId != null) {
      queryParams = `&clientId=${clientId}`;
    }

    if (itemGroupId !== null) {
      queryParams += `&itemGroupId=${clientId}`;
    }

    return axios
      .get(
        `/api/item/listNames?listComplexItems=${listComplexItems}${
          queryParams === "" ? "" : queryParams
        }`,
        { signal }
      )
      .then((response) => {
        return response.data;
      })
      .catch(errorHandler);
  },
  get: (id: any) => {
    return axios.get(`/api/item/details/${id}`).then((response) => {
      return response.data;
    });
  },
  getSuppliers: (id: any) => {
    return axios.get(`/api/item/getSuppliers/${id}`).then((response) => {
      return response.data;
    });
  },
  create: (entity: any) => {
    return postData({
      url: `/api/item/create`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  update: (entity: any) => {
    return postData({
      url: `/api/item/update`,
      data: entity,
      method: "PUT",
    }).then((response) => {
      return response;
    });
  },
  combine: (entity: any) => {
    return postData({
      url: `/api/item/combine`,
      data: entity,
      method: "PUT",
    }).then((response) => {
      return response;
    });
  },
  delete: (id: any) => {
    return postData({
      url: `/api/item/delete/${id}`,
      method: "DELETE",
    }).then((response) => {
      return response;
    });
  },
  sendToApproval: (id: any) => {
    return postData({
      url: `/api/item/sendToApproval/${id}`,
      method: "PATCH",
    }).then((response) => {
      return response;
    });
  },
  approve: (id: any) => {
    return postData({
      url: `/api/item/approve/${id}`,
      method: "PATCH",
    }).then((response) => {
      return response;
    });
  },
  reject: (id: any, comment: string) => {
    return postData({
      url: `/api/item/reject/${id}?comment=${comment}`,
      method: "PATCH",
    }).then((response) => {
      return response;
    });
  },
};

export default itemService;
