import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useCheckPermission from '../../../hooks/useCheckPermission';
import ChimneySweepingCertificateForm from './ChimneySweepingCertificateForm';
import clientService from '../../../services/crm/clientService';
import { EntityStatuses } from '../../../types/EntityStatuses';
import chimneySweepingCertificateService from '../../../services/chimneySweeping/chimneySweepingCertificateService';
import { set } from 'date-fns';
import { ClientTypes } from '../../../types/ClientTypes';
import chimneyTypeService from '../../../services/chimneySweeping/chimneyTypeService';
import chimneyUseCaseService from '../../../services/chimneySweeping/chimneyUseCaseService';
import performanceCategoryService from '../../../services/chimneySweeping/performanceCategoryService';
import chimneyErrorCodeService from '../../../services/chimneySweeping/chimneyErrorCodeService';
import userService from '../../../services/authority/userService';
import roleService from '../../../services/authority/roleService';
import chimneyService from '../../../services/chimneySweeping/chimneyService';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { ChimneySweepingCertificateStatus } from '../../../types/ChimneySweepingCertificateStatus';
import carbonMonoxideSensorService from '../../../services/chimneySweeping/carbonMonoxideSensorService';
import { ChimneySweepingCertOrderType } from '../../../types/ChimneySweepingCertOrderType';

const ChimneySweepingCertificateCreate = (props: any) => {
  const { onSave = null, navigateBack = true, handleCancel = null } = props;

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<string[]>([]);
  const [clients, setClients] = useState<any[]>([]);
  const [workAddressId, setWorkAddressId] = useState<number>(0);
  const [chimneyTypes, setChimneyTypes] = useState<any[]>([]);
  const [useCases, setUseCases] = useState<any[]>([]);
  const [chimneys, setChimneys] = useState<any[]>([]);
  const [coSensors, setCoSensors] = useState<any[]>([]);
  const [performanceCategories, setPerformanceCategories] = useState<any[]>([]);
  const [errorCodes, setErrorCodes] = useState<any[]>([]);
  const [now, setNow] = useState<Date>(new Date());
  const user = useSelector((state: any) => state.user.userInfo);
  const lastSelectedChimneySweeperId = useLocalStorage(
    'lastSelectedChimneySweeperId'
  );
  const lastCertDate = useLocalStorage('lastCertDate');
  const params = useParams();

  const [representative, setRepresentative] = useState<any>({
    clientId: 0,
    representativeName: '',
    representativePhone: '',
  });
  const [entity, setEntity] = useState<any>({
    name: 'ÉGÉSTERMÉK-ELVEZETŐK ELLENŐRZÉSE, SZÜKSÉG SZERINTI TISZTÍTÁSA, 4 ÉVENKÉNTI MŰSZAKI FELÜLVIZSGÁLATA',
    id: 0,
    airSpaceConnectionAmount: 1,
    clientId: 0,
    employeeId: 0,
    serviceProviderId: 0,
    approverName: '',
    documentNumber: '',
    carbonMonoxideSensors: [],
    approverTitle: '',
    others: '',
    date: now,
    workAddressId: 0,
    obligedToInstallCODevice: '',
    obligedToInstallCODevicePlaceNumber: '',
    hasCODevice: '',
    cODevicePlace: '',
    cODeviceWorks: '',
    cleaningDone: '',
    calibrationDone: '',
    hasDocuments: '',
    orderType: ChimneySweepingCertOrderType.OrderedByEmail,
    isOrdered: true,
    warehouseId: 0,
    expired: '',
    errorMessage: '',
    appearedOnLocation: true,
    inspection: true,
    cleaning: false,
    technicalReview: false,
    chimneyControls: [],
    hasDeliveryFee: true,
    isPaid: true,
    paymentMethod: 'Készpénz',
    status: ChimneySweepingCertificateStatus.Valid,
  });

  useEffect(() => {
    if (params.documentNumber) {
      setEntity((prev) => ({
        ...prev,
        documentNumber: params.documentNumber,
      }));
    }
  }, [params]);

  const [users, setUsers] = useState<string[]>([]);
  useEffect(() => {
    userService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setUsers(response.records);
      }
    });
  }, []);

  const { checkPermission } = useCheckPermission();

  const onSubmit = (
    entity: any,
    setSubmitting: any,
    createNew: boolean = false
  ) => {
    dispatch({ type: 'SHOW_SAVE' });
    return chimneySweepingCertificateService
      .create(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });

          lastSelectedChimneySweeperId.setLocalStorageValue(
            entity.employeeId.toString()
          );

          lastCertDate.setLocalStorageValue(entity.date.toString());

          if (onSave) onSave(response.result);
          if (navigateBack && !createNew) navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        setSubmitting(false);
      });
  };

  useEffect(() => {
    if (workAddressId > 0) {
      chimneyService.list(workAddressId).then((response) => {
        setChimneys(response.records);
      });
    }
  }, [workAddressId]);

  useEffect(() => {
    let abort = new AbortController();

    dispatch({ type: 'SHOW_QUERY' });
    clientService
      .list(true, null, abort.signal)
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) {
          setClients(response.records);
          setEntity((prev) => ({
            ...prev,
            serviceProviderId: response.records.filter(
              (x) => x.clientType === ClientTypes.Distributor
            )[0]?.id,
          }));
        } else {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
      });
    return () => {
      abort.abort();
    };
  }, []);

  const handleClientSelected = (clientId: number) => {
    if (!(clientId > 0)) return;

    clientService.get(clientId).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setRepresentative({
          clientId: response.result.id,
          representativeName: response.result.representativeName,
          representativePhone: response.result.representativePhone,
        });
      }
    });
  };

  useEffect(() => {
    carbonMonoxideSensorService.list().then((response) => {
      if (response.canceled) return;
      if (!response.hasError) {
        setCoSensors(response.records);
      } else {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      }
    });
  }, []);

  useEffect(() => {
    chimneyTypeService.list().then((response) => {
      if (response.canceled) return;
      if (!response.hasError) {
        setChimneyTypes(response.records);
      } else {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      }
    });
  }, []);

  useEffect(() => {
    chimneyUseCaseService.list().then((response) => {
      if (response.canceled) return;
      if (!response.hasError) {
        setUseCases(response.records);
      } else {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      }
    });
  }, []);

  useEffect(() => {
    chimneyErrorCodeService.list().then((response) => {
      if (response.canceled) return;
      if (!response.hasError) {
        setErrorCodes(response.records);
      } else {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      }
    });
  }, []);

  useEffect(() => {
    performanceCategoryService.list().then((response) => {
      if (response.canceled) return;
      if (!response.hasError) {
        setPerformanceCategories(response.records);
      } else {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      }
    });
  }, []);

  return (
    <ChimneySweepingCertificateForm
      entity={{
        ...entity,
        employeeId: parseInt(lastSelectedChimneySweeperId.value ?? user.userId),
        date: now,
      }}
      readonly={!checkPermission(['ChimneySweepingCertificateCreate'])}
      onEmployeeSelected={(employeeId: number) => {}}
      onSubmit={onSubmit}
      errors={errors}
      clients={clients}
      onAddressAdded={(address, clientId) => {
        var client = { ...clients.find((x) => x.id === clientId) };
        client.addresses = [...client.addresses, address];
        setClients((clients) => [
          ...clients.filter((x) => x.id !== client.id),
          client,
        ]);
      }}
      onClientAdded={(client) => {
        if (clients.find((x) => x.id === client.id)) {
          setClients((clients) =>
            clients.map((x) => (x.id === client.id ? client : x))
          );
        } else {
          setClients((clients) => [...clients, client]);
        }
      }}
      representative={representative}
      setRepresentative={setRepresentative}
      handleClientSelected={handleClientSelected}
      chimneyTypes={chimneyTypes}
      useCases={useCases}
      users={users}
      chimneys={chimneys}
      coSensors={coSensors}
      errorCodes={errorCodes}
      performanceCategories={performanceCategories}
      handleCancel={() =>
        handleCancel != null ? handleCancel() : navigate(-1)
      }
      onWorkAddressSelected={(addressId) => {
        setWorkAddressId(addressId);
      }}
    />
  );
};

export default ChimneySweepingCertificateCreate;
