import TextField from "@mui/material/TextField";
import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { PropertyTypes } from "../../../../types/PropertyTypes";
import { DesktopDatePicker } from "@mui/x-date-pickers";

const ValueEditor = (props: any) => {
  const { properties, parameter, setParameter } = props;
  const property = properties.find((x) => x.id === parameter.propertyId);

  if (!property) {
    return <div>Nem található a tulajdonság</div>;
  }

  switch (property.propertyType) {
    case PropertyTypes.Text:
      return (
        <TextField
          autoFocus
          multiline
          rows={5}
          value={parameter.value}
          margin="dense"
          id="name"
          label="Érték"
          type="text"
          fullWidth
          required
          variant="standard"
          onChange={(e) =>
            setParameter((state) => {
              return { ...state, value: e.target.value };
            })
          }
        />
      );
    case PropertyTypes.Number:
      return (
        <TextField
          autoFocus
          value={parameter.value}
          itemType="number"
          margin="dense"
          id="name"
          label={`Érték (${property.unit})`}
          fullWidth
          type="number"
          required
          variant="standard"
          onChange={(e) => {
            setParameter((state) => {
              return { ...state, value: e.target.value };
            });
          }}
        />
      );
    case PropertyTypes.Boolean:
      return (
        <FormControl>
          <FormLabel id="demo-controlled-radio-buttons-group">
            Logikai érték
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={parameter.value}
            onChange={(e) =>
              setParameter((state) => {
                return {
                  ...state,
                  value: e.target.value,
                };
              })
            }
          >
            <FormControlLabel value={"true"} control={<Radio />} label="Igen" />
            <FormControlLabel value={"false"} control={<Radio />} label="Nem" />
          </RadioGroup>
        </FormControl>
      );
    case PropertyTypes.Enum:
      return (
        <Autocomplete
          disablePortal
          id="value"
          value={parameter.value}
          onChange={(event, value: number) => {
            setParameter((state) => {
              return { ...state, value: value };
            });
          }}
          options={property.unit.split(";").map((x) => x.trim())}
          renderInput={(params) => (
            <TextField {...params} fullWidth label="Válasszon értéket" />
          )}
        />
      );
    case PropertyTypes.Date:
      return (
        <DesktopDatePicker
          label="Dátum"
          inputFormat={
            property.unit != null && property.unit !== ""
              ? property.unit
              : "yyyy-MM-dd"
          }
          value={parameter.value}
          onChange={(value: Date) => {
            setParameter((state) => {
              return { ...state, value: value };
            });
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      );
  }
};

export default ValueEditor;
