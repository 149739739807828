import { Collapse, Grid } from '@mui/material';
import { useState } from 'react';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

export const CollapseMenu = (props) => {
  const [open, setOpen] = useState(!props.collapsed);

  return (
    <Box sx={{ bgcolor: '#46B7DD', color: 'white', paddingBottom: 2 }}>
      <ListItem>
        <ListItemText
          onClick={() => setOpen((x) => !x)}
          sx={{
            color: '#fff',
            userSelect: 'none',
          }}
        >
          <Grid container justifyContent="space-between">
            <Grid item style={{ fontWeight: 'bold', fontSize: '1.15em' }}>
              {props.id}
            </Grid>
            <Grid> {open ? <ExpandLess /> : <ExpandMore />}</Grid>
          </Grid>
        </ListItemText>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {props.children}
      </Collapse>
      {/* <Divider sx={{ mt: 2 }} /> */}
    </Box>
  );
};
