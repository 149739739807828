import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { pdfjs } from 'react-pdf';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PdfViewer from '../../../components/PdfViewer';
import orderService from '../../../services/sales/orderService';
import { Currency } from '../../../types/Currency';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const OrderView = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const { orderId, values = null, currency = Currency.HUF } = props;
  const dispatch = useDispatch<any>();
  const sasToken = useSelector((state: any) => state.app.sasToken);

  const [order, setOrder] = useState<any>({
    items: [],
    invoiceUrl: '',
    invoiceNumber: '',
    discount: '',
    clientId: null,
  });

  useEffect(() => {
    if (orderId > 0 ? orderId > 0 : parseInt(params.id) > 0) {
      orderService.get(orderId > 0 ? orderId : params.id).then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          setOrder({
            ...response.result,
            pdfUrl: response.result.pdfUrl + sasToken,
          });
        }
      });
    }
  }, [params.id]);

  useEffect(() => {
    if (values != null && values.companyId > 0) {
      orderService
        .getPdf({ order: values, setting: values.templateSetting })
        .then((response) => {
          if (response.canceled) return;
          if (response.hasError) {
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
          } else {
            setOrder({
              pdfUrl: base64ToBlobUrl(response.result, 'application/pdf'),
            });
          }
        });
    }
  }, [values]);

  const base64ToBlobUrl = (base64String, contentType) => {
    // Remove the data URL prefix if it's present (i.e., "data:[<mediatype>][;base64],")
    const byteCharacters = atob(base64String.split(',')[1] || base64String);

    // Create an array for the byte numbers
    const byteNumbers = new Array(byteCharacters.length);

    // Assign the byte characters to the byte array
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    // Convert the array to a Uint8Array
    const byteArray = new Uint8Array(byteNumbers);

    // Create a blob from the Uint8Array
    const blob = new Blob([byteArray], { type: contentType });

    // Create a blob URL
    const url = URL.createObjectURL(blob);
    return url;
  };

  return (
    <>
      <PdfViewer pdf={order?.pdfUrl} downloadName={`Számla`} />
    </>
  );
};

export default OrderView;
