import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Print,
  Visibility,
} from '@mui/icons-material';
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  FormControlLabel,
  Checkbox,
  SpeedDial,
} from '@mui/material';
import {
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
} from '@mui/x-data-grid';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { parseJSON } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import DataList from '../../../components/DataList';
import QrCodeReaderDialog from '../../../components/QrCodeReaderDialog';
import { useBarcodeScanner } from '../../../components/barcode-scanner/useBarcodeScanner';
import useCheckPermission from '../../../hooks/useCheckPermission';
import useConfirmDeleteDialog from '../../../hooks/useConfirmDeleteDialog';
import useFunctionDescriptor from '../../../hooks/useFunctionDescriptor';
import useHashId from '../../../hooks/useHashId';
import usePrintComponent from '../../../hooks/usePrintComponent';
import itemService from '../../../services/wms/itemService';
import itemSupplyService from '../../../services/wms/itemSupplyService';
import ItemSupplyViewDialog from './ItemSupplyViewDialog';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import warehouseService from '../../../services/wms/warehouseService';
import { useLocalStorage } from '../../../hooks/useLocalStorage';

export enum ItemSupplyStatus {
  ReadyForPickUp = 1,
  Accepted = 2,
  Rejected = 3,
}

const ItemSupplyPage = () => {
  const navigate = useNavigate();
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const [rows, setRows] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [checkboxValue, setCheckboxValue] = useState<boolean>(true);
  const dispatch = useDispatch<any>();
  const [items, setItems] = useState<any[]>([]);
  const myDataLocal = useLocalStorage('ItemSupplyPageMyData');
  const [itemSupplyViewDialogState, setItemSupplyViewDialogState] =
    useState<any>({
      open: false,
      id: 0,
    });
  const [qrCodeReaderDialogState, setQrCodeReaderDialogState] = useState<any>({
    open: false,
  });
  const titleDescriptor = useFunctionDescriptor('ItemSupplyPage.title');
  const [refreshFlag, setRefreshFlag] = useState(false);

  const { checkPermission } = useCheckPermission();
  const [qrCodeData, setQRCodeData] = useState('');
  const { encode, decode } = useHashId();

  const { printRef, handlePrint } = usePrintComponent();

  const handleQrCodePrint = (row: any) => {
    setQRCodeData(encode(row.id));
    setTimeout(() => handlePrint(), 500);
  };

  const onQrCodeScanned = (id: any) => {
    setItemSupplyViewDialogState({ open: true, id: id });
  };

  useEffect(() => {
    if (checkPermission(['ItemSupplyViewAll']) && myDataLocal.value !== null) {
      setCheckboxValue(myDataLocal.value);
    }
  }, [myDataLocal.value]);

  useEffect(() => {
    let abort = new AbortController();
    dispatch({ type: 'SHOW_QUERY' });
    if (checkPermission(['ItemSupplyViewAll']) && !checkboxValue) {
      itemSupplyService
        .list(abort.signal)
        .then((response) => {
          if (response.canceled) return;
          if (!response.hasError) {
            setRows(response.records);
          } else {
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
          }
        })
        .finally(() => dispatch({ type: 'HIDE' }));
    } else {
      itemSupplyService
        .listMy(abort.signal)
        .then((response) => {
          if (response.canceled) return;
          if (!response.hasError) {
            setRows(response.records);
          } else {
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
          }
        })
        .finally(() => dispatch({ type: 'HIDE' }));

      return () => {
        abort.abort();
      };
    }
  }, [refreshFlag, checkboxValue]);

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];
    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          <Tooltip title="Nyomtatás">
            <Print />
          </Tooltip>
        }
        label="Nyomtatás"
        onClick={() => handleQrCodePrint(params.row)}
      />
    );

    let readonly = !checkPermission(['ItemSupplyEdit']);
    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          readonly ? (
            <Tooltip title="Megtekintés">
              <Visibility />
            </Tooltip>
          ) : (
            <Tooltip title="Szerkesztés">
              <EditIcon />
            </Tooltip>
          )
        }
        label={readonly ? 'Megtekintés' : 'Szerkesztés'}
        onClick={() => navigate(`/wms/itemSupplies/edit/${params.row.id}`)}
      />
    );

    if (checkPermission(['ItemSupplyDelete'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Törlés">
              <DeleteIcon />
            </Tooltip>
          }
          label="Törlés"
          onClick={() =>
            setParams({
              open: true,
              name: '',
              onConfirm: async () => handleDelete(params.row.id),
            })
          }
        />
      );
    }

    return actions;
  };
  const columns: GridColDef[] = [
    {
      field: 'createdAt',
      headerName: 'Létrehozás ideje',
      flex: 200,
      valueFormatter: (params) => {
        return parseJSON(params.value).toLocaleString();
      },
    },
    {
      field: 'userFullName',
      headerName: 'Dolgozó',
      flex: 200,
    },
    {
      field: 'sourceWarehouseName',
      headerName: 'Forrás Raktár',
      flex: 200,
    },
    {
      field: 'targetWarehouseName',
      headerName: 'Cél Raktár',
      flex: 200,
    },
    {
      field: 'takenAt',
      headerName: 'Felvétel ideje',
      flex: 200,
      valueFormatter: (params) => {
        if (!params.value) return '';
        return parseJSON(params.value).toLocaleString();
      },
    },
    {
      field: 'rejectionComment',
      headerName: 'Elutasítás oka',
      flex: 200,
    },
    {
      field: 'comment',
      headerName: 'Megjegyzés',
      flex: 200,
    },
    {
      field: 'status',
      headerName: 'Statusz',
      flex: 200,
      renderCell: (params) => {
        return (
          <div>
            {params.value === 'Átvételre vár' ? (
              <span style={{ color: 'orange' }}>Átvételre vár</span>
            ) : params.value === 'Átvéve' ? (
              <span style={{ color: 'green' }}>Átvéve</span>
            ) : params.value === 'Elutasítva' ? (
              <span style={{ color: 'red' }}>Elutasítva</span>
            ) : null}
          </div>
        );
      },
      valueGetter(params) {
        return params.row.status === ItemSupplyStatus.ReadyForPickUp
          ? 'Átvételre vár'
          : params.row.status === ItemSupplyStatus.Accepted
            ? 'Átvéve'
            : params.row.status === ItemSupplyStatus.Rejected
              ? 'Elutasítva'
              : '';
      },
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 150,
      getActions: getActions,
    } as GridActionsColDef,
  ];

  const handleBarcodeRead = (barcode) => {
    var decodedId = decode(barcode.data);

    if (decodedId.length > 0) {
      setItemSupplyViewDialogState({ open: true, id: decodedId });
    } else {
      enqueueSnackbar(
        'Ismeretlen árucikk, ellenőrizze hogy van-e kiválasztott árkategória / raktár',
        {
          variant: 'error',
        }
      );
    }
  };

  useBarcodeScanner(handleBarcodeRead, rows, 1);

  const handleDelete = (id: number) => {
    itemSupplyService.delete(id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres törlés', {
          variant: 'success',
        });
        setRows(rows.filter((row) => row.id !== id));
      }
    });
  };

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    itemService
      .list()
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) {
          setItems(response.records);
        } else {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        }
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  const handleAccept = (
    id: number,
    allChecked: boolean,
    selectedWarehouseId: number | null
  ) => {
    if (selectedWarehouseId === null) {
      enqueueSnackbar('Válassz raktárat!', {
        variant: 'error',
      });
      return;
    }

    if (allChecked) {
      itemSupplyService.accept(id, selectedWarehouseId).then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres átvétel', {
            variant: 'success',
          });

          setRefreshFlag(true);
        }
      });
    } else {
      enqueueSnackbar('Nincs minden termék kipipálva.', {
        variant: 'error',
      });
    }
  };

  const handleReject = (id: any, comment: string) => {
    itemSupplyService.reject(id, comment).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres visszautasítás', {
          variant: 'success',
        });
        setItemSupplyViewDialogState({
          open: false,
          id: 0,
        });
        setRefreshFlag(true);
      }
    });
  };

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>Anyagfelvétel{titleDescriptor}</h2>
        </Grid>
        <Grid item style={{ display: 'none' }}>
          <QRCode ref={printRef} value={qrCodeData} />
        </Grid>
        <Grid container item xs={6} spacing={2} p={2} justifyContent="start">
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={!checkPermission(['ItemSupplyViewAll'])}
                  checked={checkboxValue}
                  onChange={(e, checked) => {
                    setCheckboxValue(checked);
                    myDataLocal.setLocalStorageValue(checked);
                  }}
                />
              }
              label={'Saját adatok'}
            />
          </Grid>
        </Grid>
        <Grid container item xs={6} p={2} justifyContent="end">
          <Grid item sx={{ display: { xs: 'none', md: 'inherit' } }}>
            <Tooltip title="QRCode olvasás">
              <IconButton
                onClick={() => {
                  setQrCodeReaderDialogState({ open: true });
                }}
              >
                <QrCodeScannerIcon color="primary" />
              </IconButton>
            </Tooltip>
          </Grid>
          {checkPermission(['ItemSupplyCreate']) && (
            <Grid item>
              <Tooltip title="Anyagfelvétel létrehozása">
                <IconButton
                  component={RouterLink}
                  to={`/wms/itemSupplies/create`}
                  color="primary"
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <DataList
            rows={rows}
            columns={columns}
            localStorageKey={'ItemSupplyPage'}
          />
        </Grid>
        <ConfirmDeleteDialog />
      </Grid>
      <ItemSupplyViewDialog
        open={itemSupplyViewDialogState.open}
        entityId={itemSupplyViewDialogState.id}
        handleClose={() => {
          setItemSupplyViewDialogState({
            open: false,
            id: 0,
          });
          // window.location.reload();
          setRefreshFlag(!refreshFlag);
        }}
        handleAccept={handleAccept}
        handleReject={handleReject}
        items={items}
      />
      <QrCodeReaderDialog
        open={qrCodeReaderDialogState.open}
        setOpen={setQrCodeReaderDialogState}
        onQrCodeScanned={onQrCodeScanned}
        decode={decode}
      />
      <SpeedDial
        ariaLabel="QR kód olvasása"
        onClick={() => setQrCodeReaderDialogState({ open: true })}
        sx={{
          display: { md: 'none' },
          position: 'fixed',
          bottom: 16,
          right: 16,
        }}
        icon={<QrCodeScannerIcon />}
      ></SpeedDial>
    </Paper>
  );
};

export default ItemSupplyPage;
