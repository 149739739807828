import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Form, Formik } from 'formik';
import { ClientTypes } from '../../../types/ClientTypes';
import { CompanyAssetStates } from '../../../types/InstrumentStates';

const ContractForm = (props: any) => {
  const {
    entity,
    onSubmit,
    errors,
    handleCancel,
    readonly = false,
    contractTypes,
    clients,
    documentBlocks,
  } = props;

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        setFieldValue,
        handleChange,
        errors: validationErrors,
      }) => (
        <Form>
          {' '}
          <Paper>
            <Grid container justifyContent="left" spacing={2} p={5}>
              <h2>
                Szerződés {values.id > 0 ? 'szerkesztése' : 'létrehozása'}
              </h2>
              <Grid xs={12} item pt={5}>
                <Box color="red">
                  {errors.map((error) => (
                    <li>{error}</li>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="documentBlockId"
                  disabled={readonly}
                  value={values?.documentBlockId ?? ''}
                  onChange={(event, value) => {
                    setFieldValue('documentBlockId', value);
                  }}
                  getOptionLabel={(option) =>
                    documentBlocks?.find((g) => g.id === option)?.name ?? ''
                  }
                  options={documentBlocks?.map((g) => g.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={
                        !!touched.documentBlockId &&
                        !!validationErrors.documentBlockId
                      }
                      helperText={
                        !!touched.documentBlockId &&
                        !!validationErrors.documentBlockId &&
                        (validationErrors.documentBlockId as string)
                      }
                      fullWidth
                      label="Bizonylattömb"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Autocomplete
                  disablePortal
                  id="contractorId"
                  disabled={values.id > 0 || readonly}
                  value={values?.contractorId ?? ''}
                  onChange={(event, value) => {
                    setFieldValue('companyId', value);
                  }}
                  getOptionLabel={(option) =>
                    clients?.find((g) => g.id === option)?.companyName ?? ''
                  }
                  options={clients
                    ?.filter((y) => y.clientType === ClientTypes.Distributor)
                    ?.map((g) => g.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      error={
                        !!touched.contractorId &&
                        !!validationErrors.contractorId
                      }
                      helperText={
                        !!touched.contractorId &&
                        !!validationErrors.contractorId &&
                        (validationErrors.contractorId as string)
                      }
                      fullWidth
                      label="Szerződtető fél"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Autocomplete
                  disablePortal
                  id="clientId"
                  disabled={values.id > 0 || readonly}
                  value={values?.clientId ?? ''}
                  onChange={(event, value) => {
                    setFieldValue('clientId', value);
                  }}
                  getOptionLabel={(option) =>
                    clients?.find((g) => g.id === option)?.companyName ?? ''
                  }
                  options={clients?.map((g) => g.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      error={!!touched.clientId && !!validationErrors.clientId}
                      helperText={
                        !!touched.clientId &&
                        !!validationErrors.clientId &&
                        (validationErrors.clientId as string)
                      }
                      fullWidth
                      label="Szerződő fél"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Autocomplete
                  disablePortal
                  id="addressId"
                  disabled={values.id > 0 || readonly}
                  value={values?.addressId ?? ''}
                  onChange={(event, value) => {
                    setFieldValue('addressId', value);
                  }}
                  getOptionLabel={(option) =>
                    clients
                      ?.find((x) => x.id === values.clientId)
                      ?.addresses.find((g) => g.id === option)?.fullAddress ??
                    ''
                  }
                  options={
                    clients
                      ?.find((x) => x.id === values.clientId)
                      ?.addresses.map((g) => g.id) ?? []
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      error={
                        !!touched.addressId && !!validationErrors.addressId
                      }
                      helperText={
                        !!touched.addressId &&
                        !!validationErrors.addressId &&
                        (validationErrors.addressId as string)
                      }
                      fullWidth
                      label="Cím"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Autocomplete
                  disablePortal
                  id="contractTypeId"
                  disabled={values.id > 0 || readonly}
                  value={values.contractTypeId ?? ''}
                  onChange={(event, value) => {
                    setFieldValue('contractTypeId', value);
                  }}
                  getOptionLabel={(option) => {
                    var found = contractTypes?.find((g) => g.id === option);
                    if (found) {
                      return `${found.name}`;
                    } else return '';
                  }}
                  options={contractTypes?.map((g) => g.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Szerződés típusa"
                      required
                      fullWidth
                      error={
                        !!touched.contractTypeId &&
                        !!validationErrors.contractTypeId
                      }
                      helperText={
                        !!touched.contractTypeId &&
                        !!validationErrors.contractTypeId &&
                        (validationErrors.contractTypeId as string)
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.documentNumber}
                  onChange={handleChange}
                  label="Szerződés száma"
                  name="documentNumber"
                  disabled={readonly || values?.documentBlockId > 0}
                  fullWidth
                  error={
                    !!touched.documentNumber &&
                    !!validationErrors.documentNumber
                  }
                  helperText={
                    !!touched.documentNumber &&
                    !!validationErrors.documentNumber &&
                    (validationErrors.documentNumber as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.name}
                  onChange={handleChange}
                  label="Név"
                  name="name"
                  required
                  InputLabelProps={{ shrink: true }}
                  disabled={readonly}
                  fullWidth
                  error={!!touched.name && !!validationErrors.name}
                  helperText={
                    !!touched.name &&
                    !!validationErrors.name &&
                    (validationErrors.name as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.description}
                  onChange={handleChange}
                  label="Leírás"
                  name="description"
                  multiline
                  InputLabelProps={{ shrink: true }}
                  disabled={readonly}
                  fullWidth
                  error={
                    !!touched.description && !!validationErrors.description
                  }
                  helperText={
                    !!touched.description &&
                    !!validationErrors.description &&
                    (validationErrors.description as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.discount}
                  onChange={handleChange}
                  label="Kedvezmény"
                  name="discount"
                  required
                  type="number"
                  InputLabelProps={{ shrink: true }}
                  disabled={readonly}
                  fullWidth
                  error={!!touched.discount && !!validationErrors.discount}
                  helperText={
                    !!touched.discount &&
                    !!validationErrors.discount &&
                    (validationErrors.discount as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  label="Lemondva"
                  control={
                    <Checkbox
                      checked={values.cancelled}
                      disabled={readonly}
                      onChange={(e, checked) =>
                        setFieldValue('cancelled', checked)
                      }
                    />
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={'auto'} p={1}>
                <DatePicker
                  label="Szerződés kötés dátuma"
                  disabled={readonly}
                  value={values.contractDate ?? ''}
                  onChange={(date) => setFieldValue('contractDate', date)}
                  renderInput={(props) => <TextField {...props} />}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={'auto'} p={1}>
                <DatePicker
                  label="Szerződés lejárata"
                  disabled={readonly}
                  value={values.expirationDate}
                  onChange={(date) => setFieldValue('expirationDate', date)}
                  renderInput={(props) => <TextField {...props} />}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={'auto'} p={1}>
                <DatePicker
                  label="Szerződés felmondásának dátuma"
                  disabled={readonly}
                  value={values.cancelledAt}
                  onChange={(date) => setFieldValue('cancelledAt', date)}
                  renderInput={(props) => <TextField {...props} />}
                />
              </Grid>
              <Grid container item pt={3} justifyContent="left">
                {!readonly && (
                  <Grid item p={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      Mentés
                    </Button>
                  </Grid>
                )}
                <Grid item p={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleCancel}
                  >
                    Mégse
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default ContractForm;
