import { Grid, TextField } from '@mui/material';

const NameForm = (props: any) => {
  const {
    values,
    setFieldValue,
    errors,
    touched,
    readonly = false,
    showDifference = () => {},
    entity = {},
  } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3}>
        <TextField
          value={values.name?.title ?? ''}
          onChange={(e) => setFieldValue('name.title', e.target.value)}
          label="Titulus"
          name="title"
          fullWidth
          disabled={readonly}
          error={!!touched.name?.title && !!errors.name?.title}
          helperText={
            !!touched.name?.title && !!errors.name?.title && errors.name?.title
          }
        />
        {showDifference(
          `${entity?.name?.title ?? ''}`,
          `${values.name?.title ?? ''}`
        )}
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          value={values.name?.firstName ?? ''}
          onChange={(e) => setFieldValue('name.firstName', e.target.value)}
          label="Vezetéknév"
          name="firstName"
          disabled={readonly}
          fullWidth
          error={!!touched.name?.firstName && !!errors.name?.firstName}
          helperText={
            !!touched.name?.firstName &&
            !!errors.name?.firstName &&
            errors.name?.firstName
          }
        />
        {showDifference(
          `${entity?.name?.firstName ?? ''}`,
          `${values.name?.firstName ?? ''}`
        )}
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          value={values.name?.lastName ?? ''}
          fullWidth
          onChange={(e) => setFieldValue('name.lastName', e.target.value)}
          label="Keresztnév"
          name="firstName"
          disabled={readonly}
          error={!!touched.name?.lastName && !!errors.name?.lastName}
          helperText={
            !!touched.name?.lastName &&
            !!errors.name?.lastName &&
            errors.name?.lastName
          }
        />
        {showDifference(
          `${entity?.name?.lastName ?? ''}`,
          `${values.name?.lastName ?? ''}`
        )}
      </Grid>
    </Grid>
  );
};

export default NameForm;
