import { createReducer } from "@reduxjs/toolkit";
import { reset, setForm } from "../actions/offerActions";

const initialState = {
  form: {},
  offerInProgress: false,
};

export const offerReducer = createReducer(initialState, (builder) => {
  builder.addCase(setForm, (state, action) => {
    state.form = action.payload;
    state.offerInProgress = true;
  });
  builder.addCase(reset, (state, action) => {
    state.form = {};
    state.offerInProgress = false;
  });
});
