import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ServiceForm from './ServiceForm';
import { useNavigate } from 'react-router-dom';
import useCheckPermission from '../../../hooks/useCheckPermission';
import serviceService from '../../../services/erp/serviceService';
import serviceCategoryService from '../../../services/erp/serviceCategoryService';
import taxTypeService from '../../../services/erp/taxTypeService';

const ServiceCreate = (props: any) => {
  const { onSave = null, navigateBack = true, handleCancel = null } = props;

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<string[]>([]);
  const [serviceCategories, setServiceCategories] = useState<string[]>([]);
  const [taxes, setTaxes] = useState<any[]>([]);

  const { checkPermission } = useCheckPermission();
  useEffect(() => {
    serviceCategoryService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setServiceCategories(response.records);
      }
    });
  }, []);

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    serviceService
      .create(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });

          if (onSave) onSave(response.result);
          if (navigateBack) navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        setSubmitting(false);
      });
  };

  useEffect(() => {
    taxTypeService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setTaxes(response.records);
      }
    });
  }, []);

  return (
    <ServiceForm
      entity={{
        name: '',
        description: '',
        servicePriceNet: 0,
        acquisitionPriceNet: 0,
        serviceCategoryId: 0,
        unitOfQuantity: '',
        taxTypeId: 0,
      }}
      onNewServiceCategoryAdded={(serviceCategory) => {
        setServiceCategories([...serviceCategories, serviceCategory]);
      }}
      serviceCategories={serviceCategories}
      readonly={!checkPermission(['ServiceCreate'])}
      onSubmit={onSubmit}
      errors={errors}
      taxes={taxes}
      handleCancel={() =>
        handleCancel != null ? handleCancel() : navigate(-1)
      }
    />
  );
};

export default ServiceCreate;
