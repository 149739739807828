import {
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import userService from '../../../services/authority/userService';
import chimneyControlAppointmentService from '../../../services/chimneySweeping/chimneyControlAppointmentService';
import { ChimneyControlAppointmentStatuses } from '../../../types/ChimneyControlAppointmentStatuses';
import { translateChimneyControlAppointmentStatusesName } from '../../../utils/nameFormatters';
import useConfirmOfferDialog from '../../../hooks/useConfirmOfferDialog';

const ChimneyControlAppointmentDialog = (props: any) => {
  const {
    entity,
    onSubmit,
    readonly = false,
    open,
    onClose,
    chimneySweepingCertificateId,
    employeeId,
    isOfferMandatory,
    specialNeeds,
    hasClientSpecialNeeds,
  } = props;

  const [users, setUsers] = React.useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [values, setValues] = React.useState<any>({
    id: 0,
    description: '',
    chimneySweepingCertificateId: 0,
    lastControlYear: 0,
    employeeId: 0,
    appointmentDate: null,
    lastControl: null,
    status: ChimneyControlAppointmentStatuses.Planned,
  });
  const { ConfirmOfferDialog, setOfferParams } = useConfirmOfferDialog();

  useEffect(() => {
    if (employeeId > 0) {
      setValues((prevValues) => ({ ...prevValues, employeeId: employeeId }));
    }
  }, [employeeId]);

  useEffect(() => {
    if (entity.id > 0) {
      setValues(entity);
    }
  }, [entity]);

  useEffect(() => {
    if (chimneySweepingCertificateId) {
      setValues((prevValues) => ({
        ...prevValues,
        chimneySweepingCertificateId: chimneySweepingCertificateId,
      }));
    }
  }, [chimneySweepingCertificateId]);

  useEffect(() => {
    userService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setUsers(response.records);
      }
    });
  }, []);

  const handleClose = () => {
    setValues({
      id: 0,
      description: '',
      chimneySweepingCertificateId: 0,
      lastControlYear: 0,
      employeeId: 0,
      appointmentDate: null,
      lastControl: null,
      status: ChimneyControlAppointmentStatuses.Planned,
    });
    onClose();
  };

  const handleCreate = (values: any) => {
    chimneyControlAppointmentService.create(values).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres mentés!', {
          variant: 'success',
        });
        onSubmit(response.result);
        handleClose();
      }
    });
  };
  const handleUpdate = (values: any) => {
    chimneyControlAppointmentService.update(values).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres mentés!', {
          variant: 'success',
        });
        onSubmit(response.result);
        handleClose();
      }
    });
  };

  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <DialogContent sx={{ p: 0 }}>
        <Paper>
          <Grid container justifyContent="left" spacing={2} p={5}>
            <h2>
              Kémény ellenőrzés egyeztetés{' '}
              {values.id > 0 ? 'szerkesztése' : 'létrehozása'}
            </h2>
            <Grid item xs={12}>
              <Autocomplete
                disablePortal
                id="status"
                value={values.status}
                disabled={readonly}
                onChange={(event, value) => {
                  setValues({ ...values, status: value });
                }}
                getOptionLabel={(option) => {
                  return translateChimneyControlAppointmentStatusesName(
                    option as ChimneyControlAppointmentStatuses
                  );
                }}
                options={
                  Object.values(ChimneyControlAppointmentStatuses).filter((x) =>
                    Number.isFinite(x)
                  ) ?? []
                }
                renderInput={(params) => (
                  <TextField {...params} required label="Státusz" />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                disablePortal
                id="employeeId"
                disabled={readonly}
                value={values.employeeId ?? ''}
                onChange={(event, value) => {
                  setValues({ ...values, employeeId: value });
                }}
                getOptionLabel={(option) => {
                  var found = users.find((g) => g.id === option);
                  if (found) {
                    return `${found.id}.${found.fullName}`;
                  } else return '';
                }}
                options={users.map((g) => g.id)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Kéményseprő"
                    required
                    fullWidth
                  />
                )}
              />
            </Grid>
            {values.status ===
              ChimneyControlAppointmentStatuses.CompletedByOther && (
              <>
                <Grid item xs={12}>
                  <TextField
                    value={values.lastControlYear}
                    onChange={(event) => {
                      setValues({
                        ...values,
                        lastControlYear: event.target.value,
                      });
                    }}
                    label="Utolsó ellenőrzés éve"
                    name="lastControlYear"
                    type="number"
                    disabled={readonly}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <DatePicker
                    label="Utolsó ellenőrzés dátuma"
                    value={values.lastControl}
                    disabled={readonly}
                    onChange={(date) =>
                      setValues({
                        ...values,
                        lastControl: date,
                      })
                    }
                    renderInput={(props) => <TextField fullWidth {...props} />}
                  />
                </Grid>
              </>
            )}
            {values.status !==
              ChimneyControlAppointmentStatuses.CompletedByOther && (
              <Grid item xs={12}>
                <DatePicker
                  label="Egyeztetett időpont"
                  value={values.appointmentDate}
                  disabled={readonly}
                  onChange={(date) =>
                    setValues({
                      ...values,
                      appointmentDate: date,
                    })
                  }
                  renderInput={(props) => <TextField fullWidth {...props} />}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                value={values.description}
                onChange={(event) => {
                  setValues({ ...values, description: event.target.value });
                }}
                label="Leírás"
                name="description"
                InputLabelProps={{ shrink: true }}
                disabled={readonly}
                fullWidth
                multiline
                minRows={3}
              />
            </Grid>
            {hasClientSpecialNeeds && (
              <Grid item xs={12}>
                <TextField
                  value={specialNeeds}
                  label="Ügyfél különleges igényei"
                  name="description"
                  InputLabelProps={{ shrink: true }}
                  disabled
                  fullWidth
                  multiline
                  minRows={3}
                />
              </Grid>
            )}
            <Grid container item pt={3} justifyContent="left">
              {!readonly && (
                <Grid item p={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={readonly}
                    onClick={() => {
                      if (isOfferMandatory) {
                        setOfferParams({
                          open: true,
                          name: 'Kérjük ellenőrizze hogy készült-e árajánlat számlázást megelőzően!',
                          onConfirm: async () => {
                            if (values.id > 0) {
                              handleUpdate(values);
                            } else {
                              handleCreate(values);
                            }
                          },
                        });
                      } else {
                        if (values.id > 0) {
                          handleUpdate(values);
                        } else {
                          handleCreate(values);
                        }
                      }
                    }}
                  >
                    Mentés
                  </Button>
                </Grid>
              )}
              <Grid item p={1}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => handleClose()}
                >
                  Mégse
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <ConfirmOfferDialog />
        </Paper>
      </DialogContent>
    </Dialog>
  );
};

export default ChimneyControlAppointmentDialog;
