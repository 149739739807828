import {
  Button,
  TextField,
  Box,
  Grid,
  Paper,
  InputAdornment,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";

const TaxTypeForm = (props: any) => {
  const { entity, onSubmit, errors, handleCancel, readonly = false } = props;

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};
        if (!values.name) {
          errors.name = "Required";
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        handleChange,
        errors: validationErrors,
      }) => (
        <Form>
          {" "}
          <Paper>
            <Grid container justifyContent="left" spacing={2} p={5}>
              <h2>Adótípus {values.id > 0 ? "szerkesztése" : "létrehozása"}</h2>
              <Grid xs={12} item pt={5}>
                <Box color="red">
                  {errors.map((error) => (
                    <li>{error}</li>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.name}
                  onChange={handleChange}
                  label="Megnevezés"
                  name="name"
                  required
                  disabled={readonly}
                  fullWidth
                  autoFocus
                  error={!!touched.name && !!validationErrors.name}
                  helperText={
                    !!touched.name &&
                    !!validationErrors.name &&
                    (validationErrors.name as string)
                  }
                />
                <Typography variant="caption">
                  A megnevezésnek megkell egyeznie a számlázz.hu által használt
                  számla előtaggal. pl.(AAM,F.AFA) vagy egész számnak kell
                  lennie.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.value}
                  onChange={handleChange}
                  label="Érték"
                  name="value"
                  required
                  type="number"
                  InputLabelProps={{ shrink: true }}
                  disabled={readonly}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  error={!!touched.value && !!validationErrors.value}
                  helperText={
                    !!touched.value &&
                    !!validationErrors.value &&
                    (validationErrors.value as string)
                  }
                />
              </Grid>
              <Grid container item pt={3} justifyContent="left">
                {!readonly && (
                  <Grid item p={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      Mentés
                    </Button>
                  </Grid>
                )}
                <Grid item p={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleCancel}
                  >
                    Mégse
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default TaxTypeForm;
