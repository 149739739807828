import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useCheckPermission from '../../../hooks/useCheckPermission';
import userService from '../../../services/authority/userService';
import workItemService from '../../../services/erp/workItemService';
import itemService from '../../../services/wms/itemService';
import itemSupplyService from '../../../services/wms/itemSupplyService';
import warehouseService from '../../../services/wms/warehouseService';
import ItemSupplyForm from './ItemSupplyForm';

const ItemSupplyCreate = (props: any) => {
  const { onSave = null, navigateBack = true, handleCancel = null } = props;

  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<string[]>([]);
  const [warehouses, setWarehouses] = useState<any[]>([]);
  const [users, setUsers] = useState<any[]>([]);
  const [items, setItems] = useState<any[]>([]);
  const [entity, setEntity] = useState<any>({
    items: [],
    sourceWarehouseId: null,
    targetWarehouseId: null,
    userId: null,
    workItemId: params.workItemId,
    comment: '',
  });
  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    if (parseInt(params.workItemId) > 0) {
      var items;
      var sourceWarehouseId;
      let workItemNumber;
      workItemService.get(params.workItemId).then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          sourceWarehouseId = response.result.warehouseId;
          workItemNumber = response.result.workItemNumber;
          items =
            response.result.selectedItems?.map((g: any) => ({
              itemId: g.itemId,
              amount: g.amount,
            })) ?? [];

          response.result.itemPackages?.forEach((element) => {
            element?.items?.forEach(
              (g: any) =>
                (items = [...items, { itemId: g.itemId, amount: g.amount }])
            );
          });

          workItemService
            .listWorkItemHistory(params.workItemId)
            .then((response2) => {
              if (response2.hasError) {
                enqueueSnackbar(response2.errorMessages.join(','), {
                  variant: 'error',
                });
              } else {
                var mechanicId = response2.records
                  .filter((x) => x.roleName === 'Mechanic')
                  .map((x) => x.userId)
                  .pop();
                setEntity({
                  ...entity,
                  userId: mechanicId,
                  sourceWarehouseId: sourceWarehouseId,
                  items: items,
                  comment: workItemNumber,
                });
              }
            });
        }
      });
    }
  }, []);

  useEffect(() => {
    warehouseService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setWarehouses(response.records);
      }
    });
  }, []);

  useEffect(() => {
    userService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setUsers(response.records);
      }
    });
  }, []);

  const handleLoadItemPrices = (warehouseId: number | null) => {
    if (warehouseId === 0) return;
    warehouseService.listItems(warehouseId, true).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setItems(response.records.map((x: any) => ({ ...x, id: x.itemId })));
      }
    });
  };

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    itemSupplyService
      .create(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });

          if (onSave) onSave(response.result);
          if (navigateBack) navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        setSubmitting(false);
      });
  };

  return (
    <ItemSupplyForm
      entity={entity}
      readonly={!checkPermission(['ItemSupplyCreate'])}
      onSubmit={onSubmit}
      handleLoadItemPrices={handleLoadItemPrices}
      items={items ?? []}
      warehouses={warehouses}
      users={users}
      errors={errors}
      handleCancel={() =>
        handleCancel != null ? handleCancel() : navigate(-1)
      }
    />
  );
};

export default ItemSupplyCreate;
