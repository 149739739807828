import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Autocomplete,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { ClientTypes } from '../../../types/ClientTypes';
import { DatePicker } from '@mui/x-date-pickers';
import { CompanyAssetStates } from '../../../types/InstrumentStates';

const ToolForm = (props: any) => {
  const {
    entity,
    onSubmit,
    errors,
    handleCancel,
    readonly = false,
    clients,
    users,
    warehouses,
  } = props;

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};
        if (!values.name) {
          errors.name = 'Required';
        }
        if (!values.other) {
          errors.other = 'Required';
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        setFieldValue,
        handleChange,
        errors: validationErrors,
      }) => (
        <Form>
          {' '}
          <Paper>
            <Grid container justifyContent="left" spacing={2} p={5}>
              <h2>Szerszám {values.id > 0 ? 'szerkesztése' : 'létrehozása'}</h2>
              <Grid xs={12} item pt={5}>
                <Box color="red">
                  {errors.map((error) => (
                    <li>{error}</li>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Autocomplete
                  disablePortal
                  id="companyId"
                  disabled={values.id > 0 || readonly}
                  value={values?.companyId ?? ''}
                  onChange={(event, value) => {
                    setFieldValue('companyId', value);
                  }}
                  getOptionLabel={(option) =>
                    clients?.find((g) => g.id === option)?.companyName ?? ''
                  }
                  options={clients
                    ?.filter((y) => y.clientType === ClientTypes.Distributor)
                    .map((g) => g.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      error={
                        !!touched.companyId && !!validationErrors.companyId
                      }
                      helperText={
                        !!touched.companyId &&
                        !!validationErrors.companyId &&
                        (validationErrors.companyId as string)
                      }
                      fullWidth
                      label="Cég"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Autocomplete
                  disablePortal
                  id="holderId"
                  disabled={values.id > 0 || readonly}
                  value={values.holderId ?? ''}
                  onChange={(event, value) => {
                    setFieldValue('holderId', value);
                  }}
                  getOptionLabel={(option) => {
                    var found = users.find((g) => g.id === option);
                    if (found) {
                      return `${found.id}.${found.fullName}`;
                    } else return '';
                  }}
                  options={users.map((g) => g.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Felelős"
                      required
                      fullWidth
                      error={!!touched.holderId && !!validationErrors.holderId}
                      helperText={
                        !!touched.holderId &&
                        !!validationErrors.holderId &&
                        (validationErrors.holderId as string)
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Autocomplete
                  disablePortal
                  id="warehouseId"
                  disabled={values.id > 0 || readonly}
                  value={values.warehouseId ?? ''}
                  onChange={(event, value) => {
                    setFieldValue('warehouseId', value);
                  }}
                  getOptionLabel={(option) => {
                    var found = warehouses.find((g) => g.id === option);
                    if (found) {
                      return `${found.name}`;
                    } else return '';
                  }}
                  options={warehouses.map((g) => g.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Raktár"
                      required
                      fullWidth
                      error={
                        !!touched.warehouseId && !!validationErrors.warehouseId
                      }
                      helperText={
                        !!touched.warehouseId &&
                        !!validationErrors.warehouseId &&
                        (validationErrors.warehouseId as string)
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.name}
                  onChange={handleChange}
                  label="Név"
                  name="name"
                  required
                  disabled={readonly}
                  fullWidth
                  autoFocus
                  error={!!touched.name && !!validationErrors.name}
                  helperText={
                    !!touched.name &&
                    !!validationErrors.name &&
                    (validationErrors.name as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.other}
                  onChange={handleChange}
                  label="Egyéb"
                  name="other"
                  multiline
                  InputLabelProps={{ shrink: true }}
                  disabled={readonly}
                  fullWidth
                  error={!!touched.other && !!validationErrors.other}
                  helperText={
                    !!touched.other &&
                    !!validationErrors.other &&
                    (validationErrors.other as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.amount}
                  onChange={handleChange}
                  label="Darabszám"
                  name="amount"
                  required
                  disabled={readonly}
                  fullWidth
                  type="number"
                  error={!!touched.amount && !!validationErrors.amount}
                  helperText={
                    !!touched.amount &&
                    !!validationErrors.amount &&
                    (validationErrors.amount as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.netPrice}
                  onChange={handleChange}
                  label="Nettó ár"
                  name="netPrice"
                  required
                  type="number"
                  InputLabelProps={{ shrink: true }}
                  disabled={readonly}
                  fullWidth
                  error={!!touched.netPrice && !!validationErrors.netPrice}
                  helperText={
                    !!touched.netPrice &&
                    !!validationErrors.netPrice &&
                    (validationErrors.netPrice as string)
                  }
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Autocomplete
                  disablePortal
                  id="state"
                  value={values?.state ?? ''}
                  getOptionLabel={(option) => {
                    return option === CompanyAssetStates.Scrapped
                      ? 'Selejtezett'
                      : option === CompanyAssetStates.WaitingForFix
                      ? 'Javításra vár'
                      : option === CompanyAssetStates.Working
                      ? 'Működik'
                      : 'Javítás alatt';
                  }}
                  options={[
                    CompanyAssetStates.Scrapped,
                    CompanyAssetStates.WaitingForFix,
                    CompanyAssetStates.Working,
                    CompanyAssetStates.UnderFixing,
                  ]}
                  onChange={(event, newValue) => {
                    setFieldValue('state', newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label="Állapot"
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={'auto'} p={1}>
                <DatePicker
                  label="Vásárlás dátuma"
                  value={values.purchaseDate ?? ''}
                  onChange={(date) => setFieldValue('purchaseDate', date)}
                  renderInput={(props) => <TextField {...props} />}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={'auto'} p={1}>
                <DatePicker
                  label="Garancia lejárata"
                  value={values.guarantee ?? ''}
                  onChange={(date) => setFieldValue('guarantee', date)}
                  renderInput={(props) => <TextField {...props} />}
                />
              </Grid>
              <Grid container item pt={3} justifyContent="left">
                {!readonly && (
                  <Grid item p={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      Mentés
                    </Button>
                  </Grid>
                )}
                <Grid item p={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleCancel}
                  >
                    Mégse
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default ToolForm;
