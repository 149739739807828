import { Autocomplete, Checkbox, FormControlLabel, Grid } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AutocompleteLCS from '../../components/AutocompleteLCS';
import CreateEntityDecorator from '../../components/CreateEntityDecorator';
import useCheckPermission from '../../hooks/useCheckPermission';
import useFunctionDescriptor from '../../hooks/useFunctionDescriptor';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import {
  setCompanyId,
  setPaymentType,
  setClientId,
} from '../../stateManagement/actions/salesActions';
import { ClientTypes } from '../../types/ClientTypes';
import ClientCreate from '../crm/clients/ClientCreate';
import * as fromClients from '../../stateManagement/thunks/clientsThunk';

const filterOptions = createFilterOptions<any>({
  matchFrom: 'any',
  stringify: (option: any) => {
    return getOptionLabel(option);
  },
});

function getOptionLabel(option: any) {
  var billingAddress = getBillingAddress(option);

  var billingAddressText =
    billingAddress != null
      ? `${billingAddress?.street} ${billingAddress?.houseNumber}`
      : '';

  if (option.companyName)
    return `${option.id}. ${getFullName(option)} (${
      option.companyName
    }, ${billingAddressText} , ${option.taxNumber})`?.trim();

  if (billingAddressText)
    return `${option.id}. ${getFullName(
      option
    )} (${billingAddressText})`?.trim();

  return `${option.id}. ${getFullName(option)?.trim()}`;
}

function getFullName(option: any) {
  return `${option.name.title?.trim()} ${option.name.firstName?.trim()} ${option.name.lastName?.trim()}`;
}

export default function InvoiceInfoDialog(props: any) {
  const {
    open,
    setOpen,
    onSubmit,
    onClientAdded,
    clients = [],
    invoiceNumberPrefixes = [],
    workItemClient,
    documentBlocks,
    isElectronic = false,
  } = props;
  const [selectedPartner, setSelectedPartner] = React.useState({
    name: { title: '', firstName: '', lastName: '' },
    companyName: '',
    addresses: [],
  });
  const {
    paymentType,
    companyId,
    clientId,
    documentBlockId,
    dueDate,
    fulfillmentDate,
    paid,
    addressId,
  } = useSelector<any>((state) => state.sales) as any;

  const dispatch = useDispatch();
  const [selectedAddressId, setSelectedAddressId] = React.useState<any>(null);

  const defaultCompanyId = useLocalStorage('invoiceDefaultClientId');
  const defaultInvoiceNumberPrefix = useLocalStorage(
    'invoiceDefaultInvoiceNumberPrefix'
  );

  React.useEffect(() => {
    setInvoiceInfo((x: any) => {
      return {
        ...x,
        paymentType: paymentType,
        dueDate:
          paymentType === 'Átutalás'
            ? moment(new Date()).add(8, 'days').toDate()
            : moment(new Date()).toDate(),
        paid:
          paymentType === 'Készpénz' || paymentType === 'Bankkártya'
            ? true
            : false,
      };
    });
  }, [paymentType]);

  React.useEffect(() => {
    setInvoiceInfo((x: any) => {
      return {
        ...x,
        fulFillmentDate: fulfillmentDate,
      };
    });
  }, [fulfillmentDate]);
  React.useEffect(() => {
    setInvoiceInfo((x: any) => {
      return {
        ...x,
        dueDate: dueDate,
      };
    });
  }, [dueDate]);

  React.useEffect(() => {
    if (clientId > 0) {
      handlePartnerChange(clients?.find((x) => x.id === clientId));
    } else {
      setSelectedPartner(null);
      setInvoiceInfo((x: any) => {
        return {
          ...x,
          customerName: '',
          customerPostalCode: '',
          customerCity: '',
          customerStreetAddress: '',
          customerTaxNumber: '',
          customerPhoneNumber: '',
          customerComment: '',
          customerEmailAddress: '',
          clientId: 0,
        };
      });
    }
  }, [clientId, clients]);

  React.useEffect(() => {
    if (workItemClient) {
      let client = clients?.find((x) => x.id === workItemClient);
      handlePartnerChange(client);
    }
  }, [workItemClient, clients]);

  React.useEffect(() => {
    setInvoiceInfo((x) => {
      return {
        ...x,
        companyId: companyId > 0 ? companyId : defaultCompanyId.value,
        invoiceNumberPrefix: defaultInvoiceNumberPrefix.value,

        bankAccountNumber:
          clients?.find(
            (g) => g.id === (companyId > 0 ? companyId : defaultCompanyId.value)
          )?.bankAccountNumber ?? '',
      };
    });
  }, [
    defaultCompanyId.value,
    defaultInvoiceNumberPrefix.value,
    clients,
    companyId,
  ]);

  const [invoiceInfo, setInvoiceInfo] = React.useState({
    clientId: 0,
    companyId: defaultCompanyId.value ?? 0,
    customerName: '',
    bankAccountNumber:
      clients?.find(
        (g) => g.id === (companyId > 0 ? companyId : defaultCompanyId.value)
      )?.bankAccountNumber ?? '',
    customerPostalCode: '',
    customerCity: '',
    customerStreetAddress: '',
    customerTaxNumber: '',
    customerPhoneNumber: '',
    customerComment: '',
    customerEmailAddress: '',
    customerSendEmail: false,
    isElectronic: false,
    paid: paid,
    addressId: addressId ?? 0,
    dueDate: dueDate ?? new Date(),
    fulFillmentDate: fulfillmentDate ?? new Date(),
    paymentType: paymentType ?? 'Készpénz',
    invoiceNumberPrefix: defaultInvoiceNumberPrefix.value ?? '',
    documentBlockId: documentBlockId ?? null,
  });

  const { enqueueSnackbar } = useSnackbar();
  const { checkPermission } = useCheckPermission();
  const handleClose = () => {
    setOpen(false);
  };

  const titleDescriptor = useFunctionDescriptor('InvoiceInfoDialog.title');

  React.useEffect(() => {
    console.log('documentBlocks', documentBlocks);
  }, [documentBlocks]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogTitle>Számla{titleDescriptor}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Divider textAlign="left" sx={{ mb: 2 }}>
              SZÁMLAKIBOCSÁTÓ
            </Divider>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              disablePortal
              id="documentBlockId"
              value={invoiceInfo.documentBlockId ?? ''}
              onChange={(event, value) => {
                let found = documentBlocks?.find((g) => g.id === value);
                if (found) {
                  setInvoiceInfo((x: any) => {
                    return {
                      ...x,
                      invoiceNumberPrefix: found.prefix,
                    };
                  });
                }
                setInvoiceInfo((x: any) => {
                  return {
                    ...x,
                    documentBlockId: value,
                  };
                });
              }}
              getOptionLabel={(option) =>
                documentBlocks?.find((g) => g.id === option)?.name ?? ''
              }
              options={documentBlocks?.map((g) => g.id)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  required
                  label="Bizonylattömb"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              disablePortal
              id="companyId"
              fullWidth
              value={invoiceInfo.companyId}
              onChange={(event, value) => {
                defaultCompanyId.setLocalStorageValue(value);
                setInvoiceInfo((x: any) => {
                  return { ...x, companyId: value };
                });
                dispatch(setCompanyId(value));
              }}
              getOptionLabel={(option) => {
                var found = clients?.find((g) => g.id === option);
                if (found) {
                  return `${found.companyName} (${found.taxNumber})}`;
                } else return 'Nem található';
              }}
              options={(clients ?? [])
                .filter((x) => x.clientType === ClientTypes.Distributor)
                .map((g) => g.id)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Kiállító cég"
                  required
                  fullWidth
                  error={!invoiceInfo.companyId}
                  helperText={!invoiceInfo.companyId && 'Kötelező mező'}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Bankszámlaszám"
              fullWidth
              value={invoiceInfo.bankAccountNumber}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <Divider textAlign="left" sx={{ mb: 2 }}>
              PARTNER
            </Divider>
          </Grid>
          <Grid item xs={12} md={9}>
            <CreateEntityDecorator
              hideAdd={checkPermission(['ClientAdd'])}
              AutocompleteComponent={
                <Autocomplete
                  disablePortal
                  id="clientId"
                  value={selectedPartner}
                  onChange={(event, value) => {
                    handlePartnerChange(value);
                  }}
                  getOptionLabel={(option: any) => {
                    if (option === null) {
                      return 'Mindegy';
                    }
                    var found = option;
                    if (found) {
                      if (!found.isPrivatePerson) {
                        return `${found.id}. ${found.companyName} (${found.taxNumber})`;
                      }
                      return `${found.id}. ${found.name.fullName} (${found.companyName},${found.taxNumber})}`;
                    } else return '';
                  }}
                  options={clients ?? []}
                  filterOptions={(options, params) => {
                    // Convert the search term to lowercase for case-insensitive search
                    const searchTermLower = params.inputValue?.toLowerCase();

                    // Perform a fuzzy search by splitting the search term into tokens
                    const searchTokens = searchTermLower?.split(/\s+/);

                    // Filter items based on the search tokens
                    const filteredItems = options?.filter((client) => {
                      // Convert the item name to lowercase
                      let name = '';
                      if (!client.isPrivatePerson) {
                        name = `${client.id}. ${client.companyName} (${client.taxNumber})`;
                      } else {
                        name = `${client.id}. ${client.name.fullName} (${client.companyName},${client.taxNumber})}`;
                      }
                      const itemNameLower = name.toLowerCase();

                      // Check if each token is present in the item name
                      return searchTokens?.every((token) =>
                        itemNameLower?.includes(token)
                      );
                    });

                    return filteredItems;
                  }}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth label="Ügyfél" />
                  )}
                />
              }
              onSave={(client) => {
                onClientAdded(client);
                handlePartnerChange(client);
              }}
              CreateEntityComponent={ClientCreate}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label="Adószám"
              fullWidth
              value={invoiceInfo.customerTaxNumber}
              onChange={(e) =>
                setInvoiceInfo((x: any) => {
                  return { ...x, customerTaxNumber: e.target.value };
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Divider textAlign="left" sx={{ mb: 2 }}>
              Számlázási cím
            </Divider>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              disablePortal
              id="billingAddressId"
              value={selectedAddressId}
              onChange={(event, value) => {
                setSelectedAddressId(value);
                let found = selectedPartner?.addresses?.find(
                  (g) => g.id === value
                );
                if (found) {
                  setInvoiceInfo((x: any) => {
                    return {
                      ...x,
                      customerPostalCode: found.postalCode,
                      customerCity: found.city,
                      customerStreetAddress: `${found.street} ${found.houseNumber}`,
                      addressId: value,
                    };
                  });
                }
              }}
              getOptionLabel={(option) =>
                selectedPartner?.addresses?.find((g) => g.id === option)
                  ?.fullAddress ?? ''
              }
              options={selectedPartner?.addresses?.map((g) => g.id)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Számlázási cím"
                  required
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label="Irányítószám"
              required
              fullWidth
              value={invoiceInfo.customerPostalCode}
              onChange={(e) =>
                setInvoiceInfo((x: any) => {
                  return { ...x, customerPostalCode: e.target.value };
                })
              }
              error={!invoiceInfo.customerPostalCode}
              helperText={!invoiceInfo.customerPostalCode && 'Kötelező mező'}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label="Város"
              required
              fullWidth
              value={invoiceInfo.customerCity}
              onChange={(e) =>
                setInvoiceInfo((x: any) => {
                  return { ...x, customerCity: e.target.value };
                })
              }
              error={!invoiceInfo.customerCity}
              helperText={!invoiceInfo.customerCity && 'Kötelező mező'}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Utca, házszám"
              required
              fullWidth
              value={invoiceInfo.customerStreetAddress}
              onChange={(e) =>
                setInvoiceInfo((x: any) => {
                  return { ...x, customerStreetAddress: e.target.value };
                })
              }
              error={!invoiceInfo.customerStreetAddress}
              helperText={!invoiceInfo.customerStreetAddress && 'Kötelező mező'}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              label="Telefonszám"
              fullWidth
              type="text"
              value={invoiceInfo.customerPhoneNumber}
              onChange={(e) =>
                setInvoiceInfo((x: any) => {
                  return { ...x, customerPhoneNumber: e.target.value };
                })
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              label="Email"
              fullWidth
              type="email"
              value={invoiceInfo.customerEmailAddress}
              onChange={(e) =>
                setInvoiceInfo((x: any) => {
                  return { ...x, customerEmailAddress: e.target.value };
                })
              }
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <FormControlLabel
              label="Email küldése"
              disabled={!invoiceInfo.customerEmailAddress}
              control={
                <Checkbox
                  checked={invoiceInfo.customerSendEmail}
                  onChange={(e: any) =>
                    setInvoiceInfo((x: any) => {
                      return { ...x, customerSendEmail: e.target.checked };
                    })
                  }
                />
              }
            />
          </Grid>

          <Grid item xs={12}>
            <Divider textAlign="left" sx={{ mb: 2 }}></Divider>
          </Grid>
          <Grid item xs={12} md={2}>
            <Autocomplete
              disablePortal
              id="paymentType"
              fullWidth
              value={invoiceInfo.paymentType}
              onChange={(event, value) => {
                setInvoiceInfo((x: any) => {
                  return {
                    ...x,
                    paymentType: value,
                    paid:
                      value === 'Átutalás' || value === 'Utánvét'
                        ? false
                        : true,
                  };
                });
                dispatch(setPaymentType(value));
              }}
              options={['Készpénz', 'Bankkártya', 'Átutalás', 'Utánvét']}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Fizetés módja"
                  required
                  fullWidth
                  error={!invoiceInfo.paymentType}
                  helperText={!invoiceInfo.paymentType && 'Kötelező mező'}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DesktopDatePicker
              label="Fizetési határidő"
              value={invoiceInfo.dueDate}
              inputFormat="yyyy-MM-dd"
              onChange={(value) =>
                setInvoiceInfo((x: any) => {
                  return { ...x, dueDate: value };
                })
              }
              renderInput={(params) => (
                <TextField required fullWidth {...params} />
              )}
            />
            <Button
              onClick={() => {
                setInvoiceInfo((x: any) => {
                  return {
                    ...x,
                    dueDate: moment(new Date()).add(8, 'days').toDate(),
                  };
                });
              }}
            >
              +8
            </Button>
            <Button
              onClick={() => {
                setInvoiceInfo((x: any) => {
                  return {
                    ...x,
                    dueDate: moment(new Date()).add(15, 'days').toDate(),
                  };
                });
              }}
            >
              +15
            </Button>
            <Button
              onClick={() => {
                setInvoiceInfo((x: any) => {
                  return {
                    ...x,
                    dueDate: moment(new Date()).add(30, 'days').toDate(),
                  };
                });
              }}
            >
              +30
            </Button>
          </Grid>
          <Grid item xs={12} md={2}>
            <DesktopDatePicker
              label="Teljesítés dátuma"
              value={invoiceInfo.fulFillmentDate}
              inputFormat="yyyy-MM-dd"
              onChange={(value) =>
                setInvoiceInfo((x: any) => {
                  return { ...x, fulFillmentDate: value };
                })
              }
              renderInput={(params) => (
                <TextField required fullWidth {...params} />
              )}
            />
          </Grid>
          <Grid item xs={6} md={2}>
            <FormControlLabel
              label="Fizetve?"
              control={
                <Checkbox
                  checked={invoiceInfo.paid}
                  onChange={(e: any) =>
                    setInvoiceInfo((x: any) => {
                      return { ...x, paid: e.target.checked };
                    })
                  }
                />
              }
            />
          </Grid>
          <Grid item xs={6} md={2}>
            <FormControlLabel
              label="E-Számla?"
              control={
                <Checkbox
                  checked={invoiceInfo.isElectronic}
                  onChange={(e: any) =>
                    setInvoiceInfo((x: any) => {
                      return { ...x, isElectronic: e.target.checked };
                    })
                  }
                />
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Megjegyzés"
              fullWidth
              multiline
              rows={2}
              type="text"
              value={invoiceInfo.customerComment}
              onChange={(e) =>
                setInvoiceInfo((x: any) => {
                  return { ...x, customerComment: e.target.value };
                })
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={
            !selectedPartner ||
            selectedPartner.addresses?.length === 0 ||
            !invoiceInfo.companyId ||
            !invoiceInfo.addressId ||
            !invoiceInfo.documentBlockId
          }
          onClick={() => {
            onSubmit(invoiceInfo);
            handleClose();
          }}
        >
          Számla kiállítása
        </Button>
        <Button color="primary" variant="outlined" onClick={handleClose}>
          Mégse
        </Button>
      </DialogActions>
    </Dialog>
  );

  function handlePartnerChange(value: any) {
    if (!value) {
      return;
    }

    setSelectedPartner(value);
    setInvoiceInfo((x: any) => {
      var billingAddress = getBillingAddress(value);
      setSelectedAddressId(billingAddress?.id);
      return {
        ...x,
        customerName: value.isPrivatePerson
          ? getFullName(value)
          : value.companyName,
        customerPostalCode: billingAddress?.postalCode ?? '',
        customerCity: billingAddress?.city ?? '',
        customerStreetAddress:
          billingAddress != null
            ? `${billingAddress?.street} ${billingAddress?.houseNumber}`
            : '',
        customerTaxNumber: value.taxNumber ?? '',
        customerPhoneNumber: value.contact?.phone ?? '',
        customerComment: '',
        customerEmailAddress: value.contact?.email ?? '',
        clientId: value.id,
        addressId: billingAddress?.id ?? 0,
      };
    });
  }
}
function getBillingAddress(value: any) {
  if (!value.addresses) {
    return '';
  }

  var billingAddress = value.addresses?.find((x) => x.isBillingAddress);

  if (!billingAddress && value.addresses?.length > 0) {
    billingAddress = value.addresses[0];
  }
  return billingAddress;
}
