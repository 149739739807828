import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import workItemService from '../../../services/erp/workItemService';
import { Currency } from '../../../types/Currency';
import { DocumentLanguage } from '../../../types/DocumentLanguage';

const WorkItemCreate = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const userWarehouse = useSelector((state: any) => state.userWarehouse);
  const userPriceCategory = useSelector(
    (state: any) => state.userPriceCategory
  );
  const CreateWorkItem = () => {
    var entity = {
      id: 0,
      priority: 0,
      description: '',
      comment: '',
      isPrivacyPolicyAgreedByClient: false,
      isContractTermsAgreedByClient: false,
      isPriceDifferenceAcceptedByClient: false,
      timeWindowStart: null,
      timeWindowEnd: null,
      reason: '',
      taskTypeId: null,
      itemPackages: [],
      assignedToId: null,
      clientId: null,
      workAddressId: null,
      priceCategoryId: userPriceCategory?.selectedPriceCategoryId ?? null,
      warehouseId: userWarehouse?.selectedWarehouseId ?? 0,
      selectedServices: [],
      selectedItems: [],
      signature: '',
      workItemStatus: '',
      currency: Currency.HUF,
      conversionRate: 1,
      invoiceLanguage: DocumentLanguage.Hu,
    };

    workItemService
      .create(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });
          navigate(`/erp/workitems/edit/${response.result.id}/0/workItem`);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
      });
  };

  return {
    CreateWorkItem,
  };
};

export default WorkItemCreate;
