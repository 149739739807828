import { createReducer } from '@reduxjs/toolkit';

import { loadEvents, loadEmployees } from '../thunks/resourcePlannerThunk';

const initialState = {
  employees: [],
  events: [],
  error: '',
};

export const resourcePlannerReducer = createReducer(initialState, (builder) => {
  builder.addCase(loadEmployees.fulfilled, (state, action) => {
    if (action.payload?.hasError)
      state.error = action.payload.errorMessages.join('|');
    else state.employees = action.payload?.records ?? [];
  });
  builder.addCase(loadEvents.fulfilled, (state, action) => {
    if (action.payload?.hasError)
      state.error = action.payload.errorMessages.join('|');
    else state.events = action.payload?.records ?? [];
  });
});
