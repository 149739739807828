import {
  Add,
  Category,
  KeyboardArrowDown,
  KeyboardArrowRight,
  Login,
  ZoomInMap,
  ZoomOutMap,
} from '@mui/icons-material';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';

const TreeViewComponent = (props: any) => {
  const {
    data,
    readonly,
    behindActions,
    frontActions,
    handleDNG = () => {},
    disableMove = false,
  } = props;

  const [treeData, setTreeData] = useState(data);
  const [selectedItemId, setSelectedItemId] = useState(0);
  const [targetItem, setTargetItem] = useState<any>({
    id: 0,
    isParent: false,
  });
  const [targetPreviewId, setTargetPreviewId] = useState<any>({
    id: 0,
    isParent: false,
  });
  const [toggleAll, setToggleAll] = useState(true);

  const toggleOpen = (id) => {
    const newTreeData = treeData.map((item) => {
      if (item.id === id) {
        return { ...item, open: !item.open };
      }
      return item;
    });
    setTreeData(newTreeData);
  };
  const toggleOpenAll = () => {
    const newTreeData = treeData.map((item) => {
      return { ...item, open: !toggleAll };
    });
    setTreeData(newTreeData);
    setToggleAll(!toggleAll);
  };

  useEffect(() => {
    setTreeData(
      data.map((item) => {
        let exists = treeData.find((x) => item.id === x.id);
        if (exists) {
          return { ...item, open: exists.open };
        }
        return { ...item };
      })
    );
    setToggleAll(true);
  }, [data]);

  const isChild = (sourceId, targetId) => {
    const children = treeData?.filter((item) => item.parentId === sourceId);
    if (children.length === 0) {
      return false;
    }

    if (children.some((child) => child.id === targetId)) {
      return true;
    }

    return children.some((child) => isChild(child.id, targetId));
  };

  const isValidSwap = (sourceId, targetId) => {
    const target = treeData.find((item) => item.id === targetId);
    if (sourceId === targetId) return false;
    if (target.parentId === sourceId) return false;
    if (isChild(sourceId, targetId)) return false;
    return true;
  };

  const handleSwap = (sourceId, targetItem) => {
    const target = treeData.find((item) => item.id === targetItem.id);
    if (!isValidSwap(sourceId, targetItem.id)) return;

    if (targetItem.id === null) {
      handleDNG(sourceId, 1, null);
    } else {
      handleDNG(
        sourceId,
        targetItem.isParent ? 1 : target.order + 1,
        targetItem.isParent ? target.id : target.parentId
      );
    }
  };

  useEffect(() => {
    if (selectedItemId > 0 && targetItem.id !== 0) {
      handleSwap(selectedItemId, targetItem);
      setSelectedItemId(0);
      setTargetItem({ id: 0, isParent: false });
      setTargetPreviewId({ id: 0, isParent: false });
    }
  }, [selectedItemId, targetItem]);

  const childrenCount = (parentId) => {
    let count = 0;
    let children = treeData.filter((item) => item.parentId === parentId);
    count += children.length;
    children.forEach((child) => {
      if (child.open) {
        count += childrenCount(child.id);
      }
    });
    return count;
  };

  const renderTree = (data, marginLeft, parentIds = []) => {
    return data?.map((node) => {
      const children = treeData.filter((item) => item.parentId === node.id);
      const showPathLine = parentIds.length > 0;
      const beforeNode = treeData.find(
        (item) =>
          item.order === node.order - 1 &&
          item.parentId === node.parentId &&
          item.open
      );
      const beforeNodeChildrenCount = childrenCount(beforeNode?.id);

      return (
        <>
          <div
            key={node.id}
            style={{
              marginLeft: marginLeft,
              position: 'relative',
              padding: 5,
              border: '1px solid #ccc',
              borderRadius: '5px',
              height: '50px',
              alignItems: 'center',
              marginBottom: '5px',
              display: 'flex',
              backgroundColor: node.id === selectedItemId ? '#F2FF88' : 'white',
            }}
            onDrop={(event) => {
              setTargetItem({ id: node.id, isParent: false });
            }}
            onDragOver={(event) => {
              event.preventDefault();
              if (isValidSwap(selectedItemId, node.id)) {
                setTargetPreviewId({
                  id: node.id,
                  isParent: node.id === null ? true : false,
                });
              }
            }}
          >
            {!disableMove && (
              <div
                draggable={node.draggable && !readonly}
                style={{ cursor: 'move' }}
                onDragStart={(event) => {
                  setSelectedItemId(node?.id);
                }}
                onDrop={(event) => {
                  event.stopPropagation();
                  setTargetItem({ id: node.id, isParent: false });
                }}
                onDragEnd={(event) => {
                  setSelectedItemId(0);
                  setTargetItem({ id: 0, isParent: false });
                  setTargetPreviewId({ id: 0, isParent: false });
                }}
                onDragExit={(event) => {
                  setSelectedItemId(0);
                  setTargetItem({ id: 0, isParent: false });
                  setTargetPreviewId({ id: 0, isParent: false });
                }}
                onDragOver={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  if (isValidSwap(selectedItemId, node.id)) {
                    setTargetPreviewId({
                      id: node.id,
                      isParent: node.id === null ? true : false,
                    });
                  }
                }}
              >
                {node.id !== null && (
                  <IconButton size="small" onClick={() => toggleOpen(node.id)}>
                    {<ZoomOutMap />}
                  </IconButton>
                )}
              </div>
            )}
            <Grid container justifyContent={'space-between'}>
              <Grid item container xs="auto">
                <Grid item>
                  <IconButton
                    size="small"
                    onClick={() => toggleOpen(node.id)}
                    style={{ marginRight: 5 }}
                  >
                    {node.open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                  </IconButton>
                  <Category color="primary" />
                  {node.name}
                </Grid>
                {frontActions && frontActions(node)}
                {selectedItemId > 0 && (
                  <div
                    draggable={node.draggable && !readonly}
                    style={{ cursor: 'move' }}
                    onDragStart={(event) => {
                      setSelectedItemId(node?.id);
                    }}
                    onDrop={(event) => {
                      event.stopPropagation();
                      setTargetItem({ id: node.id, isParent: true });
                    }}
                    onDragEnd={(event) => {
                      setSelectedItemId(0);
                      setTargetItem({ id: 0, isParent: false });
                      setTargetPreviewId({ id: 0, isParent: false });
                    }}
                    onDragExit={(event) => {
                      setSelectedItemId(0);
                      setTargetItem({ id: 0, isParent: false });
                      setTargetPreviewId({ id: 0, isParent: false });
                    }}
                    onDragOver={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      if (isValidSwap(selectedItemId, node.id)) {
                        setTreeData(
                          treeData.map((item) => {
                            if (item.id === node.id) {
                              return { ...item, open: true };
                            }
                            return item;
                          })
                        );
                        setTargetPreviewId({ id: node.id, isParent: true });
                      }
                    }}
                  >
                    {node.id !== null && (
                      <Tooltip title="Belehelyezés" arrow>
                        <IconButton
                          size="small"
                          onClick={() => toggleOpen(node.id)}
                        >
                          {<Login />}
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                )}
              </Grid>
              <Grid item xs="auto">
                <Grid container direction="row" alignItems="center">
                  {behindActions && node.id != null && behindActions(node)}
                </Grid>
              </Grid>
            </Grid>
            {showPathLine && (
              <div
                style={{
                  position: 'absolute',
                  top:
                    node.order > 1
                      ? beforeNodeChildrenCount > 0
                        ? `${(beforeNodeChildrenCount + 1) * 55 * -1}px`
                        : '-30px'
                      : '-5px',
                  left: `-20px`,
                  borderLeft: '1px solid black',
                  borderBottom: '1px solid black',
                  width: '20px',
                  height:
                    node.order > 1
                      ? beforeNodeChildrenCount > 0
                        ? `${(beforeNodeChildrenCount + 1) * 55 + 30}px`
                        : '55px'
                      : '30px',
                }}
              />
            )}
          </div>
          {targetPreviewId.id === node.id && targetPreviewId.isParent && (
            <div
              style={{
                marginLeft: targetPreviewId.isParent
                  ? marginLeft + 40
                  : marginLeft,
                backgroundColor: '#F2FF88',
                height: '25px',
                padding: '5px',
                border: '1px solid #ccc',
                borderRadius: '5px',
                marginBottom: '5px',
              }}
            ></div>
          )}
          {node.open &&
            renderTree(children, marginLeft + 40, [...parentIds, node.id])}
          {targetPreviewId.id === node.id && !targetPreviewId.isParent && (
            <div
              style={{
                marginLeft: targetPreviewId.isParent
                  ? marginLeft + 40
                  : marginLeft,
                backgroundColor: '#F2FF88',
                height: '25px',
                padding: '5px',
                border: '1px solid #ccc',
                borderRadius: '5px',
                marginBottom: '5px',
              }}
            ></div>
          )}
        </>
      );
    });
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <IconButton onClick={toggleOpenAll}>
          {toggleAll ? <KeyboardArrowDown /> : <KeyboardArrowRight />} Összes
          {toggleAll ? ' bezárása' : ' kinyitása'}
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        {renderTree(
          treeData.filter((node) => node.parentId === -1),
          10
        )}
      </Grid>
    </Grid>
  );
};

export default TreeViewComponent;
