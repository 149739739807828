import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useCheckPermission from '../../../hooks/useCheckPermission';
import fileAttachmentService from '../../../services/files/fileAttachmentService';

const FilesEdit = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const { entity, open, onClose, onSuccess } = props;
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<string[]>([]);
  const [file, setFile] = useState<any>({});

  const { checkPermission } = useCheckPermission();

  const OnClose = () => {
    setFile({});
    onClose();
  };

  useEffect(() => {
    if (entity) {
      setFile(entity);
    }
  }, [entity]);

  const onSubmit = (entity: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    fileAttachmentService
      .update({
        id: entity.id,
        description: entity.description,
        fileName: entity.fileName,
      })
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          setFile({});
          onClose();
          onSuccess(file);
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
      });
  };

  return (
    <Dialog open={open} onClose={() => OnClose()}>
      <DialogTitle>Fájl szerkesztése</DialogTitle>
      <DialogContent>
        <Grid container pt={2} spacing={2}>
          <Grid item xs={12}>
            <TextField
              id="fileName"
              value={file.fileName}
              onChange={(e) => {
                setFile({ ...file, fileName: e.target.value });
              }}
              label="Fájlnév"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="description"
              value={file.description}
              onChange={(e) => {
                setFile({ ...file, description: e.target.value });
              }}
              multiline
              minRows={3}
              label="Leírás"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} textAlign={'center'}>
            <Button variant="contained" href={file.url} target="_blank">
              Fájl megnyitása
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          disabled={file.fileName === ''}
          onClick={() => onSubmit(file)}
        >
          Mentés
        </Button>
        <Button onClick={() => OnClose()}>Mégse</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FilesEdit;
