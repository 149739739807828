import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import clientService from '../../../services/crm/clientService';
import taskTypeService from '../../../services/erp/taskTypeService';
import workItemService from '../../../services/erp/workItemService';
import { Document, Page, pdfjs } from 'react-pdf';
import { Button, Grid, SpeedDial, useMediaQuery } from '@mui/material';
import { Add, Download, ZoomIn, ZoomOut } from '@mui/icons-material';
import PdfViewer from '../../../components/PdfViewer';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const WorkItemPrintView = (props: any) => {
  const { id } = useParams();
  const { workItemId } = props;
  const [workItem, SetWorkItem] = React.useState<any>();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const sasToken = useSelector((state: any) => state.app.sasToken);
  const [numPages, setNumPages] = useState<any>(null);
  const [scale, setScale] = useState(1.0); // Default scale is 1 (100%)
  const isSmallScreen = useMediaQuery('(min-width:600px)');

  const handleZoomIn = () => setScale(scale + 0.5); // Increase scale by 0.5
  const handleZoomOut = () => setScale(scale - 0.5); // Decrease scale by 0.5

  useEffect(() => {
    if (id || workItemId) {
      dispatch({ type: 'SHOW_QUERY' });
      workItemService
        .get(workItemId > 0 ? workItemId : parseInt(id))
        .then((response) => {
          if (response.canceled) return;
          if (!response.hasError)
            SetWorkItem({
              ...response.result,
              pdfUrl: response.result.pdfUrl + sasToken,
            });
          else
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
        })
        .finally(() => dispatch({ type: 'HIDE' }));
    }
  }, []);

  const calculatefinalPrice = (isGross: boolean) => {
    let itemSum = 0;
    let serviceSum = 0;
    let itemPackagesSum = 0;

    if (workItem && workItem.selectedItems) {
      itemSum = workItem.selectedItems.reduce(
        (sum, item) =>
          sum +
          (isGross ? item.sellPriceGross : item.sellPriceNet) *
            (1 - item.discount / 100) *
            item.amount,
        0
      );
    }

    if (workItem && workItem.selectedServices) {
      serviceSum = workItem.selectedServices.reduce(
        (sum, service) =>
          sum +
          (isGross ? service.servicePriceGross : service.servicePriceNet) *
            (1 - service.discount / 100) *
            service.amount,
        0
      );
    }
    if (workItem && workItem.itemPackages) {
      itemPackagesSum = workItem.itemPackages.reduce(
        (sum, itemPackage) =>
          sum +
          (isGross ? itemPackage.sellPriceGross : itemPackage.sellPriceNet) *
            (1 - itemPackage.discount / 100) *
            itemPackage.amount,
        0
      );
    }

    return itemSum + serviceSum + itemPackagesSum;
  };

  return (
    <PdfViewer
      pdf={workItem?.pdfUrl}
      downloadName={`${workItem?.workItemNumber}`}
    />
  );
};

export default WorkItemPrintView;
