import {
  Button,
  TextField,
  Box,
  Grid,
  Paper,
  Autocomplete,
} from '@mui/material';
import { Form, Formik } from 'formik';

const CashRegisterForm = (props: any) => {
  const {
    entity,
    onSubmit,
    errors,
    handleCancel,
    readonly = false,
    clients,
    users,
  } = props;

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};
        if (!values.name) {
          errors.name = 'Required';
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        handleChange,
        setFieldValue,
        errors: validationErrors,
      }) => (
        <Form>
          {' '}
          <Paper>
            <Grid container justifyContent="left" spacing={2} p={5}>
              <h2>Pénztár {values.id > 0 ? 'szerkesztése' : 'létrehozása'}</h2>
              <Grid xs={12} item pt={5}>
                <Box color="red">
                  {errors.map((error) => (
                    <li>{error}</li>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.name}
                  onChange={handleChange}
                  label="Megnevezés"
                  name="name"
                  required
                  disabled={readonly}
                  fullWidth
                  autoFocus
                  error={!!touched.name && !!validationErrors.name}
                  helperText={
                    !!touched.name &&
                    !!validationErrors.name &&
                    (validationErrors.name as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="companyId"
                  disabled={readonly}
                  value={values.companyId ?? ''}
                  onChange={(event, value) => {
                    setFieldValue('companyId', value);
                  }}
                  getOptionLabel={(option) => {
                    var found = clients.find((g) => g.id === option);
                    if (found) {
                      return `${
                        found?.companyName === ''
                          ? found.name.fullName
                          : found.companyName
                      } (${found.taxNumber})}`;
                    } else return '';
                  }}
                  options={clients.map((g) => g.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Cég"
                      fullWidth
                      error={
                        !!touched.companyId && !!validationErrors.companyId
                      }
                      helperText={
                        !!touched.companyId &&
                        !!validationErrors.companyId &&
                        (validationErrors.companyId as string)
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="userId"
                  disabled={readonly}
                  value={values.userId ?? ''}
                  onChange={(event, value) => {
                    setFieldValue('userId', value);
                  }}
                  getOptionLabel={(option) => {
                    var found = users.find((g) => g.id === option);
                    if (found) {
                      return `${found.id}.${found.fullName}`;
                    } else return '';
                  }}
                  options={users.map((g) => g.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Felelős"
                      fullWidth
                      error={!!touched.userId && !!validationErrors.userId}
                      helperText={
                        !!touched.userId &&
                        !!validationErrors.userId &&
                        (validationErrors.userId as string)
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.prefix}
                  onChange={handleChange}
                  label="Prefix"
                  name="prefix"
                  required
                  disabled={readonly}
                  fullWidth
                  error={!!touched.prefix && !!validationErrors.prefix}
                  helperText={
                    !!touched.prefix &&
                    !!validationErrors.prefix &&
                    (validationErrors.prefix as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.limit}
                  onChange={handleChange}
                  label="Limit"
                  name="limit"
                  required
                  disabled={readonly}
                  fullWidth
                  type="number"
                  error={!!touched.limit && !!validationErrors.limit}
                  helperText={
                    !!touched.limit &&
                    !!validationErrors.limit &&
                    (validationErrors.limit as string)
                  }
                />
              </Grid>
              <Grid container item pt={3} justifyContent="left">
                {!readonly && (
                  <Grid item p={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      Mentés
                    </Button>
                  </Grid>
                )}
                <Grid item p={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleCancel}
                  >
                    Mégse
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default CashRegisterForm;
