export enum EmailStatuses {
  Sent = 1,
  Delivered = 2,
  Opened = 3,
  Failed = 4,
  FilteredSpam = 5,
  Quarantined = 6,
  Expanded = 7,
  Bounced = 8,
  Suppressed = 9,
}
