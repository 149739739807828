import { createReducer } from '@reduxjs/toolkit';
import { reset, setSettings } from '../actions/documentTemplateActions';
import { Orientation } from '../../types/Orientation';

const initialState = {
  setting: {
    primaryColor: '#000000',
    secondaryColor: '#000000',
    decimalPrecision: 0,
    orientation: Orientation.Portrait,
    imageHeight: 50,
    imageWidth: 50,
    imageXPosition: 0,
    imageYPosition: 0,
    imageUrlOrBase64: '',
  },
};

export const documentTemplateReducer = createReducer(
  initialState,
  (builder) => {
    builder.addCase(setSettings, (state, action) => {
      state.setting = action.payload;
    });
    builder.addCase(reset, (state, action) => {
      state.setting = initialState.setting;
    });
  }
);
