import {
  Grid,
  TextField,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Autocomplete,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import React from 'react';

interface ChimneyWorkDetailsProps {
  values: {
    airSpaceConnectionAmount: string;
    appearedOnLocation: boolean;
    inspection: boolean;
    cleaning: boolean;
    employeeId: number;
    technicalReview: boolean;
    approverName: string;
    approverTitle: string;
    date: Date | null;
  };
  touched: {
    airSpaceConnectionAmount?: boolean;
    approverName?: boolean;
    approverTitle?: boolean;
  };
  validationErrors: {
    airSpaceConnectionAmount?: string;
    approverName?: string;
    approverTitle?: string;
  };
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setFieldValue: (field: string, value: any) => void;
  readonly: boolean;
  users: any[];
  onEmployeeSelected: (employeeId: number) => void;
}

const ChimneyWorkDetails: React.FC<ChimneyWorkDetailsProps> = ({
  values,
  touched,
  validationErrors,
  handleChange,
  setFieldValue,
  readonly,
  users,
  onEmployeeSelected,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          value={values.airSpaceConnectionAmount ?? 0}
          onChange={handleChange}
          type="number"
          label="Légtér összeköttetések száma"
          name="airSpaceConnectionAmount"
          disabled={readonly}
          fullWidth
          error={
            !!touched.airSpaceConnectionAmount &&
            !!validationErrors.airSpaceConnectionAmount
          }
          helperText={
            !!touched.airSpaceConnectionAmount &&
            !!validationErrors.airSpaceConnectionAmount &&
            (validationErrors.airSpaceConnectionAmount as string)
          }
        />
      </Grid>
      <Grid item xs={12} pt={5}>
        <Typography variant="h6">
          Kéményseprő-ipari tevékenység elvégzése:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl component="fieldset">
          <FormLabel>Kéményseprő helyszíni megjelenése</FormLabel>
          <RadioGroup
            aria-label="appearedOnLocation"
            row
            name="appearedOnLocation"
            value={values.appearedOnLocation?.toString() ?? ''}
            onChange={(event) =>
              setFieldValue('appearedOnLocation', event.target.value === 'true')
            }
          >
            <FormControlLabel
              value="true"
              control={<Radio disabled={readonly} />}
              label="Igen"
            />
            <FormControlLabel
              value="false"
              control={<Radio disabled={readonly} />}
              label="Nem"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl component="fieldset">
          <FormLabel>Ellenőrzés</FormLabel>
          <RadioGroup
            aria-label="inspection"
            row
            name="inspection"
            value={values.inspection?.toString() ?? ''}
            onChange={(event) =>
              setFieldValue('inspection', event.target.value === 'true')
            }
          >
            <FormControlLabel
              value="true"
              control={<Radio disabled={readonly} />}
              label="Igen"
            />
            <FormControlLabel
              value="false"
              control={<Radio disabled={readonly} />}
              label="Nem"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl component="fieldset">
          <FormLabel>Tisztítás</FormLabel>
          <RadioGroup
            aria-label="cleaning"
            row
            name="cleaning"
            value={values.cleaning?.toString() ?? ''}
            onChange={(event) =>
              setFieldValue('cleaning', event.target.value === 'true')
            }
          >
            <FormControlLabel
              value="true"
              control={<Radio disabled={readonly} />}
              label="Igen"
            />
            <FormControlLabel
              value="false"
              control={<Radio disabled={readonly} />}
              label="Nem"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl component="fieldset">
          <FormLabel>Műszaki felülvizsgálat</FormLabel>
          <RadioGroup
            aria-label="technicalReview"
            row
            name="technicalReview"
            value={values.technicalReview?.toString() ?? ''}
            onChange={(event) =>
              setFieldValue('technicalReview', event.target.value === 'true')
            }
          >
            <FormControlLabel
              value="true"
              control={<Radio disabled={readonly} />}
              label="Igen"
            />
            <FormControlLabel
              value="false"
              control={<Radio disabled={readonly} />}
              label="Nem"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          disablePortal
          disabled={readonly}
          id="employeeId"
          value={values.employeeId ?? ''}
          onChange={(event, value) => {
            setFieldValue('employeeId', value);
            onEmployeeSelected(value);
          }}
          getOptionLabel={(option) => {
            let user = users.find((g) => g.id === option);
            return `${user?.fullName} (${user?.email ?? ''})`;
          }}
          options={users.map((g) => g.id)}
          renderInput={(params) => (
            <TextField {...params} fullWidth label="Felhasználó" />
          )}
        />
      </Grid>
      <Grid item xs={12} pt={5}>
        <TextField
          value={values.approverName ?? ''}
          onChange={handleChange}
          label="A kéményseprő munkavégzését igazoló személy neve"
          name="approverName"
          disabled={readonly}
          fullWidth
          error={!!touched.approverName && !!validationErrors.approverName}
          helperText={
            !!touched.approverName &&
            !!validationErrors.approverName &&
            (validationErrors.approverName as string)
          }
        />
      </Grid>
      <Grid item xs={12} pt={5}>
        <Autocomplete
          disablePortal
          autoHighlight
          autoSelect
          disabled={readonly}
          id="approverTitle"
          value={values.approverTitle ?? ''}
          onChange={(event, value) => {
            setFieldValue('approverTitle', value);
          }}
          options={[
            'Tulajdonos',
            'Ingatlankezelő',
            'Ingatlanhasználó',
            'Ingatlan megbízottja',
            'Közös képviselő',
            'Egyéb',
          ]}
          freeSolo
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label="A kéményseprő munkavégzését igazoló személy jogcíme"
            />
          )}
        />
      </Grid>
      <Grid item xs={12} pt={5}>
        <DatePicker
          label="Dátum"
          value={values.date ?? ''}
          readOnly={readonly}
          onChange={(date) => {
            setFieldValue('date', date);
          }}
          renderInput={(props) => <TextField {...props} />}
        />
      </Grid>
    </Grid>
  );
};

export default ChimneyWorkDetails;
