import axios from "axios";
import { postData } from "../axiosUtils";

const stocktakingService = {
  listStockTaking: (isFinished: boolean = false) => {
    return axios
      .get(`/api/stocktaking/listStockTaking?isFinished=${isFinished}`)
      .then((response) => {
        return response.data;
      });
  },
  listRemainingStockTakingItem: (stockTakingId) => {
    return axios
      .get(
        `/api/stocktaking/listRemainingStockTakingItem?stockTakingId=${stockTakingId}`
      )
      .then((response) => {
        return response.data;
      });
  },
  getStockTaking: (stockTakingId) => {
    return axios
      .get(`/api/stocktaking/getStockTaking?stockTakingId=${stockTakingId}`)
      .then((response) => {
        return response.data;
      });
  },
  closeStockTaking: (stockTakingId: number) => {
    return postData({
      url: `/api/stocktaking/closeStockTaking?stockTakingId=${stockTakingId}`,
    }).then((response) => {
      return response;
    });
  },

  createStockTaking: (entity: any) => {
    return postData({
      url: `/api/stocktaking/createStockTaking`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  createStockTakingItem: (entity: any) => {
    return postData({
      url: `/api/stocktaking/createStockTakingItem`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
};

export default stocktakingService;
