import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete, Grid } from "@mui/material";
import ValueEditor from "./ValueEditor";
import PropertyCreate from "../../properties/PropertyCreate";
import CreateEntityDecorator from "../../../../components/CreateEntityDecorator";
import useCheckPermission from "../../../../hooks/useCheckPermission";

export default function ParameterDialog(props: any) {
  const {
    open,
    handleClose,
    entity,
    onSubmit,
    properties,
    parameters,
    onNewPropertyAdded,
    values,
  } = props;
  const [parameter, setParameter] = React.useState<any>(entity);

  React.useEffect(() => {
    setParameter(entity);
  }, [entity]);

  const { checkPermission } = useCheckPermission();

  return (
    <Dialog
      open={open}
      style={{ minHeight: 500 }}
      fullWidth
      onClose={handleClose}
    >
      <DialogTitle>Paraméter hozzárendelése a termékhez</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Kérem adja meg a paraméter típusát és az értékét!
        </DialogContentText>
        <Grid container style={{ minHeight: "30vh" }} pt={5}>
          <Grid item xs={12}>
            <CreateEntityDecorator
              hideAdd={!checkPermission(["PropertyCreate"])}
              AutocompleteComponent={
                <Autocomplete
                  disablePortal
                  id="parameterId"
                  value={parameter.propertyId}
                  onChange={(event, value: number) => {
                    setParameter((state) => {
                      return { ...state, propertyId: value };
                    });
                  }}
                  getOptionLabel={(option) => {
                    return properties.find((p) => p.id === option)?.name ?? "";
                  }}
                  options={properties
                    .filter(
                      (x) =>
                        x.itemGroupId === null ||
                        x.itemGroupId === values.itemGroupId
                    )
                    .map((p) => p.id)
                    .filter(
                      (id) => !parameters.some((x) => x.propertyId === id)
                    )}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth label="Tulajdonság" />
                  )}
                />
              }
              onSave={(property) => {
                setParameter((state) => {
                  return { ...state, propertyId: property.id };
                });

                onNewPropertyAdded(property);
              }}
              CreateEntityComponent={PropertyCreate}
            />
          </Grid>
          <Grid item xs={12}>
            <ValueEditor
              properties={properties}
              setParameter={setParameter}
              parameter={parameter}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            onSubmit(parameter);
            setParameter({ ...entity });
          }}
        >
          Mentés
        </Button>
        <Button color="error" variant="outlined" onClick={handleClose}>
          Mégse
        </Button>
      </DialogActions>
    </Dialog>
  );
}
