import axios from "axios";

const appService = {
  IsAlive: (signal: AbortSignal) => {
    return axios
      .get("/api/monitoring/alive", { signal: signal })
      .then((x) => x.data);
  },
};

export default appService;
