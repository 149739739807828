import axios from 'axios';
import { postData } from '../axiosUtils';

const chimneySweepingCertificateService = {
  list: (
    startDate: any | null = null,
    endDate: any | null = null,
    clientId: any | null = null,
    signal: AbortSignal = new AbortController().signal,
    hasOrder: boolean | null = null
  ) => {
    let url = `/api/chimneySweepingCertificate/list`;
    if (startDate) {
      url += `?startDate=${startDate?.toISOString()}`;
    }
    if (endDate && !startDate) {
      url += `?endDate=${endDate?.toISOString()}`;
    } else if (endDate) {
      url += `&endDate=${endDate?.toISOString()}`;
    }
    if (clientId && !startDate && !endDate) {
      url += `?clientId=${clientId}`;
    } else if (clientId) {
      url += `&clientId=${clientId}`;
    }
    if (hasOrder != null && !startDate && !endDate && !clientId) {
      url += `?hasOrder=${hasOrder}`;
    } else if (hasOrder != null) {
      url += `&hasOrder=${hasOrder}`;
    }
    return axios.get(url, { signal }).then((response) => {
      return response.data;
    });
  },
  listDue: (
    self: boolean,
    startDate: any | null = null,
    endDate: any | null = null,
    signal: AbortSignal = new AbortController().signal
  ) => {
    let url = `/api/chimneySweepingCertificate/listDue?self=${self}`;
    if (startDate) {
      url += `&startDate=${startDate.toISOString()}`;
    }
    if (endDate) {
      url += `&endDate=${endDate.toISOString()}`;
    }
    return axios.get(url, { signal }).then((response) => {
      return response.data;
    });
  },
  listNotSent: (
    startDate: any | null = null,
    endDate: any | null = null,
    signal: AbortSignal = new AbortController().signal
  ) => {
    let url = `/api/chimneySweepingCertificate/listNotSent`;
    if (startDate) {
      url += `?startDate=${startDate.toISOString()}`;
    }
    if (endDate && !startDate) {
      url += `?endDate=${endDate.toISOString()}`;
    } else if (endDate) {
      url += `&endDate=${endDate.toISOString()}`;
    }
    return axios.get(url, { signal }).then((response) => {
      return response.data;
    });
  },
  sendEmail: (entity: any) => {
    return postData({
      url: `/api/chimneySweepingCertificate/sendEmail`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  setOrderId: (entity: any) => {
    return postData({
      url: `/api/chimneySweepingCertificate/setOrderId`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  reSendEmail: (entity: any) => {
    return postData({
      url: `/api/chimneySweepingCertificate/reSendEmail`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  get: (id: any) => {
    return axios
      .get(`/api/chimneySweepingCertificate/details/${id}`)
      .then((response) => {
        return response.data;
      });
  },
  create: (entity: any) => {
    return postData({
      url: `/api/chimneySweepingCertificate/create`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  query: (entity: any, signal: AbortSignal = new AbortController().signal) => {
    return postData({
      url: `/api/chimneySweepingCertificate/query`,
      data: entity,
      signal: signal,
    }).then((response) => {
      return response;
    });
  },
  createInvoice: (entity: any) => {
    return postData({
      url: `/api/chimneySweepingCertificate/createInvoice`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  update: (entity: any) => {
    return postData({
      url: `/api/chimneySweepingCertificate/update`,
      data: entity,
      method: 'PUT',
    }).then((response) => {
      return response;
    });
  },
  updateStatus: (entity: any) => {
    return postData({
      url: `/api/chimneySweepingCertificate/updateStatus`,
      data: entity,
      method: 'PUT',
    }).then((response) => {
      return response;
    });
  },
  delete: (id: any) => {
    return postData({
      url: `/api/chimneySweepingCertificate/delete/${id}`,
      method: 'DELETE',
    }).then((response) => {
      return response;
    });
  },
  markAsDone: (id: any) => {
    return postData({
      url: `/api/chimneySweepingCertificate/markDone/${id}`,
      method: 'PUT',
    }).then((response) => {
      return response;
    });
  },
};

export default chimneySweepingCertificateService;
