import CircleIcon from '@mui/icons-material/Circle';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import { Checkbox, Grid, Paper, Typography } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

export const InvoiceTypePickerCard = (props) => {
  const {
    icon,
    text,
    isPicked,
    onSelect,
    onSelectElectronic,
    isElectronic,
    hasElectronicVersion,
  } = props;

  return (
    <Paper
      sx={{
        border: `1px solid ${isPicked ? 'blue' : 'lightgrey'}`,
        borderRadius: '10px',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      }}
    >
      <ListItem>
        <ListItemText sx={{ color: 'black', userSelect: 'none' }}>
          <Grid container p={2}>
            {hasElectronicVersion ? (
              <>
                {/* Normal Version Side */}
                <Grid
                  item
                  xs={6}
                  container
                  direction="column"
                  onClick={() => onSelect(false)} // Pass `false` to indicate normal selection
                  sx={{ cursor: 'pointer' }}
                >
                  <Grid container justifyContent="space-between">
                    <Grid item>{icon}</Grid>
                    <Grid item>
                      <Checkbox
                        checked={isPicked && !isElectronic}
                        icon={<PanoramaFishEyeIcon />}
                        checkedIcon={<CircleIcon style={{ color: 'blue' }} />}
                        onChange={() => onSelect(false)}
                      />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography fontSize={'17px'} fontWeight={'bold'}>
                      {text}
                    </Typography>
                  </Grid>
                </Grid>

                {/* Electronic Version Side */}
                <Grid
                  item
                  xs={6}
                  container
                  pl={2}
                  direction="column"
                  onClick={() => onSelectElectronic(true)} // Pass `true` to indicate electronic selection
                  sx={{ cursor: 'pointer', borderLeft: '1px solid lightgrey' }}
                >
                  <Grid container justifyContent="space-between">
                    <Grid item>{icon}</Grid>
                    <Grid item>
                      <Checkbox
                        checked={isPicked && isElectronic}
                        icon={<PanoramaFishEyeIcon />}
                        checkedIcon={<CircleIcon style={{ color: 'blue' }} />}
                        onChange={() => onSelectElectronic(true)}
                      />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography fontSize={'17px'} fontWeight={'bold'}>
                      E-{text}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid
                container
                justifyContent="space-between"
                onClick={() => onSelect()}
                sx={{ cursor: 'pointer' }}
              >
                <Grid item>{icon}</Grid>
                <Grid item>
                  <Checkbox
                    checked={isPicked}
                    icon={<PanoramaFishEyeIcon />}
                    checkedIcon={<CircleIcon style={{ color: 'blue' }} />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography fontSize={'17px'} fontWeight={'bold'}>
                    {text}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </ListItemText>
      </ListItem>
    </Paper>
  );
};
