/* eslint-disable react-hooks/exhaustive-deps */
import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropertyForm from './PropertyForm';
import { useNavigate } from 'react-router-dom';
import { PropertyTypes } from '../../../types/PropertyTypes';
import propertyService from '../../../services/wms/propertyService';
import itemGroupService from '../../../services/wms/itemGroupService';
import useCheckPermission from '../../../hooks/useCheckPermission';

const PropertyCreate = (props: any) => {
  const { onSave = null, navigateBack = true, handleCancel = null } = props;
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<string[]>([]);
  const [groups, setGroups] = useState<any[]>([{ id: '', name: '' }]);

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    itemGroupService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setGroups(response.records);
      }
    });
  }, []);

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    propertyService
      .create(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });

          if (onSave) onSave(response.result);
          if (navigateBack) navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        setSubmitting(false);
      });
  };

  return (
    <PropertyForm
      readonly={!checkPermission(['PropertyCreate'])}
      entity={{
        name: '',
        propertyType: PropertyTypes.Text,
        unit: '',
        id: 0,
        itemGroupId: null,
      }}
      onNewItemGroupAdded={(itemGroup: any) => {
        setGroups([...groups, itemGroup]);
      }}
      groups={groups}
      handleCancel={() =>
        handleCancel != null ? handleCancel() : navigate(-1)
      }
      onSubmit={onSubmit}
      errors={errors}
    />
  );
};

export default PropertyCreate;
