const initialState = {
  userInfo: {
    userId: 0,
    fullName: 'Test Elek',
    email: 'test@elek.hu',
    imageURL: '',
    authorityInfo: {
      groups: [],
      permissions: [],
      roles: [],
    },
  },
};

export const userReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'LOAD_USER_INFO':
      return {
        ...state,
        userInfo: action.payload,
      };
    default:
      return state;
  }
};
