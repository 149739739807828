import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PriceCategoryForm from './PriceCategoryForm';
import { useNavigate, useParams } from 'react-router-dom';
import priceCategoryService from '../../../services/pricing/priceCategoryService';
import useCheckPermission from '../../../hooks/useCheckPermission';

const PriceCategoryEdit = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const params = useParams();
  const [errors, setErrors] = useState<string[]>([]);
  const [entity, setEntity] = useState<any>({ name: '', margin: 0 });
  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    priceCategoryService.get(params.id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        setErrors(response.errorMessages);
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setEntity(response.result);
      }
    });
  }, [params.id]);

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    priceCategoryService
      .update(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });

          navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        setSubmitting(false);
      });
  };

  return (
    <PriceCategoryForm
      entity={entity}
      readonly={!checkPermission(['PriceCategoryEdit'])}
      onSubmit={onSubmit}
      handleCancel={() => navigate(-1)}
      errors={errors}
    />
  );
};

export default PriceCategoryEdit;
