import axios from 'axios';
import { postData } from '../axiosUtils';
import { EntityStatuses } from '../../types/EntityStatuses';
import { ClientTypes } from '../../types/ClientTypes';

const clientService = {
  list: (
    listOnlyCompanies,
    status,
    signal: AbortSignal = new AbortController().signal,
    clientType: ClientTypes | null = null
  ) => {
    let url = `/api/client/list?listOnlyCompanies=${listOnlyCompanies}`;

    // Check if 'status' is not null before adding it to the URL
    if (status !== null) {
      url += `&status=${status}`;
    }
    if (clientType !== null) {
      url += `&clientType=${clientType}`;
    }

    return axios.get(url, { signal }).then((response) => {
      return response.data;
    });
  },
  queryTaxPayer: (
    taxNumber: string,
    signal: AbortSignal = new AbortController().signal
  ) => {
    let url = `/api/client/queryTaxPayer?taxNumber=${taxNumber}`;
    return axios.get(url, { signal }).then((response) => {
      return response.data;
    });
  },

  listAddresses: (signal: AbortSignal = new AbortController().signal) => {
    return axios
      .get(`/api/client/listAddresses`, { signal })
      .then((response) => {
        return response.data;
      });
  },

  get: (id: any) => {
    return axios.get(`/api/client/details/${id}`).then((response) => {
      return response.data;
    });
  },
  create: (entity: any) => {
    return postData({
      url: `/api/client/create`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  approve: (id: any) => {
    return postData({
      url: `/api/client/approve/${id}`,
      method: 'PATCH',
    }).then((response) => {
      return response;
    });
  },
  createAddress: (entity: any) => {
    return postData({
      url: `/api/client/createAddress`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  update: (entity: any) => {
    return postData({
      url: `/api/client/update`,
      data: entity,
      method: 'PUT',
    }).then((response) => {
      return response;
    });
  },
  combine: (entity: any) => {
    return postData({
      url: `/api/client/combine`,
      data: entity,
      method: 'PUT',
    }).then((response) => {
      return response;
    });
  },
  updateRepresentative: (entity: any) => {
    return postData({
      url: `/api/client/updateRepresentative`,
      data: entity,
      method: 'PUT',
    }).then((response) => {
      return response;
    });
  },
  delete: (id: any) => {
    return postData({
      url: `/api/client/delete/${id}`,
      method: 'DELETE',
    }).then((response) => {
      return response;
    });
  },
  deleteApiKey: (id: any) => {
    return postData({
      url: `/api/client/deleteApiKey/${id}`,
      method: 'DELETE',
    }).then((response) => {
      return response;
    });
  },

  addressWorkItem: (addressId: any) => {
    return axios
      .get(`/api/client/addressWorkItem/${addressId}`)
      .then((response) => {
        return response.data;
      });
  },

  profile: (
    clientId: any,
    startDate: any | null = null,
    endDate: any | null = null,
    signal: AbortSignal = new AbortController().signal
  ) => {
    let url = `/api/client/profile/${clientId}`;
    if (startDate) {
      url += `?startDate=${startDate?.toISOString()}`;
    }
    if (endDate && !startDate) {
      url += `?endDate=${endDate?.toISOString()}`;
    } else if (endDate) {
      url += `&endDate=${endDate?.toISOString()}`;
    }
    return axios.get(url, { signal }).then((response) => {
      return response.data;
    });
  },
};

export default clientService;
