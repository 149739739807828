import {
  Button,
  TextField,
  Box,
  Grid,
  Paper,
  Autocomplete,
  InputAdornment,
} from '@mui/material';
import { Form, Formik } from 'formik';
import CreateEntityDecorator from '../../../components/CreateEntityDecorator';
import useCheckPermission from '../../../hooks/useCheckPermission';
import ServiceCategoryCreate from '../serviceCategories/ServiceCategoryCreate';

const ServiceForm = (props: any) => {
  const {
    entity,
    onSubmit,
    errors,
    handleCancel,
    readonly = false,
    onNewServiceCategoryAdded,
    serviceCategories,
    isDialog = false,
    taxes,
  } = props;

  const { checkPermission } = useCheckPermission();

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};
        if (!values.name) {
          errors.name = 'Required';
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({
        isSubmitting,
        values,
        setFieldValue,
        touched,
        handleChange,
        errors: validationErrors,
      }) => (
        <Form>
          {' '}
          <Paper>
            <Grid container justifyContent="left" spacing={2} p={5}>
              <h2>
                Szolgáltatás {values.id > 0 ? 'szerkesztése' : 'létrehozása'}
              </h2>
              <Grid xs={12} item pt={5}>
                <Box color="red">
                  {errors.map((error) => (
                    <li>{error}</li>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.name}
                  onChange={handleChange}
                  label="Megnevezés"
                  name="name"
                  required
                  disabled={readonly}
                  fullWidth
                  autoFocus
                  error={!!touched.name && !!validationErrors.name}
                  helperText={
                    !!touched.name &&
                    !!validationErrors.name &&
                    (validationErrors.name as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <CreateEntityDecorator
                  hideAdd={
                    readonly || !checkPermission(['ServiceCategoryCreate'])
                  }
                  AutocompleteComponent={
                    <Autocomplete
                      disablePortal
                      id="serviceCategories"
                      disabled={readonly}
                      value={values.serviceCategoryId}
                      onChange={(event, value) => {
                        setFieldValue('serviceCategoryId', value);
                      }}
                      getOptionLabel={(option) =>
                        serviceCategories.find((g) => g.id === option)?.name ??
                        ''
                      }
                      options={serviceCategories.map((g) => g.id)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          error={
                            !!touched.serviceCategoryId &&
                            !!validationErrors.serviceCategoryId
                          }
                          helperText={
                            !!touched.serviceCategoryId &&
                            !!validationErrors.serviceCategoryId &&
                            (validationErrors.serviceCategoryId as string)
                          }
                          fullWidth
                          label="Szolgáltatás kategória"
                        />
                      )}
                    />
                  }
                  onSave={(newServiceCategory) => {
                    setFieldValue('serviceCategoryId', newServiceCategory.id);
                    onNewServiceCategoryAdded(newServiceCategory);
                  }}
                  CreateEntityComponent={ServiceCategoryCreate}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.servicePriceNet}
                  onChange={handleChange}
                  label="Nettó ár"
                  name="servicePriceNet"
                  required
                  type="number"
                  disabled={readonly}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">HUF</InputAdornment>
                    ),
                  }}
                  error={
                    !!touched.servicePriceNet &&
                    !!validationErrors.servicePriceNet
                  }
                  helperText={
                    !!touched.servicePriceNet &&
                    !!validationErrors.servicePriceNet &&
                    (validationErrors.servicePriceNet as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.acquisitionPriceNet}
                  onChange={handleChange}
                  label="Nettó beszerzési ár"
                  name="acquisitionPriceNet"
                  required
                  type="number"
                  disabled={readonly}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">HUF</InputAdornment>
                    ),
                  }}
                  error={
                    !!touched.acquisitionPriceNet &&
                    !!validationErrors.acquisitionPriceNet
                  }
                  helperText={
                    !!touched.acquisitionPriceNet &&
                    !!validationErrors.acquisitionPriceNet &&
                    (validationErrors.acquisitionPriceNet as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.unitOfQuantity}
                  onChange={handleChange}
                  label="Egység"
                  name="unitOfQuantity"
                  required
                  disabled={readonly}
                  fullWidth
                  error={
                    !!touched.unitOfQuantity &&
                    !!validationErrors.unitOfQuantity
                  }
                  helperText={
                    !!touched.unitOfQuantity &&
                    !!validationErrors.unitOfQuantity &&
                    (validationErrors.unitOfQuantity as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="taxTypeId"
                  value={values.taxTypeId}
                  onChange={(event, value: number) => {
                    setFieldValue('taxTypeId', value);
                  }}
                  getOptionLabel={(option) =>
                    taxes?.find((g) => g.id === option)?.name ?? ''
                  }
                  options={taxes?.map((g) => g.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      fullWidth
                      label="ÁFA típusa"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.description}
                  onChange={handleChange}
                  label="Leírás"
                  name="description"
                  disabled={readonly}
                  fullWidth
                  error={
                    !!touched.description && !!validationErrors.description
                  }
                  helperText={
                    !!touched.description &&
                    !!validationErrors.description &&
                    (validationErrors.description as string)
                  }
                />
              </Grid>
              <Grid container item pt={3} justifyContent="left">
                {!readonly && (
                  <Grid item p={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      Mentés
                    </Button>
                  </Grid>
                )}
                {!isDialog && (
                  <Grid item p={1}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleCancel}
                    >
                      Mégse
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default ServiceForm;
