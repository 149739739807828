import {
  Autocomplete,
  Grid,
  InputAdornment,
  Tooltip,
  FormControlLabel,
  Checkbox,
  createFilterOptions,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import { set } from 'date-fns';
import { defaultChimneyControl } from './ChimneySweepingCertificateForm';
import { Form } from 'formik';

export default function ChimneyControlDialog(props: any) {
  const {
    open,
    handleClose,
    chimneyTypes,
    useCases,
    performanceCategories,
    errorCodes,
    onSave,
    chimneyControl,
    setChimneyControl,
    readonly,
  } = props;
  const [now, setNow] = useState<Date>(new Date());

  const { enqueueSnackbar } = useSnackbar();
  const filterOptions = createFilterOptions({
    ignoreCase: true,
  });
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogTitle>Égéstermék elvezető felvétele</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} style={{ paddingTop: 10 }}>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              fullWidth
              label="Csoport száma"
              value={chimneyControl.groupNumber ?? ''}
              onChange={(e) =>
                setChimneyControl({
                  ...chimneyControl,
                  groupNumber: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Autocomplete
              autoHighlight
              filterOptions={filterOptions}
              disablePortal
              id="chimneyType"
              autoSelect
              autoComplete
              value={chimneyControl.chimneyType ?? ''}
              disabled={readonly}
              onChange={(event, value: any) => {
                setChimneyControl({
                  ...chimneyControl,
                  chimneyType: value,
                });
              }}
              isOptionEqualToValue={(option, value) => {
                return option === value;
              }}
              getOptionLabel={(option) => {
                return option;
              }}
              options={chimneyTypes.map((option: any) => option.name)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Jele"
                  required
                  autoFocus
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              fullWidth
              disabled={readonly}
              label="Ellenőrzés/bontható bekötés száma"
              required
              value={chimneyControl.checkableDetachableConnectionsCount ?? ''}
              onChange={(e) =>
                setChimneyControl({
                  ...chimneyControl,
                  checkableDetachableConnectionsCount: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              disabled={readonly}
              fullWidth
              type="number"
              required={
                chimneyControl.chimneyType?.startsWith('K') ||
                chimneyControl.chimneyType?.startsWith('N')
              }
              label="K,N járat/összekötő elem hossz"
              value={chimneyControl.connectionElementLength ?? ''}
              onChange={(e) =>
                setChimneyControl({
                  ...chimneyControl,
                  connectionElementLength: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Autocomplete
              autoHighlight
              filterOptions={filterOptions}
              disabled={readonly}
              disablePortal
              id="chimneyUseCase"
              autoSelect
              value={chimneyControl.chimneyUseCase ?? ''}
              onChange={(event, value: any) => {
                setChimneyControl({
                  ...chimneyControl,
                  chimneyUseCase: value,
                });
              }}
              isOptionEqualToValue={(option, value) => {
                return option === value;
              }}
              getOptionLabel={(option) => {
                return option;
              }}
              options={useCases.map((option: any) => option.name)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Használat célja"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Autocomplete
              autoHighlight
              filterOptions={filterOptions}
              disabled={readonly}
              disablePortal
              id="performanceCategory"
              autoSelect
              value={chimneyControl.performanceCategory ?? ''}
              onChange={(event, value: any) => {
                setChimneyControl({
                  ...chimneyControl,
                  performanceCategory: value,
                });
              }}
              isOptionEqualToValue={(option, value) => {
                return option === value;
              }}
              getOptionLabel={(option) => {
                return option;
              }}
              options={performanceCategories.map((option: any) => option.name)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Teljesítmény kategória"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Autocomplete
              autoHighlight
              filterOptions={filterOptions}
              disablePortal
              autoSelect
              disabled={readonly}
              id="cOMeasuringNeccessary"
              value={chimneyControl.cOMeasuringNeccessary ?? ''}
              onChange={(event, value: any) => {
                setChimneyControl({
                  ...chimneyControl,
                  cOMeasuringNeccessary: value,
                });
              }}
              isOptionEqualToValue={(option, value) => {
                return option === value;
              }}
              getOptionLabel={(option) => {
                return option;
              }}
              options={errorCodes.map((option: any) => option.name)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Szénmonoxid mérés szügséges(C)"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            Tüzelőberendezés külső állapota:{' '}
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              autoHighlight
              filterOptions={filterOptions}
              disablePortal
              disabled={readonly}
              autoSelect
              id="chimneyExternalCondition1"
              value={chimneyControl.chimneyExternalCondition1 ?? ''}
              onChange={(event, value: any) => {
                setChimneyControl({
                  ...chimneyControl,
                  chimneyExternalCondition1: value,
                });
              }}
              isOptionEqualToValue={(option, value) => {
                return option === value;
              }}
              getOptionLabel={(option) => {
                return option;
              }}
              options={errorCodes.map((option: any) => option.name)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="1."
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              autoHighlight
              filterOptions={filterOptions}
              disablePortal
              disabled={readonly}
              id="chimneyExternalCondition2"
              autoSelect
              value={chimneyControl.chimneyExternalCondition2 ?? ''}
              onChange={(event, value: any) => {
                setChimneyControl({
                  ...chimneyControl,
                  chimneyExternalCondition2: value,
                });
              }}
              isOptionEqualToValue={(option, value) => {
                return option === value;
              }}
              getOptionLabel={(option) => {
                return option;
              }}
              options={errorCodes.map((option: any) => option.name)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="2."
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              autoHighlight
              filterOptions={filterOptions}
              disablePortal
              autoSelect
              disabled={readonly}
              id="chimneyExternalCondition3"
              value={chimneyControl.chimneyExternalCondition3 ?? ''}
              onChange={(event, value: any) => {
                setChimneyControl({
                  ...chimneyControl,
                  chimneyExternalCondition3: value,
                });
              }}
              isOptionEqualToValue={(option, value) => {
                return option === value;
              }}
              getOptionLabel={(option) => {
                return option;
              }}
              options={errorCodes.map((option: any) => option.name)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="3."
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Autocomplete
              autoHighlight
              filterOptions={filterOptions}
              disablePortal
              disabled={readonly}
              autoSelect
              id="airFollowUp"
              value={chimneyControl.airFollowUp ?? ''}
              onChange={(event, value: any) => {
                setChimneyControl({
                  ...chimneyControl,
                  airFollowUp: value,
                });
              }}
              isOptionEqualToValue={(option, value) => {
                return option === value;
              }}
              getOptionLabel={(option) => {
                return option;
              }}
              options={errorCodes.map((option: any) => option.name)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Levegő utánpótlás"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Autocomplete
              autoHighlight
              filterOptions={filterOptions}
              disablePortal
              disabled={readonly}
              autoSelect
              id="cleaningRequirement"
              value={chimneyControl.cleaningRequirement ?? ''}
              onChange={(event, value: any) => {
                setChimneyControl({
                  ...chimneyControl,
                  cleaningRequirement: value,
                });
              }}
              isOptionEqualToValue={(option, value) => {
                return option === value;
              }}
              getOptionLabel={(option) => {
                return option;
              }}
              options={errorCodes.map((option: any) => option.name)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tisztitás feltételei"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Autocomplete
              autoHighlight
              filterOptions={filterOptions}
              disablePortal
              disabled={readonly}
              autoSelect
              id="freeCrossSectionOfPassage"
              value={chimneyControl.freeCrossSectionOfPassage ?? ''}
              onChange={(event, value: any) => {
                setChimneyControl({
                  ...chimneyControl,
                  freeCrossSectionOfPassage: value,
                });
              }}
              isOptionEqualToValue={(option, value) => {
                return option === value;
              }}
              getOptionLabel={(option) => {
                return option;
              }}
              options={errorCodes.map((option: any) => option.name)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Járat szabad keresztmetszete"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Autocomplete
              autoHighlight
              filterOptions={filterOptions}
              disablePortal
              disabled={readonly}
              id="externalCondition"
              autoSelect
              value={chimneyControl.externalCondition ?? ''}
              onChange={(event, value: any) => {
                setChimneyControl({
                  ...chimneyControl,
                  externalCondition: value,
                });
              }}
              isOptionEqualToValue={(option, value) => {
                return option === value;
              }}
              getOptionLabel={(option) => {
                return option;
              }}
              options={errorCodes.map((option: any) => option.name)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Külső állapota"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Autocomplete
              autoHighlight
              filterOptions={filterOptions}
              disablePortal
              disabled={readonly}
              autoSelect
              id="accessoryCondition"
              value={chimneyControl.accessoryCondition ?? ''}
              onChange={(event, value: any) => {
                setChimneyControl({
                  ...chimneyControl,
                  accessoryCondition: value,
                });
              }}
              isOptionEqualToValue={(option, value) => {
                return option === value;
              }}
              getOptionLabel={(option) => {
                return option;
              }}
              options={errorCodes.map((option: any) => option.name)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tartozékainak állapota"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Autocomplete
              autoHighlight
              filterOptions={filterOptions}
              disablePortal
              autoSelect
              disabled={readonly}
              id="connectionElementCondition"
              value={chimneyControl.connectionElementCondition ?? ''}
              onChange={(event, value: any) => {
                setChimneyControl({
                  ...chimneyControl,
                  connectionElementCondition: value,
                });
              }}
              isOptionEqualToValue={(option, value) => {
                return option === value;
              }}
              getOptionLabel={(option) => {
                return option;
              }}
              options={errorCodes.map((option: any) => option.name)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Összekötő elem állapota"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Autocomplete
              autoHighlight
              filterOptions={filterOptions}
              disablePortal
              autoSelect
              id="other"
              disabled={readonly}
              value={chimneyControl.other ?? ''}
              onChange={(event, value: any) => {
                setChimneyControl({
                  ...chimneyControl,
                  other: value,
                });
              }}
              isOptionEqualToValue={(option, value) => {
                return option === value;
              }}
              getOptionLabel={(option) => {
                return option;
              }}
              options={errorCodes.map((option: any) => option.name)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Egyéb"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} p={1}>
            <DatePicker
              disabled={readonly}
              label="Utolsó műszaki felülvizsgálat éve"
              value={chimneyControl.yearOfLastTechnicalOverView ?? ''}
              onChange={(date) => {
                setChimneyControl({
                  ...chimneyControl,
                  yearOfLastTechnicalOverView: date,
                });
              }}
              renderInput={(props) => <TextField fullWidth {...props} />}
            />
          </Grid>
          <Grid item xs={12} sm={6} p={1}>
            <DatePicker
              label="Használatba vétel éve"
              disabled={readonly}
              value={chimneyControl.usingSinceDate ?? ''}
              onChange={(date) => {
                setChimneyControl({
                  ...chimneyControl,
                  usingSinceDate: date,
                });
              }}
              renderInput={(props) => <TextField fullWidth {...props} />}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={chimneyControl.isTechnicalOverView ?? ''}
                  disabled={readonly}
                  onChange={(e, checked) =>
                    setChimneyControl({
                      ...chimneyControl,
                      isTechnicalOverView: checked,
                    })
                  }
                  color="primary"
                />
              }
              label="Műszaki felülvizsgálat"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={chimneyControl.isInUse ?? ''}
                  disabled={readonly}
                  onChange={(e, checked) =>
                    setChimneyControl({
                      ...chimneyControl,
                      isInUse: checked,
                    })
                  }
                  color="primary"
                />
              }
              label="Használatban van"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={chimneyControl.hasBeenUsed ?? ''}
                  disabled={readonly}
                  onChange={(e, checked) =>
                    setChimneyControl({
                      ...chimneyControl,
                      hasBeenUsed: checked,
                    })
                  }
                  color="primary"
                />
              }
              label="Használatban volt már"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={chimneyControl.isBanned ?? ''}
                  disabled={readonly}
                  onChange={(e, checked) =>
                    setChimneyControl({
                      ...chimneyControl,
                      isBanned: checked,
                    })
                  }
                  color="primary"
                />
              }
              label="Tiltott"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            Szén-monoxid tartalom (ppm)
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={4}>
              <TextField
                fullWidth
                disabled={readonly}
                label="1."
                value={chimneyControl.coContent1 ?? ''}
                onChange={(e) =>
                  setChimneyControl({
                    ...chimneyControl,
                    coContent1: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                disabled={readonly}
                label="2."
                value={chimneyControl.coContent2 ?? ''}
                onChange={(e) =>
                  setChimneyControl({
                    ...chimneyControl,
                    coContent2: e.target.value,
                  })
                }
              />{' '}
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                disabled={readonly}
                label="3."
                value={chimneyControl.coContent3 ?? ''}
                onChange={(e) =>
                  setChimneyControl({
                    ...chimneyControl,
                    coContent3: e.target.value,
                  })
                }
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              autoHighlight
              filterOptions={filterOptions}
              disablePortal
              disabled={readonly}
              id="dangerous"
              autoSelect
              value={chimneyControl.dangerous ?? ''}
              onChange={(event, value: any) => {
                setChimneyControl({
                  ...chimneyControl,
                  dangerous: value,
                });
              }}
              isOptionEqualToValue={(option, value) => {
                return option === value;
              }}
              getOptionLabel={(option) => {
                return option;
              }}
              options={errorCodes.map((option: any) => option.name)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Élet-és vagyonvédelmi szempontból veszélyes"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={
            readonly ||
            chimneyControl.chimneyType === '' ||
            chimneyControl.groupNumber === '' ||
            chimneyControl.checkableDetachableConnectionsCount === '' ||
            ((chimneyControl.chimneyType?.startsWith('K') ||
              chimneyControl.chimneyType?.startsWith('N')) &&
              chimneyControl.connectionElementLength === '')
          }
          onClick={(e) => {
            e.preventDefault();
            onSave(chimneyControl);
            handleClose();
            setChimneyControl(defaultChimneyControl);
          }}
        >
          Mentés
        </Button>
        <Button color="primary" variant="outlined" onClick={handleClose}>
          Mégse
        </Button>
      </DialogActions>
    </Dialog>
  );
}
