import axios from "axios";
import { postData } from "./axiosUtils";

const billingoService = {
  startSync: () => {
    return axios.post("/api/billingo/startSync").then((response) => {
      return response.data;
    });
  },
  syncServices: () => {
    return axios.post("/api/billingo/syncServices").then((response) => {
      return response.data;
    });
  },
  syncInvoices: (startDate: any) => {
    return axios
      .post(`/api/billingo/syncInvoices?startDate=${startDate}`)
      .then((response) => {
        return response.data;
      });
  },
  syncExpenses: (startDate: any, endDate: any) => {
    return axios
      .post(
        `/api/billingo/syncExpenses?startDate=${new Date(
          startDate
        ).toISOString()}&endDate=${new Date(endDate).toISOString()}`
      )
      .then((response) => {
        return response.data;
      });
  },
  getConversionRate: (fromCurrency: any, toCurrency: any) => {
    return axios
      .post(
        `/api/billingo/getConversionRate?fromCurrency=${fromCurrency}&toCurrency=${toCurrency}`
      )
      .then((response) => {
        return response.data;
      });
  },

  syncDocumentBlocks: () => {
    return axios.post(`/api/billingo/syncDocumentBlocks`).then((response) => {
      return response.data;
    });
  },
  createInvoice: (entity: any) => {
    return postData({
      url: `/api/billingo/createInvoice`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
};

export default billingoService;
