import { Info } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import moduleSettingsService from '../services/functionDescriptorService copy';
import { ModuleSettingsType } from '../types/ModuleSettingsType';
import useCheckPermission from './useCheckPermission';
import SettingsIcon from '@mui/icons-material/Settings';
import { translateModuleSettingType } from '../utils/nameFormatters';
import CurrencyModuleSettingsForm from '../components/ModuleSettingsForms/CurrencyModuleSettingsForm';
import ChimneySweepingModuleSettingsForm from '../components/ModuleSettingsForms/ChimneySweepingModuleSettingsForm';

export interface ModuleSettingsProps {
  type: ModuleSettingsType;
  userId?: number | null;
}

const useModuleSettings = (props: any) => {
  const { type, userId = null, isDefualtConfig = false } = props;
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch<any>();
  const [moduleSettings, setModuleSettings] = useState<any>({
    userId: null,
    type: ModuleSettingsType.ChimneySweepingCertificateInvoice,
    settings: '',
  });
  const [component, setComponent] = useState<any>(null);
  const [settings, setSettings] = useState<any>({});

  const { checkPermission } = useCheckPermission();

  const isAdmin = checkPermission(['Administrator']);
  const { enqueueSnackbar } = useSnackbar();

  const getModuleSettings = () => {
    if (!type) return;
    dispatch({ type: 'SHOW_QUERY' });
    moduleSettingsService
      .get(type, userId)
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) {
          if (response.result === null) return;
          setModuleSettings(response.result);
          setSettings(deserialize(response?.result?.settings ?? {}));
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
      });
  };

  useEffect(() => {
    switch (type) {
      case ModuleSettingsType.ChimneySweepingCertificateInvoice:
        setComponent(
          <ChimneySweepingModuleSettingsForm
            settings={settings}
            setSettings={(value: any) => setSettings(value)}
            userId={userId}
            readonly={
              !checkPermission(['SetChimneySweepingCertificateModuleSettings'])
            }
          />
        );
        break;
      case ModuleSettingsType.Currency:
        setComponent(
          <CurrencyModuleSettingsForm
            settings={settings}
            setSettings={(value: any) => setSettings(value)}
            userId={userId}
            readonly={!checkPermission(['SetCurrencyModuleSettings'])}
          />
        );
        break;
      default:
        setComponent(null);
    }
  }, [type, settings]);

  useEffect(() => {
    getModuleSettings();
  }, [type, userId, open]);

  const serialize = (value: any) => {
    let json = JSON.stringify(value);
    return json;
  };

  const deserialize = (value: any) => {
    let json = JSON.parse(value);
    return json;
  };

  const ModuleComponent = () => {
    return (
      <>
        {!isDefualtConfig && (
          <Tooltip title="Beállítások">
            <IconButton onClick={() => setOpen(true)}>
              <SettingsIcon color="info" />
            </IconButton>
          </Tooltip>
        )}
        {isDefualtConfig && (
          <Button onClick={() => setOpen(true)} variant="contained">
            {`${translateModuleSettingType(type)} beállítások`}
          </Button>
        )}
        <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
          <DialogTitle>Beállítások</DialogTitle>
          <DialogContent>{component}</DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                let settingsJson = serialize(settings);
                dispatch({ type: 'SHOW_SAVE' });
                moduleSettingsService
                  .save({
                    userId: userId,
                    type: type,
                    settings: settingsJson,
                  })
                  .then((response) => {
                    if (response.canceled) return;
                    if (response.hasError) {
                      enqueueSnackbar(response.errorMessages.join(','), {
                        variant: 'error',
                      });
                    } else {
                      enqueueSnackbar('Sikeres mentés!', {
                        variant: 'success',
                      });

                      setOpen(false);

                      getModuleSettings();
                    }
                  })
                  .finally(() => {
                    dispatch({ type: 'HIDE' });
                  });
              }}
            >
              Mentés
            </Button>

            <Button
              color="primary"
              variant="outlined"
              onClick={() => setOpen(false)}
            >
              Mégse
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  return { ModuleComponent, settings };
};

export default useModuleSettings;
