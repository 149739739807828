import {
  Visibility,
  Sync,
  Add,
  Edit,
  Delete,
  ImportContacts,
  ImportExport,
  Search,
} from '@mui/icons-material';
import {
  makeStyles,
  Tooltip,
  Paper,
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Typography,
  Box,
  TablePagination,
  TextField,
  InputAdornment,
} from '@mui/material';
import {
  GridRowParams,
  GridActionsCellItem,
  GridColDef,
  GridActionsColDef,
} from '@mui/x-data-grid';
import { parseJSON } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, Link as RouterLink, useParams } from 'react-router-dom';
import DataList from '../../../../components/DataList';
import useCheckPermission from '../../../../hooks/useCheckPermission';
import useConfirmDeleteDialog from '../../../../hooks/useConfirmDeleteDialog';
import useFunctionDescriptor from '../../../../hooks/useFunctionDescriptor';
import customerService from '../../../../services/crm/customerService';
import { utils, read } from 'xlsx';

const CustomersPage = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const { checkPermission } = useCheckPermission();
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const params = useParams();

  const [open, setOpen] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [totalPages, setTotalPages] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchText, setSearchText] = useState('');

  const customerTitleDescriptor = useFunctionDescriptor('CustomersPage.title');

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    customerService
      .list({
        pageSize: pageSize,
        pageIndex: pageIndex,
        searchText: searchText,
      })
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) setRows(response.records);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        setTotalPages(response.totalPages);
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, [pageSize, pageIndex, searchText]);

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];

    let readonly = !checkPermission(['CustomerEdit']);
    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          readonly ? (
            <Tooltip title="Megtekintés">
              <Visibility />
            </Tooltip>
          ) : (
            <Tooltip title="Szerkesztés">
              <Edit />
            </Tooltip>
          )
        }
        label={readonly ? 'Megtekintés' : 'Szerkesztés'}
        onClick={() => navigate(`/crm/customers/edit/${params.row.id}`)}
      />
    );

    if (checkPermission(['CustomerDelete'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Törlés">
              <Delete />
            </Tooltip>
          }
          label="Törlés"
          onClick={() =>
            setParams({
              open: true,
              name: 'Biztosan törölni szeretné?',
              onConfirm: async () => handleDelete(params.row.id),
            })
          }
        />
      );
    }

    return actions;
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Cég',
      flex: 100,
      editable: false,
      valueGetter: (params: any) => {
        return `${params.row.name ?? ''}`;
      },
    },
    {
      field: 'FullAddress',
      headerName: 'Cím',
      flex: 100,
      editable: false,
      renderCell: (params: any) => {
        return (
          <>
            <div style={{ wordBreak: 'break-word' }}>
              {params.row.fullAddress}
            </div>
          </>
        );
      },
    },
    {
      field: 'contact',
      headerName: 'Ügyfél',
      flex: 100,
      editable: false,
      renderCell: (params: any) => {
        return (
          <>
            <div style={{ wordBreak: 'break-word' }}>
              {params.row.phoneNumber}
            </div>
            <div>{params.row.email}</div>
          </>
        );
      },
    },
    {
      field: 'lastContact',
      headerName: 'Utolsó kapcsolatfelvétel',
      flex: 100,
      editable: false,
      valueFormatter: (params) => {
        return params.value ? parseJSON(params.value).toLocaleString() : '';
      },
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 100,
      getActions: getActions,
    } as GridActionsColDef,
  ];

  const handleDelete = (id: number) => {
    customerService.delete(id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres törlés!', {
          variant: 'success',
        });
        setRows(rows.filter((row) => row.id !== id));
      }
    });
  };

  const handleImport = async () => {
    if (!file) {
      enqueueSnackbar('Nincs kiválasztva fájl!', {
        variant: 'error',
      });
      return;
    }

    try {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target?.result;
        const workbook = read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const rows = utils.sheet_to_json(worksheet, { header: 1 });
        const headers = rows.shift();
        const customers = rows.map((row: any) => {
          return {
            name: row[0],
            fullAddress: row[1],
            phoneNumber: row[2],
          };
        });
        handleImportCustomers(customers);
      };
      reader.readAsBinaryString(file);
    } catch (error) {
      enqueueSnackbar('Hiba történt a fájl olvasása közben!', {
        variant: 'error',
      });
    }
  };

  const handleImportCustomers = async (customers: any[]) => {
    try {
      let importedCustomers = 0;
      for (let i = 0; i < customers.length; i += 500) {
        const batch = customers.slice(i, i + 500);

        dispatch({
          type: 'SHOW',
          payload: `Importálás folyamatban... (${importedCustomers} / ${customers.length})`,
        });
        const response = await customerService.import({ items: batch });
        importedCustomers += batch.length;

        if (response.canceled) return;
        if (!response.hasError) {
        } else {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        }
      }
    } catch (error) {
      enqueueSnackbar('Hiba történt az importálás közben!', {
        variant: 'error',
      });
      return;
    } finally {
      dispatch({ type: 'HIDE' });
    }

    enqueueSnackbar('Sikeres importálás!', {
      variant: 'success',
    });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
  };

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>Marketing lista{customerTitleDescriptor}</h2>
        </Grid>
        <Grid container item p={2} justifyContent="end">
          <Grid item>
            <Tooltip title="Importálás">
              <Grid item>
                <IconButton onClick={() => setOpen(true)} color="primary">
                  <ImportContacts />
                </IconButton>
              </Grid>
            </Tooltip>
          </Grid>
          {checkPermission(['CustomerCreate']) && (
            <Grid item>
              <Tooltip title="Ügyfél létrehozása">
                <IconButton
                  component={RouterLink}
                  to={`/crm/customers/create`}
                  color="primary"
                >
                  <Add />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Keresés"
            variant="outlined"
            fullWidth
            size="small"
            value={searchText}
            onChange={(event) => setSearchText(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          <DataList
            rows={rows}
            columns={columns}
            hideFooterPagination
            localStorageKey={'CustomersPage'}
          />
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={totalPages * pageSize}
            rowsPerPage={pageSize}
            page={pageIndex}
            onPageChange={(event, newPage) => setPageIndex(newPage)}
            onRowsPerPageChange={(event) => {
              setPageSize(parseInt(event.target.value));
              setPageIndex(0);
            }}
          />
        </Grid>
        <ConfirmDeleteDialog />
      </Grid>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <DialogTitle>Ügyfelek importálása</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1">
            Az importáláshoz használja a minta fájlt. A fájlban a fejlécek
            sorrendje nem változtatható meg. A fájlban a következő adatok
            szerepelhetnek: <br /> <b>Company, Address, PhoneNumbers</b> <br />
            Támogatott formátum: <b>.xls, .xlsx</b>
            <br /> <br />
            <b style={{ color: 'red' }}>
              Folyamat közben kérjük ne zárja be az ablakot! Az ablak bezárása
              félbeszakítja az importálást.
            </b>
          </Typography>
          <Box p={5} display="flex" justifyContent="center">
            <input
              accept=".xls,.xlsx"
              style={{ display: 'none' }}
              id="contained-button-file"
              type="file"
              onChange={handleFileChange}
            />
            <label htmlFor="contained-button-file">
              <Button variant="outlined" color="primary" component="span">
                Fájl kiválasztása
              </Button>
            </label>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setOpen(false)}
            color="secondary"
          >
            Mégse
          </Button>
          <Button variant="contained" onClick={handleImport} color="primary">
            Importálás
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default CustomersPage;
