import {
  GridColDef,
  GridActionsColDef,
  GridActionsCellItem,
} from '@mui/x-data-grid';
import { Paper, Grid, Tooltip, TextField, InputAdornment } from '@mui/material';
import DataList from '../../../components/DataList';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import warehouseService from '../../../services/wms/warehouseService';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { ProductionQuantityLimits, Search } from '@mui/icons-material';
import { GridRowParams } from '../../../../node_modules/@mui/x-data-grid/models/params/gridRowParams';
import useCheckPermission from '../../../hooks/useCheckPermission';
import SetMinAmountDialog from './SetMinAmountDialog';

export interface WarehouseItem {
  itemId: string;
  warehouseId: string;
  brand: string;
  itemGroup: string;
  itemNumber: string;
  name: string;
  amount: string;
}

const WarehouseItems = () => {
  const [rows, setRows] = useState<any[]>([]);
  const [warehouse, setWarehouse] = useState<any>({ name: '' });
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const { checkPermission } = useCheckPermission();
  const [setMinAmountDialog, setSetMinAmountDialog] = useState<any>({
    open: false,
    data: { name: '' },
  });

  const params = useParams();

  const loadData = () => {
    dispatch({ type: 'SHOW_QUERY' });
    warehouseService
      .listItems(parseInt(params.id), false)
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) setRows(response.records);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  };
  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    warehouseService.get(params.id).then((response) => {
      if (response.canceled) return;
      if (!response.hasError) setWarehouse(response.result);
      else
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];

    if (checkPermission(['WarehouseMinAmountSet'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Minimum raktárkészlet beállítása">
              <ProductionQuantityLimits />
            </Tooltip>
          }
          label="Minimum raktárkészlet beállítása"
          onClick={() =>
            setSetMinAmountDialog({
              open: true,
              data: params.row,
            })
          }
        />
      );
    }

    return actions;
  };

  const columns: GridColDef[] = [
    { field: 'itemNumber', headerName: 'Cikkszám', flex: 100 },
    { field: 'name', headerName: 'Megnevezés', flex: 100 },
    {
      field: 'itemGroup',
      headerName: 'Termékcsoport',
      flex: 100,
      editable: false,
    },
    {
      field: 'brand',
      headerName: 'Márka',
      flex: 100,
      editable: false,
    },
    {
      field: 'amount',
      headerName: 'Rendelkezésre álló mennyiség',
      flex: 100,
      editable: false,
    },
    {
      field: 'minAmount',
      headerName: 'Minimális raktárkészlet',
      flex: 100,
      editable: false,
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 20,
      getActions: getActions,
    } as GridActionsColDef,
  ].filter((x) =>
    !checkPermission(['WarehouseMinAmountView'])
      ? x.field !== 'minAmount'
      : true
  ) as GridColDef[];

  const handleSetMinAmountDialog = (itemId, minAmount) => {
    dispatch({ type: 'SHOW_SAVE' });
    warehouseService
      .setMinAmount({ warehouseId: warehouse.id, itemId, amount: minAmount })
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });
          loadData();
          setSetMinAmountDialog({
            open: false,
            data: { name: '' },
          });
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
      });
  };

  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (value) => {
    setSearchTerm(value);
  };

  const searchItems = () => {
    // Convert the search term to lowercase for case-insensitive search
    const searchTermLower = searchTerm.toLowerCase();

    // Perform a fuzzy search by splitting the search term into tokens
    const searchTokens = searchTermLower.split(/\s+/);

    // Filter items based on the search tokens
    const filteredItems = rows.filter((item) => {
      // Convert the item name to lowercase
      const itemNameLower =
        item.itemNumber.toLowerCase() + item.name.toLowerCase();

      // Check if each token is present in the item name
      return searchTokens.every((token) => itemNameLower.includes(token));
    });

    return filteredItems;
  };

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>{warehouse.name} árukészlete</h2>
        </Grid>
        <Grid item xs={12} pb={2}>
          <TextField
            value={searchTerm ?? ''}
            onChange={(e) => handleSearchChange(e.target.value)}
            label="Termék keresése"
            name="name"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <DataList
            rows={searchItems()}
            columns={columns}
            getRowId={(row) => row.itemId}
            localStorageKey={'WarehouseItems'}
          />
        </Grid>
      </Grid>
      <SetMinAmountDialog
        open={setMinAmountDialog.open}
        data={setMinAmountDialog.data}
        onSubmit={handleSetMinAmountDialog}
        handleClose={() =>
          setSetMinAmountDialog({
            open: false,
            data: { name: '' },
          })
        }
      />
    </Paper>
  );
};

export default WarehouseItems;
