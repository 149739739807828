import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import DocumentBlockForm from './DocumentBlockForm';
import { useNavigate, useParams } from 'react-router-dom';
import useCheckPermission from '../../../hooks/useCheckPermission';
import documentBlockService from '../../../services/billing/documentBlockService';
import clientService from '../../../services/crm/clientService';
import { EntityStatuses } from '../../../types/EntityStatuses';
import userService from '../../../services/authority/userService';
import { ClientTypes } from '../../../types/ClientTypes';
import { DocumentBlockFormats } from '../../../types/DocumentBlockFormats';
import { DocumentBlockTypes } from '../../../types/DocumentBlockTypes';
import documentTemplateService from '../../../services/billing/documentTemplateService';

const DocumentBlockEdit = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const params = useParams();
  const [errors, setErrors] = useState<string[]>([]);
  const [users, setUsers] = useState<any>([]);
  const [documentTemplates, setDocumentTemplates] = useState<any>([]);
  const [entity, setEntity] = useState<any>({
    name: '',
    prefix: '',
    format: DocumentBlockFormats.Default,
    senderEmail: '',
    senderName: '',
    type: DocumentBlockTypes.Invoice,
    userIds: [],
    documentTemplateId: null,
  });

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    documentTemplateService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        setErrors(response.errorMessages);
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setDocumentTemplates(response.records);
      }
    });
  }, []);

  useEffect(() => {
    documentBlockService.get(params.id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        setErrors(response.errorMessages);
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setEntity(response.result);
      }
    });
  }, [params.id]);

  useEffect(() => {
    userService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        setErrors(response.errorMessages);
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setUsers(response.records);
      }
    });
  }, []);

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    documentBlockService
      .update(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });

          navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        setSubmitting(false);
      });
  };

  return (
    <DocumentBlockForm
      entity={entity}
      readonly={!checkPermission(['EditDocumentBlock'])}
      onSubmit={onSubmit}
      handleCancel={() => navigate(-1)}
      errors={errors}
      documentTemplates={documentTemplates}
      users={users}
    />
  );
};

export default DocumentBlockEdit;
