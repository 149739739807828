import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import 'suneditor/dist/css/suneditor.min.css';
import orderService from '../../../services/sales/orderService';
import { Currency } from '../../../types/Currency';
import { DocumentLanguage } from '../../../types/DocumentLanguage';
import { DocumentTemplateType } from '../../../types/DocumentTemplateType';
import { InvoiceTypes } from '../../../types/InvoiceTypes';
import { useSnackbar } from 'notistack';
import workItemService from '../../../services/erp/workItemService';
import offerService from '../../../services/erp/offerService';
import { WorkItemAssignmentStatus } from '../../erp/workItems/wizard/steps/WorkItemAssignmentStep';

const DocumentTemplateTestView = (props: any) => {
  const { type, setting } = props;
  const [documentUrl, setDocumentUrl] = useState<string>('');

  const { enqueueSnackbar } = useSnackbar();

  const base64ToBlobUrl = (base64String, contentType) => {
    // Remove the data URL prefix if it's present (i.e., "data:[<mediatype>][;base64],")
    const byteCharacters = atob(base64String.split(',')[1] || base64String);

    // Create an array for the byte numbers
    const byteNumbers = new Array(byteCharacters.length);

    // Assign the byte characters to the byte array
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    // Convert the array to a Uint8Array
    const byteArray = new Uint8Array(byteNumbers);

    // Create a blob from the Uint8Array
    const blob = new Blob([byteArray], { type: contentType });

    // Create a blob URL
    const url = URL.createObjectURL(blob);
    return url;
  };

  useEffect(() => {
    let document = {
      finalPriceNet: 5000,
      finalPriceGross: 6350.12345,
      priceNet: 5000,
      price: 6350.12345,
      invoiceLanguage: DocumentLanguage.Hu,
      language: DocumentLanguage.Hu,
      workItemNumber: 'Teszt123',
      offerNumber: 'TeAAAszt123232323232',
      paymentType: 'Készpénz',
      invoiceType:
        type === DocumentTemplateType.DepositInvoice
          ? InvoiceTypes.DepositInvoice
          : type === DocumentTemplateType.DeliveryNote
          ? InvoiceTypes.WayBill
          : type === DocumentTemplateType.FeeCollection
          ? InvoiceTypes.FeeColleciton
          : type === DocumentTemplateType.Storno
          ? InvoiceTypes.Cancellation
          : type === DocumentTemplateType.Receipt
          ? InvoiceTypes.Receipt
          : InvoiceTypes.Invoice,
      items: [
        {
          quantity: 1,
          comment: 'Test item comment',
          sellPriceGross: 1270.123,
          sellPriceNet: 1000.232,
          itemName: 'Test item',
          discount: 0,
          amount: 1,
        },
        {
          quantity: 1,
          amount: 1,
          comment: 'Test item comment',
          sellPriceGross: 1270,
          sellPriceNet: 1000,
          itemName: 'Test item',
          discount: 0,
        },
        {
          quantity: 1,
          comment: 'Test item comment',
          sellPriceGross: 1270,
          sellPriceNet: 1000,
          itemName: 'Test item',
          discount: 0,
          amount: 1,
        },
      ],
      services: [],
      selectedServices: [],
      itemPackages: [],
      invoiceNumber: 'TEST-123',
      dateOfFulfillment: new Date(),
      dueDate: new Date(),
      expireAt: new Date(),
      createdAt: new Date(),
      clientName: 'Teszt Elek',
      clientTaxNumber: '12345678-1-13',
      client: {
        company: { name: 'Teszt', taxNumber: '12345678-1-13' },
      },
      order: {
        client: {
          company: { name: 'Teszt', taxNumber: '12345678-1-13' },
        },
        description: 'Teszt',
        currency: Currency.HUF,
      },
      address: {
        isBillingAddress: true,
        city: 'Budapest',
        country: 'Magyarország',
        houseNumbe: '2',
        postalCode: '2108',
        street: 'Könyves Kálmán',
      },
      company: {
        company: { name: 'Teszt', taxNumber: '12345678-1-13' },
        addresses: [
          {
            isBillingAddress: true,
            city: 'Budapest',
            country: 'Magyarország',
            houseNumbe: '2',
            postalCode: '2108',
            street: 'Könyves Kálmán',
          },
        ],
        taxNumber: '12345678',
      },
      completedOrder: {
        company: {
          company: { name: 'Teszt', taxNumber: '12345678-1-13' },
          addresses: [
            {
              isBillingAddress: true,
              city: 'Budapest',
              country: 'Magyarország',
              houseNumbe: '2',
              postalCode: '2108',
              street: 'Könyves Kálmán',
            },
          ],
        },
      },
      workItemAssignments: [
        {
          roleId: 10,
          workItemAssignmentStatus: WorkItemAssignmentStatus.Completed,
          user: { fullName: 'Teszt Elek' },
        },
      ],
      conditions:
        '- egyedileg gyártott termékek esetén 100% anyagköltség-előleg\n- végösszeg fizetése átadáskor készpénzben, azonnali átutalással, vagy egyedi megállapodás/szerződés szerint (bankkártyával, vagy utólagos átutalással)',
      guarantee:
        '- munka és szerelvények tekintetében 1 év, vagy az aktuális jogszabályok alapján- új nyílászárók esetén 5 év, amelyből 1 év jótállás, 4 év szavatosság',
      deadLine:
        '- megrendeléskori raktárkészlet és kapacitás szerint (jellemzően 2-10 munkanap)',
    };
    if (type === DocumentTemplateType.WorkItem) {
      workItemService
        .getPdf({
          workitem: { ...document, selectedItems: document.items },
          setting: setting,
        })
        .then((response) => {
          if (response.canceled) return;
          if (response.hasError) {
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
          } else {
            setDocumentUrl(base64ToBlobUrl(response.result, 'application/pdf'));
          }
        });
    } else if (type === DocumentTemplateType.Offer) {
      offerService
        .getPdf({
          offer: { ...document, selectedItems: document.items },
          setting: setting,
        })
        .then((response) => {
          if (response.canceled) return;
          if (response.hasError) {
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
          } else {
            setDocumentUrl(base64ToBlobUrl(response.result, 'application/pdf'));
          }
        });
    } else {
      orderService
        .getPdf({ order: document, setting: setting })
        .then((response) => {
          if (response.canceled) return;
          if (response.hasError) {
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
          } else {
            setDocumentUrl(base64ToBlobUrl(response.result, 'application/pdf'));
          }
        });
    }
  }, [setting, type]);

  return (
    <Grid item xs={12}>
      <object
        data={documentUrl ?? ''}
        type="application/pdf"
        width="100%"
        height="800px"
        aria-label="PDF Viewer"
      >
        <a href={documentUrl ?? ''}>PDF letöltése</a>
      </object>
    </Grid>
  );
};

export default DocumentTemplateTestView;
