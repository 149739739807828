import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  DialogContentText,
} from '@mui/material';
import { useState } from 'react';
import { blue } from '@mui/material/colors';

const useConfirmDialog = () => {
  const [params, setParams] = useState({
    open: false,
    name: 'Biztosan kiszeretné törölni?',
    onConfirm: () => new Promise(() => {}),
  });
  const [submiting, setSubmiting] = useState(false);

  const closeDialog = () => {
    setParams({ ...params, open: false });
  };

  const ConfirmDialog = (props: any) => {
    const { actionName } = props;
    return (
      <Dialog open={params.open} onClose={closeDialog} maxWidth="sm" fullWidth>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <DialogTitle
            sx={{
              backgroundColor: blue[500],
              color: 'white',
              width: '100%',
              textAlign: 'center',
            }}
          >
            {actionName}
          </DialogTitle>

          <DialogContent>
            <DialogContentText>
              <Box pt={5}>{params.name}</Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setSubmiting(true);
                params
                  .onConfirm()
                  .then(() => {
                    closeDialog();
                  })
                  .finally(() => {
                    setSubmiting(false);
                  });
              }}
              variant="contained"
              disabled={submiting}
              sx={{ backgroundColor: blue[500], color: 'white' }}
            >
              Igen
            </Button>
            <Button onClick={closeDialog} color="primary" variant="outlined">
              Nem
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    );
  };
  return {
    ConfirmDialog,
    setConfirmParams: setParams,
    params: params,
  };
};
export default useConfirmDialog;
