import {
  GridColDef,
  GridActionsCellItem,
  GridActionsColDef,
} from "@mui/x-data-grid";
import { Paper, IconButton, Grid, Tooltip } from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Visibility,
} from "@mui/icons-material";
import { GridRowParams } from "@mui/x-data-grid/models/params/gridRowParams";
import DataList from "../../../components/DataList";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import useCheckPermission from "../../../hooks/useCheckPermission";
import useFunctionDescriptor from "../../../hooks/useFunctionDescriptor";
import useConfirmDeleteDialog from "../../../hooks/useConfirmDeleteDialog";
import abilityService from "../../../services/authority/abilityService";

export interface Ability {
  id: string;
  name: string;
  description: string;
}

const AbilityPage = () => {
  const navigate = useNavigate();
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const [rows, setRows] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const titleDescriptor = useFunctionDescriptor("AbilitysPage.title");

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    dispatch({ type: "SHOW_QUERY" });
    abilityService
      .list()
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) setRows(response.records);
        else
          enqueueSnackbar(response.errorMessages.join(","), {
            variant: "error",
          });
      })
      .finally(() => dispatch({ type: "HIDE" }));
  }, []);

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];

    let readonly = !checkPermission(["AbilityEdit"]);
    actions.push(
      <GridActionsCellItem
        color={color ? color : "primary"}
        icon={
          readonly ? (
            <Tooltip title="Megtekintés">
              <Visibility />
            </Tooltip>
          ) : (
            <Tooltip title="Szerkesztés">
              <EditIcon />
            </Tooltip>
          )
        }
        label={readonly ? "Megtekintés" : "Szerkesztés"}
        onClick={() => navigate(`/authority/abilitys/edit/${params.row.id}`)}
      />
    );

    if (checkPermission(["AbilityDelete"])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : "primary"}
          icon={
            <Tooltip title="Törlés">
              <DeleteIcon />
            </Tooltip>
          }
          label="Törlés"
          onClick={() =>
            setParams({
              open: true,
              name: "",
              onConfirm: async () => handleDelete(params.row.id),
            })
          }
        />
      );
    }

    return actions;
  };

  const columns: GridColDef[] = [
    { field: "name", headerName: "Megnevezés", flex: 100 },
    { field: "description", headerName: "Leírás", flex: 200 },
    {
      field: "actions",
      type: "actions",
      flex: 50,
      getActions: getActions,
    } as GridActionsColDef,
  ];

  const handleDelete = (id: number) => {
    abilityService.delete(id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(","), {
          variant: "error",
        });
      } else {
        enqueueSnackbar("Sikeres törlés", {
          variant: "success",
        });
        setRows(rows.filter((row) => row.id !== id));
      }
    });
  };

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>Képességek{titleDescriptor}</h2>
        </Grid>
        {checkPermission(["AbilityCreate"]) && (
          <Grid container item xs={12} p={2} justifyContent="end">
            <Grid item>
              <Tooltip title="Képesség létrehozása">
                <IconButton
                  component={RouterLink}
                  to={`/authority/abilitys/create`}
                  color="primary"
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <DataList
            rows={rows}
            columns={columns}
            localStorageKey={"AbilitysPage"}
          />
        </Grid>
        <ConfirmDeleteDialog />
      </Grid>
    </Paper>
  );
};

export default AbilityPage;
