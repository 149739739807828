export enum Currency {
  AED = 0,

  AUD = 1,

  BGN = 2,

  BRL = 3,

  CAD = 4,

  CHF = 5,

  CNY = 6,

  CZK = 7,

  DKK = 8,

  EUR = 9,

  GBP = 10,

  HKD = 11,

  HRK = 12,

  HUF = 13,

  IDR = 14,

  ILS = 15,

  INR = 16,

  ISK = 17,

  JPY = 18,

  KRW = 19,

  MXN = 20,

  MYR = 21,

  NOK = 22,

  NZD = 23,

  PHP = 24,

  PLN = 25,

  RON = 26,

  RSD = 27,

  RUB = 28,

  SEK = 29,

  SGD = 30,

  THB = 31,

  TRY = 32,

  UAH = 33,

  USD = 34,

  ZAR = 35,
}
