import axios from "axios";
import { postData } from "./axiosUtils";

const accountService = {
  getUserInfo: () => {
    return axios.get("/api/account/userInfo").then((response) => {
      return response.data;
    });
  },
  loggedOut: () => {
    return axios.get("/api/account/loggedOut");
  },
  syncUsers: () => {
    return postData({ url: "/api/account/syncUsers" });
  },
  invite: (email: string) => {
    return postData({ url: `/api/account/invite?email=${email}` });
  },
};

export default accountService;
