import axios from "axios";
import { postData } from "../axiosUtils";

const workItemAssignmentService = {
  list: (workItemId: any) => {
    return axios
      .get(`/api/workItemAssignment/list/${workItemId}`)
      .then((response) => {
        return response.data;
      });
  },
  get: (id: any) => {
    return axios
      .get(`/api/workItemAssignment/details/${id}`)
      .then((response) => {
        return response.data;
      });
  },
  create: (entity: any) => {
    return postData({
      url: `/api/workItemAssignment/create`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  update: (entity: any) => {
    return postData({
      url: `/api/workItemAssignment/update`,
      data: entity,
      method: "PUT",
    }).then((response) => {
      return response;
    });
  },
  complete: (id: any) => {
    return postData({
      url: `/api/workItemAssignment/complete/${id}`,
      method: "PUT",
    }).then((response) => {
      return response;
    });
  },
  delete: (id: any) => {
    return postData({
      url: `/api/workItemAssignment/delete/${id}`,
      method: "DELETE",
    }).then((response) => {
      return response;
    });
  },
};

export default workItemAssignmentService;
