import {
  GridColDef,
  GridActionsCellItem,
  GridActionsColDef,
} from '@mui/x-data-grid';
import { Paper, IconButton, Grid, Tooltip } from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
} from '@mui/icons-material';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import DataList from '../../../components/DataList';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import roleService from '../../../services/authority/roleService';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useConfirmDeleteDialog from '../../../hooks/useConfirmDeleteDialog';
import { translateRoleName } from '../../../utils/nameFormatters';

export interface Role {
  id: string;
  roleGroupId: string;
}

const RolesPage = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    roleService
      .list()
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) setRows(response.records);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Megnevezés',
      flex: 100,
      valueGetter: (param) => translateRoleName(param.row.name),
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 20,
      getActions: (params: GridRowParams, color: any) => [
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Szerkesztés">
              <EditIcon />
            </Tooltip>
          }
          label="Edit"
          onClick={() => navigate(`/authority/roles/edit/${params.row.id}`)}
        />,
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Törlés">
              <DeleteIcon />
            </Tooltip>
          }
          label="Delete"
          onClick={() =>
            setParams({
              open: true,
              name: 'Biztosan törölni szeretné?',
              onConfirm: async () => handleDelete(params.row.id),
            })
          }
        />,
      ],
    } as GridActionsColDef,
  ];

  const handleDelete = (id: number) => {
    roleService.delete(id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres törlés', {
          variant: 'success',
        });
        setRows(rows.filter((row) => row.id !== id));
      }
    });
  };

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>Szerepkörök</h2>
        </Grid>
        <Grid container item xs={12} p={2} justifyContent="end">
          <Grid item>
            <Tooltip title="Szerepkör létrehozása">
              <IconButton
                component={RouterLink}
                to={`/authority/roles/create`}
                color="primary"
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DataList
            rows={rows}
            columns={columns}
            localStorageKey={'RolePage'}
          />
        </Grid>
        <ConfirmDeleteDialog />
      </Grid>
    </Paper>
  );
};

export default RolesPage;
