import { Box, Button, Chip, useMediaQuery } from '@mui/material';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { reset } from '../stateManagement/actions/acquisitionActions';
import { useNavigate } from 'react-router-dom';
import BookmarkIcon from '@mui/icons-material/Bookmark';

const AcquisitionInProgress = () => {
  const { form, acquisitionInProgress } = useSelector<any>(
    (state) => state.acquisition
  ) as any;

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [dots, setDots] = useState('...');
  const isSmallScreen = useMediaQuery('(max-width:500px)');

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDots((prevDots) => {
        if (prevDots === '...') {
          return '.';
        } else if (prevDots === '.') {
          return '..';
        } else {
          return '...';
        }
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  if (!acquisitionInProgress) {
    return null;
  }
  if (isSmallScreen) {
    return (
      <Button
        title="Beszerzés folyamatban"
        onClick={() => {
          if (
            acquisitionInProgress &&
            !window.location.hash.endsWith('#/acquisitions/create') &&
            !(form.id > 0)
          ) {
            navigate('/acquisitions/create');
          } else if (
            acquisitionInProgress &&
            !window.location.hash.endsWith('#/acquisitions/create') &&
            form.id > 0
          ) {
            navigate(`/acquisitions/edit/${form.id}`);
          }
        }}
      >
        <BookmarkIcon color="secondary" />
      </Button>
    );
  }
  return (
    <Chip
      color="secondary"
      label={<Box style={{ width: 150 }}>Beszerzés folyamatban{dots}</Box>}
      onClick={() => {
        if (
          acquisitionInProgress &&
          !window.location.hash.endsWith('#/acquisitions/create') &&
          !(form.id > 0)
        ) {
          navigate('/acquisitions/create');
        } else if (
          acquisitionInProgress &&
          !window.location.hash.endsWith('#/acquisitions/create') &&
          form.id > 0
        ) {
          navigate(`/acquisitions/edit/${form.id}`);
        }
      }}
      onDelete={() => {
        if (window.location.hash.endsWith('#/acquisitions/create')) {
          navigate(-1);
        }
        dispatch(reset());
      }}
    />
  );
};

export default AcquisitionInProgress;
