import { Box, Grid, Paper, Tab, Tabs, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import useFunctionDescriptor from '../../hooks/useFunctionDescriptor';
import useCheckPermission from '../../hooks/useCheckPermission';
import AccessManagementPage from './accessManagements/AccessManagementPage';
import React from 'react';
import AccessSubjectTypePage from './accessSubjectTypes/AccessSubjectTypePage';
import CertificationPage from './certifications/CertificationPage';
import ContractTypePage from './contractTypes/ContractTypePage';
import MandatoryEducationPage from './mandatoryEducations/MandatoryEducationPage';
import ContractPage from './contracts/ContractPage';
import MissionPage from './missions/MissionPage';
import UserCertificationPage from './userCertifications/UserCertificationPage';

const HRPage = () => {
  const navigate = useNavigate();
  const titleDescriptor = useFunctionDescriptor('HRPage.title');
  const [tabValue, setTabValue] = React.useState(0);
  const { checkPermission } = useCheckPermission();
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    saveTab(newValue);
  };

  useEffect(() => {
    const savedtab = localStorage.getItem(
      `deviceManager_tab${window.location.hash}`
    );
    if (savedtab) {
      setTabValue(JSON.parse(savedtab));
    }
  }, []);

  const saveTab = (tab) => {
    localStorage.setItem(
      `deviceManager_tab${window.location.hash}`,
      JSON.stringify(tab)
    );
  };

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>HR{titleDescriptor}</h2>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ width: '100%' }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                pb: 2,
                maxWidth: isSmallScreen ? '280px' : '100%',
              }}
            >
              <Tabs
                value={tabValue}
                onChange={handleChange}
                TabIndicatorProps={{ style: { background: '#1976d2' } }}
                sx={{
                  maxWidth: isSmallScreen ? '280px' : '100%',
                  overflowX: 'scroll',
                }}
                allowScrollButtonsMobile
                scrollButtons="auto"
                variant="scrollable"
              >
                <Tab
                  label="Felhasználói hozzáférés"
                  sx={{ fontWeight: 'bold' }}
                />
                <Tab
                  label="Felhasználói hozzáférés típus"
                  sx={{ fontWeight: 'bold' }}
                />
                <Tab label="Képesítések" sx={{ fontWeight: 'bold' }} />
                <Tab label="Kötelező oktatások" sx={{ fontWeight: 'bold' }} />
                <Tab label="Kiküldetések" sx={{ fontWeight: 'bold' }} />
                <Tab label="Dolgozói képesítések" sx={{ fontWeight: 'bold' }} />
              </Tabs>
            </Box>
            {checkPermission(['AccessManagementView']) && tabValue === 0 && (
              <AccessManagementPage />
            )}
            {checkPermission(['AccessSubjectTypeView']) && tabValue === 1 && (
              <AccessSubjectTypePage />
            )}
            {checkPermission(['CertificationView']) && tabValue === 2 && (
              <CertificationPage />
            )}
            {checkPermission(['MandatoryEducationView']) && tabValue === 3 && (
              <MandatoryEducationPage />
            )}
            {checkPermission(['MissionView']) && tabValue === 4 && (
              <MissionPage />
            )}
            {checkPermission(['UserCertificationView']) && tabValue === 5 && (
              <UserCertificationPage />
            )}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default HRPage;
