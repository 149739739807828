/* eslint-disable react-hooks/exhaustive-deps */
import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import itemService from '../../../services/wms/itemService';
import warehouseService from '../../../services/wms/warehouseService';
import warehouseTransactionService from '../../../services/wms/warehouseTransactionService';
import { TransactionCommands } from './TransactionCommands';
import TransactionForm from './TransactionForm';

const TransactionCreate = (props: any) => {
  const {
    onSave = null,
    navigateBack = true,
    handleCancel = null,
    command = TransactionCommands.Add,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<string[]>([]);
  const [items, setItems] = useState<any[]>([{ id: '', name: '' }]);
  const [warehouses, setWarehouses] = useState<any[]>([{ id: '', name: '' }]);
  const [entity] = useState<any>({
    itemId: '',
    amount: '',
    warehouseId: '',
    targetWarehouseId: '',
    sourceWarehouseId: '',
    description: '',
  });

  useEffect(() => {
    itemService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setItems(response.records);
      }
    });

    warehouseService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setWarehouses(response.records);
      }
    });
  }, []);

  const onSubmit = (entity: any, setSubmitting: any) => {
    let serviceMethod = getServiceMethod();

    dispatch({ type: 'SHOW_SAVE' });
    serviceMethod(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });
          if (onSave) onSave(response.result);
          if (navigateBack) navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        setSubmitting(false);
      });
  };

  const getServiceMethod = () => {
    switch (command) {
      case TransactionCommands.Add: {
        return warehouseTransactionService.add;
      }
      case TransactionCommands.Discard: {
        return warehouseTransactionService.discard;
      }
      case TransactionCommands.Remove: {
        return warehouseTransactionService.remove;
      }
      case TransactionCommands.Move: {
        return warehouseTransactionService.move;
      }
      default:
        throw new Error('Invalid command');
    }
  };

  return (
    <TransactionForm
      items={items}
      warehouses={warehouses}
      errors={errors}
      onSubmit={onSubmit}
      entity={entity}
      command={command}
      handleCancel={() =>
        handleCancel != null ? handleCancel() : navigate(-1)
      }
    />
  );
};

export default TransactionCreate;
