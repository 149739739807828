import {
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { isBefore } from 'date-fns';
import { formatCurrency } from '../../../utils/valueFormatters';

const RankingOutStandingOrderTableComponent = (props: any) => {
  const {
    myOutstandingOrders,
    page,
    rowsPerPage,
    handleChangePage,
    showGross,
  } = props;
  const isSmallScreen = useMediaQuery('(max-width:1400px)');

  return (
    <Card
      sx={{
        margin: 'auto',
        transition: '0.3s',
        borderRadius: '10px',
        minHeight: '270px',
      }}
    >
      {!isSmallScreen && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <h3>Ügyfél:</h3>
                </TableCell>
                <TableCell>
                  <h3>Összeg:</h3>
                </TableCell>
                <TableCell>
                  <h3>Lejárat:</h3>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {myOutstandingOrders
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((order) => (
                  <TableRow key={order.id}>
                    <TableCell>{order.clientName}</TableCell>
                    <TableCell>
                      {showGross && formatCurrency(order.finalPriceGross)}
                      {!showGross && formatCurrency(order.finalPriceNet)}
                    </TableCell>
                    <TableCell>
                      {
                        <Typography
                          color={
                            isBefore(new Date(order.dueDate), new Date())
                              ? 'red'
                              : 'inherit'
                          }
                        >
                          {new Date(order.dueDate).toLocaleDateString()}
                        </Typography>
                      }
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {isSmallScreen && (
        <TableContainer>
          <Table>
            <TableBody>
              {myOutstandingOrders
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((order, index) => (
                  <>
                    <TableRow style={{ backgroundColor: 'lightgrey' }}>
                      <TableCell colSpan={2}>
                        <Grid container alignItems={'center'}>
                          <Grid item xs={6}>
                            <h3>Ügyfél:</h3>
                          </Grid>
                          <Grid item xs={6}>
                            {order.clientName}
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <h3>Összeg:</h3>
                      </TableCell>
                      <TableCell>
                        {showGross && formatCurrency(order.finalPriceGross)}
                        {!showGross && formatCurrency(order.finalPriceNet)}{' '}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <h3>Lejárat:</h3>
                      </TableCell>
                      <TableCell>
                        {
                          <Typography
                            color={
                              isBefore(new Date(order.dueDate), new Date())
                                ? 'red'
                                : 'inherit'
                            }
                          >
                            {new Date(order.dueDate).toLocaleDateString()}
                          </Typography>
                        }
                      </TableCell>
                    </TableRow>
                  </>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <TablePagination
        rowsPerPageOptions={[5]}
        component="div"
        count={myOutstandingOrders.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
      />
    </Card>
  );
};

export default RankingOutStandingOrderTableComponent;
