import { Box, Grid, Tab, Tabs, useMediaQuery, Badge } from '@mui/material';
import React, { useEffect } from 'react';
import { EntityTypes } from '../../../../../types/EntityTypes';
import ExpensePage from '../../../../erp/expenses/ExpensePage';
import DataList from '../../../../../components/DataList';
import OrderPage from '../../../../sales/orders/OrderPage';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import InvoiceTabPage from '../../../../sales/orders/InvoiceTabPage';

const ClientProfileTabDataList = (props: any) => {
  const {
    entityType = null,
    paidRows = [],
    outstandingRows = [],
    DueRows = [],
  } = props;

  const [tabValue, setTabValue] = React.useState(0);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  const getComponent = () => {
    if (entityType) {
      switch (entityType) {
        case EntityTypes.Expense:
          return (
            <ExpensePage
              externalRows={
                tabValue === 0
                  ? paidRows
                  : tabValue === 1
                  ? outstandingRows
                  : DueRows
              }
            />
          );
          break;
        case EntityTypes.Order:
          return (
            <InvoiceTabPage
              externalRows={
                tabValue === 0
                  ? paidRows
                  : tabValue === 1
                  ? outstandingRows
                  : DueRows
              }
            />
          );
          break;
        default:
          break;
      }
    }
  };

  const component = getComponent();

  return (
    <Grid container item xs={12} p={3}>
      <Grid item xs={12}>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              borderColor: 'divider',
              pb: 2,
              maxWidth: isSmallScreen ? '280px' : '100%',
            }}
          >
            <Tabs
              value={tabValue}
              onChange={handleChange}
              TabIndicatorProps={{ style: { background: '#1976d2' } }}
              sx={{
                maxWidth: isSmallScreen ? '280px' : '100%',
                overflowX: 'scroll',
              }}
              allowScrollButtonsMobile
              scrollButtons="auto"
              variant="scrollable"
            >
              <Tab
                label={
                  <Badge
                    badgeContent={paidRows.length}
                    color="error"
                    sx={{ marginRight: '1rem' }}
                  >
                    <Box alignContent={'center'} sx={{ marginRight: '0.5rem' }}>
                      Teljesített{' '}
                    </Box>
                  </Badge>
                }
                sx={{ fontWeight: 'bold' }}
              />
              <Tab
                label={
                  <Badge
                    badgeContent={outstandingRows.length}
                    color="error"
                    sx={{ marginRight: '1rem' }}
                  >
                    <Box alignContent={'center'} sx={{ marginRight: '0.5rem' }}>
                      Kintlévő{' '}
                    </Box>
                  </Badge>
                }
                sx={{ fontWeight: 'bold' }}
              />
              <Tab
                label={
                  <Badge
                    badgeContent={DueRows.length}
                    color="error"
                    sx={{ marginRight: '1rem' }}
                  >
                    <Box alignContent={'center'} sx={{ marginRight: '0.5rem' }}>
                      Lejárt{' '}
                    </Box>
                  </Badge>
                }
                sx={{ fontWeight: 'bold' }}
              />
            </Tabs>
          </Box>
          {component}
        </Box>
      </Grid>
    </Grid>
  );
};

export default ClientProfileTabDataList;
