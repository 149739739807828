import {
  Button,
  TextField,
  Box,
  Grid,
  Paper,
  Autocomplete,
} from '@mui/material';
import { Form, Formik } from 'formik';
import ClientCreate from '../../crm/clients/ClientCreate';
import CreateEntityDecorator from '../../../components/CreateEntityDecorator';
import useCheckPermission from '../../../hooks/useCheckPermission';

const CashLogForm = (props: any) => {
  const {
    entity,
    onSubmit,
    errors,
    handleCancel,
    readonly = false,
    onClientAdded,
    clients,
    users,
    registers,
  } = props;

  const { checkPermission } = useCheckPermission();
  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        handleChange,
        setFieldValue,
        errors: validationErrors,
      }) => (
        <Form>
          {' '}
          <Paper>
            <Grid container justifyContent="left" spacing={2} p={5}>
              <h2>
                Házipénztár log {values.id > 0 ? 'szerkesztése' : 'létrehozása'}
              </h2>
              <Grid xs={12} item pt={5}>
                <Box color="red">
                  {errors.map((error) => (
                    <li>{error}</li>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="registerId"
                  value={values.cashRegisterId}
                  onChange={(event, value) => {
                    setFieldValue('registerId', value);
                  }}
                  getOptionLabel={(option) => {
                    var found = registers.find((g) => g.id === option);
                    if (found) {
                      return `${found.id}.${found.name}`;
                    } else return '';
                  }}
                  options={registers.map((g) => g.id)}
                  renderInput={(params) => (
                    <TextField {...params} label="Pénztár" fullWidth />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.receiptNumber}
                  onChange={handleChange}
                  label="Bizonylatszám"
                  name="receiptNumber"
                  disabled={readonly}
                  fullWidth
                  error={
                    !!touched.receiptNumber && !!validationErrors.receiptNumber
                  }
                  helperText={
                    !!touched.receiptNumber &&
                    !!validationErrors.receiptNumber &&
                    (validationErrors.receiptNumber as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <CreateEntityDecorator
                  hideAdd={values.id > 0 || checkPermission(['ClientAdd'])}
                  AutocompleteComponent={
                    <Autocomplete
                      disablePortal
                      id="clientId"
                      disabled={readonly}
                      value={values.clientId ?? ''}
                      onChange={(event, value) => {
                        setFieldValue('clientId', value);
                      }}
                      getOptionLabel={(option) => {
                        var found = clients.find((g) => g.id === option);
                        if (found) {
                          return `${
                            found?.companyName === ''
                              ? found.name.fullName
                              : found.companyName
                          } (${found.taxNumber})}`;
                        } else return '';
                      }}
                      options={clients.map((g) => g.id)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Ügyfél"
                          fullWidth
                          error={
                            !!touched.clientId && !!validationErrors.clientId
                          }
                          helperText={
                            !!touched.clientId &&
                            !!validationErrors.clientId &&
                            (validationErrors.clientId as string)
                          }
                        />
                      )}
                    />
                  }
                  onSave={(client) => {
                    onClientAdded(client);
                    setFieldValue('clientId', client.id);
                  }}
                  CreateEntityComponent={ClientCreate}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="userId"
                  disabled={values.id > 0 || readonly}
                  value={values.userId ?? ''}
                  onChange={(event, value) => {
                    setFieldValue('userId', value);
                  }}
                  getOptionLabel={(option) => {
                    var found = users.find((g) => g.id === option);
                    if (found) {
                      return `${found.id}.${found.fullName}`;
                    } else return '';
                  }}
                  options={users.map((g) => g.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Felhasználó"
                      fullWidth
                      error={!!touched.userId && !!validationErrors.userId}
                      helperText={
                        !!touched.userId &&
                        !!validationErrors.userId &&
                        (validationErrors.userId as string)
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.incomeGross}
                  onChange={handleChange}
                  label="Bevétel"
                  name="incomeGross"
                  disabled={readonly}
                  fullWidth
                  error={
                    !!touched.incomeGross && !!validationErrors.incomeGross
                  }
                  helperText={
                    !!touched.incomeGross &&
                    !!validationErrors.incomeGross &&
                    (validationErrors.incomeGross as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.expenseGross}
                  onChange={handleChange}
                  label="Kiadás"
                  name="expenseGross"
                  disabled={readonly}
                  fullWidth
                  error={
                    !!touched.expenseGross && !!validationErrors.expenseGross
                  }
                  helperText={
                    !!touched.expenseGross &&
                    !!validationErrors.expenseGross &&
                    (validationErrors.expenseGross as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.description}
                  onChange={handleChange}
                  label="Leírás"
                  name="description"
                  InputLabelProps={{ shrink: true }}
                  disabled={readonly}
                  fullWidth
                  error={
                    !!touched.description && !!validationErrors.description
                  }
                  helperText={
                    !!touched.description &&
                    !!validationErrors.description &&
                    (validationErrors.description as string)
                  }
                />
              </Grid>
              <Grid container item pt={3} justifyContent="left">
                {!readonly && (
                  <Grid item p={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={
                        isSubmitting ||
                        !(values.clientId > 0 || values.userId > 0)
                      }
                    >
                      Mentés
                    </Button>
                  </Grid>
                )}
                <Grid item p={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleCancel}
                  >
                    Mégse
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default CashLogForm;
