import {
  GridColDef,
  GridActionsCellItem,
  GridActionsColDef,
  GridRowParams,
} from '@mui/x-data-grid';
import {
  Box,
  Paper,
  Grid,
  Tooltip,
  TextField,
  Autocomplete,
  InputAdornment,
} from '@mui/material';
import { PlaylistAddCheck, Visibility } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import DataList from '../../components/DataList';
import warehouseService from '../../services/wms/warehouseService';
import useFunctionDescriptor from '../../hooks/useFunctionDescriptor';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import acquisitionService from '../../services/acquisitions/acquisitionService';
import EntityNavigator from '../../components/EntityNavigator';
import { EntityTypes } from '../../types/EntityTypes';

export interface ItemsUnderThreshold {
  itemId: number;
  count: string;
  minAmount: string;
  name: string;
}

const ItemsUnderThresholdPage = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState<any[]>([]);
  const [itemUnderAcquisitionDialogState, setItemUnderAcquisitionDialogState] =
    useState<any>({
      open: false,
      itemId: 0,
      amount: 0,
    });
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const [warehouses, setWarehouses] = useState<any[]>([]);
  const [selectedWarehouseId, setSelectedWarehouseId] = useState<number>(0);

  const titleDescriptor = useFunctionDescriptor(
    'ItemsUnderThresholdPage.title'
  );

  useEffect(() => {
    if (selectedWarehouseId === 0) return;

    dispatch({ type: 'SHOW_QUERY' });
    warehouseService
      .listItemsUnderThreshold(selectedWarehouseId)
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) setRows(response.records);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, [selectedWarehouseId]);

  useEffect(() => {
    warehouseService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setWarehouses(response.records);
      }
    });
  }, []);

  useEffect(() => {
    let warehouseId = parseInt(
      window.localStorage.getItem('itemsUnderThresholdPage.warehouseId')
    );
    if (!isNaN(warehouseId)) {
      setSelectedWarehouseId(warehouseId);
    }
  }, []);

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];

    // actions.push(
    //   <GridActionsCellItem
    //     color={color ? color : 'primary'}
    //     icon={
    //       <Tooltip title="Termék megjelölése beszerzés alatt lévőnek">
    //         <PlaylistAddCheck />
    //       </Tooltip>
    //     }
    //     label={'Termék megjelölése beszerzés alatt lévőnek'}
    //     onClick={() =>
    //       setItemUnderAcquisitionDialogState({
    //         open: true,
    //         itemId: params.row.itemId,
    //       })
    //     }
    //   />
    // );

    actions.push(
      <EntityNavigator
        entityType={EntityTypes.Item}
        entityId={params.row.itemId}
        value={'Termék adatlapjának megtekintése'}
        showIcon
        readonly={true}
      />
    );

    return actions;
  };

  const columns: GridColDef[] = [
    { field: 'itemNumber', headerName: 'Cikkszám', flex: 100 },
    { field: 'name', headerName: 'Termék', flex: 100 },
    {
      field: 'count',
      headerName: 'Rendelkezésre álló mennyiség',
      flex: 100,
    },
    {
      field: 'underAcquisitionCount',
      headerName: 'Beszerzés alatt álló mennyiség',
      flex: 100,
    },
    {
      field: 'minAmount',
      headerName: 'Minimális raktárkészlet',
      flex: 100,
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 70,
      getActions: getActions,
    } as GridActionsColDef,
  ];

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>Beszerzésre váró termékek{titleDescriptor}</h2>
        </Grid>
        <Grid item xs={12} pb={3}>
          <Autocomplete
            disablePortal
            id="warehouseId"
            value={selectedWarehouseId}
            onChange={(event, value) => {
              window.localStorage.setItem(
                'itemsUnderThresholdPage.warehouseId',
                value
              );
              setSelectedWarehouseId(value);
            }}
            getOptionLabel={(option) =>
              warehouses.find((g) => g.id === option)?.name ?? ''
            }
            options={warehouses.map((g) => g.id)}
            renderInput={(params) => (
              <TextField {...params} fullWidth label="Raktár" />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <DataList
            rows={rows}
            columns={columns}
            getRowId={(x) => x.itemId}
            localStorageKey={'ItemsUnderThresholdPage'}
          />
        </Grid>
      </Grid>
      <Dialog
        open={itemUnderAcquisitionDialogState.open}
        style={{ minHeight: 500 }}
        fullWidth
        onClose={() =>
          setItemUnderAcquisitionDialogState({
            open: false,
            itemId: 0,
            amount: 0,
          })
        }
      >
        <DialogTitle>
          Beszerzés alatt lévő termék mennyiségének megadása
        </DialogTitle>
        <DialogContent>
          <Box p={5}>
            <TextField
              value={itemUnderAcquisitionDialogState.amount}
              onChange={(e) => {
                var value = parseInt(e.target.value);
                if (!isNaN(value))
                  setItemUnderAcquisitionDialogState((x) => {
                    return { ...x, amount: value };
                  });
              }}
              label="Mennyiség"
              type="number"
              name="amount"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Egység</InputAdornment>
                ),
              }}
              error={!itemUnderAcquisitionDialogState.amount}
              helperText={
                !itemUnderAcquisitionDialogState.amount &&
                'Mennyiség megadása kötelező!'
              }
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disabled={itemUnderAcquisitionDialogState.amount === 0}
            onClick={() => {
              dispatch({ type: 'SHOW_SAVE' });

              acquisitionService
                .markItemUnderAcquisition({
                  itemId: itemUnderAcquisitionDialogState.itemId,
                  amount: itemUnderAcquisitionDialogState.amount,
                })
                .then((response) => {
                  if (response.canceled) return;
                  if (response.hasError) {
                    enqueueSnackbar(response.errorMessages.join(','), {
                      variant: 'error',
                    });
                  } else {
                    enqueueSnackbar('Sikeres mentés!', {
                      variant: 'success',
                    });

                    var row = rows.find(
                      (x) => x.itemId === itemUnderAcquisitionDialogState.itemId
                    );
                    row = {
                      ...row,
                      underAcquisitionCount:
                        row.underAcquisitionCount +
                        itemUnderAcquisitionDialogState.amount,
                    };

                    var filtered = rows.filter(
                      (x) => x.itemId !== itemUnderAcquisitionDialogState.itemId
                    );

                    setRows([...filtered, row]);

                    setItemUnderAcquisitionDialogState({
                      itemId: 0,
                      open: false,
                      amount: 0,
                    });
                  }
                })
                .finally(() => {
                  dispatch({ type: 'HIDE' });
                });
            }}
          >
            Hozzáadás
          </Button>
          <Button
            color="error"
            variant="outlined"
            onClick={() =>
              setItemUnderAcquisitionDialogState({
                itemId: 0,
                open: false,
                amount: 0,
              })
            }
          >
            Mégse
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default ItemsUnderThresholdPage;
