import { Box } from '@mui/material';
import React from 'react';
import StockTakingPage from '../stockTaking/StockTakingPage';
import StockTakingHistory from '../stockTaking/StockTakingHistory';
import ItemsUnderThresholdPage from '../itemsUnderThreshold/ItemsUnderThresholdPage';
import StockReplenishmentPage from '../stockReplenishment/StockReplenishmentPage';
import OrderPage from '../sales/orders/OrderPage';
import SalesPage from '../sales/SalesPage';
import CustomPricePage from '../pricing/customPrices/CustomPricePage';
import AcquisitionPricePage from '../pricing/acquisitionPrices/AcquisitionPricePage';
import ItemPricePage from '../pricing/itemPrices/ItemPricePage';
import UserPage from '../authority/users/roles/UserPage';
import RolePage from '../authority/roles/RolePage';
import AcquisitionsPage from '../acquisitions/AcquisitionPage';
import UserGroupPage from '../authority/userGroups/UserGroupPage';
import ChimneyErrorCodePage from '../chimneySweeping/chimneyErrorCodes/ChimneyErrorCodePage';
import ChimneySweepingCertificatePage from '../chimneySweeping/chimneySweepingCertificate/ChimneySweepingCertificatePage';
import ChimneyTypePage from '../chimneySweeping/chimneyTypes/ChimneyTypePage';
import ChimneyUseCasePage from '../chimneySweeping/chimneyUseCases/ChimneyUseCasePage';
import ChimneyPage from '../chimneySweeping/chimneys/ChimneyPage';
import DecisionPage from '../chimneySweeping/decisions/DecisionPage';
import PerformanceCategoryPage from '../chimneySweeping/performanceCategories/PerformanceCategoryPage';
import ClientsPage from '../crm/clients/ClientsPage';
import CustomersPage from '../crm/clients/customers/CustomersPage';
import DeviceManagementPage from '../deviceManagement/DeviceManagementPage';
import ComputerPage from '../deviceManagement/computers/ComputerPage';
import InstrumentPage from '../deviceManagement/instruments/InstrumentPage';
import MobilePage from '../deviceManagement/mobiles/MobilePage';
import PeripheryPage from '../deviceManagement/periherals/PeripheryPage';
import SerialNumberedBlockTypePage from '../deviceManagement/serialNumberedBlockTypes/SerialNumberedBlockTypePage';
import SerialNumberedBlockPage from '../deviceManagement/serialNumberedBlocks/SerialNumberedBlockPage';
import SimCardPage from '../deviceManagement/simCards/SimCardPage';
import ToolPage from '../deviceManagement/tools/ToolPage';
import VehicleServicePage from '../deviceManagement/vehicleServices/VehicleServicePage';
import VehiclePage from '../deviceManagement/vehicles/VehiclePage';
import WorkClothesPage from '../deviceManagement/workClothes/WorkClothesPage';
import EmailTemplateHomePage from '../emails/emailTemplates/EmailTemplateHomePage';
import AddressPage from '../erp/addresses/AddressPage';
import ClosingSheetPage from '../erp/closingSheets/ClosingSheetPage';
import ExpenseTypePage from '../erp/expenseTypes/ExpenseTypePage';
import ExpensePage from '../erp/expenses/ExpensePage';
import HolidayPage from '../erp/holidays/HolidayPage';
import IrregularWorkdayPage from '../erp/irregularWorkdays/IrregularWorkdayPage';
import OfferPage from '../erp/offers/OfferPage';
import ProjectPage from '../erp/projects/ProjectPage';
import ServiceCategorysPage from '../erp/serviceCategories/ServiceCategoryPage';
import ServicesPage from '../erp/services/ServicePage';
import TaskTypesPage from '../erp/taskTypes/TaskTypesPage';
import TaskPage from '../erp/tasks/TaskPage';
import WorkItemsPage from '../erp/workItems/WorkItemPage';
import HRPage from '../hr/HRPage';
import AccessManagementPage from '../hr/accessManagements/AccessManagementPage';
import AccessSubjectTypePage from '../hr/accessSubjectTypes/AccessSubjectTypePage';
import CertificationPage from '../hr/certifications/CertificationPage';
import ContractTypePage from '../hr/contractTypes/ContractTypePage';
import ContractPage from '../hr/contracts/ContractPage';
import MandatoryEducationPage from '../hr/mandatoryEducations/MandatoryEducationPage';
import MissionPage from '../hr/missions/MissionPage';
import UserCertificationPage from '../hr/userCertifications/UserCertificationPage';
import TaxTypePage from '../pricing/TaxTypes/TaxTypePage';
import PriceCategoryPage from '../pricing/priceCategories/PriceCategoryPage';
import EmployeeRankingPage from '../statistics/EmployeeRankingPage';
import FinancePage from '../statistics/FinancePage';
import SystemConfigurationsPage from '../systemConfigurations/SystemConfigurationPage';
import ItemSupplyPage from '../wms/ItemSupplies/ItemSupplyPage';
import BrandPage from '../wms/brands/BrandPage';
import ItemGroupPage from '../wms/itemGroups/ItemGroupPage';
import ItemPage from '../wms/items/ItemPage';
import PropertyPage from '../wms/properties/PropertyPage';
import WarehouseGroupsPage from '../wms/warehouseGroups/WarehouseGroupsPage';
import WarehousesPage from '../wms/warehouses/WarehousesPage';
import Calendar from 'react-calendar';
import UserCalendar from '../erp/Calendar/UserCalendar';
import InvoiceTabPage from '../sales/orders/InvoiceTabPage';
import QueryTabPage from '../queries/QueryTabPage';

const HomePage = (props: any) => {
  const { path } = props;

  const getComponent = (path: string) => {
    switch (path) {
      case '/stockTaking':
        return <StockTakingPage />;
      case '/queries':
        return <QueryTabPage />;
      case '/stockTakingHistory':
        return <StockTakingHistory />;
      case '/itemsUnderThreshold':
        return <ItemsUnderThresholdPage />;
      case '/stockReplenishment':
        return <StockReplenishmentPage />;
      case 'sales/documents':
        return <InvoiceTabPage />;
      case '/sales/order':
        return <SalesPage />;
      case '/pricing/customPrices':
        return <CustomPricePage />;
      case '/pricing/acquisitionPrices':
        return <AcquisitionPricePage />;
      case '/pricing/itemPrices':
        return <ItemPricePage />;
      case '/pricing/priceCategories':
        return <PriceCategoryPage />;
      case '/acquisitions':
        return <AcquisitionsPage />;
      case '/wms/properties':
        return <PropertyPage />;
      case '/wms/items':
        return <ItemPage />;
      case '/wms/groups':
        return <WarehouseGroupsPage />;
      case '/wms/warehouses':
        return <WarehousesPage />;
      case '/wms/itemGroups':
        return <ItemGroupPage />;
      case '/erp/userCalendar':
        return <UserCalendar />;
      case '/wms/brands':
        return <BrandPage />;
      case '/crm/clients':
        return <ClientsPage />;
      case '/crm/addresses':
        return <AddressPage />;
      case '/crm/customers':
        return <CustomersPage />;
      case '/authority/userGroups':
        return <UserGroupPage />;
      case '/authority/roles':
        return <RolePage />;
      case '/authority/users':
        return <UserPage />;
      case '/systemConfigurations':
        return <SystemConfigurationsPage />;
      case '/erp/serviceCategories':
        return <ServiceCategorysPage />;
      case '/erp/services':
        return <ServicesPage />;
      case '/erp/taskTypes':
        return <TaskTypesPage />;
      case '/erp/expenseTypes':
        return <ExpenseTypePage />;
      case '/erp/expenses':
        return <ExpensePage />;
      case '/erp/tasks':
        return <TaskPage />;
      case '/erp/closingSheets':
        return <ClosingSheetPage />;
      case '/wms/itemSupplies':
        return <ItemSupplyPage />;
      case '/erp/workItems':
        return <WorkItemsPage />;
      case '/erp/offers':
        return <OfferPage />;
      case '/erp/irregularWorkdays':
        return <IrregularWorkdayPage />;
      case '/erp/holidays':
        return <HolidayPage />;
      case '/erp/emailTemplates':
        return <EmailTemplateHomePage />;
      case '/statistics/employeeRanking':
        return <EmployeeRankingPage />;
      case '/statistics/finance':
        return <FinancePage />;
      case '/pricing/taxTypes':
        return <TaxTypePage />;
      case '/erp/performanceCategories':
        return <PerformanceCategoryPage />;
      case '/erp/chimneyUseCases':
        return <ChimneyUseCasePage />;
      case '/erp/chimneyErrorCodes':
        return <ChimneyErrorCodePage />;
      case '/erp/chimneyTypes':
        return <ChimneyTypePage />;
      case '/erp/decisions':
        return <DecisionPage />;
      case '/erp/projects':
        return <ProjectPage />;
      case '/erp/chimneys':
        return <ChimneyPage />;
      case '/erp/chimneySweepingCertificates':
        return <ChimneySweepingCertificatePage />;
      case '/erp/computers':
        return <ComputerPage />;
      case '/erp/mobiles':
        return <MobilePage />;
      case '/erp/userCertifications':
        return <UserCertificationPage />;
      case '/erp/tools':
        return <ToolPage />;
      case '/erp/missions':
        return <MissionPage />;
      case '/erp/contracts':
        return <ContractPage />;
      case '/erp/mandatoryEducations':
        return <MandatoryEducationPage />;
      case '/erp/contractTypes':
        return <ContractTypePage />;
      case '/erp/certifications':
        return <CertificationPage />;
      case '/erp/accessSubjectTypes':
        return <AccessSubjectTypePage />;
      case '/erp/hr':
        return <HRPage />;
      case '/erp/accessManagements':
        return <AccessManagementPage />;
      case '/erp/vehicleServices':
        return <VehicleServicePage />;
      case '/erp/workClothes':
        return <WorkClothesPage />;
      case '/erp/vehicles':
        return <VehiclePage />;
      case '/erp/simCards':
        return <SimCardPage />;
      case '/erp/instruments':
        return <InstrumentPage />;
      case '/erp/peripherals':
        return <PeripheryPage />;
      case '/erp/serialnumberedblocks':
        return <SerialNumberedBlockPage />;
      case '/erp/serialnumberedblocktypes':
        return <SerialNumberedBlockTypePage />;
      case '/erp/deviceManagementPage':
        return <DeviceManagementPage />;
      default:
        return '';
    }
  };

  return <Box>{getComponent(path)}</Box>;
};

export default HomePage;
