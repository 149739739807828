import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { Form, Formik } from "formik";

const IrregularWorkdayForm = (props: any) => {
  const { entity, onSubmit, errors, handleCancel, readonly = false } = props;

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        setFieldValue,
        handleChange,
        errors: validationErrors,
      }) => (
        <Form>
          {" "}
          <Paper>
            <Grid container justifyContent="left" spacing={2} p={5}>
              <h2>
                Rendellenes munkanapok{" "}
                {values.id > 0 ? "szerkesztése" : "létrehozása"}
              </h2>
              <Grid xs={12} item pt={5}>
                <Box color="red">
                  {errors.map((error) => (
                    <li>{error}</li>
                  ))}
                </Box>
              </Grid>
              <Grid container item xs={12} spacing={2} alignItems="center">
                <Grid item>
                  <DesktopDatePicker
                    label="dátum"
                    value={values.date}
                    inputFormat="yyyy-MM-dd"
                    disabled={readonly}
                    onChange={(value) => setFieldValue("date", value)}
                    renderInput={(params) => (
                      <TextField
                        required
                        fullWidth
                        {...params}
                        error={!!touched.date && !!validationErrors.date}
                        helperText={
                          !!touched.date &&
                          !!validationErrors.date &&
                          (validationErrors.date as string)
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <ToggleButtonGroup
                    value={values.isWorkday.toString()}
                    exclusive
                    color="primary"
                    onChange={(event, value) => {
                      setFieldValue("isWorkday", value === "true");
                    }}
                  >
                    <ToggleButton value="true">Munkanap</ToggleButton>
                    <ToggleButton value="false">Szabadnap</ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
              <Grid container item pt={3} justifyContent="left">
                {!readonly && (
                  <Grid item p={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      Mentés
                    </Button>
                  </Grid>
                )}
                <Grid item p={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleCancel}
                  >
                    Mégse
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default IrregularWorkdayForm;
