import {
  GridColDef,
  GridActionsCellItem,
  GridActionsColDef,
} from '@mui/x-data-grid';
import {
  Paper,
  IconButton,
  Grid,
  Tooltip,
  FormControlLabel,
  Checkbox,
  Badge,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Visibility,
  FileUpload,
} from '@mui/icons-material';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import DataList from '../../../components/DataList';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useCheckPermission from '../../../hooks/useCheckPermission';
import useFunctionDescriptor from '../../../hooks/useFunctionDescriptor';
import taskService from '../../../services/erp/taskService';
import { parseJSON } from 'date-fns';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useConfirmDialog from '../../../hooks/useConfirmDialog';
import appService from '../../../services/appService';
import TaskLogDialog from './TaskLogDialog';
import useConfirmDeleteDialog from '../../../hooks/useConfirmDeleteDialog';
import HistoryIcon from '@mui/icons-material/History';
import taskLogService from '../../../services/erp/taskLogService';
import TaskLogFormDialog from './TaskLogFormDialog';
import * as React from 'react';
import { AttachmentTypes } from '../../../types/AttachmentTypes';
import FilePageDialog from '../../../components/FilePageDialog';
import { ChimneySweepingCertificate } from '../../chimneySweeping/chimneySweepingCertificate/ChimneySweepingCertificatePage';
import AttachFileIcon from '@mui/icons-material/AttachFile';

const TaskPage = () => {
  const navigate = useNavigate();
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const [rows, setRows] = useState<any[]>([]);
  const [filePageDialogOpen, setFilePageDialogOpen] = useState<any>({
    open: false,
    entityUniqueId: null,
  });
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const [checkboxValue, setCheckboxValue] = useState<boolean>(true);
  const titleDescriptor = useFunctionDescriptor('TaskPage.title');
  const [showCompletedTasks, setShowCompletedTasks] = useState(false);
  const [triggerUpdate, setTriggerUpdate] = useState(false);

  const [filteredRows, setFilteredRows] = useState<any[]>([]);

  const [taskLogFormDialogState, setTaskLogFormDialogState] =
    React.useState<any>({
      open: false,
      readOnly: false,
      taskId: 0,
      entity: {
        id: 0,
        taskId: 0,
        description: '',
        workHours: 0,
        isTaskCompleted: false,
      },
    });

  const [taskLogDialog, setTaskLogDialog] = useState<any>({
    open: false,
    taskId: 0,
  });

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    if (checkPermission(['TaskViewAll']) && !checkboxValue) {
      taskService
        .list()
        .then((response) => {
          if (response.canceled) return;
          if (!response.hasError) {
            setRows(response.records);
            if (showCompletedTasks) {
              setFilteredRows(response.records);
            } else {
              setFilteredRows(
                response.records.filter((row) => !row.isCompleted)
              );
            }
          } else
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
        })
        .finally(() => dispatch({ type: 'HIDE' }));
    } else {
      taskService
        .listmy()
        .then((response) => {
          if (response.canceled) return;
          if (!response.hasError) {
            setRows(response.records);
            if (showCompletedTasks) {
              setFilteredRows(response.records);
            } else {
              setFilteredRows(
                response.records.filter((row) => !row.isCompleted)
              );
            }
          } else
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
        })
        .finally(() => dispatch({ type: 'HIDE' }));
    }
  }, [showCompletedTasks, checkboxValue, taskLogDialog.open, triggerUpdate]);

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];

    if (checkPermission(['CreateFileAttachment'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Fájl feltöltése">
              <Badge
                badgeContent={params.row.attachmentCount}
                sx={{
                  padding: '0 4px',
                  '& .MuiBadge-badge': {
                    fontSize: 13,
                    height: 15,
                    minWidth: 15,
                    top: 5,
                  },
                }}
                color="error"
              >
                <AttachFileIcon />
              </Badge>
            </Tooltip>
          }
          label="Fájl feltöltése"
          onClick={() => {
            setFilePageDialogOpen({
              open: true,
              entityUniqueId: params.row.uniqueId,
            });
          }}
        />
      );
    }

    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          <Tooltip title="Napló hozzáadása">
            <AddIcon />
          </Tooltip>
        }
        label="Napló hozzáadása"
        onClick={() =>
          setTaskLogFormDialogState({
            open: true,
            entity: {
              id: 0,
              taskId: params.row.id,
              description: '',
              workHours: 0,
              isTaskCompleted: false,
            },
            readOnly: false,
            taskId: params.row.id,
          })
        }
      />
    );

    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          <Tooltip title="Napló megtekintése">
            <HistoryIcon />
          </Tooltip>
        }
        label="Napló megtekintése"
        showInMenu
        onClick={() =>
          setTaskLogDialog({
            open: true,
            taskId: params.row.id,
          })
        }
      />
    );

    let readonly = !checkPermission(['TaskEdit']);
    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          readonly ? (
            <Tooltip title="Megtekintés">
              <Visibility />
            </Tooltip>
          ) : (
            <Tooltip title="Szerkesztés">
              <EditIcon />
            </Tooltip>
          )
        }
        label={readonly ? 'Megtekintés' : 'Szerkesztés'}
        onClick={() => navigate(`/erp/tasks/edit/${params.row.id}`)}
        showInMenu
      />
    );

    if (checkPermission(['TaskDelete'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Törlés">
              <DeleteIcon />
            </Tooltip>
          }
          label="Törlés"
          showInMenu
          onClick={() =>
            setParams({
              open: true,
              name: '',
              onConfirm: async () => handleDelete(params.row.id),
            })
          }
        />
      );
    }

    return actions;
  };

  const columns: GridColDef[] = [
    {
      field: 'employeeName',
      headerName: 'Dolgozó',
      flex: 100,
    },
    {
      field: 'date',
      headerName: 'Dátum',
      flex: 100,
      valueFormatter: (params) => {
        return parseJSON(params.value).toLocaleDateString();
      },
    },

    { field: 'projectName', headerName: 'Projekt', flex: 200 },
    { field: 'description', headerName: 'Leírás', flex: 200 },
    { field: 'workHourSum', headerName: 'Összes munkaóra', flex: 100 },
    {
      field: 'isCompleted',
      headerName: 'Státusz',
      flex: 200,
      renderCell: (params) => {
        return (
          <span
            style={{
              color: params.value ? 'green' : 'orange',
              fontWeight: 'bold',
            }}
          >
            {params.value ? 'Kész' : 'Folyamatban'}
          </span>
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 150,
      align: 'right',
      getActions: getActions,
    } as GridActionsColDef,
  ];

  const handleDelete = (id: number) => {
    taskService.delete(id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres törlés', {
          variant: 'success',
        });
        setFilteredRows(filteredRows.filter((row) => row.id !== id));
      }
    });
  };
  const handleShowCompletedTasks = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setShowCompletedTasks(event.target.checked);
    if (event.target.checked) {
      setFilteredRows(rows);
    } else {
      setFilteredRows(rows.filter((row) => !row.isCompleted));
    }
  };
  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>Feladatok{titleDescriptor}</h2>
        </Grid>
        <Grid container item p={2}>
          <Grid item xs={12} md={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showCompletedTasks}
                  onChange={handleShowCompletedTasks}
                />
              }
              label="Teljesített feladatok"
            />
          </Grid>
          <Grid item xs={12} md={9}>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={!checkPermission(['ClosingSheetViewAll'])}
                  checked={checkboxValue}
                  onChange={(e, checked) => {
                    setCheckboxValue(checked);
                  }}
                />
              }
              label={'Saját adatok'}
            />
          </Grid>
          {checkPermission(['TaskCreate']) && (
            <Grid item xs={1} textAlign={'right'}>
              <Tooltip title="Feladat létrehozása">
                <IconButton
                  component={RouterLink}
                  to={`/erp/tasks/create`}
                  color="primary"
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>

        <Grid item xs={12}>
          <DataList
            rows={filteredRows}
            columns={columns}
            localStorageKey={'TaskPage'}
          />
        </Grid>
        <ConfirmDeleteDialog />
        <FilePageDialog
          onList={(length) => {
            setRows((prev) => {
              return prev.map((row) => {
                if (row.uniqueId === filePageDialogOpen.entityUniqueId) {
                  row.attachmentCount = length;
                }
                return row;
              });
            });
          }}
          open={filePageDialogOpen.open}
          onClose={() =>
            setFilePageDialogOpen({ open: false, entityUniqueId: null })
          }
          attachmentType={AttachmentTypes.Task}
          entityUniqueId={filePageDialogOpen.entityUniqueId}
          localStorageKey={'TaskPage'}
        />
        <TaskLogDialog
          open={taskLogDialog.open}
          setOpen={setTaskLogDialog}
          taskId={taskLogDialog.taskId}
        />
        <TaskLogFormDialog
          open={taskLogFormDialogState.open}
          setOpen={setTaskLogFormDialogState}
          taskId={taskLogFormDialogState.taskId}
          readOnly={false}
          updateRows={(result: any) => {
            setTriggerUpdate(!triggerUpdate);
          }}
        />
      </Grid>
    </Paper>
  );
};

export default TaskPage;
