// mui dialog to get reason from user

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import React from "react";

interface ReasonDialogProps {
  open: boolean;
  setReasonDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: (reason: string) => void;
}

const ReasonDialog: React.FC<ReasonDialogProps> = (props) => {
  const { open, onClose, setReasonDialogOpen } = props;
  const [reason, setReason] = React.useState("");

  return (
    <Dialog open={open} onClose={() => setReasonDialogOpen(false)}>
      <DialogTitle>Indoklás</DialogTitle>
      <DialogContent style={{ paddingTop: 10 }}>
        <TextField
          value={reason}
          multiline
          rows={4}
          onChange={(e) => setReason(e.target.value)}
          label="Indok"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(reason)}>Mentés</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReasonDialog;
