import { createReducer } from '@reduxjs/toolkit';
import {
  setPriceCategories,
  setSelectedPriceCategoryId,
} from '../actions/userPriceCategoryActions';

const initialState = {
  priceCategories: [],
  selectedPriceCategoryId: null,
};

export const userPriceCategoryReducer = createReducer(
  initialState,
  (builder) => {
    builder.addCase(setSelectedPriceCategoryId, (state, action) => {
      state.selectedPriceCategoryId = action.payload;
    });
    builder.addCase(setPriceCategories, (state, action) => {
      state.priceCategories = action.payload;
    });
  }
);
