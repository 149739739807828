import {
  GridColDef,
  GridActionsCellItem,
  GridActionsColDef,
} from '@mui/x-data-grid';
import { Paper, IconButton, Grid, Tooltip } from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Visibility,
} from '@mui/icons-material';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import DataList from '../../../components/DataList';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useCheckPermission from '../../../hooks/useCheckPermission';
import useFunctionDescriptor from '../../../hooks/useFunctionDescriptor';
import useConfirmDeleteDialog from '../../../hooks/useConfirmDeleteDialog';
import serialnumberedblockService from '../../../services/deviceManagement/serialNumberedBlockService';
import computerService from '../../../services/deviceManagement/computerService';
import MoveAssetDialog from '../MoveAssetDialog';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import CompanyAssetLogDialog from '../CompanyAssetLogDialog';
import ArticleIcon from '@mui/icons-material/Article';

export interface SerialNumberedBlock {
  serialNumber: string;
  type: string;
  oSVersion: string;
  other: string;
  purchaseDate: Date;
}

const SerialNumberedBlockPage = () => {
  const navigate = useNavigate();
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const [rows, setRows] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [assetLogDialog, setAssetLogDialog] = useState<any>({
    open: false,
    companyAssetId: 0,
  });
  const dispatch = useDispatch<any>();
  const [moveAssetDialog, setMoveAssetDialog] = useState<any>({
    open: false,
    entity: { assetId: 0, userId: 0, companyId: 0, warehouseId: 0 },
  });
  const titleDescriptor = useFunctionDescriptor(
    'SerialNumberedBlocksPage.title'
  );

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    serialnumberedblockService
      .list()
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) setRows(response.records);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];

    if (checkPermission(['CompanyAssetMove'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Mozgatás">
              <DriveFileMoveIcon />
            </Tooltip>
          }
          label="Mozgatás"
          onClick={() =>
            setMoveAssetDialog({
              open: true,
              entity: {
                assetId: params.row.companyAssetId,
                userId: params.row.holderId,
                companyId: params.row.companyId,
                warehouseId: params.row.warehouseId,
              },
            })
          }
          showInMenu
        />
      );
    }

    let readonly = !checkPermission(['SerialNumberedBlockEdit']);
    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          readonly ? (
            <Tooltip title="Megtekintés">
              <Visibility />
            </Tooltip>
          ) : (
            <Tooltip title="Szerkesztés">
              <EditIcon />
            </Tooltip>
          )
        }
        label={readonly ? 'Megtekintés' : 'Szerkesztés'}
        onClick={() =>
          navigate(`/erp/serialnumberedblocks/edit/${params.row.id}`)
        }
      />
    );
    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          <Tooltip title="Napló">
            <ArticleIcon />
          </Tooltip>
        }
        label={'Napló'}
        onClick={() =>
          setAssetLogDialog({
            open: true,
            companyAssetId: params.row.companyAssetId,
          })
        }
        showInMenu
      />
    );
    if (checkPermission(['SerialNumberedBlockDelete'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Törlés">
              <DeleteIcon />
            </Tooltip>
          }
          label="Törlés"
          onClick={() =>
            setParams({
              open: true,
              name: '',
              onConfirm: async () => handleDelete(params.row.id),
            })
          }
          showInMenu
        />
      );
    }

    return actions;
  };

  const columns: GridColDef[] = [
    { field: 'numberRange', headerName: 'Tömbszámozás', flex: 200 },
    {
      field: 'openingDate',
      headerName: 'Nyitás dátuma',
      valueFormatter(params) {
        return new Date(params.value as Date).toLocaleDateString();
      },
      flex: 200,
    },
    {
      field: 'closingDate',
      headerName: 'Zárás dátuma',
      valueFormatter(params) {
        return new Date(params.value as Date).toLocaleDateString();
      },
      flex: 200,
    },
    { field: 'companyName', headerName: 'Cég', flex: 100 },
    { field: 'warehouseName', headerName: 'Raktár', flex: 200 },
    { field: 'holderName', headerName: 'Felelős', flex: 200 },
    { field: 'typeName', headerName: 'Típus', flex: 100 },
    {
      field: 'actions',
      type: 'actions',
      flex: 200,
      getActions: getActions,
    } as GridActionsColDef,
  ];

  const handleDelete = (id: number) => {
    serialnumberedblockService.delete(id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres törlés', {
          variant: 'success',
        });
        setRows(rows.filter((row) => row.id !== id));
      }
    });
  };

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>Számozott tömbök{titleDescriptor}</h2>
        </Grid>
        {checkPermission(['SerialNumberedBlockCreate']) && (
          <Grid container item xs={12} p={2} justifyContent="end">
            <Grid item>
              <Tooltip title="Számozott tömbök létrehozása">
                <IconButton
                  component={RouterLink}
                  to={`/erp/serialnumberedblocks/create`}
                  color="primary"
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <DataList
            rows={rows}
            columns={columns}
            localStorageKey={'SerialNumberedBlockPage'}
          />
        </Grid>
        <ConfirmDeleteDialog />
        <MoveAssetDialog
          open={moveAssetDialog.open}
          baseEntity={moveAssetDialog.entity}
          close={() => {
            setMoveAssetDialog({
              open: false,
              entity: { assetId: 0, userId: 0, companyId: 0, warehouseId: 0 },
            });
            dispatch({ type: 'SHOW_QUERY' });
            serialnumberedblockService
              .list()
              .then((response) => {
                if (response.canceled) return;
                if (!response.hasError) setRows(response.records);
                else
                  enqueueSnackbar(response.errorMessages.join(','), {
                    variant: 'error',
                  });
              })
              .finally(() => dispatch({ type: 'HIDE' }));
          }}
        />
        <CompanyAssetLogDialog
          open={assetLogDialog.open}
          assetId={assetLogDialog.companyAssetId}
          setOpen={setAssetLogDialog}
        />
      </Grid>
    </Paper>
  );
};

export default SerialNumberedBlockPage;
