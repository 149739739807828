import { createReducer } from "@reduxjs/toolkit";
import { add } from "../actions/stockTakingActions";

const initialState = {
  stockTakingItems: [],
  stockTaking: {},
};

export const stockTakingReducer = createReducer(initialState, (builder) => {
  builder.addCase(add, (state, action) => {});
});
