import axios from 'axios';
import { postData } from './axiosUtils';

const szamlazzhuService = {
  startSync: (format: string, clientId: number) => {
    return axios
      .post(`/api/szamlazzhu/startSync?format=${format}&clientId=${clientId}`)
      .then((response) => {
        return response.data;
      });
  },
};

export default szamlazzhuService;
