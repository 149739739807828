import axios from 'axios';
import { postData } from '../axiosUtils';

const warehouseService = {
  list: (employeeId: number = null) => {
    let queryparams = employeeId ? '?employeeId=' + employeeId : '';

    return axios.get(`/api/warehouse/list${queryparams}`).then((response) => {
      return response.data;
    });
  },
  listItemsUnderThreshold: (warehouseId: number) => {
    return axios
      .get(`/api/warehouse/listItemsUnderThreshold?warehouseId=${warehouseId}`)
      .then((response) => {
        return response.data;
      });
  },
  listItems: (id: number, listComplexItems: boolean = true) => {
    return axios
      .get(
        `/api/warehouse/listItems?id=${id}&listComplexItems=${listComplexItems}`
      )
      .then((response) => {
        return response.data;
      });
  },
  listUser: (id: number) => {
    return axios
      .get(`/api/warehouse/listUserWarehouses?id=${id}`)
      .then((response) => {
        return response.data;
      });
  },
  get: (id: any) => {
    return axios.get(`/api/warehouse/details/${id}`).then((response) => {
      return response.data;
    });
  },
  create: (entity: any) => {
    return postData({
      url: `/api/warehouse/create`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  update: (entity: any) => {
    return postData({
      url: `/api/warehouse/update`,
      data: entity,
      method: 'PUT',
    }).then((response) => {
      return response;
    });
  },
  setMinAmount: (entity: any) => {
    return postData({
      url: `/api/warehouse/setMinAmount`,
      data: entity,
      method: 'PUT',
    }).then((response) => {
      return response;
    });
  },
  delete: (id: any) => {
    return postData({
      url: `/api/warehouse/delete/${id}`,
      method: 'DELETE',
    }).then((response) => {
      return response;
    });
  },
};

export default warehouseService;
