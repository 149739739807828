import StarIcon from '@mui/icons-material/Star';
import { Grid, Rating, Typography } from '@mui/material';
import RankingCardComponent from './RankingCardComponent';

const RankingPodiumComponent = (props: any) => {
  const { allData } = props;
  return (
    <Grid container item xs={12} pb={2}>
      <Grid item container xs={12} justifyContent={'center'} pb={2}>
        <Grid item xs={12} xl={6}>
          <RankingCardComponent
            allData={allData[0]}
            cardHeader={
              <Grid item>
                <Typography
                  fontWeight={'bold'}
                  display="flex"
                  textAlign="center"
                >
                  <Typography fontWeight={'bold'}>1.</Typography>
                </Typography>
              </Grid>
            }
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        justifyContent={'space-between'}
        spacing={2}
        pt={2}
      >
        <Grid item xs={12} xl={6}>
          <RankingCardComponent
            allData={allData[1]}
            cardHeader={
              <Typography
                fontWeight={'bold'}
                display="flex"
                alignItems="center"
              >
                <Typography fontWeight={'bold'}>2.</Typography>
              </Typography>
            }
          />
        </Grid>
        <Grid item xs={12} xl={6}>
          <RankingCardComponent
            allData={allData[2]}
            cardHeader={
              <Typography
                fontWeight={'bold'}
                display="flex"
                alignItems="center"
              >
                <Typography fontWeight={'bold'}>3.</Typography>
              </Typography>
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RankingPodiumComponent;
