import { Autocomplete, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers";
import { useSnackbar } from "notistack";
import * as React from "react";
import CreateEntityDecorator from "../../../../components/CreateEntityDecorator";
import useCheckPermission from "../../../../hooks/useCheckPermission";
import CertificationCreate from "../../../hr/certifications/CertificationCreate";
import { useSelector } from "react-redux";
import AbilityCreate from "../../abilities/AbilityCreate";
export default function AddUserAbilityDialog(props: any) {
  const { open, setOpen, abilities, setAbilities, onSave } = props;

  const { enqueueSnackbar } = useSnackbar();

  const [ability, setAbility] = React.useState({
    id: 0,
    name: "",
    description: "",
  });

  const handleClose = () => {
    setAbility({
      id: 0,
      description: "",
      name: "",
    });
    setOpen(false);
  };

  const { checkPermission } = useCheckPermission();

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth>
      <DialogTitle>Képesség felvétele</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} style={{ paddingTop: 10 }}>
          <Grid item xs={12}>
            <CreateEntityDecorator
              hideAdd={!checkPermission(["AbilityCreate"])}
              AutocompleteComponent={
                <Autocomplete
                  disablePortal
                  id="abilityId"
                  value={ability}
                  onChange={(event, value: any) => {
                    setAbility(value);
                  }}
                  getOptionLabel={(option) => {
                    var abi = abilities.find((g) => g.id === option.id);
                    if (abi == null || abi === undefined) {
                      return "";
                    }

                    return `${abi.name}`;
                  }}
                  options={abilities?.map((cert) => cert)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label="Képesség"
                      fullWidth
                    />
                  )}
                />
              }
              onSave={(abi) => {
                setAbilities([...abilities, abi]);
                setAbility(abi);
              }}
              CreateEntityComponent={AbilityCreate}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={ability.description ?? ""}
              label="Leírás"
              fullWidth
              multiline
              disabled
              minRows={3}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={ability.id === 0}
          onClick={() => {
            onSave(ability);

            handleClose();
          }}
        >
          Hozzáadás
        </Button>
        <Button color="primary" variant="outlined" onClick={handleClose}>
          Mégse
        </Button>
      </DialogActions>
    </Dialog>
  );
}
