import {
  GridActionsColDef,
  GridColDef,
  GridActionsCellItem,
} from '@mui/x-data-grid';
import {
  Delete as DeleteIcon,
  Add as AddIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { IconButton, Grid, Tooltip } from '@mui/material';
import DataList from '../../../../components/DataList';
import { IProperty } from '../../../../types/IProperty';
import { useState } from 'react';
import ParameterDialog from './ParameterDialog';
import { PropertyTypes } from '../../../../types/PropertyTypes';
import moment from 'moment';
import useCheckPermission from '../../../../hooks/useCheckPermission';
import useFunctionDescriptor from '../../../../hooks/useFunctionDescriptor';

interface PropertyEditorProps {
  values: any;
  properties: IProperty[];
  setFieldValue: any;
  onNewPropertyAdded: any;
  readonly?: boolean;
}

const PropertyEditor = (props: PropertyEditorProps) => {
  const {
    properties = [],
    values,
    setFieldValue,
    onNewPropertyAdded,
    readonly = false,
  } = props;
  const [openParameterDialog, setOpenParameterDialog] = useState({
    open: false,
    entity: { propertyId: 0, value: '' },
  });

  const { checkPermission } = useCheckPermission();

  const getActions = (params: GridRowParams, color: any) => {
    if (readonly) return [];
    var actions = [];

    if (checkPermission(['ItemParameterEdit'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Szerkesztés">
              <EditIcon />
            </Tooltip>
          }
          label="Szerkesztés"
          onClick={() => {
            setOpenParameterDialog({ open: true, entity: params.row });
          }}
        />
      );

      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Törlés">
              <DeleteIcon />
            </Tooltip>
          }
          label="Törlés"
          onClick={() => {
            setFieldValue(
              'parameters',
              values.parameters.filter(
                (x) => x.propertyId !== params.row.propertyId
              )
            );
          }}
        />
      );
    }

    return actions;
  };

  const getFormattedValue = (params: any) => {
    try {
      const property = properties.find((x) => x.id === params.id);
      if (!property) {
        return params.value;
      }

      if (property.propertyType === PropertyTypes.Boolean) {
        return params.value === 'true' ? 'Igen' : 'Nem';
      }
      if (property.propertyType === PropertyTypes.Date) {
        return params.value
          ? moment(new Date(params.value)).format(
              property.unit != null && property.unit !== ''
                ? property.unit
                : 'YYYY-MM-dd'
            )
          : '';
      }
    } catch {}
    return params.value;
  };

  const columns: GridColDef[] = [
    {
      field: 'propertyId',
      headerName: 'Tulajdonság',
      description: 'Tulajdonság',
      flex: 100,
      type: 'singleSelect',
      valueFormatter: (params: any) => {
        let property = properties.find((p) => p.id === params.value);
        if (property == null) {
          return '';
        }
        if (
          property.unit !== '' &&
          property.propertyType === PropertyTypes.Number
        )
          return `${property.name}(${property.unit})`;
        return `${property.name}`;
      },
    },
    {
      field: 'value',
      headerName: 'Érték',
      description: 'Érték',
      flex: 100,
      valueFormatter: getFormattedValue,
    },
    {
      minWidth: 150,
      field: 'actions',
      type: 'actions',
      align: 'right',
      getActions: getActions,
    } as GridActionsColDef,
  ];

  const addPropertyDescriptor = useFunctionDescriptor(
    'PropertyEditor.addProperty'
  );

  return (
    <Grid container>
      {checkPermission(['ItemParameterCreate']) && (
        <Grid container item xs={12} justifyContent="end">
          <Grid item>{addPropertyDescriptor}</Grid>
          <Grid item>
            <Tooltip title="Tulajdonság hozzáadása">
              <IconButton
                onClick={() => {
                  setOpenParameterDialog({
                    open: true,
                    entity: { propertyId: 0, value: '' },
                  });
                }}
                color="primary"
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12}>
        <DataList
          columns={columns}
          rows={values.parameters}
          getRowId={(row) => row.propertyId}
          localStorageKey={'PropertyEditor'}
        />
      </Grid>
      <ParameterDialog
        open={openParameterDialog.open}
        onNewPropertyAdded={onNewPropertyAdded}
        values={values}
        handleClose={() =>
          setOpenParameterDialog({
            open: false,
            entity: { propertyId: 0, value: '' },
          })
        }
        entity={openParameterDialog.entity}
        onSubmit={(entity) => {
          let clone = [...values.parameters];
          let oldEntity = clone.find((x) => x.propertyId === entity.propertyId);

          if (oldEntity) {
            oldEntity.value = entity.value;
          } else {
            clone.push(entity);
          }

          setFieldValue('parameters', clone);
          setOpenParameterDialog({
            open: false,
            entity: { propertyId: 0, value: '' },
          });
        }}
        parameters={values.parameters}
        properties={properties}
      />
    </Grid>
  );
};

export default PropertyEditor;
