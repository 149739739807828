import {
  GridColDef,
  GridActionsCellItem,
  GridActionsColDef,
} from '@mui/x-data-grid';
import { Paper, Grid, Tooltip } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import DataList from '../../../../../components/DataList';
import useCheckPermission from '../../../../../hooks/useCheckPermission';
import useConfirmDeleteDialog from '../../../../../hooks/useConfirmDeleteDialog';
import useFunctionDescriptor from '../../../../../hooks/useFunctionDescriptor';
import React from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import AssignmentIcon from '@mui/icons-material/Assignment';
import roleService from '../../../../../services/authority/roleService';
import workItemService from '../../../../../services/erp/workItemService';
import { WorkItemStatus } from '../../WorkItemForm';
import { formatRole } from '../../../../../utils/valueFormatters';
import { parseJSON } from 'date-fns';

const WorkItemHistory = (props: any) => {
  const navigate = useNavigate();
  useConfirmDeleteDialog();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const titleDescriptor = useFunctionDescriptor('WorkItemAssignmentStep.title');
  const { setFieldValue, employees, values } = props;
  const [rows, setRows] = useState<any[]>([]);

  const { checkPermission } = useCheckPermission();

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];

    return actions;
  };

  useEffect(() => {
    workItemService.listWorkItemHistory(values.id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setRows(response.records);
      }
    });
  }, [values.id]);

  const columns: GridColDef[] = [
    {
      field: 'userName',
      headerName: 'Dolgozó',
      flex: 100,
    },
    {
      field: 'workItemStatus',
      headerName: 'Munkalap státusz',
      renderCell(params) {
        return (
          <div>
            {params.value === WorkItemStatus.Draft ? (
              <span style={{ color: 'orange' }}>Tervezés alatt</span>
            ) : params.value === WorkItemStatus.New ? (
              <span style={{ color: '#A0A0A0' }}>Új</span>
            ) : params.value === WorkItemStatus.Assigned ? (
              <span style={{ color: '#82C0FF' }}>Hozzárendelve</span>
            ) : params.value === WorkItemStatus.Completed ? (
              <span style={{ color: 'green' }}>Befejezett</span>
            ) : params.value === WorkItemStatus.Suspended ? (
              <span style={{ color: '#FFCC00' }}>Felfüggesztett</span>
            ) : params.value === WorkItemStatus.Closed ? (
              <span style={{ color: 'red' }}>Lezárt</span>
            ) : params.value === WorkItemStatus.Evaluated ? (
              <span style={{ color: '#90EE90' }}>Kiértékelt</span>
            ) : null}
          </div>
        );
      },
      flex: 100,
    },
    {
      field: 'roleName',
      headerName: 'Munkafolyamat státusz',
      valueFormatter(params) {
        return formatRole(params.value);
      },
      flex: 100,
    },
    {
      field: 'date',
      headerName: 'Dátum',
      valueFormatter(params) {
        return parseJSON(params.value).toLocaleDateString();
      },
      flex: 100,
    },
    {
      field: 'description',
      headerName: 'Leírás',
      flex: 300,
    },
  ];

  return (
    <Paper style={{ width: '100%' }}>
      <Grid container>
        <Grid item xs={12}>
          <h2>Munkalap napló {titleDescriptor}</h2>
        </Grid>
        <Grid item xs={12}>
          <DataList
            rows={rows}
            columns={columns}
            getRowId={(row) => `${row.id}`}
            localStorageKey={'WorkItemHistory'}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default WorkItemHistory;
