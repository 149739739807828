import { useState, useEffect } from 'react';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import * as signalR from '@microsoft/signalr';
import { useSelector } from 'react-redux';

const useSignalREffect = (hubName: string) => {
  const [connection, setConnection] = useState<HubConnection>();
  const accessToken = useSelector((state: any) => state.app.accessToken);

  useEffect(() => {
    if (!accessToken) return;
    const connection = new HubConnectionBuilder()
      .withUrl(
        process.env.NODE_ENV === 'development'
          ? 'http://localhost:5000/' + hubName
          : `/` + hubName,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          transport: signalR.HttpTransportType.LongPolling,
        }
      )
      .withAutomaticReconnect()
      .build();

    connection
      .start()
      .then(() => setConnection(connection))
      .catch((error) => {});

    return () => {
      connection?.stop();
    };
  }, [accessToken]);

  return connection;
};

export default useSignalREffect;
