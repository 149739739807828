import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useCheckPermission from '../../../hooks/useCheckPermission';
import CarbonMonoxideSensorForm from './CarbonMonoxideSensorForm';
import carbonMonoxideSensorService from '../../../services/chimneySweeping/carbonMonoxideSensorService';
import clientService from '../../../services/crm/clientService';
import { EntityStatuses } from '../../../types/EntityStatuses';
import itemService from '../../../services/wms/itemService';
import serviceService from '../../../services/erp/serviceService';

const CarbonMonoxideSensorEdit = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const params = useParams();
  const [errors, setErrors] = useState<string[]>([]);
  const [now, setNow] = useState(new Date());
  const [entity, setEntity] = useState<any>({
    dateOfInstallation: now,
    addressId: 0,
    clientId: 0,
    serviceId: 0,
    itemId: 0,
    identifier: '',
    lastControlYear: '',
  });
  const [clients, setClients] = useState<any[]>([]);
  const [items, setItems] = useState<any[]>([]);
  const [services, setServices] = useState<any[]>([]);

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    carbonMonoxideSensorService.get(params.id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        setErrors(response.errorMessages);
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setEntity(response.result);
      }
    });
  }, [params.id]);

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    carbonMonoxideSensorService
      .update(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });

          navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        setSubmitting(false);
      });
  };

  useEffect(() => {
    clientService.list(false, EntityStatuses.Approved).then((response) => {
      if (response.canceled) return;
      if (!response.hasError) {
        setClients(response.records);
      } else {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      }
    });
  }, []);

  useEffect(() => {
    itemService
      .list()
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) setItems(response.records);
        else if (response.errorMessages.length > 0)
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  useEffect(() => {
    serviceService
      .list()
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) setServices(response.records);
        else if (response.errorMessages.length > 0)
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  return (
    <CarbonMonoxideSensorForm
      entity={entity}
      readonly={!checkPermission(['CarbonMonoxideSensorEdit'])}
      onSubmit={onSubmit}
      handleCancel={() => navigate(-1)}
      errors={errors}
      clients={clients}
      items={items}
      services={services}
    />
  );
};

export default CarbonMonoxideSensorEdit;
