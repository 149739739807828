import { CartItem } from "../../pages/sales/SalesPage";
import { createAction } from "@reduxjs/toolkit";

export const reset = createAction("stockTaking/reset");

export const setActiveStockTaking = createAction<number>(
  "stockTaking/setActiveStockTaking"
);

export const add = createAction<CartItem>("stockTaking/add");
export const finish = createAction("stockTaking/finish");
export const clear = createAction("stockTaking/clear");
export const remove = createAction<number>("stockTaking/remove");
