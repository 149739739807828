import { Box, Grid, Paper, Tab, Tabs, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import useFunctionDescriptor from '../../hooks/useFunctionDescriptor';
import useCheckPermission from '../../hooks/useCheckPermission';
import MobilePage from './mobiles/MobilePage';
import ToolPage from './tools/ToolPage';
import InstrumentPage from './instruments/InstrumentPage';
import React from 'react';
import ComputerPage from './computers/ComputerPage';
import PeripheryPage from './periherals/PeripheryPage';
import VehiclePage from './vehicles/VehiclePage';
import SimCardPage from './simCards/SimCardPage';
import SerialNumberedBlockPage from './serialNumberedBlocks/SerialNumberedBlockPage';
import WorkClothesPage from './workClothes/WorkClothesPage';

const DeviceManagementPage = () => {
  const navigate = useNavigate();
  const titleDescriptor = useFunctionDescriptor('FinancePage.title');
  const [tabValue, setTabValue] = React.useState(0);
  const { checkPermission } = useCheckPermission();
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    saveTab(newValue);
  };

  useEffect(() => {
    const savedtab = localStorage.getItem(
      `deviceManager_tab${window.location.hash}`
    );
    if (savedtab) {
      setTabValue(JSON.parse(savedtab));
    }
  }, []);

  const saveTab = (tab) => {
    localStorage.setItem(
      `deviceManager_tab${window.location.hash}`,
      JSON.stringify(tab)
    );
  };

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>Eszköz nyílvántartás{titleDescriptor}</h2>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ width: '100%' }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                pb: 2,
                maxWidth: isSmallScreen ? '280px' : '100%',
              }}
            >
              <Tabs
                value={tabValue}
                onChange={handleChange}
                TabIndicatorProps={{ style: { background: '#1976d2' } }}
                sx={{
                  maxWidth: isSmallScreen ? '280px' : '100%',
                  overflowX: 'scroll',
                }}
                allowScrollButtonsMobile
                scrollButtons="auto"
                variant="scrollable"
              >
                <Tab label="Számítógépek" sx={{ fontWeight: 'bold' }} />
                <Tab label="Telefonok/Tabletek" sx={{ fontWeight: 'bold' }} />
                <Tab label="Szerszámok" sx={{ fontWeight: 'bold' }} />
                <Tab label="Műszerek" sx={{ fontWeight: 'bold' }} />
                <Tab label="Perifériák" sx={{ fontWeight: 'bold' }} />
                <Tab label="Járművek" sx={{ fontWeight: 'bold' }} />
                <Tab label="SIM kártyák" sx={{ fontWeight: 'bold' }} />
                <Tab label="Számozott tömbök" sx={{ fontWeight: 'bold' }} />
                <Tab label="Munkaruhák" sx={{ fontWeight: 'bold' }} />
              </Tabs>
            </Box>
            {checkPermission(['ComputerView']) && tabValue === 0 && (
              <ComputerPage />
            )}
            {checkPermission(['MobileView']) && tabValue === 1 && (
              <MobilePage />
            )}
            {checkPermission(['ToolView']) && tabValue === 2 && <ToolPage />}
            {checkPermission(['InstrumentView']) && tabValue === 3 && (
              <InstrumentPage />
            )}
            {checkPermission(['PeripheryView']) && tabValue === 4 && (
              <PeripheryPage />
            )}
            {checkPermission(['VehicleView']) && tabValue === 5 && (
              <VehiclePage />
            )}
            {checkPermission(['SimCardView']) && tabValue === 6 && (
              <SimCardPage />
            )}
            {checkPermission(['SerialNumberedBlockView']) && tabValue === 7 && (
              <SerialNumberedBlockPage />
            )}
            {checkPermission(['WorkClothesView']) && tabValue === 8 && (
              <WorkClothesPage />
            )}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default DeviceManagementPage;
