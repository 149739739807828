import { createReducer } from '@reduxjs/toolkit';
import {
  add,
  setPriceCategoryId,
  remove,
  update,
  clear,
  setWarehouseId,
  setConversionRate,
  setCurrency,
  setIncludeUnpriced,
  setInvoiceLanguage,
} from '../actions/itemActions';
import {
  loadItems,
  loadPriceCategories,
  loadWarehouses,
} from '../thunks/itemsThunk';
import { Currency } from '../../types/Currency';
import { DocumentLanguage } from '../../types/DocumentLanguage';

const initialState = {
  items: [],
  priceCategories: [],
  warehouses: [],
  warehouseId: 0,
  priceCategoryId: 0,
  error: '',
  conversionRate: 1,
  currency: Currency.HUF,
  invoiceLanguage: DocumentLanguage.Hu,
  includeUnpriced: false,
};

export const itemsReducer = createReducer(initialState, (builder) => {
  builder.addCase(add, (state, action) => {
    state.items = [...state.items, action.payload];
  });
  builder.addCase(remove, (state, action) => {
    state.items = state.items.filter((x) => x.itemId !== action.payload);
  });
  builder.addCase(update, (state, action) => {
    let clone = [...state.items];
    let index = clone.findIndex((x) => x.itemId === action.payload.id);
    clone[index] = action.payload.item;
    state.items = clone;
  });
  builder.addCase(clear, (state, action) => {
    state.items = [];
  });
  builder.addCase(setWarehouseId, (state, action) => {
    state.warehouseId = action.payload;
  });
  builder.addCase(setPriceCategoryId, (state, action) => {
    state.priceCategoryId = action.payload;
  });
  builder.addCase(setIncludeUnpriced, (state, action) => {
    state.includeUnpriced = action.payload;
  });
  builder.addCase(setConversionRate, (state, action) => {
    state.conversionRate = action.payload;
  });
  builder.addCase(setCurrency, (state, action) => {
    state.currency = action.payload;
  });
  builder.addCase(setInvoiceLanguage, (state, action) => {
    state.invoiceLanguage = action.payload;
  });
  builder.addCase(loadItems.fulfilled, (state, action) => {
    if (action.payload?.hasError)
      state.error = action.payload.errorMessages.join('|');
    else state.items = action.payload?.records ?? [];
  });
  builder.addCase(loadWarehouses.fulfilled, (state, action) => {
    if (action.payload?.hasError)
      state.error = action.payload.errorMessages.join('|');
    else state.warehouses = action.payload?.records ?? [];
  });
  builder.addCase(loadPriceCategories.fulfilled, (state, action) => {
    if (action.payload?.hasError)
      state.error = action.payload.errorMessages.join('|');
    else state.priceCategories = action.payload?.records ?? [];
  });
});
