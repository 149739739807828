import {
  Button,
  TextField,
  Box,
  Grid,
  Paper,
  Autocomplete,
  IconButton,
  Tooltip,
  Checkbox,
  FormControlLabel,
  Tab,
  Tabs,
  Badge,
  useMediaQuery,
} from '@mui/material';
import { useFormik } from 'formik';
import PropertyEditor from './components/PropertyEditor';
import ItemGroupCreate from '../itemGroups/ItemGroupCreate';
import CreateEntityDecorator from '../../../components/CreateEntityDecorator';
import BrandCreate from '../brands/BrandCreate';
import { useBarcodeScanner } from '../../../components/barcode-scanner/useBarcodeScanner';
import usePrintComponent from '../../../hooks/usePrintComponent';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Add,
  ContentCopy,
  Download,
  Print,
  CheckCircle as ApproveIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Cancel as RejectIcon,
  Visibility,
} from '@mui/icons-material';
import useCheckPermission from '../../../hooks/useCheckPermission';
import useFunctionDescriptor from '../../../hooks/useFunctionDescriptor';
import DataList from '../../../components/DataList';
import React from 'react';
import AddBasicItemDialog from '../ItemSupplies/AddBasicItemDialog';
import {
  GridActionsColDef,
  GridColDef,
  GridActionsCellItem,
} from '@mui/x-data-grid';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { EntityStatuses } from '../../../types/EntityStatuses';
import useConfirmDeleteDialog from '../../../hooks/useConfirmDeleteDialog';
import itemService from '../../../services/wms/itemService';
import EntityNavigator from '../../../components/EntityNavigator';
import { EntityTypes } from '../../../types/EntityTypes';
import { useSnackbar } from 'notistack';
var Barcode = require('react-barcode');

const ItemForm = (props: any) => {
  const {
    entity,
    groups,
    brands,
    properties,
    onSubmit,
    errors,
    onItemGroupChange,
    onNewItemGroupAdded,
    onNewBrandAdded,
    handleCancel,
    ContainerComponent = Paper,
    onNewPropertyAdded,
    readonly = false,
    items,
    isDialog = false,
  } = props;

  const [tabValue, setTabValue] = React.useState(1);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const {
    isSubmitting,
    values,
    handleChange,
    setFieldValue,
    handleSubmit,
    touched,
    errors: validationErrors,
  } = useFormik({
    initialValues: entity,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(values, setSubmitting);
    },
    validate: (values) => {
      const errors: any = {};
      if (!values.name) {
        errors.name = 'Kötelező mező!';
      }

      if (!values.brandId) {
        errors.brandId = 'Kötelező mező!';
      }
      if (!values.itemGroupId) {
        errors.itemGroupId = 'Kötelező mező!';
      }

      return errors;
    },
  });

  const [addBasicItemDialog, setAddBasicItemDialog] = React.useState({
    open: false,
    item: {},
  });
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const { enqueueSnackbar } = useSnackbar();
  const handleDelete = (id: number) => {
    setFieldValue(
      'parts',
      values.parts?.filter((row) => row.partItemId !== id)
    );
  };

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];
    let readonly = !checkPermission(['ItemEdit']);

    actions.push(
      <IconButton
        color="primary"
        onClick={() => {
          let newParts = values.parts?.map((x) => {
            if (x.partItemId === params.row.partItemId) {
              return {
                ...x,
                partAmount: x.partAmount + 1,
              };
            }
            return x;
          });
          setFieldValue('parts', [...(newParts ?? [])]);
        }}
      >
        <Add />
      </IconButton>
    );
    actions.push(
      <IconButton
        color="primary"
        onClick={() => {
          let newParts = values.parts?.map((x) => {
            if (x.partItemId === params.row.partItemId) {
              return {
                ...x,
                partAmount: x.partAmount - 1 > 0 ? x.partAmount - 1 : 1,
              };
            }
            return x;
          });
          setFieldValue('parts', [...(newParts ?? [])]);
        }}
      >
        <RemoveIcon />
      </IconButton>
    );
    actions.push(
      <EntityNavigator
        entityType={EntityTypes.Item}
        entityId={params.row.partItemId}
        value={readonly ? 'Megtekintés' : 'Szerkesztés'}
        showIcon
        readonly={readonly}
      />
    );
    if (checkPermission(['ItemDelete'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Törlés">
              <DeleteIcon />
            </Tooltip>
          }
          label="Törlés"
          onClick={() =>
            setParams({
              open: true,
              name: 'Biztosan törölni szeretné?',
              onConfirm: async () => handleDelete(params.row.partItemId),
            })
          }
        />
      );
    }

    return actions;
  };

  const columns: GridColDef[] = [
    {
      field: 'itemNumber',
      headerName: 'Cikkszám',
      description: 'Cikkszám',
      flex: 50,
    },
    {
      field: 'name',
      headerName: 'Megnevezés',
      description: 'Megnevezés',
      flex: 100,
    },
    {
      field: 'brandName',
      headerName: 'Márka',
      description: 'Márka',
      flex: 100,
    },
    {
      field: 'itemGroupName',
      headerName: 'Termékcsoport',
      description: 'Termékcsoport',
      flex: 100,
    },
    {
      field: 'partAmount',
      headerName: 'Darabszám',
      description: 'Darabszám',
      flex: 100,
    },
    {
      minWidth: 250,
      field: 'actions',
      type: 'actions',
      align: 'right',
      getActions: getActions,
    } as GridActionsColDef,
  ];

  const { checkPermission } = useCheckPermission();

  const handleBarcodeRead = (barcode) => {
    if (tabValue === 1) {
      setFieldValue('barcode', barcode.data);
    } else {
      let found = items
        ?.filter((x) => x.id !== values.id && !x.isComplexItem)
        ?.find((x) => x.id === barcode.item?.id);
      if (found) {
        setAddBasicItemDialog({
          open: true,
          item: found,
        });
      } else {
        enqueueSnackbar('Nem található a termék vagy a termék összetett!', {
          variant: 'error',
        });
      }
    }
  };

  useBarcodeScanner(handleBarcodeRead, items);
  const { printRef, handleDownloadImage, handlePrint, handleCopyToClipboard } =
    usePrintComponent();

  const titleDescriptor = useFunctionDescriptor('ItemForm.title');

  return (
    <form onSubmit={handleSubmit}>
      <ContainerComponent>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              borderColor: 'divider',
              maxWidth: isSmallScreen ? '280px' : '100%',
            }}
          >
            <Tabs
              value={tabValue}
              onChange={(event, newValue) => setTabValue(newValue)}
              TabIndicatorProps={{ style: { background: '#1976d2' } }}
              sx={{
                maxWidth: isSmallScreen ? '280px' : '100%',
                overflowX: 'scroll',
              }}
              allowScrollButtonsMobile
              scrollButtons="auto"
              variant="scrollable"
            >
              <Tab
                value={1}
                label={'Termék adatok'}
                sx={{ fontWeight: 'bold' }}
              />
              {values.isComplexItem && (
                <Tab
                  value={2}
                  label={'Alkatrészek'}
                  sx={{ fontWeight: 'bold' }}
                />
              )}{' '}
              <Tab
                value={3}
                label={'Tulajdonságok'}
                sx={{ fontWeight: 'bold' }}
              />
            </Tabs>
          </Box>
          <Grid container justifyContent="left" spacing={2} p={5} pt={0}>
            {tabValue === 1 && (
              <>
                {' '}
                <h2>
                  Termék {values.id > 0 ? 'szerkesztése' : 'létrehozása'}
                  {titleDescriptor}
                </h2>
                <Grid xs={12} item pt={5}>
                  <Box color="red">
                    {errors.map((error) => (
                      <li>{error}</li>
                    ))}
                  </Box>
                </Grid>
                <Grid item container xs={12} md={6}>
                  <Grid
                    item
                    container
                    display="inline-block"
                    ref={printRef}
                    sx={{ md: { width: '300px' } }}
                  >
                    <Grid item display="inline-block" xs={12}>
                      <span>{values.name.toUpperCase().slice(0, 30)}</span>
                    </Grid>

                    <Grid item>
                      {values.barcode && (
                        <div
                          style={{
                            transform: `scale(${
                              1 - (values.barcode.length - 13) * 0.009
                            })`,
                            transformOrigin: 'top left',
                          }}
                        >
                          <Barcode
                            value={values.barcode}
                            width={values.barcode.length > 13 ? '1' : '2'}
                            height="35"
                          />
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={5}>
                  <TextField
                    value={values.barcode}
                    onChange={handleChange}
                    label="Vonalkód"
                    name="barcode"
                    disabled={readonly}
                    fullWidth
                    autoFocus
                    error={!!touched.barcode && !!validationErrors.barcode}
                    helperText={
                      !!touched.barcode &&
                      !!validationErrors.barcode &&
                      (validationErrors.barcode as string)
                    }
                    InputProps={{
                      endAdornment: (
                        <Box display="flex">
                          <Tooltip title="Letöltés">
                            <IconButton
                              onClick={() =>
                                handleDownloadImage(values.barcode)
                              }
                            >
                              <Download />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Nyomtatás">
                            <IconButton onClick={() => handlePrint()}>
                              <Print />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Vágólapra másolás">
                            <IconButton onClick={() => handleCopyToClipboard()}>
                              <ContentCopy />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextField
                    value={values.name}
                    onChange={handleChange}
                    label="Termék megnevezése"
                    disabled={readonly}
                    name="name"
                    fullWidth
                    required
                    error={!!touched.name && !!validationErrors.name}
                    helperText={
                      !!touched.name &&
                      !!validationErrors.name &&
                      (validationErrors.name as string)
                    }
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    value={values.itemNumber}
                    onChange={handleChange}
                    disabled={readonly}
                    label="Cikkszám"
                    name="itemNumber"
                    fullWidth
                    error={
                      !!touched.itemNumber && !!validationErrors.itemNumber
                    }
                    helperText={
                      !!touched.itemNumber &&
                      !!validationErrors.itemNumber &&
                      (validationErrors.itemNumber as string)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={values?.alternateName}
                    onChange={(e) =>
                      setFieldValue('alternateName', e.target.value)
                    }
                    label="Termék alternatív megnevezése"
                    disabled={readonly}
                    name="name"
                    fullWidth
                    error={
                      !!touched.alternateName &&
                      !!validationErrors.alternateName
                    }
                    helperText={
                      !!touched.alternateName &&
                      !!validationErrors.alternateName &&
                      (validationErrors.alternateName as string)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <CreateEntityDecorator
                    hideAdd={readonly || !checkPermission(['ItemGroupCreate'])}
                    AutocompleteComponent={
                      <Autocomplete
                        disablePortal
                        id="itemGroup"
                        disabled={readonly}
                        value={values.itemGroupId}
                        onChange={(event, value) => {
                          setFieldValue('itemGroupId', value);

                          onItemGroupChange(value);
                        }}
                        getOptionLabel={(option) =>
                          groups.find((g) => g.id === option)?.name ?? ''
                        }
                        options={groups.map((g) => g.id)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            error={
                              !!touched.itemGroupId &&
                              !!validationErrors.itemGroupId
                            }
                            helperText={
                              !!touched.itemGroupId &&
                              !!validationErrors.itemGroupId &&
                              (validationErrors.itemGroupId as string)
                            }
                            fullWidth
                            label="Termékcsoport neve"
                          />
                        )}
                      />
                    }
                    onSave={(newItemGroup) => {
                      setFieldValue('itemGroupId', newItemGroup.id);
                      onNewItemGroupAdded(newItemGroup);
                    }}
                    CreateEntityComponent={ItemGroupCreate}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CreateEntityDecorator
                    hideAdd={readonly || !checkPermission(['BrandCreate'])}
                    AutocompleteComponent={
                      <Autocomplete
                        disablePortal
                        id="brand"
                        disabled={readonly}
                        value={values.brandId}
                        onChange={(event, value) => {
                          setFieldValue('brandId', value);
                        }}
                        getOptionLabel={(option) =>
                          brands.find((g) => g.id === option)?.name ?? ''
                        }
                        options={brands.map((g) => g.id)}
                        renderInput={(params) => (
                          <TextField
                            required
                            error={
                              !!touched.brandId && !!validationErrors.brandId
                            }
                            helperText={
                              !!touched.brandId &&
                              !!validationErrors.brandId &&
                              (validationErrors.brandId as string)
                            }
                            {...params}
                            fullWidth
                            label="Márka"
                          />
                        )}
                      />
                    }
                    onSave={(newBrand) => {
                      setFieldValue('brandId', newBrand.id);
                      onNewBrandAdded(newBrand);
                    }}
                    CreateEntityComponent={BrandCreate}
                  />
                </Grid>
                <Grid item xs={12} pl={1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.isComplexItem}
                        disabled={
                          values.id > 0 ||
                          !checkPermission(['ComplexItemCreate'])
                        }
                        onChange={(e, checked) =>
                          setFieldValue('isComplexItem', checked)
                        }
                      />
                    }
                    label="Összetett termék?"
                  />
                </Grid>
                <Grid item xs={12} pl={1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.isMarked}
                        onChange={(e, checked) =>
                          setFieldValue('isMarked', checked)
                        }
                      />
                    }
                    label="Megjelölés"
                  />
                </Grid>
              </>
            )}
            {values.isComplexItem && tabValue === 2 && (
              <>
                <Grid item xs={12}>
                  {<h3>Alkatrészek:</h3>}
                </Grid>
                <Grid item xs={12} textAlign={'right'}>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setAddBasicItemDialog({ open: true, item: {} });
                    }}
                  >
                    <Add />
                  </IconButton>
                </Grid>
                <Grid item xs={12}>
                  <DataList
                    rows={values?.parts ?? []}
                    localStorageKey={'ComplexItemForm'}
                    getRowId={(row) => row.partItemId}
                    columns={columns}
                  />
                </Grid>
              </>
            )}
            <Grid container>
              {tabValue === 3 && (
                <>
                  <Grid item xs={12}>
                    {<h3>Tulajdonságok:</h3>}
                  </Grid>
                  <Grid item xs={12}>
                    <PropertyEditor
                      properties={properties}
                      values={values}
                      readonly={readonly}
                      setFieldValue={setFieldValue}
                      onNewPropertyAdded={onNewPropertyAdded}
                    />
                  </Grid>
                </>
              )}
              <Grid
                container
                item
                pt={3}
                sx={{ justifyContent: { sm: 'left', xs: 'center' } }}
              >
                {!readonly && (
                  <Grid item p={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      Mentés
                    </Button>
                  </Grid>
                )}
                {!isDialog && (
                  <Grid item p={1}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleCancel}
                    >
                      Mégse
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <AddBasicItemDialog
              items={items.filter(
                (x) => x.id !== values.id && !x.isComplexItem
              )}
              open={addBasicItemDialog.open}
              setOpen={() => {
                setAddBasicItemDialog({ open: false, item: {} });
              }}
              cart={values.items}
              scannedItem={addBasicItemDialog.item}
              disableAvailableAmount
              addToCart={(item) => {
                const i = values.parts?.find((x) => x.partItemId === item.id);
                if (!i) {
                  setFieldValue('parts', [
                    ...(values.parts ?? []),
                    {
                      ...item,
                      partItemId: item.id,
                      partAmount: item.amount,
                    },
                  ]);
                } else {
                  setFieldValue('parts', [
                    ...values.parts?.filter(
                      (x) => x.partItemId !== i.partItemId
                    ),
                    {
                      ...i,
                      partAmount: item?.amount + i?.partAmount,
                    },
                  ]);
                }
                setAddBasicItemDialog({ open: false, item: {} });
              }}
            />
            <ConfirmDeleteDialog />
          </Grid>
        </Box>
      </ContainerComponent>
    </form>
  );
};

export default ItemForm;
