import { createAsyncThunk } from "@reduxjs/toolkit";
import userService from "../../services/authority/userService";
import eventService from "../../services/erp/eventService";

export const loadEmployees = createAsyncThunk(
  "resourcePlanner/loadEmployees",
  async (payload, thunkAPI) => {
    thunkAPI.dispatch({ type: "SHOW_QUERY" });

    const response = await userService
      .list()
      .finally(() => thunkAPI.dispatch({ type: "HIDE" }));

    return response;
  }
);
export const loadEvents = createAsyncThunk(
  "resourcePlanner/loadEvents",
  async (date: Date, thunkAPI) => {
    thunkAPI.dispatch({ type: "SHOW_QUERY" });
    const response = await eventService
      .list(date)
      .finally(() => thunkAPI.dispatch({ type: "HIDE" }));
    return response;
  }
);
