import { Autocomplete, Grid, Paper, TextField } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import DataList from '../../components/DataList';
import useFunctionDescriptor from '../../hooks/useFunctionDescriptor';
import stockTakingService from '../../services/stockTaking/stockTakingService';
import warehouseService from '../../services/wms/warehouseService';
import userService from '../../services/authority/userService';

const StockTakingHistory = () => {
  const [warehouses, setWarehouses] = useState<any[]>([]);
  const [selectedStockTaking, setSelectedStockTaking] = useState<number>(0);
  const [stockTakings, setStockTakings] = useState<any[]>([]);
  const [users, setUsers] = useState<any[]>([]);
  const [stockTaking, setStockTaking] = useState<any>();
  const titleDescriptor = useFunctionDescriptor('StockTakingHistory.title');
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    warehouseService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setWarehouses(response.records);
      }
    });
  }, []);

  useEffect(() => {
    stockTakingService.listStockTaking(true).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setStockTakings(response.records);
      }
    });
  }, []);
  useEffect(() => {
    userService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setUsers(response.records);
      }
    });
  }, []);

  useEffect(() => {
    if (!selectedStockTaking) {
      return;
    }
    stockTakingService.getStockTaking(selectedStockTaking).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setStockTaking(response.result);
      }
    });
  }, [selectedStockTaking]);

  const columns: GridColDef[] = [
    {
      field: 'itemName',
      headerName: 'Termék',
      flex: 100,
      editable: false,
    },
    {
      field: 'count',
      headerName: 'Korábbi mennyiség',
      editable: false,
      flex: 100,
    },
    {
      field: 'actualCount',
      headerName: 'Leltározott mennyiség',
      editable: false,
      flex: 100,
    },
    {
      field: 'difference',
      headerName: 'Különbség',
      editable: false,
      flex: 100,
    },
  ];

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          {stockTaking != null ? (
            <h2>
              {warehouses.find((x) => x.id === stockTaking.warehouseId)?.name}{' '}
              leltározása{titleDescriptor}
            </h2>
          ) : (
            <h2>Korábbi leltárak{titleDescriptor}</h2>
          )}
        </Grid>
        <Grid item xs={12} md={6} pb={2}>
          <Autocomplete
            disablePortal
            id="stockTaking"
            value={selectedStockTaking}
            onChange={(event, value) => {
              setSelectedStockTaking(value);
            }}
            getOptionLabel={(option) => {
              let stockTaking = stockTakings.find((g: any) => g.id === option);
              let user = users.find((u) => u.id === stockTaking?.createdById);
              if (
                stockTaking?.warehouseName &&
                stockTaking?.createdAt &&
                user?.fullName
              ) {
                return (
                  `${option}.${stockTaking?.warehouseName}(${new Date(
                    stockTaking?.createdAt
                  ).toLocaleDateString()}, ${user?.fullName})` ?? ''
                );
              } else {
                return '';
              }
            }}
            options={stockTakings.map((g) => g.id)}
            renderInput={(params) => (
              <TextField {...params} fullWidth label="Korábbi leltározások" />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <DataList
            rows={stockTaking?.stockTakingItems ?? []}
            columns={columns}
            getRowId={(row) => row.itemId}
            exportFields={['itemName', 'actualCount', 'count']}
            localStorageKey={'StockTakingHistory'}
          />{' '}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default StockTakingHistory;
