import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import CashRegisterForm from './CashRegisterForm';
import { useNavigate } from 'react-router-dom';
import useCheckPermission from '../../../hooks/useCheckPermission';
import cashRegisterService from '../../../services/billing/cashRegisterService';
import clientService from '../../../services/crm/clientService';
import { EntityStatuses } from '../../../types/EntityStatuses';
import userService from '../../../services/authority/userService';
import { ClientTypes } from '../../../types/ClientTypes';

const CashRegisterCreate = (props: any) => {
  const { onSave = null, navigateBack = true, handleCancel = null } = props;

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<[]>([]);
  const [clients, setClients] = useState<any[]>([]);
  const [users, setUsers] = useState<any[]>([]);

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    clientService
      .list(false, EntityStatuses.Approved)
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError)
          setClients(
            response.records.filter(
              (x) => x.clientType === ClientTypes.Distributor
            )
          );
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    userService
      .list()
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) setUsers(response.records);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    cashRegisterService
      .create(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });

          if (onSave) onSave(response.result);
          if (navigateBack) navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        setSubmitting(false);
      });
  };

  return (
    <CashRegisterForm
      entity={{ companyId: 0, userId: 0, name: '', prefix: '', limit: 0 }}
      readonly={!checkPermission(['CreateCashRegister'])}
      onSubmit={onSubmit}
      clients={clients}
      users={users}
      errors={errors}
      handleCancel={() =>
        handleCancel != null ? handleCancel() : navigate(-1)
      }
    />
  );
};

export default CashRegisterCreate;
