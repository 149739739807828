import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as React from 'react';
import accountService from '../services/accountService';
import appService from '../services/appService';
import monitoringService from '../services/monitoringService';
import systemConfigurationService from '../services/systemConfigurationService';
const useCheckAlive = () => {
  const dispatch = useDispatch<any>();

  const [lastConnection, setLastConnection] = useState<any>(new Date());
  const [alive, setAlive] = useState<boolean>(true);
  const [config, setConfig] = useState<any>({ key: '', value: '' });

  React.useEffect(() => {
    systemConfigurationService
      .getByKey('monitoring.checkAliveInterval')
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) setConfig(response.result);
      });
  }, []);

  const checkAlive = useCallback(() => {
    let controller = new AbortController();
    monitoringService
      .IsAlive(controller.signal)
      .then((response) => {
        if (response.hasError) return;

        if (alive === false) {
          dispatch({ type: 'HIDE' });
        }
        appService.getAppStatus().then((response: any) => {
          dispatch({ type: 'LOAD_APP_STATUS', payload: response });
        });
        if (!alive) {
          accountService.getUserInfo().then((response: any) => {
            dispatch({ type: 'LOAD_USER_INFO', payload: response });
          });
        }

        setAlive(true);
        setLastConnection(response);
      })
      .catch(() => {
        setAlive(false);
        dispatch({ type: 'SHOW_SERVICE_UNAVAILABLE' });
      });
    return () => {
      controller.abort();
    };
  }, [alive, dispatch]);

  useEffect(() => {
    checkAlive();
    var time = parseInt(config.value);
    if (!time || isNaN(time)) {
      time = 15;
    }
    let id = setInterval(() => checkAlive(), time * 1000);

    return () => {
      clearInterval(id);
    };
  }, [checkAlive, config.value]);
};

export default useCheckAlive;
