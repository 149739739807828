import {
  GridColDef,
  GridActionsCellItem,
  GridActionsColDef,
} from '@mui/x-data-grid';
import { Paper, IconButton, Grid, Tooltip } from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Visibility,
} from '@mui/icons-material';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import DataList from '../../../components/DataList';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useCheckPermission from '../../../hooks/useCheckPermission';
import useFunctionDescriptor from '../../../hooks/useFunctionDescriptor';
import useConfirmDeleteDialog from '../../../hooks/useConfirmDeleteDialog';
import chimneyControlAppointmentService from '../../../services/chimneySweeping/chimneyControlAppointmentService';
import { translateChimneyControlAppointmentStatusesName } from '../../../utils/nameFormatters';
import { ChimneyControlAppointmentStatuses } from '../../../types/ChimneyControlAppointmentStatuses';
import ChimneyControlAppointmentDialog from './ChimneyControlAppointmentDialog';
import { set, parseJSON } from 'date-fns';
import { EntityTypes } from '../../../types/EntityTypes';
import EntityNavigator from '../../../components/EntityNavigator';
import * as XLSX from 'xlsx';

const ChimneyControlAppointmentPage = () => {
  const navigate = useNavigate();
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const [rows, setRows] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const userId = useSelector((state: any) => state.user.userInfo.userId);
  const dispatch = useDispatch<any>();
  const [chimneyControlAppointmentDialog, setChimneyControlAppointmentDialog] =
    useState<any>({
      open: false,
      entity: {},
      chimneySweepingCertificateId: 0,
      readonly: false,
      hasClientSpecialNeeds: false,
      specialNeeds: '',
      isOfferMandatory: false,
    });
  const titleDescriptor = useFunctionDescriptor(
    'ChimneyControlAppointmentPage.title'
  );

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    dispatch({ type: 'SHOW_QUERY' });
    chimneyControlAppointmentService
      .list()
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) setRows(response.records);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  };
  function convertObjectsToArrays(objects: any[]) {
    if (!Array.isArray(objects)) {
      throw new Error('Input must be an array of objects');
    }

    if (objects?.length === 0) {
      return [];
    }

    const translationMap = {
      appointmentDate: 'Egyeztetett időpont',
      createdByName: 'Egyeztető',
      employeeName: 'Kéményseprő',
      clientName: 'Ügyfél',
      clientPhone: 'Telefonszám',
      address: 'Cím',
      description: 'Leírás',
      clientSpecialNeeds: 'Ügyfél különleges igényei',
      isOfferMandatory: 'Kötelező árajánlat',
    };

    const filteredKeys = [
      'appointmentDate',
      'createdByName',
      'employeeName',
      'clientName',
      'clientPhone',
      'address',
      'description',
      'clientSpecialNeeds',
      'isOfferMandatory',
    ];
    const headers = filteredKeys.map((key) =>
      translationMap[key]?.toUpperCase()
    );
    const rows = objects.map((object) => {
      if (typeof object !== 'object') {
        throw new Error('Elements of the input array must be objects');
      }

      return filteredKeys.map((key) => {
        const value = object[key];

        if (new Date(value).toString() !== 'Invalid Date') {
          const date = new Date(value);
          if (isNaN(date.getTime())) {
            return parseJSON(value).toLocaleDateString();
          }
          return parseJSON(value).toLocaleDateString();
        }
        if (typeof value === 'boolean') {
          return value ? 'Igen' : 'Nem';
        }
        return value;
      });
    });

    return [headers, ...rows];
  }
  function exportToExcel(data: any) {
    var array = convertObjectsToArrays(data);
    const ws = XLSX.utils.aoa_to_sheet(array);

    // Calculate column widths based on content length and header length
    const columnWidths = array[0].map((header, columnIndex) => {
      const columnValues = array.map((row) => String(row[columnIndex]));
      const headerLength = header?.length;
      const maxContentLength = Math.max(
        headerLength,
        ...columnValues.map((value) => value?.length)
      );
      return maxContentLength + 2; // Add extra space for readability
    });

    // Set column widths
    ws['!cols'] = columnWidths.map((width) => ({ width }));

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Adatsor');
    const fileName = `Egyeztetett kémény ellenőrzések.xlsx`;
    XLSX.writeFile(wb, fileName);
  }

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];

    let readonly =
      !checkPermission(['ChimneyControlAppointmentEdit']) ||
      params.row.createdById !== userId;
    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          readonly ? (
            <Tooltip title="Megtekintés">
              <Visibility />
            </Tooltip>
          ) : (
            <Tooltip title="Szerkesztés">
              <EditIcon />
            </Tooltip>
          )
        }
        label={readonly ? 'Megtekintés' : 'Szerkesztés'}
        onClick={() =>
          setChimneyControlAppointmentDialog({
            open: true,
            entity: params.row,
            chimneySweepingCertificateId:
              params.row.chimneySweepingCertificateId,
            readonly: readonly,
            hasClientSpecialNeeds: params.row.hasClientSpecialNeeds,
            specialNeeds: params.row.clientSpecialNeeds,
            isOfferMandatory: params.row.isOfferMandatory,
          })
        }
      />
    );

    if (checkPermission(['ChimneyControlAppointmentDelete'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Törlés">
              <DeleteIcon />
            </Tooltip>
          }
          label="Törlés"
          onClick={() =>
            setParams({
              open: true,
              name: '',
              onConfirm: async () => handleDelete(params.row.id),
            })
          }
        />
      );
    }

    return actions;
  };

  const columns: GridColDef[] = [
    {
      field: 'appointmentDate',
      headerName: 'Egyeztetett időpont',
      flex: 150,
      valueFormatter(params) {
        return params.value
          ? parseJSON(params.value).toLocaleDateString()
          : 'Nincs';
      },
    },
    { field: 'createdByName', headerName: 'Egyeztető', flex: 150 },
    { field: 'employeeName', headerName: 'Kéményseprő', flex: 150 },

    {
      field: 'clientName',

      headerName: 'Ügyfél',
      flex: 150,
      renderCell(params) {
        return (
          <EntityNavigator
            entityType={EntityTypes.Client}
            entityId={params.row.clientId}
            value={params.row.clientName}
          />
        );
      },
      valueGetter: (params: any) => {
        return params.row.clientName;
      },
    },
    { field: 'clientPhone', headerName: 'Telefonszám', flex: 100 },
    { field: 'address', headerName: 'Cím', flex: 300 },
    { field: 'description', headerName: 'Leírás', flex: 200 },
    {
      field: 'isOfferMandatory',
      headerName: 'Kötelező árajánlat',
      flex: 100,
      valueFormatter(params) {
        return params.value ? 'Igen' : 'Nem';
      },
    },
    {
      field: 'hasClientSpecialNeeds',
      headerName: 'Van különleges ügyfgél igény?',
      flex: 100,
      valueFormatter(params) {
        return params.value ? 'Igen' : 'Nem';
      },
    },
    {
      field: 'status',
      headerName: 'státusz',
      flex: 150,
      renderCell(params) {
        let color =
          params.value === ChimneyControlAppointmentStatuses.Completed
            ? 'green'
            : params.value === ChimneyControlAppointmentStatuses.Planned
              ? 'lightgreen'
              : params.value === ChimneyControlAppointmentStatuses.Failed
                ? 'red'
                : 'orange';
        return (
          <div style={{ color: color }}>
            {translateChimneyControlAppointmentStatusesName(
              params.value as ChimneyControlAppointmentStatuses
            )}
          </div>
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 100,
      getActions: getActions,
    } as GridActionsColDef,
  ];

  const handleDelete = (id: number) => {
    chimneyControlAppointmentService.delete(id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres törlés', {
          variant: 'success',
        });
        setRows(rows.filter((row) => row.id !== id));
      }
    });
  };

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>Egyeztetett kémény ellenőrzések {titleDescriptor}</h2>
        </Grid>
        <Grid item xs={12}>
          <DataList
            rows={rows}
            columns={columns}
            localStorageKey={'ChimneyControlAppointmentPage'}
            exportToExcel={() => exportToExcel(rows)}
          />
        </Grid>
        <ConfirmDeleteDialog />
        <ChimneyControlAppointmentDialog
          entity={chimneyControlAppointmentDialog.entity}
          onSubmit={(appointment) => {
            fetchData();
          }}
          readonly={chimneyControlAppointmentDialog.readonly}
          open={chimneyControlAppointmentDialog.open}
          isOfferMandatory={chimneyControlAppointmentDialog.isOfferMandatory}
          specialNeeds={chimneyControlAppointmentDialog.specialNeeds}
          hasClientSpecialNeeds={
            chimneyControlAppointmentDialog.hasClientSpecialNeeds
          }
          onClose={() => {
            setChimneyControlAppointmentDialog({
              open: false,
              entity: {},
              chimneySweepingCertificateId: 0,
            });
          }}
          chimneySweepingCertificateId={
            chimneyControlAppointmentDialog.chimneySweepingCertificateId
          }
        />
      </Grid>
    </Paper>
  );
};

export default ChimneyControlAppointmentPage;
