import {
  Autocomplete,
  Button,
  TextField,
  Box,
  Grid,
  Paper,
  useMediaQuery,
} from "@mui/material";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import WarehouseGroupCreate from "../warehouseGroups/WarehouseGroupCreate";
import CreateEntityDecorator from "../../../components/CreateEntityDecorator";
import useCheckPermission from "../../../hooks/useCheckPermission";
import useFunctionDescriptor from "../../../hooks/useFunctionDescriptor";

const WarehouseForm = (props: any) => {
  const isMobile = useMediaQuery("(max-width:800px)");
  const {
    entity,
    groups,
    onSubmit,
    errors,
    onNewGroupAdded,
    readonly = false,
    users,
  } = props;
  const navigate = useNavigate();
  const { checkPermission } = useCheckPermission();

  const titleDescriptor = useFunctionDescriptor("WarehouseForm.title");

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};
        if (!values.warehouseGroupId) {
          errors.warehouseGroupId = "Kötelező mező";
        }
        if (!values.name) {
          errors.name = "Kötelező mező";
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        handleChange,
        setFieldValue,
        errors: validationErrors,
      }) => (
        <Form>
          {" "}
          <Paper>
            <Grid container justifyContent="left" spacing={2} p={5}>
              <h2>
                Raktár {values.id > 0 ? "szerkesztése" : "létrehozása"}
                {titleDescriptor}
              </h2>
              <Grid xs={12} item pt={5}>
                <Box color="red">
                  {errors.map((error) => (
                    <li>{error}</li>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.name}
                  onChange={handleChange}
                  label="Megnevezés"
                  name="name"
                  fullWidth
                  required
                  disabled={readonly}
                  autoFocus
                  error={!!touched.name && !!validationErrors.name}
                  helperText={
                    !!touched.name &&
                    !!validationErrors.name &&
                    (validationErrors.name as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <CreateEntityDecorator
                  hideAdd={!checkPermission(["WarehouseGroupCreate"])}
                  AutocompleteComponent={
                    <Autocomplete
                      disablePortal
                      id="warehouseGroupId"
                      value={values.warehouseGroupId}
                      disabled={readonly}
                      onChange={(event, value) => {
                        setFieldValue("warehouseGroupId", value);
                      }}
                      getOptionLabel={(option) =>
                        groups.find((g) => g.id === option)?.groupName ?? ""
                      }
                      options={groups.map((g) => g.id)}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          error={
                            !!touched.warehouseGroupId &&
                            !!validationErrors.warehouseGroupId
                          }
                          helperText={
                            !!touched.warehouseGroupId &&
                            !!validationErrors.warehouseGroupId &&
                            (validationErrors.warehouseGroupId as string)
                          }
                          label="Raktár csoport neve"
                        />
                      )}
                    />
                  }
                  onSave={(group) => {
                    setFieldValue("warehouseGroupId", group.id);
                    onNewGroupAdded(group);
                  }}
                  CreateEntityComponent={WarehouseGroupCreate}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="users"
                  multiple
                  value={values.userIds}
                  onChange={(event, value) => {
                    setFieldValue("userIds", value);
                  }}
                  getOptionLabel={(option) => {
                    let user = users.find((g) => g.id === option);
                    return isMobile && user?.email
                      ? `${user?.email ?? ""}`
                      : `${user?.fullName} (${user?.email ?? ""})`;
                  }}
                  options={users.map((g) => g.id)}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth label="Felhasználók" />
                  )}
                />
              </Grid>
              <Grid container item pt={3} justifyContent="left">
                {!readonly && (
                  <Grid item p={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      Mentés
                    </Button>
                  </Grid>
                )}
                <Grid item p={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => navigate(-1)}
                  >
                    Mégse
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default WarehouseForm;
