import { postData } from '../axiosUtils';

const financeService = {
  expenseQuery: (
    entity: any,
    signal: AbortSignal = new AbortController().signal
  ) => {
    return postData({
      url: `/api/finance/expenseQuery`,
      data: entity,
      method: 'POST',
      signal: signal,
    }).then((response) => {
      return response;
    });
  },

  incomeQuery: (
    entity: any,
    signal: AbortSignal = new AbortController().signal
  ) => {
    return postData({
      url: `/api/finance/incomeQuery`,
      data: entity,
      signal: signal,
      method: 'POST',
    }).then((response) => {
      return response;
    });
  },

  itemsIncomeQuery: (
    entity: any,
    signal: AbortSignal = new AbortController().signal
  ) => {
    return postData({
      url: `/api/finance/itemsIncomeQuery`,
      data: entity,
      signal: signal,
      method: 'POST',
    }).then((response) => {
      return response;
    });
  },

  outstandingOrderQuery: (
    entity: any,
    signal: AbortSignal = new AbortController().signal
  ) => {
    return postData({
      url: `/api/finance/outstandingOrderQuery`,
      data: entity,
      signal: signal,
      method: 'POST',
    }).then((response) => {
      return response;
    });
  },

  itemsProfitQuery: (
    entity: any,
    signal: AbortSignal = new AbortController().signal
  ) => {
    return postData({
      url: `/api/finance/itemsProfitQuery`,
      data: entity,
      signal: signal,
      method: 'POST',
    }).then((response) => {
      return response;
    });
  },

  employeeProfitQuery: (
    entity: any,
    signal: AbortSignal = new AbortController().signal
  ) => {
    return postData({
      url: `/api/finance/employeeProfitQuery`,
      data: entity,
      signal: signal,
      method: 'POST',
    }).then((response) => {
      return response;
    });
  },

  profitQuery: (
    entity: any,
    signal: AbortSignal = new AbortController().signal
  ) => {
    return postData({
      url: `/api/finance/profitQuery`,
      data: entity,
      signal: signal,
      method: 'POST',
    }).then((response) => {
      return response;
    });
  },

  employeeRankingQuery: (
    entity: any,
    signal: AbortSignal = new AbortController().signal
  ) => {
    return postData({
      url: `/api/finance/employeeRankingQuery`,
      data: entity,
      signal: signal,
      method: 'POST',
    }).then((response) => {
      return response;
    });
  },
};

export default financeService;
