import { Box, Button, Typography, Modal, Grid } from '@mui/material';
import axios from 'axios';
import { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import CropImageModal from '../components/CropImageModal';
import CropImagePreview from '../components/CropImagePreview';
import ImagePreview from '../components/ImagePreview';
import imageService from '../services/files/imageService';
import imageCompression from 'browser-image-compression';

function ImageSelect({
  onSelect,
  image,
  setCrop1_1,
  crop1_1,
  setImageToUpload,
  imageToUpload,
  setImageUploaded,
  imageUploaded,
  uploading,
  uploadImage,
  openCrop1_1,
  setOpenCrop1_1,
}: any) {
  const accept = {
    'image/png': ['.png'],
    'image/jpeg': ['.jpg', '.jpeg'],
    'image/gif': ['.gif'],
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: useCallback((acceptedFiles: any) => {
      setCrop1_1({
        crop: { x: 0, y: 0 },
        area: {},
        zoom: 1,
        aspect: 1,
        shape: 'rect',
        rotation: 0,
        image: { data: '', url: '' },
      });
      const compressedImage = imageCompression(acceptedFiles[0], {
        maxSizeMB: 0.3,
        maxWidthOrHeight: 720,
        useWebWorker: true,
      });
      compressedImage.then((compressedImage) => {
        setImageToUpload(compressedImage);
        setOpenCrop1_1(true);
      });
    }, []),
    accept: accept,
  });

  const [open, setOpen] = useState(false);

  const [images, setImages] = useState<any>([]);

  useEffect(() => {
    imageService.list().then((res) => {
      setImages(
        res.records.map((x: any) => ({
          ...x,
          original: x.url,
        }))
      );
    });
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = (image: any) => {
    onSelect(image);
    handleClose();
  };

  return (
    <Box>
      <div {...getRootProps()} style={{ cursor: 'pointer' }}>
        <input {...getInputProps()} />
        <Box
          border={2}
          borderColor="primary.main"
          borderRadius="5px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="150px"
        >
          <Typography variant="body1" color="primary">
            Húzd ide a képet, vagy kattints a kiválasztáshoz.
          </Typography>
        </Box>
      </div>
      {imageToUpload ? (
        <CropImagePreview
          crop1_1={crop1_1}
          uploading={uploading}
          imageUploaded={imageUploaded}
          setImageUploaded={setImageUploaded}
          imageToUpload={imageToUpload}
          setOpenCrop1_1={setOpenCrop1_1}
          uploadImage={uploadImage}
        />
      ) : image ? (
        <ImagePreview image={image} />
      ) : (
        <Typography>Kérjük töltsön fel egy képet!</Typography>
      )}

      <CropImageModal
        open={openCrop1_1}
        crop={crop1_1}
        onClose={() => setOpenCrop1_1(false)}
        setCrop={setCrop1_1}
        imageToUpload={imageToUpload}
      />
    </Box>
  );
}

function useImageSelect(directoryName: string) {
  const [uploadedFile, setUploadedFile] = useState<any>({});
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState<string>('');
  const [imageUploaded, setImageUploaded] = useState(false);
  const [imageToUpload, setImageToUpload] = useState<File>();
  const [openCrop1_1, setOpenCrop1_1] = useState(false);
  const [crop1_1, setCrop1_1] = useState({
    crop: { x: 0, y: 0 },
    area: {},
    zoom: 1,
    rotation: 0,
    aspect: 1,
    shape: 'rect',
    image: { data: '', url: '' },
  });

  const onUpload = useCallback(
    async (
      image: any,
      alt: string,
      fileName: string,
      fileType: string,
      onUploaded: (image: any) => void
    ) => {
      try {
        // Prepare the UpdateImageCommand JSON data
        const createImageCommand = {
          FileName: fileName, // Include the file extension in the FileName
          Alt: alt, // Replace with your Alt Text
          ContentType: fileType,
          DirectoryName: directoryName,
          Data1_1: image.image1_1
            ? Array.from(new Uint8Array(image.image1_1))
            : undefined,
        };

        setUploading(true);
        setError('');

        // Make the API call to upload the image
        const response = await axios.post(
          '/api/image/create',
          createImageCommand,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        setUploadedFile(response.data.result);
        if (onUploaded) onUploaded(response.data.result);
        setUploading(false);
      } catch (err) {
        setError('Error uploading file');
        setUploading(false);
      }
    },
    []
  );
  const uploadImage = (onUploaded: (image: any) => void, alt: any) => {
    const data = {
      image1_1: crop1_1.image.data,
    };
    if (!imageToUpload || !data || imageUploaded) return Promise.resolve();
    setUploading(true);
    return onUpload(
      data,
      alt,
      imageToUpload.name,
      imageToUpload.type,
      onUploaded
    )
      .then(() => setImageUploaded(true))
      .finally(() => setUploading(false));
  };

  const ImageSelectComponent = (props) => {
    const { onSelect, image, onUploaded, alt } = props;

    return (
      <ImageSelect
        onSelect={onSelect}
        image={image}
        setCrop1_1={setCrop1_1}
        crop1_1={crop1_1}
        setImageToUpload={setImageToUpload}
        imageToUpload={imageToUpload}
        setImageUploaded={setImageUploaded}
        imageUploaded={imageUploaded}
        openCrop1_1={openCrop1_1}
        setOpenCrop1_1={setOpenCrop1_1}
        uploading={uploading}
        uploadImage={() => uploadImage(onUploaded, alt)}
      />
    );
  };

  return {
    ImageSelectComponent,
    uploadedFile,
    uploading,
    error,
    uploadImage,
  };
}

export default useImageSelect;
