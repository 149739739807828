import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import WarehouseGroupIcon from '@mui/icons-material/Category';
import SimCardAlertIcon from '@mui/icons-material/SimCardAlert';
import WarehouseItemsIcon from '@mui/icons-material/Liquor';
import AcquisitionsIcon from '@mui/icons-material/LocalShipping';
import WarehouseTranactionsIcon from '@mui/icons-material/ReceiptLong';
import ItemGroupIcon from '@mui/icons-material/Category';
import UserGroupIcon from '@mui/icons-material/Groups';
import ReceiptIcon from '@mui/icons-material/Receipt';
import RoleIcon from '@mui/icons-material/AccountTree';
import WarningIcon from '@mui/icons-material/Warning';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import BusinessIcon from '@mui/icons-material/Business';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import PropertiesIcon from '@mui/icons-material/DataObject';
import { NavLink } from 'react-router-dom';
import useCheckPermission from '../hooks/useCheckPermission';
import { styled } from '@mui/system';
import PaidIcon from '@mui/icons-material/Paid';
import BusAlertIcon from '@mui/icons-material/BusAlert';
import CategoryIcon from '@mui/icons-material/Category';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DescriptionIcon from '@mui/icons-material/Description';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import HistoryIcon from '@mui/icons-material/History';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import DevicesIcon from '@mui/icons-material/Devices';
import ArticleIcon from '@mui/icons-material/Article';
import BarChartIcon from '@mui/icons-material/BarChart';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import {
  AccountBalance,
  AccountTree,
  AddBusiness,
  Assignment,
  Book,
  Calculate,
  Category,
  DataUsage,
  DocumentScanner,
  FindInPage,
  Fireplace,
  LocalOffer,
  MenuBook,
  MiscellaneousServices,
  MoveDown,
  People,
  PointOfSale,
  PriceChange,
  ProductionQuantityLimits,
  Psychology,
  Receipt,
  Roofing,
  Sensors,
  Settings,
  Task,
  Tune,
  Verified,
  Warning,
} from '@mui/icons-material';
import { CollapseMenu } from '../components/CollapseMenu';

const MyNavLink = React.forwardRef<any, any>((props, ref) => (
  <NavLink
    ref={ref}
    to={props.to}
    className={({ isActive }) =>
      `${props.className} ${isActive ? props.activeClassName : ''}`
    }
  >
    {props.children}
  </NavLink>
));

const ActiveLink = styled(ListItem)(({ theme }) => ({
  backgroundColor: theme.palette.info.light,

  '& .MuiSvgIcon-root': {
    color: '#FFFFFF',
    stroke: '#FFFFFF',
    fill: '#19ABC0',
  },
}));

const item = {
  py: '2px',
  px: 3,
  color: 'rgba(255, 255, 255, 1)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
};

const itemCategory = {
  boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
  py: 1.5,
  px: 3,
};

export const menuCategories = [
  {
    id: 'Alkalmazások',
    collapsed: false,
    requiredPermissions: [
      'AcquisitionView',
      'OrderCreate',
      'OrderView',
      'StockTakingView',
      'WarehouseMinAmountView',
      'ItemSupplyViewAll',
      'StockReplenishment',
    ],
    children: [
      {
        id: `Lekérdezések`,
        to: '/queries',
        icon: <FindInPage />,
        useHref: false,
        requiredPermissions: ['ViewQueries'],
      },
      {
        id: `Beszerzésre váró termékek`,
        to: '/itemsUnderThreshold',
        icon: <ProductionQuantityLimits />,
        useHref: false,
        requiredPermissions: ['WarehouseMinAmountView'],
      },
      {
        id: `Beszerzés`,
        to: '/acquisitions',
        icon: <AcquisitionsIcon />,
        useHref: false,
        requiredPermissions: ['AcquisitionView'],
      },
      {
        id: `Eladás`,
        to: '/sales/order',
        icon: <PointOfSale />,
        useHref: false,
        requiredPermissions: ['OrderCreate'],
      },
      {
        id: `Bizonylatok`,
        to: 'sales/documents',
        icon: <Receipt />,
        useHref: false,
        requiredPermissions: ['OrderView'],
      },
      // {
      //   id: `Foglalások`,
      //   to: 'sales/orders',
      //   icon: <Book />,
      //   useHref: false,
      //   requiredPermissions: ['OrderView'],
      // },
      // {
      //   id: `Számlázás`,
      //   to: 'sales/billing',
      //   icon: <Receipt />,
      //   useHref: false,
      //   requiredPermissions: ['UseBilling'],
      // },
      {
        id: `Leltározás`,
        to: '/stockTaking',
        icon: <Calculate />,
        useHref: false,
        requiredPermissions: ['StockTakingView'],
      },
      {
        id: `Korábbi leltárak`,
        to: '/stockTakingHistory',
        icon: <HistoryIcon />,
        useHref: false,
        requiredPermissions: ['StockTakingView'],
      },
      {
        id: 'Anyagfelvétel',
        to: '/wms/itemSupplies',
        icon: <LocalShippingIcon />,
        useHref: false,
        requiredPermissions: ['ItemSupplyView'],
      },
      {
        id: `Árufeltöltés`,
        to: '/stockReplenishment',
        icon: <MoveDown />,
        useHref: false,
        requiredPermissions: ['StockReplenishment'],
      },
    ],
  },
  {
    id: 'Kéményseprés',
    collapsed: false,
    requiredPermissions: [
      'PerformanceCategoryView',
      'ChimneyUseCaseView',
      'ChimneyErrorCodeView',
      'DecisionView',
      'ChimneyTypeView',
      'ChimneyView',
      'ChimneySweepingCertificateView',
    ],
    children: [
      {
        id: 'Teljesítmény kategória',
        to: '/erp/performanceCategories',
        icon: <Fireplace />,
        useHref: false,
        requiredPermissions: ['PerformanceCategoryView'],
      },
      {
        id: 'Használat célja',
        to: '/erp/chimneyUseCases',
        icon: <DataUsage />,
        useHref: false,
        requiredPermissions: ['ChimneyUseCaseView'],
      },
      {
        id: 'Hibakódok',
        to: '/erp/chimneyErrorCodes',
        icon: <Warning />,
        useHref: false,
        requiredPermissions: ['ChimneyErrorCodeView'],
      },
      {
        id: 'Égéstermék elvezetők',
        to: '/erp/chimneyTypes',
        icon: <Roofing />,
        useHref: false,
        requiredPermissions: ['ChimneyTypeView'],
      },
      {
        id: 'Határozatok',
        to: '/erp/decisions',
        icon: <AccountBalance />,
        useHref: false,
        requiredPermissions: ['DecisionView'],
      },
      {
        id: 'Kémények',
        to: '/erp/chimneys',
        icon: <Roofing />,
        useHref: false,
        requiredPermissions: ['ChimneyView'],
      },
      {
        id: 'Szén-monoxid érzékelők',
        to: '/erp/carbonMonoxideSensor',
        icon: <Sensors />,
        useHref: false,
        requiredPermissions: ['CarbonMonoxideSensorView'],
      },
      {
        id: 'Tanúsítványok',
        to: '/erp/chimneySweepingCertificateTabPage',
        icon: <Verified />,
        useHref: false,
        requiredPermissions: ['ChimneySweepingCertificateView'],
      },
    ],
  },
  {
    id: 'Nyilvántartások',
    collapsed: true,
    requiredPermissions: [
      'ComputerView',
      'PeripheryView',
      'MobileView',
      'VehicleView',
      'SimCardView',
      'InstrumentView',
      'ToolView',
      'SerialNumberedBlockView',
      'AccessManagementView',
      'MissionView',
      'MandatoryEducationView',
      'UserCertificationView',
      'CertificationView',
      'AccessSubjectTypeView',
      'SerialNumberedBlockTypeView',
      'VehicleServiceView',
    ],
    children: [
      {
        id: 'Eszköz nyílvántartás',
        to: '/erp/deviceManagementPage',
        icon: <DevicesIcon />,
        useHref: false,
        requiredPermissions: [
          'ComputerView',
          'PeripheryView',
          'MobileView',
          'VehicleView',
          'SimCardView',
          'InstrumentView',
          'ToolView',
          'SerialNumberedBlockView',
        ],
      },
      {
        id: 'HR',
        to: '/erp/hr',
        icon: <Psychology />,
        useHref: false,
        requiredPermissions: [
          'AccessManagementView',
          'MissionView',
          'MandatoryEducationView',
          'UserCertificationView',
          'CertificationView',
          'AccessSubjectTypeView',
          'ViewFileAttachment',
        ],
      },
      {
        id: 'Számozott tömb típus',
        to: '/erp/serialnumberedblocktypes',
        icon: <ArticleIcon />,
        useHref: false,
        requiredPermissions: ['SerialNumberedBlockTypeView'],
      },
      {
        id: 'Jármű szervizek',
        to: '/erp/vehicleServices',
        icon: <ArticleIcon />,
        useHref: false,
        requiredPermissions: ['VehicleServiceView'],
      },
      {
        id: 'Fájlok',
        to: '/erp/files',
        icon: <InsertDriveFileIcon />,
        useHref: false,
        requiredPermissions: ['ViewFileAttachment'],
      },
    ],
  },
  {
    id: 'Termékek, szolgáltatások',
    collapsed: true,
    requiredPermissions: [
      'ItemView',
      'ServiceView',
      'BrandView',
      'ItemGroupView',
      'PropertyView',
    ],
    children: [
      {
        id: `Termékek`,
        to: '/wms/items',
        icon: <WarehouseItemsIcon />,
        useHref: false,
        requiredPermissions: ['ItemView'],
      },
      {
        id: `Márkák`,
        to: '/wms/brands',
        icon: <Category />,
        useHref: false,
        requiredPermissions: ['BrandView'],
      },
      {
        id: 'Termékcsoportok',
        to: '/wms/itemGroups',
        icon: <ItemGroupIcon />,
        useHref: false,
        requiredPermissions: ['ItemGroupView'],
      },
      {
        id: `Termék tulajdonságok`,
        to: '/wms/properties',
        icon: <PropertiesIcon />,
        useHref: false,
        requiredPermissions: ['PropertyView'],
      },
      {
        id: 'Szolgáltatás kategóriák',
        to: '/erp/serviceCategories',
        icon: <Category />,
        useHref: false,
        requiredPermissions: ['ServiceCategoryView'],
      },
      {
        id: `Szolgáltatások`,
        to: '/erp/services',
        icon: <MiscellaneousServices />,
        useHref: false,
        requiredPermissions: ['ServiceView'],
      },
    ],
  },
  {
    id: 'Naptár',
    collapsed: true,
    requiredPermissions: [
      'CalendarView',
      'HolidayView',
      'HolidayViewAll',
      'IrregularWorkdayView',
    ],
    children: [
      {
        id: 'Naptár',
        to: '/erp/userCalendar',
        icon: <CalendarMonthIcon />,
        useHref: false,
        requiredPermissions: ['CalendarView'],
      },
      {
        id: 'Jelenléti',
        to: '/erp/userAttendances',
        icon: <EventAvailableIcon />,
        useHref: false,
        requiredPermissions: ['UserAttendanceView'],
      },
      {
        id: 'Szabadságok',
        to: '/erp/holidays',
        icon: <EventAvailableIcon />,
        useHref: false,
        requiredPermissions: ['HolidayView'],
      },
      {
        id: 'Rendellenes munkanapok',
        to: '/erp/irregularWorkdays',
        icon: <EventBusyIcon />,
        useHref: false,
        requiredPermissions: ['IrregularWorkdayView'],
      },
    ],
  },
  {
    id: 'Munkafolyamatok',
    collapsed: true,
    requiredPermissions: [
      'WorkItemView',
      'TaskTypeView',
      'TaskView',
      'ClosingSheetView',
      'OfferView',
    ],
    children: [
      {
        id: 'Feladatok',
        to: '/erp/tasks',
        icon: <Assignment />,
        useHref: false,
        requiredPermissions: ['TaskView'],
      },
      {
        id: 'Feladattípusok',
        to: '/erp/taskTypes',
        icon: <Assignment />,
        useHref: false,
        requiredPermissions: ['TaskTypeView'],
      },
      {
        id: 'Zárólapok',
        to: '/erp/closingSheets',
        icon: <DescriptionIcon />,
        useHref: false,
        requiredPermissions: ['ClosingSheetView'],
      },
      {
        id: 'Árajánlatok',
        to: '/erp/offers',
        icon: <MonetizationOnIcon />,
        useHref: false,
        requiredPermissions: ['OfferView'],
      },
      {
        id: 'Munkalapok',
        to: '/erp/workItems',
        icon: <Task />,
        useHref: false,
        requiredPermissions: ['WorkItemView'],
      },
      {
        id: 'Projektek',
        to: '/erp/projects',
        icon: <AccountTree />,
        useHref: false,
        requiredPermissions: ['ProjectView'],
      },
    ],
  },
  {
    id: 'Pénzügyek',
    collapsed: true,
    requiredPermissions: [
      'ExpenseQuery',
      'IncomeQuery',
      'ItemsProfitQuery',
      'ExpenseQueryAll',
      'IncomeQueryAll',
      'ItemsIncomeQuery',
      'OutstandingOrderQuery',
      'TaxTypeView',
      'ExpenseTypeView',
      'ExpenseView',
      'EmployeeRankingView',
    ],
    children: [
      {
        id: 'Bevétel-kiadás',
        to: '/statistics/finance',
        icon: <WarehouseIcon />,
        useHref: false,
        requiredPermissions: [
          'ExpenseQuery',
          'IncomeQuery',
          'ItemsProfitQuery',
          'ExpenseQueryAll',
          'IncomeQueryAll',
          'ItemsIncomeQuery',
          'OutstandingOrderQuery',
          'WorkItemViewInvoiceAfterwards',
        ],
      },
      {
        id: 'Adótípusok',
        to: '/pricing/taxTypes',
        icon: <MoneyOffIcon />,
        useHref: false,
        requiredPermissions: ['TaxTypeView'],
      },
      {
        id: 'Kiadástípusok',
        to: '/erp/expenseTypes',
        icon: <CategoryIcon />,
        useHref: false,
        requiredPermissions: ['ExpenseTypeView'],
      },
      {
        id: 'Kiadások',
        to: '/erp/expenses',
        icon: <PaidIcon />,
        useHref: false,
        requiredPermissions: ['ExpenseView'],
      },
      {
        id: 'Dolgozói statisztika',
        to: '/statistics/employeeRanking',
        icon: <BarChartIcon />,
        useHref: false,
        requiredPermissions: ['EmployeeRankingView'],
      },
      {
        id: 'Házipénztár',
        to: '/billing/cashPage',
        icon: <RequestQuoteIcon />,
        useHref: false,
        requiredPermissions: ['ViewCashLog', 'ViewCashRegister'],
      },
      {
        id: 'Utólagos számlázás',
        to: '/erp/workItemsWithInvoiceAfterwards',
        icon: <FileCopyIcon />,
        useHref: false,
        requiredPermissions: ['WorkItemViewInvoiceAfterwards'],
      },
    ],
  },
  {
    id: 'Raktárkezelő',
    collapsed: true,
    requiredPermissions: ['WarehouseView'],
    children: [
      {
        id: 'Raktárak',
        to: '/wms/warehouses',
        icon: <WarehouseIcon />,
        useHref: false,
        requiredPermissions: ['WarehouseView'],
      },
      {
        id: 'Raktár csoportok',
        to: '/wms/groups',
        icon: <WarehouseGroupIcon />,
        useHref: false,
        requiredPermissions: ['WarehouseGroupView'],
      },
      {
        id: 'Raktár események',
        to: '/wms/transactionLogs',
        icon: <WarehouseTranactionsIcon />,
        useHref: false,
        requiredPermissions: ['WarehouseTransactionsView'],
      },
    ],
  },
  {
    id: 'Árazás',
    collapsed: true,
    requiredPermissions: [
      'PriceCategoryView',
      'ItemPriceView',
      'CustomPriceView',
      'AcquisitionView',
    ],
    children: [
      {
        id: 'Árkategoriák',
        to: '/pricing/priceCategories',
        icon: <PriceChange />,
        useHref: false,
        requiredPermissions: ['PriceCategoryView'],
      },
      {
        id: 'Beszerzési árak',
        to: '/pricing/acquisitionPrices',
        icon: <AddBusiness />,
        useHref: false,
        requiredPermissions: ['AcquisitionView'],
      },
      {
        id: 'Ártábla',
        to: '/pricing/itemPrices',
        icon: <LocalOffer />,
        useHref: false,
        requiredPermissions: ['ItemPriceView'],
      },
      {
        id: `Egyedi árazás`,
        to: '/pricing/customPrices',
        icon: <Tune />,
        useHref: false,
        requiredPermissions: ['CustomPriceView'],
      },
    ],
  },
  {
    id: 'Ügyfélkezelő',
    collapsed: false,
    requiredPermissions: ['ClientView'],
    children: [
      {
        id: 'Ügyfelek',
        to: '/crm/clients',
        icon: <BusinessIcon />,
        useHref: false,
        requiredPermissions: ['ClientView'],
      },
      {
        id: 'Címek',
        to: '/crm/addresses',
        icon: <LocationOnIcon />,
        useHref: false,
        requiredPermissions: ['ClientView'],
      },
      {
        id: 'Marketing lista',
        to: '/crm/customers',
        icon: <BusinessIcon />,
        useHref: false,
        requiredPermissions: ['CustomerView'],
      },
      {
        id: 'Szerződések',
        to: '/erp/contracts',
        icon: <ArticleIcon />,
        useHref: false,
        requiredPermissions: ['ContractView'],
      },
      {
        id: 'Szerződés típusok',
        to: '/erp/contractTypes',
        icon: <ArticleIcon />,
        useHref: false,
        requiredPermissions: ['ContractTypeView'],
      },
    ],
  },
  {
    id: 'Bejelentések',
    collapsed: true,
    requiredPermissions: [
      'VehicleDamageReportView',
      'ReportView',
      'ReportCategoryView',
      'ReportViewAll',
    ],
    children: [
      {
        id: 'Jármű kárbejelentések',
        to: '/reports/vehicleDamageReports',
        icon: <BusAlertIcon />,
        useHref: false,
        requiredPermissions: ['VehicleDamageReportView'],
      },
      {
        id: 'Bejelentések',
        to: '/reports/reports',
        icon: <WarningIcon />,
        useHref: false,
        requiredPermissions: ['ReportView', 'ReportViewAll'],
      },
      {
        id: 'Bejelentés kategóriák',
        to: '/reports/reportCategorys',
        icon: <SimCardAlertIcon />,
        useHref: false,
        requiredPermissions: ['ReportCategoryView'],
      },
    ],
  },
  {
    id: 'Rendszerbeállítások',
    collapsed: true,
    requiredPermissions: ['Administrator'],
    children: [
      {
        id: 'Rendszerkonfigurációk',
        to: '/systemConfigurations',
        icon: <Settings />,
        useHref: false,
        requiredPermissions: ['SystemConfigurationEdit'],
      },
      {
        id: 'Email sablonok',
        to: '/erp/emailTemplates',
        icon: <EmailIcon />,
        useHref: false,
        requiredPermissions: ['EmailTemplateView'],
      },
      {
        id: 'Dokumentum sablonok',
        to: '/erp/documentTemplates',
        icon: <DocumentScanner />,
        useHref: false,
        requiredPermissions: ['DocumentTemplateView'],
      },
      {
        id: 'Bizonylattömbök',
        to: '/billing/documentBlocks',
        icon: <ReceiptIcon />,
        useHref: false,
        requiredPermissions: ['ViewDocumentBlock'],
      },
    ],
  },
  {
    id: 'Jogosultságkezelés',
    collapsed: true,
    requiredPermissions: [
      'ManageAuthorization',
      'ManageUsers',
      'ManageUserGroups',
      'ManageRoles',
    ],
    children: [
      {
        id: 'Felhasználók',
        to: '/authority/users',
        icon: <People />,
        useHref: false,
        requiredPermissions: ['ManageUsers'],
      },
      {
        id: 'Felhasználói csoportok',
        to: '/authority/userGroups',
        icon: <UserGroupIcon />,
        useHref: false,
        requiredPermissions: ['ManageUserGroups'],
      },
      {
        id: 'Szerepkörök',
        to: '/authority/roles',
        icon: <RoleIcon />,
        useHref: false,
        requiredPermissions: ['ManageRoles'],
      },
      {
        id: 'Képességek',
        to: '/authority/abilitys',
        icon: <MenuBook />,
        useHref: false,
        requiredPermissions: ['AbilityView'],
      },
    ],
  },
];

export default function Navigator(props: any) {
  const { onClose, ...other } = props;

  const { checkPermission, hasAnyPermission } = useCheckPermission();

  return (
    <Drawer variant="permanent" {...other} onClick={() => {}} onClose={onClose}>
      <List disablePadding>
        <ListItem
          button
          component={MyNavLink}
          activeClassName={ActiveLink}
          to="/"
          sx={{
            ...item,
            ...itemCategory,
            boxShadow: 'none',
            fontSize: 22,
            color: '#fff',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Box
            onClick={() => {
              onClose && onClose();
            }}
          >
            <img
              alt="Jacana logo"
              src="jacana_logo_w.svg"
              height={75}
              style={{ verticalAlign: 'middle' }}
            />
          </Box>
        </ListItem>
        {menuCategories.map(
          ({ id, children, requiredPermissions, collapsed }) =>
            hasAnyPermission(requiredPermissions) ? (
              <CollapseMenu collapsed={collapsed} id={id} key={id}>
                {children.map(
                  ({ id: childId, icon, to, useHref, requiredPermissions }) =>
                    checkPermission(requiredPermissions) ? (
                      !useHref ? (
                        <ListItem
                          disablePadding
                          key={childId}
                          component={MyNavLink}
                          activeClassName={ActiveLink}
                          to={to}
                        >
                          <ListItemButton
                            sx={item}
                            style={{ fontWeight: 'bold' }}
                            onClick={() => {
                              onClose && onClose();
                            }}
                          >
                            <ListItemIcon>{icon}</ListItemIcon>
                            <ListItemText>{childId}</ListItemText>
                          </ListItemButton>
                        </ListItem>
                      ) : (
                        <ListItem
                          disablePadding
                          style={{ fontWeight: 'bold' }}
                          key={childId}
                          component="a"
                          href={to}
                        >
                          <ListItemButton
                            sx={item}
                            onClick={() => {
                              onClose && onClose();
                            }}
                          >
                            <ListItemIcon>{icon}</ListItemIcon>
                            <ListItemText>{childId}</ListItemText>
                          </ListItemButton>
                        </ListItem>
                      )
                    ) : null
                )}
              </CollapseMenu>
            ) : null
        )}
      </List>
    </Drawer>
  );
}
