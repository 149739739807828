import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useDispatch, useSelector } from 'react-redux';
import '../../../css/calendar.css';
import useCheckPermission from '../../../hooks/useCheckPermission';
import useConfirmDeleteDialog from '../../../hooks/useConfirmDeleteDialog';
import userService from '../../../services/authority/userService';
import irregularWorkdayService from '../../../services/erp/irregularWorkdayService';
import { loadEvents } from '../../../stateManagement/thunks/resourcePlannerThunk';
import ResourcePlanner from '../workItems/resourcePlanner/ResourcePlanner';
import holidayService from '../../../services/erp/holidayService';
import { useNavigate } from 'react-router-dom';
import { EntityStatuses } from '../../../types/EntityStatuses';

const UserCalendar = () => {
  const { ConfirmDeleteDialog } = useConfirmDeleteDialog();
  const { checkPermission } = useCheckPermission();

  const { events = [] } = useSelector((state: any) => state.resourcePlanner);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const dispatch = useDispatch<any>();
  const user = useSelector((state: any) => state.user.userInfo);
  const [employees, setEmployees] = useState<any[]>([]);
  const [irregularWorkdays, setIrregularWorkdays] = useState<any[]>([]);
  const [holidays, setHolidays] = useState<any[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [checkboxValue, setCheckboxValue] = useState<boolean>(true);
  const navigate = useNavigate();

  const handleDateClick = useCallback(
    (date: Date) => {
      setSelectedDate(moment(date).add(12, 'hour').toDate());
      dispatch(loadEvents(moment(date).add(12, 'hour').toDate()));
      setIsDialogOpen(true);
    },
    [selectedDate]
  );
  const calendarEvents = events?.map((x: any) => ({
    id: `${x.eventId} ${x.employeeId}`,
    group: x.employeeId,
    title: x.workItemNumber === null ? x.task : x.workItemNumber,
    start_time: moment.utc(x.startDate).local(),
    end_time: moment.utc(x.endDate).local(),
    itemProps: {
      onDoubleClick: () => {
        if (x.workItemId > 0) {
          navigate(`/erp/workitems/edit/${x.workItemId}/0`);
        }
      },
      style: {
        textAlign: 'center',
      },
    },
  }));

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  useEffect(() => {
    if (!checkboxValue) {
      userService.list().then((response) => {
        if (response.canceled) return;
        if (!response.hasError) setEmployees(response.records);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      });
    } else {
      if (user.userId) {
        userService.get(user.userId).then((response) => {
          if (response.canceled) return;
          if (!response.hasError) setEmployees([response.result]);
          else
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
        });
      }
    }
  }, [user.userId, checkboxValue]);

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    irregularWorkdayService
      .list()
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) setIrregularWorkdays(response.records);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  useEffect(() => {
    if (!checkboxValue) {
      dispatch({ type: 'SHOW_QUERY' });
      holidayService
        .list()
        .then((response) => {
          if (response.canceled) return;
          if (!response.hasError) setHolidays(response.records);
          else
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
        })
        .finally(() => dispatch({ type: 'HIDE' }));
    } else if (user.userId > 0) {
      dispatch({ type: 'SHOW_QUERY' });
      holidayService
        .listUser(user.userId)
        .then((response) => {
          if (response.canceled) return;
          if (!response.hasError) setHolidays(response.records);
          else
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
        })
        .finally(() => dispatch({ type: 'HIDE' }));
    }
  }, [user.userId, checkboxValue]);

  return (
    <Paper elevation={3} style={{ padding: '20px' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4" align="center" gutterBottom>
            Naptár
          </Typography>
        </Grid>
        <Grid item xs={12} textAlign={'center'}>
          <FormControlLabel
            control={
              <Checkbox
                disabled={!checkPermission(['CalendarViewAll'])}
                checked={checkboxValue}
                onChange={(e, checked) => {
                  setCheckboxValue(checked);
                }}
              />
            }
            label={'Saját adatok'}
          />
        </Grid>
        <Grid item container justifyContent="center" spacing={2}>
          <Grid item>
            <Calendar
              value={selectedDate}
              returnValue="start"
              onClickDay={(value) => handleDateClick(value)}
              tileClassName={({ date, view }) => {
                let irregularWorkday = irregularWorkdays.find(
                  (x) =>
                    moment(x.date).local().format('DD-MM-YYYY') ===
                    moment(date).local().format('DD-MM-YYYY')
                );
                let holiday = holidays.find(
                  (x) =>
                    x.status === EntityStatuses.Approved &&
                    moment(date)
                      .add(12, 'hour')
                      .isBetween(
                        moment.utc(x.startDate).local(),
                        moment.utc(x.endDate).local(),
                        null,
                        '[]'
                      )
                );

                if (holiday != null) {
                  return 'holiday';
                } else if (irregularWorkday != null) {
                  return irregularWorkday.isWorkday
                    ? 'irregular-date-workday'
                    : 'irregular-date-holiday';
                }
              }}
            />
          </Grid>
          <Grid item>
            <div className="legend">
              <div style={{ display: 'flex' }}>
                <div style={{ color: 'green', fontSize: '24px' }}>▬</div>
                <div style={{ alignSelf: 'center' }}>Munkanap</div>
              </div>
              <div style={{ display: 'flex' }}>
                <div style={{ color: 'crimson', fontSize: '24px' }}>▬</div>
                <div style={{ alignSelf: 'center' }}>Munkaszüneti nap</div>
              </div>
              <div style={{ display: 'flex' }}>
                <div style={{ color: 'yellow', fontSize: '24px' }}>▬</div>
                <div style={{ alignSelf: 'center' }}>Szabadság</div>
              </div>
            </div>
          </Grid>
        </Grid>
        <Dialog
          open={isDialogOpen}
          onClose={closeDialog}
          maxWidth="lg"
          fullWidth
        >
          <DialogContent>
            <ResourcePlanner
              employees={employees}
              events={calendarEvents}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              isCalendar
            />
          </DialogContent>
        </Dialog>
      </Grid>
      <ConfirmDeleteDialog />
    </Paper>
  );
};

export default UserCalendar;
