import SyncIcon from '@mui/icons-material/Sync';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Autocomplete,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Tab,
  Tabs,
  TextField,
  Tooltip,
} from '@mui/material';
import Button from '@mui/material/Button';
import {
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
} from '@mui/x-data-grid';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { add, parseJSON } from 'date-fns';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import DataList from '../../../components/DataList';
import SelectOrderIdDialog from '../../../components/SelectOrderIdDialog';
import '../../../css/dataGrid.css';
import chimneySweepingCertificateService from '../../../services/chimneySweeping/chimneySweepingCertificateService';
import clientService from '../../../services/crm/clientService';
import orderService from '../../../services/sales/orderService';
import { AttachmentTypes } from '../../../types/AttachmentTypes';
import FilesPage from '../Files/FilesPage';
import {
  translateEmailStatusName,
  translateAttachmentTypesName,
} from '../../../utils/nameFormatters';

export default function ChimneySweepingCertificateEmailDialog(props: any) {
  const {
    chimneySweepingCertificateId,
    orderId = 0,
    open,
    setOpen,
    onSent,
    clientId,
  } = props;
  const [address, setAddress] = React.useState<string>('');
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const [certificates, setCertificates] = useState<any[]>([]);
  const [now, setNow] = useState<Date>(new Date());
  const [orderSelectDialog, setOrderSelectDialog] = useState<any>({
    open: false,
    clientId: 0,
    chimneySweepingCertificateId: 0,
  });
  const [orders, setOrders] = useState<any[]>([]);
  const [selectedCertsWithOrder, setSelectedCertsWithOrder] = useState<any[]>(
    []
  );
  const [tabValue, setTabValue] = React.useState(0);
  const [filePageOpen, setFilePageOpen] = React.useState({
    open: false,
    certId: 0,
  });
  const [chimneysweepingcertificate, setChimneySweepingCertificate] =
    useState<any>({
      name: 'ÉGÉSTERMÉK-ELVEZETŐK ELLENŐRZÉSE, SZÜKSÉG SZERINTI TISZTÍTÁSA, 4 ÉVENKÉNTI MŰSZAKI FELÜLVIZSGÁLATA',
      id: 0,
      airSpaceConnectionAmount: '',
      clientId: 0,
      employeeId: 0,
      serviceProviderId: 0,
      approverName: '',
      documentNumber: '',
      approverTitle: '',
      others: '',
      date: now,
      workAddressId: 0,
      obligedToInstallCODevice: '',
      obligedToInstallCODevicePlaceNumber: '',
      hasCODevice: '',
      cODevicePlace: '',
      cODeviceWorks: '',
      cleaningDone: '',
      calibrationDone: '',
      hasDocuments: '',
      expired: '',
      errorMessage: '',
      appearedOnLocation: true,
      inspection: true,
      cleaning: false,
      technicalReview: false,
      chimneyControls: [],
    });

  useEffect(() => {
    if (clientId > 0) {
      dispatch({ type: 'SHOW_QUERY' });
      chimneySweepingCertificateService
        .list(null, null, clientId)
        .then((response) => {
          if (response.canceled) return;
          if (!response.hasError) {
            setCertificates(response.records);
          } else
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
        })
        .finally(() => dispatch({ type: 'HIDE' }));
    }
  }, [clientId]);

  const handleClose = () => {
    setSelectedCertsWithOrder([]);
    setOpen({ open: false, id: 0, certificates: [] });
  };

  useEffect(() => {
    if (certificates?.length > 0) {
      dispatch({ type: 'SHOW_QUERY' });
      orderService
        .list(certificates[0].clientId)
        .then((response) => {
          if (response.canceled) return;
          if (!response.hasError) {
            setOrders(response.records);
          } else
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
        })
        .finally(() => dispatch({ type: 'HIDE' }));
    }
  }, [certificates]);

  useEffect(() => {
    if (chimneySweepingCertificateId > 0) {
      let found = certificates.find(
        (x) => x.id === chimneySweepingCertificateId
      );
      if (found) {
        if (found.attachmentCount > 0) {
          setSelectedCertsWithOrder((prev) => [
            {
              chimneySweepingCertificateId: chimneySweepingCertificateId,
              orderId: orderId,
            },
          ]);
        }
        setChimneySweepingCertificate(found);
        setAddress(found.workAddressEmail);
      }
    }
  }, [chimneySweepingCertificateId, orderId, certificates]);

  const sendEmail = async (selectedCertsWithOrder: any) => {
    if (address !== '') {
      const entity = {
        to: address,
        selectedCertsWithOrder: selectedCertsWithOrder,
      };
      dispatch({ type: 'SHOW_QUERY' });
      chimneySweepingCertificateService
        .sendEmail(entity)
        .then((response) => {
          if (response.canceled) return;
          if (response.hasError) {
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
          } else {
            enqueueSnackbar('Sikeres küldés', {
              variant: 'success',
            });
            onSent();
            handleClose();
          }
          fetchData(chimneysweepingcertificate.id);
        })
        .finally(() => dispatch({ type: 'HIDE' }));
    }
  };

  const sendOrderEmail = async () => {
    if (address !== '') {
      const entity = {
        to: address,
        orderId: orderId,
      };
      dispatch({ type: 'SHOW_QUERY' });
      orderService
        .sendEmail(entity)
        .then((response) => {
          if (response.canceled) return;
          if (response.hasError) {
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
          } else {
            enqueueSnackbar('Sikeres küldés', {
              variant: 'success',
            });
            onSent(response.result);
            setOpen({ open: false, id: 0 });
          }
        })
        .finally(() => dispatch({ type: 'HIDE' }));
    }
  };
  const reSendEmail = async (sentEmailId: any) => {
    if (address !== '') {
      const entity = {
        to: address,
        sentEmailId: sentEmailId,
      };
      dispatch({ type: 'SHOW_QUERY' });
      chimneySweepingCertificateService
        .reSendEmail(entity)
        .then((response) => {
          if (response.canceled) return;
          if (response.hasError) {
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
          } else {
            enqueueSnackbar('Sikeres küldés', {
              variant: 'success',
            });
            onSent();
            handleClose();
          }
        })
        .finally(() => dispatch({ type: 'HIDE' }));
    }
  };
  const fetchData = async (certId: any) => {
    dispatch({ type: 'SHOW_QUERY' });
    chimneySweepingCertificateService
      .get(certId)
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) {
          setCertificates((prev) => [
            ...prev.map((x) => (x.id === certId ? response.result : x)),
          ]);
        } else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  };

  const getEmailActions = (params: GridRowParams, color: any) => {
    var actions = [];

    actions.push(
      <GridActionsCellItem
        color={'primary'}
        icon={
          <Tooltip title="Újraküldés">
            <SyncIcon />
          </Tooltip>
        }
        label="Újraküldés"
        onClick={() => {
          reSendEmail(params.row.id);
        }}
      />
    );

    return actions;
  };

  const columns: GridColDef[] = [
    {
      field: 'to',
      headerName: 'Email cím',
      flex: 200,
    },
    {
      field: 'sentAt',
      headerName: 'Küldés dátuma',
      valueFormatter: (params: any) => {
        return parseJSON(params.value).toLocaleString();
      },
      flex: 200,
    },
    {
      field: 'openedAt',
      headerName: 'Megnyitás dátuma',
      valueFormatter(params) {
        var date = new Date(params.value);
        return date > new Date(null)
          ? parseJSON(params.value).toLocaleString()
          : 'Nincs megnyitva';
      },
      flex: 200,
    },
    {
      field: 'status',
      headerName: 'Státusz',
      valueFormatter(params) {
        return translateEmailStatusName(params.value);
      },
      flex: 100,
    },
    {
      field: 'getEmailActions',
      type: 'actions',
      align: 'right',
      flex: 50,
      getActions: getEmailActions,
    } as GridActionsColDef,
  ];

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];

    let found = selectedCertsWithOrder.find(
      (x) => x.chimneySweepingCertificateId === params.row.id
    );

    if (found) {
      let foundOrder = orders.find((x) => x.id === found.orderId);
      actions.push(
        <Button
          variant="contained"
          color={color ? color : 'primary'}
          onClick={() => {
            setOrderSelectDialog({
              open: true,
              clientId: params.row.clientId,
              chimneySweepingCertificateId: params.row.id,
            });
          }}
          sx={{ padding: '0px 10px', borderRadius: '8px' }}
        >
          {foundOrder?.invoiceNumber
            ? foundOrder.invoiceNumber
            : 'Számla kiválasztása'}
        </Button>
      );
    }
    if (params.row.attachmentCount !== 0) {
      actions.push(
        <Button
          variant="contained"
          color={color ? color : 'primary'}
          onClick={() => {
            if (
              selectedCertsWithOrder.some(
                (x) => x.chimneySweepingCertificateId === params.row.id
              )
            ) {
              setSelectedCertsWithOrder((prev) =>
                prev.filter(
                  (x) => x.chimneySweepingCertificateId !== params.row.id
                )
              );
            } else {
              let foundCert = certificates.find((x) => x.id === params.row.id);
              setSelectedCertsWithOrder((prev) => [
                ...prev,
                {
                  chimneySweepingCertificateId: params.row.id,
                  orderId: foundCert?.orderId ?? 0,
                },
              ]);
            }
          }}
          sx={{ padding: '0px 10px', borderRadius: '8px' }}
        >
          Kiválasztás
        </Button>
      );
    }
    return actions;
  };
  const getHeaders = (params: GridRowParams, color: any) => {
    var actions = [];

    if (params.row.attachmentCount === 0) {
      actions.push(
        <GridActionsCellItem
          color={'error'}
          icon={
            <Tooltip title="Nincs feltöltve fájl a tanúsítványhoz!">
              <WarningIcon />
            </Tooltip>
          }
          label="Nincs feltöltve fájl a tanúsítványhoz!"
          onClick={() => {
            setFilePageOpen({ open: true, certId: params.row.id });
          }}
        />
      );
    }
    return actions;
  };

  const clientCertColumns: GridColDef[] = [
    {
      field: 'getHeaders',
      type: 'actions',
      align: 'center',
      flex: 20,
      getActions: getHeaders,
    } as GridActionsColDef,
    {
      field: 'date',
      headerName: 'Dátum',
      flex: 100,
      valueFormatter(params) {
        return params.value
          ? parseJSON(params.value).toLocaleDateString()
          : 'Nincs';
      },
    },
    {
      field: 'documentNumber',
      headerName: 'Dokumentum száma',
      flex: 50,
      valueGetter(params) {
        return params.value ? parseInt(params.value) : params.value;
      },
    },
    {
      field: 'employee',
      headerName: 'Dolgozó',
      flex: 100,
      valueFormatter(params) {
        return params.value ? params.value.fullName : 'Nincs';
      },
    },
    { field: 'workAddress', headerName: 'Cím', flex: 200 },
    { field: 'workAddressEmail', headerName: 'Email cím', flex: 150 },
    {
      field: 'actions',
      type: 'actions',
      align: 'right',
      flex: 200,
      getActions: getActions,
    } as GridActionsColDef,
  ];

  return (
    <>
      <Dialog open={open} onClose={() => handleClose()} fullWidth maxWidth="lg">
        <DialogTitle>Email küldése:</DialogTitle>
        <DialogContent>
          <Grid item xs={12} pt={2}>
            <Autocomplete
              disablePortal
              id="address"
              value={address ? address?.split(';') : []}
              onChange={(event, value) => {
                setAddress(value.join(';'));
              }}
              multiple
              freeSolo
              autoSelect
              renderInput={(params) => (
                <TextField {...params} required label="Email cím" />
              )}
              options={[]}
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ width: '100%' }}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: 'divider',
                  pb: 2,
                }}
              >
                <Tabs
                  value={tabValue}
                  onChange={(event, newValue) => {
                    setTabValue(newValue);
                  }}
                  TabIndicatorProps={{ style: { background: '#1976d2' } }}
                  allowScrollButtonsMobile
                  scrollButtons="auto"
                  variant="scrollable"
                  centered
                >
                  <Tab
                    label="Ügyfél tanúsítványai"
                    sx={{ fontWeight: 'bold' }}
                  />
                  <Tab label=" Korábbi küldések" sx={{ fontWeight: 'bold' }} />
                </Tabs>
              </Box>
              {tabValue === 0 && (
                <Grid item xs={12} pt={2}>
                  <DataList
                    rows={certificates ?? []}
                    columns={clientCertColumns}
                    getRowId={(row) => `${row.id}-${row.uniqueId}`}
                    minimal
                    localStorageKey={'chimneysweepingclientcertificatesEmails'}
                    getRowClassName={(params) => {
                      return selectedCertsWithOrder.some(
                        (x) => x.chimneySweepingCertificateId === params.row.id
                      )
                        ? 'verified'
                        : '';
                    }}
                  />
                </Grid>
              )}
              {tabValue === 1 && (
                <Grid item xs={12} pt={2}>
                  <DataList
                    rows={chimneysweepingcertificate?.emails || []}
                    columns={columns}
                    getRowId={(row) => row.id}
                    minimal
                    localStorageKey={'chimneysweepingcertificateEmails'}
                  />
                </Grid>
              )}
            </Box>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disabled={address === '' || !(orderId > 0)}
            onClick={() => {
              sendOrderEmail();
            }}
          >
            Csak Számla küldése
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={
              address === '' ||
              (selectedCertsWithOrder?.length ?? 0) === 0 ||
              selectedCertsWithOrder.some((x) => x.orderId === 0)
            }
            onClick={() => {
              sendEmail(selectedCertsWithOrder);
            }}
          >
            Küldés
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => handleClose()}
          >
            Mégse
          </Button>
        </DialogActions>
        <SelectOrderIdDialog
          open={orderSelectDialog.open}
          orders={orders}
          onClose={() =>
            setOrderSelectDialog({
              open: false,
              clientId: 0,
              chimneySweepingCertificateId: 0,
            })
          }
          onSelected={(selectedOrder: any) => {
            setSelectedCertsWithOrder((prev) => [
              ...prev.map((x) =>
                x.chimneySweepingCertificateId ===
                orderSelectDialog.chimneySweepingCertificateId
                  ? {
                      ...x,
                      orderId: selectedOrder.id,
                    }
                  : x
              ),
            ]);
          }}
        />
      </Dialog>
      <Dialog
        open={filePageOpen.open}
        onClose={() => setFilePageOpen({ open: false, certId: 0 })}
        fullWidth
        maxWidth="lg"
      >
        <DialogContent sx={{ p: 0 }}>
          <FilesPage
            attachmentType={AttachmentTypes.ChimneySweepingCertificate}
            localStorageKey={'ChimneySweepingCertificateEmailDialogFilePage'}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              fetchData(filePageOpen.certId);
              setFilePageOpen({ open: false, certId: 0 });
            }}
          >
            Változás történt
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => setFilePageOpen({ open: false, certId: 0 })}
          >
            Mégse
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
