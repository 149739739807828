import axios from 'axios';

const userCertificationService = {
  list: () => {
    return axios.get(`/api/userCertification/list`).then((response) => {
      return response.data;
    });
  },
  get: (id: any) => {
    return axios
      .get(`/api/userCertification/details/${id}`)
      .then((response) => {
        return response.data;
      });
  },
};

export default userCertificationService;
