import PaidIcon from '@mui/icons-material/Paid';
import { Grid, Typography } from '@mui/material';
import RankingCardComponent from '../../../statistics/RankingComponents/RankingCardComponent';

const ClientProfileStatistics = (props: any) => {
  const {
    filteredExpenses,
    filteredOrders,
    isGross,
    orders = [],
    expenses = [],
  } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <RankingCardComponent
          allData={{
            totalIncomeValue:
              filteredOrders.paidOrdersPrice +
              filteredOrders.dueOrdersPrice +
              filteredOrders.outStandingOrdersPrice,
            totalIncomes: orders,
            paidIncomeValue: filteredOrders.paidOrdersPrice,
            dueIncomeValue: filteredOrders.dueOrdersPrice,
            outstandingIncomeValue: filteredOrders.outStandingOrdersPrice,
          }}
          disableName={true}
          cardHeader={
            <Grid container justifyContent={'space-between'}>
              <Grid item>
                <Typography
                  fontWeight={'bold'}
                  display="flex"
                  alignItems="center"
                >
                  <PaidIcon color="success" />
                  Bevétel({isGross ? 'bruttó' : 'nettó'})
                </Typography>
              </Grid>
            </Grid>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <RankingCardComponent
          allData={{
            totalIncomeValue:
              filteredExpenses.paidExpensesPrice +
              filteredExpenses.dueExpensesPrice +
              filteredExpenses.outStandingExpensesPrice,
            totalIncomes: expenses,
            paidIncomeValue: filteredExpenses.paidExpensesPrice,
            dueIncomeValue: filteredExpenses.dueExpensesPrice,
            outstandingIncomeValue: filteredExpenses.outStandingExpensesPrice,
          }}
          disableName={true}
          cardHeader={
            <Grid container justifyContent={'space-between'}>
              <Grid item>
                <Typography
                  fontWeight={'bold'}
                  display="flex"
                  alignItems="center"
                >
                  <PaidIcon color="error" />
                  Kiadás({isGross ? 'bruttó' : 'nettó'})
                </Typography>
              </Grid>
            </Grid>
          }
        />
      </Grid>
    </Grid>
  );
};

export default ClientProfileStatistics;
