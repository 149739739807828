import {
  Grid,
  TextField,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Checkbox,
  IconButton,
  Box,
  Tooltip,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import React from 'react';
import Divider from '@mui/material/Divider';
import { Dangerous, Delete, Warning } from '@mui/icons-material';
import VerticalTable from './VerticalTable';
import COVerticalTable from './COVerticalTable';

const OtherInformation: React.FC<any> = ({
  values,
  touched,
  validationErrors,
  handleChange,
  setFieldValue,
  readonly,
  setCurrentNegativeId,
  currentNegativeId,
  moduleSettings,
  firstSectionDone,
  coSensors,
  now,
}) => {
  const getData = (values: any) => {
    const verticalTable = [];

    values.carbonMonoxideSensors.forEach((sensor) => {
      const rowData = {
        id:
          sensor.id > 0 ? (
            <div>
              {sensor.clientName}
              {sensor.clientId === values.clientId ? (
                ''
              ) : (
                <Tooltip
                  children={<Warning color="error" />}
                  title={
                    'Figyelem az érzékelő nem a jelenlegi ügyfélhez tartozik'
                  }
                ></Tooltip>
              )}
              <br />
              {sensor.fullAddress}
              <br />
              {`Telepítés dátuma: ${new Date(
                sensor.dateOfInstallation
              ).toLocaleDateString()}`}
            </div>
          ) : (
            'Telepített érzékelő'
          ),
        Azonosító: (
          <TextField
            value={sensor.identifier}
            InputProps={{
              sx: {
                input: {
                  textAlign: 'center',
                },
              },
            }}
            InputLabelProps={{ shrink: true }}
            type="number"
            onChange={(e) => {
              setFieldValue('carbonMonoxideSensors', [
                ...values.carbonMonoxideSensors.map((x) =>
                  x.id === sensor.id
                    ? {
                        ...x,
                        identifier: e.target.value,
                      }
                    : x
                ),
              ]);
            }}
            fullWidth
            onBlur={() => {
              let found = coSensors.find(
                (x) => x.identifier === parseInt(sensor.identifier)
              );
              if (found) {
                setFieldValue('carbonMonoxideSensors', [
                  ...values.carbonMonoxideSensors.map((x) =>
                    parseInt(x.identifier) === parseInt(sensor.identifier)
                      ? {
                          ...found,
                        }
                      : x
                  ),
                ]);
              } else {
                setFieldValue('carbonMonoxideSensors', [
                  ...values.carbonMonoxideSensors.map((x) =>
                    parseInt(x.identifier) === parseInt(sensor.identifier)
                      ? {
                          id: currentNegativeId,
                          identifier: x.identifier,
                          lastControlYear: now,
                          addressId: values.workAddressId,
                          dateOfInstallation: now,
                          clientId: values.clientId,
                          itemId: moduleSettings.settings.coItemId,
                          serviceId: moduleSettings.settings.coServiceId,
                          wasChecked: false,
                          wasInstalled: true,
                        }
                      : x
                  ),
                ]);
                setCurrentNegativeId(currentNegativeId - 1);
              }
            }}
            required
          />
        ),
        Telepítve: (
          <Checkbox
            checked={sensor.wasInstalled}
            disabled={readonly}
            onChange={(e) => {
              setFieldValue('carbonMonoxideSensors', [
                ...values.carbonMonoxideSensors.map((x) =>
                  x.id === sensor.id
                    ? {
                        ...sensor,
                        wasInstalled: e.target.checked,
                      }
                    : x
                ),
              ]);
            }}
          />
        ),
        'Tisztítás,karbantartás megtörtént': (
          <Checkbox
            checked={sensor.wasChecked}
            disabled={readonly}
            onChange={(e) => {
              setFieldValue('carbonMonoxideSensors', [
                ...values.carbonMonoxideSensors.map((x) =>
                  x.id === sensor.id
                    ? {
                        ...sensor,
                        wasChecked: e.target.checked,
                        cleaningDone: e.target.checked,
                        lastControlYear: e.target.checked
                          ? now
                          : new Date(now.getFullYear() - 1),
                      }
                    : x
                ),
              ]);
            }}
          />
        ),
        'Szén-monokszid-érzékelő berendezés megléte': (
          <Checkbox
            checked={sensor.hasCODevice}
            disabled={readonly}
            onChange={(e) => {
              setFieldValue('carbonMonoxideSensors', [
                ...values.carbonMonoxideSensors.map((x) =>
                  x.id === sensor.id
                    ? {
                        ...sensor,
                        hasCODevice: e.target.checked,
                      }
                    : x
                ),
              ]);
            }}
          />
        ),

        'Szén-monokszid-érzékelő berendezés helye': (
          <TextField
            value={sensor.coDevicePlace}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              setFieldValue('carbonMonoxideSensors', [
                ...values.carbonMonoxideSensors.map((x) =>
                  x.id === sensor.id
                    ? {
                        ...sensor,
                        coDevicePlace: e.target.value,
                      }
                    : x
                ),
              ]);
            }}
            fullWidth
          />
        ),
        'Szén-monokszid-érzékelő berendezés müködőképessége megfelelő': (
          <Checkbox
            checked={sensor.cODeviceWorks}
            disabled={readonly}
            onChange={(e) => {
              setFieldValue('carbonMonoxideSensors', [
                ...values.carbonMonoxideSensors.map((x) =>
                  x.id === sensor.id
                    ? {
                        ...sensor,
                        cODeviceWorks: e.target.checked,
                      }
                    : x
                ),
              ]);
            }}
          />
        ),
        'Kalibrálás megtörtént': (
          <Checkbox
            checked={sensor.calibrationDone}
            disabled={readonly}
            onChange={(e) => {
              setFieldValue('carbonMonoxideSensors', [
                ...values.carbonMonoxideSensors.map((x) =>
                  x.id === sensor.id
                    ? {
                        ...sensor,
                        calibrationDone: e.target.checked,
                      }
                    : x
                ),
              ]);
            }}
          />
        ),
        'Műszaki követelményeknek való megfelelőségét igazoló dokumentumok megléte':
          (
            <Checkbox
              checked={sensor.hasDocuments}
              disabled={readonly}
              onChange={(e) => {
                setFieldValue('carbonMonoxideSensors', [
                  ...values.carbonMonoxideSensors.map((x) =>
                    x.id === sensor.id
                      ? {
                          ...sensor,
                          hasDocuments: e.target.checked,
                        }
                      : x
                  ),
                ]);
              }}
            />
          ),
        'Szavatosággi idő, a működőképesség határideje lejárt': (
          <Checkbox
            checked={sensor.expired}
            disabled={readonly}
            onChange={(e) => {
              setFieldValue('carbonMonoxideSensors', [
                ...values.carbonMonoxideSensors.map((x) =>
                  x.id === sensor.id
                    ? {
                        ...sensor,
                        expired: e.target.checked,
                      }
                    : x
                ),
              ]);
            }}
          />
        ),
        Törlés: (
          <Box alignContent="center">
            <IconButton
              disabled={readonly}
              size="small"
              onClick={() => {
                setFieldValue('carbonMonoxideSensors', [
                  ...values.carbonMonoxideSensors?.filter(
                    (y) => y.id !== sensor.id
                  ),
                ]);
              }}
            >
              <Delete color="error" />
            </IconButton>
          </Box>
        ),
      };

      verticalTable.push(rowData);
    });

    return verticalTable;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControl component="fieldset">
          <FormLabel>
            Szén-monokszid-érzékelő berendezés felszerelésére és müködtetésére
            köteles
          </FormLabel>
          <RadioGroup
            aria-label="obligedToInstallCODevice"
            row
            name="obligedToInstallCODevice"
            value={values.obligedToInstallCODevice?.toString() ?? ''}
            onChange={(event) =>
              setFieldValue(
                'obligedToInstallCODevice',
                event.target.value === 'true'
              )
            }
          >
            <FormControlLabel
              value="true"
              control={<Radio disabled={readonly} />}
              label="Igen"
            />
            <FormControlLabel
              value="false"
              control={<Radio disabled={readonly} />}
              label="Nem"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={values.obligedToInstallCODevicePlaceNumber ?? ''}
          type="number"
          onChange={handleChange}
          label="Szén-monokszid-érzékelő berendezés felszerelésére köteles helységes száma"
          name="obligedToInstallCODevicePlaceNumber"
          disabled={readonly}
          fullWidth
          error={
            !!touched.obligedToInstallCODevicePlaceNumber &&
            !!validationErrors.obligedToInstallCODevicePlaceNumber
          }
          helperText={
            !!touched.obligedToInstallCODevicePlaceNumber &&
            !!validationErrors.obligedToInstallCODevicePlaceNumber &&
            (validationErrors.obligedToInstallCODevicePlaceNumber as string)
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Divider textAlign="left" sx={{ mt: 2 }}>
          Szén-monoxid érzékelők
        </Divider>
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={12} pb={2}>
          <Button
            variant="contained"
            disabled={readonly || !firstSectionDone || !values.workAddressId}
            onClick={() => {
              setFieldValue('carbonMonoxideSensors', [
                ...values.carbonMonoxideSensors,
                {
                  id: currentNegativeId,
                  identifier: '',
                  lastControlYear: now,
                  addressId: values.workAddressId,
                  dateOfInstallation: now,
                  clientId: values.clientId,
                  itemId: moduleSettings.settings.coItemId,
                  serviceId: moduleSettings.settings.coServiceId,
                  wasChecked: false,
                  wasInstalled: true,
                },
              ]);
              setFieldValue(
                'warehouseId',
                moduleSettings.settings.coItemWarehouseId
              );
              setCurrentNegativeId(currentNegativeId - 1);
            }}
          >
            Szén-monoxid érzékelő hozzáadása
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <COVerticalTable
          data={getData(values)}
          readonly={readonly || !firstSectionDone}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={values.errorMessage ?? ''}
          onChange={handleChange}
          label="Hiba részletes leírása"
          multiline
          rows={4}
          name="errorMessage"
          disabled={readonly}
          fullWidth
          error={!!touched.errorMessage && !!validationErrors.errorMessage}
          helperText={
            !!touched.errorMessage &&
            !!validationErrors.errorMessage &&
            (validationErrors.errorMessage as string)
          }
        />
      </Grid>
    </Grid>
  );
};

export default OtherInformation;
