import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import store from './stateManagement/store';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { SnackbarProvider } from 'notistack';
import { IconButton, Tooltip } from '@mui/material';
import { Close } from '@mui/icons-material';
import { MsalProvider } from '@azure/msal-react';

import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './authConfig';
import axios from 'axios';

var container = document.getElementById('root');
var root = ReactDOMClient.createRoot(container);

const notistackRef = React.createRef<any>();

const onClickDismiss = (key: any) => {
  notistackRef.current.closeSnackbar(key);
};

const msalInstance = new PublicClientApplication(msalConfig);

axios.interceptors.response.use(
  (resp) => {
    return resp;
  },
  (error) => {
    if (error.name === 'AxiosError') {
      throw error;
    }
    return {
      data: {
        canceled: error.name === 'CanceledError',
        hasError: true,
        errorMessages: [error.message],
      },
    };
  }
);

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <SnackbarProvider
            maxSnack={3}
            autoHideDuration={5000}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            ref={notistackRef}
            onClick={() => {
              onClickDismiss(notistackRef.current.key);
            }}
            action={(key: any) => (
              <Tooltip title={<span>Close</span>}>
                <IconButton
                  aria-label="delete"
                  size="small"
                  color="inherit"
                  onClick={() => onClickDismiss(key)}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              </Tooltip>
            )}
          >
            <App />
          </SnackbarProvider>
        </LocalizationProvider>
      </Provider>
    </MsalProvider>
  </React.StrictMode>
);
