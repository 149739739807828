import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  useMediaQuery,
} from '@mui/material';
import Button from '@mui/material/Button';
import OrderView from '../pages/sales/orders/OrderView';
import { Currency } from '../types/Currency';

export default function OrderPDFDialog(props: any) {
  const {
    orderId,
    open,
    onClose,
    values = null,
    currency = Currency.HUF,
  } = props;
  const isSmallScreen = useMediaQuery('(min-width:600px)');

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      fullWidth
      maxWidth={'md'}
      fullScreen={!isSmallScreen}
    >
      <DialogContent>
        <Grid item xs={12} pt={2}>
          <OrderView orderId={orderId} values={values} currency={currency} />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={() => onClose()}>
          Kilépés
        </Button>
      </DialogActions>
    </Dialog>
  );
}
