import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import CustomerForm, { Customer } from './CustomerForm';
import { useNavigate } from 'react-router-dom';
import useCheckPermission from '../../../../hooks/useCheckPermission';
import customerService from '../../../../services/crm/customerService';

const CustomerCreate = (props: any) => {
  const { onSave = null, navigateBack = true, handleCancel = null } = props;
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<string[]>([]);
  const { checkPermission } = useCheckPermission();

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    customerService
      .create(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés', {
            variant: 'success',
          });
          if (onSave) onSave(response.result);
          if (navigateBack) navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        setSubmitting(false);
      });
  };

  return (
    <CustomerForm
      readonly={!checkPermission(['CustomerCreate'])}
      entity={
        {
          isActive: true,
          contactMode: '',
          source: '',
          name: '',
          fullAddress: '',
          email: '',
          isEmailValid: false,
          phoneNumber: '',
          isPhoneNumberValid: false,
          comment: '',
          activities: '',
          contactQuality: '',
          firstContact: '',
          lastContact: '',
          nextContact: '',
          conversionDate: '',
          clientId: '',
          salesPhase: '',
        } as Customer
      }
      onSubmit={onSubmit}
      errors={errors}
      handleCancel={() =>
        handleCancel != null ? handleCancel() : navigate(-1)
      }
    />
  );
};

export default CustomerCreate;
