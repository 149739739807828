import useConfirmDialog from './useConfirmDialog';

const useConfirmDeleteDialog = () => {
  const { ConfirmDialog, setConfirmParams, params } = useConfirmDialog();

  const ConfirmDeleteDialog = (props: any) => {
    return <ConfirmDialog actionName="Törlés" />;
  };
  return {
    ConfirmDeleteDialog,
    setParams: setConfirmParams,
    params,
  };
};
export default useConfirmDeleteDialog;
