import { Suspense, useCallback, useEffect, useState } from 'react';
import Layout from './layout/Layout';
import { HashRouter as Router } from 'react-router-dom';
import WaitLayer from './layout/WaitLayer';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useAccount,
  useMsal,
} from '@azure/msal-react';

import { loginRequest, forceLoginRequest, msalConfig } from './authConfig'; // Add googleLoginRequest
import axios from 'axios';
import appService from './services/appService';
import accountService from './services/accountService';
import functionDescriptorService from './services/functionDescriptorService';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { LocalizationProvider } from '@mui/x-date-pickers';
import AdapterDateFns from '@date-io/date-fns';

import { Paper, Button } from '@mui/material';
import { Box } from '@mui/system';
import sasService from './services/sasService';
const Login = () => {
  const { instance, inProgress, accounts } = useMsal();

  const handleGoogleLogin = async () => {
    try {
      await instance.loginPopup(forceLoginRequest);
    } catch (error) {}
  };

  const handleMicrosoftLogin = async () => {
    try {
      await instance.loginRedirect(forceLoginRequest);
    } catch (error) {}
  };

  instance.handleRedirectPromise().then((response) => {
    if (response) {
      instance.setActiveAccount(response.account);
    }
  });

  // useEffect(() => {
  //   if (accounts.length > 0) {
  //     let authorizedAccounts = accounts.filter(
  //       (a) => a.idTokenClaims.aud === msalConfig.auth.clientId
  //     );
  //     instance.setActiveAccount(authorizedAccounts[0]);
  //   }
  // }, [accounts, instance]);

  return (
    <Box
      display="flex"
      id="login"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      margin="-8px"
      bgcolor="#f5f5f5"
    >
      <Paper elevation={3} sx={{ padding: '2rem', textAlign: 'center' }}>
        <img
          src="android-chrome-192x192.png"
          alt="Logo"
          style={{ marginBottom: '1rem' }}
        />
        <p>Welcome to the Jacana Enterprise Management System</p>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap="1rem"
          marginTop="2rem"
        >
          {/*           <Button
            variant="contained"
            color="primary"
            onClick={handleGoogleLogin}
          >
            Sign in with Google
          </Button> */}
          <Button
            variant="contained"
            color="primary"
            onClick={handleMicrosoftLogin}
            disabled={inProgress === 'login' || inProgress !== 'none'}
          >
            Sign in with Microsoft
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

const App = () => {
  const { instance, accounts } = useMsal();
  const [axiosReady, setAxiosReady] = useState(false);
  const [isUserInfoLoaded, setIsUserInfoLoaded] = useState(false);

  const dispatch = useDispatch<any>();
  const { enqueueSnackbar } = useSnackbar();

  const fetchToken = async () => {
    try {
      const response = await sasService.getSASToken();
      dispatch({ type: 'SET_SAS_TOKEN', payload: response.result.token });
    } catch (error) {
      console.error('Error fetching SAS token:', error);
    }
  };

  useEffect(() => {
    let acc = instance.getActiveAccount();
    if (acc) {
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: acc,
          forceRefresh: true,
        })
        .then(
          (response) => {
            localStorage.setItem('webApiAccessToken', response.accessToken);
            dispatch({
              type: 'SET_ACCESS_TOKEN',
              payload: response.accessToken,
            });
            return response.accessToken;
          },
          (error) => {
            instance
              .acquireTokenRedirect({
                ...forceLoginRequest,
              })
              .then((response) => {
                /*  localStorage.setItem('webApiAccessToken', response.accessToken);
                dispatch({
                  type: 'SET_ACCESS_TOKEN',
                  payload: response.accessToken,
                });
                return response.accessToken; */
              });
          }
        )
        .then((token: string) => {
          axios.interceptors.request.use((config) => {
            const t = localStorage.getItem('webApiAccessToken');

            config.headers.Authorization = `Bearer ${t}`;

            return config;
          });
          setAxiosReady(true);
        });

      let timer = setInterval(async () => {
        var result = await instance.acquireTokenSilent({
          ...loginRequest,
          forceRefresh: true,
          account: acc,
        });

        localStorage.setItem('webApiAccessToken', result.accessToken);
        fetchToken();
      }, 10 * 1000 * 60);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [dispatch, instance, accounts]);

  useEffect(() => {
    if (axiosReady) {
      accountService.getUserInfo().then((response: any) => {
        if (response.canceled) return;
        if (!response.hasError)
          dispatch({ type: 'LOAD_USER_INFO', payload: response });
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        setIsUserInfoLoaded(true);
      });
      appService.getAppStatus().then((response: any) => {
        if (response.canceled) return;
        if (!response.hasError)
          dispatch({ type: 'LOAD__STATUS', payload: response });
      });
      functionDescriptorService.list().then((response: any) => {
        if (response.canceled) return;
        if (!response.hasError)
          dispatch({
            type: 'LOAD_FUNCTION_DESCRIPTORS',
            payload: response.records,
          });
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      });
    }
  }, [axiosReady, dispatch, enqueueSnackbar]);

  useEffect(() => {
    fetchToken();
  }, [axiosReady]);

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <AuthenticatedTemplate>
          {axiosReady && (
            <Router>
              <Suspense fallback="Loading...">
                <WaitLayer />
                {isUserInfoLoaded && <Layout />}
              </Suspense>
            </Router>
          )}
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Login />
        </UnauthenticatedTemplate>
      </LocalizationProvider>
    </div>
  );
};

export default App;
