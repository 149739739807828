import axios from 'axios';

const itemPriceService = {
  list: (warehouseId: number) => {
    return axios
      .get(`/api/itemPrice/list?warehouseId=${warehouseId}`)
      .then((response) => {
        return response.data;
      });
  },
  listListPrices: (
    warehouseId: number | null,
    priceCategory: number,
    includeUnpriced: boolean = false
  ) => {
    if (warehouseId === null)
      return axios
        .get(
          `/api/itemPrice/listListPrices?priceCategory=${priceCategory}&includeUnpriced=${includeUnpriced}`
        )
        .then((response) => {
          return response.data;
        });
    else
      return axios
        .get(
          `/api/itemPrice/listListPrices?warehouseId=${warehouseId}&priceCategory=${priceCategory}&includeUnpriced=${includeUnpriced}`
        )
        .then((response) => {
          return response.data;
        });
  },
  listAcquisitionPrices: (signal: AbortSignal) => {
    return axios
      .get(`/api/itemPrice/listAcquisitionPrices`, {
        signal,
      })
      .then((response) => {
        return response.data;
      });
  },
};

export default itemPriceService;
