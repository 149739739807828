import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { QrReader } from 'react-qr-reader';

export default function QrCodeReaderDialog(props: any) {
  const { open, setOpen, decode, onQrCodeScanned } = props;

  const handleClose = () => {
    setOpen(false);
    window.location.reload();
  };

  const handleScan = (result: any, error: any) => {
    if (result && result.text.length > 0) {
      setOpen(false);
      onQrCodeScanned(decode(result.text));
    }
  };
  return (
    <Dialog open={open} onClose={handleClose} fullScreen>
      <DialogTitle>QrCode olvasás</DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={2}
          alignItems={'center'}
          justifyContent="center"
          style={{ height: '100%', width: '100%' }}
        >
          <Grid item xs={12}>
            {open && (
              <QrReader
                constraints={{ facingMode: 'environment', aspectRatio: 1 }}
                onResult={handleScan}
                scanDelay={500}
                containerStyle={{
                  height: '100%',
                  width: '100%',
                }}
                videoContainerStyle={{ width: '100%', height: '100%' }}
                videoStyle={{ width: '100%', height: '100%' }}
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={handleClose}>
          Mégse
        </Button>
      </DialogActions>
    </Dialog>
  );
}
