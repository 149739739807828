import axios from 'axios';
import { postData } from '../axiosUtils';
import { AttachmentTypes } from '../../types/AttachmentTypes';

const fileAttachmentService = {
  list: (
    type: AttachmentTypes | null,
    hasDocument: boolean | null,
    entityUniqueId: string | null
  ) => {
    let url = `/api/fileAttachment/list`;
    if (type) {
      url += `?type=${type}`;
    }
    if (hasDocument !== null) {
      if (type) {
        url += `&hasDocument=${hasDocument}`;
      } else {
        url += `?hasDocument=${hasDocument}`;
      }
    }
    if (entityUniqueId) {
      if (type || hasDocument !== null) {
        url += `&entityUniqueId=${entityUniqueId}`;
      } else {
        url += `?entityUniqueId=${entityUniqueId}`;
      }
    }
    return axios.get(url).then((response) => {
      return response.data;
    });
  },
  pairFileAttachments: () => {
    let url = `/api/fileAttachment/pairFileAttachments`;
    return axios.get(url).then((response) => {
      return response.data;
    });
  },
  get: (id: any) => {
    return axios.get(`/api/fileAttachment/details/${id}`).then((response) => {
      return response.data;
    });
  },
  create: (entity: any) => {
    return postData({
      url: `/api/fileAttachment/create`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  update: (entity: any) => {
    return postData({
      url: `/api/fileAttachment/update`,
      data: entity,
      method: 'PUT',
    }).then((response) => {
      return response;
    });
  },
  delete: (id: any) => {
    return postData({
      url: `/api/fileAttachment/delete/${id}`,
      method: 'DELETE',
    }).then((response) => {
      return response;
    });
  },
  detach: (id: any) => {
    return postData({
      url: `/api/fileAttachment/detach/${id}`,
      method: 'PUT',
    }).then((response) => {
      return response;
    });
  },
};

export default fileAttachmentService;
