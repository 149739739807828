import {
  ArrowBack,
  ArrowDownward,
  ArrowForward,
  ArrowUpward,
} from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useFormik } from 'formik';
import 'suneditor/dist/css/suneditor.min.css';
import { Orientation } from '../../../types/Orientation';
import { translateOrientation } from '../../../utils/nameFormatters';
import DocumentTemplateTestView from './DocumentTemplateTestView';
import React, { useEffect, useState } from 'react';
import { SketchPicker } from 'react-color';
import { useSelector } from 'react-redux';
import { invoiceInfo } from '../../sales/SalesPage';

const DocumentTemplateForm = (props: any) => {
  const { entity, onSubmit, errors, handleCancel, readonly = false } = props;
  const [primaryColorPicker, setPrimaryColorPicker] = useState(false);
  const [secondaryColorPicker, setSecondaryColorPicker] = useState(false);
  const [imageOriginalWidth, setImageOriginalWidth] = useState(0);
  const [imageOriginalHeight, setImageOriginalHeight] = useState(0);

  const { setting } = useSelector<any>(
    (state) => state.documentTemplate
  ) as any;

  const {
    isSubmitting,
    values,
    handleChange,
    setFieldValue,
    handleSubmit,
    touched,
    errors: validationErrors,
  } = useFormik({
    initialValues: entity,
    enableReinitialize: true,
    validate: (values) => {
      const errors: any = {};

      return errors;
    },
    onSubmit: async (values, { setSubmitting }) => {
      if (values.setting.imageUrlOrBase64) {
        let height =
          imageOriginalHeight >= values.setting.imageHeight * 4
            ? values.setting.imageHeight * 4
            : imageOriginalHeight;
        let width =
          imageOriginalWidth >= values.setting.imageWidth * 4
            ? values.setting.imageWidth * 4
            : imageOriginalWidth;
        const scaledImage = await handleImageScaling(
          values.setting.imageUrlOrBase64,
          values.setting.imageWidth / values.setting.imageHeight,
          height,
          width
        );

        values.setting.imageUrlOrBase64 = (
          scaledImage as any
        ).scaledBase64String;
      }
      onSubmit(values, setSubmitting);
    },
  });
  const [aspectRatio, setAspectRatio] = React.useState(0);
  const [keepAspectRatio, setKeepAspectRatio] = React.useState(true);
  const handleImageUpload = (event: any) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    const isSvg = file?.type === 'image/svg+xml';
    reader.onloadend = () => {
      const base64String = reader.result as string;
      // Remove the Data URL prefix (e.g., "data:image/png;base64,")
      let base64WithoutPrefix = '';
      if (isSvg) {
        base64WithoutPrefix = base64String.replace(
          /^data:image\/svg\+xml;base64,/,
          ''
        );
      } else {
        base64WithoutPrefix = base64String.replace(
          /^data:image\/[a-zA-Z]+;base64,/,
          ''
        );
      }
      const img = new Image();

      // When the image loads, get its width, height, and ratio
      img.onload = () => {
        let width = img.width;
        let height = img.height;
        setImageOriginalWidth(img.width);
        setImageOriginalHeight(img.height);
        const aspectRatio = width / height;

        height = 50;
        width = Math.round(height * aspectRatio);

        // Optionally, store width, height, and ratio in state or formik field
        setFieldValue('setting.imageWidth', width);
        setFieldValue('setting.imageHeight', height);
        setAspectRatio(aspectRatio);
      };

      // Set the image source to the base64 string (with the prefix)
      img.src = base64String;

      setFieldValue('setting.imageUrlOrBase64', base64WithoutPrefix);
    };

    if (file) {
      reader.readAsDataURL(file); // Convert to base64 string
    }
  };

  useEffect(() => {
    if (entity.setting.imageUrlOrBase64) {
      setAspectRatio(entity.setting.imageWidth / entity.setting.imageHeight);
    }
  }, [entity]);

  const handleImageScaling = (
    imageUrl: string,
    aspectRatio: number,
    height: number,
    width: number
  ) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = `data:image/jpeg;base64,${imageUrl}`;

      img.onload = () => {
        // Create a canvas to draw the scaled image
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.drawImage(img, 0, 0, width, height);

          // Convert canvas content to Base64
          const scaledBase64String = canvas
            .toDataURL('image/png')
            .replace(/^data:image\/[a-zA-Z]+;base64,/, '');
          resolve({ scaledBase64String, width, height });
        } else {
          reject('Failed to create canvas context.');
        }
      };

      img.onerror = (err) => reject(err);
    });
  };

  useEffect(() => {
    if (setting) {
      setFieldValue('setting', setting);
    }
  }, [setting]);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Paper>
        <Grid container justifyContent="left" spacing={2} p={5}>
          <h2>
            Dokumentum sablon
            {readonly
              ? 'megtekintése'
              : values.id !== 0
              ? 'szerkesztése'
              : 'létrehozása'}
          </h2>
          <Grid xs={12} item pt={5}>
            <Box color="red">
              {errors?.map((error) => <li>{error}</li>) ?? []}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid item xs={12} pb={2}>
              <TextField
                value={values.name}
                onChange={(event) => {
                  setFieldValue('name', event.target.value);
                }}
                label="Megnevezés"
                InputLabelProps={{ shrink: true }}
                disabled={readonly}
                fullWidth
                error={!!touched.name && !!validationErrors.name}
                helperText={
                  !!touched.name &&
                  !!validationErrors.name &&
                  (validationErrors.name as string)
                }
              />
            </Grid>
            {/* <Grid item xs={12} pb={2}>
              <Autocomplete
                disablePortal
                value={values.type}
                disabled={readonly}
                onChange={(event, value) => {
                  setFieldValue('type', value);
                }}
                getOptionLabel={(option) => {
                  return translateDocumentTemplateType(
                    option as DocumentTemplateType
                  );
                }}
                options={
                  Object.values(DocumentTemplateType).filter((x) =>
                    Number.isFinite(x)
                  ) ?? []
                }
                renderInput={(params) => (
                  <TextField {...params} required label="Típus" />
                )}
              />
            </Grid> */}
            <Grid container item xs={12} alignItems="center" pb={2}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  onClick={() => setPrimaryColorPicker(!primaryColorPicker)}
                  style={{
                    width: '30px',
                    height: '30px',
                    backgroundColor: values.setting.primaryColor,
                    cursor: 'pointer',
                    borderRadius: '50%',
                  }}
                ></div>
                <h3 style={{ marginLeft: '10px' }}>Elsődleges szín</h3>
              </div>
              {primaryColorPicker && (
                <SketchPicker
                  color={values.setting.primaryColor}
                  onChangeComplete={(newColor) =>
                    setFieldValue('setting.primaryColor', newColor.hex)
                  }
                />
              )}
            </Grid>
            <Grid container item xs={12} alignItems="center" pb={2}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  onClick={() => setSecondaryColorPicker(!secondaryColorPicker)}
                  style={{
                    width: '30px',
                    height: '30px',
                    backgroundColor: values.setting.secondaryColor,
                    cursor: 'pointer',
                    borderRadius: '50%',
                  }}
                ></div>
                <h3 style={{ marginLeft: '10px' }}>Másodlagos szín</h3>
              </div>
              {secondaryColorPicker && (
                <SketchPicker
                  color={values.setting.secondaryColor}
                  onChangeComplete={(newColor) =>
                    setFieldValue('setting.secondaryColor', newColor.hex)
                  }
                />
              )}
            </Grid>
            <Grid item xs={12} pb={2}>
              <Autocomplete
                disablePortal
                value={values.setting.orientation}
                disabled={readonly}
                onChange={(event, value) => {
                  setFieldValue('setting.orientation', value);
                }}
                getOptionLabel={(option) => {
                  return translateOrientation(option as Orientation);
                }}
                options={
                  Object.values(Orientation).filter((x) =>
                    Number.isFinite(x)
                  ) ?? []
                }
                renderInput={(params) => (
                  <TextField {...params} required label="Orientáció" />
                )}
              />
            </Grid>
            <Grid item xs={12} pb={2}>
              <TextField
                value={values.setting.decimalPrecision}
                onChange={(event) => {
                  setFieldValue(
                    'setting.decimalPrecision',
                    parseInt(event.target.value)
                  );
                }}
                label="Tizedesjegyek száma"
                type="number"
                InputLabelProps={{ shrink: true }}
                disabled={readonly}
                fullWidth
                error={
                  !!touched.decimalPrecision &&
                  !!validationErrors.decimalPrecision
                }
                helperText={
                  !!touched.decimalPrecision &&
                  !!validationErrors.decimalPrecision &&
                  (validationErrors.decimalPrecision as string)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Divider textAlign="left" sx={{ mt: 2 }}>
                Kép beállítások
              </Divider>
            </Grid>
            <Grid item xs={12} pb={2}>
              <TextField
                type="file"
                inputProps={{ accept: 'image/png, image/jpeg, image/jpg' }}
                onChange={handleImageUpload}
                disabled={readonly}
                label="Kép feltöltése"
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} pb={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={keepAspectRatio}
                    onChange={(e, checked) =>
                      setKeepAspectRatio(checked as boolean)
                    }
                    color="primary"
                  />
                }
                label="Képarány megtartása"
              />
            </Grid>
            <Grid item xs={12} pb={2}>
              <TextField
                value={values.setting.imageHeight}
                onChange={(event) => {
                  setFieldValue(
                    'setting.imageHeight',
                    parseInt(event.target.value)
                  );

                  if (keepAspectRatio) {
                    setFieldValue(
                      'setting.imageWidth',
                      Math.round(parseInt(event.target.value) * aspectRatio)
                    );
                  }
                }}
                label="Kép magasság"
                type="number"
                InputLabelProps={{ shrink: true }}
                disabled={readonly}
                fullWidth
                error={!!touched.imageHeight && !!validationErrors.imageHeight}
                helperText={
                  !!touched.imageHeight &&
                  !!validationErrors.imageHeight &&
                  (validationErrors.imageHeight as string)
                }
              />
            </Grid>
            <Grid item xs={12} pb={2}>
              <TextField
                value={values.setting.imageWidth}
                onChange={(event) => {
                  setFieldValue(
                    'setting.imageWidth',
                    parseInt(event.target.value)
                  );
                  if (keepAspectRatio) {
                    setFieldValue(
                      'setting.imageHeight',
                      Math.round(parseInt(event.target.value) / aspectRatio)
                    );
                  }
                }}
                label="Kép szélesség"
                type="number"
                InputLabelProps={{ shrink: true }}
                disabled={readonly}
                fullWidth
                error={!!touched.imageHeight && !!validationErrors.imageWidth}
                helperText={
                  !!touched.imageWidth &&
                  !!validationErrors.imageWidth &&
                  (validationErrors.imageWidth as string)
                }
              />
            </Grid>
            <Grid container direction="column" alignItems="center">
              <Grid item>
                <IconButton
                  disabled={readonly}
                  onClick={() => {
                    setFieldValue(
                      'setting.imageYPosition',
                      values.setting.imageYPosition - 5
                    );
                  }}
                >
                  <ArrowUpward color="primary" />
                </IconButton>
              </Grid>

              <Grid item container justifyContent="center">
                <Grid item pr={6}>
                  <IconButton
                    disabled={readonly}
                    onClick={() => {
                      setFieldValue(
                        'setting.imageXPosition',
                        values.setting.imageXPosition - 5
                      );
                    }}
                  >
                    <ArrowBack color="primary" />
                  </IconButton>
                </Grid>

                <Grid item>
                  <IconButton
                    disabled={readonly}
                    onClick={() => {
                      setFieldValue(
                        'setting.imageXPosition',
                        values.setting.imageXPosition + 5
                      );
                    }}
                  >
                    <ArrowForward color="primary" />
                  </IconButton>
                </Grid>
              </Grid>

              <Grid item>
                <IconButton
                  disabled={readonly}
                  onClick={() => {
                    setFieldValue(
                      'setting.imageYPosition',
                      values.setting.imageYPosition + 5
                    );
                  }}
                >
                  <ArrowDownward color="primary" />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <DocumentTemplateTestView
              type={values.type}
              setting={values.setting}
            />
          </Grid>
          <Grid container item pt={3} justifyContent="left">
            {!readonly && (
              <Grid item p={1}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                >
                  Mentés
                </Button>
              </Grid>
            )}
            <Grid item p={1}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCancel}
              >
                Mégse
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </form>
  );
};

export default DocumentTemplateForm;
