import {
  Button,
  TextField,
  Box,
  Grid,
  Paper,
  InputAdornment,
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Form, Formik } from "formik";
import CreateEntityDecorator from "../../../components/CreateEntityDecorator";
import ItemCreate from "../../wms/items/ItemCreate";
import BrandCreate from "../../wms/brands/BrandCreate";
import PriceCategoryCreate from "../priceCategories/PriceCategoryCreate";
import useCheckPermission from "../../../hooks/useCheckPermission";

const CustomPriceForm = (props: any) => {
  const {
    entity,
    brands,
    items,
    onNewItemAdded,
    onNewBrandAdded,
    onNewPriceCategoryAdded,
    priceCategories,
    onSubmit,
    errors,
    handleCancel,
    readonly = false,
  } = props;

  const { checkPermission } = useCheckPermission();

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        handleChange,
        setFieldValue,
        errors: validationErrors,
      }) => (
        <Form>
          {" "}
          <Paper>
            <Grid container justifyContent="left" spacing={2} p={5}>
              <h2>
                Egyedi ár {values.id > 0 ? "szerkesztése" : "létrehozása"}
              </h2>
              <Grid xs={12} item pt={5}>
                <Box color="red">
                  {errors.map((error) => (
                    <li>{error}</li>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <FormLabel id="customPriceType">Egyedi ár típusa</FormLabel>
                  <RadioGroup
                    aria-labelledby="customPriceType"
                    name="customPriceType"
                    onChange={(event) =>
                      setFieldValue(
                        "customPriceType",
                        parseInt(event.target.value)
                      )
                    }
                    value={values.customPriceType}
                  >
                    <Grid container item xs={12}>
                      <Grid item>
                        <FormControlLabel
                          value={1}
                          disabled={readonly}
                          control={<Radio />}
                          label="Termék"
                        />
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          value={2}
                          disabled={readonly}
                          control={<Radio />}
                          label="Márka"
                        />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </Grid>
              {values.customPriceType === 1 && (
                <Grid item xs={12}>
                  <CreateEntityDecorator
                    hideAdd={!checkPermission(["ItemCreate"])}
                    AutocompleteComponent={
                      <Autocomplete
                        disablePortal
                        id="item"
                        disabled={readonly}
                        value={values.itemId}
                        onChange={(event, value) => {
                          setFieldValue("itemId", value);
                          setFieldValue("brandId", "");
                        }}
                        getOptionLabel={(option) =>
                          items.find((g) => g.id === option)?.name ?? ""
                        }
                        options={items.map((g) => g.id)}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth label="Termék" />
                        )}
                      />
                    }
                    onSave={(item) => {
                      setFieldValue("itemId", item.id);
                      onNewItemAdded(item);
                    }}
                    CreateEntityComponent={ItemCreate}
                  />
                </Grid>
              )}
              {values.customPriceType === 2 && (
                <Grid item xs={12}>
                  <CreateEntityDecorator
                    hideAdd={!checkPermission(["BrandCreate"])}
                    AutocompleteComponent={
                      <Autocomplete
                        disablePortal
                        id="brand"
                        disabled={readonly}
                        value={values.brandId}
                        onChange={(event, value) => {
                          setFieldValue("brandId", value);
                          setFieldValue("itemId", "");
                        }}
                        getOptionLabel={(option) =>
                          brands.find((g) => g.id === option)?.name ?? ""
                        }
                        options={brands.map((g) => g.id)}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth label="Márka" />
                        )}
                      />
                    }
                    onSave={(brand) => {
                      setFieldValue("brandId", brand.id);
                      onNewBrandAdded(brand);
                    }}
                    CreateEntityComponent={BrandCreate}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <CreateEntityDecorator
                  hideAdd={!checkPermission(["PriceCategoryCreate"])}
                  AutocompleteComponent={
                    <Autocomplete
                      disablePortal
                      id="priceCategory"
                      disabled={readonly}
                      value={values.priceCategoryId}
                      onChange={(event, value) => {
                        setFieldValue("priceCategoryId", value);
                      }}
                      getOptionLabel={(option) =>
                        priceCategories.find((g) => g.id === option)?.name ?? ""
                      }
                      options={priceCategories.map((g) => g.id)}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth label="Árcsoport" />
                      )}
                    />
                  }
                  onSave={(priceCategory) => {
                    setFieldValue("priceCategoryId", priceCategory.id);
                    onNewPriceCategoryAdded(priceCategory);
                  }}
                  CreateEntityComponent={PriceCategoryCreate}
                />
              </Grid>
              <Grid item xs={10} md={2}>
                <TextField
                  value={values.margin}
                  onChange={(event) => {
                    setFieldValue("margin", parseFloat(event.target.value));
                  }}
                  label="Árrés"
                  name="margin"
                  disabled={readonly}
                  fullWidth
                  required
                  type="number"
                  error={!!touched.margin && !!validationErrors.margin}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  helperText={
                    !!touched.margin &&
                    !!validationErrors.margin &&
                    (validationErrors.margin as string)
                  }
                />
              </Grid>
              <Grid container item pt={3} justifyContent="left">
                {!readonly && (
                  <Grid item p={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      Mentés
                    </Button>
                  </Grid>
                )}
                <Grid item p={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleCancel}
                  >
                    Mégse
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default CustomPriceForm;
